import React from "react";
import ChatComponent from "./diligenceChat";

const ChatLayout = (props) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
      }}
    >
      <div
        style={{
          flex: 3,
          borderRight: "1px solid #e0e0e0",
          borderLeft: "1px solid #e0e0e0",
        }}
      >
        {props.children}
      </div>
      <div style={{ flex: 2 }}>
        <ChatComponent
          companyInfo={props.companyInfo}
          opportunityInfo={props.opportunity_data}
          updateCompanyData={props.updateCompanyData}
          applicationObject={props.applicationObject}
          handleCreditChange={props.handleCreditChange}
          user={props.user}
        />
      </div>
    </div>
  );
};

export default ChatLayout;
