import React from 'react';

import { Navbar, Nav, Row, Col, Card, Button, ButtonToolbar, Container, FormLabel, Form } from 'react-bootstrap';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import RestoreIcon from '@material-ui/icons/Restore';
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import PauseIcon from '@material-ui/icons/Pause';

class ProjectNavBar extends React.Component {
  render() {
    return (
      <div className='__project-navbar'>
        <BottomNavigation
          value={ this.props.menuSelection }
          onChange={ this.props.handleMenuChange }
          class=""
          showLabels >
          <BottomNavigationAction
            label={`Pending (${this.props.pending_projects.length})`}
            value={0}
            icon={ <RestoreIcon /> } />
          <BottomNavigationAction
            label={`Active (${this.props.active_projects.length})`}
            value={1}
            icon={ <PlayCircleFilledWhiteIcon /> } />
            <BottomNavigationAction
              label={`Paused (${this.props.paused_projects.length})`}
              value={2}
              icon={ <PauseIcon /> } />
          <BottomNavigationAction
            label={`Completed (${this.props.completed_projects.length})`}
            value={3}
            icon={ <CheckCircleIcon /> } />

        </BottomNavigation>
      </div>
      );
  }
}

export default ProjectNavBar;
