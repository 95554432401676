import React from 'react';
import './LatestNews.css';
import image1 from '../assets/dominik-lange-86967-403x290.jpg'
import image2 from '../assets/carissa-gan-66379-403x290.jpg'
import image3 from '../assets/jaroslav-devia-715017-unsplash-403x290.jpg';
import pic1 from '../assets/crumina-reaction-amazed.png'
import pic2 from '../assets/crumina-reaction-anger.png'
import pic3 from '../assets/crumina-reaction-bad.png'
import pic4 from '../assets/crumina-reaction-cool.png'
import pic5 from '../assets/crumina-reaction-like.png'
import pic6 from '../assets/crumina-reaction-joy.png'
import pic7 from '../assets/crumina-reaction-lol.png'
import CommentIcon from '../assets/CommentIcon';

const LatestNews = props => (
    <div className='OlympusComunityReviews LatesOlympusNews'>
        <div className='MainBodyOlympus'>
            <span className='OlympusOr'>OLYMPUS BLOG</span>
            <h2>Latest News</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore</p>
            <div className='OlympusReviewCards'>
                <div className='OlympusReviewCard OlympusNewsCard'>
                    <div className='latestNewsHeader' style={{ backgroundImage: 'url(' + image1 + ')' }}></div>
                    <div style={{ padding: '0 24px' }}>
                        <a href='#' className='labelNewsOlympus'>Community</a>
                        <a href='#'>Olympus join people all over the world</a>
                        <p>Praesent ut turpis turpis. Maecenas in elementum lacus. Proin malesuada nunc quam, vel malesuada turpis elementum et. In vel dui eu orci rutrum tincidunt. Sed massa neque, ultricies ac dolor quis, posuere maximus diam.</p>
                    </div>
                    <div className='NewsLatesFooter'>
                        <span>BY <b>CRUMINA</b> - JULY 24, 2018</span>
                        <div className='NewsLatesFooterMain'>
                            <img src={pic1} alt='' />
                            <img src={pic2} alt='' />
                            <img src={pic3} alt='' />
                            <img src={pic4} alt='' />
                            <img src={pic6} alt='' />
                            <img src={pic5} alt='' />
                            <img src={pic7} alt='' />
                            <div className='commentslikes'>
                                210
                                <CommentIcon /> 0
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className='OlympusReviewCard OlympusNewsCard' style={{ margin: '0 31px' }}>
                    <div className='latestNewsHeader' style={{ backgroundImage: 'url(' + image2 + ')' }}></div>
                    <div style={{ padding: '0 24px' }}>
                        <a className='labelNewsOlympus' href='#'>Community</a>
                        <a href='#'>Olympus join people all over the world</a>
                        <p>Praesent ut turpis turpis. Maecenas in elementum lacus. Proin malesuada nunc quam, vel malesuada turpis elementum et. In vel dui eu orci rutrum tincidunt. Sed massa neque, ultricies ac dolor quis, posuere maximus diam.</p>
                    </div>
                    <div className='NewsLatesFooter'>
                        <span>BY <b>CRUMINA</b> - JULY 24, 2018</span>
                        <div className='NewsLatesFooterMain'>
                            <img src={pic1} alt='' />
                            <img src={pic2} alt='' />
                            <img src={pic3} alt='' />
                            <img src={pic4} alt='' />
                            <img src={pic6} alt='' />
                            <img src={pic5} alt='' />
                            <img src={pic7} alt='' />
                            <div className='commentslikes'>
                                210
                                <CommentIcon /> 0
                            </div>
                        </div>
                    </div>
                </div>

                <div className='OlympusReviewCard OlympusNewsCard'>
                    <div className='latestNewsHeader' style={{ backgroundImage: 'url(' + image1 + ')' }}></div>
                    <div style={{ padding: '0 24px' }}>
                        <a href='#' className='labelNewsOlympus'>Community</a>
                        <a href='#'>Olympus join people all over the world</a>
                        <p>Praesent ut turpis turpis. Maecenas in elementum lacus. Proin malesuada nunc quam, vel malesuada turpis elementum et. In vel dui eu orci rutrum tincidunt. Sed massa neque, ultricies ac dolor quis, posuere maximus diam.</p>
                    </div>
                    <div className='NewsLatesFooter'>
                        <span>BY <b>CRUMINA</b> - JULY 24, 2018</span>
                        <div className='NewsLatesFooterMain'>
                            <img src={pic1} alt='' />
                            <img src={pic2} alt='' />
                            <img src={pic3} alt='' />
                            <img src={pic4} alt='' />
                            <img src={pic6} alt='' />
                            <img src={pic5} alt='' />
                            <img src={pic7} alt='' />
                            <div className='commentslikes'>
                                210
                                <CommentIcon /> 0
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
)

export default LatestNews;