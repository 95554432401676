import React from 'react';
import './NewsFeed.css';
import './LeftNav.css';
import './LeftBody/LeftBody.css';
import './RightBody/RightBody.css';
import './Result/Result.css';
import Nav from './Nav/Nav';
import { AppContext } from '../App'
import RightNav from './RightNav/RightNav';
import LeftBody from './LeftBody/LeftBody';
import RightBody from './RightBody/RightBody';
import SideMenu from '../live/SideMenu';
import SocialOnboardingView from './CenterBody/SocialOnboardingView';

class PageContainer extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <SocialOnboardingContainer
        {...this.props}
        {...this.context} />
    )
  }
}
PageContainer.contextType = AppContext;

class SocialOnboardingContainer extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <Nav />
        <div className='newsFeedBody'>
          <div className='newsfeed NewsFeedBodyMain'>
            <SocialOnboardingView {...this.props} />
          </div>
        </div>
      </div>
    )
  }
}

export default PageContainer;
