import React from 'react';
import SocialProfile from './SocialProfile';
import NetworkActivity from '../RightBody/NetworkActivity';

const LeftBody = props => (
  <div className='LeftPartNews'>
    <SocialProfile opp_company={props.opp_company} />
    
  </div>
)

export default LeftBody;
