import React from 'react';
import './FAQsPitch.css';

const FAQsPitch = props => (
    <div className='pitchHowItWorks FAQsPitch'>
        <h3>FAQ's</h3>
        <div className='pitchBoxContainer'>
            <h5>Who is the PitchBox for?</h5>
            The PitchBox is for Pitch Competition organizers around the world to manage intake, market & review startup applications. Whether you run a conference, incubator, accelerator, investment network, workspace or are a startup-related organization, running a Pitch Competition is something you may already do or definitely should be running!
            <h5>What's the difference between using Typeform/Google Forms, our own website, etc.?</h5>
            It's still a very manual process for you once you've downloaded all the forms. The PitchBox saves you time, allows your organization to invite judges who can review & provide feedback all in one place. In addition to this, your Pitch Competition and Live Event (if you run one) will be shared with our network on Startupfuel.com!
            <h5>How much does it cost?</h5>
            The PitchBox is affordable to launch & we charge per competition you run with us, no monthly fees! Our 3 tier pricing structure will be explained over a demo so you can understand how it works!
            <h5>How long should we use the PitchBox?</h5>
            It depends on each contest, however, we recommend accepting applications for about 3 months and then a month after the deadline to make sure your judges have time to review all the startups, collaborate with your team & finalize the top startups.
            <h5>Can we use the PitchBox for our Live Event?</h5>
            Yes, you may use the PitchBox for your Live Event and it works in a very similar way to the initial review process. No more pen & paper - the scores will be an average of all the judge's reviews & ranked in order.
            <h5>Can you help with marketing our Pitch Competition & Live Event?</h5>
            Yes! Once you have your Pitch Competition launched with us, your Competition will be shared on www.startupfuel.com! Once the deadline ends, we shall promote the Live Event that is taking place so those who wish to attend, can book tickets.
            <h5>Can you help with additional marketing for our Pitch Competition?</h5>
            Of course we can! If you choose to opt-in to PitchBox Plus, we can market your Pitch Competition to the relevant startups on our platform, share across our social media channels, run ads & more! Contact us for pricing!
            <h5>I'd like more information about this this tool, how can I get in touch?</h5>
            Sure, happy to help! You can click 'Request a Demo' above or email us at team@startupfuel.com & we'll get back to you to schedule a call.
            </div>
    </div>
)

export default FAQsPitch;