/* (2019-06-10) - This is the page that appears after a user registers using
  the register link that appears in the menu.
*/
import React from 'react';
import '../main.css';
import axios from 'axios'
import PropTypes from 'prop-types';
import URL from 'url-parse';
import Navigation from './components/navbar'
import SiteTitle from './components/site_title.js'
import { Sign_In, Register, Forgot_Password } from './components/popups'
import { AppContext } from '../App'
import { withStyles } from '@material-ui/core/styles';
import SideMenu from './SideMenu';
import { Link } from 'react-router-dom';
import './welcomeUBaid.css';
import stripe_logo from '../img/stripe_logo.png';
import { v4 as uuidv4 } from 'uuid'

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing.unit,
  },
  bootstrapFormLabel: {
    fontSize: 18,
  },
});

class Create_User_Company_Container extends React.Component {
  constructor(props) {
    super(props);
  }
  static contextType = AppContext;
  render() {
    return (
      <Create_User_Company
        {...this.props}
        {...this.context} />
    )
  }
}

class Create_User_Company extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: new URL(window.location.href, true),
      uuid:uuidv4(),
      email: '',
      password: '',
      first_name: "",
      last_name: '',
      company_name: "",
      company_type: "",
      csrfToken:"",
      loaded:false
    }
  }

  componentDidMount(){
    if (this.state.url.query.state!==undefined){
      console.log("trying again")
      axios.get(`/stripe_confirmation_req?state=${this.state.url.query.state}&code=${this.state.url.query.code}&uuid=${this.state.uuid}`).then((response)=>{
        console.log(response)
        this.setState({
          loaded:true
        })
      })
    }

  }

  componentDidUpdate(){
    if (!this.state.loaded){
      axios.get(`/stripe_confirmation_req?state=${this.state.url.query.state}&code=${this.state.url.query.code}&uuid=${this.state.uuid}`).then((response)=>{
        console.log(response)
        this.setState({
          loaded:true
        })
      })
    }
  }

  handleChange = (e) => {
    const name = e.target.name;
    this.setState({
      [name]: e.target.value
    }, () => {
      if (name === "address") {
        this.getLocation()
      }
    })
  }

  handleChangeMultiple = (e) => {
    const name = e.target.name;
    let selected_values = []
    Array.from(e.target.selectedOptions).forEach(function (item) {
      selected_values.push(item.value);
    });
    this.setState({
      [name]: selected_values
    })
  }
  getStage = (value, array) => {
    for (let i = 0; i < array.length; i++) {
      if (array[i].value === value) {
        return array[i].text
      }
    }
  }


  render() {
    return (

      <React.Fragment>
        <SiteTitle />
        <Navigation />
        <Sign_In />
        <Register />
        <SideMenu />
        <Forgot_Password />
        <div class="container ubaidWelcomePageParent">
          <div class="main ubaidWelcomePage">
            <div class="panel-heading">
              <div class="panel-title text-center">
                <h1 class="title" style={{ fontWeight: "500" }}>Successfully connected to</h1>
                <div style={{ fontStyle: "italic" }} className='StartupAimUBaid'><img style={{width:"50%"}} src={stripe_logo}/></div>
                <h3 style={{ fontWeight: "400" }}> Getting Started:</h3>


                <div className='WelcomePageDescr'>
                  <div className='WelcomeUBaidTdSr'>1.</div>
                  <div>Now you can start to accept money from people on StartupFuel through your Stripe account!</div>
                </div>

                <div className='WelcomePageDescr'>
                  <div className='WelcomeUBaidTdSr'>2.</div>
                  <div>Start messaging companies and tell them to use your service.</div>
                </div>
                <div className='WelcomePageDescr'>
                  <div className='WelcomeUBaidTdSr'>3.</div>
                  <div>They can either hire you and start a new project or they can base a project off a service that you have listed on your profile.</div>
                </div>
                <div className='WelcomePageDescr'>
                  <div className='WelcomeUBaidTdSr'>4.</div>
                  <div>If you were invited to a project you can now accept the project by going back to your <a href={"/projects"}>projects</a>.</div>
                </div>
                <div className='WelcomePageDescr'>
                  <div className='WelcomeUBaidTdSr'>5.</div>
                  <div>StartupFuel takes 10% commission on all transactions.</div>
                </div>
                <div className='WelcomePageDescr'>
                  <div className='WelcomeUBaidTdSr'>6.</div>
                  <div>The more money you generate on StartupFuel, the more you get promoted in various directories.</div>
                </div>






              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}
Create_User_Company_Container.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(Create_User_Company_Container);
