import React, { Component } from "react";
import axios from "axios";
import URL from "url-parse";
import { Success_Popup } from "../../../components/popups";
import Chip from "@material-ui/core/Chip";
import Select from "@material-ui/core/Select";
import Switch from "@material-ui/core/Switch";
import MenuItem from "@material-ui/core/MenuItem";
import ArgNav from "../argNav";
import Navigation from "../../../components/navbar_pb";
import SettingsNavigation from "./settings-sidenav";
import "./index.scss";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

class Page extends Component {
  constructor(props) {
    super(props);

    this.state = {
      url: new URL(window.location.href, true),
      judges: [1],
      showRelevancySettings: true,
      showOtherSettings: false,
      target_industries: [],
      target_model_companies: [],
      target_distinctions: [],
      target_stage_companies: [],
      geographical_location: [],
      start_intake_date: "",
      end_intake_date: "",
      website_url: "",
      opportunity_description: "",
      max_cheque_size: "",
      min_cheque_size: "",
      prize: "",
      venue_location: "",
      background_cover: null,
      has_applicant_room: false,
      show_success_popup: false,
      success_msg: "Pitchbox successfully updated",
      isSubmitting: false,
    };
  }

  componentDidMount() {
    axios
      .get(
        `fetch_opportunity?opportunity_id=${this.state.url.query.opportunity_id}`,
      )
      .then((response) => {
        const cleanArray = (arr) =>
          Array.isArray(arr) ? arr.filter((item) => item && item !== "") : [];

        this.setState({
          ...response.data.result,
          target_industries: cleanArray(response.data.result.target_industries),
          target_model_companies: cleanArray(
            response.data.result.target_model_companies,
          ),
          target_distinctions: cleanArray(
            response.data.result.target_distinctions,
          ),
          target_stage_companies: cleanArray(
            response.data.result.target_stage_companies,
          ),
          geographical_location: cleanArray(
            response.data.result.geographical_location,
          ),
          has_applicant_room: response.data.result.has_applicant_room || false,
          opportunity_data: response.data.result,
        });
      });
  }

  // List of Industries
  industries = [
    { value: "Agriculture", title: "Agriculture" },
    { value: "AI/ML", title: "AI/ML" },
    { value: "Blockchain", title: "Blockchain" },
    { value: "Cannabis", title: "Cannabis" },
    { value: "Construction", title: "Construction" },
    { value: "COVID-19", title: "COVID-19" },
    { value: "Data", title: "Data" },
    { value: "E-Commerce", title: "E-Commerce" },
    { value: "Education", title: "Education" },
    { value: "Employment", title: "Employment" },
    { value: "Energy", title: "Energy" },
    { value: "Enterprise Software", title: "Enterprise Software" },
    { value: "Environmental", title: "Environmental" },
    { value: "Fashion", title: "Fashion" },
    { value: "Fintech", title: "Fintech" },
    { value: "Fitness", title: "Fitness" },
    { value: "Food", title: "Food" },
    { value: "Gaming", title: "Gaming" },
    { value: "Healthcare", title: "Healthcare" },
    { value: "Hospitality", title: "Hospitality" },
    { value: "IoT", title: "IoT" },
    { value: "Legal", title: "Legal" },
    { value: "Lifestyle", title: "Lifestyle" },
    { value: "Manufacturing", title: "Manufacturing" },
    { value: "Marketing", title: "Marketing" },
    { value: "Media", title: "Media" },
    { value: "Photography", title: "Photography" },
    { value: "PropTech", title: "PropTech" },
    { value: "Real Estate", title: "Real Estate" },
    { value: "Retail", title: "Retail" },
    { value: "Robotics", title: "Robotics" },
    { value: "Security", title: "Security" },
    { value: "Social Media", title: "Social Media" },
    { value: "Space", title: "Space" },
    { value: "Sports", title: "Sports" },
    { value: "Telecom", title: "Telecom" },
    { value: "Transportation", title: "Transportation" },
    { value: "Travel", title: "Travel" },
    { value: "Other", title: "Other" },
  ];

  // List of Business Models
  businessModels = [
    { value: "B2B", title: "Business to Business" },
    { value: "B2C", title: "Business to Consumer" },
    { value: "B2G", title: "Business to Government" },
    { value: "C2C", title: "Consumer to Consumer" },
  ];

  // List of Business Stages
  businessStages = [
    {
      value: 1.1,
      title: "Pre-Seed",
      subtitle: "< $150K",
      description:
        "A Pre-Seed round is a pre-institutional seed round that either has no institutional investors or is a very low amount, often below $150k.",
    },
    {
      value: 1.2,
      title: "Seed",
      subtitle: "$150k - $2M",
      description:
        "Seed rounds are among the first rounds of funding a company will receive, generally while the company is young and working to gain traction.",
    },
    {
      value: 2.1,
      title: "Series A",
      subtitle: "$1M–$30M.",
      description:
        "Series A rounds are funding rounds for earlier stage companies and range on average between $1M–$30M.",
    },
    {
      value: 2.2,
      title: "Series B",
      subtitle: "$1M–$30M.",
      description:
        "Series B rounds are similar to Series A, they are just the second round.",
    },
    {
      value: 2.3,
      title: "Series C",
      subtitle: "$10M+.",
      description:
        "Series C rounds and onwards are for later stage and more established companies.",
    },
    {
      value: 3,
      title: "IPO",
      subtitle: "",
      description:
        "These are public companies that can be found on the stock market.",
    },
  ];

  // List of Diversity Components
  diversityComponents = [
    { value: "female_founder", title: "Female Founders" },
    { value: "aboriginal", title: "Aboriginal Founders" },
    { value: "minority", title: "Visible Minority Founder" },
    { value: "lgbtq", title: "LGBTQ+ Founders" },
    { value: "veteran", title: "Veteran Founders" },
    { value: "disabled", title: "Founders of Disability" },
    { value: "Other", title: "Other" },
    { value: "Not Applicable", title: "Not Applicable" },
  ];

  // List of Countries (example list, you should complete it with the full list of countries)
  countries = [
    "Antigua and Barbuda",
    "Argentina",
    "Aruba",
    "Austria",
    "Australia",
    "Bahamas",
    "Bangladesh",
    "Barbados",
    "Belize",
    "Brazil",
    "British Virgin Islands",
    "Belgium",
    "Cameroon",
    "Canada",
    "Cayman Islands",
    "Chile",
    "China",
    "Croatia",
    "Colombia",
    "Costa Rica",
    "Cuba",
    "Curacao",
    "Denmark",
    "Dominica",
    "Dominican Republic",
    "Egypt",
    "Estonia",
    "Finland",
    "France",
    "Germany",
    "Ghana",
    "Greece",
    "Grenada",
    "Guyana",
    "Haiti",
    "Honduras",
    "Hong Kong",
    "India",
    "Indonesia",
    "Iran",
    "Ireland",
    "Israel",
    "Italy",
    "Japan",
    "Jamaica",
    "Kenya",
    "Lithuania",
    "Malaysia",
    "Martinique",
    "Mexico",
    "Nepal",
    "Netherlands",
    "New Zealand",
    "Nicaragua",
    "Nigeria",
    "Norway",
    "Pakistan",
    "Peru",
    "Philippines",
    "Poland",
    "Portugal",
    "Romania",
    "Russia",
    "Saudi Arabia",
    "Saint Kitts and Nevis",
    "Singapore",
    "South Africa",
    "South Korea",
    "Spain",
    "Sri Lanka",
    "Suriname",
    "Sweden",
    "Switzerland",
    "Taiwan",
    "Thailand",
    "Trinidad & Tobago",
    "Turkey",
    "Turks and Caicos Islands",
    "UAE",
    "Uganda",
    "Ukraine",
    "United States",
    "United Kingdom",
    "Uruguay",
    "US Virgin Islands",
    "Venezula",
    "Vietnam",
  ];

  displaySaved = () => {
    this.setState(
      {
        show_success_popup: true,
        isSubmitting: false,
      },
      () => {
        setTimeout(() => this.setState({ show_success_popup: false }), 1500);
      },
    );
  };

  handleSwitchChange = () => {
    this.setState((prevState) => ({
      has_applicant_room: !prevState.has_applicant_room,
    }));
  };

  handleMultiSelectChange = (event, field) => {
    const {
      target: { value },
    } = event;
    this.setState({
      [field]: typeof value === "string" ? value.split(",") : value,
    });
  };

  handleDiversitySelectChange = (event) => {
    const {
      target: { value },
    } = event;
    this.setState({
      target_distinctions: value.map((item) => item.value),
    });
  };

  handleSingleSelectChange = (event, field) => {
    this.setState({
      [field]: [event.target.value],
    });
  };

  handleInputChange = (event, field) => {
    let value = event.target.value;

    // If the field is one of the date fields, convert it to ISO format
    const dateFields = ["start_intake_date", "end_intake_date"];
    if (dateFields.includes(field)) {
      value = new Date(value).toISOString();
    }

    this.setState({
      [field]: value,
    });
  };

  // Function to format date to 'YYYY-MM-DDTHH:MM'
  formatDateTimeLocal = (dateTime) => {
    if (!dateTime) return "";
    const date = new Date(dateTime);
    const offset = date.getTimezoneOffset();
    const adjustedDate = new Date(date.getTime() - offset * 60 * 1000);
    return adjustedDate.toISOString().slice(0, 16);
  };

  validateForm = () => {
    const {
      start_intake_date,
      end_intake_date,
      min_cheque_size,
      max_cheque_size,
    } = this.state;

    if (new Date(start_intake_date) > new Date(end_intake_date)) {
      alert("Application start date cannot be after the application end date.");
      return false;
    }

    if (parseFloat(min_cheque_size) > parseFloat(max_cheque_size)) {
      alert(
        "Minimum cheque size cannot be greater than the maximum cheque size.",
      );
      return false;
    }

    return true;
  };

  handleSave = () => {
    if (!this.validateForm()) {
      return;
    }

    this.setState({ isSubmitting: true }); // Set loading state

    const { background_cover } = this.state;

    // Function to proceed with the rest of the form submission
    const submitForm = () => {
      const { url, background_cover } = this.state;

      // Clean up arrays by filtering out null or empty string values
      const cleanArray = (arr) => arr.filter((item) => item && item !== "");
      const cleanValue = (value) =>
        value !== null &&
        value !== undefined &&
        value !== "" &&
        (!Array.isArray(value) || value.length > 0);

      const data = {};

      if (cleanArray(this.state.target_industries).length) {
        data.target_industries = cleanArray(this.state.target_industries);
      }
      if (cleanArray(this.state.target_model_companies).length) {
        data.target_model_companies = cleanArray(
          this.state.target_model_companies,
        );
      }
      if (cleanArray(this.state.target_stage_companies).length) {
        data.target_stage_companies = cleanArray(
          this.state.target_stage_companies,
        );
      }
      if (cleanArray(this.state.target_distinctions).length) {
        data.target_distinctions = cleanArray(this.state.target_distinctions);
      }
      if (cleanArray(this.state.geographical_location).length) {
        data.geographical_location = cleanArray(
          this.state.geographical_location,
        );
      }

      // Submit the data as JSON
      axios
        .post(
          `/edit_opportunity_data?opportunity_id=${url.query.opportunity_id}`,
          data,
          {
            headers: {
              "Content-Type": "application/json",
            },
          },
        )
        .then((response) => {
          if (response.data.status === "success") {
            this.displaySaved();
          } else {
            console.error("Error updating opportunity:", response.data.message);
          }
          this.setState({ isSubmitting: false }); // Reset loading state on success or error
        })
        .catch((error) => {
          console.error("Error:", error);
          this.setState({ isSubmitting: false }); // Reset loading state on error
        });
    };

    submitForm();
  };

  render() {
    const {
      target_industries,
      target_model_companies,
      target_stage_companies,
      geographical_location,
      isSubmitting,
    } = this.state;

    return (
      <div>
        <Navigation opportunity_data={this.state.opportunity_data} />
        <ArgNav
          opportunity_name={this.props.opportunity_name}
          opportunity_id={this.state.url.query.opportunity_id}
          pathname={this.state.url.pathname}
        />
        <div className="_content">
          <React.Fragment>
            <Success_Popup
              show={this.state.show_success_popup}
              alert_message={this.state.success_msg}
            />
            <div className="__application-room-settings">
              <div className="__header  __application-room-padding">
                <h3>Fit Score Settings</h3>
              </div>
              <div className="__pb_settings_page">
                <SettingsNavigation />

                {/* Combined Section Wrapper */}
                <div className="__main __application-room-padding">
                  <div style={{ marginBottom: "16px" }}>
                    <p
                      className="__settings_text"
                      style={{ marginBottom: "4px" }}
                    >
                      Target Applicants Industry:
                    </p>
                    <Select
                      multiple
                      value={target_industries}
                      onChange={(event) =>
                        this.handleMultiSelectChange(event, "target_industries")
                      }
                      renderValue={(selected) => (
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                          {selected.map((value) => (
                            <Chip
                              key={value}
                              label={value}
                              style={{ margin: 2 }}
                            />
                          ))}
                        </div>
                      )}
                      style={{ display: "block", width: "100%" }}
                    >
                      {this.industries.map((industry) => (
                        <MenuItem key={industry.value} value={industry.value}>
                          {industry.title}
                        </MenuItem>
                      ))}
                    </Select>
                    <p
                      className="__settings_text"
                      style={{ marginBottom: "4px" }}
                    >
                      Target Model Companies:
                    </p>
                    <Select
                      multiple
                      value={target_model_companies}
                      onChange={(event) =>
                        this.handleMultiSelectChange(
                          event,
                          "target_model_companies",
                        )
                      }
                      renderValue={(selected) => (
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                          {selected.map((value) => (
                            <Chip
                              key={value}
                              label={value}
                              style={{ margin: 2 }}
                            />
                          ))}
                        </div>
                      )}
                      style={{ display: "block", width: "100%" }}
                    >
                      {this.businessModels.map((model) => (
                        <MenuItem key={model.value} value={model.value}>
                          {model.title}
                        </MenuItem>
                      ))}
                    </Select>

                    <p
                      className="__settings_text"
                      style={{ marginBottom: "4px", marginTop: "16px" }}
                    >
                      Target Diversity Components:
                    </p>
                    <Select
                      multiple
                      value={this.state.target_distinctions.map((val) =>
                        this.diversityComponents.find(
                          (comp) => comp.value === val,
                        ),
                      )}
                      onChange={this.handleDiversitySelectChange}
                      renderValue={(selected) => (
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                          {selected.map((item) => (
                            <Chip
                              key={item.value}
                              label={item.title}
                              style={{ margin: 2 }}
                            />
                          ))}
                        </div>
                      )}
                      style={{ display: "block", width: "100%" }}
                    >
                      {this.diversityComponents.map((component) => (
                        <MenuItem key={component.value} value={component}>
                          {component.title}
                        </MenuItem>
                      ))}
                    </Select>

                    <p
                      className="__settings_text"
                      style={{ marginBottom: "4px", marginTop: "16px" }}
                    >
                      Target Stage Companies:
                    </p>
                    <Select
                      value={target_stage_companies[0] || ""}
                      onChange={(event) =>
                        this.handleSingleSelectChange(
                          event,
                          "target_stage_companies",
                        )
                      }
                      style={{ display: "block", width: "100%" }}
                    >
                      <option value="">Select Business Stage</option>
                      {this.businessStages.map((stage) => (
                        <MenuItem key={stage.value} value={stage.value}>
                          {stage.title} - {stage.subtitle}
                        </MenuItem>
                      ))}
                    </Select>

                    <p
                      className="__settings_text"
                      style={{ marginBottom: "4px", marginTop: "16px" }}
                    >
                      Target Geographical Location:
                    </p>
                    <Select
                      multiple
                      value={geographical_location}
                      onChange={(event) =>
                        this.handleMultiSelectChange(
                          event,
                          "geographical_location",
                        )
                      }
                      renderValue={(selected) => (
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                          {selected.map((value) => (
                            <Chip
                              key={value}
                              label={value}
                              style={{ margin: 2 }}
                            />
                          ))}
                        </div>
                      )}
                      style={{ display: "block", width: "100%" }}
                    >
                      {this.countries.map((country) => (
                        <MenuItem key={country} value={country}>
                          {country}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>

                  {/* Save Button */}
                  <button
                    onClick={this.handleSave}
                    disabled={isSubmitting}
                    style={{
                      marginTop: "20px",
                      padding: "10px 20px",
                      backgroundColor: "#007bff",
                      color: "white",
                      border: "none",
                      borderRadius: "4px",
                      cursor: isSubmitting ? "not-allowed" : "pointer",
                      width: "100%",
                    }}
                  >
                    {isSubmitting ? "Saving..." : "Save"}
                  </button>
                </div>
              </div>
            </div>
          </React.Fragment>
        </div>
      </div>
    );
  }
}

export default Page;
