/* (2019-06-10) - This page is used as the primary registration page for the site.
It allows the simoltaneous registration of both a user and a company. Upon
registering it redirects you to a welcome page.
*/
import React from "react";
import axios from "axios";
import "../main.css";
import {
  Button,
  Alert,
  Form,
  FormControl,
  FormLabel,
  Radio,
  Row,
} from "react-bootstrap";
import PropTypes from "prop-types";

import FormGroup from "@material-ui/core/FormGroup";
import { Helmet } from "react-helmet";
import URL from "url-parse";
import {
  company_type_list,
  industry_list,
  stage_list,
  business_type,
} from "../boilerplate/list.json";
import Navigation from "./components/navbar";
import Footer from "./components/footer_side";
import SiteTitle from "./components/site_title.js";
import {
  Sign_In,
  Register,
  Forgot_Password,
  Alert_Message_To_User,
} from "./components/popups";
import waterfall from "async/waterfall";
import { AppContext } from "../App";

import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";

import InputLabel from "@material-ui/core/InputLabel";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputBase from "@material-ui/core/InputBase";
import { withStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input";
import Chip from "@material-ui/core/Chip";
import Icon from "@material-ui/core/Icon";
import classNames from "classnames";
import TextField from "@material-ui/core/TextField";
import Geocode from "react-geocode";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing.unit * 3,
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    width: "auto",
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

const styles = (theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing.unit,
  },
  bootstrapFormLabel: {
    fontSize: 18,
  },
});

class Create_User_Company_Container extends React.Component {
  constructor(props) {
    super(props);
  }
  static contextType = AppContext;
  render() {
    return <Create_User_Company {...this.props} {...this.context} />;
  }
}

class Create_User_Company extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: new URL(window.location.href, true),
      email: "",
      password: "",
      first_name: "",
      last_name: "",
      company_name: "",
      company_type: "",
      street_number: "",
      referral_partners: [],
      referred_by: null,
      short_bio: "",
      website: "",
      street_name: "",
      city: "",
      province: "Ontario",
      country: "Canada",
      company_type: "",
      industry: ["Fintech"],
      address: "",
      stage: 1,
      business_model: "B2B",
      formIsValids: false,
      errors: {},
      showButtonLoader: false,
      old_address: "",
      incubator_accelerator_history: "",
      linkedIn: "",
      contact_phone: "",
      raising_funds: "",
      fintech_sub_vertical: "",
      finance_mtl_q1: "",
      finance_mtl_q2: "",
      finance_mtl_q3: "",
      finance_mtl_q4: "",
      settings: {
        weeklyOpportunities: true,
        weeklyServices: true,
      },
    };
  }

  componentDidMount() {
    axios.get("fetch_referral_partners").then((response) => {
      this.setState({
        referral_partners: response.data.referral_partners,
      });
    });
  }

  handleChange = (e) => {
    const name = e.target.name;
    this.setState(
      {
        [name]: e.target.value,
      },
      () => {
        if (name === "address") {
          this.getLocation();
        }
      }
    );
  };

  handleChangeMultiple = (e) => {
    const name = e.target.name;
    let selected_values = [];
    Array.from(e.target.selectedOptions).forEach(function (item) {
      selected_values.push(item.value);
    });
    this.setState({
      [name]: selected_values,
    });
  };
  getStage = (value, array) => {
    for (let i = 0; i < array.length; i++) {
      if (array[i].value === value) {
        return array[i].text;
      }
    }
  };
  getReferrer = (value, array) => {
    for (let i = 0; i < array.length; i++) {
      if (array[i]._id === value) {
        return `${array[i].first_name} ${array[i].last_name}`;
      }
    }
  };
  validateResult = (data, callback) => {
    if (data.status === "success" || data.status === "partial") {
      callback(data, this);
    } else if (data.status === "redirect") {
      this.props.history.push(data.redirect_to);
    } else if (data.status === "error") {
    }
  };
  handleValidation = (event) => {
    this.setState({
      showButtonLoader: !this.state.showButtonLoader,
    });
    let errors = {};
    let formIsValid = true;

    //Opportunity Name
    if (this.state.first_name.length === 0) {
      formIsValid = false;
      errors["first"] = " Please Enter an your first Name";
    }
    if (this.state.last_name.length === 0) {
      formIsValid = false;
      errors["last"] = " Please Enter an your last Name";
    }
    if (this.state.password.length === 0) {
      formIsValid = false;
      errors["password"] = " Cannot be empty";
    }
    if (this.state.company_name.length === 0) {
      formIsValid = false;
      errors["company_name"] = " Cannot be empty";
    }
    if (this.state.company_type.length === 0) {
      formIsValid = false;
      errors["company_type"] = " Cannot be empty";
    }
    if (this.state.industry.length === 0) {
      formIsValid = false;
      errors["industry"] = " Cannot be empty";
    }
    if (this.state.stage === 1) {
      formIsValid = false;
      errors["company_stage"] = " Cannot be empty";
    }
    if (this.state.address.length === 0) {
      formIsValid = false;
      errors["location"] = " Cannot be empty";
    }

    if (this.state.email.length === 0) {
      formIsValid = false;
      errors["email"] = " Cannot be empty";
    }

    if (typeof this.state.email !== "undefined") {
      let lastAtPos = this.state.email.lastIndexOf("@");
      let lastDotPos = this.state.email.lastIndexOf(".");

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          this.state.email.indexOf("@@") == -1 &&
          lastDotPos > 2 &&
          this.state.email.length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        errors["email"] =
          " Email is not valid, please structure it like 'user@example.com'";
      }
    }

    this.setState(
      {
        errors: errors,
      },
      () => {
        if (formIsValid) {
          this.setState(
            {
              formIsValids: true,
            },
            () => {
              this.handleInputSubmit(event);
            }
          );
        }
      }
    );
  };

  handleInputSubmit = (e) => {
    e.preventDefault();
    if (this.state.formIsValids) {
      waterfall(
        [
          (callback) => {
            axios
              .post("/create_user", {
                first_name: this.state.first_name,
                last_name: this.state.last_name,
                email: this.state.email,
                referred_by: this.state.referred_by,
                password: this.state.password,
              })
              .then((result) => {
                if (result.data.status === "existing user") {
                  let errors = {};
                  let formIsValid = true;
                  errors["email"] = " User already exists";
                  this.setState({
                    errors: errors,
                  });
                }
                if (result.data.status !== "existing user") {
                  callback(null, result.data);
                }
              });
          },
          (user, callback) => {
            axios
              .post("/create_company", {
                user_id: user._id,
                company_name: this.state.company_name,
                company_type: this.state.company_type,
                street_number: this.state.street_number,
                short_bio: this.state.short_bio,
                website: this.state.website,
                linkedIn: this.state.linkedIn,
                street_name: this.state.street_number,
                address: this.state.address,
                city: this.state.city,
                province: this.state.province,
                country: this.state.country,
                industry: this.state.industry,
                stage: this.state.stage,
                business_model: this.state.business_model,
                address: this.state.address,
                formatted_address: this.state.formatted_address,
              })
              .then((result) => {
                callback(null, {
                  user: user.data,
                  company: result.data,
                });
              });
          },
        ],
        (err, done) => {
          if (err) console.log(err);
          this.props.persistUser(done.user);
          this.setState({
            showButtonLoader: false,
          });
          //this.props.history.push(`profile?company_id=${done.company.result._id}`)
          this.props.history.push(`/welcome`);
        }
      );
    }
  };

  getLocation = () => {
    Geocode.setApiKey("AIzaSyAo8kBbOGTAr2C5kFNic3fZYUxA7KYNp7M");

    // Enable or disable logs. Its optional.
    Geocode.enableDebug();

    // Get latidude & longitude from address.
    if (this.state.old_address !== this.state.address) {
      this.setState(
        {
          old_address: this.state.address,
        },
        () => {
          Geocode.fromAddress(this.state.address).then(
            (response) => {
              const address_array =
                response.results[0].formatted_address.split(", ");
              const { lat, lng } = response.results[0].geometry.location;
              if (address_array.length === 4) {
                this.setState({
                  location_processed: 1,
                  lat: lat,
                  long: lng,
                  city: address_array[1],
                  province: address_array[2],
                  country: address_array[3],
                  formatted_address: response.results[0].formatted_address,
                });
              } else if (address_array.length === 3) {
                this.setState({
                  location_processed: 1,
                  lat: lat,
                  long: lng,
                  city: address_array[0],
                  province: address_array[1],
                  country: address_array[2],
                  formatted_address: response.results[0].formatted_address,
                });
              }
            },
            (error) => {
              console.error(error);
            }
          );
        }
      );
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <SiteTitle />
        <Navigation />
        <Sign_In />
        <Register />
        <Forgot_Password />
        {this.props.isLoggedIn === false ? (
          <div class="container">
            <div class="main" style={{ marginTop: "unset" }}>
              <div class="main-login main-center form-horizontal mb-5">
                <h3 className="text-center">Account Owner Info:</h3>
                <hr />
                <div className="form-gorup">
                  <FormLabel className="control-label">First Name</FormLabel>
                  <span className="text-danger">
                    {this.state.errors["first"]}
                  </span>
                  <FormControl
                    type="text"
                    value={this.state.first_name}
                    placeholder="First Name"
                    name="first_name"
                    onChange={this.handleChange}
                    className="form-control mb-3"
                  />
                </div>
                <FormLabel className="control-label">Last Name</FormLabel>
                <span className="text-danger">{this.state.errors["last"]}</span>
                <FormControl
                  type="text"
                  value={this.state.last_name}
                  placeholder="Last Name"
                  name="last_name"
                  onChange={this.handleChange}
                  className="form-control mb-3"
                />
                <FormLabel className="control-label">Email</FormLabel>
                <span className="text-danger">
                  {this.state.errors["email"]}
                </span>
                <FormControl
                  type="email"
                  value={this.state.email}
                  placeholder="User Email"
                  name="email"
                  onChange={this.handleChange}
                  className="form-control mb-3"
                />
                <FormLabel className="control-label">Password</FormLabel>
                <span className="text-danger">
                  {this.state.errors["password"]}
                </span>
                <FormControl
                  type="password"
                  value={this.state.password}
                  placeholder="User Password"
                  name="password"
                  onChange={this.handleChange}
                  className="form-control mb-3"
                />
                <FormLabel className="control-label">
                  Referred by? (Optional)
                </FormLabel>
                <Select
                  value={this.state.referred_by}
                  onChange={this.handleChange}
                  input={<Input id="select-multiple-chip" />}
                  name="referred_by"
                  style={{ display: "inherit" }}
                  className={classes.textField}
                  renderValue={(selected) => (
                    <div>
                      <MenuItem
                        key={this.state.referred_by}
                        value={this.state.referred_by}
                      >
                        {this.getReferrer(
                          this.state.referred_by,
                          this.state.referral_partners
                        )}
                      </MenuItem>
                    </div>
                  )}
                  MenuProps={MenuProps}
                >
                  {this.state.referral_partners.map((partner) => (
                    <MenuItem key={partner.first_name} value={partner._id}>
                      {partner.first_name}
                      {partner.last_name}
                    </MenuItem>
                  ))}
                </Select>
                <h3 className="text-center">Company Info:</h3>
                <hr />
                <div className="form-gorup">
                  <FormLabel className="control-label">Company Name</FormLabel>
                  <span className="text-danger">
                    {this.state.errors["company_name"]}
                  </span>
                  <FormControl
                    type="text"
                    value={this.state.company_name}
                    placeholder="Company Name"
                    name="company_name"
                    onChange={this.handleChange}
                    className="form-control mb-3"
                  />
                </div>
                <div className="form-gorup">
                  <FormLabel className="control-label">Company Type</FormLabel>
                  <span className="text-danger">
                    {this.state.errors["company_type"]}
                  </span>
                  <br />
                  <Select
                    value={this.state.company_type}
                    onChange={this.handleChange}
                    input={<Input id="select-multiple-chip" />}
                    name="company_type"
                    style={{ display: "inherit" }}
                    className={classes.textField}
                    renderValue={(selected) => (
                      <div>
                        <MenuItem
                          key={this.state.company_type}
                          value={this.state.company_type}
                        >
                          {this.state.company_type}
                        </MenuItem>
                      </div>
                    )}
                    MenuProps={MenuProps}
                  >
                    {company_type_list.map((name) => (
                      <MenuItem key={name} value={name}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <br />
                {/*
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 <FormLabel className="control-label">Short Bio (Optional)</FormLabel>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 <br/>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 <TextField
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                   id="outlined-multiline-static"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                   label="Please provide a short biography"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                   multiline
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                   rows="4"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                   className={classes.textField}
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                   margin="normal"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                   variant="outlined"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                   value={this.state.short_bio}
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                   name='short_bio'
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                   onChange={this.handleChange}
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                   style={{width:'100%'}}
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 />
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 <br/>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 <FormLabel className="control-label">Company Website (Optional)</FormLabel>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 <FormControl
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                   type="text"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                   value={this.state.website}
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                   placeholder="www.yourwebsite.com"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                   name='website'
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                   onChange={this.handleChange}
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                   className="form-control mb-3"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 />
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 */}
                <FormLabel className="control-label">
                  Company HQ (If you do not have an office address array, just
                  enter the city you reside in.)
                </FormLabel>
                <span className="text-danger">
                  {this.state.errors["location"]}
                </span>
                <br />
                <Icon
                  className={classNames(
                    classes.iconHover,
                    "fas fa-map-marker-alt"
                  )}
                  color="error"
                  style={{
                    fontSize: 30,
                    margin: "3px",
                    width: "50px",
                    paddingLeft: "15px",
                  }}
                />
                <input
                  type="text"
                  style={{ width: "80%" }}
                  name="address"
                  value={this.state.address}
                  onChange={this.handleChange}
                />
                <br />
                <div className="form-gorup">
                  <FormLabel className="control-label">
                    {" "}
                    Business Model
                  </FormLabel>
                  <br />
                  <Select
                    value={this.state.business_model}
                    onChange={this.handleChange}
                    input={<Input id="select-multiple-chip" />}
                    name="business_model"
                    className={classes.textField}
                    renderValue={(selected) => (
                      <div>
                        <MenuItem
                          key={this.state.business_model}
                          value={this.state.business_model}
                        >
                          {this.state.business_model}
                        </MenuItem>
                      </div>
                    )}
                    MenuProps={MenuProps}
                  >
                    {business_type.map((name) => (
                      <MenuItem key={name} value={name}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <br />
                <InputLabel htmlFor="select-multiple-chip">
                  Industries
                </InputLabel>
                <span className="text-danger">
                  {this.state.errors["industry"]}
                </span>
                <br />
                <Select
                  multiple
                  value={this.state.industry}
                  onChange={this.handleChange}
                  input={<Input id="select-multiple-chip" />}
                  name="industry"
                  style={{ width: "95%" }}
                  className={classes.textField}
                  renderValue={(selected) => (
                    <div className={classes.chips}>
                      {selected.map((value) => (
                        <Chip
                          key={value}
                          label={value}
                          className={classes.chip}
                        />
                      ))}
                    </div>
                  )}
                  MenuProps={MenuProps}
                >
                  {industry_list.map((name) => (
                    <MenuItem key={name} value={name}>
                      <Checkbox
                        checked={this.state.industry.indexOf(name) > -1}
                      />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
                <div className="form-gorup">
                  <br />
                  <FormLabel className="control-label">Company Stage</FormLabel>
                  <span className="text-danger">
                    {this.state.errors["company_stage"]}
                  </span>
                  <br />
                  <Select
                    value={this.state.stage}
                    onChange={this.handleChange}
                    input={<Input id="select-multiple-chip" />}
                    name="stage"
                    style={{ display: "inherit" }}
                    className={classes.textField}
                    renderValue={(selected) => (
                      <div>
                        <MenuItem
                          key={this.state.stage}
                          value={this.state.stage}
                        >
                          {this.getStage(this.state.stage, stage_list)}
                        </MenuItem>
                      </div>
                    )}
                    MenuProps={MenuProps}
                  >
                    {stage_list.map((name) => (
                      <MenuItem key={name.text} value={name.value}>
                        {name.text}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <Button
                  type="submit"
                  onClick={this.handleValidation}
                  className="btn btn-primary btn-lg btn-block login-button mt-3"
                  id="user-registration-button"
                >
                  Submit
                </Button>
                {Object.keys(this.state.errors).length >= 1 && (
                  <p style={{ color: "red" }}>
                    Please fix the errors listed above
                  </p>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div>You have already registered.</div>
        )}
      </React.Fragment>
    );
  }
}
Create_User_Company_Container.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(Create_User_Company_Container);
