import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import {
  FormControl,
  FormLabel,
  Button,
  Form,
  Row,
  Col,
  Card,
} from "react-bootstrap";
import update from "immutability-helper";
import URL from "url-parse";

import {
  company_type_list,
  industry_list,
  stage_list,
  business_type,
  vertical_list,
  type_of_service_provider,
  product_categories,
} from "../../../boilerplate/list.json";
import popupPic from "../../../img/rocket.png";
import developmentPic from "../../../img/development_icon.png";
import Grid from "@material-ui/core/Grid";
import { AppContext } from "../../../App";
import axios from "axios";
import waterfall from "async/waterfall";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import FormGroup from "@material-ui/core/FormGroup";
import CardMedia from "@material-ui/core/CardMedia";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input";
import Icon from "@material-ui/core/Icon";
import classNames from "classnames";
import InputLabel from "@material-ui/core/InputLabel";
import Chip from "@material-ui/core/Chip";
import ListItemText from "@material-ui/core/ListItemText";
import { SocialIcon } from "react-social-icons";
import VideoIcon from "@material-ui/icons/Videocam";
import ImageIcon from "@material-ui/icons/Image";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function getStyles(name, that) {
  return {
    fontWeight:
      that.state.name.indexOf(name) === -1
        ? that.props.theme.typography.fontWeightRegular
        : that.props.theme.typography.fontWeightMedium,
  };
}
const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: theme.spacing.unit / 4,
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 100,
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 100,
  },
});

const currencies = [
  {
    value: "USD",
    label: "$",
  },
  {
    value: "EUR",
    label: "€",
  },
  {
    value: "BTC",
    label: "฿",
  },
  {
    value: "JPY",
    label: "¥",
  },
];

export class Create_Product_Popup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formIsValids: false,
      subject: "",
      message: "",
      errors: {},
    };
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps !== this.props &&
      this.props.subject !== undefined &&
      this.props.subject.length > 0
    ) {
      this.setState({
        subject: this.props.subject,
      });
    }
  }

  handleChange = (e) => {
    const name = e.target.name;
    this.setState({
      [name]: e.target.value,
    });
  };
  handleToggle = (e) => {
    const name = e.target.name;
    this.setState({
      [name]: !this.state[name],
    });
  };
  handleFileUpload = (e) => {
    let file = e.target.files[0];
    this.setState({
      productImageDetails: {
        file,
        file_name: "product avatar",
        file_type: `product ${e.target.dataset.fileType}`,
        object_ref: "product",
        object_ref_value: this.props.url.query.company_id,
      },
    });
  };

  handleValidation = (event) => {
    let errors = {};
    let formIsValid = true;

    //Opportunity Name
    if (this.state.subject.length === 0) {
      formIsValid = false;
      errors["subject"] = " Please enter a subject to discuss.";
    }
    if (this.state.message.length === 0) {
      formIsValid = false;
      errors["message"] = " Please enter a message.";
    }

    this.setState({ errors: errors }, () => {
      if (formIsValid) {
        this.setState({
          formIsValids: true,
        });

        this.handleInputSubmit(event);
      }
    });
  };

  handleInputSubmit = (e) => {
    e.preventDefault();
    axios
      .post("/create_conversation", {
        subject: this.state.subject,
        users: [this.props.companies_owned[1][0]._id, this.props.target_id],
        users_information: [
          {
            comp_id: this.props.companies_owned[1][0]._id,
            company_name: this.props.companies_owned[1][0].company_name,
            company_type: this.props.companies_owned[1][0].company_type[0],
            read: true,
          },
          {
            comp_id: this.props.target_id,
            company_name: this.props.company_name,
            company_type: this.props.target_type,
            read: false,
          },
        ],
        messages: [
          {
            to: this.props.target_id,
            from: this.props.companies_owned[1][0]._id,
            content: this.state.message,
            time: new Date(),
          },
        ],
        started_by: this.props.companies_owned[1][0]._id,
        associated_service: this.props.service,
      })
      .then((response) => {
        if (response.data.status === "success") {
          this.setState({
            subject: "",
            message: "",
          });
          this.props.handleSuccessfulConversation();
        }
      });
  };
  getStage = (value, array) => {
    for (let i = 0; i < array.length; i++) {
      if (array[i].value === value) {
        return array[i].text;
      }
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <div
        style={
          this.props.showCreateMessagePopup
            ? { display: "block" }
            : { display: "none" }
        }
        onClick={(event) => this.props.dismissModal(event)}
        class="modal1"
      >
        <form class="modal-content animate">
          <div class="container" style={{ padding: "30px" }}>
            <div className="mb-3">
              <h3 style={{ textAlign: "center" }}>
                Message {this.props.company_name}
              </h3>
            </div>
            <span className="text-danger">{this.state.errors["subject"]}</span>
            <FormControl
              type="text"
              value={this.state.subject}
              //className={this.props.classes.textField}
              placeholder="Subject"
              name="subject"
              onChange={this.handleChange}
              className="form-control"
            />

            <TextField
              id="outlined-multiline-flexible"
              label="Message"
              multiline
              value={this.state.message}
              onChange={this.handleChange}
              rows={5}
              name="message"
              placeholder="Enter your message here"
              margin="normal"
              fullWidth={true}
              variant="outlined"
            />
            <span className="text-danger">{this.state.errors["message"]}</span>
            <Button
              onClick={(event) => this.handleValidation(event)}
              className="btn btn-primary btn-lg btn-block login-button"
            >
              Send
            </Button>
          </div>
        </form>
      </div>
    );
  }
}
Create_Product_Popup.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(Create_Product_Popup);
