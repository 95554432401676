import React, { useState } from "react";
import axios from "axios";
import LoadingButton from "../../loadingButton";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

const MarketIntelligence = ({
  companyInfo,
  opportunityInfo,
  updateCompanyData,
  handleCreditChange,
  applicationObject,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [marketIntelligence, setMarketIntelligence] = useState(
    companyInfo.aiGenerations.marketIntelligence[0]
      ? companyInfo.aiGenerations.marketIntelligence[0].marketIntelligence
      : null,
  );
  const [generationStep, setGenerationStep] = useState("");
  const [progress, setProgress] = useState(0);

  const simulateProgress = async () => {
    // Extracting phase (3 seconds)
    setGenerationStep("Extracting");
    for (let i = 0; i <= 30; i++) {
      setProgress(i);
      await new Promise((r) => setTimeout(r, 100));
    }

    // Summarizing phase (5 seconds)
    setGenerationStep("Summarizing");
    for (let i = 31; i <= 80; i++) {
      setProgress(i);
      await new Promise((r) => setTimeout(r, 62.5));
    }

    // Generating phase (remaining time)
    setGenerationStep("Generating");
  };

  const generateMarketIntelligence = async () => {
    setLoading(true);
    setError(null);
    setProgress(0);
    simulateProgress();

    try {
      const response = await axios.post(
        `/generate_market_intelligence?company_id=${companyInfo.applicant_company}&opportunity_id=${opportunityInfo._id}`,
        {
          applicationObject,
        },
      );
      console.log(
        `response.data.marketIntelligence ${JSON.stringify(
          response.data.marketIntelligence,
        )}`,
      );

      if (response.data.marketIntelligence.marketIntelligence) {
        const updatedMarketIntelligence = [
          response.data.marketIntelligence,
          ...companyInfo.aiGenerations.marketIntelligence.slice(1),
        ];

        const updates = {
          aiGenerations: {
            ...companyInfo.aiGenerations,
            marketIntelligence: updatedMarketIntelligence,
          },
        };

        updateCompanyData(companyInfo.applicant_company, updates);

        setMarketIntelligence(updatedMarketIntelligence[0].marketIntelligence);

        handleCreditChange(-response.data.creditsUsed);
      }
    } catch (error) {
      console.error("Error generating market intelligence:", error);

      if (error.response?.status === 401) {
        setError("Authentication error. Please log in again.");
      } else if (error.response?.status === 429) {
        setError("Rate limit exceeded. Please try again later.");
      } else if (error.response?.status === 404) {
        setError(
          "Pitch deck or necessary information is missing. Please upload a pitch deck.",
        );
      } else if (error.response?.status === 400) {
        setError(
          error.response.data || "Invalid request. Please check your inputs.",
        );
      } else {
        setError(
          "Due to high network load, we were unable to generate the market intelligence. Please try again later.",
        );
      }
    } finally {
      setLoading(false);
      setGenerationStep("");
      setProgress(0);
    }
  };

  const prepareChartData = (data) => {
    if (!data) return null;

    try {
      const years = data.map((point) => point.year);
      const values = data.map((point) => {
        if (!point.value) return null;
        const match = point.value.match(/\$?(\d+)/);
        return match ? parseInt(match[1]) : null;
      });

      // Check if any values are null/missing
      if (values.some((v) => v === null)) return null;

      return {
        labels: years,
        datasets: [
          {
            label: "Market Growth (Billions)",
            data: values,
            borderColor: "rgb(75, 192, 192)",
            tension: 0.1,
            fill: false,
          },
        ],
      };
    } catch (error) {
      return null;
    }
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Market Growth Rate Over Time",
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Amount (Billions $)",
        },
      },
      x: {
        title: {
          display: true,
          text: "Year",
        },
      },
    },
  };

  return (
    <div className="market-intelligence-container">
      <header className="header-section">
        <h1 className="market-intelligence-title">Market Intelligence</h1>
        <p className="subtitle">
          Leverage AI-driven insights to generate comprehensive market
          intelligence.
        </p>
      </header>
      <div className="action-section">
        <LoadingButton
          onClick={generateMarketIntelligence}
          loading={loading}
          text={loading ? "Generating..." : "Generate Market Intelligence"}
          className="summarize-button"
        />
      </div>
      {loading && (
        <div style={{ marginTop: "20px", marginBottom: "20px" }}>
          <div style={{ marginBottom: "10px", fontWeight: "bold" }}>
            {generationStep}...
          </div>
          <div
            style={{
              width: "100%",
              height: "20px",
              backgroundColor: "#f0f0f0",
              borderRadius: "10px",
              overflow: "hidden",
            }}
          >
            <div
              style={{
                width: `${progress}%`,
                height: "100%",
                background: "linear-gradient(90deg, #6902b9 0%, #1c71a1 100%)",
                transition: "width 0.3s ease-in-out",
              }}
            ></div>
          </div>
        </div>
      )}
      {error && <p className="error-message">{error}</p>}
      {marketIntelligence && (
        <div className="intelligence-report">
          <section className="report-section">
            <h2 className="section-title">Total Addressable Market (TAM)</h2>
            <p className="section-content">{marketIntelligence.TAM}</p>
          </section>
          <section className="report-section">
            <h2 className="section-title">Market Growth Rate</h2>
            {marketIntelligence.MarketGrowthRate.Data &&
            prepareChartData(marketIntelligence.MarketGrowthRate.Data) ? (
              <Line
                data={prepareChartData(
                  marketIntelligence.MarketGrowthRate.Data,
                )}
                options={chartOptions}
              />
            ) : null}
            <div className="growth-rate-table-container">
              <table className="growth-rate-table">
                <thead>
                  <tr>
                    <th>Year</th>
                    <th>Growth Rate</th>
                  </tr>
                </thead>
                <tbody>
                  {marketIntelligence.MarketGrowthRate.Data.map((dataPoint) => (
                    <tr key={dataPoint._id}>
                      <td>{dataPoint.year}</td>
                      <td>{dataPoint.value}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <p className="section-content">
              {marketIntelligence.MarketGrowthRate.Description}
            </p>
          </section>
          <section className="report-section">
            <h2 className="section-title">Market Summary</h2>
            <p className="section-content">
              {marketIntelligence.MarketSummary}
            </p>
          </section>
        </div>
      )}
    </div>
  );
};

export default MarketIntelligence;
