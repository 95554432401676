import React, { Component } from "react";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import Moment from "moment";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  country_list,
  opportunity_type_list,
  industry_list,
  stage_list,
  business_type,
  company_type_list,
} from "../../../boilerplate/list.json";
const styles = (theme) => ({
  button: {
    margin: theme.spacing.unit,
  },
  input: {
    display: "none",
  },
  root: {
    margin: theme.spacing.unit * 2,
  },
  header: {
    overflow: "hidden",
  },
  chip: {
    margin: theme.spacing.unit,
  },
  CardHeight: {
    height: 200,
    overflow: "hidden",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  actions: {
    display: "flex",
  },
  expand: {
    transform: "rotate(0deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
    marginLeft: "0",
    [theme.breakpoints.up("sm")]: {
      marginRight: -8,
    },
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  type: {
    fontSize: "1em",
    color: "red",
    textAlign: "center",
    fontWeight: 0,
  },
  footer: {
    display: "flex",
  },
  title: {
    fontSize: "1rem",
    overflow: "hidden",
    height: 25,
  },
  grow: {
    flexGrow: 1,
  },
});
class CardThree extends React.Component {
  render() {
    let { imagePreviewUrl } = {
      file: this.props.file_temp,
      imagePreviewUrl: this.props.imagePreviewUrl,
    };
    let $imagePreview = null;
    if (imagePreviewUrl) {
      $imagePreview = <img style={{ height: "100%" }} src={imagePreviewUrl} />;
    } else {
      $imagePreview = (
        <div className="previewText">Please select a background image.</div>
      );
    }
    const { classes } = this.props;
    return (
      <div className="card-3-container">
        <Card className="profile-card">
          <div className="cover-image-container">
            <CardMedia
              className={classes.media}
              image={imagePreviewUrl}
              title="background_picture"
            />
            <div className="profile-image-container">
              {this.props.company_list
                .filter((x) => x._id === this.props.affiliated_company)
                .map((x) => (
                  <img
                    src={this.props.getAvatarUrl(
                      x.company_files,
                      "company avatar"
                    )}
                    alt="profile-image"
                    class="profile2 mb-2"
                    style={{ width: "inherit", maxWidth: "unset" }}
                  />
                ))}
            </div>
          </div>
          <div className="profile-data-container">
            <div className="company-name-container">
              <div className="container">
                <h4>
                  <span>{this.props.opportunity_name} </span>{" "}
                  <span
                    style={{
                      fontSize: "15px",
                      marginTop: "5px",
                      float: "right",
                    }}
                  >
                    {this.props.opportunity_type}
                  </span>
                </h4>
                {this.props.affiliated_company.length > 0 && (
                  <p className="opportunity-name">
                    {" "}
                    {this.props.company_list
                      .filter((x) => x._id === this.props.affiliated_company)
                      .map((x) => x.company_name)}
                  </p>
                )}
                <h5>{this.props.venue_location}</h5>
                {this.props.ticket_price !== 0 && (
                  <h5>${this.props.ticket_price}</h5>
                )}
                {this.props.deadline.length > 0 && (
                  <h5>
                    {Moment(this.props.deadline).format(
                      "MMMM Do YYYY, h:mm:ss a"
                    )}
                  </h5>
                )}
              </div>
            </div>
            <div className="opportunity-name-container">
              <div
                className="form-container"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                {this.props.start_date.length > 0 && (
                  <div
                    className="left-input"
                    style={{
                      width: "100%",
                      borderStyle: "solid",
                      borderWidth: "thin",
                    }}
                  >
                    <h5 style={{ margin: "0px", textAlign: "center" }}>
                      Start Date
                    </h5>
                    <p style={{ margin: "0px", textAlign: "center" }}>
                      {Moment(this.props.start_date).format("MMMM Do YYYY")}
                    </p>
                    <p style={{ margin: "0px", textAlign: "center" }}>
                      {Moment(this.props.start_date).format("h:mm:ss a")}
                    </p>
                  </div>
                )}
                {this.props.end_date.length > 0 && (
                  <div
                    className="right-input"
                    style={{
                      width: "100%",
                      borderStyle: "solid",
                      borderWidth: "thin",
                    }}
                  >
                    <h5 style={{ margin: "0px", textAlign: "center" }}>
                      End Date
                    </h5>
                    <p style={{ margin: "0px", textAlign: "center" }}>
                      {Moment(this.props.end_date).format("MMMM Do YYYY")}
                    </p>
                    <p style={{ margin: "0px", textAlign: "center" }}>
                      {Moment(this.props.end_date).format("h:mm:ss a")}
                    </p>
                  </div>
                )}
              </div>
            </div>
            <div className="opportunity-description-container">
              <p className="opportunity-description">Opportunity Description</p>
              <div className="form-container">
                {this.props.opportunity_description}
              </div>
            </div>
            <div
              className="apply-btn-container"
              style={{ flex: "unset", display: "unset", padding: "0 20px" }}
            >
              <div
                className="hash-tags-container"
                style={{ flex: "unset", display: "unset" }}
              >
                {this.props.target_industries.length > 0 && (
                  <span className="hash-tags">
                    {this.props.target_industries.map((x) => "#" + x)}
                  </span>
                )}
                {this.props.target_stage_companies.length > 0 && (
                  <span className="hash-tags">
                    {this.props.target_stage_companies.map(
                      (x) => "#" + this.props.getStage(x, stage_list)
                    )}
                  </span>
                )}
                {this.props.target_model_companies.length > 0 && (
                  <p className="hash-tags">
                    {this.props.target_model_companies.map((x) => "#" + x)}
                  </p>
                )}
                {this.props.geographical_location !== null &&
                  this.props.geographical_location.length > 0 && (
                    <p className="hash-tags">
                      {this.props.geographical_location.map((x) => "#" + x)}
                    </p>
                  )}
              </div>
              <div className="btn-container">
                <a href={this.props.website_url}>
                  <button className="apply-btn">Apply On Website</button>
                </a>
              </div>
            </div>
          </div>
        </Card>
      </div>
    );
  }
}
CardThree.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CardThree);
