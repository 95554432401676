/* (2019-06-11) - The card that is displayed to represent the companies on
the explore page.
*/
import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import default_background from "../../img/bg.png";
import classnames from "classnames";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import CardActionArea from "@material-ui/core/CardActionArea";
import Typography from "@material-ui/core/Typography";
import red from "@material-ui/core/colors/red";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ShareIcon from "@material-ui/icons/Share";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Chip from "@material-ui/core/Chip";
import FaceIcon from "@material-ui/icons/Face";
import DoneIcon from "@material-ui/icons/Done";
import FolderIcon from "@material-ui/icons/Folder";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";
import Moment from "moment";
import { Explore_Share_Popup } from "./popups";
import {
  country_list,
  opportunity_type_list,
  industry_list,
  stage_list,
  business_type,
} from "../../boilerplate/list.json";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Hire_For_Project from "./popups/hire_for_project";
import { Generic_Success_Message } from "./popups";
import Start_A_Conversation_Popup from "./popups/start_a_conversation";
import MessageIcon from "@material-ui/icons/Message";
import Projects_Register_Popup from "./popups/projects_register_popup";

const CustomTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#e0e0e0",
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);
const styles = (theme) => ({
  button: {
    margin: theme.spacing.unit,
  },
  input: {
    display: "none",
  },
  root: {
    marginBottom: theme.spacing.unit * 2,
  },
  header: {
    overflow: "hidden",
  },
  chip: {
    margin: theme.spacing.unit,
  },
  CardHeight: {
    height: 210,
    overflow: "hidden",
    fontSize: "14px",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  actions: {
    display: "flex",
  },
  expand: {
    transform: "rotate(0deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
    marginLeft: "0",
    [theme.breakpoints.up("sm")]: {
      marginRight: -8,
    },
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  type: {
    fontSize: "1em",
    color: "red",
    textAlign: "center",
    fontWeight: 0,
  },
  footer: {
    display: "flex",
  },
  title: {
    fontSize: "1rem",
    overflow: "hidden",
    height: 25,
  },
  grow: {
    flexGrow: 1,
  },
});

function handleClick() {
  alert("You clicked the Chip.");
}

class RecipeReviewCard extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    expanded: false,
    showSharePopup: false,
    show_project: false,
    showSuccessPopup: false,
    showConversationPopup: false,
    showCreateMessageSuccessPopup: false,
    show_register: false,
  };
  handleConversationPopup = (e) => {
    e.preventDefault();
    this.setState({
      showConversationPopup: !this.state.showConversationPopup,
    });
  };
  handleSuccessfulConversation = () => {
    this.setState({
      showConversationPopup: false,
      showCreateMessageSuccessPopup: true,
    });
  };
  show_hire = (e) => {
    this.setState({
      show_project: true,
    });
  };
  showregister = (e) => {
    this.setState({
      show_register: true,
    });
  };
  dismissModal = (e) => {
    (e.target.className === "modal1" || e.target.className === "cancelbtn") &&
      this.setState({
        showCreateMessageSuccessPopup: false,
        showConversationPopup: false,
        showCheckout: false,
        show_project: false,
        showSuccessPopup: false,
        show_register: false,
      });
  };
  handleExpandClick = () => {
    this.setState((state) => ({ expanded: !state.expanded }));
  };
  handleSharePopup = (e) => {
    this.setState({
      showSharePopup: !this.state.showSharePopup,
    });
  };
  show_success_popup = () => {
    this.setState({
      show_project: false,
      showSuccessPopup: true,
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <Card className={`${classes.Card} ${classes.grow} ${classes.root}`}>
        <Explore_Share_Popup
          {...this.state}
          {...this.props}
          handleChange={this.handleChange}
          handleSharePopup={this.handleSharePopup}
        />
        <Projects_Register_Popup
          show={this.state.show_register}
          companies_owned={this.props.companies_owned}
          dismissModal={this.dismissModal}
        />
        <Hire_For_Project
          show={this.state.show_project}
          show_success_popup={this.show_success_popup}
          dismissModal={this.dismissModal}
          service={this.props.service._id}
          employee={this.props._id}
          employer={
            this.props.companies_owned !== null &&
            this.props.companies_owned[1][0]._id
          }
        />
        <Generic_Success_Message
          show={this.state.showSuccessPopup}
          popup_header="Invitation Successfully Sent!"
          dismiss={this.dismissModal}
        />
        <Start_A_Conversation_Popup
          dismissModal={this.dismissModal}
          companies_owned={this.props.companies_owned}
          handleSuccessfulConversation={this.handleSuccessfulConversation}
          subject={`I am interested in ${this.props.service.title_of_service}`}
          showCreateMessagePopup={this.state.showConversationPopup}
          target_id={this.props._id}
          company_name={this.props.company_name}
          target_type={this.props.company_type[0]}
          service={this.props.service._id}
        />
        <Generic_Success_Message
          show={this.state.showCreateMessageSuccessPopup}
          popup_header="Message Successfully Sent!"
          dismiss={this.dismissModal}
        />
        <CardHeader
          title={
            <a href={this.props.service.service_link}>
              <Typography variant="h6" className={classes.title} gutterBottom>
                {this.props.service.title_of_service}
              </Typography>
            </a>
          }
          subheader={this.props.service.type_of_consultant + " Service"}
          className={classes.header}
          action={
            <CardActions disableSpacing style={{ paddingLeft: 0 }}>
              {this.props.userIsProfileOwner && !this.props.inPreviewMode && (
                <div>
                  <IconButton style={{ padding: "0px", marginRight: 0 }}>
                    <EditIcon
                      className={classes.iconHover}
                      onClick={(event) =>
                        this.props.handleEditService(
                          event,
                          this.props.service._id
                        )
                      }
                      style={{ fontSize: "22px" }}
                      color={this.props.flagged ? "error" : ""}
                    />
                  </IconButton>
                  <IconButton
                    onClick={(event) =>
                      this.props.handleDeleteService(
                        event,
                        this.props.service._id
                      )
                    }
                    style={{ padding: "0px", marginRight: "-10px" }}
                    aria-label="Share"
                  >
                    <DeleteIcon
                      className={classes.iconHover}
                      style={{ fontSize: "22px" }}
                      color={this.props.flagged ? "error" : ""}
                    />
                  </IconButton>
                </div>
              )}
              {!this.props.userIsProfileOwner &&
                this.props.companies_owned !== undefined &&
                this.props.companies_owned !== null &&
                this.props.companies_owned[1] !== undefined &&
                this.props.companies_owned[1][0] !== undefined && (
                  <IconButton
                    onClick={this.handleConversationPopup}
                    size="small"
                  >
                    <MessageIcon />
                  </IconButton>
                )}
            </CardActions>
          }
        />

        <CardActionArea
          href={
            this.props.service !== undefined &&
            this.props.service.service_link.includes("http")
              ? this.props.service.service_link
              : `http://${this.props.service.service_link}`
          }
          target="_blank"
        >
          <CardMedia
            className={classes.media}
            image={
              this.props.service.file !== undefined &&
              this.props.service.file[0] !== undefined
                ? this.props.service.file[0].file_url
                : default_background
            }
            title="background_picture"
          />
        </CardActionArea>

        <CardContent
          className="exploreCardContent"
          style={{ whiteSpace: "pre-line" }}
        >
          {this.props.service.description_of_service}
        </CardContent>

        <Grid className={classes.footer}>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-start"
            className="col-3"
          >
            <CardActions
              className={classes.actions}
              style={{ padding: "6px 0" }}
            >
              {this.props.service.confirming_b2b ? (
                <IconButton
                  style={{ padding: "6px" }}
                  className={classnames(classes.expand, {
                    [classes.expandOpen]: this.state.expanded,
                  })}
                  onClick={this.handleExpandClick}
                  aria-expanded={this.state.expanded}
                  aria-label="Show more"
                >
                  <ExpandMoreIcon />
                </IconButton>
              ) : (
                <div style={{ height: "36px" }}></div>
              )}
            </CardActions>
          </Grid>
          <Grid container direction="row" justify="center" alignItems="center">
            <div>
              <em>
                ${this.props.service.service_price}
                {this.props.service.pricing_model === "Hourly" && "/hr"}
                {this.props.service.pricing_model === "Monthly" && "/month"}
                {this.props.service.pricing_model === "Yearly" && "/year"}
              </em>
            </div>
          </Grid>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-end"
            className="col-4"
          >
            {this.props.companies_owned !== undefined &&
            this.props.companies_owned !== null &&
            this.props.companies_owned[1] !== undefined &&
            this.props.companies_owned[1] !== null ? (
              <Button
                variant="contained"
                color="primary"
                onClick={this.show_hire}
                className={classes.button + " OopSerToolCardFooterBtn"}
                size="small"
              >
                Hire
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={this.showregister}
                className={classes.button + " OopSerToolCardFooterBtn"}
                size="small"
              >
                Hire
              </Button>
            )}
          </Grid>
        </Grid>
        <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
          <CardContent>
            {this.props.service.applicable_user_types !== undefined &&
              this.props.service.applicable_user_types !== null && (
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <CustomTableCell className="text-center">
                        Serviced User Types
                      </CustomTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow style={{ height: "auto" }}>
                      <div style={{ margin: "7px 0", textAlign: "center" }}>
                        {this.props.service.applicable_user_types.map((val) => (
                          <Chip
                            size="small"
                            label={
                              val == "Artificial Intelligence" ? "A.I" : val
                            }
                            className={classes.chip}
                          />
                        ))}
                      </div>
                    </TableRow>
                  </TableBody>
                </Table>
              )}
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <CustomTableCell className="text-center">
                    Company Industries
                  </CustomTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow style={{ height: "auto" }}>
                  <div style={{ margin: "7px 0", textAlign: "center" }}>
                    {this.props.service.applicable_industries.map((val) => (
                      <Chip
                        size="small"
                        label={val == "Artificial Intelligence" ? "A.I" : val}
                        className={classes.chip}
                      />
                    ))}
                  </div>
                </TableRow>
              </TableBody>
            </Table>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <CustomTableCell className="text-center">
                    Company Stages
                  </CustomTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow style={{ height: "auto" }}>
                  <div style={{ margin: "7px 0", textAlign: "center" }}>
                    {this.props.service.applicable_business_stages.map(
                      (val) => (
                        <Chip
                          size="small"
                          label={this.props.getStage(
                            parseFloat(val),
                            stage_list
                          )}
                          className={classes.chip}
                        />
                      )
                    )}
                  </div>
                </TableRow>
              </TableBody>
            </Table>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <CustomTableCell className="text-center">
                    Business Models
                  </CustomTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow style={{ height: "auto" }}>
                  <div style={{ margin: "7px 0", textAlign: "center" }}>
                    {this.props.service.applicable_business_models.map(
                      (val) => (
                        <Chip
                          size="small"
                          label={val == "Artificial Intelligence" ? "A.I" : val}
                          className={classes.chip}
                        />
                      )
                    )}
                  </div>
                </TableRow>
              </TableBody>
            </Table>
          </CardContent>
        </Collapse>
      </Card>
    );
  }
}

RecipeReviewCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RecipeReviewCard);
