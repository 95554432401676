import React from 'react';
import './ProgressTrack.css';

const ProgressTrack = props => (
    <div className='AragonFlex'>
        { /* <img src={props.avtar} alt='' className='AragonMemberIcon' style={{marginRight: '27px'}} /> */ }
        <div style={{flexGrow: 100}}>
            <div>
                {props.title * 10 + "%"}
            </div>
            <div className="rounded" style={{background: 'rgb(82, 95, 127, .2)'}}>
                <div className="rounded-lg" style={{height: '10px', border: '1px solid ' + props.color, background: props.color, width: props.width}}></div>
            </div>
        </div>
    </div>
)

export default ProgressTrack;