import React from 'react';
import Navigation from '../../live/components/navbar'
import Footer from '../../live/components/footer_side'
import { Sign_In, Register, Forgot_Password, Alert_Message_To_User } from '../../live/components/popups'
import SiteTitle from '../../live/components/site_title.js'

class Nav extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <SiteTitle />
        <Navigation />
        <Sign_In />
        <Forgot_Password />
        <Alert_Message_To_User
          show={ this.props.showPopupMessage }
          handleChange={ this.props.handleChange }
          dismiss={ this.props.dismissModal }
          alert_message={ this.props.alert_message }
          {...this.state} />
        <Register />
      </div>
      );
  }
}

export default Nav;
