/* (2019-06-11) - This is the left side when creating/editing an opportunity.
Contains information on the company (profile picture, name, etc)
*/
import React from "react";
import axios from "axios";
import update from "immutability-helper";
import { SocialIcon } from "react-social-icons";
import {
  Navbar,
  Nav,
  Row,
  Col,
  Card,
  Button,
  ButtonToolbar,
  Container,
  FormLabel,
  Form,
} from "react-bootstrap";
import PropTypes from "prop-types";
import classNames from "classnames";
import "../../components/profile.css";
import "./opportunities.css";
import DisplayToggle from "../DisplayToggle";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Checkbox from "@material-ui/core/Checkbox";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import StarRatings from "react-star-ratings";
import "./application-to-opportunity.scss";
import { Helmet } from "react-helmet";
import URL from "url-parse";
import default_pic from "../../../img/default_company_pic.png";
import default_background from "../../../img/bg.png";
import rocket from "../../../img/rocketlaunch.jpg";
import StartupFuel from "../../../img/watermarksffinal.png";
import Loader from "../../components/loader";
import {
  Profile_Photo_Update,
  Cover_Photo_Update,
  Company_Update,
  Sign_In,
  Register,
} from "../../components/popups";
import {
  country_list,
  opportunity_type_list,
  industry_list,
  stage_list,
  business_type,
  company_type_list,
  goals,
} from "../../../boilerplate/list.json";
import SiteTitle from "../../components/site_title.js";

import waterfall from "async/waterfall";

import { AppContext } from "../../../App";
import Navigation from "../../components/navbar";
import Footer from "./../../components/footer";
import Moment from "moment";

import CompanyNavBar from "./nav_bar";

import { Map, GoogleApiWrapper, InfoWindow, Marker } from "google-maps-react";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

class CustomizedTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: new URL(window.location.href, true),
      show_feedback_number: 1000,
      reviews: [],
      feedback: [],
      totalTeam: 0,
      totalIdea: 0,
      totalTech: 0,
      totalMarketing: 0,
      totalOverall: 0,
    };
  }
  handleAllowInvestor = (e) => {
    this.props.handleInvestorChange(e);
    this.props.handleAlertPopup();
  };

  calculate_reviews = () => {
    let totalTech = 0;
    let totalMarketing = 0;
    let totalIdea = 0;
    let totalTeam = 0;
    let totalOverall = 0;
    let length = 0;
    this.state.reviews.map((review) => {
      totalTech += review.technology;
      totalMarketing += review.marketing;
      totalIdea += review.idea;
      totalTeam += review.team;
      totalOverall += review.overallRating;
      length++;
      if (length === this.state.reviews.length) {
        this.setState({
          totalTeam: totalTeam / length,
          totalIdea: totalIdea / length,
          totalTech: totalTech / length,
          totalMarketing: totalMarketing / length,
          totalOverall: totalOverall / length,
        });
      }
    });
  };

  handleFeedbackClick = (e, value, opp_id, setting) => {
    if (this.state.show_feedback_number !== value) {
      this.setState(
        {
          show_feedback_number: value,
          opp_id: opp_id,
        },
        () => {
          axios
            .post(
              `/fetch_review_basic?company_id=${this.state.url.query.company_id}`,
              { opp_id: this.state.opp_id }
            )
            .then((response) => {
              this.setState(
                {
                  reviews: response.data.reviews,
                },
                () => {
                  this.calculate_reviews();
                }
              );
            });
          if (setting === "detailed_hidden_judges") {
            axios
              .post(
                `/fetch_anon_feedback?company_id=${this.state.url.query.company_id}`,
                { opp_id: this.state.opp_id }
              )
              .then((response) => {
                this.setState({
                  feedback: response.data.feedback,
                });
              });
          }

          if (setting === "detailed_show_judges") {
            axios
              .post(
                `/fetch_anon_feedback?company_id=${this.state.url.query.company_id}`,
                { opp_id: this.state.opp_id }
              )
              .then((response) => {
                this.setState({
                  feedback: response.data.feedback,
                });
              });
          }
        }
      );
    } else {
      this.setState({
        show_feedback_number: 10000,
        reviews: [],
        feedback: [],
        totalTeam: 0,
        totalIdea: 0,
        totalTech: 0,
        totalMarketing: 0,
        totalOverall: 0,
      });
    }
  };
  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        {this.props.userIsProfileOwner && (
          <div>
            <div style={{ width: "100%" }}>
              <CompanyNavBar
                menuSelection={this.props.menuSelection}
                handleMenuChange={this.props.handleMenuChange}
                company_type={this.props.company_type}
                allow_investor={this.props.allow_investor}
                userIsProfileOwner={this.props.userIsProfileOwner}
              />
              <DisplayToggle
                show={
                  this.props.opportunity_list !== undefined &&
                  this.props.opportunity_list !== null &&
                  this.props.opportunity_list.length !== 0
                }
              >
                <div>
                  {this.props.opportunity_list &&
                    this.props.opportunity_list.map((opp, i) => (
                      <>
                        <div class="card opp-card-horz __ubaid__application__opp">
                          <div className="opp-card-image-wrapper">
                            <div></div>
                            <img
                              src={
                                opp.background_cover !== null
                                  ? opp.background_cover
                                  : default_background
                              }
                            />
                          </div>
                          <div class="opp-card-content">
                            <h4>
                              <a
                                href={"/opportunity?opportunity_id=" + opp._id}
                              >
                                {opp.opportunity_name}
                              </a>
                            </h4>
                            {opp.venue_location !== undefined &&
                              opp.venue_location.length > 0 && (
                                <p>
                                  <i
                                    class="fa fa-map-marker"
                                    aria-hidden="true"
                                  ></i>
                                  {opp.venue_location}
                                </p>
                              )}
                            <p>{opp.opportunity_description}</p>
                            <div class="card__bottom">
                              <div class="options">
                                {opp.deadline !== null && (
                                  <span class="date">
                                    <i
                                      class="fa fa-calendar"
                                      aria-hidden="true"
                                    ></i>
                                    Deadline:{" "}
                                    {Moment(opp.deadline).format(
                                      "MMMM Do YYYY, h:mm:ss a"
                                    )}
                                  </span>
                                )}
                                {opp.start_date !== null && (
                                  <span class="date">
                                    <i
                                      class="fa fa-calendar"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    Start Date:{" "}
                                    {Moment(opp.start_date).format(
                                      "MMMM Do YYYY, h:mm:ss a"
                                    )}
                                  </span>
                                )}
                                {opp.end_date !== null && (
                                  <span class="date">
                                    <i
                                      class="fa fa-calendar"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    End Date:{" "}
                                    {Moment(opp.end_date).format(
                                      "MMMM Do YYYY, h:mm:ss a"
                                    )}
                                  </span>
                                )}
                              </div>
                              <div class="card__price">{opp.price}</div>
                            </div>
                            <div className="__footer">
                              <a
                                href={"/opportunity?opportunity_id=" + opp._id}
                              >
                                <button
                                  type="button"
                                  class="btn btn-sm btn-blue"
                                >
                                  {" "}
                                  Edit Application
                                </button>
                              </a>
                              {opp.users_can_see_feedback ? (
                                <button
                                  type="button"
                                  onClick={(event) =>
                                    this.handleFeedbackClick(
                                      event,
                                      i,
                                      opp._id,
                                      opp.feedback_setting
                                    )
                                  }
                                  class="btn btn-sm btn-white"
                                >
                                  {" "}
                                  View Feedback
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  disabled
                                  class="btn btn-sm btn-white"
                                >
                                  {" "}
                                  View Feedback
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                        {this.state.show_feedback_number === i && (
                          <>
                            <div class="opp-card-content ubaidopp-card-content">
                              <div
                                style={{ textAlign: "center", width: "100%" }}
                              >
                                *Learn more about the breakdown of your feedback
                                score below to know where your company needs
                                improving.*
                              </div>
                              <div className="ratings ubaidSubRatings">
                                <div>
                                  <span className="ubaidSubOverAllRatings">
                                    Overall
                                  </span>

                                  <span>
                                    <StarRatings
                                      rating={this.state.totalOverall}
                                      starRatedColor="#E6B800"
                                      numberOfStars={10}
                                      starDimension="15px"
                                      starSpacing="0"
                                    />
                                  </span>
                                </div>

                                <div>
                                  {this.state.totalOverall.toFixed(1)}/10 based
                                  on {this.state.reviews.length} reviews.
                                </div>

                                <div className="rating-counts">
                                  <div>
                                    <span className="ReviewCardTitleUbaid">
                                      Idea
                                    </span>
                                    <span className="ReviewCardStarUBaid">
                                      <StarRatings
                                        rating={this.state.totalIdea}
                                        starRatedColor="#E6B800"
                                        numberOfStars={10}
                                        starDimension="15px"
                                        starSpacing="0"
                                      />{" "}
                                    </span>
                                    <span className="ReviewCardRatingUbaid">
                                      {this.state.totalIdea.toFixed(1)}/10
                                      <a
                                        href="https://calendly.com/mrmartis/15-min-general-info-call?month=2019-11"
                                        target="_blank"
                                      >
                                        <button
                                          type="button"
                                          class="btn btn-sm btn-blue"
                                        >
                                          {" "}
                                          Call Us?
                                        </button>
                                      </a>
                                    </span>
                                  </div>

                                  <div>
                                    <span className="ReviewCardTitleUbaid">
                                      Tech
                                    </span>
                                    <span className="ReviewCardStarUBaid">
                                      <StarRatings
                                        rating={this.state.totalTech}
                                        starRatedColor="#E6B800"
                                        numberOfStars={10}
                                        starDimension="15px"
                                        starSpacing="0"
                                      />{" "}
                                    </span>
                                    <span className="ReviewCardRatingUbaid">
                                      {this.state.totalTech.toFixed(1)}/10
                                      <a
                                        href="https://calendly.com/trevor-33/services"
                                        target="_blank"
                                      >
                                        <button
                                          type="button"
                                          class="btn btn-sm btn-blue"
                                        >
                                          {" "}
                                          Call Us?
                                        </button>
                                      </a>
                                    </span>
                                  </div>
                                  <div>
                                    <span className="ReviewCardTitleUbaid">
                                      Marketing
                                    </span>
                                    <span className="ReviewCardStarUBaid">
                                      <StarRatings
                                        rating={this.state.totalMarketing}
                                        starRatedColor="#E6B800"
                                        numberOfStars={10}
                                        starDimension="15px"
                                        starSpacing="0"
                                      />{" "}
                                    </span>
                                    <span className="ReviewCardRatingUbaid">
                                      {this.state.totalMarketing.toFixed(1)}/10
                                      <a
                                        href="https://calendly.com/mrmartis/15-min-general-info-call?month=2019-11"
                                        target="_blank"
                                      >
                                        <button
                                          type="button"
                                          class="btn btn-sm btn-blue"
                                        >
                                          {" "}
                                          Call Us?
                                        </button>
                                      </a>
                                    </span>
                                  </div>
                                  <div>
                                    <span className="ReviewCardTitleUbaid">
                                      Team
                                    </span>
                                    <span className="ReviewCardStarUBaid">
                                      <StarRatings
                                        rating={this.state.totalTeam}
                                        starRatedColor="#E6B800"
                                        numberOfStars={10}
                                        starDimension="15px"
                                        starSpacing="0"
                                      />{" "}
                                    </span>
                                    <span className="ReviewCardRatingUbaid">
                                      {this.state.totalTeam.toFixed(1)}/10
                                      <a
                                        href="https://calendly.com/mrmartis/15-min-general-info-call?month=2019-11"
                                        target="_blank"
                                      >
                                        <button
                                          type="button"
                                          class="btn btn-sm btn-blue"
                                        >
                                          {" "}
                                          Call Us?
                                        </button>
                                      </a>
                                    </span>
                                  </div>
                                  {opp.additional_review_options &&
                                    opp.additional_review_criteria !==
                                      undefined &&
                                    opp.additional_review_criteria !== null &&
                                    opp.additional_review_criteria.length >
                                      0 && (
                                      <>
                                        {opp.additional_review_criteria.map(
                                          (criteria) => (
                                            <div>
                                              <span>{criteria}</span>
                                              <span>
                                                <StarRatings
                                                  rating={10}
                                                  starRatedColor="#E6B800"
                                                  numberOfStars={10}
                                                  starDimension="15px"
                                                  starSpacing="0"
                                                />{" "}
                                              </span>
                                              <span>10/10</span>
                                            </div>
                                          )
                                        )}
                                      </>
                                    )}
                                </div>
                                {/*opp.feedback_setting === "detailed_hidden_judges" &&
                      <div className="comment ubaidComment">
                        <div className="comment-img">
                          <img src={default_pic} />
                        </div>

                        <div className="comment-text">
                          <div><span style={{ padding: "unset" }}>John Doe</span><span> -</span></div >
                        </div>
                        <div className='ubaidCenter'>




                          <div className="ubaidPara"><p>Iggy</p></div>
                        </div>
                      </div>
                  */}
                                {/* opp.feedback_setting === "detailed_show_judges" &&
                    <p>hey2</p>
                */}
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    ))}
                </div>
              </DisplayToggle>
              <DisplayToggle
                show={
                  this.props.opportunity_list === undefined ||
                  this.props.opportunity_list === null ||
                  this.props.opportunity_list.length === 0
                }
              >
                <br />
                <h5 style={{ textAlign: "center" }}>
                  You have not submitted any applications yet, please apply to
                  some opportunities.
                </h5>
              </DisplayToggle>
              <div class="opp-create-btn">
                <a href="/opportunities" class="btn btn-primary">
                  View Opportunities
                </a>
              </div>
              <br />
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default CustomizedTable;
