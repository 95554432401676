/* (2019-06-10) - Contains the site title as a component to display on every page.
*/
import React from 'react';
import axios from 'axios'
import update from 'immutability-helper';
import '../../main.css';
import { Helmet } from "react-helmet";
import URL from 'url-parse';

class SiteTitle extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {classes} = this.props;

    return (
      <Helmet>
        <title>
          {this.props.title}
        </title>
        <meta name="description" content={this.props.meta_description}/>
        <meta name="keywords" content={this.props.meta_keywords} />
      </Helmet>
      );
  }
}

export default SiteTitle;
