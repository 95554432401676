/* (2019-06-11) - The card that is displayed to represent the companies on
the explore page.
*/
import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import default_background from "../../img/bg.png";
import classnames from "classnames";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import CardActionArea from "@material-ui/core/CardActionArea";
import Typography from "@material-ui/core/Typography";
import red from "@material-ui/core/colors/red";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ShareIcon from "@material-ui/icons/Share";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Chip from "@material-ui/core/Chip";
import FaceIcon from "@material-ui/icons/Face";
import DoneIcon from "@material-ui/icons/Done";
import FolderIcon from "@material-ui/icons/Folder";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";
import Moment from "moment";
import { Explore_Share_Popup } from "./popups";
import {
  country_list,
  opportunity_type_list,
  industry_list,
  stage_list,
  business_type,
} from "../../boilerplate/list.json";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

const CustomTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#e0e0e0",
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const styles = (theme) => ({
  button: {
    margin: theme.spacing.unit,
  },
  input: {
    display: "none",
  },
  root: {
    marginBottom: theme.spacing.unit * 2,
  },
  header: {
    overflow: "hidden",
  },
  chip: {
    margin: theme.spacing.unit,
  },
  CardHeight: {
    height: 200,
    overflow: "hidden",
    fontSize: "14px",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  actions: {
    display: "flex",
  },
  expand: {
    transform: "rotate(0deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
    marginLeft: "0",
    [theme.breakpoints.up("sm")]: {
      marginRight: -8,
    },
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  type: {
    fontSize: "1em",
    color: "red",
    textAlign: "center",
    fontWeight: 0,
  },
  footer: {
    display: "flex",
  },
  title: {
    fontSize: "1rem",
    overflow: "hidden",
    height: 25,
  },
  grow: {
    flexGrow: 1,
  },
});

function handleClick() {
  alert("You clicked the Chip.");
}

class RecipeReviewCard extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    expanded: false,
    showSharePopup: false,
  };

  handleExpandClick = () => {
    this.setState((state) => ({ expanded: !state.expanded }));
  };
  handleSharePopup = (e) => {
    this.setState({
      showSharePopup: !this.state.showSharePopup,
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <Card className={`${classes.Card} ${classes.grow} ${classes.root}`}>
        <Explore_Share_Popup
          {...this.state}
          {...this.props}
          handleChange={this.handleChange}
          handleSharePopup={this.handleSharePopup}
        />
        <CardHeader
          title={
            <a href={this.props.product.product_link}>
              <Typography variant="h6" className={classes.title} gutterBottom>
                {this.props.product.product_name}
              </Typography>
            </a>
          }
          subheader={`${this.props.product.type} ${
            this.props.product.category ? this.props.product.category : ""
          } Product`}
          className={classes.header}
          action={
            <CardActions disableSpacing style={{ paddingLeft: 0 }}>
              {this.props.userIsProfileOwner && !this.props.inPreviewMode && (
                <div>
                  <IconButton style={{ padding: "0px", marginRight: 0 }}>
                    <EditIcon
                      className={classes.iconHover}
                      onClick={(event) =>
                        this.props.handleEditProduct(
                          event,
                          this.props.product._id
                        )
                      }
                      style={{ fontSize: "22px" }}
                      color={this.props.flagged ? "error" : ""}
                    />
                  </IconButton>
                  <IconButton
                    onClick={(event) =>
                      this.props.handleDeleteProduct(
                        event,
                        this.props.product._id
                      )
                    }
                    style={{ padding: "0px", marginRight: "-10px" }}
                    aria-label="Share"
                  >
                    <DeleteIcon
                      className={classes.iconHover}
                      style={{ fontSize: "22px" }}
                      color={this.props.flagged ? "error" : ""}
                    />
                  </IconButton>
                </div>
              )}
            </CardActions>
          }
        />

        <CardActionArea
          href={
            this.props.product.product_link.includes("http")
              ? this.props.product.product_link
              : `http://${this.props.product.product_link}`
          }
          target="_blank"
        >
          <CardMedia
            className={classes.media}
            image={
              this.props.product.file !== undefined &&
              this.props.product.file[0] !== undefined
                ? this.props.product.file[0].file_url
                : default_background
            }
            title="background_picture"
          />
        </CardActionArea>

        <CardContent
          className="exploreCardContent"
          style={{ whiteSpace: "pre-line" }}
        >
          {this.props.product.product_description}
        </CardContent>

        <Grid className={classes.footer}>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-start"
            className="col-3"
          >
            <CardActions
              className={classes.actions}
              style={{ padding: "6px 0" }}
            >
              {this.props.product.confirming_b2b ? (
                <IconButton
                  style={{ padding: "6px" }}
                  className={classnames(classes.expand, {
                    [classes.expandOpen]: this.state.expanded,
                  })}
                  onClick={this.handleExpandClick}
                  aria-expanded={this.state.expanded}
                  aria-label="Show more"
                >
                  <ExpandMoreIcon />
                </IconButton>
              ) : (
                <div style={{ height: "36px" }}></div>
              )}
            </CardActions>
          </Grid>
          <Grid container direction="row" justify="center" alignItems="center">
            <div>
              <em>
                ${this.props.product.product_price}
                {this.props.product.payment_plan === "Monthly" && "/month"}
                {this.props.product.payment_plan === "Yearly" && "/year"}
              </em>
            </div>
          </Grid>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-end"
            className="col-4"
          >
            <Button
              variant="contained"
              color="primary"
              href={
                this.props.product.product_link.includes("http")
                  ? this.props.product.product_link
                  : `http://${this.props.product.product_link}`
              }
              target="_blank"
              className={classes.button}
              size="small"
            >
              VIEW
            </Button>
          </Grid>
        </Grid>
        <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
          <CardContent>
            {this.props.product.applicable_user_types !== undefined &&
              this.props.product.applicable_user_types !== null && (
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <CustomTableCell className="text-center">
                        Targeted User Types
                      </CustomTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow style={{ height: "auto" }}>
                      <div style={{ margin: "7px 0", textAlign: "center" }}>
                        {this.props.product.applicable_user_types.map((val) => (
                          <Chip
                            size="small"
                            label={
                              val == "Artificial Intelligence" ? "A.I" : val
                            }
                            className={classes.chip}
                          />
                        ))}
                      </div>
                    </TableRow>
                  </TableBody>
                </Table>
              )}
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <CustomTableCell className="text-center">
                    Company Industries
                  </CustomTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow style={{ height: "auto" }}>
                  <div style={{ margin: "7px 0", textAlign: "center" }}>
                    {this.props.product.applicable_industries.map((val) => (
                      <Chip
                        size="small"
                        label={val == "Artificial Intelligence" ? "A.I" : val}
                        className={classes.chip}
                      />
                    ))}
                  </div>
                </TableRow>
              </TableBody>
            </Table>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <CustomTableCell className="text-center">
                    Company Stages
                  </CustomTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow style={{ height: "auto" }}>
                  <div style={{ margin: "7px 0", textAlign: "center" }}>
                    {this.props.product.applicable_business_stages.map(
                      (val) => (
                        <Chip
                          size="small"
                          label={this.props.getStage(
                            parseFloat(val),
                            stage_list
                          )}
                          className={classes.chip}
                        />
                      )
                    )}
                  </div>
                </TableRow>
              </TableBody>
            </Table>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <CustomTableCell className="text-center">
                    Business Models
                  </CustomTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow style={{ height: "auto" }}>
                  <div style={{ margin: "7px 0", textAlign: "center" }}>
                    {this.props.product.applicable_business_models.map(
                      (val) => (
                        <Chip
                          size="small"
                          label={val == "Artificial Intelligence" ? "A.I" : val}
                          className={classes.chip}
                        />
                      )
                    )}
                  </div>
                </TableRow>
              </TableBody>
            </Table>
          </CardContent>
        </Collapse>
      </Card>
    );
  }
}

RecipeReviewCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RecipeReviewCard);
