import { stage_list } from "../../../../../boilerplate/list.json";
import default_pic from "../../../../../img/default_company_pic.png";
import React from "react";

export default function PdfHeader({ company, created_on, docType }) {
  return <div className="company-overview">
  <div className="company-info">
    <div>
      <h2 className="tearsheet-title">{docType}</h2>
      Investment Opportunity | Powered by DiligenceGPT
      <p>
        {company.company_name}
        {company.company_website && (
          <a
            href={
              company.company_website.startsWith("http")
                ? company.company_website
                : `http://${company.company_website}`
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            | {company.company_website}
          </a>
        )}
      </p>
    </div>
    <div>
      <img
        src={`${process.env.PUBLIC_URL}/startupfuel-logo.png`}
        alt="StartupFuel Logo"
        style={{ height: 50 }}
      />
      <p>Venture Ratings Agency</p>
      <p>
        Date-created:{" "}
        {new Date(created_on).toLocaleDateString()}
      </p>
    </div>
  </div>
  <div className="tearsheet-section">
    <div className="company-info">
      <div className="company-details">
        <img
          src={default_pic}
          alt={`${company.company_name} Avatar`}
          style={{ width: "100px", height: "100px" }}
        />
      </div>
      <div className="company-details">
        <div>
          Founded
          <p style={{ fontWeight: "bold" }}>
            {company.founded_year ? company.founded_year : "-"}
          </p>
        </div>
        <div>
          Location
          <p style={{ fontWeight: "bold" }}>{company.country}</p>
        </div>
      </div>
      <div className="company-details">
        <div>
          Business Model(s)
          <p style={{ fontWeight: "bold" }}>
            {company.business_model}
          </p>
        </div>
        <div>
          Primary Industry
          <p style={{ fontWeight: "bold" }}>
            {company.industry[0]}
          </p>
        </div>
      </div>
      <div className="company-details">
        <div>
          Business Stage
          <p style={{ fontWeight: "bold" }}>
            {stage_list.find(
              (stage) => stage.value === company.stage,
            )?.text || "N/A"}
          </p>
        </div>
        <div>
          Business Type
          <p style={{ fontWeight: "bold" }}>
            {company.company_type[0]}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>;
}

