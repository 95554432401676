/* (2019-06-11) - This only appears for companies that have a type of 'startup'
or 'tech startup'. It displays a pitch deck that they can fill out, it is also
the content that is used when applying for opportunities.
*/
import React from "react";
import axios from "axios";
import update from "immutability-helper";
import { SocialIcon } from "react-social-icons";
import {
  Navbar,
  Nav,
  Row,
  Col,
  Card,
  Button,
  ButtonToolbar,
  Container,
  FormLabel,
  Form,
} from "react-bootstrap";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import "../../components/profile.css";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import AccountCircle from "@material-ui/icons/AccountCircle";

import { Helmet } from "react-helmet";
import URL from "url-parse";
import default_pic from "../../../img/default_company_pic.png";
import default_background from "../../../img/bg.png";
import rocket from "../../../img/rocketlaunch.jpg";
import StartupFuel from "../../../img/watermarksffinal.png";
import Loader from "../../components/loader";
import {
  Profile_Photo_Update,
  Cover_Photo_Update,
  Company_Update,
  Sign_In,
  Register,
} from "../../components/popups";
import {
  country_list,
  opportunity_type_list,
  industry_list,
  stage_list,
  business_type,
  company_type_list,
  goals,
} from "../../../boilerplate/list.json";
import SiteTitle from "../../components/site_title.js";
import CompanyNavBar from "./nav_bar";

import waterfall from "async/waterfall";

import { AppContext } from "../../../App";
import Navigation from "../../components/navbar";
import Footer from "./../../components/footer";

import { Map, GoogleApiWrapper, InfoWindow, Marker } from "google-maps-react";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function getStyles(name, that) {
  return {
    fontWeight:
      that.state.name.indexOf(name) === -1
        ? that.props.theme.typography.fontWeightRegular
        : that.props.theme.typography.fontWeightMedium,
  };
}
const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: theme.spacing.unit / 4,
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 100,
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 100,
  },
});

const currencies = [
  {
    value: "USD",
    label: "$",
  },
  {
    value: "EUR",
    label: "€",
  },
  {
    value: "BTC",
    label: "฿",
  },
  {
    value: "JPY",
    label: "¥",
  },
];

class CustomizedTable extends React.Component {
  constructor(props) {
    super(props);
  }
  handleAllowInvestor = (e) => {
    this.props.handleInvestorChange(e);
    this.props.handleAlertPopup();
  };
  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        {this.props._id === "5c33c65c22687d002938324b" ||
        this.props.userIsProfileOwner ||
        ((this.props.company_type[0] === "Startup" ||
          this.props.company_type[0] === "Tech Startup") &&
          this.props.allow_investor &&
          this.props.companies_owned &&
          this.props.companies_owned[1][0].company_type[0] === "Investor") ? (
          <div>
            <div style={{ width: "100%" }}>
              <CompanyNavBar
                menuSelection={this.props.menuSelection}
                handleMenuChange={this.props.handleMenuChange}
                company_type={this.props.company_type}
                allow_investor={this.props.allow_investor}
                companies_owned={this.props.companies_owned}
                userIsProfileOwner={this.props.userIsProfileOwner}
              />
              {this.props.userIsProfileOwner && (
                <p style={{ textAlign: "center" }}>
                  *Only Investors users can view your pitch profile if you set
                  it it public, otherwise only you can see it.*{" "}
                </p>
              )}

              {((this.props.userIsProfileOwner && !this.props.inPreviewMode) ||
                this.props.getFileUrl(
                  this.props.company_files,
                  "company pitchdeck"
                ) !== 0) && (
                <div>
                  <br />
                  <div class="row">
                    {this.props.userIsProfileOwner && (
                      <div class="col">
                        <button
                          class={classNames("btn btn-primary btn-block", {
                            disabled: this.props.showEditPitchDeck,
                          })}
                          onClick={(event) => {
                            this.handleAllowInvestor(event);
                          }}
                        >
                          {!this.props.allow_investor
                            ? "Set Pitch Deck to Public"
                            : "Set Pitch Deck to Private"}
                        </button>
                      </div>
                    )}
                    {this.props.getFileUrl(
                      this.props.company_files,
                      "company pitchdeck"
                    ) !== 0 && (
                      <div class="col">
                        <a
                          class={classNames("btn btn-primary btn-block", {
                            disabled: this.props.showEditPitchDeck,
                          })}
                          href={this.props.getFileUrl(
                            this.props.company_files,
                            "company pitchdeck"
                          )}
                        >
                          Download Pitch Deck
                        </a>
                      </div>
                    )}
                    <div>
                      {this.props.userIsProfileOwner && (
                        <div class="col">
                          <a
                            class={classNames("btn btn-primary btn-block", {
                              disabled: this.props.showEditPitchDeck,
                            })}
                            onClick={() =>
                              this.props.handleEdit("showEditPitchDeck")
                            }
                          >
                            Upload New Pitch Deck
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                  {this.props.showEditPitchDeck && (
                    <div class="row">
                      <div class="col">
                        <br />
                        <div class="card">
                          <div class="card-body">
                            <div className="form-group">
                              <FormLabel className="control-label">
                                Pitch Deck
                              </FormLabel>
                              <Form.Control
                                type="file"
                                data-file-type="pitchdeck"
                                placeholder="pitchdeck"
                                accept=".jpg,.jpeg,.png,.pdf,.doc,.docx"
                                name="pitchdeck"
                                onChange={this.props.handleFileUpload}
                                className="form-control mb-3"
                              />
                            </div>
                            <div className="form-group">
                              <button
                                class="btn btn-primary"
                                name="showEditPitchDeck"
                                onClick={this.props.handleInputSubmit}
                              >
                                Save
                              </button>
                              <button
                                class="btn btn-white"
                                onClick={() =>
                                  this.props.handleEdit("showEditPitchDeck")
                                }
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {((this.props.userIsProfileOwner && !this.props.inPreviewMode) ||
                this.props.process_pitch_video.length !== 0 ||
                this.props.objective.length !== 0) && (
                <Row>
                  <Col>
                    <Card className="video-card" style={{ width: "100%" }}>
                      {!this.props.showEditTen && (
                        <div>
                          {((this.props.userIsProfileOwner &&
                            !this.props.inPreviewMode) ||
                            this.props.process_pitch_video.length !== 0) && (
                            <iframe
                              width="100%"
                              height="500px"
                              style={{ margin: 0 }}
                              src={this.props.process_pitch_video}
                              frameborder="0"
                              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                              allowfullscreen
                            ></iframe>
                          )}
                        </div>
                      )}
                      <Card.Body className="video-card-body">
                        {this.props.userIsProfileOwner &&
                          !this.props.inPreviewMode && (
                            <i
                              style={{ marginTop: "10px" }}
                              onClick={() =>
                                this.props.handleEdit("showEditTen")
                              }
                              className={
                                "fa fa-pencil " +
                                (this.props.showEditTen
                                  ? "img-card-icon-clicked"
                                  : "img-card-icon")
                              }
                            ></i>
                          )}
                        {!this.props.showEditTen && (
                          <div>
                            {((this.props.userIsProfileOwner &&
                              !this.props.inPreviewMode) ||
                              this.props.objective.length !== 0) && (
                              <div>
                                <Card.Title>Objective</Card.Title>
                                <Card.Text style={{ fontSize: "1.1rem" }}>
                                  {this.props.objective}
                                </Card.Text>
                              </div>
                            )}
                          </div>
                        )}
                        {this.props.showEditTen && (
                          <div>
                            <Card.Title>
                              Please enter the url for promotional video you
                              have on youtube
                            </Card.Title>
                            <TextField
                              id="outlined-multiline-static"
                              style={{ width: "90%" }}
                              value={this.props.pitch_video_link}
                              name="pitch_video_link"
                              className={classes.textField}
                              onChange={this.props.handleChange}
                              placeholder="https://www.youtube.com/watch?v=rnlIE9i10_Y"
                              margin="normal"
                              variant="outlined"
                            />
                            <Card.Title>
                              Please enter your company's objective
                            </Card.Title>
                            <TextField
                              id="outlined-multiline-static"
                              multiline
                              style={{ width: "90%" }}
                              rows="4"
                              value={this.props.objective}
                              name="objective"
                              className={classes.textField}
                              onChange={this.props.handleChange}
                              margin="normal"
                              variant="outlined"
                            />
                            <Button
                              variant="contained"
                              name="showEditTen"
                              onClick={this.props.handleInputSubmit}
                              style={{
                                backgroundColor: "#007bff",
                                textAlign: "center",
                                float: "right",
                              }}
                              className={classes.button}
                            >
                              Save
                            </Button>
                          </div>
                        )}
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              )}
              {((this.props.userIsProfileOwner && !this.props.inPreviewMode) ||
                this.props.profitable.length !== 0) && (
                <Row>
                  <Col>
                    <div className="justify-content-md-center post-revenue">
                      {this.props.userIsProfileOwner && (
                        <i
                          style={{ marginTop: "0px" }}
                          onClick={() =>
                            this.props.handleEdit("showEditEleven")
                          }
                          className={
                            "fa fa-pencil " +
                            (this.props.showEditEleven
                              ? "img-card-icon-clicked"
                              : "img-card-icon")
                          }
                        ></i>
                      )}
                      {!this.props.showEditEleven && (
                        <div>
                          {this.props.profitable.length > 0 ? (
                            <div>{this.props.profitable}</div>
                          ) : (
                            <div>Has your company made money yet?</div>
                          )}
                        </div>
                      )}
                      {this.props.showEditEleven && (
                        <div>
                          <p>Has your company made revenue?</p>
                          <FormControlLabel
                            control={
                              <Radio
                                checked={
                                  this.props.profitable === "POST-REVENUE"
                                }
                                onChange={this.props.handleChange}
                                value="POST-REVENUE"
                                name="profitable"
                                aria-label="A"
                              />
                            }
                            label="YES"
                          />
                          <FormControlLabel
                            control={
                              <Radio
                                checked={
                                  this.props.profitable === "PRE-REVENUE"
                                }
                                onChange={this.props.handleChange}
                                value="PRE-REVENUE"
                                name="profitable"
                                aria-label="A"
                              />
                            }
                            label="NO"
                          />
                          <Button
                            variant="contained"
                            name="showEditEleven"
                            onClick={this.props.handleInputSubmit}
                            style={{
                              backgroundColor: "#007bff",
                              textAlign: "center",
                              float: "right",
                            }}
                            className={classes.button}
                          >
                            Save
                          </Button>
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
              )}
            </div>
            <div>
              <Row className="justify-content-md-center am">
                {((this.props.userIsProfileOwner &&
                  !this.props.inPreviewMode) ||
                  this.props.attempting_to_raise !== 0) && (
                  <Col
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Card className="amount-card" style={{ width: "100%" }}>
                      {this.props.userIsProfileOwner && (
                        <i
                          style={{}}
                          onClick={() =>
                            this.props.handleEdit("showEditTwelve")
                          }
                          className={
                            "fa fa-pencil " +
                            (this.props.showEditTwelve
                              ? "amount-card-icon-clicked"
                              : "amount-card-icon")
                          }
                        ></i>
                      )}
                      <Card.Body className="amount-card-body">
                        <div className="title">
                          <Card.Title>
                            <b>Raise Amount</b>
                          </Card.Title>
                          {!this.props.showEditTwelve && (
                            <div>
                              <Card.Title className="amount-price">
                                <b>
                                  {this.props.getCurrencySymbol(
                                    this.props.currency,
                                    this.props.currencies
                                  )}
                                  {this.props.attempting_to_raise.toLocaleString(
                                    undefined,
                                    {
                                      minimumFractionDigits: 2,
                                    }
                                  )}
                                </b>
                              </Card.Title>
                              <Card.Text
                                style={{
                                  textAlign: "center",
                                  paddingTop: "5px",
                                }}
                              >
                                {this.props.currency}
                              </Card.Text>
                            </div>
                          )}
                        </div>
                        {this.props.showEditTwelve && (
                          <div>
                            <Card.Text
                              style={{ textAlign: "center", paddingTop: "5px" }}
                            >
                              <div style={{ textAlign: "center" }}>
                                <Grid
                                  container
                                  style={{
                                    width: "auto",
                                    display: "inline-block",
                                    textAlign: "center",
                                  }}
                                  spacing={8}
                                  alignItems="flex-end"
                                >
                                  <Grid item>
                                    <TextField
                                      type="number"
                                      id="input-with-icon-grid"
                                      name="attempting_to_raise"
                                      style={{ width: "100%" }}
                                      value={this.props.attempting_to_raise}
                                      onChange={this.props.handleChange}
                                    />
                                  </Grid>
                                </Grid>
                              </div>
                              <TextField
                                id="standard-select-currency"
                                select
                                className={classes.textField}
                                value={this.props.currency}
                                name="currency"
                                onChange={this.props.handleChangeCurrency(
                                  "currency"
                                )}
                                SelectProps={{
                                  MenuProps: { className: classes.menu },
                                }}
                                helperText="Please select your currency"
                                margin="normal"
                              >
                                {this.props.currencies.map((option) => (
                                  <MenuItem
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Card.Text>
                            <Button
                              variant="contained"
                              name="showEditTwelve"
                              onClick={this.props.handleInputSubmit}
                              style={{
                                backgroundColor: "#007bff",
                                textAlign: "center",
                                float: "right",
                              }}
                              className={classes.button}
                            >
                              Save
                            </Button>
                          </div>
                        )}
                      </Card.Body>
                    </Card>
                  </Col>
                )}
                {((this.props.userIsProfileOwner &&
                  !this.props.inPreviewMode) ||
                  this.props.evaluation !== 0) && (
                  <Col
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Card className="amount-card" style={{ width: "100%" }}>
                      {this.props.userIsProfileOwner && (
                        <i
                          style={{}}
                          onClick={() =>
                            this.props.handleEdit("showEditThirteen")
                          }
                          className={
                            "fa fa-pencil " +
                            (this.props.showEditThirteen
                              ? "amount-card-icon-clicked"
                              : "amount-card-icon")
                          }
                        ></i>
                      )}
                      <Card.Body className="amount-card-body">
                        <div className="title">
                          <Card.Title>
                            <b>Proposed Evaluation</b>
                          </Card.Title>
                          {!this.props.showEditThirteen && (
                            <div>
                              <Card.Title className="amount-price">
                                <b>
                                  {this.props.getCurrencySymbol(
                                    this.props.currency,
                                    this.props.currencies
                                  )}{" "}
                                  {this.props.evaluation.toLocaleString(
                                    undefined,
                                    {
                                      minimumFractionDigits: 2,
                                    }
                                  )}
                                </b>
                              </Card.Title>
                              <Card.Text
                                style={{
                                  textAlign: "center",
                                  paddingTop: "5px",
                                }}
                              >
                                {this.props.currency}
                              </Card.Text>
                            </div>
                          )}
                        </div>
                        {this.props.showEditThirteen && (
                          <div>
                            <Card.Text
                              style={{ textAlign: "center", paddingTop: "5px" }}
                            >
                              <div style={{ textAlign: "center" }}>
                                <Grid
                                  container
                                  style={{
                                    width: "auto",
                                    display: "inline-block",
                                    textAlign: "center",
                                  }}
                                  spacing={8}
                                  alignItems="flex-end"
                                >
                                  <Grid item>
                                    <TextField
                                      id="input-with-icon-grid"
                                      type="number"
                                      name="evaluation"
                                      style={{ width: "100%" }}
                                      value={this.props.evaluation}
                                      onChange={this.props.handleChange}
                                    />
                                  </Grid>
                                </Grid>
                              </div>
                              <TextField
                                id="standard-select-currency"
                                select
                                className={classes.textField}
                                value={this.props.currency}
                                name="currency"
                                onChange={this.props.handleChangeCurrency(
                                  "currency"
                                )}
                                SelectProps={{
                                  MenuProps: { className: classes.menu },
                                }}
                                helperText="Please select your currency"
                                margin="normal"
                              >
                                {this.props.currencies.map((option) => (
                                  <MenuItem
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Card.Text>
                            <Button
                              variant="contained"
                              name="showEditThirteen"
                              onClick={this.props.handleInputSubmit}
                              style={{
                                backgroundColor: "#007bff",
                                textAlign: "center",
                                float: "right",
                              }}
                              className={classes.button}
                            >
                              Save
                            </Button>
                          </div>
                        )}
                      </Card.Body>
                    </Card>
                  </Col>
                )}
              </Row>
              <Row
                className="justify-content-md-center "
                style={{ marginTop: "10px" }}
              >
                {((this.props.userIsProfileOwner &&
                  !this.props.inPreviewMode) ||
                  this.props.problem.length !== 0) && (
                  <Col
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Card className="month-card" style={{ width: "100%" }}>
                      {this.props.userIsProfileOwner && (
                        <i
                          style={{}}
                          onClick={() =>
                            this.props.handleEdit("showEditFourteen")
                          }
                          className={
                            "fa fa-pencil " +
                            (this.props.showEditFourteen
                              ? "amount-card-icon-clicked"
                              : "amount-card-icon")
                          }
                        ></i>
                      )}
                      <Card.Body className="amount-card-body">
                        {!this.props.showEditFourteen && (
                          <div className="title">
                            <Card.Title>
                              <b>Problem</b>
                            </Card.Title>
                            <Card.Text style={{ fontSize: "1.1rem" }}>
                              {this.props.problem}
                            </Card.Text>
                            <br />
                          </div>
                        )}
                        {this.props.showEditFourteen && (
                          <div>
                            <div className="title">
                              <Card.Title>
                                <b>Problem</b>
                              </Card.Title>
                              <textarea
                                type="textarea"
                                name="problem"
                                style={{ width: "80%", height: "100px" }}
                                value={this.props.problem}
                                onChange={this.props.handleChange}
                              />
                            </div>
                            <Button
                              variant="contained"
                              name="showEditFourteen"
                              onClick={this.props.handleInputSubmit}
                              style={{
                                backgroundColor: "#007bff",
                                textAlign: "center",
                                float: "right",
                              }}
                              className={classes.button}
                            >
                              Save
                            </Button>
                          </div>
                        )}
                      </Card.Body>
                    </Card>
                  </Col>
                )}
                {((this.props.userIsProfileOwner &&
                  !this.props.inPreviewMode) ||
                  this.props.solution.length !== 0) && (
                  <Col
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Card className="month-card" style={{ width: "100%" }}>
                      {this.props.userIsProfileOwner && (
                        <i
                          style={{}}
                          onClick={() =>
                            this.props.handleEdit("showEditFifteen")
                          }
                          className={
                            "fa fa-pencil " +
                            (this.props.showEditFifteen
                              ? "amount-card-icon-clicked"
                              : "amount-card-icon")
                          }
                        ></i>
                      )}
                      <Card.Body className="amount-card-body">
                        {!this.props.showEditFifteen && (
                          <div className="title">
                            <Card.Title>
                              <b>Solution</b>
                            </Card.Title>
                            <Card.Text style={{ fontSize: "1.1rem" }}>
                              {this.props.solution}
                            </Card.Text>
                            <br />
                          </div>
                        )}
                        {this.props.showEditFifteen && (
                          <div>
                            <div className="title">
                              <Card.Title>
                                <b>Solution</b>
                              </Card.Title>
                              <textarea
                                type="textarea"
                                name="solution"
                                style={{ width: "80%", height: "100px" }}
                                value={this.props.solution}
                                onChange={this.props.handleChange}
                              />
                            </div>
                            <Button
                              variant="contained"
                              name="showEditFifteen"
                              onClick={this.props.handleInputSubmit}
                              style={{
                                backgroundColor: "#007bff",
                                textAlign: "center",
                                float: "right",
                              }}
                              className={classes.button}
                            >
                              Save
                            </Button>
                          </div>
                        )}
                      </Card.Body>
                    </Card>
                  </Col>
                )}
              </Row>
              <Row
                className="justify-content-md-center "
                style={{ marginTop: "10px" }}
              >
                {((this.props.userIsProfileOwner &&
                  !this.props.inPreviewMode) ||
                  this.props.target_market.length !== 0) && (
                  <Col
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Card className="month-card" style={{ width: "100%" }}>
                      {this.props.userIsProfileOwner && (
                        <i
                          style={{}}
                          onClick={() =>
                            this.props.handleEdit("showEditSixteen")
                          }
                          className={
                            "fa fa-pencil " +
                            (this.props.showEditSixteen
                              ? "amount-card-icon-clicked"
                              : "amount-card-icon")
                          }
                        ></i>
                      )}
                      <Card.Body className="amount-card-body">
                        {!this.props.showEditSixteen && (
                          <div className="title">
                            <Card.Title>
                              <b>Target Market</b>
                            </Card.Title>
                            <Card.Text style={{ fontSize: "1.1rem" }}>
                              {this.props.target_market}
                            </Card.Text>
                            <br />
                          </div>
                        )}
                        {this.props.showEditSixteen && (
                          <div>
                            <div className="title">
                              <Card.Title>
                                <b>Target Market</b>
                              </Card.Title>
                              <textarea
                                type="textarea"
                                name="target_market"
                                style={{ width: "80%", height: "100px" }}
                                value={this.props.target_market}
                                onChange={this.props.handleChange}
                              />
                            </div>
                            <Button
                              variant="contained"
                              name="showEditSixteen"
                              onClick={this.props.handleInputSubmit}
                              style={{
                                backgroundColor: "#007bff",
                                textAlign: "center",
                                float: "right",
                              }}
                              className={classes.button}
                            >
                              Save
                            </Button>
                          </div>
                        )}
                      </Card.Body>
                    </Card>
                  </Col>
                )}
                {((this.props.userIsProfileOwner &&
                  !this.props.inPreviewMode) ||
                  this.props.market_size.length !== 0) && (
                  <Col
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Card className="month-card" style={{ width: "100%" }}>
                      {this.props.userIsProfileOwner && (
                        <i
                          style={{}}
                          onClick={() =>
                            this.props.handleEdit("showEditSeventeen")
                          }
                          className={
                            "fa fa-pencil " +
                            (this.props.showEditSeventeen
                              ? "amount-card-icon-clicked"
                              : "amount-card-icon")
                          }
                        ></i>
                      )}
                      <Card.Body className="amount-card-body">
                        {!this.props.showEditSeventeen && (
                          <div className="title">
                            <Card.Title>
                              <b>Market Size</b>
                            </Card.Title>
                            <Card.Text style={{ fontSize: "1.1rem" }}>
                              {this.props.market_size}
                            </Card.Text>
                            <br />
                          </div>
                        )}
                        {this.props.showEditSeventeen && (
                          <div>
                            <div className="title">
                              <Card.Title>
                                <b>Market Size</b>
                              </Card.Title>
                              <textarea
                                type="textarea"
                                name="market_size"
                                style={{ width: "80%", height: "100px" }}
                                value={this.props.market_size}
                                onChange={this.props.handleChange}
                              />
                            </div>
                            <Button
                              variant="contained"
                              name="showEditSeventeen"
                              onClick={this.props.handleInputSubmit}
                              style={{
                                backgroundColor: "#007bff",
                                textAlign: "center",
                                float: "right",
                              }}
                              className={classes.button}
                            >
                              Save
                            </Button>
                          </div>
                        )}
                      </Card.Body>
                    </Card>
                  </Col>
                )}
              </Row>
              <Row
                className="justify-content-md-center "
                style={{ marginTop: "10px" }}
              >
                {((this.props.userIsProfileOwner &&
                  !this.props.inPreviewMode) ||
                  this.props.competitors.length !== 0) && (
                  <Col
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Card className="month-card" style={{ width: "100%" }}>
                      {this.props.userIsProfileOwner && (
                        <i
                          style={{}}
                          onClick={() =>
                            this.props.handleEdit("showEditEighteen")
                          }
                          className={
                            "fa fa-pencil " +
                            (this.props.showEditEighteen
                              ? "amount-card-icon-clicked"
                              : "amount-card-icon")
                          }
                        ></i>
                      )}
                      <Card.Body className="amount-card-body">
                        {!this.props.showEditEighteen && (
                          <div className="title">
                            <Card.Title>
                              <b>Competitors</b>
                            </Card.Title>
                            <Card.Text style={{ fontSize: "1.1rem" }}>
                              {this.props.competitors}
                            </Card.Text>
                            <br />
                          </div>
                        )}
                        {this.props.showEditEighteen && (
                          <div>
                            <div className="title">
                              <Card.Title>
                                <b>Competitors</b>
                              </Card.Title>
                              <textarea
                                type="textarea"
                                name="competitors"
                                style={{ width: "80%", height: "100px" }}
                                value={this.props.competitors}
                                onChange={this.props.handleChange}
                              />
                            </div>
                            <Button
                              variant="contained"
                              name="showEditEighteen"
                              onClick={this.props.handleInputSubmit}
                              style={{
                                backgroundColor: "#007bff",
                                textAlign: "center",
                                float: "right",
                              }}
                              className={classes.button}
                            >
                              Save
                            </Button>
                          </div>
                        )}
                      </Card.Body>
                    </Card>
                  </Col>
                )}
                {((this.props.userIsProfileOwner &&
                  !this.props.inPreviewMode) ||
                  this.props.differentiation.length !== 0) && (
                  <Col
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Card className="month-card" style={{ width: "100%" }}>
                      {this.props.userIsProfileOwner && (
                        <i
                          style={{}}
                          onClick={() =>
                            this.props.handleEdit("showEditNineteen")
                          }
                          className={
                            "fa fa-pencil " +
                            (this.props.showEditNineteen
                              ? "amount-card-icon-clicked"
                              : "amount-card-icon")
                          }
                        ></i>
                      )}
                      <Card.Body className="amount-card-body">
                        {!this.props.showEditNineteen && (
                          <div className="title">
                            <Card.Title>
                              <b>Differentiation</b>
                            </Card.Title>
                            <Card.Text style={{ fontSize: "1.1rem" }}>
                              {this.props.differentiation}
                            </Card.Text>
                            <br />
                          </div>
                        )}
                        {this.props.showEditNineteen && (
                          <div>
                            <div className="title">
                              <Card.Title>
                                <b>Differentiation</b>
                              </Card.Title>
                              <textarea
                                type="textarea"
                                name="differentiation"
                                style={{ width: "80%", height: "100px" }}
                                value={this.props.differentiation}
                                onChange={this.props.handleChange}
                              />
                            </div>
                            <Button
                              variant="contained"
                              name="showEditNineteen"
                              onClick={this.props.handleInputSubmit}
                              style={{
                                backgroundColor: "#007bff",
                                textAlign: "center",
                                float: "right",
                              }}
                              className={classes.button}
                            >
                              Save
                            </Button>
                          </div>
                        )}
                      </Card.Body>
                    </Card>
                  </Col>
                )}
              </Row>
              <Row
                className="justify-content-md-center "
                style={{ marginTop: "10px" }}
              >
                {((this.props.userIsProfileOwner &&
                  !this.props.inPreviewMode) ||
                  this.props.revenue_model.length !== 0) && (
                  <Col
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Card className="month-card" style={{ width: "100%" }}>
                      {this.props.userIsProfileOwner && (
                        <i
                          style={{}}
                          onClick={() =>
                            this.props.handleEdit("showEditTwenty")
                          }
                          className={
                            "fa fa-pencil " +
                            (this.props.showEditTwenty
                              ? "amount-card-icon-clicked"
                              : "amount-card-icon")
                          }
                        ></i>
                      )}
                      <Card.Body className="amount-card-body">
                        {!this.props.showEditTwenty && (
                          <div className="title">
                            <Card.Title>
                              <b>Revenue Model</b>
                            </Card.Title>
                            <Card.Text style={{ fontSize: "1.1rem" }}>
                              {this.props.revenue_model}
                            </Card.Text>
                            <br />
                          </div>
                        )}
                        {this.props.showEditTwenty && (
                          <div>
                            <div className="title">
                              <Card.Title>
                                <b>Revenue Model</b>
                              </Card.Title>
                              <textarea
                                type="textarea"
                                name="revenue_model"
                                style={{ width: "80%", height: "100px" }}
                                value={this.props.revenue_model}
                                onChange={this.props.handleChange}
                              />
                            </div>
                            <Button
                              variant="contained"
                              name="showEditTwenty"
                              onClick={this.props.handleInputSubmit}
                              style={{
                                backgroundColor: "#007bff",
                                textAlign: "center",
                                float: "right",
                              }}
                              className={classes.button}
                            >
                              Save
                            </Button>
                          </div>
                        )}
                      </Card.Body>
                    </Card>
                  </Col>
                )}
                {((this.props.userIsProfileOwner &&
                  !this.props.inPreviewMode) ||
                  this.props.revenue_since_inception.length !== 0) && (
                  <Col
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Card className="month-card" style={{ width: "100%" }}>
                      {this.props.userIsProfileOwner && (
                        <i
                          style={{}}
                          onClick={() =>
                            this.props.handleEdit("showEditTwentyOne")
                          }
                          className={
                            "fa fa-pencil " +
                            (this.props.showEditTwentyOne
                              ? "amount-card-icon-clicked"
                              : "amount-card-icon")
                          }
                        ></i>
                      )}
                      <Card.Body className="amount-card-body">
                        {!this.props.showEditTwentyOne && (
                          <div className="title">
                            <Card.Title>
                              <b>Revenue Since Inception</b>
                            </Card.Title>
                            <Card.Text style={{ fontSize: "1.1rem" }}>
                              {this.props.revenue_since_inception}
                            </Card.Text>
                            <br />
                          </div>
                        )}
                        {this.props.showEditTwentyOne && (
                          <div>
                            <div className="title">
                              <Card.Title>
                                <b>Revenue Since Inception</b>
                              </Card.Title>
                              <textarea
                                type="textarea"
                                name="revenue_since_inception"
                                style={{ width: "80%", height: "100px" }}
                                value={this.props.revenue_since_inception}
                                onChange={this.props.handleChange}
                              />
                            </div>
                            <Button
                              variant="contained"
                              name="showEditTwentyOne"
                              onClick={this.props.handleInputSubmit}
                              style={{
                                backgroundColor: "#007bff",
                                textAlign: "center",
                                float: "right",
                              }}
                              className={classes.button}
                            >
                              Save
                            </Button>
                          </div>
                        )}
                      </Card.Body>
                    </Card>
                  </Col>
                )}
              </Row>
              <Row
                className="justify-content-md-center "
                style={{ marginTop: "10px" }}
              >
                {((this.props.userIsProfileOwner &&
                  !this.props.inPreviewMode) ||
                  this.props.traction.length !== 0) && (
                  <Col
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Card className="month-card" style={{ width: "100%" }}>
                      {this.props.userIsProfileOwner && (
                        <i
                          style={{}}
                          onClick={() =>
                            this.props.handleEdit("showEditTwentyTwo")
                          }
                          className={
                            "fa fa-pencil " +
                            (this.props.showEditTwentyTwo
                              ? "amount-card-icon-clicked"
                              : "amount-card-icon")
                          }
                        ></i>
                      )}
                      <Card.Body className="amount-card-body">
                        {!this.props.showEditTwentyTwo && (
                          <div className="title">
                            <Card.Title>
                              <b>Traction</b>
                            </Card.Title>
                            <Card.Text style={{ fontSize: "1.1rem" }}>
                              {this.props.traction}
                            </Card.Text>
                            <br />
                          </div>
                        )}
                        {this.props.showEditTwentyTwo && (
                          <div>
                            <div className="title">
                              <Card.Title>
                                <b>Traction</b>
                              </Card.Title>
                              <textarea
                                type="textarea"
                                name="traction"
                                style={{ width: "80%", height: "100px" }}
                                value={this.props.traction}
                                onChange={this.props.handleChange}
                              />
                            </div>
                            <Button
                              variant="contained"
                              name="showEditTwentyTwo"
                              onClick={this.props.handleInputSubmit}
                              style={{
                                backgroundColor: "#007bff",
                                textAlign: "center",
                                float: "right",
                              }}
                              className={classes.button}
                            >
                              Save
                            </Button>
                          </div>
                        )}
                      </Card.Body>
                    </Card>
                  </Col>
                )}
                {((this.props.userIsProfileOwner &&
                  !this.props.inPreviewMode) ||
                  this.props.marketing_strategy.length !== 0) && (
                  <Col
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Card className="month-card" style={{ width: "100%" }}>
                      {this.props.userIsProfileOwner && (
                        <i
                          style={{}}
                          onClick={() =>
                            this.props.handleEdit("showEditTwentyThree")
                          }
                          className={
                            "fa fa-pencil " +
                            (this.props.showEditTwentyThree
                              ? "amount-card-icon-clicked"
                              : "amount-card-icon")
                          }
                        ></i>
                      )}
                      <Card.Body className="amount-card-body">
                        {!this.props.showEditTwentyThree && (
                          <div className="title">
                            <Card.Title>
                              <b>Marketing Strategy</b>
                            </Card.Title>
                            <Card.Text style={{ fontSize: "1.1rem" }}>
                              {this.props.marketing_strategy}
                            </Card.Text>
                            <br />
                          </div>
                        )}
                        {this.props.showEditTwentyThree && (
                          <div>
                            <div className="title">
                              <Card.Title>
                                <b>Marketing Strategy</b>
                              </Card.Title>
                              <textarea
                                type="textarea"
                                name="marketing_strategy"
                                style={{ width: "80%", height: "100px" }}
                                value={this.props.marketing_strategy}
                                onChange={this.props.handleChange}
                              />
                            </div>
                            <Button
                              variant="contained"
                              name="showEditTwentyThree"
                              onClick={this.props.handleInputSubmit}
                              style={{
                                backgroundColor: "#007bff",
                                textAlign: "center",
                                float: "right",
                              }}
                              className={classes.button}
                            >
                              Save
                            </Button>
                          </div>
                        )}
                      </Card.Body>
                    </Card>
                  </Col>
                )}
              </Row>
            </div>
          </div>
        ) : (
          <div>
            <CompanyNavBar
              menuSelection={this.props.menuSelection}
              handleMenuChange={this.props.handleMenuChange}
              company_type={this.props.company_type}
              allow_investor={this.props.allow_investor}
              companies_owned={this.props.companies_owned}
              userIsProfileOwner={this.props.userIsProfileOwner}
            />
            <h3 style={{ marginTop: "10px", textAlign: "center" }}>
              You do not have access to view this page!
            </h3>
          </div>
        )}
      </React.Fragment>
    );
  }
}
CustomizedTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CustomizedTable);
