import React from 'react';

const ForumIcon = props => (
    <svg>
        <path fill="#9A9FBF" d="M20,2H4C2.896,2,2,2.896,2,4v16c0,1.104,0.896,2,2,2h16c1.104,0,2-0.896,2-2v-2.001h-2V20H4V9.999h16v2h2
        V4C22,2.896,21.104,2,20,2z M20,7.999H4V4h16V7.999z M20,16h2v-2h-2V16z M7,4.999H5v2h2V4.999z M10,4.999H8v2h2V4.999z M13,4.999
        h-2v2h2V4.999z M9,11.999H7v2h2V11.999z M17,13.999v-2h-2v2H17z M11.986,17.995c1.655,0,2.997-0.849,2.997-1.896V16H8.991v0.102
        C8.991,17.146,10.332,17.995,11.986,17.995z"></path>
    </svg>
)

export default ForumIcon;