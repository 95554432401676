import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import axios from "axios";
import Icon from "@material-ui/core/Icon";
import { AppContext } from "../../App";

const styles = (theme) => ({
  root: {
    flex: 1,
  },
  text: {
    paddingTop: theme.spacing.unit * 2,
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
  },
  list: {
    flex: 1,
    padding: 0,
  },
  subHeader: {
    backgroundColor: theme.palette.background.paper,
  },
  media: {
    objectFit: "cover",
    height: "55vh",
    maxHeight: 480,
  },
  avatar: {
    position: "absolute",
    top: -90,
    margin: 10,
    width: 150,
    border: 2,
    background: "white",
    boxShadow: "0 3px 5px 2px lightgrey",
    height: 150,
  },
  button: {
    margin: theme.spacing.unit,
  },
  input: {
    display: "none",
  },
});

const options = [
  {
    label: "",
    value: "",
  },
  {
    label: "A+",
    value: "A+",
  },
  {
    label: "A",
    value: "A",
  },
  {
    label: "A-",
    value: "A-",
  },
  {
    label: "B+",
    value: "B+",
  },

  {
    label: "B",
    value: "B",
  },

  {
    label: "B-",
    value: "B-",
  },

  {
    label: "C+",
    value: "C+",
  },
  {
    label: "C",
    value: "C",
  },
  {
    label: "C-",
    value: "C-",
  },
  {
    label: "N/A",
    value: "N/A",
  },
];

class CFA_Container extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <CFA {...this.props} {...this.context} />;
  }
}

CFA_Container.contextType = AppContext;

class CFA extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rating: "",
      isOpen: false,
      initial_view: true,
      display_question_one: false,
      display_question_two: false,
      display_question_three: false,
      display_question_four: false,
      display_question_five: false,
      display_question_six: false,
      display_question_seven: false,
      display_question_eight: false,
      data_response: false,
      response_rating: "",
      question_one_answer: "",
      question_two_answer: "",
      question_three_answer: "",
      question_four_answer: "",
      question_five_answer: "",
      question_six_answer: "",
      question_seven_answer: "",
      question_eight_answer: "",
      // totals
      one_total: "",
      two_total: "",
      three_total: "",
      four_total: "",
      five_total: "",
      six_total: "",
      seven_total: "",
      eight_total: "",
    };

    this.handleOneChange = this.handleOneChange.bind(this);
    this.handleTwoChange = this.handleTwoChange.bind(this);
    this.handleThreeChange = this.handleThreeChange.bind(this);
    this.handleFourChange = this.handleFourChange.bind(this);
    this.handleFiveChange = this.handleFiveChange.bind(this);
    this.handleSixChange = this.handleSixChange.bind(this);
    this.handleSevenChange = this.handleSevenChange.bind(this);
    this.handleEightChange = this.handleEightChange.bind(this);
  }

  handleOneChange(e) {
    this.setState({ question_one_answer: e.target.value });
    if (e.target.value === "A+") {
      this.setState({ one_total: "12.5" });
      console.log(e.target.value);
    } else if (e.target.value === "A") {
      this.setState({ one_total: "12 " });
      console.log(e.target.value);
    } else if (e.target.value === "A-") {
      this.setState({ one_total: "11.5" });
      console.log(e.target.value);
    } else if (e.target.value === "B+") {
      console.log(e.target.value);

      this.setState({ one_total: " 6.75 " });
    } else if (e.target.value === "B") {
      this.setState({ one_total: "6.25 " });
    } else if (e.target.value === "B-") {
      this.setState({ one_total: " 5.75 " });
    } else if (e.target.value === "C+") {
      this.setState({ one_total: "1.25" });
    } else if (e.target.value === "C") {
      this.setState({ one_total: "1.0" });
    } else if (e.target.value === "C-") {
      this.setState({ one_total: " 0.25" });
    }
  }

  handleTwoChange(e) {
    this.setState({ question_two_answer: e.target.value });

    if (e.target.value === "A+") {
      this.setState({ two_total: "12.5 " });
    } else if (e.target.value === "A") {
      this.setState({ two_total: " 12" });
    } else if (e.target.value === "A-") {
      this.setState({ two_total: "11.5" });
    } else if (e.target.value === "B+") {
      this.setState({ two_total: "6.75" });
    } else if (e.target.value === "B") {
      this.setState({ two_total: "6.25" });
    } else if (e.target.value === "B-") {
      this.setState({ two_total: " 5.75" });
    } else if (e.target.value === "C+") {
      this.setState({ two_total: "1.25" });
    } else if (e.target.value === "C") {
      this.setState({ two_total: " 1.0" });
    } else if (e.target.value === "C-") {
      this.setState({ two_total: "0.25" });
    }
  }

  handleThreeChange(e) {
    this.setState({ question_three_answer: e.target.value });

    if (e.target.value === "A+") {
      this.setState({ three_total: "12.5 " });
    } else if (e.target.value === "A") {
      this.setState({ three_total: " 12" });
    } else if (e.target.value === "A-") {
      this.setState({ three_total: "11.5" });
    } else if (e.target.value === "B+") {
      this.setState({ three_total: "6.75" });
    } else if (e.target.value === "B") {
      this.setState({ three_total: "6.25" });
    } else if (e.target.value === "B-") {
      this.setState({ three_total: " 5.75" });
    } else if (e.target.value === "C+") {
      this.setState({ three_total: "1.25" });
    } else if (e.target.value === "C") {
      this.setState({ three_total: " 1.0" });
    } else if (e.target.value === "C-") {
      this.setState({ three_total: "0.25" });
    }
  }

  handleFourChange(e) {
    this.setState({ question_four_answer: e.target.value });

    if (e.target.value === "A+") {
      this.setState({ four_total: "12.5 " });
    } else if (e.target.value === "A") {
      this.setState({ four_total: " 12" });
    } else if (e.target.value === "A-") {
      this.setState({ four_total: "11.5" });
    } else if (e.target.value === "B+") {
      this.setState({ four_total: "6.75" });
    } else if (e.target.value === "B") {
      this.setState({ four_total: "6.25" });
    } else if (e.target.value === "B-") {
      this.setState({ four_total: " 5.75" });
    } else if (e.target.value === "C+") {
      this.setState({ four_total: "1.25" });
    } else if (e.target.value === "C") {
      this.setState({ four_total: " 1.0" });
    } else if (e.target.value === "C-") {
      this.setState({ four_total: "0.25" });
    }
  }

  handleFiveChange(e) {
    this.setState({ question_five_answer: e.target.value });

    if (e.target.value === "A+") {
      this.setState({ five_total: "12.5 " });
    } else if (e.target.value === "A") {
      this.setState({ five_total: " 12" });
    } else if (e.target.value === "A-") {
      this.setState({ five_total: "11.5" });
    } else if (e.target.value === "B+") {
      this.setState({ five_total: "6.75" });
    } else if (e.target.value === "B") {
      this.setState({ five_total: "6.25" });
    } else if (e.target.value === "B-") {
      this.setState({ five_total: " 5.75" });
    } else if (e.target.value === "C+") {
      this.setState({ five_total: "1.25" });
    } else if (e.target.value === "C") {
      this.setState({ five_total: " 1.0" });
    } else if (e.target.value === "C-") {
      this.setState({ five_total: "0.25" });
    }
  }

  handleSixChange(e) {
    this.setState({ question_six_answer: e.target.value });

    if (e.target.value === "A+") {
      this.setState({ six_total: "12.5 " });
    } else if (e.target.value === "A") {
      this.setState({ six_total: " 12" });
    } else if (e.target.value === "A-") {
      this.setState({ six_total: "11.5" });
    } else if (e.target.value === "B+") {
      this.setState({ six_total: "6.75" });
    } else if (e.target.value === "B") {
      this.setState({ six_total: "6.25" });
    } else if (e.target.value === "B-") {
      this.setState({ six_total: " 5.75" });
    } else if (e.target.value === "C+") {
      this.setState({ six_total: "1.25" });
    } else if (e.target.value === "C") {
      this.setState({ six_total: " 1.0" });
    } else if (e.target.value === "C-") {
      this.setState({ six_total: "0.25" });
    }
  }
  handleSevenChange(e) {
    this.setState({ question_seven_answer: e.target.value });

    if (e.target.value === "A+") {
      this.setState({ seven_total: "12.5 " });
    } else if (e.target.value === "A") {
      this.setState({ seven_total: " 12" });
    } else if (e.target.value === "A-") {
      this.setState({ seven_total: "11.5" });
    } else if (e.target.value === "B+") {
      this.setState({ seven_total: "6.75" });
    } else if (e.target.value === "B") {
      this.setState({ seven_total: "6.25" });
    } else if (e.target.value === "B-") {
      this.setState({ seven_total: " 5.75" });
    } else if (e.target.value === "C+") {
      this.setState({ seven_total: "1.25" });
    } else if (e.target.value === "C") {
      this.setState({ seven_total: " 1.0" });
    } else if (e.target.value === "C-") {
      this.setState({ seven_total: "0.25" });
    }
  }

  handleEightChange(e) {
    this.setState({ question_eight_answer: e.target.value });

    if (e.target.value === "A+") {
      this.setState({ eight_total: "12.5 " });
    } else if (e.target.value === "A") {
      this.setState({ eight_total: " 12" });
    } else if (e.target.value === "A-") {
      this.setState({ eight_total: "11.5" });
    } else if (e.target.value === "B+") {
      this.setState({ eight_total: "6.75" });
    } else if (e.target.value === "B") {
      this.setState({ eight_total: "6.25" });
    } else if (e.target.value === "B-") {
      this.setState({ eight_total: " 5.75" });
    } else if (e.target.value === "C+") {
      this.setState({ eight_total: "1.25" });
    } else if (e.target.value === "C") {
      this.setState({ eight_total: " 1.0" });
    } else if (e.target.value === "C-") {
      this.setState({ eight_total: "0.25" });
    }
  }

  beginAssessment = () => {
    this.setState({ initial_view: false, display_question_one: true });
  };

  postCFA = (e, company_id) => {
    e.preventDefault();
    const newrate = 0;

    const total =
      Number(this.state.one_total) +
      Number(this.state.two_total) +
      Number(this.state.three_total) +
      Number(this.state.four_total) +
      Number(this.state.five_total) +
      Number(this.state.six_total) +
      Number(this.state.seven_total) +
      Number(this.state.eight_total);
    console.log("the first start here", total);

    if (total <= 20) {
      this.setState({ rating: " 1" });
      console.log("1");

      localStorage.setItem("rating", "1");
      this.postData();
    } else if (total >= 20 && total <= 40) {
      console.log("2");

      this.setState({ rating: "2" });
      localStorage.setItem("rating", "2");

      this.postData();
      console.log("3");
    } else if (total >= 40 && total <= 60) {
      this.setState({ rating: "3" });
      localStorage.setItem("rating", "3");

      this.postData();
      console.log("3");
    } else if (total >= 60 && total <= 80) {
      this.setState({ rating: "4" });
      localStorage.setItem("rating", "4");

      this.postData();
      console.log("4");
    } else if (total >= 80) {
      this.setState({ rating: "5" });
      localStorage.setItem("rating", "5");

      this.postData();
      console.log("5");
    }
  };

  postData = (company_id) => {
    console.log("start the process");
    const rating = localStorage.getItem("rating");
    const com_id = localStorage.getItem("com_id");

    axios
      .post("/create_fundability", {
        question_one_answer: this.state.question_one_answer,
        question_two_answer: this.state.question_two_answer,
        question_three_answer: this.state.question_three_answer,
        question_four_answer: this.state.question_four_answer,
        question_five_answer: this.state.question_five_answer,
        question_six_answer: this.state.question_six_answer,
        question_seven_answer: this.state.question_seven_answer,
        question_eight_answer: this.state.question_eight_answer,
        affiliated_company: com_id,

        ratings: rating,
      })
      .then((response) => {
        console.log("get the fundabiity response", response.data.result.rating);
        if (response.data.status === "success") {
          this.setState({
            display_question_eight: false,
            data_response: true,
            response_rating: response.data.result.ratings,
          });
          localStorage.setItem("response_rating", response.data.result.ratings);
        }
      });
  };

  render() {
    const { classes } = this.props;

    return (
      <>
        <div
          style={{
            background: "#3f51b5",
            width: "100%",
          }}
        >
          <h2 style={{ color: "white" }}>Critical Factor Assessment (CFA)</h2>
        </div>

        {this.state.initial_view && (
          <div>
            <div>
              This self‐assessment is designed to give you quick feedback on
              your project or business idea. The questions are designed to help
              you weigh your project or business idea to see how it compares and
              stands up against other opportunities. Once you have completed
              this self‐assessment, you can use it as a basis for re‐thinking
              your original idea or progressing it to the next stage. The
              assessment will cover the following 8 critical factors:
              <br />
              <br />
              <ul style={{ marginLeft: "-22px" }}>
                <li> Product - Performance Advantage</li>
                <li>Product – Market Readiness</li>
                <li>Product – Uniqueness</li>
                <li>Market – First Customer</li>
                <li>Market – Distribution</li>
                <li>Market – Market Size</li>
                <li>The Entrepreneur</li>
                <li>The Financial Plan</li>
              </ul>
              Please remember there is no pass or fail and the overall score
              does not matter. What does matter is how you address each of the
              areas that require attention. Each factor has been rated on a
              three point scale A through C, with a plus (+) or minus (‐) used
              to further moderate a rating. Ideally, you should find ways to
              move C ratings to B or even A ratings but you should be aware,
              that it isn’t always possible. Please note that while the ratings
              change for each situation and some factors may be less relevant or
              irrelevant to your innovation, the descriptions for each of these
              8 critical factors do not.
              <br />
              Ideally, you should find ways to move C ratings to B or even A
              ratings but you should be aware, that it isn’t always possible.
              <br />
              Please note that while the ratings change for each situation and
              some factors may be less relevant or irrelevant to your
              innovation, the descriptions for each of these 8 critical factors
              do not.
              <br />
              <br />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  onClick={() => {
                    this.setState({
                      display_question_one: true,
                      initial_view: false,
                    });
                  }}
                  id="buttons-grid-opp-button"
                  variant="contained"
                  color="primary"
                  xs={12}
                  style={{ background: "#3f51b5", fontWeight: "bolder" }}
                >
                  Begin Assessment
                </Button>
              </div>
            </div>
          </div>
        )}

        {this.state.display_question_one && (
          <div>
            <h6 style={{ fontWeight: "bold" }}>
              Product - Performance AdvantageQuestion 1 of 8
            </h6>
            <br />
            This factor is about demand for your product or service and how they
            match to customer demand or expectations. If you are a new business,
            then you have a challenge in persuading new customers to purchase a
            product or service from you. You need to be able to show a potential
            customer that what you offer has significant benefits over current
            solutions. You also need to be able to demonstrate that customers
            directly in your target market will want to purchase the product, in
            preference to not purchasing anything or sourcing an alternate
            solution.
            <br />
            <span style={{ fontWeight: "bold" }}>
              Does your proposed product or service offer performance advantages
              compared to currently deployed solutions?
            </span>
            <br />
            A) There are substantial advantages to our product or service over
            current deployed solutions, or there is no one yet offering a
            comparable solution.
            <br />
            B) Our solution has measurable benefits over current deployed
            solutions or offers substantive advantages in a market niche.
            <br />
            C) Our solution meets the performance of current deployed solutions
            at a competitive cost.
            <br />
            <label>
              Select your answer:
              <br />
              <select
                value={this.state.question_one_answer}
                onChange={this.handleOneChange}
                style={{
                  // border: "2px solid #29BD00",
                  // borderRadius: "12px",
                  height: "40px",
                  padding: "0px 0px 0px 10px ",
                  backgroundColor: "white",
                  margin: "0px 2px 0px 0px ",
                  fontSize: 14,
                  width: "50%",
                }}
              >
                {options.map((option) => (
                  <option value={option.value}>{option.label}</option>
                ))}
              </select>
            </label>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                onClick={() => {
                  this.setState({
                    initial_view: true,
                    display_question_one: false,
                  });
                }}
                id="buttons-grid-opp-button"
                variant="contained"
                color="primary"
                style={{ background: "#3f51b5", fontWeight: "bolder" }}
                xs={12}
              >
                Back
              </Button>

              <Button
                onClick={() => {
                  this.setState({
                    display_question_one: false,
                    display_question_two: true,
                  });
                }}
                id="buttons-grid-opp-button"
                variant="contained"
                color="primary"
                style={{ background: "#3f51b5", fontWeight: "bolder" }}
                xs={12}
              >
                Next
              </Button>
            </div>
          </div>
        )}

        {/* second question here */}
        {this.state.display_question_two && (
          <div>
            <h6 style={{ fontWeight: "bold" }}>
              Product – Market Readiness 2 of 8
            </h6>
            <br />
            This factor is about how close your product or service is to being
            “market ready”. Specifically, you have to be able to show that your
            product development progress to date has already eliminated most of
            the technology development risk associated with the underlying
            innovation, with the delivery of the product/service and with its
            performance in the market place.
            <br />
            <span style={{ fontWeight: "bold" }}>
              How far away are you from being able to deliver completed products
              or services to your first revenue customer?
            </span>
            <br />
            A) The product or service offering is finished, customer beta tests
            completed and manufacturing/supply issues have been addressed. Some
            initial sales may have been obtained.
            <br />
            B) The product or service has been tested in and performs to
            expectation. Final sales require further work on testing, approvals
            or supply chain issues. No sales yet.
            <br />
            C) The concept of the product or service has been completed, but
            further research is needed to develop the product/service or address
            critical supply chain issues.
            <br />
            <label>
              Select your answer:
              <br />
              <select
                value={this.state.question_two_answer}
                onChange={this.handleTwoChange}
                style={{
                  // border: "2px solid #29BD00",
                  // borderRadius: "12px",
                  height: "40px",
                  padding: "0px 0px 0px 10px ",
                  backgroundColor: "white",
                  margin: "0px 2px 0px 0px ",
                  fontSize: 14,
                  width: "50%",
                }}
              >
                {options.map((option) => (
                  <option value={option.value}>{option.label}</option>
                ))}
              </select>
            </label>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                onClick={() => {
                  this.setState({
                    display_question_one: true,
                    display_question_two: false,
                  });
                }}
                id="buttons-grid-opp-button"
                variant="contained"
                color="primary"
                style={{ background: "#3f51b5", fontWeight: "bolder" }}
                xs={12}
              >
                Back
              </Button>

              <Button
                onClick={() => {
                  this.setState({
                    display_question_three: true,
                    display_question_two: false,
                  });
                }}
                id="buttons-grid-opp-button"
                variant="contained"
                color="primary"
                style={{ background: "#3f51b5", fontWeight: "bolder" }}
                xs={12}
              >
                Next
              </Button>
            </div>
          </div>
        )}

        {/* question three */}

        {this.state.display_question_three && (
          <div>
            <h6 style={{ fontWeight: "bold" }}>Product – Uniqueness 3 of 8</h6>
            <br />
            This factor is about being able to create an ongoing competitive
            advantage for your product or service, which discourages others from
            entering your market, and competing with an identical solution at a
            lower price. You must be able to show that your innovation can
            create a significant barrier to entry for both existing participants
            in the market and for potential new entrants. If you cannot do this,
            then in the longer term, this will create concerns about your
            profitability, as existing players try to compete on price, or other
            new entrants start to attack your customer base. In the long-term,
            without a barrier to entry, the long-term profitability of your
            business will reduce to zero. While patents offer one solution,
            there are many other ways of creating a sustainable competitive
            advantage.
            <br />
            <span style={{ fontWeight: "bold" }}>
              What is unique or patentable about your product that represents a
              barrier to entry for potential competitors?
            </span>
            <br />
            A) The product or service has received a patent, or embeds
            proprietary technology or knowledge that is not easily replicated.
            Alternatively, the product or service has a unique feature (such as
            a brand) that creates a significant barrier to competitors.
            <br />
            B) There is a level of unique knowledge or know how deployed in the
            product or service, which might be patentable (or is patent
            pending). Or there is another unique property that makes it
            challenging, but not impossible for others to replicate.
            <br />
            C) The product or service is innovative, but there are limited
            formal mechanisms to reduce the likelihood that competitors will
            replicate key features in the future.
            <br />
            <label>
              Select your answer:
              <br />
              <select
                value={this.state.question_three_answer}
                onChange={this.handleThreeChange}
                style={{
                  // border: "2px solid #29BD00",
                  // borderRadius: "12px",
                  height: "40px",
                  padding: "0px 0px 0px 10px ",
                  backgroundColor: "white",
                  margin: "0px 2px 0px 0px ",
                  fontSize: 14,
                  width: "50%",
                }}
              >
                {options.map((option) => (
                  <option value={option.value}>{option.label}</option>
                ))}
              </select>
            </label>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                onClick={() => {
                  this.setState({
                    display_question_three: false,
                    display_question_two: true,
                  });
                }}
                id="buttons-grid-opp-button"
                variant="contained"
                color="primary"
                style={{ background: "#3f51b5", fontWeight: "bolder" }}
                xs={12}
              >
                Back
              </Button>

              <Button
                onClick={() => {
                  this.setState({
                    display_question_four: true,
                    display_question_three: false,
                  });
                }}
                id="buttons-grid-opp-button"
                variant="contained"
                color="primary"
                style={{ background: "#3f51b5", fontWeight: "bolder" }}
                xs={12}
              >
                Next
              </Button>
            </div>
          </div>
        )}

        {/* question four */}

        {this.state.display_question_four && (
          <div>
            <h6 style={{ fontWeight: "bold" }}>
              {" "}
              Market – First Customer 4 of 8
            </h6>
            <br />
            Have you identified a first customer, who can validate the need for
            your product or service and has agreed to try or buy it? This is
            important, particularly in the case of where an investor is being
            sought; investors have an opinion on the need for your product or
            service, but require confirmation that that the stimulus behind the
            innovation was due to a desire to meet a real and identified
            customer need. A classic way to demonstrate that this is happening
            is to involve one or more customers in the development process, or
            to find another way to find third party evidence that customers in
            your target market will purchase the product or service. The same
            holds true if you are not seeking investment – your product must
            answer the question of what need it fills and how important is the
            need it fills.
            <br />
            <span style={{ fontWeight: "bold" }}>
              Can you demonstrate that customers in your target market will
              purchase your product or service when it is available?
            </span>
            <br />
            A) Customers have been intimately involved in the development
            process, and have committed to either purchase or try the
            product/service as soon as it becomes available.
            <br />
            B) Market research has confirmed a specific market for the product
            or service, but to-date no commitments have been made to test or
            purchase the product.
            <br />
            C) At present, there is no independent market validation of the
            product or service and no first customers have agreed to test or
            purchase it.
            <br />
            <label>
              Select your answer:
              <br />
              <select
                value={this.state.question_four_answer}
                onChange={this.handleFourChange}
                style={{
                  // border: "2px solid #29BD00",
                  // borderRadius: "12px",
                  height: "40px",
                  padding: "0px 0px 0px 10px ",
                  backgroundColor: "white",
                  margin: "0px 2px 0px 0px ",
                  fontSize: 14,
                  width: "50%",
                }}
              >
                {options.map((option) => (
                  <option value={option.value}>{option.label}</option>
                ))}
              </select>
            </label>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                onClick={() => {
                  this.setState({
                    display_question_three: true,
                    display_question_four: false,
                  });
                }}
                id="buttons-grid-opp-button"
                variant="contained"
                color="primary"
                style={{ background: "#3f51b5", fontWeight: "bolder" }}
                xs={12}
              >
                Back
              </Button>

              <Button
                onClick={() => {
                  this.setState({
                    display_question_four: false,
                    display_question_five: true,
                  });
                }}
                id="buttons-grid-opp-button"
                variant="contained"
                color="primary"
                style={{ background: "#3f51b5", fontWeight: "bolder" }}
                xs={12}
              >
                Next
              </Button>
            </div>
          </div>
        )}

        {/* question five */}

        {this.state.display_question_five && (
          <div>
            <h6 style={{ fontWeight: "bold" }}>Market – Distribution</h6>
            <br />
            It is very rare for a new venture to be able to develop,
            manufacture, deliver and market its new product or service by
            itself. So you have to ask yourself, are there people in the supply
            chain who will help you source the product/service and channel
            partners who will help take your product to new and additional
            customers? The company that you select to help you, must show you
            that it has a good understanding of what it takes to get the product
            or service to market, and can identify partners that can make this
            happen. However, simply identifying such partners only partly
            addresses the issue, as critical supply chain partner they must show
            a willingness to collaborate, especially if they are larger
            companies, and this opportunity is small. On the other hand, you
            must show that you understand what is required of supply chain
            partners and that you can negotiate with them to achieve desired
            results.
            <br />
            <span style={{ fontWeight: "bold" }}>
              Can you provide confirmation that there are no success barriers
              either about your supply chain, or distribution channel?
            </span>
            <br />
            A) Suppliers and channel partners have been engaged in the
            development process, and have made firm commitments to participate
            as soon as the product or service is ready for market.
            <br />
            B) Possible channel and supply chain partners have been identified
            and initial discussions held. While interested, there are no formal
            agreements in place with these partners.
            <br />
            C) At this point, although identified, no distribution or supply
            chain partners have yet been approached.
            <br />
            <label>
              Select your answer:
              <br />
              <select
                value={this.state.question_five_answer}
                onChange={this.handleFiveChange}
                style={{
                  // border: "2px solid #29BD00",
                  // borderRadius: "12px",
                  height: "40px",
                  padding: "0px 0px 0px 10px ",
                  backgroundColor: "white",
                  margin: "0px 2px 0px 0px ",
                  fontSize: 14,
                  width: "50%",
                }}
              >
                {options.map((option) => (
                  <option value={option.value}>{option.label}</option>
                ))}
              </select>
            </label>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                onClick={() => {
                  this.setState({
                    display_question_five: false,
                    display_question_four: true,
                  });
                }}
                id="buttons-grid-opp-button"
                variant="contained"
                color="primary"
                style={{ background: "#3f51b5", fontWeight: "bolder" }}
                xs={12}
              >
                Back
              </Button>

              <Button
                onClick={() => {
                  this.setState({
                    display_question_six: true,
                    display_question_five: false,
                  });
                }}
                id="buttons-grid-opp-button"
                variant="contained"
                color="primary"
                style={{ background: "#3f51b5", fontWeight: "bolder" }}
                xs={12}
              >
                Next
              </Button>
            </div>
          </div>
        )}

        {/* question six */}

        {this.state.display_question_six && (
          <div>
            <h6 style={{ fontWeight: "bold" }}>Market – Market Size</h6>
            <br />
            In launching a new venture, it is important for you to show that you
            both understand the total potential market size and the likely
            market share you can obtain. Each of these factors, not only helps
            you to show that you have a viable business, but also significantly
            affects the strategy of the company. You need to show that overall
            market projections are realistic, and that you have a strategy,
            which realistically considers your strengths and weaknesses, that
            can deliver the overall result required.
            <br />
            <span style={{ fontWeight: "bold" }}>
              Is the overall size of the market and your likely market share,
              sufficient to generate the envisaged revenues? Further, is the
              overall market forecast to be large enough to be interesting?
            </span>
            <br />
            A) There is direct evidence that the market potential for your
            product or service is large, and that you can achieve enough market
            share to achieve a sales target of over $20 million.
            <br />
            B) There is some evidence that the market potential for your product
            or service is quite large, and that you can achieve enough market
            share to achieve a sales target of over $5 million.
            <br />
            C) There is no direct evidence to support the overall size of your
            market, or to validate your likely market share. As a result, it is
            difficult to forecast with confidence that revenue will exceed $1
            million.
            <br />
            <label>
              Select your answer:
              <br />
              <select
                value={this.state.question_six_answer}
                onChange={this.handleSixChange}
                style={{
                  // border: "2px solid #29BD00",
                  // borderRadius: "12px",
                  height: "40px",
                  padding: "0px 0px 0px 10px ",
                  backgroundColor: "white",
                  margin: "0px 2px 0px 0px ",
                  fontSize: 14,
                  width: "50%",
                }}
              >
                {options.map((option) => (
                  <option value={option.value}>{option.label}</option>
                ))}
              </select>
            </label>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                onClick={() => {
                  this.setState({
                    display_question_five: true,
                    display_question_six: false,
                  });
                }}
                id="buttons-grid-opp-button"
                variant="contained"
                color="primary"
                style={{ background: "#3f51b5", fontWeight: "bolder" }}
                xs={12}
              >
                Back
              </Button>

              <Button
                onClick={() => {
                  this.setState({
                    display_question_six: false,
                    display_question_seven: true,
                  });
                }}
                id="buttons-grid-opp-button"
                variant="contained"
                color="primary"
                style={{ background: "#3f51b5", fontWeight: "bolder" }}
                xs={12}
              >
                Next
              </Button>
            </div>
          </div>
        )}

        {/* question seven */}

        {this.state.display_question_seven && (
          <div>
            <h6 style={{ fontWeight: "bold" }}>The Entrepreneur</h6>
            <br />
            You need to show that between you and your team you have the ability
            to navigate the company through the numerous issues you will face.
            The most likely predictor of this is prior relevant experience.
            Although experience needs to be related to the proposed product or
            service, success is not always a requirement, indeed if you can show
            that you have learnt from previous failures, this can often help. In
            certain cases, gaps in the entrepreneur’s experience can be
            compensated for by recruiting a team of individuals with the
            required experience.
            <br />
            <span style={{ fontWeight: "bold" }}>
              Do you or members of your team have any direct or relevant
              (entrepreneurial, industry, business) experience that can be
              directly applied to the challenges facing this business?
            </span>
            <br />
            A) You or members of the management team have deep and significant
            relevant experience that will enable you to operate the business
            successfully. This experience enabled or encouraged you to start the
            business.
            <br />
            B) You or members of the management team have significant business
            (or equivalent, i.e. military) experience, which although not
            directly relevant, will help you to operate the business.
            <br />
            C) Your experience is primarily technical, or limited, and provides
            no direct evidence that it will help you meet the challenges of
            operating the business.
            <br />
            <label>
              Select your answer:
              <br />
              <select
                value={this.state.question_seven_answer}
                onChange={this.handleSevenChange}
                style={{
                  // border: "2px solid #29BD00",
                  // borderRadius: "12px",
                  height: "40px",
                  padding: "0px 0px 0px 10px ",
                  backgroundColor: "white",
                  margin: "0px 2px 0px 0px ",
                  fontSize: 14,
                  width: "50%",
                }}
              >
                {options.map((option) => (
                  <option value={option.value}>{option.label}</option>
                ))}
              </select>
            </label>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                onClick={() => {
                  this.setState({
                    display_question_seven: false,
                    display_question_six: true,
                  });
                }}
                id="buttons-grid-opp-button"
                variant="contained"
                color="primary"
                style={{ background: "#3f51b5", fontWeight: "bolder" }}
                xs={12}
              >
                Back
              </Button>

              <Button
                onClick={() => {
                  this.setState({
                    display_question_eight: true,
                    display_question_seven: false,
                  });
                }}
                id="buttons-grid-opp-button"
                variant="contained"
                color="primary"
                style={{ background: "#3f51b5", fontWeight: "bolder" }}
                xs={12}
              >
                Next
              </Button>
            </div>
          </div>
        )}

        {/* question eight */}

        {this.state.display_question_eight && (
          <div>
            <h6 style={{ fontWeight: "bold" }}></h6>
            <br />
            The most important factor to consider in evaluating your business is
            the ability to make money. Unfortunately, this is often construed as
            a need to show how you will make profit. In reality, the single most
            important factor is the ability to manage cash flow so that you do
            not run out. To demonstrate that the company can first survive and
            then make money, you need to show that you can develop reliable and
            realistic cash-flow forecasts, and then that you can identify
            sources of cash to make up any shortfalls.
            <br />
            <span style={{ fontWeight: "bold" }}>
              Do your financial projections present a persuasive argument that
              your company can achieve cash-flow neutrality, based on your own
              investment, money you can borrow, and money you can raise from
              external investors?
            </span>
            <br />
            A) You show high degree of confidence that revenues/profit margins
            forecast or external funding identified, helps you achieve cash flow
            neutrality within 12 months, either directly, or by enabling you to
            raise additional cash after 12 months.
            <br />
            B) You show a reasonable degree of confidence that revenues/profit
            margins forecast or external funding identified, helps you achieve
            cash flow neutrality within 24 months, either directly, or by
            enabling you to raise additional cash after 24 months.
            <br />
            C) You do not yet have a financial projection that provides this
            level of detail, or it shows that you will be in a negative cash
            flow position over the next 24 months, with limited confidence that
            after 24 months additional cash can be raised.
            <br />
            <label>
              Select your answer:
              <br />
              <select
                value={this.state.question_eight_answer}
                onChange={this.handleEightChange}
                style={{
                  // border: "2px solid #29BD00",
                  // borderRadius: "12px",
                  height: "40px",
                  padding: "0px 0px 0px 10px ",
                  backgroundColor: "white",
                  margin: "0px 2px 0px 0px ",
                  fontSize: 14,
                  width: "50%",
                }}
              >
                {options.map((option) => (
                  <option value={option.value}>{option.label}</option>
                ))}
              </select>
            </label>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                onClick={() => {
                  this.setState({
                    display_question_seven: true,
                    display_question_eight: false,
                  });
                }}
                id="buttons-grid-opp-button"
                variant="contained"
                color="primary"
                style={{ background: "#3f51b5", fontWeight: "bolder" }}
                xs={12}
              >
                Back
              </Button>

              <Button
                onClick={this.postCFA}
                id="buttons-grid-opp-button"
                variant="contained"
                color="primary"
                style={{ background: "#3f51b5", fontWeight: "bolder" }}
                xs={12}
              >
                Submit
              </Button>
            </div>
          </div>
        )}

        {/* results */}

        {this.state.data_response && (
          <div>
            <h6 style={{ fontWeight: "bold" }}>
              Congratulations on completing the CFA Self-Assessment!
            </h6>
            <br />
            <h4>
              ratings:{" "}
              {this.state.response_rating == 5 && (
                <>
                  <i
                    style={{ color: "#3f51b5", fontSize: "35px" }}
                    className="fa fa-star"
                  ></i>
                  <i
                    style={{ color: "#3f51b5", fontSize: "35px" }}
                    className="fa fa-star"
                  ></i>
                  <i
                    style={{ color: "#3f51b5", fontSize: "35px" }}
                    className="fa fa-star"
                  ></i>
                  <i
                    style={{ color: "#3f51b5", fontSize: "35px" }}
                    className="fa fa-star"
                  ></i>
                  <i
                    style={{ color: "#3f51b5", fontSize: "35px" }}
                    className="fa fa-star"
                  ></i>
                </>
              )}
              {this.state.response_rating == 4 && (
                <>
                  <i
                    style={{ color: "#3f51b5", fontSize: "35px" }}
                    className="fa fa-star"
                  ></i>
                  <i
                    style={{ color: "#3f51b5", fontSize: "35px" }}
                    className="fa fa-star"
                  ></i>
                  <i
                    style={{ color: "#3f51b5", fontSize: "35px" }}
                    className="fa fa-star"
                  ></i>
                  <i
                    style={{ color: "#3f51b5", fontSize: "35px" }}
                    className="fa fa-star"
                  ></i>

                  <i
                    style={{ color: "#3f51b5", fontSize: "35px" }}
                    className="fa fa-star-o"
                  ></i>
                </>
              )}
              {this.state.response_rating == 3 && (
                <>
                  <i
                    style={{ color: "#3f51b5", fontSize: "35px" }}
                    className="fa fa-star"
                  ></i>
                  <i
                    style={{ color: "#3f51b5", fontSize: "35px" }}
                    className="fa fa-star"
                  ></i>
                  <i
                    style={{ color: "#3f51b5", fontSize: "35px" }}
                    className="fa fa-star"
                  ></i>

                  <i
                    style={{ color: "#3f51b5", fontSize: "35px" }}
                    className="fa fa-star-o"
                  ></i>

                  <i
                    style={{ color: "#3f51b5", fontSize: "35px" }}
                    className="fa fa-star-o"
                  ></i>
                </>
              )}
              {this.state.response_rating == 2 && (
                <>
                  <i
                    style={{ color: "#3f51b5", fontSize: "35px" }}
                    className="fa fa-star"
                  ></i>
                  <i
                    style={{ color: "#3f51b5", fontSize: "35px" }}
                    className="fa fa-star"
                  ></i>

                  <i
                    style={{ color: "#3f51b5", fontSize: "35px" }}
                    className="fa fa-star-o"
                  ></i>

                  <i
                    style={{ color: "#3f51b5", fontSize: "35px" }}
                    className="fa fa-star-o"
                  ></i>

                  <i
                    style={{ color: "#3f51b5", fontSize: "35px" }}
                    className="fa fa-star-o"
                  ></i>
                </>
              )}
              {this.state.response_rating == 1 && (
                <>
                  <i
                    style={{ color: "#3f51b5", fontSize: "35px" }}
                    className="fa fa-star"
                  ></i>

                  <i
                    style={{ color: "#3f51b5", fontSize: "35px" }}
                    className="fa fa-star-o"
                  ></i>

                  <i
                    style={{ color: "#3f51b5", fontSize: "35px" }}
                    className="fa fa-star-o"
                  ></i>

                  <i
                    style={{ color: "#3f51b5", fontSize: "35px" }}
                    className="fa fa-star-o"
                  ></i>

                  <i
                    style={{ color: "#3f51b5", fontSize: "35px" }}
                    className="fa fa-star-o"
                  ></i>
                </>
              )}
            </h4>
            <br />
            <br />
            Notes to keep in mind while reading this report: If you rated a
            factor with a “B” or “C” then you will need to take appropriate
            actions to move the rating to an “A”. If you rated a factor with an
            “A” you do not need to do anything. There is no pass or fail and the
            overall score does not matter as much as how you address each of the
            areas that require attention. Each factor has been rated on a
            three-point scale, and in some cases, a plus (+) or minus (‐) is
            also used to further moderate a rating. In a full CFA assessment, it
            is also sometimes necessary to apply an alternate rating. The
            possible ratings for the CFA Snapshot include: A ‐ This factor
            appears to be quite favourable. B ‐ Further investigation on your
            part is recommended to determine what effect this factor might
            possibly have. Ideally, you should find ways to move B ratings to A
            ratings. This isn’t always possible. C ‐ This factor represents a
            critical risk. If it cannot be solved it may be necessary to
            discontinue efforts to commercialize this invention. Ideally, you
            should find ways to move C ratings to B or even A ratings. It isn’t
            always possible.
            <br />
            <span style={{ fontWeight: "bold" }}>
              {" "}
              To read full report, go to company profile
            </span>
            <br />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                onClick={() => this.props.discard()}
                id="buttons-grid-opp-button"
                variant="contained"
                color="primary"
                style={{ background: "#3f51b5", fontWeight: "bolder" }}
                xs={12}
              >
                Save & Finish Application
              </Button>

              <Button
                onClick={() => this.props.closeparentmodal()}
                id="buttons-grid-opp-button"
                variant="contained"
                color="primary"
                style={{ background: "#3f51b5", fontWeight: "bolder" }}
                xs={12}
              >
                Save & Discard Application
              </Button>
            </div>
          </div>
        )}
      </>
    );
  }
}

CFA.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CFA);
