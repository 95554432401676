import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Charts.css";
import LineChart from "./LineChart";
import BarChart from "./BarChart";
import ProgressTrack from "./ProgressTrack/ProgressTrack";
import URL from "url-parse";

import bootstrap from "../../../../../pitch_box/assets/bootstrap.jpg";
import angular from "../../../../../pitch_box/assets/angular.jpg";
import sketch from "../../../../../pitch_box/assets/sketch.jpg";
import react from "../../../../../pitch_box/assets/react.jpg";

const Charts = (props) => {
  const [dealPipelineData, setDealPipelineData] = useState({
    new: 0,
    reviewing: 0,
    dilligence: 0,
    deepDilligence: 0,
    offer: 0,
    portco: 0,
    total: 0,
  });
  const [judges, setJudges] = useState([]);

  const [leads, setLeads] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);

  const [reviews, setReviews] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);

  useEffect(() => {
    var url = new URL(window.location.href, true);
    var opportunityId = url.query.opportunity_id;

    axios
      .get(
        `fetch_submitted_companies_for_opportunity?opportunity_id=${opportunityId}`,
      )
      .then((res) => {
        let newCount = 0;
        let reviewingCount = 0;
        let dilligenceCount = 0;
        let deepDilligenceCount = 0;
        let portcoCount = 0;
        let offer = 0;
        let companies = res.data.full_company_list_with_flags || [];
        console.log(companies);
        companies.map((company) => {
          const createdOn = new Date(company.created_on);
          setLeads((prevLeads) => {
            const updatedLeads = [...prevLeads];
            updatedLeads[createdOn.getMonth()] += 1;
            return updatedLeads;
          });
          const companyReviews = company.reviews.length;
          setReviews((prevReviews) => {
            const updatedReviews = [...prevReviews];
            updatedReviews[createdOn.getMonth()] += companyReviews;
            return updatedReviews;
          });

          if (company.application_status === "new") {
            newCount++;
          }
          if (company.application_status === "review") {
            reviewingCount++;
          }
          if (company.application_status === "firstdiligence") {
            dilligenceCount++;
          }
          if (company.application_status === "deepdilligence") {
            deepDilligenceCount++;
          }
          if (company.application_status === "offer") {
            offer++;
          }
          if (company.application_status === "portco") {
            portcoCount++;
          }
        });
        setDealPipelineData({
          new: newCount,
          reviewing: reviewingCount,
          dilligence: dilligenceCount,
          deepDilligence: deepDilligenceCount,
          offer: offer,
          portco: portcoCount,
          total: companies.length,
        });
      });
  }, []);

  useEffect(() => {
    var url = new URL(window.location.href, true);
    var opportunityId = url.query.opportunity_id;

    axios
      .get(
        `fetch_judges_for_opportunity_simple?opportunity_id=${opportunityId}`,
      )
      .then((res) => {
        setJudges(res.data.opp_judges);
        console.log(res.data.opp_judges);
      });
  }, []);

  return (
    <div>
      <div className="AragonCharts AragonFlex">
        <div className="AragonLineChartMain">
          <div className="AragonLineChart">
            <div className="AragonFlex AragonLineChartOverView">
              <div>
                <h6>OVERVIEW</h6>
                <h3>Leads Per Month</h3>
              </div>
            </div>
            <div style={{ height: "360px" }} className="lineabc">
              <LineChart data={leads} />
            </div>
          </div>
        </div>
        <div className="AragonBarChartMain">
          <div className="AragonBarChart">
            <div>
              <h6>TEAM ACTIVITY</h6>
              <h3>Reviews per month</h3>
            </div>
            <div style={{ height: "360px" }}>
              <BarChart data={reviews} />
            </div>
          </div>
        </div>
        <div className="AragonBarChartHorizontal">
          <h6>PIPELINE</h6>
          <h3>Stages of review</h3>
          <ProgressTrack
            avtar={bootstrap}
            title={` ${dealPipelineData.new} | Stage 1 - New Leads `}
            color="#FFFFFF"
            width={(dealPipelineData.new / dealPipelineData.total) * 100 + "%"}
          />
          <ProgressTrack
            avtar={angular}
            title={`  ${dealPipelineData.reviewing} | Stage 2 -  Reviewing`}
            color="#2DCE89"
            width={
              (dealPipelineData.reviewing / dealPipelineData.total) * 100 + "%"
            }
          />
          <ProgressTrack
            avtar={sketch}
            title={` ${dealPipelineData.dilligence} | Stage 3 - 1st Dilligence `}
            color="#F5365C"
            width={
              (dealPipelineData.dilligence / dealPipelineData.total) * 100 + "%"
            }
          />
          <ProgressTrack
            avtar={react}
            title={` ${dealPipelineData.deepDilligence} | Stage 4 -  Deep Dilligence `}
            color="#11CDEF"
            width={
              (dealPipelineData.deepDilligence / dealPipelineData.total) * 100 +
              "%"
            }
          />
          <ProgressTrack
            avtar={bootstrap}
            title={` ${dealPipelineData.offer} | Stage 5 -  Offer Signed`}
            color="#FB6340"
            width={
              (dealPipelineData.offer / dealPipelineData.total) * 100 + "%"
            }
          />
          <ProgressTrack
            avtar={bootstrap}
            title={` ${dealPipelineData.portco} | Stage 6 -  Portfolio Companies`}
            color="#ef11cd"
            width={
              (dealPipelineData.portco / dealPipelineData.portco) * 100 + "%"
            }
          />
        </div>
      </div>
      <div className="AragonCharts AragonFlex">
        <div
          style={{
            background: "#FFFFFF", 
            borderRadius: "4px",
            boxShadow: "0 1px 3px rgba(0,0,0,0.12)",
            padding: "1.5rem",
            marginBottom: "30px",
          }}
        >
          <div>
            <h3 style={{ margin: 0, color: "#32325d" }}>Judges</h3>
          </div>
          <div>
            <div style={{ overflowX: "auto" }}>
              <table
                style={{
                  width: "100%",
                  borderCollapse: "separate",
                  borderSpacing: "0 0",
                  fontSize: "0.8rem",
                }}
              >
                <thead>
                  <tr style={{ borderBottom: "2px solid #e9ecef" }}>
                    <th
                      style={{
                        padding: "12px 24px",
                        textAlign: "left",
                        color: "#8898aa",
                        fontWeight: 600,
                      }}
                    >
                      Judge
                    </th>
                    <th
                      style={{
                        padding: "12px 24px",
                        textAlign: "left",
                        color: "#8898aa",
                        fontWeight: 600,
                      }}
                    >
                      Date Added
                    </th>
                    <th
                      style={{
                        padding: "12px 24px",
                        textAlign: "left",
                        color: "#8898aa",
                        fontWeight: 600,
                      }}
                    >
                      Reviews
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {judges.map((judge) => (
                    <tr
                      key={judge._id}
                      style={{ borderBottom: "1px solid #e9ecef" }}
                    >
                      <td style={{ padding: "12px 24px", color: "#525f7f" }}>
                        <div style={{ fontSize: "0.85rem" }}>
                          {judge.first_name} {judge.last_name}
                        </div>
                        <div style={{ fontSize: "0.75rem", color: "#8898aa" }}>
                          {judge.email}
                        </div>
                      </td>
                      <td style={{ padding: "12px 24px", color: "#525f7f", fontSize: "0.75rem" }}>
                        {new Date(judge.created_on).toLocaleDateString()}
                      </td>
                      <td style={{ padding: "12px 24px", color: "#525f7f", fontSize: "0.75rem" }}>
                        {judge.reviews.length}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Charts;
