import React, { Component } from "react";

import axios from "axios";

import "./_M-signupForm.scss";

import { Alert, Form, FormLabel, Radio, Row } from "react-bootstrap";
import PropTypes from "prop-types";

import FormGroup from "@material-ui/core/FormGroup";
import { Helmet } from "react-helmet";
import URL from "url-parse";
import { Link } from "react-router-dom";
import {
  company_type_list,
  industry_list,
  stage_list,
  business_type,
} from "../../../../boilerplate/list.json";
// import Footer from './components/footer_side'

import {
  Sign_In,
  Register,
  Forgot_Password,
  Alert_Message_To_User,
} from "../../../../live/components/popups";
import waterfall from "async/waterfall";
import { AppContext } from "../../../../App";

import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";

import Geocode from "react-geocode";

import {
  FormControl,
  FormHelperText,
  TextField,
  InputLabel,
  NativeSelect,
  InputBase,
  Select,
  MenuItem,
  Chip,
  Icon,
  Input,
  Button,
} from "@material-ui/core";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

let userInfo;

class SignupFormInvited_Container extends React.Component {
  constructor(props) {
    super(props);
  }
  static contextType = AppContext;

  render() {
    return <SignupFormInvited {...this.props} {...this.context} />;
  }
}

class SignupFormInvited extends Component {
  state = {
    url: new URL(window.location.href, true),
    check_email_message: "",

    userFormDisplay: Boolean,
    companyFormDisplay: Boolean,

    //****************/Signup User/***********************//
    email: "",
    password: "",
    first_name: "",
    last_name: "",
    referral_partners: [],
    referred_by: null,
    confirmed_privacy_poicy_terms: false,

    //****************/singup Company/***********************//
    company_name: "",
    street_number: "",

    short_bio: "",
    website: "",
    street_name: "",
    city: "",
    province: "Ontario",
    country: "Canada",
    company_type: "",
    industry: [],
    address: "",
    stage: 1,
    business_model: "",
    formIsValids: false,
    errors: {},
    showButtonLoader: false,
    old_address: "",
    incubator_accelerator_history: "",
    linkedIn: "",
    contact_phone: "",
    raising_funds: "",
    fintech_sub_vertical: "",
    finance_mtl_q1: "",
    finance_mtl_q2: "",
    finance_mtl_q3: "",
    finance_mtl_q4: "",
  };

  componentDidMount() {
    //console.log("this.state.url.query.ref_id = " + this.state.url.query.ref_id);
    axios.get("fetch_referral_partners").then((response) => {
      this.setState({
        referral_partners: response.data.referral_partners,
      });
    });
  }

  handleChange = (e) => {
    const name = e.target.name;
    this.setState(
      {
        [name]: e.target.value,
      },
      () => {
        if (name === "address") {
          this.getLocation();
        }
      },
    );
  };

  handleChangeMultiple = (e) => {
    const name = e.target.name;
    let selected_values = [];
    Array.from(e.target.selectedOptions).forEach(function (item) {
      selected_values.push(item.value);
    });
    this.setState({
      [name]: selected_values,
    });
  };
  getStage = (value, array) => {
    for (let i = 0; i < array.length; i++) {
      if (array[i].value === value) {
        return array[i].text;
      }
    }
  };
  getReferrer = (value, array) => {
    for (let i = 0; i < array.length; i++) {
      if (array[i]._id === value) {
        return `${array[i].first_name} ${array[i].last_name}`;
      }
    }
  };
  validateResult = (data, callback) => {
    if (data.status === "success" || data.status === "partial") {
      callback(data, this);
    } else if (data.status === "redirect") {
      this.props.history.push(data.redirect_to);
    } else if (data.status === "error") {
    }
  };
  handleUserValidation = (event) => {
    event.preventDefault();

    this.setState({
      showButtonLoader: !this.state.showButtonLoader,
    });
    let errors = {};
    let formIsValid = true;

    if (this.state.first_name.length === 0) {
      formIsValid = false;
      errors["first"] = " Please Enter an your first Name";
    }
    if (this.state.last_name.length === 0) {
      formIsValid = false;
      errors["last"] = " Please Enter an your last Name";
    }
    if (this.state.password.length === 0) {
      formIsValid = false;
      errors["password"] = " Cannot be empty";
    }

    if (this.state.email.length === 0) {
      formIsValid = false;
      errors["email"] = " Cannot be empty";
    }
    if (this.state.confirmed_privacy_poicy_terms === false) {
      formIsValid = false;
      errors["privacy_policy"] =
        "Must accept the privacy policy and terms of service to make an account.";
    }

    if (typeof this.state.email !== "undefined") {
      let lastAtPos = this.state.email.lastIndexOf("@");
      let lastDotPos = this.state.email.lastIndexOf(".");

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          this.state.email.indexOf("@@") == -1 &&
          lastDotPos > 2 &&
          this.state.email.length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        errors["email"] = "Email is not valid, e.g., user@example.com";
      }
    }
    this.setState({ errors: errors });
    if (formIsValid) {
      //this.setState({ formIsValids: true });
      return waterfall(
        [
          (callback) => {
            axios
              .post("/create_invited_user_with_verification", {
                first_name: this.state.first_name,
                last_name: this.state.last_name,
                email: this.state.email,
                referred_by: this.state.referred_by,
                password: this.state.password,
                ref_id: this.state.url.query.ref_id,
              })
              .then((result) => {
                if (result.data.status === "existing user") {
                  let errors = {};
                  let formIsValid = true;
                  errors["email"] = " User already exists";
                  this.setState({ errors: errors });
                }
                if (result.data.status !== "existing user") {
                  //callback(null, result.data);
                  //document.location.reload(true);
                  this.props.history.push(`/welcome`);
                  document.location.reload(true);
                }
                this.setState({
                  showButtonLoader: false,
                });
              });
          },
        ],
        (err, done) => {
          if (err) console.log(err);
          console.log(done);
          // this.setState({
          //   check_email_message:"Please check for a confirmation email!"
          // })
          this.props.persistUser(done.data);
        },
      );
    }
  };

  handleCompanyValidation = (event) => {
    event.preventDefault();
    this.setState({
      showButtonLoader: !this.state.showButtonLoader,
    });
    let errors = {};
    let formIsValid = true;

    if (this.state.company_name.length === 0) {
      formIsValid = false;
      errors["company_name"] = " Cannot be empty";
    }
    if (this.state.company_type.length === 0) {
      formIsValid = false;
      errors["company_type"] = " Cannot be empty";
    }
    if (this.state.industry.length === 0) {
      formIsValid = false;
      errors["industry"] = " Cannot be empty";
    }
    if (this.state.stage === 1) {
      formIsValid = false;
      errors["company_stage"] = " Cannot be empty";
    }
    if (this.state.address.length === 0) {
      formIsValid = false;
      errors["location"] = " Cannot be empty";
    }

    this.setState({ errors: errors });

    if (formIsValid) {
      waterfall(
        [
          // get user info with its id
          (callback) => {
            axios
              .post("/create_company", {
                user_id: this.props.user._id,
                company_name: this.state.company_name,
                company_type: this.state.company_type,
                street_number: this.state.street_number,
                short_bio: this.state.short_bio,
                company_website: this.state.website,
                street_name: this.state.street_number,
                address: this.state.address,
                city: this.state.city,
                province: this.state.province,
                country: this.state.country,
                industry: this.state.industry,
                stage: this.state.stage,
                business_model: this.state.business_model,
                formatted_address: this.state.formatted_address,
                lat: this.state.lat,
                long: this.state.long,
              })
              .then((result) => {
                // callback(null, { user: userInfo.data, company: result.data })
                if (
                  this.state.url.query.commaff !== undefined &&
                  this.state.url.query.commaff.length > 0
                ) {
                  axios
                    .post("/community_email_response", {
                      community_applicant: result.data.result._id,
                      applicant_response: this.state.url.query.response,
                      commaff: this.state.url.query.commaff,
                    })
                    .then((response) => {
                      if (response.data.status === "success") {
                        this.props.history.push(
                          `/welcome?community_id=${response.data.community_id}`,
                        );
                        document.location.reload(true);
                      } else {
                        this.props.history.push(`/welcome`);
                        document.location.reload(true);
                      }
                    });
                } else if (
                  this.state.url.query.community_id !== undefined &&
                  this.state.url.query.user_id !== undefined
                ) {
                  console.log(result.data.result._id);
                  axios
                    .post("/global_share_has_company", {
                      applicant_company: result.data.result._id,

                      user_id: this.state.url.query.user_id,
                      opp_company: this.state.url.query.community_id,
                    })
                    .then((response) => {
                      if (response.data.status === "success") {
                        this.props.history.push(
                          `/welcome?community_id=${this.state.url.query.community_id}`,
                        );
                        document.location.reload(true);
                      } else {
                        this.props.history.push(`/welcome`);
                        document.location.reload(true);
                      }
                    });
                } else {
                  this.props.history.push(`/welcome`);
                  document.location.reload(true);
                }
              });
          },
        ],
        (err, done) => {
          if (err) console.log(err);
          this.props.persistUser(done.user);
          this.setState({
            showButtonLoader: false,
          });
          //this.props.history.push(`profile?company_id=${done.company.result._id}`)
        },
      );
    }
  };

  getUserInfo = (userInfo) => {
    let userData = userInfo;
    return { userData: userData };
  };
  handleInputSubmit = (e) => {
    e.preventDefault();
  };
  getLocation = () => {
    Geocode.setApiKey("AIzaSyAo8kBbOGTAr2C5kFNic3fZYUxA7KYNp7M");
    // Enable or disable logs. Its optional.
    Geocode.enableDebug();

    // Get latidude & longitude from address.
    if (this.state.old_address !== this.state.address) {
      this.setState(
        {
          old_address: this.state.address,
        },
        () => {
          Geocode.fromAddress(this.state.address).then(
            (response) => {
              const address_array =
                response.results[0].formatted_address.split(", ");
              const { lat, lng } = response.results[0].geometry.location;
              if (address_array.length === 4) {
                this.setState({
                  location_processed: 1,
                  lat: lat,
                  long: lng,
                  city: address_array[1],
                  province: address_array[2],
                  country: address_array[3],
                  formatted_address: response.results[0].formatted_address,
                });
              } else if (address_array.length === 3) {
                this.setState({
                  location_processed: 1,
                  lat: lat,
                  long: lng,
                  city: address_array[0],
                  province: address_array[1],
                  country: address_array[2],
                  formatted_address: response.results[0].formatted_address,
                });
              }
            },
            (error) => {
              console.error(error);
            },
          );
        },
      );
    }
  };

  handleCheck = (e) => {
    let name = e.target.name;
    this.setState({
      [name]: !this.state[name],
    });
  };
  render() {
    let userSignUp;

    if (this.state.userFormDisplay) {
      userSignUp = (
        <form>
          <h4 className="__signup-form-title">
            You are invited to join StartupFuel: <br /> The Global Startup
            Network
          </h4>
          <div className="__form-group">
            <TextField
              type="text"
              value={this.state.first_name}
              label="First Name"
              name="first_name"
              onChange={this.handleChange}
              fullWidth
              helperText={this.state.errors["first"]}
              error={this.state.errors["first"]}
            />
          </div>

          <div className="__form-group">
            <TextField
              type="text"
              value={this.state.last_name}
              label="Last Name"
              name="last_name"
              onChange={this.handleChange}
              fullWidth
              helperText={this.state.errors["last"]}
              error={this.state.errors["last"]}
            />
          </div>
          <div className="__form-group">
            <TextField
              type="email"
              value={this.state.email}
              label="Email"
              name="email"
              onChange={this.handleChange}
              fullWidth
              helperText={this.state.errors["email"]}
              error={this.state.errors["email"]}
            />
          </div>
          <div className="__form-group">
            <TextField
              type="password"
              value={this.state.password}
              label="Password"
              name="password"
              onChange={this.handleChange}
              fullWidth
              helperText={this.state.errors["password"]}
              error={this.state.errors["password"]}
            />
          </div>
          <div className="__form-group">
            <FormControl fullWidth>
              <InputLabel id="referedBy">Referred by? (Optional)</InputLabel>
              <Select
                labelId="referedBy"
                value={this.state.referred_by}
                onChange={this.handleChange}
                MenuProps={MenuProps}
                name="referred_by"
              >
                {this.state.referral_partners.map((partner) => (
                  <MenuItem key={partner.first_name} value={partner._id}>
                    {partner.first_name} {partner.last_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div className="__term-and-conditions-field">
            <Checkbox
              checked={this.state.confirmed_privacy_poicy_terms}
              onChange={(event) => {
                this.handleCheck(event);
              }}
              name="confirmed_privacy_poicy_terms"
              color="primary"
              className="__check-box"
              error={this.state.errors["privacy_policy"]}
              helperText={this.state.errors["privacy_policy"]}
            />
            <span>
              I agree to the{" "}
              <a href="/privacy_policy" target="_blank">
                privacy policy
              </a>{" "}
              &{" "}
              <a href="/terms_conditions" target="_blank">
                terms of service
              </a>{" "}
            </span>
          </div>
          <div className="__validate-text">
            {this.state.errors["privacy_policy"]}
          </div>

          <Button
            onClick={this.handleUserValidation}
            id="user-registration-button"
            fullWidth
            variant="contained"
            color="primary"
          >
            {this.state.check_email_message.length === 0
              ? "Sign Up"
              : this.state.check_email_message}
          </Button>
          <div className="__login">
            <hr></hr>

            <Link to="/sign_in">
              <Button color="secondary" fullWidth variant="contained">
                Sign In
              </Button>
            </Link>
          </div>
        </form>
      );
    }

    let companySignUp;

    if (this.state.companyFormDisplay) {
      companySignUp = (
        <form id="company-registration" className="__signup-form">
          <div className="__form-group">
            <TextField
              type="text"
              value={this.state.company_name}
              label="Company Name"
              name="company_name"
              fullWidth
              onChange={this.handleChange}
              error={this.state.errors["company_name"]}
              helperText={this.state.errors["company_name"]}
            />
          </div>
          <div className="__form-group">
            <TextField
              type="text"
              value={this.state.website}
              name="website"
              onChange={this.handleChange}
              label="Company Website"
              fullWidth
              helperText={this.state.errors.website || "https://mycompany.com"}
              error={this.state.errors["website"]}
            />
          </div>
          <div className="__form-group">
            <FormControl fullWidth error={this.state.errors["company_type"]}>
              <InputLabel id="company_type">Company Type</InputLabel>
              <Select
                labelId="company_type"
                value={this.state.company_type}
                onChange={this.handleChange}
                name="company_type"
                MenuProps={MenuProps}
              >
                {company_type_list.map((name) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                {this.state.errors["company_type"]}
              </FormHelperText>
            </FormControl>
          </div>
          <div className="__form-group">
            <TextField
              label="Address"
              name="address"
              value={this.state.address}
              onChange={this.handleChange}
              helperText={
                this.state.errors["location"] ||
                "Office Address or Location (City, Country)"
              }
              fullWidth
              error={this.state.errors["location"]}
            />
          </div>
          <div className="__form-group">
            <FormControl fullWidth error={this.state.errors["industry"]}>
              <InputLabel id="industries">Industries</InputLabel>
              <Select
                multiple
                value={this.state.industry}
                onChange={this.handleChange}
                labelId="industries"
                name="industry"
                renderValue={(selected) =>
                  selected.map((value) => <Chip key={value} label={value} />)
                }
                MenuProps={MenuProps}
              >
                {industry_list.map((name) => (
                  <MenuItem key={name} value={name}>
                    <Checkbox
                      checked={this.state.industry.indexOf(name) > -1}
                    />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                {this.state.errors["industry"] ||
                  (this.state.company_type === "Service Provider"
                    ? "Industries of the companies that you work with?"
                    : this.state.company_type !== "Startup" &&
                      this.state.company_type !== "Tech Startup" &&
                      this.state.company_type.length > 0 &&
                      "Industries of the companies that you are looking for?")}
              </FormHelperText>
            </FormControl>
          </div>
          <div
            className="__form-group"
            error={this.state.errors["business_model"]}
          >
            <FormControl fullWidth>
              <InputLabel id="business_model">Business Model</InputLabel>
              <Select
                labelId="business_model"
                value={this.state.business_model}
                onChange={this.handleChange}
                name="business_model"
                error={this.state.errors["business_model"]}
                helperText={this.state.errors["business_model"]}
                MenuProps={MenuProps}
              >
                {business_type.map((name) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                {this.state.errors["business_model"] ||
                  (this.state.company_type === "Service Provider"
                    ? "Business Models of the companies that you work with?"
                    : this.state.company_type !== "Startup" &&
                      this.state.company_type !== "Tech Startup" &&
                      this.state.company_type.length > 0 &&
                      "Business Models of the companies that you are looking for?")}
              </FormHelperText>
            </FormControl>
          </div>
          <div className="__form-group">
            <FormControl fullWidth error={this.state.errors["company_stage"]}>
              <InputLabel id="stage">Company Stage</InputLabel>
              <Select
                value={this.state.stage === 1 ? "" : this.state.stage}
                onChange={this.handleChange}
                name="stage"
                label="stage"
                MenuProps={MenuProps}
              >
                {stage_list.map((name) => (
                  <MenuItem key={name.text} value={name.value}>
                    {name.text}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                {this.state.errors["company_stage"] ||
                  (this.state.company_type === "Service Provider"
                    ? "Stages of the companies that you like to work with?"
                    : this.state.company_type !== "Startup" &&
                      this.state.company_type !== "Tech Startup" &&
                      this.state.company_type.length > 0 &&
                      "Stages of the companies that you are looking for?")}
              </FormHelperText>
            </FormControl>
          </div>
          <Button
            onClick={this.handleCompanyValidation}
            variant="contained"
            color="primary"
          >
            Join us now
          </Button>
          <Button
            onClick={() => (window.location.href = "/opportunities")}
            variant="contained"
            color="secondary"
            className="__skip-btn"
            type="button"
          >
            Skip
          </Button>
        </form>
      );
    }

    const renderSwitch = () => {
      switch (this.props.isLoggedIn || this.props.companies_owned) {
        case this.props.companies_owned !== null:
          return <div>Already signed up</div>;
        case this.props.isLoggedIn:
          return <>{companySignUp}</>;
        default:
          return <>{userSignUp}</>;
      }
    };

    return (
      <div className="__signup-form">
        {renderSwitch()}
        {Object.keys(this.state.errors).length >= 1 && (
          <p className="__validate-text __error">
            {" "}
            Please fix the errors listed above{" "}
          </p>
        )}
      </div>
    );
  }
}

export default SignupFormInvited_Container;
