/* (2019-06-10) - This page is actually a page of the SIR/ApplicationRoom
It doesnt contain much just houses the components. The page displays all of
the flagged companies associated with the SIR. Basically, whenever the
flag button is clicked on one of the cards, it appears on this page.
The actual functions for restoring and deleting companies that have been
put in the flagged room alon with the components can be found in:
 /application_room/components/settings. The sidebar contains access
 to the other pages associate with the SIR.
*/
import React, { Component } from 'react';
import { AppContext } from '../App'
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import axios from 'axios'
import URL from 'url-parse';


import SiteTitle from './components/site_title.js'
import { Sign_In, Register, Forgot_Password, Alert_Message_To_User } from './components/popups'
import Navigation from './components/navbar'
import Page from "./application_room/components/flagged_companies/page"
import SideBar from "./application_room/components/Sidebar"
import TopNav from "./application_room/components/topNav"

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing.unit,
  },
  bootstrapFormLabel: {
    fontSize: 18,
  },
});

class Applicant_Room_Container extends React.Component {
  constructor(props) {
    super(props);
  }
  static contextType = AppContext;
  render() {
    return (
      <Applicant_Room
        {...this.props}
        {...this.context} />
    )
  }
}

class Applicant_Room extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: new URL(window.location.href, true),
      judges: "",
      opportunity_owner: "",
      show_page: true,
      UbaidSubClass: '',
      opportunity_data: {},
    }

  }
  componentDidMount() {
    axios.get(`/fetch_opportunity?opportunity_id=${this.state.url.query.opportunity_id}`)
      .then((response) => {
        this.setState({
          opportunity_data: response.data.result
        })
      })
    axios.get(`fetch_judges_for_opportunity?opportunity_id=${this.state.url.query.opportunity_id}`)
      .then((response) => {
        this.setState({
          judges: response.data.judges,
          opportunity_owner: response.data.owner,
          access_ids: response.data.access_ids
        }, () => {
          setTimeout(() => {
            if (this.state.access_ids.includes(this.props.user._id)||this.props.user.email==="trevor@pangeafirm.com"||this.props.user.email==="ash@startupfuel.com") {
            } else {
              //this.props.history.push('/explore')
            }
          }, 1000);

        })
      })

  }
  check = true;
  sideNavHandler = () => {
    this.check ? this.setState({ UbaidSubClass: 'UbaidSubClass' }) : this.setState({ UbaidSubClass: '' });
    this.check = !this.check;
  }
  render() {
    return (
      <div  className='__application-room-main'>
          <SiteTitle />
          <Sign_In />
          <Register />
          <Forgot_Password />
          <TopNav
            user={this.props.user}
            {...this.state} />
        <div className='__content'>
        <SideBar
          clicked={this.sideNavHandler}
          user={this.props.user}
          {...this.state} />
        <Page
          user={this.props.user}
          {...this.state} />
        </div>
      </div>
    );
  }
}

Applicant_Room_Container.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(Applicant_Room_Container);
