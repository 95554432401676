/* (2019-06-11) - This is the settings page of the SIR/Application room, it
allows you to configure various settings for the SIR. A lot of the logic is
handled on other pages, this mainly just accepts the checkbox.
*/
import React, { Component, Fragment } from "react";
import axios from "axios";
import URL from "url-parse";
import { FormControl, FormLabel } from "react-bootstrap";
import Button from "@material-ui/core/Button";
import total_review from "../../../../img/TotalReview.PNG";
import total_review_feedback from "../../../../img/TotalReviewFeedback.png";
import total_review_feedback_judge from "../../../../img/TotalReviewFeedbackJudge.png";
import { Success_Popup } from "../../../components/popups";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Switch";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Rating from "./Rating";
import "./index.scss";
import { NavItem } from "reactstrap";
import { Link } from "react-router-dom";
import ArgNav from "../argNav";
import Navigation from "../../../components/navbar_pb";
import SettingsNavigation from "./settings-sidenav";

class Page extends Component {
  constructor(props) {
    super(props);

    this.state = {
      url: new URL(window.location.href, true),
      feedback_setting: "basic",
      judges: [1],
      number_of_reviews: 7,
      judges_can_register: false,
      users_can_see_feedback: false,
      additional_review_options: true,
      additional_review_criteria: [],
      temp_value: "",
      success_msg: "Saved!",
      show_success_popup: false,
      enter_company_website: true,
      enter_pitch_video: false,
      judges_get_email: false,
      opportunity_private: false,
      limit_number_of_reviews: false,
      has_applicant_registration_email: false,
      applicant_registration_email: {
        header: "Thank you for applying!",
        body: `We appreciate you taking the time to apply to our application.
              We will get back to your shortly regarding how you did.
              `,
      },
      opportunity_form_modify: false,
      custom_text_q1: "",
      custom_text_q2: "",
      opportunity_form: [],
      titleNewForm: "",
      helperTextnewForm: "",
      displayNewForm: false,
      requiredNewForm: false,
      showFieldNform: false,
      QuestionAddnewDataAPi: [],
      saving: false,
    };
  }
  componentDidMount() {
    this.fetchApiQuestionNewData();

    axios
      .get(
        `fetch_opportunity?opportunity_id=${this.state.url.query.opportunity_id}`,
      )
      .then((response) => {
        console.log("response 66", response);
        this.setState({
          ...response.data.result,
        });
      });
    axios
      .get(
        `/fetch_optional_questions?opportunity_id=${this.state.url.query.opportunity_id}`,
      )
      .then((response) => {
        console.log("response 72", response);
        this.setState({
          ...response.data.existing_opportunity_form,
        });
      });
  }
  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({
        judges: this.props.judges,
      });
    }
  }

  fetchApiQuestionNewData = () => {
    axios
      .get(
        `fetch_opportunity?opportunity_id=${this.state.url.query.opportunity_id}`,
      )
      .then((response) => {
        this.setState({
          QuestionAddnewDataAPi: response?.data?.result?.opportunity_form_new,
        });
      });
  };

  displaySaved = () => {
    this.setState(
      {
        show_success_popup: true,
      },
      () => {
        setTimeout(() => this.setState({ show_success_popup: false }), 1500);
      },
    );
  };

  handleChange = (e) => {
    const name = e.target.name;
    this.setState({
      [name]: e.target.value,
    });
  };

  handleNewFormChange = (event) => {
    const { name, value } = event.target;
    if (name === "title") {
      this.setState({ titleNewForm: value });
    } else if (name === "helper_text") {
      this.setState({ helperTextnewForm: value });
    }
  };

  handleQuestionsChange = (event, question, key) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;

    if (key === "delete") {
      // Delete the question from the state
      this.setState((prevState) => {
        const updatedOpportunityForm = { ...prevState.opportunity_form };
        delete updatedOpportunityForm[question];
        return { opportunity_form: updatedOpportunityForm };
      });
    } else if (key === "required" || key === "display") {
      // Update the boolean value for 'required' or 'display'
      this.setState((prevState) => ({
        opportunity_form: {
          ...prevState.opportunity_form,
          [question]: {
            ...prevState.opportunity_form[question],
            [key]: value,
          },
        },
      }));
    } else {
      // Update the question's property
      this.setState((prevState) => ({
        opportunity_form: {
          ...prevState.opportunity_form,
          [question]: {
            ...prevState.opportunity_form[question],
            [key]: value,
          },
        },
      }));
    }
    console.log("Updates opportunity_form", this.state.opportunity_form);
  };

  toSnakeCase(str) {
    return str.replace(/\s+/g, "_").toLowerCase();
  }

  addOpportunityFormQuestion = () => {
    const { titleNewForm, helperTextnewForm, displayNewForm, requiredNewForm } =
      this.state;

    // Trim the input values
    const trimmedTitleNewForm = titleNewForm.trim();
    const trimmedHelperTextnewForm = helperTextnewForm.trim();

    if (trimmedTitleNewForm && trimmedHelperTextnewForm) {
      const newQuestion = {
        title: trimmedTitleNewForm,
        helper_text: trimmedHelperTextnewForm,
        display: displayNewForm,
        required: requiredNewForm,
      };

      const titleKey = this.toSnakeCase(trimmedTitleNewForm);

      this.setState((prevState) => ({
        opportunity_form: {
          ...prevState.opportunity_form,
          [titleKey]: newQuestion,
        },
        titleNewForm: "",
        helperTextnewForm: "",
        displayNewForm: false,
        requiredNewForm: false,
      }));
    } else {
      console.error(
        "Title and helper text are required to add a new question.",
      );
    }
  };

  showAddQForm = () => {
    this.setState({ showFieldNform: true });
  };

  submitOpportunityForm = async () => {
    this.setState({ saving: true }); // Set saving state to true

    const { opportunity_form } = this.state;
    const opportunity_id = this.state.url.query.opportunity_id; // Replace with actual opportunity_id

    try {
      const response = await fetch(
        `/edit_opportunity_form?opportunity_id=${opportunity_id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ opportunity_form }),
        },
      );

      const result = await response.json();

      if (response.ok) {
        console.log(result.message);
        // Handle success (e.g., show a success message, update state, etc.)
      } else {
        console.error(result.message);
        // Handle error (e.g., show an error message)
      }
    } catch (error) {
      console.error("Error submitting opportunity form:", error);
      // Handle error (e.g., show an error message)
    } finally {
      this.setState({ saving: false }); // Reset saving state to false
    }
  };

  handleEmailChange = (e) => {
    const name = e.target.name;
    this.setState({
      applicant_registration_email: {
        ...this.state.applicant_registration_email,
        [name]: e.target.value,
      },
    });
  };

  handleNumberReviewsSave = (e) => {
    axios.post(
      `/edit_opportunity_settings?opportunity_id=${this.state.url.query.opportunity_id}`,
      {
        judges_can_register: this.state.judges_can_register,
        users_can_see_feedback: this.state.users_can_see_feedback,
        judges_get_email: this.state.judges_get_email,
        limit_number_of_reviews: this.state.limit_number_of_reviews,
        additional_review_criteria: this.state.additional_review_criteria,
        additional_review_options: this.state.additional_review_options,
        number_of_reviews: this.state.number_of_reviews,
        opportunity_private: this.state.opportunity_private,
        opportunity_form_modify: this.state.opportunity_form_modify,
        has_applicant_registration_email:
          this.state.has_applicant_registration_email,
      },
    );
    this.displaySaved();
  };

  handleCheck = (e) => {
    const name = e.target.name;
    this.setState(
      {
        [name]: !this.state[name],
      },
      () => {
        axios.post(
          `/edit_opportunity_settings?opportunity_id=${this.state.url.query.opportunity_id}`,
          {
            judges_can_register: this.state.judges_can_register,
            users_can_see_feedback: this.state.users_can_see_feedback,
            judges_get_email: this.state.judges_get_email,
            limit_number_of_reviews: this.state.limit_number_of_reviews,
            opportunity_private: this.state.opportunity_private,
            opportunity_form_modify: this.state.opportunity_form_modify,
            has_applicant_registration_email:
              this.state.has_applicant_registration_email,
            additional_review_options: this.state.additional_review_options,
            additional_review_criteria: this.state.additional_review_criteria,
            enter_company_website: this.state.enter_company_website,
            enter_pitch_video: this.state.enter_pitch_video,
          },
        );
        this.displaySaved();
      },
    );
  };
  handleCustomForm = (e, key) => {
    const name = e.target.name;
    let target_key = this.state.opportunity_form[key];
    if (
      name === "display" &&
      this.state.opportunity_form[key]["display"] === true
    ) {
      let obj = {
        ...this.state.opportunity_form[key],
        ["required"]: false,
        ["display"]: false,
      };
      this.setState({
        opportunity_form: { ...this.state.opportunity_form, [key]: obj },
      });
    } else {
      let obj = {
        ...this.state.opportunity_form[key],
        [name]: !this.state.opportunity_form[key][name],
      };
      this.setState({
        opportunity_form: { ...this.state.opportunity_form, [key]: obj },
      });
    }
  };

  handleFormSave = (e) => {
    axios.post(
      `/edit_opportunity_form?opportunity_id=${this.state.url.query.opportunity_id}`,
      {
        opportunity_form: this.state.opportunity_form,
      },
    );
    this.displaySaved();
  };

  handleEmailSave = (e) => {
    axios.post(
      `/edit_opportunity_email?opportunity_id=${this.state.url.query.opportunity_id}`,
      {
        applicant_registration_email: {
          header: this.state.applicant_registration_email.header,
          body: this.state.applicant_registration_email.body,
        },
      },
    );
    this.displaySaved();
  };

  handleSave = (e) => {
    axios.post(
      `/create_optional_questions?opportunity_id=${this.state.url.query.opportunity_id}`,
      {
        custom_text_q1: this.state.custom_text_q1,
        custom_text_q2: this.state.custom_text_q2,
      },
    );
  };

  addCriteria = (e) => {
    this.setState(
      {
        additional_review_criteria: [
          ...this.state.additional_review_criteria,
          this.state.temp_value,
        ],
        temp_value: "",
      },
      () => {
        axios.post(
          `/edit_opportunity_settings?opportunity_id=${this.state.url.query.opportunity_id}`,
          {
            judges_can_register: this.state.judges_can_register,
            users_can_see_feedback: this.state.users_can_see_feedback,
            judges_get_email: this.state.judges_get_email,
            limit_number_of_reviews: this.state.limit_number_of_reviews,
            opportunity_private: this.state.opportunity_private,
            opportunity_form_modify: this.state.opportunity_form_modify,
            has_applicant_registration_email:
              this.state.has_applicant_registration_email,
            additional_review_options: this.state.additional_review_options,
            additional_review_criteria: this.state.additional_review_criteria,
          },
        );
        this.displaySaved();
      },
    );
  };
  deleteCriteria = (e, value) => {
    let newList = this.state.additional_review_criteria.splice(
      this.state.additional_review_criteria.indexOf(value),
      1,
    );
    this.setState(
      {
        additional_review_criteria:
          this.state.additional_review_criteria.filter((x) => x !== value),
      },
      () => {
        axios.post(
          `/edit_opportunity_settings?opportunity_id=${this.state.url.query.opportunity_id}`,
          {
            judges_can_register: this.state.judges_can_register,
            users_can_see_feedback: this.state.users_can_see_feedback,
            judges_get_email: this.state.judges_get_email,
            limit_number_of_reviews: this.state.limit_number_of_reviews,
            opportunity_private: this.state.opportunity_private,
            opportunity_form_modify: this.state.opportunity_form_modify,
            has_applicant_registration_email:
              this.state.has_applicant_registration_email,
            additional_review_options: this.state.additional_review_options,
            additional_review_criteria: this.state.additional_review_criteria,
          },
        );
        this.displaySaved();
        axios.post(
          `/edit_rating_criteria_for_opp?opportunity_id=${this.state.url.query.opportunity_id}`,
          {
            additional_review_criteria: this.state.additional_review_criteria,
            value: value,
          },
        );
      },
    );
  };

  handleRadio = (e) => {
    this.setState(
      {
        feedback_setting: e.target.value,
      },
      () => {
        axios.post(
          `/edit_feedback_setting_opp?opportunity_id=${this.state.url.query.opportunity_id}`,
          {
            feedback_setting: this.state.feedback_setting,
          },
        );
      },
    );
  };

  render() {
    const { url, QuestionAddnewDataAPi } = this.state;
    return (
      <div>
        <Navigation
          opportunity_data={
            this.state.opportunity_data
              ? {
                  ...this.state.opportunity_data,
                  // Ensure that credits are present; if not, fallback to this.state.credits
                  credits:
                    this.state.opportunity_data.credits || this.state.credits,
                }
              : { credits: this.state.credits }
          }
        />{" "}
        <ArgNav
          opportunity_name={this.props.opportunity_name}
          opportunity_id={this.state.url.query.opportunity_id}
          pathname={this.state.url.pathname}
        />
        <div class="_content">
          <Fragment>
            <Success_Popup
              show={this.state.show_success_popup}
              alert_message={this.state.success_msg}
            />
            <div className="__application-room-settings">
              <div className="__application-room-padding __header">
                <h3>Applicant Settings</h3>
              </div>
              <div className="__pb_settings_page">
                <SettingsNavigation />
                <div
                  className="__main __application-room-padding"
                  style={{ overflowX: "auto" }}
                >
                  <label>
                    <Checkbox
                      checked={this.state.users_can_see_feedback}
                      onChange={(event) => {
                        this.handleCheck(event);
                      }}
                      name="users_can_see_feedback"
                      color="primary"
                    />
                    <p className="__settings_text">
                      Deal Feedback Report<em></em>
                    </p>
                  </label>
                  {this.state.users_can_see_feedback && (
                    <RadioGroup
                      aria-label="gender"
                      name="gender2"
                      value={this.state.feedback_setting}
                      onChange={this.handleRadio}
                      className="__review-setting"
                    >
                      <div className="__applicant-settings-wrapper">
                        <div className="__application-room-radio-image">
                          <label className="__radio-btn">
                            <FormControlLabel
                              value="basic"
                              control={<Radio color="primary" />}
                              label="Basic"
                              labelPlacement="end"
                            />
                          </label>
                          <Rating />
                        </div>
                        <div className="__application-room-radio-image">
                          <label className="__radio-btn">
                            <FormControlLabel
                              value="detailed_hidden_judges"
                              control={<Radio color="primary" />}
                              label="Detailed - Hide Team Members"
                              labelPlacement="end"
                            />
                          </label>
                          <Rating>
                            <hr />
                            <div className="__title __rating-title">
                              Feedback
                            </div>
                            <div className="__feedback-para">
                              Lorum Ipsum Lorum Lorum Lorum Ipsum Lorum Lorum
                            </div>
                            <hr />
                            <div>
                              Lorum Ipsum Lorum Lorum Lorum Ipsum Lorum Lorum
                            </div>
                          </Rating>
                        </div>
                        <div className="__application-room-radio-image">
                          <label className="__radio-btn">
                            <FormControlLabel
                              value="detailed_show_judges"
                              control={<Radio color="primary" />}
                              label="Detailed - Show Team Members"
                              labelPlacement="end"
                            />
                          </label>
                          <Rating>
                            <hr />
                            <div className="__title __rating-title">
                              Feedback
                            </div>
                            <div className="__user">
                              <div className="__user-avtar"></div>
                              <div>John Doe</div>
                              <div className="__date">Sep 23, 2020</div>
                            </div>
                            <div className="__feedback-para">
                              Lorum Ipsum Lorum Lorum Lorum Ipsum Lorum Lorum
                            </div>
                            <hr />
                            <div className="__user">
                              <div className="__user-avtar"></div>
                              <div>John Doe</div>
                              <div className="__date">Sep 23, 2020</div>
                            </div>
                            <div className="__feedback-para">
                              Lorum Ipsum Lorum Lorum Lorum Ipsum Lorum Lorum
                            </div>
                          </Rating>
                        </div>
                      </div>
                    </RadioGroup>
                  )}
                  <label>
                    <Checkbox
                      checked={this.state.has_applicant_registration_email}
                      onChange={(event) => {
                        this.handleCheck(event);
                      }}
                      name="has_applicant_registration_email"
                      color="primary"
                    />
                    <p className="__settings_text">
                      Send Custom Email To Companies Who Apply
                    </p>
                  </label>
                  {this.state.has_applicant_registration_email && (
                    <div>
                      <div>
                        <FormLabel className="control-label">
                          Email Header
                        </FormLabel>
                        <FormControl
                          type="text"
                          value={this.state.applicant_registration_email.header}
                          placeholder="Ex.How did you hear about this opportunity?"
                          name="header"
                          onChange={this.handleEmailChange}
                          className="form-control mb-3"
                        />
                      </div>
                      <div>
                        <FormLabel className="control-label">
                          Email Body
                        </FormLabel>
                        <FormControl
                          as="textarea"
                          rows="5"
                          value={this.state.applicant_registration_email.body}
                          placeholder="Ex. Enter the name of the person that reffered you?"
                          name="body"
                          onChange={this.handleEmailChange}
                        />
                      </div>{" "}
                      <br />
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={this.handleEmailSave}
                      >
                        Save
                      </Button>
                      <br /> <br />
                    </div>
                  )}
                  <label>
                    <Checkbox
                      checked={this.state.opportunity_form_modify}
                      onChange={(event) => {
                        this.handleCheck(event);
                      }}
                      name="opportunity_form_modify"
                      color="primary"
                    />
                    <p className="__settings_text">
                      Modify Portal Questions<em></em>
                    </p>
                  </label>
                  {this.state.opportunity_form_modify && (
                    <Fragment>
                      {/*<label>*/}
                      {/*  <Checkbox*/}
                      {/*    checked={this.state.enter_company_website}*/}
                      {/*    onChange={(event) => {*/}
                      {/*      this.handleCheck(event);*/}
                      {/*    }}*/}
                      {/*    name="enter_company_website"*/}
                      {/*    color="primary"*/}
                      {/*  />*/}
                      {/*  Would you like the user to enter their company website?*/}
                      {/*</label>*/}
                      {/*<label>*/}
                      {/*  <Checkbox*/}
                      {/*    checked={this.state.enter_pitch_video}*/}
                      {/*    onChange={(event) => {*/}
                      {/*      this.handleCheck(event);*/}
                      {/*    }}*/}
                      {/*    name="enter_pitch_video"*/}
                      {/*    color="primary"*/}
                      {/*  />*/}
                      {/*  Would you like the user to enter a pitch video?*/}
                      {/*</label>*/}
                      {/*{Object.keys(this.state.opportunity_form).map(*/}
                      {/*  (opp_form_key, i) => (*/}
                      {/*    <div>*/}
                      {/*      <FormLabel>*/}
                      {/*        {*/}
                      {/*          this.state.opportunity_form[opp_form_key][*/}
                      {/*            "title"*/}
                      {/*          ]*/}
                      {/*        }*/}
                      {/*      </FormLabel>*/}
                      {/*      <div className="__applicant-settings-form">*/}
                      {/*        <TextField*/}
                      {/*          disabled*/}
                      {/*          rows="4"*/}
                      {/*          style={{ width: "500px" }}*/}
                      {/*          variant="outlined"*/}
                      {/*          multiline*/}
                      {/*          placeholder={*/}
                      {/*            this.state.opportunity_form[opp_form_key][*/}
                      {/*              "helper_text"*/}
                      {/*            ]*/}
                      {/*          }*/}
                      {/*        />*/}
                      {/*        <div className="__checkbox-wrapper">*/}
                      {/*          <FormControlLabel*/}
                      {/*            value="top"*/}
                      {/*            control={*/}
                      {/*              <Checkbox*/}
                      {/*                checked={*/}
                      {/*                  this.state.opportunity_form[*/}
                      {/*                    opp_form_key*/}
                      {/*                  ]["display"]*/}
                      {/*                }*/}
                      {/*                onChange={(event) => {*/}
                      {/*                  this.handleCustomForm(*/}
                      {/*                    event,*/}
                      {/*                    opp_form_key,*/}
                      {/*                  );*/}
                      {/*                }}*/}
                      {/*                name="display"*/}
                      {/*                color="primary"*/}
                      {/*              />*/}
                      {/*            }*/}
                      {/*            label="Display"*/}
                      {/*            labelPlacement="top"*/}
                      {/*          />*/}
                      {/*          <FormControlLabel*/}
                      {/*            value="top"*/}
                      {/*            control={*/}
                      {/*              <Checkbox*/}
                      {/*                checked={*/}
                      {/*                  this.state.opportunity_form[*/}
                      {/*                    opp_form_key*/}
                      {/*                  ]["required"]*/}
                      {/*                }*/}
                      {/*                onChange={(event) => {*/}
                      {/*                  this.handleCustomForm(*/}
                      {/*                    event,*/}
                      {/*                    opp_form_key,*/}
                      {/*                  );*/}
                      {/*                }}*/}
                      {/*                name="required"*/}
                      {/*                color="primary"*/}
                      {/*              />*/}
                      {/*            }*/}
                      {/*            label="Required"*/}
                      {/*            labelPlacement="top"*/}
                      {/*          />*/}
                      {/*        </div>*/}
                      {/*      </div>*/}
                      {/*    </div>*/}
                      {/*  ),*/}
                      {/*)}*/}

                      {Object.keys(this.state.opportunity_form).map(
                        (question, index) => (
                          <div key={index} style={{ marginBottom: "30px" }}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginBottom: "15px",
                              }}
                            >
                              <span
                                style={{
                                  fontWeight: "bold",
                                  marginRight: "15px",
                                  fontSize: "18px",
                                }}
                              >
                                {index + 1}.
                              </span>
                              <input
                                style={{
                                  width: "100%",
                                  padding: "10px 15px",
                                  fontSize: "16px",
                                  border: "1px solid #ccc",
                                  borderRadius: "4px",
                                  boxSizing: "border-box",
                                }}
                                type="text"
                                placeholder={
                                  this.state.opportunity_form[question]["title"]
                                }
                                onChange={(event) =>
                                  this.handleQuestionsChange(
                                    event,
                                    question,
                                    "title",
                                  )
                                }
                              />
                            </div>

                            <div style={{ marginBottom: "15px" }}>
                              <TextField
                                rows="4"
                                style={{
                                  width: "100%",
                                }}
                                variant="outlined"
                                multiline
                                onChange={(event) =>
                                  this.handleQuestionsChange(
                                    event,
                                    question,
                                    "helper_text",
                                  )
                                }
                                placeholder={
                                  this.state.opportunity_form[question][
                                    "helper_text"
                                  ]
                                }
                              />
                            </div>

                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                marginBottom: "15px",
                              }}
                            >
                              <div>
                                <FormControlLabel
                                  value="top"
                                  control={
                                    <Checkbox
                                      checked={
                                        this.state.opportunity_form[question][
                                          "display"
                                        ]
                                      }
                                      onChange={(event) =>
                                        this.handleQuestionsChange(
                                          event,
                                          question,
                                          "display",
                                        )
                                      }
                                      name="display"
                                      color="primary"
                                    />
                                  }
                                  label="Display"
                                  labelPlacement="top"
                                />
                                <FormControlLabel
                                  value="top"
                                  control={
                                    <Checkbox
                                      checked={
                                        this.state.opportunity_form[question][
                                          "required"
                                        ]
                                      }
                                      onChange={(event) =>
                                        this.handleQuestionsChange(
                                          event,
                                          question,
                                          "required",
                                        )
                                      }
                                      name="required"
                                      color="primary"
                                    />
                                  }
                                  label="Required"
                                  labelPlacement="top"
                                />
                              </div>
                              <i
                                className="material-icons delete-icon"
                                style={{
                                  fontSize: "26px",
                                  margin: 0,
                                  cursor: "pointer",
                                  color: "#f44336",
                                }}
                                onClick={(event) =>
                                  this.handleQuestionsChange(
                                    event,
                                    question,
                                    "delete",
                                  )
                                }
                              >
                                delete
                              </i>
                            </div>

                            <hr
                              style={{
                                border: "none",
                                borderTop: "1px solid #ccc",
                              }}
                            />
                          </div>
                        ),
                      )}

                      {this.state.showFieldNform ? (
                        <div style={{ marginBottom: "30px" }}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "15px",
                            }}
                          >
                            <span
                              style={{
                                fontWeight: "bold",
                                marginRight: "15px",
                                fontSize: "18px",
                              }}
                            >
                              New Question
                            </span>
                            <FormControl
                              style={{
                                width: "100%",
                                marginRight: "15px",
                              }}
                              value={this.state.titleNewForm}
                              placeholder="Enter the title"
                              onChange={this.handleNewFormChange}
                              name="title"
                            />
                          </div>

                          <div style={{ marginBottom: "15px" }}>
                            <FormControl
                              as="textarea"
                              rows="4"
                              style={{
                                width: "100%",
                              }}
                              value={this.state.helperTextnewForm}
                              placeholder="Enter the Helper Text"
                              onChange={this.handleNewFormChange}
                              name="helper_text"
                            />
                          </div>

                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              marginBottom: "15px",
                            }}
                          >
                            <div>
                              <FormControlLabel
                                value="top"
                                control={
                                  <Checkbox
                                    checked={this.state.displayNewForm}
                                    onChange={(event) =>
                                      this.setState((prevState) => ({
                                        displayNewForm:
                                          !prevState.displayNewForm,
                                      }))
                                    }
                                    name="displayNewForm"
                                    color="primary"
                                  />
                                }
                                label="Display"
                                labelPlacement="top"
                              />
                              <FormControlLabel
                                value="top"
                                control={
                                  <Checkbox
                                    checked={this.state.requiredNewForm}
                                    onChange={(event) =>
                                      this.setState((prevState) => ({
                                        requiredNewForm:
                                          !prevState.requiredNewForm,
                                      }))
                                    }
                                    name="requiredNewForm"
                                    color="primary"
                                  />
                                }
                                label="Required"
                                labelPlacement="top"
                              />
                            </div>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={this.addOpportunityFormQuestion}
                            >
                              Add Question
                            </Button>
                          </div>

                          <hr
                            style={{
                              border: "none",
                              borderTop: "1px solid #ccc",
                            }}
                          />
                        </div>
                      ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={this.showAddQForm}
                          style={{ marginBottom: "30px" }}
                        >
                          Add New Question
                        </Button>
                      )}
                      <br />
                      <br />
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={this.submitOpportunityForm}
                        disabled={this.state.saving}
                      >
                        {this.state.saving ? "Saving..." : "Save"}
                      </Button>
                    </Fragment>
                  )}
                </div>
              </div>
            </div>
          </Fragment>
        </div>
      </div>
    );
  }
}

export default Page;
