import React, { useState } from "react";
import Submenu from "../submenu";
import StartupScore from "./startupScore";
import Tearsheet from "./tearsheet";
import Summary from "./summary";
import ComingSoon from "./comingSoon";
import DataSufficiencyScore from "./dataSufficiency";
import ChatLayout from "../reviewsCardContainer";
import MarketIntelligence from "./marketIntelligence";
import Metrics from "./metrics";
import DealMemo from "./dealmemo/dealMemo";
const ReportsMain = ({
  companyInfo,
  applicationObject,
  opportunityInfo,
  handleCreditChange,
  updateCompanyData,
  user,
}) => {
  const menuItems = [
    "Data Sufficiency",
    "Tear Sheet",
    "Deal Brief",
    "Startup Score",
    "Metrics",
    "Market Intelligence",
    "Deal Memo",
  ];
  const [activeItem, setActiveItem] = useState("Data Sufficiency");

  const renderActiveComponent = () => {
    switch (activeItem) {
      case "Data Sufficiency":
        return (
          <DataSufficiencyScore
            updateCompanyData={updateCompanyData}
            companyInfo={companyInfo}
            applicationObject={applicationObject}
            opportunityInfo={opportunityInfo}
            handleCreditChange={handleCreditChange}
          />
        );
      case "Tear Sheet":
        return (
          <Tearsheet
            updateCompanyData={updateCompanyData}
            applicationInfo={applicationObject}
            company={companyInfo}
            handleCreditChange={handleCreditChange}
            opportunity_data={opportunityInfo}
          />
        );
      case "Deal Brief":
        return (
          <Summary
            updateCompanyData={updateCompanyData}
            applicationObject={applicationObject}
            companyInfo={companyInfo}
            opportunityInfo={opportunityInfo}
            handleCreditChange={handleCreditChange}
          />
        );
      case "Startup Score":
        return (
          <StartupScore
            updateCompanyData={updateCompanyData}
            companyInfo={companyInfo}
            applicationObject={applicationObject}
            opportunityInfo={opportunityInfo}
            handleCreditChange={handleCreditChange}
          />
        );
      case "Metrics":
        return (
          <Metrics
            updateCompanyData={updateCompanyData}
            opportunityInfo={opportunityInfo}
            companyInfo={companyInfo}
            applicationObject={applicationObject}
            handleCreditChange={handleCreditChange}
          />
        );
      case "Market Intelligence":
        return (
          <MarketIntelligence
            updateCompanyData={updateCompanyData}
            opportunityInfo={opportunityInfo}
            companyInfo={companyInfo}
            applicationObject={applicationObject}
            handleCreditChange={handleCreditChange}
          />
        );
      case "Deal Memo":
        // return (
        //   <DealMemo
        //     updateCompanyData={updateCompanyData}
        //     opportunityInfo={opportunityInfo}
        //     companyInfo={companyInfo}
        //     applicationObject={applicationObject}
        //     handleCreditChange={handleCreditChange}
        //   />
        // );
        return <ComingSoon />;
      default:
        return null;
    }
  };

  return (
    <ChatLayout
      companyInfo={companyInfo}
      opportunity_data={opportunityInfo}
      updateCompanyData={updateCompanyData}
      applicationObject={applicationObject}
      handleCreditChange={handleCreditChange}
      user={user}
    >
      <div className="reports-container">
        <Submenu
          activeItem={activeItem}
          setActiveItem={setActiveItem}
          menuItems={menuItems}
        />
        <div className="reports-content">{renderActiveComponent()}</div>
      </div>
    </ChatLayout>
  );
};

export default ReportsMain;
