import React, { useRef, useEffect } from 'react';
import './NewsLetter.css';
import paperplan from '../assets/paper-plane-300x245.png';
import InputForm from '../LoginPage/InputForm';

const NewsLetter = props => {
    const floatingOlympus = useRef();
    useEffect(() => {
        window.addEventListener('scroll', () => {
            let x = window.scrollY + 300;
            if (x >= floatingOlympus.current.offsetTop) {
                floatingOlympus.current.className = 'floatingOlympus';
            }
        });
    }, [])
    return (
        <div className='OlympusNewsLetter'>
            <div className='MainBodyOlympus'>
                <div className='detailsOlympus'>
                    <h2>Olympus Newsletter</h2>
                    <p>Subscribe to be the first one to know about updates, new features and much more!</p>
                    <form>
                        <InputForm label='Enter your Email' />
                        <button>Send</button>
                    </form>
                </div>
                <img src={paperplan} alt='' ref={floatingOlympus} />
            </div>
        </div>
    )
}

export default NewsLetter;