import React from 'react';
import axios from 'axios'
import { FormControl } from 'react-bootstrap';
import '../test_mj-dev/sass/sass-css/_privacy_policy.scss';
import Navbar from './components/navbar';
import SideMenu from './SideMenu';
import { withStyles } from '@material-ui/core/styles';
import { AppContext } from '../App'
import PropTypes from 'prop-types';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);
const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  }, icon: {
    margin: theme.spacing.unit * 2,
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
});

class Page_Container extends React.Component {
  constructor(props) {
    super(props);
  }
  static contextType = AppContext;
  render() {
    return (<div>
      <Page {...this.props} {...this.context} />
    </div>
    )
  }
}
class Page extends React.Component {

  constructor(props) {
    super(props);
      this.state={
        services:[],
        show_edit:[],
        edited_service:{}
      }
  }
  componentDidMount(){
    axios.get('/fetch_admin_company_services').then((response)=>{
      this.setState({
        services:response.data.services
      })
    })
  }

  changeStatus = (e,id,value) =>{
    e.preventDefault()
    axios.post('/update_service_status',{
      service_id:id,
      new_approval_status:!value
    }).then((response)=>{
      if (response.data.status === "success"){
        axios.get('/fetch_admin_company_services').then((response)=>{
          this.setState({
            services:response.data.services
          })
        })
      }
    })


  }

  handleEditUrl=(value)=>{
    if (this.state.show_edit.includes(value)){
      let arr = this.state.show_edit.filter((x)=>x!==value)

      this.setState({
        show_edit:arr
      })
    } else {
      let arr = this.state.show_edit
      arr.push(value)
      this.setState({
        show_edit:arr
      })
    }
  }
handleChange=(e, index)=>{
  e.preventDefault()
  this.setState({
    services:  [
  ...this.state.services.slice(0, index),
  { ...this.state.services[index], service_link: e.target.value},
  ...this.state.services.slice(index + 1),
]})

}

handleSave =(e,index)=>{
  axios.post('/edit_service_link',{
    id:this.state.services[index]._id,
    service_link:this.state.services[index].service_link,
  }).then((response)=>{
    if (response.data.status === "success"){
      let arr = this.state.show_edit.filter((x)=>x!==index)

      this.setState({
        show_edit:arr
      })
    }
  })
}

  render() {
    const { classes } = this.props;
    return (
		<React.Fragment>
			<Navbar />
			<SideMenu />
      <Paper style={{width:"85%", margin:"auto"}} className={classes.root + ' ubaidTable'}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>

              <StyledTableCell>Company Name</StyledTableCell>
              <StyledTableCell>Service Name</StyledTableCell>
              <StyledTableCell style={{ maxWidth: '100px'}}>Description</StyledTableCell>
              <StyledTableCell style={{ textAlign: 'center'}}>Cost</StyledTableCell>
              <StyledTableCell style={{ minWidth: '100px'}}>Request Access</StyledTableCell>
              <StyledTableCell style={{ minWidth: '100px'}}>Has Access</StyledTableCell>
              <StyledTableCell >Contact Email</StyledTableCell>
              <StyledTableCell >Service URL</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {this.state.services.length >0 &&
            <>
            {this.state.services.map((service, index)=>(
              <StyledTableRow key="1">

                <StyledTableCell component="th" scope="row">
                  <a href={`/profile?company_id=${service.affiliated_company._id}`}>{service.affiliated_company.company_name}</a>
                </StyledTableCell>
                <StyledTableCell>{service.title_of_service}</StyledTableCell>
                <StyledTableCell >{service.description_of_service}</StyledTableCell>
                <StyledTableCell style={{textAlign: 'center'}}>{service.service_price} {service.pricing_model}</StyledTableCell>
                  <StyledTableCell style={{ minWidth: '100px'}}>{service.wants_startupfuel_approval?"True":"False"}</StyledTableCell>
                  <StyledTableCell style={{ minWidth: '100px'}} ><button onClick={(event)=>this.changeStatus(event,service._id,service.approval_status)} >{service.approval_status?"True":"False"}</button></StyledTableCell>
                  <StyledTableCell style ={{maxWidth:"200px", overflow:"auto"}}>{service.aff.user.email}</StyledTableCell>
                  {this.state.show_edit.includes(index)?
                    <StyledTableCell style ={{maxWidth:"200px", overflow:"auto"}}><FormControl
                      type="text"
                      value={service.service_link}
                      placeholder="Street Name"
                      name='street_name'
                      onChange={(event)=>this.handleChange(event,index)}
                      className="form-control mb-3"
                    /><i
                      style={{ marginTop: "10px" }}
                      onClick={(event) => this.handleSave(event,index)}
                      className={"fas fa-check" }></i></StyledTableCell>
                    :
                    <StyledTableCell style ={{maxWidth:"200px", overflow:"auto"}}>{service.service_link}<i
                      style={{ marginTop: "10px" }}
                      onClick={() => this.handleEditUrl(index)}
                      className={"fa fa-pencil " }></i></StyledTableCell>
                  }

              </StyledTableRow>
            ))}

            </>
          }


          </TableBody>
        </Table>
      </Paper>
		</React.Fragment>

	)

}
}




Page_Container.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Page_Container);
