import React from 'react';
import { render } from 'react-snapshot';
import './main.css';
import Router from './Router';
import { unregister } from './registerServiceWorker';

import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/ie11'; // For IE 11 support

render(
    <Router/>,
    document.getElementById('root')
  );

unregister();
