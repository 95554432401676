import React from "react";
export default function pdfFooter() {
  return (
    <>
      <hr
        style={{
          margin: "20px 0",
          border: "none",
          borderTop: "2px solid #000",
          width: "100%",
        }}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <p>
          Confidential | Contact us for info on how this was derived:
          diligence@startupfuel.com
        </p>
        <img
          src={require("../../../../../img/favicon.png")}
          style={{ width: 40 }}
        />
      </div>
    </>
  );
}
