import React from 'react';
import MainLayout from './MainLayout';
import { AppContext } from '../App'
import FollowingList from './CenterBody/FollowingList';

class PageContainer extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <FollowingPage
        {...this.props}
        {...this.context} />
    )
  }
}
PageContainer.contextType = AppContext;

class FollowingPage extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <MainLayout {... this.props}>
        <FollowingList/>
      </MainLayout>
      );
  }
}

export default PageContainer;
