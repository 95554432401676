/* (2019-06-11) - An outdated footer that is not currently in use.
*/
import React from 'react';

const Footer = () => {
  return (

    <footer className="footer">
      <div class="container">
        <div class="row">
          <div className="social-links col-lg-6">
            <p>
              StartupFuel has a goal of revolutionizing the venture capital industry by acting as funding tool for startups. Startups can track their performance, increase exposure,
              and garner investments. Investors h ave all documents needed to conduct a proper due diligence.
            </p>
          </div>
          <div className="company-trademark col-lg-6">
            <p>
              Address:
              <br /> 123 Edward St. #221, Toronto, Ontario, Canada
              <br /> Phone:
              <br /> General Consultations: (416) 859-6564
              <br /> Tech Development: (647) 985-0392
              <br /> Marketing: (647) 686-3345
              <br /> Email
              <br /> info@pangeafirm.com
            </p>
          </div>
        </div>
      </div>
    </footer>
  )
};

export default Footer;
