import React, { useState } from 'react';
import './CenterBody.css';
import SocialAPI from '../../../api/social_community';
import ComputerIcon from '../icons/ComputerIcon';
import ThreeDotsIcon from '../icons/ThreeDotsIcon';
import ProfileImage from '../../../components/profile_image';
import URL from 'url-parse';

class FeedPostShare extends React.Component {
  constructor(props) {
    super(props)
    this.textBox = React.createRef();
    this.state = {
      url: new URL(window.location.href, true)
    };
  }

  handlePostContentChange = (event) => {
    this.setState({
      postContent: event.target.value
    });
  }

  handlePostSubmit = (event) => {
    this.handlePost(this.state.postContent, this.state.url.query.community_id);
    event.preventDefault();
  }

  handlePost = (content, community_id) => {
    SocialAPI.post(content, community_id)
      .then((response) => {
        // refresh feed
        this.props.onSuccessfulPost();
        this.textBox.current.value = '';
        this.setState({
          postContent: undefined
        });
      })
  }

  render() {
    return (
      <div className='newsfeedPost'>
        <div className='addPostNews'>
          <ProfileImage />
          <div className='shareyouthinkNews'>
            <textarea
              ref={ this.textBox }
              onChange={ this.handlePostContentChange }
              value={ this.state.postContent }
              placeholder="Share something with the community..." />
          </div>
        </div>
        <div className='newsFeedPostFooter'>
          <div className='newsfeedPostBtn'>
            <button
              onClick={ this.handlePostSubmit }
              className='newsfeedOrange'>
              Post
            </button>
          </div>
        </div>
      </div>
    )
  }
}

export default FeedPostShare;
