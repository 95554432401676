import React, { useRef } from 'react';
import './InputForm.css';

const InputForm = props => {
    const labelClass = useRef();
    const inputField = useRef();
    const inputEl = useRef()
    const focused = () => {
        labelClass.current.className = 'olympus-changelabel';
        inputField.current.style.borderColor = '#ff5e3a';
    }
    const blured = (event) => {
        if (event.target.value === '') {
            labelClass.current.className = ''
        }
        inputField.current.style.borderColor = '#e6ecf5';
    }
    let check = true;
    const showPassword = (event) => {
        if(check){
            inputEl.current.type = 'text';
            event.target.className = 'far fa-eye-slash';
        } else{
            inputEl.current.type = 'password';
            event.target.className = 'far fa-eye';   
        }
        check = !check;
    }
    return (
        <div className='olympus-inputField' ref={inputField} >
            <label ref={labelClass} htmlFor={props.label}>{props.label}</label>
            <input type={props.type} id={props.label} onFocus={focused} onBlur={blured} ref={inputEl} />
            {props.type === 'password' ? <i className='far fa-eye' onClick={showPassword}></i> : null}
        </div>
    )
}

export default InputForm;