import React, { useState } from 'react';
import './CenterBody.css';
import SocialAPI from '../../live/api/social';
import ProfileImage from '../../live/components/profile_image';

class FollowerList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      list: []
    };
  }

  componentDidMount() {
    this.fetchFeed();
  }

  fetchFeed = () => {
    SocialAPI.follower_list()
      .then((response) => {
        if (response.data.status === 'success') {
          this.setState({
            loaded: true,
            list: response.data.followers || []
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    return (
      <div className='CenterBodyNewsFeed'>
        <div className="SocialFeedTitleBox">
          Followers <span className="countBox">{ this.state.list.length }</span>
        </div>
        { this.state.loaded && this.state.list.length == 0 && (
          <h5 style={ { textAlign: "center" } }><br />You currently have no followers at the moment</h5>
          ) }
        { this.state.list.map(function(item, i) {
            return (
              <div
                key={ i }
                className="FollowingListFeed">
                <a href={ "/profile?company_id=" + item._id }>
                  <ProfileImage company_id={ item._id } />
                </a>
                <a
                  href={ "/profile?company_id=" + item._id }
                  className="mainContent"><span>{ item.company_name }</span> <span className="small">{ item.company_type && item.company_type[0] }</span></a>
              </div>
            )
          }.bind(this)) }
      </div>
    )
  }
}

export default FollowerList;
