import React from "react";
import { CircularProgress } from "@material-ui/core";

const LoadingButton = ({ loading, text, onClick, ...props }) => {
  return (
    <button
      onClick={onClick}
      disabled={loading}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      {...props}
    >
      {loading && (
        <CircularProgress
          color="inherit"
          size={18}
          style={{ marginRight: "8px" }}
        />
      )}
      <span>{text}</span>
    </button>
  );
};

export default LoadingButton;
