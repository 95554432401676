import React from "react";
import default_pic from "../../../../img/default_company_pic.png";

export default function OpportunityInfo({ opportunityData, files }) {
  const { opportunity_name, opportunity_type, website_url } = opportunityData;

  const getAvatarUrl = (array, file_type) => {
    if (array?.length > 0) {
      let new_arr = array.filter((file) => file.file_type === file_type);
      return new_arr.length !== 0 ? new_arr[0].file_url : default_pic;
    } else {
      return default_pic;
    }
  };

  const avatarUrl = getAvatarUrl(files, "company avatar");

  return (
    <div className="opportunity-submission-card-responsive">
      <img src={avatarUrl} width={200} height={200} alt="Company Avatar" />
      <div>
        <p className="type-oppo-new mt-4">{opportunity_type}</p>
      </div>
      <p style={{ fontWeight: "bold" }}>{opportunity_name}</p>
      <div>
        <button
          style={{
            backgroundColor: "#000000",
            color: "white",
            padding: "10px 20px",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
          }}
          onClick={() => window.open(website_url, "_blank")}
        >
          Visit Website
        </button>
      </div>
    </div>
  );
}
