import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";

export const AboutContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 5vw;
  margin-left: 5vw;
  margin-bottom: 5vh;

  @media screen and (min-width: 35rem) {
    margin-left: 9vw;
    margin-right: 9vw;
    margin-bottom: 15vh;
  }
`;

export const AboutInfo = styled.div`
  font-weight: normal;

  @media screen and (min-width: 35rem) {
    text-align: center;
  }
`;

export const AboutTitle = styled.h2`
  text-align: center;
  margin-bottom: 4vh; //3.125rem
  font-size: 1.5rem;
  font-weight: bold;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;

  @media screen and (min-width: 35rem) {
    font-size: 2.6rem;
  }
`;

export const AboutSubtitle = styled.h3`
  text-align: center;
  color: var(--color-blue);
  font-size: 0.9rem;
  font-weight: bold;

  @media screen and (min-width: 35rem) {
    font-size: 1.5rem;
  }
`;

export const AboutGreytitle = styled.h3`
  text-align: center;
  color: var(--color-grey);
  font-size: 0.9rem;
  font-weight: bold;

  @media screen and (min-width: 35rem) {
    font-size: 1.5rem;
  }
`;



export const AboutDesc = styled.p`
  font-size: 1rem;


  @media screen and (min-width: 25rem) {
    font-size: 1.25rem;
    width: 60%;
    margin: auto;
  }
`;

export const AboutBtn = styled.div`
  margin-top: 7vh; //3.125rem
  text-align: center;
`;

export const AboutBtnLink = styled(LinkR)`
  background: var(--color-blue);
  color: var(--color-white);
  border-radius: 0.5rem;
  font-size: 1rem;
  padding: 0.7rem 0.7rem;

  &:hover {
    background: #2777a7;
  }

  @media screen and (min-width: 35rem) {
    padding: 1rem 1rem;
    font-size: 1.125rem;
  }
`;

export const GridContainer = styled.div`
  margin-top: 3.125rem;
  display: grid;
  column-gap: 5vw;
  row-gap: 3.125rem;
  grid-template-columns: repeat(2, 1fr);

  @media screen and (max-width: 35rem) {
    grid-template-columns: 100%;
  }
`;

export const GridItem = styled.div`
  text-align: center;
  margin-bottom: 3.125rem;
`;

export const GridSubtitle = styled.h4`
  font-size: 1.5rem;
  font-weight: 400;
`;

export const ItemDesc = styled.p`
  font-weight: normal;
  font-size: 1rem;
  margin: auto;

  @media screen and (min-width: 60rem) {
    width: 60%;
  }
`;
