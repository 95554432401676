import React, { Fragment, useRef } from 'react';
import './CommunityLinks.css';
import LinksCard from './Links';

const CommunityLinks = props => {
    let check = true;
    let olympicsLink = useRef();
    const classHandler = () => {
        check ? olympicsLink.current.className = 'olympusLinkCard OlympusDropShow' : olympicsLink.current.className = 'olympusLinkCard';
        check = !check;
    }
    return (
        <Fragment>
            <div className='OlympicsNavLinkInner' onClick={classHandler}>
                <a href='#'>Community</a>
                <span className='dropIconOlympus'></span>
            </div>
            <div className='olympusLinkCard' ref={olympicsLink}>
                <div className='CommunityLinks'>
                    <LinksCard title='Main Links'>
                        <a href='#'>Members</a>
                        <a href='#'>Members Activity</a>
                        <a href='#'>Groups</a>
                        <a href='#'>Group</a>
                        <a href='#'>Private Group</a>
                        <a href='#' className='lastOlympusLink'>Private Account</a>
                    </LinksCard>
                    <LinksCard title='Profile Pages'>
                        <a href='#'>User Profile</a>
                        <a href='#'>User Activity</a>
                        <a href='#'>About User</a>
                        <a href='#'>User's Friends</a>
                        <a href='#'>User's Group</a>
                        <a href='#' className='lastOlympusLink'>User's Newsfeed</a>
                    </LinksCard>
                    <LinksCard title='More Pages'>
                        <a href='#'>Media</a>
                        <a href='#'>Badges</a>
                        <a href='#'>Comments</a>
                        <a href='#'>Reviews</a>
                        <a href='#'>Favorites</a>
                        <a href='#' className='lastOlympusLink'>Mentions</a>
                    </LinksCard>
                    <LinksCard title='bbPress Forums'>
                        <a href='#'>Forums page</a>
                        <a href='#'>User's Forum</a>
                        <a href='#'>Topic</a>
                    </LinksCard>
                </div>
            </div>
        </Fragment>
    )
}

export default CommunityLinks;