import React, {Fragment, useState} from 'react';
import LoginIcon from '../assets/LoginIcon';
import CloseIcon from './../../newsfeed/icons/CloseIcon';
import MainLoginPage from './../LoginPage/LoginPage';
import './LoginPage.css';

const LoginPage = props => {
    let [loginPage, setLoginPage] = useState({
        show: false,
        className: 'olympusLoginPage'
    })
    const showPage = () => {
        setLoginPage({ show: true, className: 'olympusLoginPage' });
    }
    const hidePage = () => {
        setLoginPage({ show: true, className: 'olympusLoginPage olympusLoginPageHide' });
        setTimeout(() => {
            setLoginPage({ show: false, className: 'olympusLoginPage' });
        }, 100)
    }
    return(
        <Fragment>
            <span className='olympusnavToggle' onClick={showPage}><LoginIcon /></span>
            {loginPage.show && <div>
                <div className='olympusBlur' onClick={hidePage}></div>
                <div className={loginPage.className}>
                    <CloseIcon onClick={hidePage} />
                    <MainLoginPage />
                </div>
            </div>}
        </Fragment>
    )
}

export default LoginPage;