/* (2019-06-10) - This is the component that is used to sort the opportunities.
The function that handles it can be found in the /opportunities page.
*/
import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { withStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FilledInput from '@material-ui/core/FilledInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import Grid from '@material-ui/core/Grid';

const styles = theme => ({
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
});

class NativeSelects extends React.Component {
  state = {
    sortby: '',
    name: '',
    labelWidth: 0,
  };

  componentDidMount() {
    this.setState({
      labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth,
    });
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  render() {
    const {classes} = this.props;

    return (
      <Grid
        container
        justify="flex-end"
        alignItems="flex-start"
        className="d-sm-flex">
        <div className={ classes.root + ' UbaidSort' }>
          <FormControl
            variant="outlined"
            className={ classes.formControl + ' UbaidSortFormControl' }>
            <InputLabel
              ref={ ref => {
                      this.InputLabelRef = ref;
                    } }
              htmlFor="outlined-sortby-native-simple">
              Sort By:
            </InputLabel>
            <Select
              native
              value={ this.props.sortby }
              onChange={ this.props.handleChange }
              input={ <OutlinedInput
                        name="sortby"
                        labelWidth={ this.state.labelWidth }
                        id="outlined-sortby-native-simple" /> }>
              <option value="" />
              <option value={ "latest" }>
                Latest
              </option>
              <option value={ "alphabetically" }>
                A to Z
              </option>
              <option value={ "balphabetically" }>
                Z to A
              </option>
            </Select>
          </FormControl>
        </div>
      </Grid>
      );
  }
}

NativeSelects.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NativeSelects);
