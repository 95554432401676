import React, { useRef } from 'react';
import './CheckForm.css';

const CheckForm = props => {
    let check = true;
    let checkBox = useRef();
    const checkHandler = () => {
        check ? checkBox.current.style.opacity = 1 : checkBox.current.style.opacity = 0;
        check = !check;
    }
    return (
        <label className='inputCheckBox'>
            <input type='checkbox' name='checkbox' />
            <div className='viewCheckBox' onClick={checkHandler}>
                <i className='fa fa-check' ref={checkBox}></i>
            </div>
            <div onClick={checkHandler}>{props.label}</div>
        </label>
    )
}

export default CheckForm;