/* (2019-06-10) - This is the /explore page. It contains all of the companies.
It displays all of the companies by mapping through a list of companies that
are fetched. It passes each map via props to a component card. It utilizes the
explort sort filter and explore filters which can be found in the /components
directory.
*/
import React from "react";
import axios from "axios";
import "../main.css";
import "./Explore_Service_Product.css";
import {
  Button,
  Alert,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  Col,
  Row,
  Image,
  Navbar,
  Jumbotron,
  Nav,
  NavItem,
  Dropdown,
  MenuItem,
} from "react-bootstrap";
import { Helmet } from "react-helmet";
import StartupFuel from "../img/watermarksffinal.png";
import URL from "url-parse";
import default_pic from "../img/default_company_pic.png";
import default_background from "../img/bg.png";
import { AppContext } from "../App";
import Navigation from "./components/navbar";
import Footer from "./components/footer_side";
import {
  Sign_In,
  Register,
  Forgot_Password,
  Alert_Message_To_User,
} from "./components/popups";
import Admin_Manage_Companies_Card from "./components/manage_companies_card";
import {
  company_type_list,
  industry_list,
  stage_list,
  business_type,
} from "../boilerplate/list.json";
import SiteTitle from "./components/site_title.js";
import Filters from "./components/explore_filters";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import SortBy from "./components/explore_sort_filter";
import Loader from "./components/loader3";
import SideMenu from "./SideMenu";
import classnames from "classnames";
import ThreeDotsIcon from "../newsfeed/icons/ThreeDotsIcon";
import Social_Follow_Popup from "./components/popups/social_follow_popup";
import Social_Register_Popup from "./components/popups/social_register_popup";
import _uniqBy from "lodash/uniqBy";
import map from "../img/map.png";
//import { Map, GoogleApiWrapper, InfoWindow, Marker } from 'google-maps-react';
import { compose, withProps, withHandlers, withStateHandlers } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
} from "react-google-maps";
import { MarkerClusterer } from "react-google-maps/lib/components/addons/MarkerClusterer";
const { InfoBox } = require("react-google-maps/lib/components/addons/InfoBox");

const MapWithAMarkerClusterer = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyCyJKxnmJUAgE0jZrhcdKi9X9_e4dJXijc&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `100%` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withHandlers({
    onMarkerClustererClick: () => (markerClusterer) => {
      const clickedMarkers = markerClusterer.getMarkers();
    },
  }),
  withStateHandlers(
    () => ({
      isOpen: false,
      marker_id: "0",
    }),
    {
      onToggleOpen:
        ({ isOpen, id }) =>
        (marker_id) => ({
          isOpen: !isOpen,
          marker_id: marker_id,
        }),
    }
  ),
  withScriptjs,
  withGoogleMap
)((props) => (
  <GoogleMap
    defaultZoom={2}
    defaultCenter={{ lat: 18.915142, lng: -19.862345 }}
  >
    <MarkerClusterer
      onClick={props.onMarkerClustererClick}
      averageCenter
      enableRetinaIcons
      gridSize={60}
    >
      {props.markers.map((marker) => {
        if (marker.lat !== undefined) {
          return (
            <Marker
              key={marker._id}
              position={{ lat: marker.lat, lng: marker.long }}
              onClick={() => {
                props.onToggleOpen(marker._id);
              }}
            >
              {props.isOpen && marker._id === props.marker_id && (
                <InfoWindow
                  className="mapWindow"
                  onCloseClick={() => {
                    props.onToggleOpen(marker._id);
                  }}
                >
                  <div>
                    <a href={`/profile?company_id=${marker._id}`}>
                      <div className="mapCard">
                        <h3>{marker.company_name}</h3>
                      </div>
                    </a>
                    <span>{marker.company_type}</span>
                  </div>
                </InfoWindow>
              )}
            </Marker>
          );
        }
      })}
    </MarkerClusterer>
  </GoogleMap>
));

class Explore_Container extends React.Component {
  constructor(props) {
    super(props);
  }
  static contextType = AppContext;
  render() {
    return <Explore {...this.props} {...this.context} />;
  }
}

class Explore extends React.Component {
  constructor(props) {
    super(props);

    this.PAGE_LENGTH = 12;
    this.state = {
      company_list: [],
      loaded: false,
      fetchingNextPage: true,
      noMoreContent: false,
      mapped_companies: [],

      number_of_companies: 0,
      filters: {},
      query_search: "",
      sortby: "active",

      showLoader: true,
      showSignInPopup: false,
      showRegisterPopup: false,
      showMap: false,
    };
  }

  componentDidMount() {
    window.addEventListener(
      "scroll",
      this.handleInfiniteScroll.bind(this),
      true
    );
    this.setState(
      {
        fetchingNextPage: true,
      },
      this.fetchFeed
    );
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleInfiniteScroll);
  }

  handleInfiniteScroll() {
    if (
      window.innerHeight + window.scrollY >= document.body.offsetHeight - 100 &&
      !this.state.fetchingNextPage
    ) {
      this.setState(
        {
          fetchingNextPage: true,
        },
        function () {
          this.fetchFeed();
        }.bind(this)
      );
    }
  }

  resetFeed() {
    this.setState(
      {
        fetchingNextPage: true,
        noMoreContent: false,
      },
      this.fetchFeed.bind(this, true)
    );
  }

  fetchFeed(reset) {
    var serialize = function (obj) {
      var str = [];
      for (var p in obj)
        if (obj.hasOwnProperty(p)) {
          str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
      return str.join("&");
    };
    if (this.state.showMap === true) {
      this.handleMapPopulate();
    }

    axios
      .get(
        `/fetch_explore_directory?${serialize({
          size: this.PAGE_LENGTH,
          count: reset ? 0 : this.state.company_list.length,
          search: this.state.query_search,
          filters: JSON.stringify(this.state.filters),
          sort: this.state.sortby,
        })}`
      )
      .then((response) => {
        if (response.data.status === "success") {
          var updated_list = reset
            ? response.data.result
            : this.state.company_list.concat(response.data.result);
          updated_list = _uniqBy(updated_list, (co) => {
            return co._id;
          });

          this.setState({
            number_of_companies: response.data.total,
            noMoreContent: response.data.result.length < this.PAGE_LENGTH,
            company_list: updated_list,
            loaded: true,
            fetchingNextPage: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          loaded: true,
          fetchingNextPage: false,
        });
        console.log(error);
      });
  }

  handleMapPopulate = () => {
    this.setState({
      mapped_companies: [],
    });
    var serialize = function (obj) {
      var str = [];
      for (var p in obj)
        if (obj.hasOwnProperty(p)) {
          str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
      return str.join("&");
    };
    axios
      .get(
        `/fetch_map_explore?${serialize({
          size: this.PAGE_LENGTH,
          count: this.state.company_list.length,
          search: this.state.query_search,
          filters: JSON.stringify(this.state.filters),
          //sort: this.state.sortby
        })}`
      )
      .then((response) => {
        this.setState(
          {
            mapped_companies: response.data.result,
          },
          () => {
            this.setState({ showMap: true });
          }
        );
      });
  };

  dismissRegisterModal = (e) => {
    this.setState({
      showRegisterPopup: false,
    });
  };

  showRegisterModal = (e) => {
    this.setState({
      showRegisterPopup: true,
    });
  };

  handleChange = (e) => {
    const name = e.target.name;
    this.setState(
      {
        [name]: e.target.value,
      },
      () => {
        if (name === "sortby") {
          this.fetchFeed(true);
        }
      }
    );
  };

  handleLogOut = () => {
    axios
      .get(`/logout`)
      .then((response) => {
        if (response.data.status === "success") {
          this.setState(
            {
              isLoggedIn: false,
            },
            function () {
              this.props.history.push(`/sign_in`);
            }
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getAvatarUrl = (array, file_type) => {
    let new_arr = array.filter((file) => {
      return file.file_type === file_type;
    });
    if (file_type === "company avatar") {
      if (new_arr.length !== 0) {
        return new_arr[0].file_url_optimized_50_50;
      } else {
        return default_pic;
      }
    }
    if (file_type === "company background") {
      if (new_arr.length !== 0) {
        return new_arr[0].file_url_optimized_500_300;
      } else {
        return default_background;
      }
    }
  };

  handleSearchInputChange = (e) => {
    this.setState(
      {
        query_search: e.target.value,
      },
      () => {
        setTimeout(this.fetchFeed.bind(this, true), 1000);
      }
    );
  };

  getStage = (value, array) => {
    for (let i = 0; i < array.length; i++) {
      if (array[i].value === value) {
        return array[i].text;
      }
    }
  };

  handleFilterChange = (e) => {
    let deleteKey = (object, key) => {
      delete object[key];
      return {
        ...object,
      };
    };
    const name = e.target.name;
    this.setState(
      {
        filters:
          e.target.value.length > 0
            ? {
                ...this.state.filters,
                [name]: e.target.value,
              }
            : deleteKey(this.state.filters, name),
      },
      this.fetchFeed.bind(this, true)
    );
  };

  handleSingleDelete = (data, key) => () => {
    let deleteKey = (object, key) => {
      delete object[key];
      return {
        ...object,
      };
    };
    let filtersx = this.state.filters[key];
    let chipToDelete = filtersx.indexOf(data);
    filtersx.splice(chipToDelete, 1);
    this.setState(
      {
        filters:
          filtersx.length > 0
            ? {
                ...this.state.filters,
                [key]: filtersx,
              }
            : deleteKey(this.state.filters, key),
      },
      this.fetchFeed.bind(this, true)
    );
  };

  togglePopup = (e) => {
    this.setState({
      showSignInPopup: !this.state.showSignInPopup,
    });
  };

  resetFilters = () => {
    this.setState(
      {
        filters: {},
      },
      this.fetchFeed.bind(this, true)
    );
  };

  mapHandler = () => {
    if (this.state.showMap === false) {
      this.handleMapPopulate();
    } else {
      this.setState({
        showMap: false,
      });
    }
  };

  deleteCompany = (e, company_id) => {
    e.preventDefault();

    axios
      .post(`/delete_company_user_admin?company_id=${company_id}`, {
        password_entry: this.state.password_entry,
      })
      .then((response) => {
        if (response.data.status === "success") {
          window.location.reload();
        }
      });
  };

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <SiteTitle
          title="StartupFuel - Browse Companies"
          meta_description="Browse and connect with relevant companies in the startup ecosystem."
          meta_keywords="Startups, StartupFuel, Investors, Consultants, Accelerators, Events, startup ecosystem, startup social network"
        />
        <Navigation />
        <Sign_In />
        <Forgot_Password />
        <Alert_Message_To_User
          show={this.props.showPopupMessage}
          handleChange={this.props.handleChange}
          dismiss={this.props.dismissModal}
          alert_message={this.props.alert_message}
          {...this.state}
        />
        <Register />
        <SideMenu />
        <div className="ExpOppTooMainUbaid">
          <div className="row">
            <div className="UbaidRightFilter">
              {/* <div> */}
              <Filters
                query_search={this.state.query_search}
                handleFilterChange={this.handleFilterChange}
                filters={this.state.filters}
                handleSingleDelete={this.handleSingleDelete}
                resetFilters={this.resetFilters}
                getStage={this.getStage}
                results={[]}
                handleSearchInputChange={this.handleSearchInputChange}
              />
              <Footer />
              {/* </div> */}
            </div>
            <div class="UbaidLeftOppExp">
              <div className="row">
                <div className="col">
                  <div className="card">
                    <div className="row">
                      <div className="col-sm-7 mt-2">
                        {this.state.number_of_companies !== undefined && (
                          <h5
                            className="NumberOfPitchCompUBaid"
                            style={{ lineHeight: "56px" }}
                          >
                            {this.state.number_of_companies}{" "}
                            {this.state.number_of_companies === 1
                              ? "Company"
                              : "Companies"}{" "}
                            Listed
                          </h5>
                        )}
                        <FormControl
                          type="text"
                          value={this.state.password_entry}
                          placeholder="Password"
                          name="password_entry"
                          onChange={this.handleChange}
                          className="form-control mb-3"
                        />
                      </div>

                      <div
                        className="col-sm-5 hidden-sm-down"
                        style={{ paddingRight: 0 }}
                      >
                        <SortBy
                          sortby={this.state.sortby}
                          handleChange={this.handleChange}
                          mapHandler={this.mapHandler}
                          MapOpen={this.state.showMap}
                        />
                      </div>
                    </div>
                    <div
                      className={
                        "ExploreMap " + (this.state.showMap ? "showMap" : "")
                      }
                    >
                      {this.state.showMap &&
                      this.state.mapped_companies.length > 0 ? (
                        <MapWithAMarkerClusterer
                          markers={this.state.mapped_companies}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row ubaidOppCards">
                {this.state.company_list.map((company, index) => (
                  <div className="ubaidExploreCards col-xl-3 col-lg-4 col-md-6 col-sm-12">
                    <Admin_Manage_Companies_Card
                      key={index}
                      getStage={this.getStage}
                      getAvatarUrl={this.getAvatarUrl}
                      {...company}
                      index={index}
                      filters={this.state.filters}
                      showRegisterPopup={this.showRegisterModal}
                      isLoggedIn={this.props.isLoggedIn}
                      companies_owned={this.props.companies_owned}
                      deleteCompany={this.deleteCompany}
                    />
                  </div>
                ))}
              </div>
              {this.props.isLoggedIn === false && (
                <Social_Follow_Popup
                  dismissModal={this.dismissRegisterModal}
                  showNewsFeedPopup={this.state.showRegisterPopup}
                />
              )}
              {this.props.isLoggedIn === true &&
                this.props.companies_owned === null && (
                  <Social_Register_Popup
                    dismissModal={this.dismissRegisterModal}
                    showNewsFeedPopup={this.state.showRegisterPopup}
                  />
                )}
              {this.state.loaded && this.state.noMoreContent && (
                <h5 style={{ textAlign: "center" }}>
                  <br /> There is no more content to show at the moment <br />{" "}
                  <br />
                </h5>
              )}
              {!this.state.noMoreContent && (
                <div>
                  <div className={classnames("SocialLoadingIcon")}>
                    <ThreeDotsIcon />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Explore_Container;
