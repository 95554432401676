import React, { useEffect, useRef, useState } from "react";

import PdfHeader from "./pdfHeader";
import PdfFooter from "./pdfFooter";
import { TypeAnimation } from "react-type-animation";
import "../tearsheet.css";
import axios from "axios";
import html2pdf from "html2pdf.js";
import { ExpandMore, ExpandLess, Delete } from "@material-ui/icons";
import LoadingButton from "../../loadingButton";

const EditableText = ({
  value,
  onChange,
  triggerResize,
  showTypingAnimation,
  onAnimationComplete,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const textareaRef = useRef(null);

  const resizeTextarea = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  useEffect(() => {
    if (isEditing) {
      resizeTextarea();
    }
  }, [isEditing, value, triggerResize]);

  return (
    <>
      {isEditing ? (
        <textarea
          ref={textareaRef}
          style={{ width: "100%", height: "auto", overflow: "hidden" }}
          value={value}
          onChange={(e) => {
            onChange(e.target.value);
            resizeTextarea();
          }}
          onBlur={() => setIsEditing(false)}
          autoFocus
        />
      ) : (
        <div onClick={() => setIsEditing(true)}>
          {value && showTypingAnimation ? (
            <TypeAnimation
              sequence={[
                value,
                20000,
                () => {
                  onAnimationComplete();
                },
              ]}
              wrapper="span"
              cursor={true}
              speed={85}
              repeat={0}
              style={{ whiteSpace: "pre-line" }}
            />
          ) : (
            value || "Click to edit"
          )}
        </div>
      )}
    </>
  );
};

const TearsheetItem = ({
  tearsheet,
  index,
  company,
  downloadPdf,
  isDownloading,
  handleExpand,
  isExpanded,
  saveContent,
  isSaving,
  totalTearSheets,
  handleDelete,
  isDeleting,
  showDeleteConfirm,
  setShowDeleteConfirm,
}) => {
  const [content, setContent] = useState(tearsheet.tearsheet);
  const [triggerResize, setTriggerResize] = useState(false);
  const [showTypingAnimation, setShowTypingAnimation] = useState(false);
  const pdfRef = useRef(null);

  const handleAnimationComplete = () => {
    setShowTypingAnimation(false);
  };

  const handleHighlightChange = (index, newValue) => {
    const newHighlights = [...content.KeyHighlights];
    newHighlights[index] = newValue;
    setContent({ ...content, KeyHighlights: newHighlights });
  };

  const displayNumber = totalTearSheets - index;

  return (
    <div className="report-container" key={index}>
      <div className="overall-summary" onClick={() => handleExpand(index)}>
        <h2 className="overall-score">
          Tearsheet {displayNumber}{" "}
          {isExpanded ? <ExpandLess /> : <ExpandMore />}
        </h2>
        <div className="score-details-right">
          <p className="credits-used">Credits Used: {tearsheet.credits_used}</p>
          <p className="created-on">
            Date Created: {new Date(tearsheet.created_on).toLocaleDateString()}
          </p>
          <div className="score-button-container">
            <LoadingButton
              className="button button-secondary"
              loading={isDownloading}
              text={isDownloading ? "Downloading..." : "Download PDF"}
              onClick={(e) => {
                e.stopPropagation();
                downloadPdf(index, pdfRef, company.company_name);
              }}
            />
            <button
              className="button button-danger ml-2"
              onClick={(e) => {
                e.stopPropagation();
                setShowDeleteConfirm(tearsheet._id);
              }}
            >
              <Delete />
            </button>
          </div>
          {showDeleteConfirm === tearsheet._id && (
            <div style={{ textAlign: "left" }}>
              <p>Are you sure you want to delete this tearsheet?</p>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <LoadingButton
                  className="button button-danger mr-2"
                  onClick={() => handleDelete(tearsheet._id)}
                  loading={isDeleting}
                  text="Delete"
                />
                <button
                  className="button button-secondary"
                  onClick={() => setShowDeleteConfirm(null)}
                >
                  Cancel
                </button>
              </div>
            </div>
          )}
        </div>
      </div>

      {isExpanded && (
        <>
          <div className="header" style={{ marginTop: "20px" }}>
            <LoadingButton
              className="button button-secondary"
              loading={isSaving}
              text={isSaving ? "Saving..." : "Save"}
              onClick={() => saveContent(content)}
            />
          </div>
          <div ref={pdfRef} className="pdf-content">
            <PdfHeader
              company={company}
              created_on={tearsheet.created_on}
              docType="Startup Tear sheet"
            />
            <div style={{ padding: "20px" }}>
              <div className="business-overview">
                <h2>Business Overview</h2>
                <EditableText
                  value={content.Overview}
                  onChange={(value) =>
                    setContent({ ...content, Overview: value })
                  }
                  triggerResize={triggerResize}
                  showTypingAnimation={showTypingAnimation}
                  onAnimationComplete={handleAnimationComplete}
                />
                <span style={{ fontWeight: "bold" }}>Problem:</span>
                <EditableText
                  value={content.Problem}
                  onChange={(value) =>
                    setContent({ ...content, Problem: value })
                  }
                  triggerResize={triggerResize}
                  showTypingAnimation={showTypingAnimation}
                  onAnimationComplete={handleAnimationComplete}
                />
                <span style={{ fontWeight: "bold" }}>Solution:</span>
                <EditableText
                  value={content.Solution}
                  onChange={(value) =>
                    setContent({ ...content, Solution: value })
                  }
                  triggerResize={triggerResize}
                  showTypingAnimation={showTypingAnimation}
                  onAnimationComplete={handleAnimationComplete}
                />
                <span style={{ fontWeight: "bold" }}>Market:</span>
                <EditableText
                  value={content.Market}
                  onChange={(value) =>
                    setContent({ ...content, Market: value })
                  }
                  triggerResize={triggerResize}
                  showTypingAnimation={showTypingAnimation}
                  onAnimationComplete={handleAnimationComplete}
                />
                <span style={{ fontWeight: "bold" }}>Traction:</span>
                <EditableText
                  value={content.Traction}
                  onChange={(value) =>
                    setContent({ ...content, Traction: value })
                  }
                  triggerResize={triggerResize}
                  showTypingAnimation={showTypingAnimation}
                  onAnimationComplete={handleAnimationComplete}
                />
                <span style={{ fontWeight: "bold" }}>Advantage:</span>
                <EditableText
                  value={content.Advantage}
                  onChange={(value) =>
                    setContent({ ...content, Advantage: value })
                  }
                  triggerResize={triggerResize}
                  showTypingAnimation={showTypingAnimation}
                  onAnimationComplete={handleAnimationComplete}
                />
              </div>
              <div className="key-highlights">
                <h2>Key Highlights</h2>
                <ul>
                  {content.KeyHighlights.map((highlight, index) => (
                    <li key={index}>
                      <EditableText
                        value={highlight}
                        onChange={(value) =>
                          handleHighlightChange(index, value)
                        }
                        triggerResize={triggerResize}
                        showTypingAnimation={showTypingAnimation}
                        onAnimationComplete={handleAnimationComplete}
                      />
                    </li>
                  ))}
                </ul>
              </div>
              <PdfFooter />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default function Tearsheet(props) {
  const [tearSheets, setTearSheets] = useState([]);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [expandedSheet, setExpandedSheet] = useState(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(null);
  const [generationStep, setGenerationStep] = useState("");
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState("");

  useEffect(() => {
    if (props.company && props.company.aiGenerations) {
      const sortedTearSheets = [
        ...(props.company.aiGenerations.tearsheets || []),
      ].sort((a, b) => new Date(b.created_on) - new Date(a.created_on));
      setTearSheets(sortedTearSheets);
    }
  }, [props.company]);

  const simulateProgress = async () => {
    // Extracting phase (3 seconds)
    setGenerationStep("Extracting");
    for (let i = 0; i <= 30; i++) {
      setProgress(i);
      await new Promise((r) => setTimeout(r, 100));
    }

    // Analyzing phase (5 seconds)
    setGenerationStep("Analyzing");
    for (let i = 31; i <= 80; i++) {
      setProgress(i);
      await new Promise((r) => setTimeout(r, 62.5));
    }

    // Generating phase (remaining time)
    setGenerationStep("Generating");
  };

  const handleDelete = async (id) => {
    setIsDeleting(true);
    try {
      const response = await fetch(`/delete_ai_generation/${id}`, {
        method: "DELETE",
      });

      if (!response.ok) {
        throw new Error("Failed to delete tearsheet");
      }

      setTearSheets((sheets) => sheets.filter((sheet) => sheet._id !== id));

      const updatedTearsheets = props.company.aiGenerations.tearsheets.filter(
        (sheet) => sheet._id !== id,
      );

      const updates = {
        aiGenerations: {
          ...props.company.aiGenerations,
          tearsheets: updatedTearsheets,
        },
      };

      props.updateCompanyData(props.company.applicant_company, updates);
    } catch (error) {
      console.error("Error deleting tearsheet:", error);
      setError("Failed to delete tearsheet. Please try again.");
    }
    setShowDeleteConfirm(null);
    setIsDeleting(false);
  };

  const downloadPdf = (index, pdfRef, companyName) => {
    if (!pdfRef.current) {
      handleExpand(index);
      setTimeout(() => {
        initiateDownload(pdfRef, companyName);
      }, 300);
    } else {
      initiateDownload(pdfRef, companyName);
    }
  };

  const initiateDownload = (pdfRef, companyName) => {
    setIsDownloading(true);
    const element = pdfRef.current;
    const heightPx = element.scrollHeight;
    const heightCM = heightPx / 35.35;

    const options = {
      filename: companyName + " Tearsheet | StartupFuel.pdf",
      html2canvas: { dpi: 192, letterRendering: true, scale: 2 },
      jsPDF: {
        orientation: "portrait",
        unit: "cm",
        format: [heightCM, 29.7],
      },
    };

    html2pdf()
      .from(element)
      .set(options)
      .save()
      .then(() => setIsDownloading(false))
      .catch((error) => {
        console.error("Error generating PDF:", error);
        setIsDownloading(false);
      });
  };

  const saveContent = async (content, index) => {
    setIsSaving(true);
    try {
      await axios.post(`/save_tearsheet`, { content: content });
      const updatedTearSheets = [...tearSheets];
      updatedTearSheets[index] = {
        ...updatedTearSheets[index],
        tearsheet: content,
      };
      setTearSheets(updatedTearSheets);
      setIsSaving(false);
    } catch (error) {
      console.error("Error saving content:", error);
      setIsSaving(false);
    }
  };

  const generateTearsheet = async () => {
    const companyId = props.company.applicant_company;
    const opportunityId = props.opportunity_data._id;

    if (props.opportunity_data.credits < 1) {
      setError("Not enough credits to generate a tear sheet.");
      return;
    }

    setIsGenerating(true);
    setProgress(0);
    setError("");
    simulateProgress();

    try {
      const requestBody = {
        applicationObject: props.applicationInfo,
      };

      const response = await axios.post(
        `/fetch_tearsheet?company_id=${companyId}&opportunity_id=${opportunityId}`,
        requestBody,
      );

      const newTearSheet = {
        tearsheet: response.data.analysis.tearsheet,
        created_on: response.data.analysis.created_on,
        credits_used: response.data.analysis.credits_used,
        _id: response.data.analysis._id,
      };

      setTearSheets((prevTearSheets) => [newTearSheet, ...prevTearSheets]);

      const updates = {
        aiGenerations: {
          ...props.company.aiGenerations,
          tearsheets: [
            newTearSheet,
            ...(props.company.aiGenerations?.tearsheets || []),
          ],
        },
      };

      props.updateCompanyData(companyId, updates);
      props.handleCreditChange(-response.data.creditsUsed);
      setExpandedSheet(0);
      setProgress(100);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setError(
          "Tearsheet generation failed. No pitch deck found. Please upload a pitch deck to generate a tear sheet.",
        );
      } else if (!error.response) {
        setError(
          "Due to high network load, we were unable to generate the tearsheet. Please try again later.",
        );
      } else {
        console.error("Error generating tear sheet:", error);
        setError(
          "Due to high network load, we were unable to generate the tearsheet. Please try again later.",
        );
      }
    } finally {
      setIsGenerating(false);
      setGenerationStep("");
      setProgress(0);
    }
  };

  const handleExpand = (index) => {
    setExpandedSheet(expandedSheet === index ? null : index);
  };

  return (
    <div className="px-3">
      <h4>Tear sheet</h4>
      <p className="text-grey text-sm">
        Generate a Startup Tearsheet using the patent pending StartupFuel
        DiligenceGPT Model.
      </p>
      <LoadingButton
        className="summarize-button"
        onClick={generateTearsheet}
        loading={isGenerating}
        text={isGenerating ? "Generating..." : "Generate Tearsheet"}
      />
      {error && <p className="text-danger mt-2">{error}</p>}
      {isGenerating && (
        <div style={{ marginTop: "20px", marginBottom: "20px" }}>
          <div style={{ marginBottom: "10px", fontWeight: "bold" }}>
            {generationStep}...
          </div>
          <div
            style={{
              width: "100%",
              height: "20px",
              backgroundColor: "#f0f0f0",
              borderRadius: "10px",
              overflow: "hidden",
            }}
          >
            <div
              style={{
                width: `${progress}%`,
                height: "100%",
                background: "linear-gradient(90deg, #6902b9 0%, #1c71a1 100%)",
                transition: "width 0.3s ease-in-out",
              }}
            ></div>
          </div>
        </div>
      )}
      {tearSheets.map((tearsheet, index) => (
        <TearsheetItem
          key={tearsheet.created_on}
          tearsheet={tearsheet}
          index={index}
          company={props.company}
          downloadPdf={downloadPdf}
          isDownloading={isDownloading}
          handleExpand={handleExpand}
          isExpanded={expandedSheet === index}
          saveContent={(content) => saveContent(content, index)}
          isSaving={isSaving}
          totalTearSheets={tearSheets.length}
          handleDelete={handleDelete}
          isDeleting={isDeleting}
          showDeleteConfirm={showDeleteConfirm}
          setShowDeleteConfirm={setShowDeleteConfirm}
        />
      ))}
    </div>
  );
}
