import React, { useState } from "react";
import "./SideMenuUbaid.scss";
import { NavLink } from "react-router-dom";
import favicon from "../img/favicon.png";
import watermarks from "../img/watermarksffinal.png";
import { AppContext } from "../App";

class Menu_Container extends React.Component {
  constructor(props) {
    super(props);
  }
  static contextType = AppContext;
  render() {
    return <SideMenu {...this.props} {...this.context} />;
  }
}

class SideMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
  }
  handleToggle = () => {
    this.setState({
      show: !this.state.show,
    });
  };
  render() {
    const props = this.props;
    return (
      <span className="__sidebar__wrapper">
        <button
          onClick={this.handleToggle}
          onBlur={this.handleToggle}
          class="navbar-toggler mobile-only"
          type="button"
          style={{ padding: "0px" }}
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div className="LeftSideMenuContianer" style={{ height: "0px" }}>
          <div className={`leftNavExpOppToo ${this.state.show ? "open" : ""}`}>
            <NavLink to="/" className="ubaid-logo-container">
              <img src={favicon} alt="" />
              <img src={watermarks} alt="" className="secLogo" />
            </NavLink>
            <div className="hide-on-large">
              {props.isLoggedIn ? (
                <>
                  <a onClick={props.handleLogOut}>
                    <i className="fa">
                      <span className="fas fa-sign-out-alt"></span>
                    </i>
                    Sign Out
                  </a>
                  <a href={`/inbox`}>
                    <i className="fa">
                      <span className="fas fa-comments"></span>
                    </i>
                    Inbox
                  </a>
                  <a href="/user_profile">
                    <i className="fa">
                      <span className="fas fa-user"></span>
                    </i>
                    {this.props.user.first_name} {this.props.user.last_name}
                  </a>
                  {this.props.companies_owned !== undefined &&
                  this.props.companies_owned !== null &&
                  this.props.companies_owned[1] !== undefined &&
                  this.props.companies_owned[1][0] !== undefined ? (
                    <a
                      href={`/profile?company_id=${this.props.companies_owned[1][0]._id}`}
                    >
                      <i className="fa">
                        <span className="fas fa-address-card"></span>
                      </i>
                      {this.props.companies_owned[1][0].company_name}
                    </a>
                  ) : (
                    <a href={`/home_page`}>
                      <i className="fa">
                        <span className="fas fa-address-card"></span>
                      </i>
                      Register a company
                    </a>
                  )}
                </>
              ) : (
                <NavLink to="/sign_in">
                  <i className="fa">
                    <span className="fas fa-sign-in-alt"></span>
                  </i>
                  Sign in
                </NavLink>
              )}
            </div>
            <NavLink to="/newsfeed">
              <i className="fa fa-home"></i>Home
            </NavLink>
            {/* <NavLink to='/opportunities'><i className="fa fa-calendar-alt"></i>Competitions</NavLink>
            <NavLink to='/accelerators'><i className='fa'><span className="fas fa-chart-line"></span></i>Programs</NavLink>
            <NavLink to='/funding'><i className='fa'><span className="fas fa-dollar-sign"></span></i>Funding</NavLink>
            <NavLink to='/explore'><i className='fa fa-rocket'></i>Browse Companies</NavLink> */}
            {/*<NavLink to='/toolbox'><i className='fa'><span className="fas fa-toolbox"></span></i>Startup Toolbox</NavLink>*/}

            {/*For Startups*/}
            {props.companies_owned !== undefined &&
              props.companies_owned !== null &&
              props.companies_owned[1] !== undefined &&
              props.companies_owned[1][0] !== undefined &&
              (props.companies_owned[1][0].company_type[0] === "Startup" ||
                props.companies_owned[1][0].company_type[0] ===
                  "Tech Startup") && (
                <>
                  <hr />
                  <a
                    href={`/profile?company_id=${props.companies_owned[1][0]._id}&tab=4`}
                  >
                    <i className="fa fa-calendar-alt"></i>Applications
                  </a>
                </>
              )}

            {/*For Not Startups*/}
            {props.companies_owned !== undefined &&
              props.companies_owned !== null &&
              props.companies_owned[1] !== undefined &&
              props.companies_owned[1][0] !== undefined &&
              (props.companies_owned[1][0].company_type[0] === "Accelerator" ||
                props.companies_owned[1][0].company_type[0] === "Incubator" ||
                props.companies_owned[1][0].company_type[0] === "Events" ||
                props.companies_owned[1][0].company_type[0] === "Educator") && (
                <>
                  <hr />
                  <a
                    href={`/community_newsfeed?community_id=${props.companies_owned[1][0]._id}`}
                  >
                    <i className="fa">
                      <span className="fas fa-users"></span>
                    </i>
                    My Community
                  </a>
                </>
              )}
            {props.companies_owned !== undefined &&
              props.companies_owned !== null &&
              props.companies_owned[1] !== undefined &&
              props.companies_owned[1][0] !== undefined &&
              props.communities_as_member.length > 0 &&
              props.companies_owned[1][0].company_type[0] !== "Accelerator" &&
              props.companies_owned[1][0].company_type[0] !== "Incubator" && (
                <>
                  <hr />
                  {props.communities_as_member.map((community) => (
                    <a
                      href={`/community_members?community_id=${community._id}`}
                      style={{ display: "flex" }}
                    >
                      <i className="fa">
                        <span className="fas fa-users"></span>
                      </i>
                      <span style={{ marginTop: "10px" }}>
                        {community.company_name}'s Community
                      </span>
                    </a>
                  ))}
                </>
              )}
            {props.companies_owned !== undefined &&
              props.companies_owned !== null &&
              props.companies_owned[1] !== undefined &&
              props.companies_owned[1][0] !== undefined &&
              props.companies_owned[1][0].company_type[0] !== "Startup" &&
              props.companies_owned[1][0].company_type[0] !==
                "Tech Startup" && (
                <>
                  <hr />
                  <NavLink to="/create_opportunity">
                    <i className="fa">
                      <span className="fa-solid fa-plus"></span>
                    </i>
                    New PitchBox
                  </NavLink>
                  <a
                    href={`/profile?company_id=${props.companies_owned[1][0]._id}&tab=2`}
                  >
                    <i className="fa">
                      <span className="fas fa-cubes"></span>
                    </i>
                    All PitchBoxes
                  </a>
                  {props.companies_owned[1]["opportunity"].map((opp) => (
                    <a
                      href={`/applicant_room_dash?opportunity_id=${opp._id}&tab=2`}
                    >
                      <i className="fa fa-cube"></i>
                      {opp.opportunity_name}
                    </a>
                  ))}
                </>
              )}
          </div>
        </div>
      </span>
    );
  }
}

export default Menu_Container;
