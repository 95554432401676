import React from 'react';
import mainLogo from "../assets/PitchBox.png";
import arrow from '../assets/17tmw1w-arrow.gif';
import "./Header.css";
import backroundImage from '../assets/e97d84cc-maia-rocket_0q30f6000000000000001.png'

const Header = props => (
    <div className='PitchBoxHeader'>

        <img src={backroundImage} className='PitchBoxHeaderBackground' />
        <div className='PitchBoxHeaderBackgroundMain'>
            <img src={mainLogo} style={{ width: "200px" }} alt='' className='startupLogo' />
            <h4 style={{ fontSize: "24px" }}>A Simple Tool for Pitch Competition Organizers to Intake, Market & Review Applications all in one Place!</h4>
            <a href="mailto: team@startupfuel.com"><button>Request a Demo</button></a>
            <img src={arrow} className='animatedArrow hideOnSmall' />
            <h5>Be efficient, collaborate effectively & share your opportunity with a relevant audience so you focus on what you do best!</h5>
        </div>
    </div>
)

export default Header;
