import React from "react";
import { AppContext } from "../App";
import PropTypes from "prop-types";
import axios from "axios";
import URL from "url-parse";
import "./application-room-index.scss";

import SiteTitle from "./components/site_title.js";
import {
  Sign_In,
  Register,
  Forgot_Password,
  Alert_Message_To_User,
} from "./components/popups";
import Page from "./application_room/components/submissions/page";
import SideBar from "./application_room/components/Sidebar";
import TopNav from "./application_room/components/topNav";
import Navigation from "./components/navbar_pb";

class Applicant_Room_Container extends React.Component {
  static contextType = AppContext;
  render() {
    return <Applicant_Room {...this.props} {...this.context} />;
  }
}

class Applicant_Room extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: new URL(window.location.href, true),
      judges: "",
      opportunity_owner: "",
      show_page: true,
      opportunity_data: {},
      UbaidSubClass: "",
      isMobile: false,
    };
  }

  componentDidMount() {
    this.fetchOpportunityData();
    this.fetchJudgesData();
    this.checkMobile();
    window.addEventListener("resize", this.checkMobile);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.checkMobile);
  }

  checkMobile = () => {
    this.setState({ isMobile: window.innerWidth <= 768 });
  };

  fetchOpportunityData = () => {
    axios
      .get(
        `/fetch_opportunity?opportunity_id=${this.state.url.query.opportunity_id}`,
      )
      .then((response) => {
        if (response.status === "error") {
          console.log("Error fetching opportunity data:", response.message);
        } else {
          this.setState({ opportunity_data: response.data.result });
        }
      })
      .catch((error) =>
        console.error("Error fetching opportunity data:", error),
      );
  };

  fetchJudgesData = () => {
    axios
      .get(
        `fetch_judges_for_opportunity?opportunity_id=${this.state.url.query.opportunity_id}`,
      )
      .then((response) => {
        this.setState(
          {
            judges: response.data.judges,
            opportunity_owner: response.data.owner,
            access_ids: response.data.access_ids,
          },
          this.checkAccess,
        );
      })
      .catch((error) => console.error("Error fetching judges data:", error));
  };

  checkAccess = () => {
    setTimeout(() => {
      const { access_ids } = this.state;
      const { user } = this.props;
      if (
        !access_ids.includes(user._id) &&
        user.email !== "trevor@pangeafirm.com" &&
        user.email !== "ash@startupfuel.com"
      ) {
        // Uncomment the following line if you want to redirect unauthorized users
        // this.props.history.push("/explore");
      }
    }, 1000);
  };

  redirectToDesktopSite = () => {
    window.location.href = "https://startupfuel.com";
  };

  modifyCredit = (amount) => {
    this.setState((prevState) => ({
      opportunity_data: {
        ...prevState.opportunity_data,
        credits: prevState.opportunity_data.credits + amount,
      },
    }));
  };
  render() {
    const { isMobile } = this.state;

    if (isMobile) {
      return (
        <div className="mobile-popup">
          <div className="mobile-popup-content">
            <p>
              This application is best viewed on desktop. Please switch to a
              desktop computer to start using DiligenceGPT.
            </p>
            <button onClick={this.redirectToDesktopSite}>Close</button>
          </div>
        </div>
      );
    }

    return (
      <div className="__application-room-main">
        <Navigation opportunity_data={this.state.opportunity_data} />
        <SiteTitle />
        <Sign_In />
        <Register />
        <Forgot_Password />
        <Alert_Message_To_User
          show={this.props.showPopupMessage}
          handleChange={this.props.handleChange}
          dismiss={this.props.dismissModal}
          alert_message={this.props.alert_message}
          {...this.state}
        />
        <SideBar user={this.props.user} {...this.state} />
        <Page
          user={this.props.user}
          companies_owned={this.props.companies_owned}
          opportunity_name={this.state.opportunity_data.opportunity_name}
          modifyCredit={this.modifyCredit}
          {...this.state}
        />
      </div>
    );
  }
}

Applicant_Room_Container.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default Applicant_Room_Container;
