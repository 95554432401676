/* (2019-06-10) - This page is an intermediate page, that handles a response
then redirects to the "/reset_password" page.
*/
import React from 'react';
import axios from 'axios'
import '../main.css';
import { AppContext } from '../App'
import { Button, Alert, FormControl, FormLabel, Card } from 'react-bootstrap';
import { Helmet } from "react-helmet";
import URL from 'url-parse';
import Navigation from './components/navbar'
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import InputBase from '@material-ui/core/InputBase';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import SideMenu from './SideMenu';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function getStyles(name, that) {
  return {
    fontWeight: that.state.name.indexOf(name) === -1
      ? that.props.theme.typography.fontWeightRegular
      : that.props.theme.typography.fontWeightMedium,
  };
}
const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing.unit / 4,
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 100,
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 100,
  },
});

class Reset_Password_Container extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <Reset_Password
        {...this.props}
        {...this.context} />
    )
  }
}
Reset_Password_Container.contextType = AppContext;

class Reset_Password extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: new URL(window.location.href, true),
      errors: {},
      error_msg: "",
      email_msg: "",
      new_password: "",
      new_password_again: "",
      token_expired:false,
      showPassword: false,
      showPasswordConfirm: false
    }
  }
  componentDidMount() {

    axios.get(`/reset/${this.state.url.query.token}`).then((response) => {
      if (response.data.status === "success") {
        this.props.history.push(`/reset_password?token=${this.state.url.query.token}`)
      } else if (response.data.status !== "success" && this.state.url.query.token!==undefined) {
        this.setState({
          token_expired:true
        })
      }
      else {
        this.props.history.push(`/`)
      }
    })
  }
  handleChange = (e) => {
    const name = e.target.name;
    this.setState({
      [name]: e.target.value
    })
  }
  submitPasswordChange = (e) => {
    e.preventDefault()
    axios.post(`/submit_new_pass_after_reset?token=${this.state.url.query.token}`, {
      new_password: this.state.new_password,
      new_password_again: this.state.new_password_again
    })
      .then((response) => {
        this.setState({
          error_msg: response.data.message
        })
        if (response.data.status === 'success') {
          this.props.history.push('/explore')
        }

      })
  }

  togglePasswordVisibility = () => {
    this.setState(prevState => ({
      showPassword: !prevState.showPassword
    }));
  }

  togglePasswordConfirmVisibility = () => {
    this.setState(prevState => ({
      showPasswordConfirm: !prevState.showPasswordConfirm
    }));
  }

  render() {
    const {classes} = this.props;
    return (
      <React.Fragment>
        <Helmet>
          <title>
            Fastest Growing Innovation Network in the World - StartupFuel
          </title>
        </Helmet>
        <Navigation />
        <SideMenu />
        {!this.state.token_expired?
        <div class="container">
          <div class="main">
            <div class="panel-heading">
              <div class="panel-title text-center">
                <h1 class="title">Reset Your Password</h1>
                <hr className="mb-5" />
              </div>
            </div>
            <div>
              <Card.Title>
                Update your password
              </Card.Title>
              <p style={ { marginBottom: '0' } }>
                Enter your new password
              </p>
              <div style={{ position: "relative", width: "90%" }}>
                <TextField
                  type={this.state.showPassword ? "text" : "password"}
                  id="outlined-multiline-static"
                  style={{ width: "100%" }}
                  value={this.state.new_password}
                  name="new_password"
                  className={classes.textField}
                  onChange={this.handleChange}
                  margin="normal"
                  variant="outlined"
                />
                <span
                  onClick={this.togglePasswordVisibility}
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    cursor: "pointer"
                  }}
                >
                  {this.state.showPassword ? <FaEyeSlash /> : <FaEye />}
                </span>
              </div>
              <p style={ { marginBottom: '0' } }>
                Enter your new password again
              </p>
              <div style={{ position: "relative", width: "90%" }}>
                <TextField
                  type={this.state.showPasswordConfirm ? "text" : "password"}
                  id="outlined-multiline-static"
                  style={{ width: "100%" }}
                  value={this.state.new_password_again}
                  name="new_password_again"
                  className={classes.textField}
                  onChange={this.handleChange}
                  margin="normal"
                  variant="outlined"
                />
                <span
                  onClick={this.togglePasswordConfirmVisibility}
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    cursor: "pointer"
                  }}
                >
                  {this.state.showPasswordConfirm ? <FaEyeSlash /> : <FaEye />}
                </span>
              </div>
              <Button
                variant="contained"
                onClick={ this.submitPasswordChange }
                style={ { backgroundColor: '#007bff', textAlign: 'center' } }
                className={ classes.button }>
                Save
              </Button>
              { this.state.error_msg.length > 0 &&
                <div>
                  <p>
                    { this.state.error_msg }
                  </p>
                  { this.state.error_msg === 'Your password has been changed! You will receive a confirmation email shortly.' &&
                    <p>
                      Go back to the <a href='/explore'>homepage</a>
                    </p> }
                </div> }
            </div>
          </div>
        </div>
        :
        <h1 style={{textAlign:"center"}}> You're reset password Token has expired, please try again. </h1>
      }
      </React.Fragment>
    )
  }
}
Reset_Password_Container.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(Reset_Password_Container);
