import React from "react";
import {
  Hero,
  Section,
  HeroContent,
  HeroTitle,
  HeroDesc,
  HeroBtn,
  HeroBtnLink,
  HeroBtnLinkWhite,
  TopWrap,
  BottomWrap,
  Img,
  EmailWrap,
  EmailInput,
  Overlay,
} from "./HeroElements";
import img from "../../../img/computer-screen.png";

const HeroSection = () => {
  return (
    <Hero className="pitchbox_hero">
      <Overlay className="_made-overlay" />
      <Section>
        <TopWrap>
          <Img src={img} />
        </TopWrap>
        <HeroContent>
          <HeroTitle>Startup Due Diligence Enhanced </HeroTitle>
          <HeroDesc>
            <li>
              The #1 diligence software for startup investors, accelerators,
              lenders & corporates{" "}
            </li>
            <li>
              Reduce time, cost, & missed opportunities in the due diligence
              process
            </li>
            <li>
              The smartest & fastest way to collect & review startup pitches
            </li>
          </HeroDesc>
          <HeroBtnLink to="//www.startupfuel.com/" target="_blank">
            Let's Get Started
          </HeroBtnLink>
          <HeroBtnLinkWhite
            to="//startupfuel.zohobookings.com/#/customer/pitchbox"
            target="_blank"
          >
            Book A Demo
          </HeroBtnLinkWhite>
        </HeroContent>
        <BottomWrap>
          <Img src={img} />
        </BottomWrap>
      </Section>
    </Hero>
  );
};

export default HeroSection;
