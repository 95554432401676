import React from 'react';

const GridIcon = props => (
    <svg>
        <path fill="#9A9FBF" d="M14.997,17.999V12h2.001v5.999H14.997z M10.997,17.999V12h2.001v5.999H10.997z M7,17.991v-6h2v6H7z M8,4.999
        v1.999H6V4.999H8z M11,4.999v1.999H9V4.999H11z M14,4.999v1.999h-2V4.999H14z M19,9.998H5v-2h14V9.998z M17,21.997H5H3v-3v-3.001
        V3.999c0-1.105,0.896-2,2-2h14c1.104,0,2,0.895,2,2v6h-2v-6H5v11.998v4.001h10L19,20v-4.003h2v2.001V22L17,21.997z M19,11.998h2v2
        h-2V11.998z"></path>
    </svg>
)

export default GridIcon;