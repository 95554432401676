/* (2019-06-11) - The card used in the SIR/application room in the top
applicants page. Displays the amalgamated score of all the ratings at the top.
*/
import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import default_background from "../../img/bg.png";
import classnames from "classnames";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import CardActionArea from "@material-ui/core/CardActionArea";
import Typography from "@material-ui/core/Typography";
import red from "@material-ui/core/colors/red";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ShareIcon from "@material-ui/icons/Share";
import WebsiteIcon from "@material-ui/icons/Link";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Chip from "@material-ui/core/Chip";
import FaceIcon from "@material-ui/icons/Face";
import DoneIcon from "@material-ui/icons/Done";
import FolderIcon from "@material-ui/icons/Folder";
import Button from "@material-ui/core/Button";
import Moment from "moment";
import { Explore_Share_Popup } from "./popups";
import { SocialIcon } from "react-social-icons";
import AttachIcon from "@material-ui/icons/AttachFile";
import Rating from './../application_room/components/ReviewsCard/Rating';
import StarRatings from 'react-star-ratings';
import {
  country_list,
  opportunity_type_list,
  industry_list,
  stage_list,
  business_type,
} from "../../boilerplate/list.json";
import "./top-applications-profile-card.scss";
import _compact from "lodash/compact";
import _flatten from "lodash/flatten";
import _includes from "lodash/includes";
import _forEach from "lodash/forEach";
import _intersection from "lodash/intersection";
import _difference from "lodash/difference";
import Applicant_Info_Popup from "./popups/applicant_info_popup";
import "./application_room_card_top.scss";

const styles = (theme) => ({
  button: {
    margin: theme.spacing.unit,
  },
  input: {
    display: "none",
  },
  root: {
    marginLeft: theme.spacing.unit * 1.3,
    marginTop: "16px",
  },
  header: {
    overflow: "hidden",
  },
  chip: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  CardHeight: {
    overflow: "hidden",
    padding: "16px 5px",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  actions: {
    display: "flex",
  },
  expand: {
    transform: "rotate(0deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
    marginLeft: "0",
    [theme.breakpoints.up("sm")]: {
      marginRight: -8,
    },
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  type: {
    fontSize: "1em",
    color: "red",
    textAlign: "center",
    fontWeight: 0,
  },
  footer: {
    display: "flex",
  },
  title: {
    fontSize: "1rem",
    overflow: "hidden",
    height: 25,
  },
  grow: {
    flexGrow: 1,
  },
});

function handleClick() {
  alert("You clicked the Chip.");
}

class RecipeReviewCard extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    expanded: false,
    showSharePopup: false,
    show_info_popup: false,
  };

  

  show_info_popup = () => {
    this.setState({
      show_info_popup: !this.state.show_info_popup,
    });
  };
  handleExpandClick = () => {
    this.setState((state) => ({ expanded: !state.expanded }));
  };
  handleSharePopup = (e) => {
    this.setState({
      showSharePopup: !this.state.showSharePopup,
    });
  };
  render() {
    var allTags = _compact(
      _flatten([
        this.props.business_model,
        this.props.getStage(this.props.stage, stage_list),
        this.props.country,
        _compact(this.props.industry).map((val) =>
          val == "Artificial Intelligence" ? "A.I" : val
        ),
      ])
    );
    var getTagColorClass = function (tag) {
      if (this.props.business_model === tag) {
        return "blueHl";
      } else if (this.props.getStage(this.props.stage, stage_list) === tag) {
        return "redHl";
      } else if (this.props.country === tag) {
        return "";
      } else {
        return "greenHl";
      }
    }.bind(this);
    var filterTags = [];
    _forEach(
      this.props.filters,
      function (value, key, obj) {
        if (key === "stage") {
          _forEach(
            value,
            function (val) {
              filterTags.push(this.props.getStage(val, stage_list));
            }.bind(this)
          );
        } else if (key === "industry") {
          _forEach(
            value,
            function (val) {
              filterTags.push(val == "Artificial Intelligence" ? "A.I" : val);
            }.bind(this)
          );
        } else if (key === "country") {
          filterTags = filterTags.concat(value);
        }
      }.bind(this)
    );
    var showFilterTags = _intersection(filterTags, allTags);
    var showRemainderTags = _difference(
      allTags,
      _intersection(filterTags, allTags)
    );
    const { classes } = this.props;

    return (
      <Card
        className={`${classes.Card} ${classes.grow} ${classes.root} SubmissionProfileCard`}
      >
        <Explore_Share_Popup
          {...this.state}
          {...this.props}
          handleChange={this.handleChange}
          handleSharePopup={this.handleSharePopup}
        />
        <div className="UBaidSubCardHeader">
          <img
            className="UbaidSubCardAvtar"
            src={this.props.getAvatarUrl(
              this.props.company_files,
              "company avatar"
            )}
          />
          <div className="UbaidSubCardCompanyName">
            <div>{this.props.company_name}</div>
            <div className="UBaidSubCardSubtitle">
              {this.props.city} {this.props.country}
            </div>
          </div>
          <div>
            <div className="UbaidCardActions">
              <span
                onClick={
                  this.props.overallRatingArr === undefined
                    ? this.handleSharePopup
                    : null
                }
                aria-label="Share"
              >
                {this.props.overallRatingArr === undefined ? (
                  <i className="material-icons">share</i>
                ) : (
                  <span>
                    {this.props.overallRatingArr.overallRatingArr.toFixed(1)}{" "}
                  </span>
                )}
              </span>
            </div>
          </div>
        </div>
        <CardContent className="UbaidSubCardActionArea">

        <Grid container direction="row" style={{ marginTop: "10px" }}>
            
                <Grid
                  container
                  direction="column"
                  alignItems="center"
                >
                  {/* <StarRatings
                    title='Overall'
                    className='__stars'
                    rating={this.props.overallRatingArr.overallRatingArr}
                    starRatedColor="#C49733"
                    numberOfStars={10}
                    starDimension={20}
                    starSpacing="0"
                  /> <hr /> */}
                  <Rating title='Idea' className="top_rating" rating={this.props.overallRatingArr.ideaRatingArr} /> <br/>
                  <Rating title='Tech' className="top_rating"rating={this.props.overallRatingArr.technologyRatingArr} /> <br/>
                  <Rating title='Team' className="top_rating"rating={this.props.overallRatingArr.teamRatingArr} /> <br/>
                  <Rating title='Marketing' className="top_rating" rating={this.props.overallRatingArr.marketingRatingArr} /> <br/>
                  {(this.props.opportunity_data.additional_review_options && this.props.opportunity_data.additional_review_criteria !== undefined && this.props.opportunity_data.additional_review_criteria !== null && this.props.opportunity_data.additional_review_criteria.length > 0) &&
                      this.props.opportunity_data.additional_review_criteria.map((criteria) => (
                        <Rating title={criteria} className="top_rating" rating={(this.props.reviews.length > 0 && this.props.reviews[0].additional_review_criteria[criteria]) ? this.props.reviews[0].additional_review_criteria[criteria] : 0 } />
                      ))
                  }
                  <br />
                  {this.props.company_website !== undefined &&
                    this.props.company_website.length > 0 && (
                    <>
                      <Typography variant="caption" gutterBottom>
                        Website
                      </Typography>
                      {this.props.company_website.includes("http") ||
                      this.props.company_website.includes("https") ? (
                        <a target="_blank" href={this.props.company_website}>
                          <WebsiteIcon />
                        </a>
                      ) : (
                        <a
                          target="_blank"
                          href={`http://${this.props.company_website}`}
                        >
                          <WebsiteIcon />
                        </a>
                      )}
                    </>
                  )}
                </Grid>
            {/* {this.props.getFileUrl(
              this.props.company_files,
              "company pitchdeck"
            ) !== undefined &&
              this.props.getFileUrl(
                this.props.company_files,
                "company pitchdeck"
              ) !== 0 && (
                <Grid
                  container
                  direction="column"
                  xs={4}
                  sm={4}
                  alignItems="center"
                >
                  <Typography variant="caption" gutterBottom>
                    Pitch Deck
                  </Typography>
                  <a
                    href={this.props.getFileUrl(
                      this.props.company_files,
                      "company pitchdeck"
                    )}
                  >
                    <AttachIcon />
                  </a>
                </Grid>
              )}

            {this.props.linkedIn !== undefined &&
              this.props.linkedIn.length > 0 && (
                <Grid
                  container
                  direction="column"
                  xs={4}
                  sm={4}
                  alignItems="center"
                >
                  <Typography variant="caption" gutterBottom>
                    LinkedIn
                  </Typography>
                  <SocialIcon
                    style={{ margin: "3px", width: "30px", height: "30px" }}
                    target="_blank"
                    url={this.props.linkedIn}
                  />
                </Grid>
              )} */}
          </Grid>
          <Grid container direction="row">
            <Grid
              container
              direction="column"
              xs={12}
              sm={12}
              alignItems="center"
            >
              <div
                className={classnames(
                  "exploreCardTags",
                  this.state.expanded_top && "expanded"
                )}
              >
                <div className="allTags">
                  {showFilterTags.concat(showRemainderTags).map((val) => (
                    <Chip
                      variant="outlined"
                      className={classnames(
                        "exploreTag",
                        getTagColorClass(val)
                      )}
                      color={
                        _includes(showFilterTags, val) ? "primary" : "default"
                      }
                      size="small"
                      label={val}
                    />
                  ))}
                </div>
              </div>
            </Grid>
          </Grid>
          
          {/* <div className="__footer">
            <p
              style={{
                fontSize: "12px",
                margin: "unset",
                fontStyle: "oblique",
              }}
            >
              {this.props.sub_vertical}
            </p>
            <Button
              variant="contained"
              color="primary"
              onClick={(event) =>
                this.props.previewSelectedCompany(event, this.props._id)
              }
              size="small"
            >
              Reviews
            </Button>
          </div> */}
        </CardContent>
        {this.state.show_info_popup && (
          <div className="__info-popup">
            <div className="__blur" onClick={this.show_info_popup}></div>
            <div className="__content">
              <div>
                <div>Description</div>
                <div>{this.props.short_bio}</div>
              </div>
              <div>
                <div>Objective</div>
                <div>{this.props.objective}</div>
              </div>
              <div>
                <div>Problem</div>
                <div>{this.props.problem}</div>
              </div>
              <div>
                <div>Solution</div>
                <div>{this.props.solution}</div>
              </div>
              <div>
                <div>Target Market</div>
                <div>{this.props.target_market}</div>
              </div>
              <div>
                <div>Market Size</div>
                <div>{this.props.market_size}</div>
              </div>
              <div>
                <div>Competitors</div>
                <div>{this.props.competitors}</div>
              </div>
              <div>
                <div>Revenue Model</div>
                <div>{this.props.revenue_model}</div>
              </div>
              <div>
                <div>Revenue Since Inception</div>
                <div>{this.props.revenue_since_inception}</div>
              </div>
              <div>
                <div>Growth</div>
                <div>{this.props.growth}</div>
              </div>
              <div>
                <div>Traction</div>
                <div>{this.props.traction}</div>
              </div>
              <div>
                <div>Market Size</div>
                <div>{this.props.marketing_strategy}</div>
              </div>
              <div>
                <div>Team Description</div>
                <div>{this.props.team_description}</div>
              </div>
              <div>
                <div>Incubator & Accelerator History</div>
                <div>{this.props.incubator_accelerator_history}</div>
              </div>
              <div>
                <div>Fundraising</div>
                <div>{this.props.raising_funds}</div>
              </div>
              <div>
                <div>Contact Info</div>
                <p>
                  <span style={{ fontStyle: "italic" }}>Name: </span>{" "}
                  {this.props.company_owner.first_name}{" "}
                  {this.props.company_owner.last_name}{" "}
                </p>
                <p>
                  <span style={{ fontStyle: "italic" }}>Email: </span>{" "}
                  {this.props.company_owner.email}{" "}
                </p>
                <p>
                  <span style={{ fontStyle: "italic" }}>#: </span>{" "}
                  {this.props.contact_phone}{" "}
                </p>
              </div>
            </div>
          </div>
        )}
      </Card>
    );
  }
}

RecipeReviewCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RecipeReviewCard);
