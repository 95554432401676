import React, { Component } from "react";
// import './sass/main.css';

// Imported elements from explore_card.js
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import default_background from "../img/bg.png";
import classnames from "classnames";
import Grid from "@material-ui/core/Grid";
// import Card from '@material-ui/core/Card';
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import CardActionArea from "@material-ui/core/CardActionArea";
import Typography from "@material-ui/core/Typography";
import red from "@material-ui/core/colors/red";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ShareIcon from "@material-ui/icons/Share";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Chip from "@material-ui/core/Chip";
import FaceIcon from "@material-ui/icons/Face";
import DoneIcon from "@material-ui/icons/Done";
import FolderIcon from "@material-ui/icons/Folder";

import SiteTitle from "../live/components/site_title";

// import Button from '@material-ui/core/Button';
import Moment from "moment";
import { Explore_Share_Popup, Sign_In } from "../live/components/popups";
import {
  country_list,
  opportunity_type_list,
  industry_list,
  stage_list,
  business_type,
} from "../boilerplate/list.json";

// // Time template layouts & icons
// import "../argon-timtemplate/src/assets/vendor/nucleo/css/nucleo.css";
// import "../argon-timtemplate/src/assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
// import "../argon-timtemplate/src/assets/css/argon-dashboard-react.css";

import Landingpage from "./pages/M-landingPage";
import Navbar from "./components/navbar/M-navbar";
import SideDrawer from "./components/sideDrawer/M-sideDrawer";
import Backdrop from "./components/backdrop/M-backdrop";

import Layout from "./layout/layout";

import ServicesPage from "./pages/M-servicesPage";

class Test extends Component {
  // state = {
  //   sideDrawerOpen : false

  // };

  // drawerToggleClickHandler = () => {

  //   this.setState((prevState) => {

  //     return {sideDrawerOpen : !prevState.sideDrawerOpen};

  //   });

  // }

  // backdropClickHandler = () => {
  //   this.setState ({
  //     sideDrawerOpen : false
  //   })
  // }

  render() {
    // let backdrop;

    // if (this.state.sideDrawerOpen) {

    //   backdrop = <Backdrop  click = {this.backdropClickHandler}/>;
    // }
    return (
      <div>
        <SiteTitle
          title="StartupFuel - Services"
          meta_description="StartupFuel services connect you with consultants and service providers that are the best fit based off of what you need to help your company grow."
          meta_keywords="Startup Consulting, Startup Services, Startup Help"
        />
        <Sign_In />
        <ServicesPage />
      </div>
    );
  }
}

export default Test;
