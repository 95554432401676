/* (2019-06-11) - The filters component used on the opportunities page.
 */
import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import {
  country_list,
  opportunity_type_list,
  industry_list,
  stage_list,
  business_type,
} from "../../boilerplate/list.json";
import Button from "@material-ui/core/Button";
import "./filterUbaidCustom.css";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
  formControl: {
    margin: theme.spacing.unit,
    width: "100%",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: theme.spacing.unit / 4,
  },
  noLabel: {
    marginTop: theme.spacing.unit * 3,
  },
  button: {
    margin: theme.spacing.unit,
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: "auto",
    },
  },
};

function getStyles(name, that) {
  return {
    fontWeight:
      that.state.name.indexOf(name) === -1
        ? that.props.theme.typography.fontWeightRegular
        : that.props.theme.typography.fontWeightMedium,
  };
}

function handleDelete() {
  alert("You clicked the delete icon.");
}

function handleClick() {
  alert("You clicked the Reset.");
}

class MultipleSelect extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    name: [],
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root + " ubaidFilterBoxMain"}>
        {/* <Paper
          className={classes.root + ' ubaidFilterBox'}
          elevation={3}> */}
        {/*
            <FormControl className={classes.formControl + ' ubaidForm'}>
              <InputLabel htmlFor="select-multiple-chip">
                Looking For?
              </InputLabel>
              <Select
                multiple
                value={this.props.filters.opportunity_type || []}
                onChange={this.props.handleFilterChange}
                input={<Input id="select-multiple-chip" />}
                name='opportunity_type'
                renderValue={selected => (
                  <div className={classes.chips}>
                    {selected.map(value => (
                      <Chip
                        key={value}
                        label={value}
                        className={classes.chip}
                        onDelete={this.props.handleSingleDelete(value, 'opportunity_type')} />
                    ))}
                  </div>
                )}
                MenuProps={MenuProps}>
                {opportunity_type_list.map(name => (
                  <MenuItem
                    key={name}
                    value={name}
                    style={getStyles(name, this)}>
                    <Checkbox checked={this.props.filters.opportunity_type !== undefined ? this.props.filters.opportunity_type.indexOf(name) > -1 : ""} />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            */}
        <div className="FilterUbaidForm">
          <FormControl className={classes.formControl + " ubaidForm"}>
            <InputLabel htmlFor="select-multiple-chip">Industry</InputLabel>
            <Select
              multiple
              value={this.props.filters.target_industries || []}
              onChange={this.props.handleFilterChange}
              input={<Input id="select-multiple-chip" />}
              name="target_industries"
              renderValue={(selected) => (
                <div className={classes.chips}>
                  {selected.map((value) => (
                    <Chip
                      key={value}
                      label={value}
                      className={classes.chip}
                      onDelete={this.props.handleSingleDelete(
                        value,
                        "target_industries"
                      )}
                    />
                  ))}
                </div>
              )}
              MenuProps={MenuProps}
            >
              {industry_list.map((name) => (
                <MenuItem key={name} value={name} style={getStyles(name, this)}>
                  <Checkbox
                    checked={
                      this.props.filters.target_industries !== undefined
                        ? this.props.filters.target_industries.indexOf(name) >
                          -1
                        : ""
                    }
                  />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="FilterUbaidForm UbaidFormMarginLr">
          <FormControl className={classes.formControl + " ubaidForm"}>
            <InputLabel htmlFor="select-multiple-chip">Country</InputLabel>
            <Select
              multiple
              value={this.props.filters.geographical_location || []}
              onChange={this.props.handleFilterChange}
              input={<Input id="select-multiple-chip" />}
              name="geographical_location"
              renderValue={(selected) => (
                <div className={classes.chips}>
                  {selected.map((value) => (
                    <Chip
                      key={value}
                      label={value}
                      className={classes.chip}
                      onDelete={this.props.handleSingleDelete(
                        value,
                        "geographical_location"
                      )}
                    />
                  ))}
                </div>
              )}
              MenuProps={MenuProps}
            >
              {country_list.map((name) => (
                <MenuItem key={name} value={name} style={getStyles(name, this)}>
                  <Checkbox
                    checked={
                      this.props.filters.geographical_location !== undefined
                        ? this.props.filters.geographical_location.indexOf(
                            name
                          ) > -1
                        : ""
                    }
                  />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="FilterUbaidForm UbaidFormMarginR">
          <FormControl className={classes.formControl + " ubaidForm"}>
            <InputLabel htmlFor="select-multiple-chip">Stage</InputLabel>
            <Select
              multiple
              value={this.props.filters.target_stage_companies || []}
              onChange={this.props.handleFilterChange}
              input={<Input id="select-multiple-chip" />}
              name="target_stage_companies"
              renderValue={(selected) => (
                <div className={classes.chips}>
                  {selected.map((value) => (
                    <Chip
                      key={value}
                      label={this.props.getStage(value, stage_list)}
                      className={classes.chip}
                      onDelete={this.props.handleSingleDelete(
                        value,
                        "target_stage_companies"
                      )}
                    />
                  ))}
                </div>
              )}
              MenuProps={MenuProps}
            >
              {stage_list.map((name) => (
                <MenuItem
                  key={name.value}
                  value={name.value}
                  style={getStyles(name, this)}
                >
                  <Checkbox
                    checked={
                      this.props.filters.target_stage_companies !== undefined
                        ? this.props.filters.target_stage_companies.indexOf(
                            name.value
                          ) > -1
                        : ""
                    }
                  />
                  <ListItemText primary={name.text} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="FilterUbaidBTN">
          <Button
            variant="contained"
            color="primary"
            className={classes.button + " FilterUbaidBTNIn"}
            onClick={this.props.resetFilters}
          >
            Reset
          </Button>
        </div>
        {/* </Paper> */}
        {/* </Grid> */}
      </div>
    );
  }
}

MultipleSelect.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, {
  withTheme: true,
})(MultipleSelect);
