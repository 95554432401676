import React from "react";
import { GlobalStyles } from "./components/GlobalStyles";

import NavBar from "./components/Nav/NavBar";
import HeroSection from "./components/Hero/Hero";
import AboutSection from "./components/About/About";
import { Business } from "./components/Divider/Divider";
import { Stats } from "./components/Divider/Divider";
import PitchBoxSection from "./components/PitchBox/PitchBox";
import FundSection from "./components/Fund/Fund";
import FooterSection from "./components/Footer/Footer";
import './pitchbox.css'

export default function NewPitchBox() {

  return (
    <>
      <NavBar />
      <HeroSection />
      <Stats />
      {/* <Business /> */}
      <AboutSection />
      <PitchBoxSection />
      <FundSection />
      <FooterSection />
      <GlobalStyles />
    </>
  );
}
