/* (2019-06-10) - This is popup used to share the opportunity.
*/
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import Grid from '@material-ui/core/Grid';

const styles = theme => ({
  typography: {
    margin: theme.spacing.unit * 2,
    font: 12,
    color: 'black',
  },
  popover: {
    color: 'black',
  },
  button: {
    margin: theme.spacing.unit,
  },
});

class SimplePopover extends React.Component {
  state = {
    anchorEl: null,
  };

  handleClick = event => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  render() {
    const {classes} = this.props;
    const {anchorEl} = this.state;
    const open = Boolean(anchorEl);

    return (
      <Grid
        container
        direction="column"
        justify="flex-end">
        <Button
          aria-owns={ open ? 'simple-popper' : undefined }
          aria-haspopup="true"
          variant="outlined"
          color="primary"
          onClick={ this.handleClick }
          className={ classes.button }>
          Share
        </Button>
        <Popover
          id="simple-popper"
          open={ open }
          anchorEl={ anchorEl }
          onClose={ this.handleClose }
          className={ classes.popover }
          anchorOrigin={ {  vertical: 'bottom',  horizontal: 'center',} }
          transformOrigin={ {  vertical: 'top',  horizontal: 'center',} }
          elevation={ 4 }>
          <Typography className={ classes.typography }>
            Link URL Copied!
          </Typography>
        </Popover>
      </Grid>
      );
  }
}

SimplePopover.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SimplePopover);
