import React from 'react';
import './PostFooter.css';
import classnames from 'classnames';
import HeartIcon from '../icons/HeartIcon';
import CommentIcon from '../icons/CommentIcon';
import ProfileImage from '../../../components/profile_image';
import FollowersPopup from '../../../components/popups/newsfeed_followers';

class PostFooter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showNewsFeedPopup: false
    };
  }
  handleLikeListPopUp = (e) => {
    this.setState({
      showNewsFeedPopup: !this.state.showNewsFeedPopup
    })
  }
  render() {
    return (
      <div>
        <FollowersPopup
          {...this.state}
          post_id={ this.props.post_id }
          handleLikeListPopUp={ this.handleLikeListPopUp } />
        <div className='PostFooterNewsFeed PostSocialIcons'>
          <a
            className={ classnames('PostSocialLikeButton', {
                          "active": this.props.currentlyLiked
                        }) }
            href='#'
            style={ { marginRight: "unset" } }
            onClick={ this.props.handleLikeToggle }>
            <HeartIcon />
          </a>
          <a
            className="PostSocialLikeButton"
            onClick={ (event) => this.handleLikeListPopUp(event) }>
            { this.props.likes }
          </a>
          <a
            href='#'
            style={ { marginRight: "unset" } }
            onClick={ this.props.handleCommentToggle }>
            <CommentIcon />
          </a>
          <a
            className="PostSocialLikeButton">
            { this.props.comments }
          </a>
        </div>
      </div>
      );
  }
}

export default PostFooter;
