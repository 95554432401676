import React from "react";
import axios from "axios";
import update from "immutability-helper";
import "../main.css";
import "./components/profile.css";
import {
  Button,
  Alert,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  Card,
  Col,
  Row,
  Image,
  Navbar,
  Jumbotron,
  Nav,
  NavItem,
} from "react-bootstrap";
import { Helmet } from "react-helmet";
import URL from "url-parse";
import default_pic from "../img/default-avatar.png";
import default_background from "../img/bg.png";
import StartupFuel from "../img/watermarksffinal.png";
import Loader from "./components/loader";
import { AppContext } from "../App";
import Navigation from "./components/navbar";
import {
  Company_Update,
  Sign_In,
  Register,
  Forgot_Password,
  Alert_Message_To_User,
  User_Profile_Photo_Update,
  FullScreenModal,
} from "./components/popups";
import { User_Profile_Card } from "./components/cards";
import { stage_list } from "../boilerplate/list.json";
import SiteTitle from "./components/site_title.js";
import { withStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import InputBase from "@material-ui/core/InputBase";
import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";
import DisplayToggle from "./components/DisplayToggle";
import SideMenu from "./SideMenu";

import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function getStyles(name, that) {
  return {
    fontWeight:
      that.state.name.indexOf(name) === -1
        ? that.props.theme.typography.fontWeightRegular
        : that.props.theme.typography.fontWeightMedium,
  };
}
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);
const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: theme.spacing.unit / 4,
  },
  textField: {
    width: "100%",
    display: "block",
    padding: "15px 0",
    maxWidth: 500,
    margin: "0 auto",
    border: "1px solid #ccc",
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 100,
  },
});
class User_Profile_Container extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return <User_Profile {...this.props} {...this.context} />;
  }
}
User_Profile_Container.contextType = AppContext;
class User_Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: new URL(window.location.href, true),
      group_list: [],
      showEditPopup: false,
      showSignInPopup: false,
      showEditEmail: false,
      showEditPassword: false,
      showUserProfilePhotoPopup: false,
      team_list: [],
      error_msg: "",
      email_msg: "",
      submitting: false,

      userIsProfileOwner: false,
      userAvatarDetails: {
        file: "",
        file_name: "",
        file_type: "",
      },
      avatarDetails: {
        file: "",
        file_name: "",
        file_type: "",
      },
      backgroundDetails: {
        file: "",
        file_name: "",
        file_type: "",
      },
      loading: false,

      //for form
      company_name: "",
      company_files: [],
      old_password: "",
      new_password: "",
      new_password_again: "",
      referred_users_companies: [],
    };
  }
  handleFileUpload = (e) => {
    let file = e.target.files[0];
    if (e.target.dataset.fileType === "user avatar") {
      this.setState({
        userAvatarDetails: {
          file,
          file_name: "user profile",
          file_type: `${e.target.dataset.fileType}`,
          object_ref: "user",
          object_ref_value: this.props.user._id,
        },
      });
    }
  };

  getStage = (value, array) => {
    for (let i = 0; i < array.length; i++) {
      if (array[i].value === value) {
        return array[i].text;
      }
    }
  };

  fetchAssets = (asset_type, callback) => {
    axios
      .get(`/fetch_${asset_type}`)
      .then((response) => {
        callback(response.data, this);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleToggle = (e) => {
    const name = e.target.name;
    this.setState({
      [name]: !this.state[name],
    });
  };

  handleChange = (e) => {
    const name = e.target.name;
    this.setState({
      [name]: e.target.value,
    });
  };

  handleChangeMultiple = (e) => {
    const name = e.target.name;
    let selected_values = [];
    Array.from(e.target.selectedOptions).forEach(function (item) {
      selected_values.push(item.value);
    });
    this.setState({
      [name]: selected_values,
    });
  };

  getAvatarUrl = (array, file_type) => {
    if (array?.length > 0) {
      let new_arr = array.filter((file) => {
        return file.file_type === file_type;
      });
      if (new_arr.length !== 0) {
        return new_arr[0].file_url;
      } else {
        return default_pic;
      }
    } else {
      return default_pic;
    }
  };

  handleEditPopup = (e) => {
    this.setState({
      showEditPopup: !this.state.showEditPopup,
    });
  };
  handleUserProfilePhotoEditPopup = (e) => {
    this.setState({
      showUserProfilePhotoPopup: !this.state.showUserProfilePhotoPopup,
    });
  };

  dismissModal = (e) => {
    this.setState({
      showEditPopup: false,
      showUserProfilePhotoPopup: false,
    });
  };

  togglePopup = (e) => {
    this.setState({
      showSignInPopup: !this.state.showSignInPopup,
    });
  };

  handleFileSubmit = (e) => {
    this.setState({
      submitting: true,
    });
    e.preventDefault();
    let name = e.target.name;
    
    console.log("Form name:", name);
    console.log("State data:", this.state[name]);
    console.log("File type:", typeof this.state[name]?.file);

    let config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    let formData = new FormData();

    for (let key in this.state[name]) {
      formData.append(key, this.state[name][key]);
    }
    
    if (typeof this.state[name]?.file === "object") {
      console.log("Making API call...");
      
      axios
        .post(
          `/upload_file_user?user_id=${this.props.user._id}`,
          formData,
          config,
        )
        .then((response) => {
          if (response.data.status === "success") {
            this.setState({
              submitting: false,
            });
            window.location.reload();
          }
        })
        .catch((error) => {
          console.log("API Error:", error);
          this.setState({
            submitting: false,
          });
        });
    } else {
      console.log("File validation failed - not making API call");
      this.setState({
        submitting: false,
      });
      return;
    }
  };

  submitEmailChange = (e) => {
    e.preventDefault();

    axios
      .post(`/edit_user_email?user_id=${this.props.user._id}`, {
        email: this.props.user.email,
      })
      .then((response) => {
        this.setState({
          email_msg: response.data.message,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  submitPasswordChange = (e) => {
    e.preventDefault();

    axios
      .post(`/edit_user_pass`, {
        username: this.props.user.email,
        old_password: this.state.old_password,
        new_password: this.state.new_password,
        new_password_again: this.state.new_password_again,
      })
      .then((response) => {
        this.setState({
          error_msg: response.data.message,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  componentDidMount() {
    axios.post(`/fetch_user_referred_users_companies`).then((response) => {
      this.setState({
        referred_users_companies: response.data.referred_users_companies,
      });
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <SiteTitle />
        <Navigation />
        <Sign_In />
        <Forgot_Password />
        <Register />
        <SideMenu />
        <User_Profile_Photo_Update
          {...this.state}
          show={this.state.showUserProfilePhotoPopup}
          handleChange={this.handleChange}
          handleChangeMultiple={this.handleChangeMultiple}
          handleInputSubmit={this.handleInputSubmit}
          handleFileUpload={this.handleFileUpload}
          handleFileSubmit={this.handleFileSubmit}
          submitting={this.state.submitting}
          dismissModal={this.dismissModal}
        />
        <DisplayToggle show={this.props.isLoggedIn === true}>
          <div className="ExpOppTooMainUbaid">
            <div style={{ display: "flex", flexDirection: "column", gap: 15 }}>
              <div>
                <User_Profile_Card
                  {...this.state}
                  {...this.props}
                  getAvatarUrl={this.getAvatarUrl}
                  getStage={this.getStage}
                  handleUserProfilePhotoEditPopup={
                    this.handleUserProfilePhotoEditPopup
                  }
                  handleEditPopup={this.handleEditPopup}
                />
              </div>
              <div>
                <div
                  style={{
                    backgroundColor: "#fff",
                    borderRadius: "12px",
                    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
                    padding: "20px",
                    margin: "auto",
                    transition: "box-shadow 0.3s ease-in-out",
                    maxWidth: "600px",
                  }}
                  onMouseOver={(e) => {
                    e.currentTarget.style.boxShadow =
                      "0 6px 30px rgba(0, 0, 0, 0.2)";
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.style.boxShadow =
                      "0 4px 20px rgba(0, 0, 0, 0.1)";
                  }}
                >
                  <div className="section-body">
                    <div>
                      <Card.Title>User Information</Card.Title>
                      <Card.Text>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p style={{ margin: 0, padding: 0 }}>
                            Email: {this.props.user.email}
                          </p>
                          <button
                            type="button"
                            name="showEditEmail"
                            onClick={this.handleToggle}
                            style={{
                              backgroundColor: "#fff",
                              textAlign: "center",
                              border: "none",
                              marginLeft: "10px",
                            }}
                          >
                            Change Email
                          </button>
                        </div>
                      </Card.Text>
                      <Card.Text>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p style={{ margin: 0, padding: 0 }}>
                            Password: **********
                          </p>
                          <button
                            type="button"
                            name="showEditPassword"
                            onClick={this.handleToggle}
                            style={{
                              backgroundColor: "#fff",
                              textAlign: "center",
                              border: "none",
                              marginLeft: "10px",
                            }}
                          >
                            Change Password
                          </button>
                        </div>
                      </Card.Text>
                    </div>

                    {/* Email Edit Form */}
                    <FullScreenModal show={!!this.state.showEditEmail}>
                      <div
                        style={{
                          borderRadius: "12px",
                          boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          gap: "20px",
                          padding: "20px",
                        }}
                      >
                        <Card.Title
                          style={{
                            fontSize: "24px",
                            marginBottom: "10px",
                            textAlign: "center",
                          }}
                        >
                          Update Your Email
                        </Card.Title>
                        <input
                          id="outlined-multiline-static"
                          type="text"
                          value={this.props.user.email}
                          name="email"
                          style={{
                            width: "100%",
                            padding: "10px",
                            borderRadius: "5px",
                            border: "1px solid #ced4da",
                            fontSize: "16px",
                          }}
                          onChange={this.props.handleEmailChange}
                          placeholder="Enter your personal email"
                        />
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            marginTop: "20px",
                          }}
                        >
                          <button
                            name="showEditEmail"
                            onClick={this.submitEmailChange}
                            style={{
                              backgroundColor: "#007bff",
                              color: "#fff",
                              padding: "10px 20px",
                              borderRadius: "5px",
                              border: "none",
                              fontSize: "16px",
                              cursor: "pointer",
                              flex: 1,
                              marginRight: "10px",
                            }}
                          >
                            Save
                          </button>
                          <button
                            name="showEditEmail"
                            onClick={this.handleToggle}
                            style={{
                              backgroundColor: "#ca3a64",
                              color: "#fff",
                              padding: "10px 20px",
                              borderRadius: "5px",
                              border: "none",
                              fontSize: "16px",
                              cursor: "pointer",
                              flex: 1,
                            }}
                          >
                            Close
                          </button>
                        </div>
                        {this.state.email_msg.length > 0 && (
                          <p
                            style={{
                              color: "#ca3a64",
                              fontSize: "14px",
                              marginTop: "10px",
                              textAlign: "center",
                            }}
                          >
                            {this.state.email_msg}
                          </p>
                        )}
                      </div>
                    </FullScreenModal>

                    <FullScreenModal show={!!this.state.showEditPassword}>
                      <div
                        style={{
                          borderRadius: "12px",
                          boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          gap: "20px",
                          padding: "20px",
                        }}
                      >
                        <Card.Title
                          style={{
                            fontSize: "24px",
                            marginBottom: "10px",
                            textAlign: "center",
                          }}
                        >
                          Update Your Password
                        </Card.Title>
                        <input
                          id="outlined-multiline-static"
                          type="text"
                          value={this.state.old_password}
                          name="old_password"
                          style={{
                            width: "100%",
                            padding: "10px",
                            borderRadius: "5px",
                            border: "1px solid #ced4da",
                            fontSize: "16px",
                          }}
                          onChange={this.handleChange}
                          placeholder="Enter your old password"
                        />
                        <input
                          id="outlined-multiline-static"
                          type="text"
                          value={this.state.new_password}
                          name="new_password"
                          style={{
                            width: "100%",
                            padding: "10px",
                            borderRadius: "5px",
                            border: "1px solid #ced4da",
                            fontSize: "16px",
                          }}
                          onChange={this.handleChange}
                          placeholder="Enter your new password"
                        />
                        <input
                          id="outlined-multiline-static"
                          type="text"
                          value={this.state.new_password_again}
                          name="new_password_again"
                          style={{
                            width: "100%",
                            padding: "10px",
                            borderRadius: "5px",
                            border: "1px solid #ced4da",
                            fontSize: "16px",
                          }}
                          onChange={this.handleChange}
                          placeholder="Enter your new password again"
                        />
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            marginTop: "20px",
                          }}
                        >
                          <button
                            name="showEditPassword"
                            onClick={this.submitPasswordChange}
                            style={{
                              backgroundColor: "#007bff",
                              color: "#fff",
                              padding: "10px 20px",
                              borderRadius: "5px",
                              border: "none",
                              fontSize: "16px",
                              cursor: "pointer",
                              flex: 1,
                              marginRight: "10px",
                            }}
                          >
                            Save
                          </button>
                          <button
                            name="showEditPassword"
                            onClick={this.handleToggle}
                            style={{
                              backgroundColor: "#ca3a64",
                              color: "#fff",
                              padding: "10px 20px",
                              borderRadius: "5px",
                              border: "none",
                              fontSize: "16px",
                              cursor: "pointer",
                              flex: 1,
                            }}
                          >
                            Close
                          </button>
                        </div>
                        {this.state.email_msg.length > 0 && (
                          <p
                            style={{
                              color: "#ca3a64",
                              fontSize: "14px",
                              marginTop: "10px",
                              textAlign: "center",
                            }}
                          >
                            {this.state.error_msg}
                          </p>
                        )}
                      </div>
                    </FullScreenModal>
                  </div>
                </div>
              </div>

              {/*<div class="col-lg-9">
                <Card className="mt-4" style={{ width: "100%" }}>
                  <Card.Body>
                    <DisplayToggle show={!this.state.showEditEmail}>
                      <div>
                        <Card.Title>User Information</Card.Title>
                        <Card.Text>
                          <br />
                          <p>
                            Email:
                            {this.props.user.email}
                          </p>
                          <div>
                            <button
                              type="button"
                              name="showEditEmail"
                              onClick={this.handleToggle}
                              class="btn btn-light"
                            >
                              Change Email?
                            </button>
                          </div>
                        </Card.Text>
                      </div>
                    </DisplayToggle>
                    <DisplayToggle show={!!this.state.showEditEmail}>
                      <div>
                        <Card.Title>Update your email</Card.Title>
                        <input
                          id="outlined-multiline-static"
                          type="text"
                          value={this.props.user.email}
                          name="email"
                          className={classes.textField}
                          onChange={this.props.handleEmailChange}
                          placeholder="Enter your personal email"
                          margin="normal"
                          variant="outlined"
                        />
                        <div>
                          <Button
                            variant="contained"
                            name="showEditEmail"
                            onClick={this.submitEmailChange}
                            style={{
                              backgroundColor: "#007bff",
                              textAlign: "center",
                            }}
                            className={classes.button}
                          >
                            Save
                          </Button>
                          <Button
                            variant="contained"
                            name="showEditEmail"
                            onClick={this.handleToggle}
                            style={{
                              backgroundColor: "#ca3a64",
                              textAlign: "center",
                            }}
                            className={classes.button}
                          >
                            Close
                          </Button>
                        </div>
                        {this.state.email_msg.length > 0 && (
                          <p>{this.state.email_msg}</p>
                        )}
                      </div>
                    </DisplayToggle>
                  </Card.Body>
                </Card>
                <br />
                <Card>
                  <Card.Body>
                    <DisplayToggle show={!this.state.showEditPassword}>
                      <div>
                        <Card.Title>User Password</Card.Title>
                        <Card.Text>
                          <br />
                          <p>Password: **********</p>
                          <div>
                            <button
                              type="button"
                              name="showEditPassword"
                              onClick={this.handleToggle}
                              class="btn btn-light"
                            >
                              Change password?
                            </button>
                          </div>
                        </Card.Text>
                      </div>
                    </DisplayToggle>
                    <DisplayToggle show={!!this.state.showEditPassword}>
                      <Card.Title>Update your password</Card.Title>
                      <p style={{ marginBottom: "0" }}>
                        Enter your old password
                      </p>
                      <input
                        type="password"
                        id="outlined-multiline-static"
                        value={this.state.old_password}
                        name="old_password"
                        className={classes.textField}
                        onChange={this.handleChange}
                        margin="normal"
                        variant="outlined"
                      />
                      <p style={{ marginBottom: "0" }}>
                        Enter your new password
                      </p>
                      <input
                        type="password"
                        id="outlined-multiline-static"
                        value={this.state.new_password}
                        name="new_password"
                        className={classes.textField}
                        onChange={this.handleChange}
                        margin="normal"
                        variant="outlined"
                      />
                      <p style={{ marginBottom: "0" }}>
                        Enter your new password again
                      </p>
                      <input
                        type="password"
                        id="outlined-multiline-static"
                        value={this.state.new_password_again}
                        name="new_password_again"
                        className={classes.textField}
                        onChange={this.handleChange}
                        margin="normal"
                        variant="outlined"
                      />
                      <div>
                        <Button
                          variant="contained"
                          onClick={this.submitPasswordChange}
                          style={{
                            backgroundColor: "#007bff",
                            textAlign: "center",
                          }}
                          className={classes.button}
                        >
                          Save
                        </Button>
                      </div>
                      {this.state.error_msg.length > 0 && (
                        <p>{this.state.error_msg}</p>
                      )}
                    </DisplayToggle>
                  </Card.Body>
                </Card>
                {this.state.referred_users_companies.length > 0 && (
                  <>
                    <Card style={{ width: "100%" }}>
                      <Card.Body>
                        <Card.Title>Referred Users</Card.Title>
                        <Paper className={classes.root + " ubaidTable"}>
                          <Table className={classes.table}>
                            <TableHead>
                              <TableRow>
                                <StyledTableCell>Email</StyledTableCell>
                                <StyledTableCell>First Name</StyledTableCell>
                                <StyledTableCell>Last Name</StyledTableCell>
                                <StyledTableCell
                                  style={{
                                    maxWidth: "167px",
                                    textAlign: "center",
                                  }}
                                >
                                  Company Name
                                </StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {this.state.referred_users_companies.map(
                                (partner) => (
                                  <StyledTableRow key="1">
                                    <StyledTableCell component="th" scope="row">
                                      {partner.email}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      {partner.first_name}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      {partner.last_name}
                                    </StyledTableCell>
                                    {partner.company !== undefined ? (
                                      <StyledTableCell
                                        style={{ textAlign: "center" }}
                                      >
                                        <a
                                          href={`/profile?company_id=${partner.company._id}`}
                                        >
                                          {partner.company.company_name}
                                        </a>
                                      </StyledTableCell>
                                    ) : (
                                      <StyledTableCell
                                        style={{ textAlign: "center" }}
                                      >
                                        N/A
                                      </StyledTableCell>
                                    )}
                                  </StyledTableRow>
                                )
                              )}
                            </TableBody>
                          </Table>
                        </Paper>
                      </Card.Body>
                    </Card>
                  </>
                )}
              </div>*/}
            </div>
          </div>
        </DisplayToggle>
        <DisplayToggle hide={this.props.isLoggedIn === true}>
          You cannot view this page, you are not logged in
        </DisplayToggle>
        <br />
      </React.Fragment>
    );
  }
}

User_Profile_Container.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(User_Profile_Container);
