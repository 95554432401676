/* (2019-06-10) - This is the navbar used across the site, it also contains the
mobile version of the navbar (which are coded seperatly).
*/
import React from 'react';
import axios from 'axios'
import './navbar.css';
import { Nav } from 'react-bootstrap';
import logo from '../../img/combined.png'
import default_pic from '../../img/default_company_pic.png'
import default_profile_pic from '../../img/default-avatar.png'
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import { withContext } from './popups'
import { AppContext } from '../../App'
import URL from 'url-parse';
import './navbarUbaid.css';
import {Link} from 'react-router-dom';


const root = {
  padding: '2px 4px',
  display: 'flex',
  alignItems: 'center',
  width: "30%",
};

const input = {
  marginLeft: 8,
  flex: 1,
};

const iconButton = {
  padding: 10,
};

class Navig extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: new URL(window.location.href, true),
      old_url: "",
      highlighted_explore: false,
      highlighted_opportunity: false,
      highlighted_services: false,
    }
  }

  getAvatarUrl = (array) => {
    if (array?.length > 0) {
      let new_arr = array.filter(file => {
        return file.file_type === 'user avatar'
      })
      if (new_arr.length !== 0) {
        return new_arr[0].file_url
      } else {
        return default_profile_pic
      }
    } else {
      return default_profile_pic
    }
  }

  getField = (array, field) => {
    if (Array.isArray(array) && array.length > 0) {
      if (array.length !== 0) {
        return array[0][field]
      } else {
        return ''
      }
    } else {
      return ''
    }
  }

  getcompanyUrl = (array) => {
    if (Array.isArray(array) && array.length > 0) {
      if (array.length !== 0) {
        return `/profile?company_id=${array[0]._id}`
      } else {
        return '/'
      }
    } else {
      return '/'
    }
  }
  getCurrentUrl = () => {
    if (this.state.old_url !== this.state.url.href) {
      this.setState({
        old_url: this.state.url.href
      })

      if (`${this.state.url.href}` === `${this.state.url.origin}/explore`) {
        this.setState({
          highlighted_explore: true
        })
      } else {
        this.setState({
          highlighted_explore: false
        })
      }
      if (`${this.state.url.href}` === `${this.state.url.origin}/services_page`) {
        this.setState({
          highlighted_services: true
        })
      } else {
        this.setState({
          highlighted_services: false
        })
      }
      if (this.state.url.href === `${this.state.url.origin}/opportunities`) {
        this.setState({
          highlighted_opportunity: true
        })
      } else {
        this.setState({
          highlighted_opportunity: false
        })
      }
    }
  }
  getGroupUrl = (array, page) => {
    if (Array.isArray(array) && array.length > 0) {
      if (array.length !== 0) {
        return `${page}?group_id=${array[0]._id}`
      } else {
        return '/'
      }
    } else {
      return '/'
    }
  }

  render() {
    let dropDown = true;
    return (
      <React.Fragment>
        <div className='UBaidSubHeader' >
        {(!this.props.isLoggedIn && (this.state.url.pathname === "/explore" || this.state.url.pathname === "/profile")) &&
          <div className="subHeader" style={{marginBottom:"20px"}}>
            <div className="leftContent">
              <h3>Welcome to StartupFuel</h3><span> Intelligently built to connect, engage and grow the startup ecosystem. If you are a <strong>Startup Founder</strong>, simply register your company and we will try to help you connect with the right people and the right opportunities. If you are a <strong>Service Provider</strong>, <strong>Investor</strong>, <strong>Startup Event Organizer</strong>, we help you to market and engage with startups that you want to work with.</span>
            </div>
            <div className="rightContent">
              <Link to="/register">
                <button
                  type="button"
                  class="btn btn-success"> Add Your Company </button>
              </Link>
            </div>
          </div>}
        {(!this.props.isLoggedIn && (this.state.url.pathname === "/opportunities")) &&
          <div className="subHeader" style={{marginBottom:"20px"}}>
            <div className="leftContent">
              <h3>Welcome to StartupFuel</h3><span> Intelligently built to connect, engage and grow the startup ecosystem. If you are a <strong>Startup Founder</strong>, simply register your company and apply to relevent pitch competitions. If you are an entity that hosts pitch competitions we help you to market and manage startups for your pitch competition(s).</span>
              </div>
            <div className="rightContent">
              <a href="/register">
                <button
                  type="button"
                  class="btn btn-success"> Add Your Company </button>
              </a>
            </div>
          </div>}

          {(!this.props.isLoggedIn && (this.state.url.pathname === "/accelerators")) &&
            <div className="subHeader" style={{marginBottom:"20px"}}>
              <div className="leftContent">
                <h3>Welcome to StartupFuel</h3><span> Intelligently built to connect, engage and grow the startup ecosystem. If you are a <strong>Startup Founder</strong>, simply register your company and apply to join accelerators and incubators. If you are operate an accelerator or Incubator we help you to market and accept the best startups.</span>
                </div>
              <div className="rightContent">
                <a href="/register">
                  <button
                    type="button"
                    class="btn btn-success"> Add Your Company </button>
                </a>
              </div>
            </div>}

        


        {(!this.props.isLoggedIn && this.state.url.pathname === "/create_user_company") &&
          <div
            className="subHeader"
            style={{ display: "block" }} style={{marginBottom:"20px"}}>
            <div
              className="leftContent"
              style={{ textAlign: 'center' }}>
              <h3 style={{ textAlign: 'center' }}>Welcome to StartupFuel</h3><span> Intelligently built to connect, engage and grow the startup ecosystem. If you are a founder, simply register your company and we will suggest relevant Pitch Competitions, Accelerators, Investors and more.</span>
            </div>
            <div className="rightContent">
            </div>
          </div>}


        {(!this.props.isLoggedIn && this.state.url.pathname === "/services") &&
          <div className="subHeader" style={{marginBottom:"20px"}}>
            <div className="leftContent">
              <h3>Welcome to StartupFuel</h3><span> Intelligently built to connect, engage and grow the startup ecosystem. If you are a <strong>startup</strong>, simply register your company and we'll recommend services for you to hire directly through our platform. <strong>Service Provider?</strong> Feel free to sign up, add your service and begin the vetting process.</span>
              </div>
            <div className="rightContent">
              <a href="/register">
                <button
                  type="button"
                  class="btn btn-success"> Add Your Company </button>
              </a>
            </div>
          </div>}
          {(this.props.isLoggedIn && this.state.url.pathname === "/services" && this.props.companies_owned !== undefined && this.props.companies_owned !== null && this.props.companies_owned[1] !== undefined && this.props.companies_owned[1] !== null && this.props.companies_owned[1][0] !== undefined && this.props.companies_owned[1][0].company_type[0]==="Service Provider") &&
            <div className="subHeader" style={{marginBottom:"20px"}}>
              <div className="leftContent">
                <h3>Post a Service</h3><span> We want to help you generate leads for your business. List on StartupFuel so startups can see your service offering.</span>
                </div>
              <div className="rightContent">
                <a target="_blank" href="https://services.startupfuel.com/">
                  <button
                    type="button"
                    class="btn btn-success"> Add Your Service </button>
                </a>
              </div>
            </div>}
            {(this.props.isLoggedIn && this.state.url.pathname === "/services" && this.props.companies_owned !== undefined && this.props.companies_owned !== null && this.props.companies_owned[1] !== undefined && this.props.companies_owned[1] !== null && this.props.companies_owned[1][0] !== undefined && this.props.companies_owned[1][0].company_type[0]!=="Service Provider") &&
              <div className="subHeader" style={{marginBottom:"20px"}}>
                <div className="leftContent">
                  <h3>Want to grow & improve your company? </h3><span>Speak to us for a free consultation on how to improve and we'll connect you to relevant & affordable service providers. If there is a particular service you are interested in below, you can message & hire them directly through StartupFuel but we can also walk you through the process. Click the button to the right to chat with us and get started.</span>
                  </div>
                <div className="rightContent">
                  <a target="_blank" href="https://services.startupfuel.com/">
                    <button
                      type="button"
                      class="btn btn-success">Chat with Advisor?</button>
                  </a>
                </div>
              </div>}
        {(!this.props.isLoggedIn && this.state.url.pathname === "/toolbox") &&
          <div className="subHeader" style={{marginBottom:"20px"}}>
            <div className="leftContent">
              <h3>Welcome to StartupFuel</h3><span> Intelligently built to connect, engage and grow the startup ecosystem. If you are a <strong>Startup</strong>, register your company to give your products global exposure. If you are looking for tools to improve your business, feel free to browse and register to get suggestions on tools that can help your business.</span>
                </div>
            <div className="rightContent">
              <a href="/register">
                <button
                  type="button"
                  class="btn btn-success"> Add Your Company </button>
              </a>
            </div>
          </div>}

          {/*(!this.props.isLoggedIn && (this.state.url.pathname === "/" || this.state.url.pathname === "/home_page")) &&
            <div className="subHeader" >
              <div className="leftContent">
                <h3>Welcome to StartupFuel</h3> Calling all innovators working towards fighting the Coronavirus Pandemic! The Global COVID-19 Innovation Challenge is on the search for startups, researchers & entrepreneurs that have ventures in preventing, treating or solving the impacts of Coronavirus.
                  </div>
              <div className="rightContent">
                <a href="/opportunity?opportunity_id=5e634fa518aebb0043502d4a">
                  <button
                    type="button"
                    class="btn btn-success"> Apply Now! </button>
                </a>
              </div>
            </div>*/}
        </div>
      </React.Fragment>
    );
  }
}

export default withContext(Navig, AppContext);
