import React from 'react';
import './Testimonials.css';
import avtar from '../assets/f19e50d2-0_02s02s00000000000001o.jpeg';

const Testimonials = props => (
    <div className='pitchTestimonials'>
        <div className='pitchBoxContainer'>
            <h1>Testimonials</h1>
            <i>"StartupFuel's PitchBox came in very useful for the Schulich Startups Future Stars Pitch Competition. It was very helpful in consolidating judges feedback for contestants, and allowing us to easily organize that feedback in a way that was comprehensive for all parties involved. I recommend it as a great tool to make pitch competitions more streamlined and effective."</i>
        </div>
        <hr />
        <img src={avtar} alt='' />
        <h5>LISA GRASTON</h5>
        <span>President of Schulich Ventures Club</span>
    </div>
)

export default Testimonials;