import React from 'react';
import './SocialProfile.css';
import classnames from 'classnames';
import Moment from "moment";
import SocialAPI from '../../../api/social';
import ProfileImage from '../../../components/profile_image';
import ThreeDotsIcon from '../icons/ThreeDotsIcon';

class SocialProfile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    SocialAPI.follow_counts()
      .then((response) => {
        if (response.data.status === 'success') {
          this.setState({
            loaded: true,
            display_name: response.data.display_name,
            following_count: response.data.following_count,
            follower_count: response.data.follower_count,
            company_name: response.data.company_name
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    return (
      <div className='SocialProfile'>
        { !this.state.loaded && (
            <div className={ classnames('SocialLoadingIcon darkIcon') }>
              <ThreeDotsIcon />
          </div>
        )}
          { this.state.loaded && (
            <div>
        <div className='profile_pic'>
          <ProfileImage company_id={this.props.opp_company._id} />
        </div>
        <h5>{this.props.opp_company.company_name}'s Community</h5>
        {/*
        <div className='FollowerCounts'>
              <a href="/following" className="following">
            <span>
              Following
            </span>
            {this.state.following_count}
          </a>
            <a href="/followers" className="followers">
            <span>
              Followers
            </span>
            {this.state.follower_count}
          </a>
          </div>
          */}
          </div>
        )}
      </div>
      );
  }
}

export default SocialProfile;
