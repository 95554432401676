import React, { useState, useEffect } from "react";
import axios from "axios";

const OtpVerificationPopup = ({ show, onClose, isRegister, email }) => {
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  const [sending, setSending] = useState(false);
  const [resendTimer, setResendTimer] = useState(0);

  useEffect(() => {
    if (!isRegister && show) {
      sendOtp();
    }
  }, [isRegister, show]);

  useEffect(() => {
    if (resendTimer > 0) {
      const timer = setTimeout(() => setResendTimer(resendTimer - 1), 1000);
      return () => clearTimeout(timer);
    }
  }, [resendTimer]);

  const sendOtp = async () => {
    setSending(true);
    try {
      await axios.post("/resend_otp", { email: email });
      setSending(false);
      setResendTimer(120); // Start 30 second countdown
    } catch (err) {
      setError("Failed to send OTP. Please try again.");
      setSending(false);
    }
  };

  const onVerify = async (enteredOtp) => {
    try {
      const response = await axios.post("/verify_otp", {
        email: email,
        otp: enteredOtp,
      });

      if (response.data.status === "success") {
        onClose();
        if (!isRegister) {
          window.location.href = "/newsfeed";
        } else {
          window.location.reload();
        }
      } else {
        setError("Invalid OTP. Please try again.");
      }
    } catch (err) {
      setError("Verification failed. Please try again.");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!otp) {
      setError("Please enter OTP");
      return;
    }
    if (otp.length !== 6) {
      setError("OTP must be 6 digits");
      return;
    }
    onVerify(otp);
  };

  const handleChange = (e) => {
    const value = e.target.value;
    // Only allow numbers
    if (value === "" || /^\d+$/.test(value)) {
      setOtp(value);
      setError("");
    }
  };

  if (!show) return null;

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 1000,
      }}
    >
      <div
        style={{
          backgroundColor: "white",
          padding: "20px",
          borderRadius: "8px",
          width: "90%",
          maxWidth: "400px",
          position: "relative",
        }}
      >
        <button
          onClick={onClose}
          style={{
            position: "absolute",
            right: "10px",
            top: "10px",
            border: "none",
            background: "none",
            fontSize: "20px",
            cursor: "pointer",
          }}
        >
          ×
        </button>

        <h2 style={{ marginBottom: "20px" }}>Verify Your Email</h2>

        <p style={{ marginBottom: "20px" }}>
          {!isRegister
            ? `Please verify your email address (${email}) to complete the login process.`
            : `We've sent a verification code to ${email}`}
        </p>

        <form onSubmit={handleSubmit}>
          <div style={{ marginBottom: "20px" }}>
            <label
              style={{
                display: "block",
                marginBottom: "8px",
                fontWeight: "500",
              }}
            >
              Enter 6-digit OTP
            </label>
            <input
              type="text"
              placeholder="Enter OTP"
              value={otp}
              onChange={handleChange}
              maxLength={6}
              style={{
                width: "100%",
                padding: "8px 12px",
                border: "1px solid #ccc",
                borderRadius: "4px",
                fontSize: "16px",
              }}
            />
            {error && (
              <div
                style={{
                  color: "#dc3545",
                  marginTop: "8px",
                  padding: "8px",
                  backgroundColor: "#f8d7da",
                  borderRadius: "4px",
                }}
              >
                {error}
              </div>
            )}
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <button
              type="button"
              onClick={sendOtp}
              disabled={sending || resendTimer > 0}
              style={{
                padding: "8px 16px",
                border: "1px solid #28a745",
                borderRadius: "4px",
                backgroundColor: "white",
                color: "#28a745",
                cursor: sending || resendTimer > 0 ? "not-allowed" : "pointer",
                opacity: sending || resendTimer > 0 ? 0.7 : 1,
              }}
            >
              {sending
                ? "Sending..."
                : resendTimer > 0
                  ? `Resend in ${resendTimer}s`
                  : "Resend Code"}
            </button>

            <div style={{ display: "flex", gap: "10px" }}>
              <button
                type="button"
                onClick={onClose}
                style={{
                  padding: "8px 16px",
                  border: "1px solid #6c757d",
                  borderRadius: "4px",
                  backgroundColor: "#6c757d",
                  color: "white",
                  cursor: "pointer",
                }}
              >
                Cancel
              </button>
              <button
                type="submit"
                style={{
                  padding: "8px 16px",
                  border: "1px solid #0d6efd",
                  borderRadius: "4px",
                  backgroundColor: "#0d6efd",
                  color: "white",
                  cursor: "pointer",
                }}
              >
                Verify
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default OtpVerificationPopup;
