import React from 'react';
import axios from 'axios'

import '../test_mj-dev/sass/sass-css/_privacy_policy.scss';
import Navbar from './components/navbar';
import SideMenu from './SideMenu';
import { withStyles } from '@material-ui/core/styles';
import { AppContext } from '../App'
import PropTypes from 'prop-types';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);
const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  }, icon: {
    margin: theme.spacing.unit * 2,
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
});

class Page_Container extends React.Component {
  constructor(props) {
    super(props);
  }
  static contextType = AppContext;
  render() {
    return (<div>
      <Page {...this.props} {...this.context} />
    </div>
    )
  }
}
class Page extends React.Component {

  constructor(props) {
    super(props);
      this.state={
        referral_partners:[]
      }
  }
  componentDidMount(){
    axios.post('/fetch_admin_referral_partners_stats').then((response)=>{
      this.setState({
        referral_partners:response.data.referral_partners
      })
    })
  }


  render() {
    const { classes } = this.props;
    return (
		<React.Fragment>
			<Navbar />
			<SideMenu />
      <Paper style={{width:"85%", margin:"auto"}} className={classes.root + ' ubaidTable'}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <StyledTableCell>Email</StyledTableCell>
              <StyledTableCell>First Name</StyledTableCell>
              <StyledTableCell>Last Name</StyledTableCell>
              <StyledTableCell style={{maxWidth: '167px', textAlign: 'center'}}># of companies reviewed</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {this.state.referral_partners.length >0 &&
            <>
            {this.state.referral_partners.map((partner)=>(
              <StyledTableRow key="1">
                <StyledTableCell component="th" scope="row">
                  {partner.email}
                </StyledTableCell>
                <StyledTableCell>{partner.first_name}</StyledTableCell>
                <StyledTableCell>{partner.last_name}</StyledTableCell>
                <StyledTableCell style={{textAlign: 'center'}}>{partner.referred_users.length}</StyledTableCell>
              </StyledTableRow>
            ))}

            </>
          }


          </TableBody>
        </Table>
      </Paper>
		</React.Fragment>

	)

}
}




Page_Container.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Page_Container);
