/* (2019-06-11) - This is the component that appears when you are examining
existing reviews that have occured in the SIR/ApplicationRoom.
*/
import React, { Component } from 'react'
import StarRatings from 'react-star-ratings';
import './comment.css'
import './ubaidCustom.css'
import Moment from "moment";
import { fontSize } from '@mui/system';
export class Comment extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    starSize: '20px',
    smallStarSize: '16px'
  }
  render() {
    return (
      <div className='ubaidBadar'>

        <div className="comment ubaidComment">
          <div className="comment-img">
            <img src={this.props.getAvatarUrl(this.props.data.judge.files, "user avatar")} />
          </div>

          <div className="comment-text">
            <div className='justify-content-around'><span style={{ padding: "unset" }}>{this.props.data.judge.first_name} {this.props.data.judge.last_name}</span><span> - </span><span>{Moment(this.props.data.created_on).format("MMM Do YYYY")}</span></div >
          </div>
          <div className='ubaidCenter'>

            <div className='__rating justify-content-center overallrating' style={{ marginBottom: '12px' }}>
              <div className='__reviews'>
                <div className='__graphical-reviews'>
                  <StarRatings
                    rating={(this.props.data.pitchOverallRating===undefined || this.props.data.pitchOverallRating===null)? this.props.data.overallRating:this.props.data.pitchOverallRating}
                    starRatedColor="#C49733"
                    numberOfStars={10}
                    starDimension={this.state.starSize}
                    starSpacing="0"
                  />
                </div>
              </div>
            </div>
            <span>{this.props.data.pitchOverallRating.toFixed(1)}</span>
            <div style={{ margin: '0px'}} className="ubaidStars justify-content-around">
              <span className='ubaidFontSizes'>Idea</span>
              <span>
                <StarRatings
                  rating={this.props.data.idea}
                  starRatedColor="#C49733"
                  numberOfStars={10}
                  starDimension={this.state.smallStarSize}
                  starSpacing="0"
                  style={{ margin: '0px' }}
                />
              </span>
              <span>{this.props.data.idea.toFixed(1)}</span>
            </div>
            <div style={{ margin: '0px' }} className="ubaidStars justify-content-around">
              <span className='ubaidFontSizes'>Tech</span>
              <span>
                <StarRatings
                  rating={this.props.data.technology}
                  starRatedColor="#C49733"
                  numberOfStars={10}
                  starDimension={this.state.smallStarSize}
                  starSpacing="0"
                  style={{ margin: '0px' }}
                />
              </span>
              <span>{this.props.data.technology.toFixed(1)}</span>
              
            </div>

            <div style={{ margin: '0px' }} className="ubaidStars justify-content-around">
              <span className='ubaidFontSizes'>Team</span>
              <span>
                <StarRatings
                  rating={this.props.data.team}
                  starRatedColor="#C49733"
                  numberOfStars={10}
                  starDimension={this.state.smallStarSize}
                  starSpacing="0"
                  style={{ margin: '0px' }}
                />
              </span>
              <span>{this.props.data.team.toFixed(1)}</span>
            </div>
            <div style={{ margin: '0px' }} className="ubaidStars justify-content-around">
              <span className='ubaidFontSizes'>Mktg</span>
              <span>
                <StarRatings
                  rating={this.props.data.marketing}
                  starRatedColor="#C49733"
                  numberOfStars={10}
                  starDimension={this.state.smallStarSize}
                  starSpacing="0"
                  style={{ margin: '0px' }}
                />
              </span>
              <span>{this.props.data.marketing.toFixed(1)}</span>
            </div>
            {(this.props.data.additional_review_criteria!== undefined && Object.keys(this.props.data.additional_review_criteria).length>0) &&
              <>
              {Object.keys(this.props.data.additional_review_criteria).map((key)=>(
                <div style={{ margin: '0px' }} className="ubaidStars">
                  <span>
                    <StarRatings
                      rating={this.props.data.additional_review_criteria[key]}
                      starRatedColor="#C49733"
                      numberOfStars={10}
                      starDimension={this.state.smallStarSize}
                      starSpacing="0"
                      style={{ margin: '0px' }}
                    />
                  </span>
                  <span className='ubaidFontSizes'>{key.toUpperCase()}</span>
                </div>
              ))}

              </>
            }
            <br />
            <div className="rating_comment"><p>{this.props.data.comment}</p></div>
          </div>
        </div>


      </div>
    )
  }
  zmediaQuery = (z) => {
    if (z.matches) {
      this.setState({ starSize: '16px', smallStarSize: '13px' })
    } else {
      this.setState({ starSize: '20px', smallStarSize: '15px' })
    }
    this.xmediaQuery();
  }
  xmediaQuery = () => {
    let x = window.matchMedia("(max-width: 1152px)");
    if(x.matches){
      this.setState({starSize: '17px', smallStarSize: '13px'})
    }
  }
  componentDidMount() {
    let z = window.matchMedia("(max-width: 320px)")
    this.zmediaQuery(z);
    z.addListener(this.zmediaQuery)
  }
}

export default Comment
