/* (2019-06-11) - This is the overview tab that displays the general content for
each type of company. It appears on every profile.
*/
import React, { createRef } from "react";
import ReactDOM from "react-dom";

import axios from "axios";
import update from "immutability-helper";
import { SocialIcon } from "react-social-icons";
import {
  Navbar,
  Nav,
  Row,
  Col,
  Card,
  Button,
  ButtonToolbar,
  Container,
  FormLabel,
  FormControl,
} from "react-bootstrap";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import "../../components/profile.css";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Typography from "@material-ui/core/Typography";
import DateFnsUtils from "@date-io/date-fns";
import Grid from "@material-ui/core/Grid";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Icon from "@material-ui/core/Icon";
import { FaGoogle, FaApple } from "react-icons/fa";
import { IoLogoAndroid } from "react-icons/io";

import "./ubaidOverView.css";

import {
  MuiPickersUtilsProvider,
  TimePicker,
  DatePicker,
} from "material-ui-pickers";

import { Helmet } from "react-helmet";
import URL from "url-parse";
import default_pic from "../../../img/default_company_pic.png";
import default_background from "../../../img/bg.png";
import rocket from "../../../img/rocketlaunch.jpg";
import StartupFuel from "../../../img/watermarksffinal.png";
import Loader from "../../components/loader";
import Product_Card from "../../components/product_card";
import Service_Card from "../../components/service_card";
import {
  Profile_Photo_Update,
  Cover_Photo_Update,
  Company_Update,
  Sign_In,
  Register,
} from "../../components/popups";
import {
  country_list,
  opportunity_type_list,
  industry_list,
  stage_list,
  business_type,
  company_type_list,
  years,
  goals,
  investor_goals,
} from "../../../boilerplate/list.json";
import SiteTitle from "../../components/site_title.js";

import { AppContext } from "../../../App";
import Navigation from "../../components/navbar";
import Footer from "./../../components/footer";
import CompanyNavBar from "./nav_bar";
import { compose, withProps, withHandlers, withStateHandlers } from "recompose";
import { FaRegCalendarAlt, FaHandHoldingUsd } from "react-icons/fa";

import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function getStyles(name, that) {
  return {
    fontWeight:
      that.state.name.indexOf(name) === -1
        ? that.props.theme.typography.fontWeightRegular
        : that.props.theme.typography.fontWeightMedium,
  };
}
const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: theme.spacing.unit / 4,
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 100,
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 100,
  },
});
const MyMapComponent = compose(
  withProps({
    /**
     * Note: create and replace your own key in the Google console.
     * https://console.developers.google.com/apis/dashboard
     * The key "AIzaSyBkNaAGLEVq0YLQMi-PYEMabFeREadYe1Q" can be ONLY used in this sandbox (no forked).
     */
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyCyJKxnmJUAgE0jZrhcdKi9X9_e4dJXijc&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)((props) => (
  <GoogleMap
    defaultZoom={8}
    defaultCenter={{ lat: props.lat, lng: props.long }}
  >
    {props.isMarkerShown && (
      <Marker position={{ lat: props.lat, lng: props.long }} />
    )}
  </GoogleMap>
));

const currencies = [
  {
    value: "USD",
    label: "$",
  },
  {
    value: "EUR",
    label: "€",
  },
  {
    value: "BTC",
    label: "฿",
  },
  {
    value: "JPY",
    label: "¥",
  },
];

class CustomizedTable extends React.Component {
  constructor(props) {
    super(props);
    this.descriptionRef = createRef();
    this.foundedRef = createRef();
    this.socialLinksRef = createRef();
    this.productsRef = createRef();
    this.ServicesRef = createRef();
    this.HeadquartersRef = createRef();
    this.ContactRef = createRef();
    this.state = {
      url: new URL(window.location.href, true),
      showEditTeam: false,
    };
  }
  componentDidMount() {
    axios
      .get(`/get_lat_long?company_id=${this.state.url.query.company_id}`)
      .then((response) => {
        this.setState({
          lat: response.data.company.lat[0],
          long: response.data.company.long[0],
        });
      });
  }

  getStage = (value, array) => {
    for (let i = 0; i < array.length; i++) {
      if (array[i].value === value) {
        return array[i].text;
      }
    }
  };

  handleChange = (e) => {
    const name = e.target.name;
    this.setState({
      [name]: e.target.value,
    });
  };

  handleNavigationClick = (section) => {
    if (section === "Description") {
      window.scrollTo({
        top: ReactDOM.findDOMNode(this.descriptionRef.current).offsetTop,
        behavior: "smooth",
      });
    } else if (section === "Founded") {
      window.scrollTo({
        top: ReactDOM.findDOMNode(this.foundedRef.current).offsetTop,
        behavior: "smooth",
      });
    } else if (section === "Social_links") {
      window.scrollTo({
        top: ReactDOM.findDOMNode(this.socialLinksRef.current).offsetTop,
        behavior: "smooth",
      });
    } else if (section === "Products") {
      window.scrollTo({
        top: ReactDOM.findDOMNode(this.productsRef.current).offsetTop,
        behavior: "smooth",
      });
    } else if (section === "Services") {
      window.scrollTo({
        top: ReactDOM.findDOMNode(this.ServicesRef.current).offsetTop,
        behavior: "smooth",
      });
    } else if (section === "Headquarters") {
      window.scrollTo({
        top: ReactDOM.findDOMNode(this.HeadquartersRef.current).offsetTop,
        behavior: "smooth",
      });
    } else if (section === "Contact") {
      window.scrollTo({
        top: ReactDOM.findDOMNode(this.ContactRef.current).offsetTop,
        behavior: "smooth",
      });
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <div>
        <CompanyNavBar
          menuSelection={this.props.menuSelection}
          companies_owned={this.props.companies_owned}
          handleMenuChange={this.props.handleMenuChange}
          company_type={this.props.company_type}
          allow_investor={this.props.allow_investor}
          userIsProfileOwner={this.props.userIsProfileOwner}
          showEditFour={this.props.detailed_description}
          Founded_Date={"Founded_Date"}
          showEditFive={"this.props.showEditFive"}
          company_products={this.props?.company_products}
          company_services={this.props?.company_services}
          formatted_address={this.props.formatted_address}
          contact_phone={this.props.contact_phone.length}
          handleNavigationClick={this.handleNavigationClick}
          companyData={this.props.company_type[0]}
        />
        {this.props.userIsProfileOwner && !this.props.inPreviewMode && (
          <div class="bg-red py-2 my-2 rounded text-white align-middle">
            <span>This section is publicly viewable</span>
          </div>
        )}
        {((this.props.userIsProfileOwner && !this.props.inPreviewMode) ||
          (this.props.detailed_description !== null &&
            this.props.detailed_description.length !== 0)) && (
          <div class="row" ref={this.descriptionRef}>
            <div
              className="bg-white my-2 p-4 profile-card-description-wraper rounded-lg"
              style={{ width: "100%" }}
            >
              <Card.Body className="profile-card-description">
                {this.props.userIsProfileOwner && !this.props.inPreviewMode && (
                  <i
                    style={{ marginTop: "10px" }}
                    onClick={() => this.props.handleEdit("showEditFour")}
                    className={
                      "fa fa-pencil " +
                      (this.props.showEditFour
                        ? "img-card-icon-clicked"
                        : "img-card-icon")
                    }
                  ></i>
                )}
                {!this.props.showEditFour && (
                  <div>
                    <div className="card-title-border text-left">
                      <hr />
                      <h4>Description</h4>
                      <span>{this.props.detailed_description}</span>
                    </div>
                  </div>
                )}
                {this.props.showEditFour && (
                  <div>
                    <Card.Title>
                      Enter a detailed description of what your company does
                    </Card.Title>
                    <TextField
                      id="outlined-multiline-static"
                      multiline
                      style={{ width: "90%" }}
                      rows="4"
                      value={this.props.detailed_description}
                      name="detailed_description"
                      className={classes.textField}
                      onChange={this.props.handleChange}
                      margin="normal"
                      variant="outlined"
                    />
                    <Button
                      variant="contained"
                      name="showEditFour"
                      onClick={this.props.handleInputSubmit}
                      style={{
                        backgroundColor: "#007bff",
                        textAlign: "center",
                        float: "right",
                        marginRight: "100px",
                      }}
                      className={classes.button}
                    >
                      Save
                    </Button>
                  </div>
                )}
              </Card.Body>
            </div>
          </div>
        )}
        <div
          class="mb-2 p-4 text-left bg-white rounded-lg"
          ref={this.foundedRef}
        >
          <div className="card-title-border p-4">
            <hr />
            <h4>Founded Date & Current Goals</h4>
          </div>
          <div className="flex main-wraper-user-profile-box">
            {((this.props.userIsProfileOwner && !this.props.inPreviewMode) ||
              (this.props.date_of_inception !== "" &&
                this.props.date_of_inception !== null)) && (
              <div className="box-date-p-m margin-mobile-01">
                {this.props.userIsProfileOwner && !this.props.inPreviewMode && (
                  <i
                    style={{}}
                    onClick={() => this.props.handleEdit("showEditTwo")}
                    className={
                      "fa fa-pencil " +
                      (this.props.showEditTwo
                        ? "amount-card-icon-clicked"
                        : "amount-card-icon")
                    }
                  ></i>
                )}
                <Card.Body className="">
                  {!this.props.showEditTwo && (
                    <div className="title">
                      <Card.Title>
                        <div className="calender-icon-profile">
                          <FaRegCalendarAlt />
                        </div>
                        <p className="profile-card-text-color">
                          Date of Incorporation
                        </p>
                      </Card.Title>
                      <Card.Title className="amount-month">
                        <p>
                          {this.props.month_of_inception}{" "}
                          {this.props.year_of_inception}
                        </p>
                      </Card.Title>
                    </div>
                  )}
                  {/* {!this.props.showEditTwo && (
                      <Card.Text
                        style={{ textAlign: "center", paddingTop: "5px" }}
                      >
                        {this.props.year_of_inception}
                      </Card.Text>
                    )} */}
                  {this.props.showEditTwo && (
                    <div>
                      <div className="title">
                        <Card.Title>
                          <div className="calender-icon-profile">
                            <FaHandHoldingUsd />
                          </div>
                          <b>Founded</b>
                        </Card.Title>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <DatePicker
                            margin="normal"
                            label="Date picker"
                            name="date_of_inception"
                            value={this.props.date_of_inception}
                            onChange={this.props.handleDateChange}
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                      <Button
                        variant="contained"
                        name="showEditTwo"
                        onClick={this.props.handleInputSubmit}
                        style={{
                          backgroundColor: "#007bff",
                          textAlign: "center",
                          float: "right",
                        }}
                        className={classes.button}
                      >
                        Save
                      </Button>
                    </div>
                  )}
                </Card.Body>
              </div>
            )}
            {((this.props.userIsProfileOwner && !this.props.inPreviewMode) ||
              this.props.looking_for.length !== 0) && (
              <div className="box-date-p-m">
                {this.props.userIsProfileOwner && !this.props.inPreviewMode && (
                  <i
                    style={{}}
                    onClick={() => this.props.handleEdit("showEditThree")}
                    className={
                      "fa fa-pencil " +
                      (this.props.showEditThree
                        ? "amount-card-icon-clicked"
                        : "amount-card-icon")
                    }
                  ></i>
                )}
                <Card.Body className="">
                  {!this.props.showEditThree && (
                    <div className="title">
                      <Card.Title>
                        <div className="calender-icon-profile">
                          <FaHandHoldingUsd />
                        </div>
                        <p className="profile-card-text-color">Current Goal</p>
                      </Card.Title>
                      <Card.Title className="amount-month">
                        <p>{this.props.looking_for}</p>
                      </Card.Title>
                    </div>
                  )}
                  {this.props.showEditThree && (
                    <div>
                      <div className="title">
                        <Card.Title>
                          <b>Current Goal</b>
                        </Card.Title>
                        {(this.props.company_type[0] === "Startup" ||
                          this.props.company_type[0] === "Tech Startup") && (
                          <TextField
                            id="standard-select-currency"
                            select
                            label="Select"
                            className={classes.textField}
                            value={this.props.looking_for}
                            name="looking_for"
                            style={{ width: "200px" }}
                            onChange={this.props.handleChange}
                            SelectProps={{
                              MenuProps: { className: classes.menu },
                            }}
                            helperText="Please select your goal"
                            margin="normal"
                          >
                            {goals.map((option) => (
                              <MenuItem key={option} value={option}>
                                {option}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                        {this.props.company_type[0] !== "Tech Startup" &&
                          this.props.company_type[0] !== "Startup" && (
                            <TextField
                              id="standard-select-currency"
                              select
                              label="Select"
                              className={classes.textField}
                              value={this.props.looking_for}
                              name="looking_for"
                              style={{ width: "200px" }}
                              onChange={this.props.handleChange}
                              SelectProps={{
                                MenuProps: { className: classes.menu },
                              }}
                              helperText="Please select your goal"
                              margin="normal"
                            >
                              {investor_goals.map((option) => (
                                <MenuItem key={option} value={option}>
                                  {option}
                                </MenuItem>
                              ))}
                            </TextField>
                          )}
                      </div>
                      <Button
                        variant="contained"
                        name="showEditThree"
                        onClick={this.props.handleInputSubmit}
                        style={{
                          backgroundColor: "#007bff",
                          textAlign: "center",
                          float: "right",
                        }}
                        className={classes.button}
                      >
                        Save
                      </Button>
                    </div>
                  )}
                </Card.Body>
              </div>
            )}
          </div>
        </div>
        <div
          class="row ubaidRow main-under-des-row-wraper mb-2"
          ref={this.socialLinksRef}
        >
          {((this.props.userIsProfileOwner && !this.props.inPreviewMode) ||
            this.props.company_website.length !== 0) && (
            <div
              style={{ display: "flex", justifyContent: "space-between" }}
              className="ubaidBox main-under-des-box"
            >
              <Card className="month-card" style={{ width: "100%" }}>
                {this.props.userIsProfileOwner && !this.props.inPreviewMode && (
                  <i
                    style={{}}
                    onClick={() => this.props.handleEdit("showEditFive")}
                    className={
                      "fa fa-pencil " +
                      (this.props.showEditFive
                        ? "amount-card-icon-clicked"
                        : "amount-card-icon")
                    }
                  ></i>
                )}
                <Card.Body className="amount-card-body">
                  {!this.props.showEditFive && (
                    <div className="title">
                      <a
                        href={
                          this.props.company_website.includes("http")
                            ? this.props.company_website
                            : `http://${this.props.company_website}`
                        }
                        target="_blank"
                      >
                        {/* <i
                            style={{ color: "#b4b4d5" }}
                            class="fab fa-google fa-5x"
                          ></i> */}
                        <div className="calender-icon-profile">
                          <FaGoogle />
                        </div>
                      </a>
                      <Card.Title>
                        <p className="profile-card-text-color">Website</p>
                      </Card.Title>
                    </div>
                  )}
                  {this.props.showEditFive && (
                    <div>
                      <div className="title">
                        <Card.Title>
                          <b>Please enter your company website</b>
                        </Card.Title>
                        <div className={classes.margin}>
                          <Grid
                            container
                            style={{ width: "100%" }}
                            spacing={8}
                            alignItems="flex-end"
                          >
                            <Grid item>
                              <AccountCircle />
                            </Grid>
                            <Grid item>
                              <TextField
                                id="input-with-icon-grid"
                                name="company_website"
                                style={{ width: "100%" }}
                                value={this.props.company_website}
                                onChange={this.props.handleChange}
                              />
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                      <Button
                        variant="contained"
                        name="showEditFive"
                        onClick={this.props.handleInputSubmit}
                        style={{
                          backgroundColor: "#007bff",
                          textAlign: "center",
                          float: "right",
                        }}
                        className={classes.button}
                      >
                        Save
                      </Button>
                      <Button
                        variant="contained"
                        name="website"
                        onClick={this.props.needsHelp}
                        style={{
                          backgroundColor: "grey",
                          textAlign: "center",
                          padding: ".84rem .9rem ",
                        }}
                        className={classes.button}
                      >
                        Want one?
                      </Button>
                    </div>
                  )}
                </Card.Body>
              </Card>
            </div>
          )}
          {((this.props.userIsProfileOwner && !this.props.inPreviewMode) ||
            this.props.iOS_app_url.length !== 0) && (
            <div
              style={{ display: "flex", justifyContent: "space-between" }}
              className="main-under-des-box margin-mobile"
            >
              <Card className="month-card" style={{ width: "100%" }}>
                {this.props.userIsProfileOwner && !this.props.inPreviewMode && (
                  <i
                    style={{}}
                    onClick={() => this.props.handleEdit("showEditSix")}
                    className={
                      "fa fa-pencil " +
                      (this.props.showEditSix
                        ? "amount-card-icon-clicked"
                        : "amount-card-icon")
                    }
                  ></i>
                )}
                <Card.Body className="amount-card-body">
                  {!this.props.showEditSix && (
                    <div className="title">
                      <a href={"http://" + this.props.iOS_app_url}>
                        {/* <i
                            style={{ color: "#b4b4d5" }}
                            class="fab fa-apple fa-5x"
                          ></i> */}
                        <div className="calender-icon-profile">
                          <FaApple />
                        </div>
                      </a>
                      <Card.Title>
                        <p className="profile-card-text-color">
                          iOS Application
                        </p>
                      </Card.Title>
                    </div>
                  )}
                  {this.props.showEditSix && (
                    <div>
                      <div className="title">
                        <Card.Title>
                          <b>iOS App URL</b>
                        </Card.Title>
                        <Grid
                          container
                          style={{ width: "100%" }}
                          spacing={8}
                          alignItems="flex-end"
                        >
                          <Grid item>
                            <AccountCircle />
                          </Grid>
                          <Grid item>
                            <TextField
                              id="input-with-icon-grid"
                              name="iOS_app_url"
                              style={{ width: "100%" }}
                              value={this.props.iOS_app_url}
                              onChange={this.props.handleChange}
                            />
                          </Grid>
                        </Grid>
                      </div>
                      <Button
                        variant="contained"
                        name="showEditSix"
                        onClick={this.props.handleInputSubmit}
                        style={{
                          backgroundColor: "#007bff",
                          textAlign: "center",
                          float: "right",
                        }}
                        className={classes.button}
                      >
                        Save
                      </Button>
                      <Button
                        variant="contained"
                        name="iOS App"
                        onClick={this.props.needsHelp}
                        style={{
                          backgroundColor: "grey",
                          textAlign: "center",
                          padding: ".84rem .9rem ",
                        }}
                        className={classes.button}
                      >
                        Want one?
                      </Button>
                    </div>
                  )}
                </Card.Body>
              </Card>
            </div>
          )}
          {((this.props.userIsProfileOwner && !this.props.inPreviewMode) ||
            this.props.android_app_url.length !== 0) && (
            <div
              style={{ display: "flex", justifyContent: "space-between" }}
              className="main-under-des-box"
            >
              <Card className="month-card" style={{ width: "100%" }}>
                {this.props.userIsProfileOwner && !this.props.inPreviewMode && (
                  <i
                    style={{}}
                    onClick={() => this.props.handleEdit("showEditSeven")}
                    className={
                      "fa fa-pencil " +
                      (this.props.showEditSeven
                        ? "amount-card-icon-clicked"
                        : "amount-card-icon")
                    }
                  ></i>
                )}
                <Card.Body className="amount-card-body">
                  {!this.props.showEditSeven && (
                    <div className="title">
                      <a href={"http://" + this.props.android_app_url}>
                        {/* <i
                            style={{ color: "#b4b4d5" }}
                            class="fab fa-android fa-5x"
                          ></i> */}
                        <div className="calender-icon-profile">
                          <IoLogoAndroid />
                        </div>
                      </a>
                      <Card.Title>
                        <p className="profile-card-text-color">Android App</p>
                      </Card.Title>
                    </div>
                  )}
                  {this.props.showEditSeven && (
                    <div>
                      <div className="title">
                        <Card.Title>
                          <b>Android App URL</b>
                        </Card.Title>
                        <Grid
                          container
                          style={{ width: "100%" }}
                          spacing={8}
                          alignItems="flex-end"
                        >
                          <Grid item>
                            <AccountCircle />
                          </Grid>
                          <Grid item>
                            <TextField
                              id="input-with-icon-grid"
                              name="android_app_url"
                              style={{ width: "100%" }}
                              value={this.props.android_app_url}
                              onChange={this.props.handleChange}
                            />
                          </Grid>
                        </Grid>
                      </div>
                      <Button
                        variant="contained"
                        name="showEditSeven"
                        onClick={this.props.handleInputSubmit}
                        style={{
                          backgroundColor: "#007bff",
                          textAlign: "center",
                          float: "right",
                        }}
                        className={classes.button}
                      >
                        Save
                      </Button>
                      <Button
                        variant="contained"
                        name="android app"
                        onClick={this.props.needsHelp}
                        style={{
                          backgroundColor: "grey",
                          textAlign: "center",
                          padding: ".84rem .9rem ",
                        }}
                        className={classes.button}
                      >
                        Want one?
                      </Button>
                    </div>
                  )}
                </Card.Body>
              </Card>
            </div>
          )}
        </div>
        {this.props.group_list.length > 0 ? (
          <div class="card my-3">
            <h2 class="card-title ml-3 mt-3 lead">Groups:</h2>
            <div class="card-body row">
              {this.props.group_list.map((group) => (
                <div class="mx-2 col text-center">
                  <a href={"/feed?group_id=" + group._id}>
                    <img
                      class="rounded-circle"
                      width="45"
                      src={this.getAvatarUrl(group.group_file)}
                      alt=""
                    />{" "}
                    <p> {group.name} </p>
                  </a>
                </div>
              ))}
            </div>
          </div>
        ) : null}

        {(this.props.company_type[0] === "Startup" ||
          this.props.company_type[0] === "Tech Startup") &&
          (this.props.userIsProfileOwner ||
            this.props.company_products.length > 0) && (
            <div ref={this.productsRef}>
              <div className="products-card bg-white rounded-lg mb-2 p-5">
                <div className="card-title-border">
                  <hr />
                  <h3 class="text-left">Products</h3>
                  {this.props.userIsProfileOwner &&
                    !this.props.inPreviewMode && (
                      <i
                        style={{ margin: "-44px -16px 0 0" }}
                        onClick={(event) =>
                          this.props.toggleCreateProductPopup(event)
                        }
                        className={
                          "fa fa-plus " +
                          (this.props.showEditFour
                            ? "img-card-icon-clicked"
                            : "img-card-icon")
                        }
                      ></i>
                    )}
                </div>

                <Row>
                  <div style={{ width: "100%", textAlign: "center" }}>
                    {this.props.company_products.map((product) => (
                      <Col sm={12} md={6} lg={4} className="ubaidCardCol">
                        <Card.Body className="video-card-body">
                          <Card.Text className="ubaidProductCardText">
                            <Product_Card
                              handleEditProduct={this.props.handleEditProduct}
                              handleDeleteProduct={
                                this.props.handleDeleteProduct
                              }
                              toggleCreateProductPopup={
                                this.props.toggleCreateProductPopup
                              }
                              getStage={this.getStage}
                              getAvatarUrl={this.props.getAvatarUrl}
                              product={product}
                              {...this.props}
                            />
                          </Card.Text>
                        </Card.Body>
                      </Col>
                    ))}
                  </div>
                </Row>
              </div>
            </div>
          )}
        {this.props.company_type[0] === "Service Provider" &&
          (this.props.userIsProfileOwner ||
            this.props.company_services.length > 0) && (
            <div style={{ margin: "24px 0px 0 0px" }} ref={this.ServicesRef}>
              <Card
                className="video-card"
                style={{ padding: "16px 16px 4px 16px" }}
              >
                <div>
                  <Card.Title
                    style={{ margin: "0 0 12px 0", textAlign: "left" }}
                  >
                    Services
                  </Card.Title>
                  {this.props.userIsProfileOwner &&
                    !this.props.inPreviewMode && (
                      <i
                        style={{ margin: "-44px -16px 0 0" }}
                        onClick={(event) =>
                          this.props.toggleCreateServicePopup(event)
                        }
                        className={
                          "fa fa-plus " +
                          (this.props.showEditFour
                            ? "img-card-icon-clicked"
                            : "img-card-icon")
                        }
                      ></i>
                    )}
                </div>
                <Row>
                  <div style={{ width: "100%", textAlign: "center" }}>
                    {this.props.company_services.map((service) => (
                      <Col sm={12} md={6} lg={4} className="ubaidCardCol">
                        <Card.Body
                          className="video-card-body"
                          style={{ margin: 0, padding: 0, textAlign: "left" }}
                        >
                          <Card.Text className="ubaidProductCardText">
                            <Service_Card
                              handleEditService={this.props.handleEditService}
                              handleDeleteService={
                                this.props.handleDeleteService
                              }
                              toggleCreateServicePopup={
                                this.props.toggleCreateServicePopup
                              }
                              getStage={this.getStage}
                              getAvatarUrl={this.props.getAvatarUrl}
                              service={service}
                              {...this.props}
                            />
                          </Card.Text>
                        </Card.Body>
                      </Col>
                    ))}
                  </div>
                </Row>
              </Card>
            </div>
          )}
        {this.props.team_list.length > 0 && (
          <div class="row">
            <Col>
              <Card
                className="video-card contact-information"
                style={{ width: "100%" }}
              >
                <Card.Body className="video-card-body">
                  {this.props.userIsProfileOwner &&
                    !this.props.inPreviewMode && (
                      <i
                        style={{ marginTop: "-10px" }}
                        onClick={() => this.props.handleEdit("showEditEight")}
                        className={
                          "fa fa-plus " +
                          (this.props.showEditEight
                            ? "img-card-icon-clicked"
                            : "img-card-icon")
                        }
                      ></i>
                    )}
                  {!this.props.showEditEight && (
                    <div>
                      <Card.Title>Team</Card.Title>
                      <div class="card-body row">
                        {this.props.team_list.map((user) => (
                          <div class="mx-2 col text-center">
                            {/* <img
                            class="rounded-circle"
                            width="45"
                            src={this.props.getAvatarUrl(user.user_file, "user avatar")}
                            alt="" /> */}
                            <div
                              style={{
                                backgroundImage:
                                  "url(" +
                                  this.props.getAvatarUrl(
                                    user.user_file,
                                    "user avatar"
                                  ) +
                                  ")",
                                backgroundSize: "cover",
                                width: "48px",
                                height: "48px",
                                borderRadius: "26px",
                                margin: "auto",
                              }}
                            ></div>
                            <p>
                              {user.first_name} {user.last_name}
                            </p>
                            {this.props.showEditTeam === false ? (
                              <p>
                                {user.user_company_role === undefined ||
                                user.user_company_role === null
                                  ? "CEO"
                                  : user.user_company_role}
                              </p>
                            ) : (
                              <div>
                                <TextField
                                  id="outlined-multiline-static"
                                  style={{ width: "90%" }}
                                  value={this.props.team_list.user_company_role}
                                  name="user_company_role"
                                  className={classes.textField}
                                  onChange={this.props.handleMainTeamChange}
                                  placeholder="Enter job title"
                                  margin="normal"
                                  variant="outlined"
                                />
                                <Button
                                  variant="contained"
                                  name="showEditNine"
                                  onClick={this.props.handleMainTeamSubmit}
                                  style={{
                                    backgroundColor: "#007bff",
                                    textAlign: "center",
                                    float: "right",
                                  }}
                                  className={classes.button}
                                >
                                  Save
                                </Button>
                              </div>
                            )}
                            {this.props.userIsProfileOwner && (
                              <i
                                className={"fa fa-pencil "}
                                onClick={(event) =>
                                  this.props.handleToggleEdit(
                                    event,
                                    "showEditTeam"
                                  )
                                }
                              ></i>
                            )}
                          </div>
                        ))}
                        {this.props.manual_team_list.map((user, index) => (
                          <div class="mx-2 col text-center">
                            {/* <img
                            class="rounded-circle"
                            width="45"
                            src={this.props.getAvatarUrl([user.teamMemberProfilePictureDetails], "company team member")}
                            alt="" /> */}
                            <div
                              style={{
                                width: "45px",
                                height: "45px",
                                margin: "auto",
                                overflow: "hidden",
                                borderRadius: "30px",
                              }}
                            >
                              <img
                                width="45"
                                src={this.props.getAvatarUrl(
                                  [user.teamMemberProfilePictureDetails],
                                  "company team member"
                                )}
                                alt=""
                              />
                            </div>
                            {this.props.showEditTeamMember !== index ? (
                              <div>
                                <p>{user.name}</p>
                                <p>{user.title}</p>
                              </div>
                            ) : (
                              <div>
                                <TextField
                                  id="outlined-multiline-static"
                                  style={{ width: "90%" }}
                                  value={
                                    this.props.manual_team_list[index].name
                                  }
                                  name="name"
                                  className={classes.textField}
                                  onChange={(event) =>
                                    this.props.handleManualTeamChange(
                                      event,
                                      index,
                                      "name"
                                    )
                                  }
                                  placeholder="Enter name."
                                  margin="normal"
                                  variant="outlined"
                                />
                                <TextField
                                  id="outlined-multiline-static"
                                  style={{ width: "90%" }}
                                  value={
                                    this.props.manual_team_list[index].title
                                  }
                                  name="last_name"
                                  className={classes.textField}
                                  onChange={(event) =>
                                    this.props.handleManualTeamChange(
                                      event,
                                      index,
                                      "title"
                                    )
                                  }
                                  placeholder="Enter job title."
                                  margin="normal"
                                  variant="outlined"
                                />
                                <Button
                                  variant="contained"
                                  name="showEditNine"
                                  onClick={(event) =>
                                    this.props.handleTeamEditSubmit(event)
                                  }
                                  style={{
                                    backgroundColor: "#007bff",
                                    textAlign: "center",
                                    float: "right",
                                  }}
                                  className={classes.button}
                                >
                                  Save
                                </Button>
                              </div>
                            )}
                            {this.props.userIsProfileOwner && (
                              <div>
                                <i
                                  className={"fa fa-pencil "}
                                  onClick={(event) =>
                                    this.props.handleToggleEditTeam(
                                      event,
                                      index
                                    )
                                  }
                                ></i>
                                <i
                                  onClick={(event) =>
                                    this.props.handleTeamDelete(event, index)
                                  }
                                  className={"fa fa-trash "}
                                ></i>
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  {this.props.showEditEight && (
                    <div>
                      <Card.Title>Add New Team Member</Card.Title>
                      <div className="form-gorup">
                        <FormLabel className="control-label">
                          Profile Photo
                        </FormLabel>
                        <FormControl
                          type="file"
                          accept=".gif,.jpg,.jpeg,.png"
                          data-file-type="team member"
                          placeholder="Profile Photo"
                          name="team member"
                          onChange={this.props.handleFileUpload}
                          className="form-control mb-3"
                        />
                      </div>
                      <TextField
                        label="Name"
                        id="outlined-multiline-static"
                        style={{ width: "90%" }}
                        rows="4"
                        value={this.props.new_team_member.name}
                        name="name"
                        className={classes.textField}
                        onChange={this.props.handleTeamChange}
                        margin="normal"
                        variant="outlined"
                      />
                      <TextField
                        label="Title"
                        id="outlined-multiline-static"
                        style={{ width: "90%" }}
                        rows="4"
                        value={this.props.new_team_member.title}
                        name="title"
                        className={classes.textField}
                        onChange={this.props.handleTeamChange}
                        margin="normal"
                        variant="outlined"
                      />
                      <TextField
                        label="linkedIn"
                        id="outlined-multiline-static"
                        style={{ width: "90%" }}
                        rows="4"
                        value={this.props.new_team_member.linkedIn}
                        name="linkedIn"
                        className={classes.textField}
                        onChange={this.props.handleTeamChange}
                        margin="normal"
                        variant="outlined"
                      />
                      <Button
                        variant="contained"
                        name="showEditEight"
                        onClick={this.props.saveTeamChange}
                        style={{
                          backgroundColor: "#007bff",
                          textAlign: "center",
                          float: "right",
                        }}
                        className={classes.button}
                      >
                        Save
                      </Button>
                    </div>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </div>
        )}
        {this.state.lat !== undefined &&
          this.state.long !== undefined &&
          this.state.lat !== 0 &&
          this.state.long !== 0 &&
          this.props.formatted_address !== "" && (
            <div class="row mb-2" ref={this.HeadquartersRef}>
              <div
                className="rounded-lg bg-white text-left company-headq-main-wraper"
                style={{ width: "100%" }}
              >
                <div className="card-title-border px-5 py-5">
                  <hr />
                  <h4>Company Headquarters</h4>
                </div>
                <div class="">
                  <div style={{ position: "relative", height: "100%" }}>
                    <MyMapComponent
                      isMarkerShown={true}
                      lat={this.state.lat}
                      long={this.state.long}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        {((this.props.userIsProfileOwner && !this.props.inPreviewMode) ||
          (this.props.contact_email.length !== 0 &&
            this.props.contact_phone.length !== 0)) && (
          <div class="row">
            <div
              className="bg-white rounded-lg p-4 contact-information"
              style={{ width: "100%" }}
            >
              <div className="">
                {this.props.userIsProfileOwner && !this.props.inPreviewMode && (
                  <i
                    style={{ marginTop: "10px" }}
                    onClick={() => this.props.handleEdit("showEditNine")}
                    className={
                      "fa fa-pencil " +
                      (this.props.showEditNine
                        ? "img-card-icon-clicked"
                        : "img-card-icon")
                    }
                  ></i>
                )}
                {!this.props.showEditNine && this.props.isLoggedIn ? (
                  <div className="text-left" ref={this.ContactRef}>
                    <div className="card-title-border p-4">
                      <hr />
                      <h4>Contact</h4>
                    </div>
                    <div className="px-4">
                      {this.props.contact_email.length !== 0 && (
                        <p style={{ marginBottom: 0 }}>
                          Email:
                          {this.props.contact_email}
                        </p>
                      )}

                      {this.props.contact_phone.length !== 0 && (
                        <p style={{ margin: 0 }}>
                          Phone #:
                          {this.props.contact_phone}
                        </p>
                      )}
                    </div>
                  </div>
                ) : (
                  <>
                    <Card.Title>Contact Information</Card.Title>
                    <Card.Text>Must log in to view information.</Card.Text>
                  </>
                )}
                {this.props.showEditNine && (
                  <div>
                    <Card.Title>Update your contact information</Card.Title>
                    <TextField
                      id="outlined-multiline-static"
                      style={{ width: "90%" }}
                      value={this.props.contact_email}
                      name="contact_email"
                      className={classes.textField}
                      onChange={this.props.handleChange}
                      placeholder="Enter your company email"
                      margin="normal"
                      variant="outlined"
                    />
                    <TextField
                      id="outlined-multiline-static"
                      style={{ width: "90%" }}
                      value={this.props.contact_phone}
                      name="contact_phone"
                      placeholder="Enter your company phone number"
                      className={classes.textField}
                      onChange={this.props.handleChange}
                      margin="normal"
                      variant="outlined"
                    />
                    <Button
                      variant="contained"
                      name="showEditNine"
                      onClick={this.props.handleInputSubmit}
                      style={{
                        backgroundColor: "#007bff",
                        textAlign: "center",
                        float: "right",
                      }}
                      className={classes.button}
                    >
                      Save
                    </Button>
                    {/*
                                                                                                                                                                                                                                                                                                                                                      <Button variant="contained" onClick={() => this.props.handleEdit('showEditNine')} style={{backgroundColor: '#ca3a64',textAlign:'center'}} className={classes.button}>
                                                                                                                                                                                                                                                                                                                                                        Close
                                                                                                                                                                                                                                                                                                                                                      </Button>
                                                                                                                                                                                                                                                                                                                                                      */}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
CustomizedTable.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(CustomizedTable);
