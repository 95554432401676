import React from "react";
import "./submit_opportunity.css";

// Function to determine the background color for the circles based on the active step
const getBackgroundColor = (step, current) => {
  return step === current ? "#1f2937" : "#e5e7eb"; // Dark background for active, light for inactive
};

// Function to determine the text color for the numbers inside the circles
const getNumberColor = (step, current) => {
  return step === current ? "#f9fafb" : "#374151"; // White for active, dark for inactive
};

// Function to determine the text color for the labels next to the circles
const getLabelColor = (step, current) => {
  return step === current ? "#1f2937" : "#374151"; // Darker for active, normal dark for inactive
};

function OpportunitySubmissionHeader({ activeStep }) {
  const totalSteps = 4;
  const progress = (activeStep / totalSteps) * 100;

  return (
    <header className="header-container">
      <div className="header-content">
        <div className="step-container">
          {[1, 2, 3, 4].map((step) => (
            <React.Fragment key={step}>
              <div
                className="step"
                style={{
                  backgroundColor: getBackgroundColor(step, activeStep),
                  color: getNumberColor(step, activeStep),
                }}
              >
                {step}
              </div>
              <span
                className="step-label"
                style={{
                  color: getLabelColor(step, activeStep),
                }}
              >
                {step === 1
                  ? "Basic Info"
                  : step === 2
                    ? "Upload Documents"
                    : step === 3
                      ? "Company Info"
                      : "Application"}
              </span>
            </React.Fragment>
          ))}
        </div>
        <div className="progress-bar-submission">
          <div
            className="progress-bar-fill"
            style={{
              width: `${progress}%`,
            }}
          ></div>
        </div>
      </div>
    </header>
  );
}

export default OpportunitySubmissionHeader;
