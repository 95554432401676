import React from 'react';

import '../test_mj-dev/sass/sass-css/_terms_conditions.scss';
import Navbar from './components/navbar';
import SideMenu from './SideMenu';
import './terms_conditionsUbaid.css'



const TermsConditionsPage = props => {

	return (
		<React.Fragment>
			<Navbar />
			<SideMenu />
			<div className="termsConditionsPage">


				<div className="termsConditionsPage__title"><h1><b>Terms & Conditions</b></h1></div>

				<code><strong>Terms and Conditions of Use</strong></code>

				&nbsp;
					Pangea Firm Inc., operating as StartupFuel, a corporation registered in the Province of Ontario, Canada, headquartered in Toronto, Canada (“<strong>StartupFuel</strong>”) operates each website that links to these Terms of Use together with our applications, APIs, and online services (collectively, the “<strong>Site</strong>”), as well as our other services, offerings, and other interactions (e.g., customer service inquiries etc.) you may have with us (together with our Site, collectively referred to as our “<strong>Services</strong>”). By accessing or using our Services, you are agreeing to these terms and conditions (the “<strong>Terms</strong>”) and concluding a legally binding contract with StartupFuel. Do not access or use our Services if you are unwilling or unable to be bound by the Terms.
				<ol>
					<li><strong> Definitions</strong></li>
					<li><strong> Parties</strong></li>
				</ol>
				<strong>“You”</strong> and <strong>“your”</strong> refer to you, as a user of our Services. A <strong>“user”</strong> is someone who accesses, posts, edits, browses, or in any way uses our Services.

				<strong>“We,”</strong> <strong>“us,”</strong> and <strong>“our”</strong> refer to StartupFuel.
				<ol>
					<li><strong> Content</strong></li>
				</ol>
				<strong>“Content”</strong> means text, images, photos, audio, video, documents and all other forms of data or communication.

				<strong>“Your Content”</strong> means Content that you submit or transmit to, through, or in connection with our Services, such as posts, comments, invitations, messages, and information that you publicly display.

				<strong>“User Content”</strong> means Content that users submit or transmit to, through, or in connection with our Services.

				<strong>“StartupFuel Content”</strong> means Content that we create and make available in connection with our Services.

				<strong>“Third Party Content”</strong> means Content that originates from parties other than StartupFuel or its users, which is made available in connection with our Services.

				<strong>“Site Content”</strong> means all of the Content that is made available in connection with our Services, including Your Content, User Content, Third Party Content, and StartupFuel Content.

								&nbsp;

								&nbsp;
				<ol start="2">
					<li><strong> Changes to Terms of Use</strong></li>
				</ol>
				We may modify these Terms from time to time. You understand and agree that your access to or use of our Services is governed by the Terms effective at the time of your access to or use of our Services. If we make material changes to these Terms, we will notify you by email or by posting a revised version of the Terms on our Site. We will also indicate at the top of this page the date that revisions were last made. You should revisit these Terms on a regular basis as revised versions will be binding on you. <strong>You understand and agree that your continued access to or use of our Services after the effective date of modifications to the Terms indicates your acceptance of the modifications.</strong>
				<ol start="3">
					<li><strong> Using our Services</strong></li>
					<li><strong> Eligibility</strong></li>
				</ol>
				To access or use our Services, you must be 18 years or older and have the requisite power and authority to enter into these Terms. You may not access or use our Services if we have previously banned you from our Site or closed your account.
<ol>
					<li><strong> Permission to Use the Site</strong></li>
				</ol>
				We grant you permission to use our Services subject to the restrictions in these Terms. Your use of our Services is at your own risk.
<ol>
					<li><strong> Site Availability</strong></li>
				</ol>
				Our Services may be modified, updated, interrupted, suspended or discontinued at any time without notice or liability.
<ol>
					<li><strong> User Accounts</strong></li>
				</ol>
				You must create an account and provide certain information about yourself in order to use some of the features that are offered through our Services. You are responsible for maintaining the confidentiality of your account password. You are also responsible for all activities that occur in connection with your account. You agree to notify us immediately of any unauthorized use of your account. We reserve the right to close your account at any time for any or no reason.
<ol>
					<li><strong> Communications from StartupFuel and other Users</strong></li>
				</ol>
				By creating an account, you agree to receive certain communications in connection with your use of our Services. For example, you might receive questions or requests from other Users related to information you post on using our Services. If you have subscribed to them, you also will receive reading lists and email newsletters about events and business connection opportunities on the Website or in certain geographic areas. We provide unsubscribe links in all of our email footers if you no longer wish to receive those communications from us.
<ol>
					<li><strong> User Profile</strong></li>
				</ol>
				If you have applied and been accepted to create a Business Profile, you may utilize our Services to post materials, express interest in businesses, and communicate with other Users on the Website in compliance with the Terms and during the period stated in your acceptance. As a User, you may use the StartupFuel trademarks and service marks in connection with your use of our Services so long as you comply with the guidelines for its use that we may provide to you, as may be amended from time to time. You acknowledge and agree that how you utilize our Services as a User impacts our brand. Accordingly, you agree not to utilize our Services in a manner that diminishes and/or tarnishes our image and/or reputation, and that we may terminate your User Profile and/or license to use our Services immediately if you do so. You may not receive compensation or favour from third parties in connection with your status as a User. In addition, you may not enter into any form of agreement on behalf of StartupFuel without our written approval.
<ol start="4">
					<li><strong> Content</strong></li>
					<li><strong> Content Guidelines</strong></li>
				</ol>
				The purpose of our Services is to promote a thriving entrepreneurial ecosystem by introducing investors to startups. You confirm that the content provided on the Website is true and accurate. You also commit to maintain an updated User Profile, that represents the most up-to-date set of content related to your User Profile.
<ol>
					<li><strong> Responsibility for Your Content</strong></li>
				</ol>
				You alone are responsible for Your Content and assume all risks associated with Your Content. You represent that you own, or have the necessary permissions to use and authorize the use of Your Content as described herein.

				You may expose yourself to liability if, for example, (i) Your Content contains material that is false, intentionally misleading, or defamatory; (ii) violates any third-party right, including any copyright, trademark, patent, trade secret, moral right, privacy right, right of publicity, or any other intellectual property or proprietary right; (iii) contains material that is unlawful, including illegal hate speech or pornography; (iv) exploits or otherwise harms minors; or (v) violates or advocates the violation of any law or regulation.

				&nbsp;
<ol>
					<li><strong>Our Right to Use Your Content</strong></li>
				</ol>
				We may use Your Content in a number of different ways, including publicly displaying it, reformatting it, incorporating it into other works, creating derivative works from it, promoting it, distributing it, and allowing others to do the same in connection with their own websites and media platforms (<strong>“Other Media”</strong>). As such, you hereby irrevocably grant us world-wide, perpetual, non-exclusive, royalty-free, assignable, sublicensable, transferable rights to use Your Content for any purpose. Please note that you also irrevocably grant the users of our Services and any Other Media the right to access Your Content in connection with their use of our Services and any Other Media. Finally, you irrevocably waive, and cause to be waived, against StartupFuel and its users any claims and assertions of moral rights or attribution with respect to Your Content. By “use” we mean use, copy, publicly perform and display, reproduce, distribute, modify, translate, remove, analyze, commercialize, and prepare derivative works of Your Content.
<ol>
					<li><strong> Ownership</strong></li>
				</ol>
				As between you and StartupFuel, you own Your Content. We own the StartupFuel Content, including but not limited to the visual interfaces, interactive features, graphics, design and all other elements and components of our Services excluding Your Content, User Content, and Third Party Content. We also own the copyrights, trademarks, service marks, trade names, and other intellectual and proprietary rights throughout the world (<strong>“IP Rights”</strong>) associated with the StartupFuel Content and our Services, which are protected by copyright, trade dress, patent, trademark laws and all other applicable intellectual and proprietary rights and laws. As such, you may not modify, reproduce, distribute, create derivative works or adaptations of, publicly display or in any way exploit any of the StartupFuel Content in whole or in part except as expressly authorized by us. Except as expressly and unambiguously provided herein, we do not grant you any express or implied rights, and all rights in and to our Services and the StartupFuel Content are retained by us.
<ol>
					<li><strong> Other</strong></li>
				</ol>
				We reserve the right to remove, screen, edit, or reinstate User Content from time to time at our sole discretion for any reason or no reason, and without notice to you. How your User Content is managed is governed by the StartupFuel Privacy Policy.
<ol start="5">
					<li><strong> Restrictions</strong></li>
					<li><strong> You agree not to, and will not assist, encourage, or enable others to use our Services to:</strong></li>
				</ol>
				<ul>
					<li>Violate our Content or Event Guidelines as set forth in these Terms</li>
					<li>Violate any third party’s rights, including any breach of confidence, copyright, trademark, patent, trade secret, moral right, privacy right, right of publicity, or any other intellectual property or proprietary right</li>
					<li>Threaten, stalk, harm, or harass others, or promote bigotry or discrimination</li>
					<li>Send bulk emails, surveys, or other mass messaging, whether commercial in nature or not</li>
					<li>Violate any applicable law</li>
				</ul>
				<ol>
					<li><strong> You also agree not to, and will not assist, encourage, or enable others to:</strong></li>
				</ol>
				<ul>
					<li>Violate the Terms</li>
					<li>Modify, adapt, appropriate, reproduce, distribute, translate, create derivative works or adaptations of, publicly display, sell, trade, or in any way exploit our Services or Site Content (other than Your Content), except as expressly authorized by StartupFuel</li>
					<li>Reverse engineer any portion of our Services</li>
					<li>Remove or modify any copyright, trademark or other proprietary rights notice that appears on any portion of our Services or on any materials printed or copied from our Services</li>
					<li>Record, process, or mine information about other companies and/or users</li>
					<li>Reformat or frame any portion of our Services</li>
					<li>Attempt to gain unauthorized access to our Services, user accounts, computer systems or networks connected to our Services through hacking, password mining or any other means</li>
					<li>Use our Services or any Site Content to transmit any computer viruses, worms, defects, Trojan horses or other items of a destructive nature (collectively, <strong>“Viruses”</strong>)</li>
					<li>Use any device, software or routine that interferes with the proper working of our Services, or otherwise attempt to interfere with the proper working of our Services</li>
					<li>Use our Services to violate the security of any computer network, crack passwords or security encryption codes; disrupt or interfere with the security of, or otherwise cause harm to, our Services or Site Content</li>
					<li>Remove, circumvent, disable, damage or otherwise interfere with any security-related features of our Services, features that prevent or restrict the use or copying of Site Content, or features that enforce limitations on the use of our Services</li>
				</ul>
				&nbsp;
<ol start="6">
					<li><strong> Privacy, Copyright/Trademark Disputes and Confidentiality</strong></li>
					<li><strong> Privacy</strong></li>
				</ol>
				Your use of our Services is subject to StartupFuel’ Privacy Policy available here

				&nbsp;
<ol>
					<li><strong> Copyright and Trademark Disputes</strong></li>
				</ol>
				If you believe that your copyright or trademark is being infringed through our Services, please send us a written notice with the following information:
<ul>
					<li>Identification of the copyrighted or trademarked work that you claim has been infringed;</li>
					<li>Identification of the allegedly infringing content, and information reasonably sufficient to permit StartupFuel to locate it on our Site (e.g., the URL for the web page on which the content appears);</li>
					<li>A statement by you that you have a good faith belief that the use of the content identified in your notice in the manner complained of is not authorized by the copyright/trademark owner, its agent, or the law;</li>
					<li>A statement by you that you attest, under penalty of perjury, that the information in your notice is accurate and that you are the copyright/trademark owner or authorized to act on the owner’s behalf; and</li>
					<li>Your physical or electronic signature, together with your contact information (address, telephone number and, if available, email address).</li>
				</ul>
				If you believe that your content should not have been removed for alleged copyright or trademark infringement, you may send us a written counter-notice with the following information:
<ul>
					<li>Identification of the copyrighted or trademarked work that was removed, and the location within our Services where it would have been found prior to its removal;</li>
					<li>A statement, under penalty of perjury, that you have a good faith belief that the content was removed as a result of a mistake or misidentification. For trademark disputes only: information reasonably sufficient to explain why you believe you are not infringing the trademarked work;</li>
					<li>A statement that you consent either to the jurisdiction of (a) the Federal Courts of Canada if you live in the Canada, or (b) any judicial district in which StartupFuel is located if you live outside Canada. Please also include a statement that you will accept service of process from the person who sent the original infringement notice to StartupFuel, or an agent of such person;</li>
					<li>Your physical or electronic signature, together with your contact information (address, telephone number and, if available, email address).</li>
				</ul>
				We will respond to all such notices and comply with applicable law. We reserve the right to remove content alleged to be infringing without prior notice and at our sole discretion. We also reserve the right to terminate a user’s account if the user is determined to be a repeat infringer.

				You can send us your copyright or trademark notices to:

StartupFuel: 413 Brimorton Road, Toronto, ON, M1H 2E4 or <a href="mailto:terms@startupfuel.com">terms@startupfuel.com</a>

				&nbsp;
<ol>
					<li><strong> Mutual Non-Disclosure and Non-Solicitation</strong></li>
				</ol>
				For purposes of this section (C), the following terms have the following meanings:

				Definition of Parties:

				Disclosing Party – A Party or Parties that utilizes the Website to send Continental Information during the course of permitted use.

				Recipient Party – A Party of Parties that utilizes the Website to receive Confidential Information during the course of permitted use.

				"Confidential Information" means all information, data, documents, agreements, files and other materials regarding or concerning the Disclosing Party or its affiliates, whether disclosed orally or disclosed or stored in written, electronic or other form or media, which is disclosed or otherwise furnished by the Disclosing Party or its Representatives to the Recipient or its Representatives before, on or after the date hereof, including all analyses, notes, compilations, reports, forecasts, studies, samples, statistics, summaries, algorithms, interpretations and other documents prepared by or for the Recipient or its Representatives which contain or otherwise reflect or are generated from such information, data, documents, agreements, files or other materials, whether or not marked, designated or otherwise identified as "confidential," including, without limitation:
<ul>
					<li>information concerning the Disclosing Party's and its affiliates' and their customers', suppliers' and other third parties', past, present and future business affairs including, without limitation, finances, supplier information, services, customer information, Employee information, products, services, organizational structure and internal practices, human resources, geographic locations, forecasts, sales and other financial results, records and budgets, and business, marketing, development, sales and other commercial strategies;</li>
					<li>unpatented inventions, ideas, methods and discoveries, trade secrets, know-how, software programs, videos, databases, unpublished patent applications and any and all other intellectual property;</li>
					<li>designs, specifications, documentation, components, source code, object code, computer chips, images, icons, audiovisual components and objects, schematics, drawings, protocols, processes, and other visual depictions, in whole or in part, of any of the items listed in this section;</li>
					<li>third-party information (including, without limitation, any Personal Information as defined below) included with, or incorporated in, any information provided by the Disclosing Party to the Recipient or its Representatives; and</li>
					<li>other information that would reasonably be considered non-public, confidential or proprietary given the nature of the information and the Disclosing Party's business.</li>
				</ul>
				The term "Confidential Information" as used herein shall not include information that:
<ul>
					<li>is or becomes generally available to and known by the public (other than as a result of its disclosure directly or indirectly by the Recipient or its Representatives in violation of this Agreement);</li>
					<li>is or becomes available to the Recipient from a source other than the Disclosing Party or its Representatives, provided that such source, to the Recipient's knowledge after reasonable inquiry, was not and is not bound by a confidentiality agreement regarding the Disclosing Party or its affiliates, or otherwise prohibited from disclosing such information by a legal, contractual or fiduciary obligation;</li>
					<li>was already known by or in the possession of the Recipient as established by documentary evidence, prior to being disclosed by or on behalf of the Disclosing Party pursuant to this Agreement;</li>
					<li>has been independently developed by the Recipient as established by documentary evidence, without violating any of its obligations under this Agreement, use of or reference to, in whole or in part, the Confidential Information.</li>
				</ul>
				“Employee” means any employee, independent contractor, subcontractor, intern or volunteer, employed, engaged or retained by the Disclosing Party or its affiliates on a full-time, part-time or occasional basis, including any who are on medical or long-term disability leave, or other statutory or authorized leave or absence.

				“Governmental Authority” means:
<ul>
					<li>any federal, provincial, state, local, municipal, regional, territorial, aboriginal, provincial or other government, governmental or public department, branch, ministry, or court, domestic or foreign, including any district, agency, commission, board, arbitration panel or authority and any subdivision of any of them exercising or entitled to exercise any administrative, executive, judicial, ministerial, prerogative, legislative, regulatory, or taxing authority or power of any nature; and</li>
					<li>any quasi-governmental or private body exercising any regulatory, expropriation or taxing authority under or for the account of any of them, and any subdivision of any of them.</li>
				</ul>
				"Person" will be broadly interpreted and includes:
<ul>
					<li>a natural person, whether acting in his or her own capacity, or in his or her capacity as executor, administrator, estate trustee, trustee or personal or legal representative, and the heirs, executors, administrators, estate trustees, trustees or other personal or legal representatives of a natural person;</li>
					<li>a corporation or a company of any kind, a partnership of any kind, a sole proprietorship, a trust, a joint venture, an association, an unincorporated association, an unincorporated syndicate, an unincorporated organization or any other association, organization or entity of any kind; and</li>
					<li>a Governmental Authority.</li>
				</ul>
				"Personal Information" means information that:
<ul>
					<li>relates to an individual person; and</li>
					<li>identifies or can be used to identify, locate or contact that individual alone or when combined with other personal or identifying information that is or can be associated with that specific individual.</li>
				</ul>
				"Representatives" means, as to any Person, such Person's affiliates, and its and their respective directors, officers, employees, general partners, shareholders, agents and consultants (including lawyers, financial advisors and accountants).

				“Territory” means the World.

				Other terms not specifically defined in this Section shall have the meanings given to them elsewhere in this Agreement.

				Recipient's Obligations. The Recipient shall:
<ul>
					<li>keep the Confidential Information strictly confidential and protect and safeguard the confidentiality of all such Confidential Information with at least the same degree of care as the Recipient would protect its own Confidential Information, but in no event with less than a commercially reasonable degree of care;</li>
					<li>not use the Confidential Information, or permit it to be accessed or used, for any purpose other than the Purpose, or otherwise in any manner to the Disclosing Party's detriment, including without limitation, to reverse engineer, disassemble, decompile or design around the Disclosing Party's proprietary services, products and/or intellectual property;</li>
					<li>comply, and require its Representatives to comply, with all applicable federal and provincial data protection and privacy laws and regulations in the maintenance, disclosure and use of all Personal Information contained in any Confidential Information that is disclosed to the Recipient or its Representatives hereunder;</li>
					<li>maintain sufficient information security protocols to secure and protect the confidentiality of all Confidential Information in the Recipient's or its Representatives' possession or control;</li>
					<li>not disclose or permit its Representatives to disclose any Confidential Information to any Person except:
<ol>
							<li>if required by law, regulation or rule or a valid court order or pursuant to any requirement, request or process of any Governmental Authority, but only in accordance with this Agreement; or</li>
							<li>to its Representatives, to the extent necessary to permit such Representatives, who need to know the Confidential Information to assist the Recipient in relation to the Purpose and for no other purpose, provided the Recipient shall:
<ul>
									<li>inform the Recipient's Representatives of the confidential nature of the Confidential Information;</li>
									<li>cause its Representatives to be bound by the terms of this Agreement to the same extent as if they were parties hereto;</li>
									<li>maintain a record of everyone who has access to the Confidential Information;</li>
									<li>be responsible for any breach of this Agreement by any of its Representatives;</li>
									<li>use commercially reasonable controls to prevent unauthorized use or disclosure of the Confidential Information (but in any event no less than the degree of care and control that the Recipient uses to protect its own confidential information of similar importance);</li>
									<li>comply with all applicable on-site access, remote access and related security rules and procedures of the Disclosing Party;</li>
									<li>promptly notify the Disclosing Party of any unauthorized use or disclosure of the Confidential Information of which the Recipient has become aware; and</li>
									<li>fully cooperate with the Disclosing Party in any effort undertaken by the Disclosing Party to enforce its rights related to any such unauthorized disclosure.</li>
								</ul>
							</li>
						</ol>
					</li>
				</ul>
				Further Confidentiality Obligations. Except for such disclosure as is necessary not to be in violation of any applicable law, regulation or rule or a valid court order or pursuant to any requirement, request or process of any Governmental Authority (in which case the disclosure must be made in accordance with Section 5), the Recipient shall not, and shall not permit any of its Representatives to, without the prior written consent of the Disclosing Party, disclose to any Person:
<ul>
					<li>the fact that the Confidential Information has been made available to it or any of its Representatives or that it or any of its Representatives has received or inspected any portion of the Confidential Information;</li>
					<li>the existence or contents of this Agreement;</li>
					<li>the fact that investigations, discussions or negotiations are taking or have taken place concerning the Confidential Information or the Purpose, including the status thereof; or</li>
					<li>any terms, conditions or other matters or arrangements relating to the Confidential Information or the Purpose;</li>
				</ul>
				(collectively, the "Purpose Information").

				No Representations or Warranties by Disclosing Party. The Recipient understands and agrees that neither the Disclosing Party nor any of its Representatives:
<ul>
					<li>have made or makes any representation or warranty, expressed or implied, as to the accuracy or completeness of the Confidential Information; or</li>
					<li>shall have any liability to the Recipient or its Representatives relating to or resulting from the use of the Confidential Information or any errors therein or omissions therefrom.</li>
				</ul>
				Acceptance of the Website’s Terms of Use will give effect to rights and obligations as in relations to Non-Disclosure. The Disclosing Party reserves the right, in its sole discretion, to reject any and all proposals made by the Recipient or on its behalf with regard to the Purpose or a transaction, to terminate discussions and negotiations with the Recipient at any time and to enter into any agreement with any other Person without notice to the Recipient or any of its Representatives, at any time and for any reason or no reason.

				Disclosure Required. If, in the written opinion of the Recipient's counsel, the Recipient or any of its Representatives is required to disclose any Confidential Information or Purpose Information, by law, regulation, rule or a valid court order or pursuant to any requirement, request or process of any Governmental Authority, the Recipient shall:
<ul>
					<li>give the Disclosing Party immediate prior written notice of such requirement so that the Disclosing Party may seek an appropriate protective order or other remedy; and</li>
					<li>cooperate with and provide reasonable assistance to the Disclosing Party in opposing such disclosure and to obtain such protective order or other remedy.</li>
				</ul>
				If, after providing such notice and cooperation as required herein, such protective order or other remedy is not obtained, the Recipient (or such Representative to whom such requirement is directed) shall furnish only that portion of the Confidential Information or Purpose Information which, on the written advice of the Recipient's counsel, is legally required to be disclosed and shall use its best efforts to preserve the privileged nature or confidentiality of the Confidential Information or Purpose Information and obtain assurances that confidential treatment will be accorded the Confidential Information or Purpose Information so disclosed.

				Recipient's Representations and Warranties. The Recipient represents and warrants that:
<ul>
					<li>the performance of its obligations herein does not and will not violate any other contract or obligation to which the Recipient is a party, including covenants not to compete and confidentiality agreements;</li>
					<li>it is not legally or contractually prohibited from:
<ul>
							<li>discussing a potential relationship with the Disclosing Party;</li>
							<li>receiving information about a potential relationship with the Disclosing Party; or</li>
							<li>entering into a principal agreement with the Disclosing Party; and</li>
							<li>it has implemented sufficient information security protocols to secure and protect the confidentiality of all Confidential Information in the Recipient's or its Representatives' possession or control.</li>
						</ul>
					</li>
				</ul>
				Return or Destruction of Confidential Information. Upon the expiration or termination of this Agreement, or at any time upon the Disclosing Party's written request, the Recipient and its Representatives shall promptly, and in any event no later than five (5) days after such expiration, termination or request, return or destroy all Confidential Information (including all copies, reports, analyses, extracts, notes or other reproductions created using the Confidential Information) to the Disclosing Party and if destroyed, certify in writing to the Disclosing Party within such time frame that such Confidential Information (including any Confidential Information held electronically) has been destroyed. Notwithstanding the return or destruction of the Confidential Information, the Recipient and its Representatives shall continue to be bound by their obligations of confidentiality and other obligations hereunder.

				&nbsp;

				Non-Solicitation. Except with the prior written consent of the Disclosing Party, the Recipient agrees that during the term of this Agreement and for a period of five (5) years after the date of the expiration or termination of this Agreement, neither the Recipient nor its Representatives will directly or indirectly solicit, hire, contract or take away or cause to be solicited, hired, contracted or taken away, any officer, director, or Employee or solicit, induce or attempt to induce away the business of any potential, current or actual suppliers, vendors, service providers, advertisers, sponsors, clients or customers of the Disclosing Party or any of its affiliates, except pursuant to a general solicitation which is not directed specifically to any such officers, directors, Employees, suppliers, vendors, service providers, advertisers, sponsors, clients  or customers. For purposes of this section, the term “Employee” includes, without limitation, any Employee who has been employed, engaged or retained by the Disclosing Party or its affiliates at any time during the six-month period prior to the relevant time.

				&nbsp;

				&nbsp;
<ol start="7">
					<li>7<strong>. Suggestions and Improvements</strong></li>
				</ol>
				By sending us any ideas, suggestions, documents or proposals (<strong>“Feedback”</strong>), you agree that (i) your Feedback does not contain the confidential or proprietary information of third parties, (ii) we are under no obligation of confidentiality, express or implied, with respect to the Feedback, (iii) we may have something similar to the Feedback already under consideration or in development, and (iv) you grant us an irrevocable, non-exclusive, royalty-free, perpetual, worldwide license to use, modify, prepare derivative works, publish, distribute and sublicense the Feedback, and you irrevocably waive, and cause to be waived, against StartupFuel and its users any claims and assertions of any moral rights contained in such Feedback.
<ol start="8">
					<li><strong> Third Parties</strong></li>
				</ol>
				Our Services may contain links to third party websites. When you click on a link to any other website or location, you will leave our Services and you will be subject to the terms of use and privacy policy applicable to those websites. We have no control over, do not review, and cannot be responsible for, these outside websites or their content, and your use of such websites is at your own risk. The links to third party websites or locations are for your convenience and do not signify our endorsement of such third parties or their products, content or websites. Some of the services made available through our Services may be subject to additional third party or open source licensing terms and disclosures, which are incorporated herein by reference.
<ol start="9">
					<li><strong> Indemnity</strong></li>
				</ol>
				You understand and agree that you are personally responsible for your behaviour on our Services. You agree to indemnify, defend, and hold StartupFuel, its parents, subsidiaries, affiliates, any related companies, suppliers, licensors and partners, and the officers, directors, employees, agents and representatives of each of them (collectively, the “<strong>StartupFuel Entities</strong>“) harmless, including costs, liabilities and legal fees, from any claim or demand made by any third party arising out of or relating to (i) your access to or use of our Services, (ii) your violation of the Terms, or (iii) the infringement by you, or any third party using your account, of any intellectual property or other right of any person or entity. StartupFuel reserves the right, at your expense, to assume the exclusive defence and control of any matter for which you are required to indemnify us and you agree to cooperate with our defence of these claims. You agree not to settle any such matter without the prior written consent of StartupFuel. StartupFuel will use reasonable efforts to notify you of any such claim, action or proceeding upon becoming aware of it.
<ol start="10">
					<li><strong> DISCLAIMERS AND LIMITATIONS OF LIABILITY</strong></li>
				</ol>
				<strong>PLEASE READ THIS SECTION CAREFULLY SINCE IT LIMITS THE LIABILITY OF THE STARTUPFUEL ENTITIES TO YOU. EACH OF THE SUBSECTIONS BELOW ONLY APPLIES UP TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW. NOTHING HEREIN IS INTENDED TO LIMIT ANY RIGHTS YOU MAY HAVE WHICH MAY NOT BE LAWFULLY LIMITED. IF YOU ARE UNSURE ABOUT THIS OR ANY OTHER SECTION OF THESE TERMS, PLEASE CONSULT WITH A LEGAL PROFESSIONAL PRIOR TO ACCESSING OR USING OUR SERVICES. BY ACCESSING OR USING OUR SERVICES, YOU REPRESENT THAT YOU HAVE READ, UNDERSTOOD, AND AGREE TO THESE TERMS, INCLUDING THIS SECTION. YOU ARE GIVING UP SUBSTANTIAL LEGAL RIGHTS BY AGREEING TO THESE TERMS.</strong>
				<ol>
					<li>OUR SERVICES ARE MADE AVAILABLE TO YOU ON AN “AS IS”, “WITH ALL FAULTS” AND “AS AVAILABLE” BASIS, WITH THE EXPRESS UNDERSTANDING THAT THE STARTUPFUEL ENTITIES MAY NOT MONITOR, CONTROL, OR VET USER CONTENT. AS SUCH, YOUR USE OF OUR SERVICES IS AT YOUR OWN DISCRETION AND RISK. THE STARTUPFUEL ENTITIES MAKE NO CLAIMS OR PROMISES ABOUT THE QUALITY, ACCURACY, OR RELIABILITY OF OUR SERVICES, ITS SAFETY OR SECURITY, OR THE SITE CONTENT. ACCORDINGLY, THE STARTUPFUEL ENTITIES ARE NOT LIABLE TO YOU FOR ANY LOSS OR DAMAGE THAT MIGHT ARISE, FOR EXAMPLE, FROM OUR SERVICE’S INOPERABILITY, UNAVAILABILITY OR SECURITY VULNERABILITIES OR FROM YOUR RELIANCE ON THE QUALITY, ACCURACY, OR RELIABILITY OF OUR SERVICES, OR INFORMATION FOUND ON, OR MADE AVAILABLE THROUGH OUR SERVICES.</li>
					<li>THE STARTUPFUEL ENTITIES MAKE NO CLAIMS OR PROMISES WITH RESPECT TO ANY THIRD PARTY, SUCH AS THE BUSINESSES HOSTED BY THIRD PARTIES LISTED ON OUR SERVICES OR OUR SERVICE’S USERS. ACCORDINGLY, THE STARTUPFUEL ENTITIES ARE NOT LIABLE TO YOU FOR ANY LOSS OR DAMAGE THAT MIGHT ARISE FROM THEIR ACTIONS OR OMISSIONS. YOUR ATTENDENCE AT EVENTS AND/OR USE OF SERVICES OFFERED BY THIRD PARTIES THROUGH OUR SERVICES IS AT YOUR OWN DISCRETION AND RISK.</li>
					<li>THE STARTUPFUEL ENTITIES EXPRESSLY DISCLAIM ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING WARRANTIES AS TO THE EVENTS LISTED ON OUR SERVICES, AND IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.</li>
					<li>YOUR SOLE AND EXCLUSIVE RIGHT AND REMEDY IN CASE OF DISSATISFACTION WITH OUR SERVICES OR ANY OTHER GRIEVANCE SHALL BE YOUR TERMINATION AND DISCONTINUATION OF ACCESS TO, OR USE OF OUR SERVICES.</li>
					<li>THE STARTUPFUEL ENTITIES’ MAXIMUM AGGREGATE LIABILITY TO YOU FOR LOSSES OR DAMAGES THAT YOU SUFFER IN CONNECTION WITH OUR SERVICES OR THESE TERMS IS LIMITED TO THE GREATER OF (i) THE AMOUNT PAID, IF ANY, BY YOU TO THE STARTUPFUEL ENTITIES IN CONNECTION WITH OUR SERVICES IN THE 12 MONTHS PRIOR TO THE ACTION GIVING RISE TO LIABILITY, OR (ii) $100.</li>
					<li>THE STARTUPFUEL ENTITIES DISCLAIM LIABILITY FOR ANY (i) INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE, EXEMPLARY, RELIANCE, OR CONSEQUENTIAL DAMAGES, (ii) LOSS OF PROFITS, (iii) BUSINESS INTERRUPTION, (iv) REPUTATIONAL HARM, OR (v) LOSS OF INFORMATION OR DATA.</li>
					<li>BY UPLOADING BUSINESS RELATED DATA ON THE WEBSITE, YOU ACKNOWLEDGE THAT THIS DOES NOT WAIVE YOUR LEGAL OBLIGATION TO COMPLY WITH THE DATA RETENTION POLICIES OF THE CANADA REVENUE AGENCY OR THE RELEVANT TAX AND/OR REGULATORY BODY IN YOUR JURISDICTION. FURTHER, STARTFUEL DOES NOT HOLD ITSELF OUT AS A SUBSTITUTE TO YOUR OWN DATA RETENTION OBLIGATIONS IN ANY CAPACITY. ADDITIONALLY, YOU AGREE TO RETAIN COPIES OF SAID BUSINESS RELATED DOCUMENTS (WHETHER ONLINE, OFFLINE OR BOTH) IN ORDER TO COMPLY WITH THE RELEVANT TAX AND REGULATORY AGENCIES OF YOUR JURISDICTION.</li>
					<li><strong> Choice of Law and Venue</strong></li>
				</ol>
				These Terms shall be governed by and construed in accordance with the laws of the Province of Ontario and the federal laws of Canada applicable therein. Each party irrevocably submits to the exclusive jurisdiction and venue of the courts located in the Province of Ontario in any legal suit, action or proceeding arising out of or based upon this Agreement..

				&nbsp;

				&nbsp;
<ol start="12">
					<li><strong> Termination</strong></li>
					<li>You may terminate the Terms at any time by closing your account, discontinuing your use of our Services, and providing StartupFuel with a written notice of termination to  <a href="mailto:terms@startupfuel.com">terms@startupfuel.com</a></li>
				</ol>
				&nbsp;
<ol>
					<li>We may close your account, suspend your ability to use certain portions of our Services, and/or ban you altogether from our Services for any or no reason, and without notice or liability of any kind. Any such action could prevent you from accessing your account, our Services, Your Content, Site Content, or any other related information.</li>
					<li>In the event of any termination of these Terms, whether by you or us, Sections 1, and 4 through 13 will continue in full force and effect, including our right to use Your Content as detailed in Section 4.</li>
					<li><strong> General Terms</strong></li>
					<li>We reserve the right to modify, update, or discontinue our Services at our sole discretion, at any time, for any or no reason, and without notice or liability.</li>
					<li>We may provide you with notices, including those regarding changes to the Terms by email, regular mail or communications through our Services.</li>
					<li>Except as otherwise stated in Section 9 above, nothing herein is intended, nor will be deemed, to confer rights or remedies upon any third party.</li>
					<li>The Terms contain the entire agreement between you and us regarding the use of our Services, and supersede any prior agreement between you and us on such subject matter. The parties acknowledge that no reliance is placed on any representation made but not expressly contained in these Terms.</li>
					<li>Any failure on StartupFuel’s part to exercise or enforce any right or provision of the Terms does not constitute a waiver of such right or provision. The failure of either party to exercise in any respect any right provided for herein shall not be deemed a waiver of any further rights hereunder.</li>
					<li>If any provision of the Terms is found to be unenforceable or invalid, then only that provision shall be modified to reflect the parties’ intention or eliminated to the minimum extent necessary so that the Terms shall otherwise remain in full force and effect and enforceable.</li>
					<li>The Terms, and any rights or obligations hereunder, are not assignable, transferable or sublicensable by you except with StartupFuel’s prior written consent, but may be assigned or transferred by us without restriction. Any attempted assignment by you shall violate these Terms and be void.</li>
					<li>The section titles in the Terms are for convenience only and have no legal or contractual effect.</li>
				</ol>



			</div>
		</React.Fragment>

	)

}





export default TermsConditionsPage;
