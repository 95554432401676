import React, { useState } from "react";
import "./NewsFeed.css";
import "./LeftNav.css";
import "./LeftBody/LeftBody.css";
import "./RightBody/RightBody.css";
import "./Result/Result.css";
import Nav from "./Nav/Nav";
import LeftBody from "./LeftBody/LeftBody";
import RightBody from "./RightBody/RightBody";
import SideMenu from "../live/SideMenu";
import SocialAPI from "../live/api/social";
import ThreeDotsIcon from "./icons/ThreeDotsIcon";
import classnames from "classnames";
import NewsFeed_Popup from "../live/components/popups/newsfeed_login_popup";
import NewsFeed_Popup_Register_Company from "../live/components/popups/newsfeed_register_popup";

class MainLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    console.log(`Judge: ${this.props.opportunities_judging}`);
    console.log("Props:", this.props); // Log all props

    if (
      Array.isArray(this.props.opportunities_judging) &&
      this.props.opportunities_judging.length > 0
    ) {
      console.log("Judge");
      this.props.history.push(
        `/applicant_room_dash?opportunity_id=${this.props.opportunities_judging[0]._id}`,
      );
    }
  }

  componentDidMount() {
    this.fetchData();
    document.body.addEventListener("click", this.hideDropDowns);
  }

  componentWillUnmount() {
    document.body.removeEventListener("click", this.hideDropDowns);
  }

  hideDropDowns() {
    var elements = document.getElementsByClassName("socialDropDownMenu active");
    for (var i = elements.length - 1; i >= 0; i--) {
      elements[i].classList.remove("active");
    }
  }

  fetchData = () => {
    SocialAPI.follow_counts()
      .then((response) => {
        if (response.data.status === "success") {
          if (response.data.following_count < 3) {
            this.props.history.push(`/social_onboarding`);
            return;
          }

          this.setState({
            loaded: true,
            display_name: response.data.display_name,
            following_count: response.data.following_count,
            follower_count: response.data.follower_count,
            company_name: response.data.company_name,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    var noCompanyRegistered =
      this.props.companies_owned !== undefined &&
      this.props.companies_owned === null;
    var showNewsFeedPopup =
      this.props.user.loading === false && this.props.isLoggedIn === false;
    var showRegisterPopup =
      this.props.user.loading === false &&
      this.props.isLoggedIn === true &&
      noCompanyRegistered;

    return (
      <div>
        <Nav />
        <div className="newsFeedBody">
          <div className="NewsFeedLeftNavMain">
            <SideMenu />
          </div>
          {!this.state.loaded && (
            <div className="newsfeed NewsFeedBodyMain">
              {showNewsFeedPopup && <NewsFeed_Popup showNewsFeedPopup={true} />}
              {showRegisterPopup && (
                <NewsFeed_Popup_Register_Company showNewsFeedPopup={true} />
              )}
              <div className={classnames("SocialLoadingIcon")}>
                <ThreeDotsIcon />
              </div>
            </div>
          )}
          {this.state.loaded && (
            <div className="newsfeed NewsFeedBodyMain">
              <div className="NewsFeedBodyLeft">
                <LeftBody />
              </div>
              <div className="NewsFeedBodyCenter mx-3">
                {this.props.children}
              </div>
              <div className="NewsFeedBodyRight">
                <RightBody />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default MainLayout;
