/* (2019-06-10) - This page is actually a page of the SIR/ApplicationRoom
It contains all of the applicants that have applied to part of the opportunity.
It basically collects all of the applicants by querying the opportunity_applicants
schema. The components used on this page can be found:
 /application_room/components/submissions. The sidebar contains access
 to the other pages associate with the SIR.
*/
import React from "react";
import { AppContext } from "../App";
import PropTypes from "prop-types";
import axios from "axios";
import URL from "url-parse";
import "./application-room-index.scss";
import Filters from "./components/company_filter";
import Add_Company_Form from "./components/add_company_form/Add_Company_form";
import Footer from "./components/footer_side";
import SortBy from "./components/explore_sort_filter";
import Explore_Profile_Card from "./components/explore_card";
import ExploreAddedCard from "./components/explore_added_card ";

import _uniqBy from "lodash/uniqBy";
import { Modal, Button } from "react-bootstrap";
import classnames from "classnames";
import ThreeDotsIcon from "../newsfeed/icons/ThreeDotsIcon";
import Social_Follow_Popup from "./components/popups/social_follow_popup";
import Social_Register_Popup from "./components/popups/social_register_popup";
import default_background from "../img/bg.png";

import SiteTitle from "./components/site_title.js";
import {
  Sign_In,
  Register,
  Forgot_Password,
  Alert_Message_To_User,
} from "./components/popups";
import Navigation from "./components/navbar";
import Page from "./application_room/components/submissions/page";
import SideBar from "./application_room/components/Sidebar";

import TopNav from "./application_room/components/topNav";
import default_pic from "../img/default_company_pic.png";

class Added_Company_Container extends React.Component {
  constructor(props) {
    super(props);
  }
  static contextType = AppContext;
  render() {
    return <Added_Companies {...this.props} {...this.context} />;
  }
}

class Added_Companies extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: new URL(window.location.href, true),
      judges: "",
      opportunity_owner: "",
      show_page: true,
      opportunity_data: {},
      UbaidSubClass: "",
      

      company_list: [],
      loaded: false,
      fetchingNextPage: true,
      noMoreContent: false,
      mapped_companies: [],

      number_of_companies: 0,
      filters: {},
      query_search: "",
      sortby: "active",
      isOpen: false,
      showLoader: true,
      showSignInPopup: false,
      showRegisterPopup: false,
      showMap: false,
      map_loading: false,
    };
  }
  componentDidMount() {
    window.addEventListener(
      "scroll",
      this.handleInfiniteScroll.bind(this),
      true
    );
    this.setState(
      {
        fetchingNextPage: true,
      },
      this.fetchFeed
    );
    if (
      this.state.url.query.industry_tag !== undefined &&
      this.state.url.query.industry_tag !== null
    ) {
      this.setState(
        {
          filters: {
            ...this.state.filters,
            industry: [this.state.url.query.industry_tag],
          },
        },
        this.fetchFeed.bind(this, true)
      );
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleInfiniteScroll);
  }

  handleInfiniteScroll() {
    if (
      window.innerHeight + window.scrollY >= document.body.offsetHeight - 100 &&
      !this.state.fetchingNextPage
    ) {
      this.setState(
        {
          fetchingNextPage: true,
        },
        function () {
          this.fetchFeed();
        }.bind(this)
      );
    }
  }

  resetFeed() {
    this.setState(
      {
        fetchingNextPage: true,
        noMoreContent: false,
      },
      this.fetchFeed.bind(this, true)
    );
  }

  fetchFeed(reset) {
    var serialize = function (obj) {
      var str = [];
      for (var p in obj)
        if (obj.hasOwnProperty(p)) {
          str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
      return str.join("&");
    };
    if (this.state.showMap === true) {
      this.handleMapPopulate();
    }

    axios
      .get(
        `/fetch_explore_directory?${serialize({
          size: this.PAGE_LENGTH,
          count: reset ? 0 : this.state.company_list.length,
          search: this.state.query_search,
          filters: JSON.stringify(this.state.filters),
          sort: this.state.sortby,
        })}`
      )
      .then((response) => {
        if (response.data.status === "success") {
          const data = response.data.result
            .filter(
              (item) =>
                item.opportunity_id == this.state.url.query.opportunity_id
            )
            .map(
              ({
                background_video_link,
                stage,
                short_bio,
                looking_for,
                industry,
                distinction,
                created_on,
                country,
                company_type,
                company_name,
                company_files,
                business_model,
              }) => ({
                background_video_link,
                stage,
                short_bio,
                looking_for,
                industry,
                distinction,
                created_on,
                country,
                company_type,
                company_name,
                company_files,
                business_model,
              })
            );
          console.log("get company by id", data);

          // var updated_list = reset
          //   ? data
          //   : this.state.company_list.concat(data);
          // updated_list = _uniqBy(updated_list, (co) => {
          //   return co._id;
          // });
          // console.log("get updated list", updated_list);
          this.setState({
            // number_of_companies: response.data.total,
            noMoreContent: data.length < this.PAGE_LENGTH,
            company_list: data,
            loaded: false,
            fetchingNextPage: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          loaded: true,
          fetchingNextPage: false,
        });
      });
  }

  handleMapPopulate = () => {
    this.setState({
      mapped_companies: [],
    });
    var serialize = function (obj) {
      var str = [];
      for (var p in obj)
        if (obj.hasOwnProperty(p)) {
          str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
      return str.join("&");
    };
    axios
      .get(
        `/fetch_map_explore?${serialize({
          size: this.PAGE_LENGTH,
          count: this.state.company_list.length,
          search: this.state.query_search,
          filters: JSON.stringify(this.state.filters),
          //sort: this.state.sortby
        })}`
      )
      .then((response) => {
        this.setState(
          {
            mapped_companies: response.data.result,
          },
          () => {
            this.setState({ showMap: true, map_loading: false });
          }
        );
      });
  };

  dismissRegisterModal = (e) => {
    this.setState({
      showRegisterPopup: false,
    });
  };

  showRegisterModal = (e) => {
    this.setState({
      showRegisterPopup: true,
    });
  };

  handleChange = (e) => {
    const name = e.target.name;
    this.setState(
      {
        [name]: e.target.value,
      },
      () => {
        if (name === "sortby") {
          this.fetchFeed(true);
        }
      }
    );
  };

  handleLogOut = () => {
    axios
      .get(`/logout`)
      .then((response) => {
        if (response.data.status === "success") {
          this.setState(
            {
              isLoggedIn: false,
            },
            function () {
              this.props.history.push(`/sign_in`);
            }
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getAvatarUrl = (array, file_type, temp_background_url) => {
    let new_arr = array.filter((file) => {
      return file.file_type === file_type;
    });
    if (file_type === "company avatar") {
      if (new_arr.length !== 0) {
        return new_arr[0].file_url_optimized_50_50;
      } else {
        return default_pic;
      }
    }
    if (file_type === "company background") {
      if (new_arr.length !== 0) {
        return new_arr[0].file_url_optimized_500_300;
      } else {
        if (
          temp_background_url !== undefined &&
          temp_background_url !== null &&
          temp_background_url.length > 0
        ) {
          return temp_background_url;
        } else {
          return default_background;
        }
      }
    }
  };

  handleSearchInputChange = (e) => {
    this.setState(
      {
        query_search: e.target.value,
      },
      () => {
        setTimeout(this.fetchFeed.bind(this, true), 1000);
      }
    );
  };

  getStage = (value, array) => {
    for (let i = 0; i < array.length; i++) {
      if (array[i].value === value) {
        return array[i].text;
      }
    }
  };

  handleFilterChange = (e) => {
    let deleteKey = (object, key) => {
      delete object[key];
      return {
        ...object,
      };
    };
    const name = e.target.name;
    this.setState(
      {
        filters:
          e.target.value.length > 0
            ? {
                ...this.state.filters,
                [name]: e.target.value,
              }
            : deleteKey(this.state.filters, name),
      },
      this.fetchFeed.bind(this, true)
    );
  };

  handleSingleDelete = (data, key) => () => {
    let deleteKey = (object, key) => {
      delete object[key];
      return {
        ...object,
      };
    };
    let filtersx = this.state.filters[key];
    let chipToDelete = filtersx.indexOf(data);
    filtersx.splice(chipToDelete, 1);
    this.setState(
      {
        filters:
          filtersx.length > 0
            ? {
                ...this.state.filters,
                [key]: filtersx,
              }
            : deleteKey(this.state.filters, key),
      },
      this.fetchFeed.bind(this, true)
    );
  };

  togglePopup = (e) => {
    this.setState({
      showSignInPopup: !this.state.showSignInPopup,
    });
  };

  resetFilters = () => {
    this.setState(
      {
        filters: {},
      },
      this.fetchFeed.bind(this, true)
    );
  };

  openModal = () => {
    // this.setState(
    //   {
    //     filters: {},
    //   },
    //   this.fetchFeed.bind(this, true)
    // );
    console.log("open the modal");
    this.setState({ isOpen: true });
  };
  closeModal = () => {
    // this.setState(
    //   {
    //     filters: {},
    //   },
    //   this.fetchFeed.bind(this, true)
    // );
    console.log("close the modal");
    this.setState({ isOpen: false });
  };

  mapHandler = () => {
    if (this.state.showMap === false) {
      this.setState({
        map_loading: true,
      });
      this.handleMapPopulate();
    } else {
      this.setState({
        showMap: false,
      });
    }
  };

  render() {
    return (
      <div>
        <Modal size="xl" show={this.state.isOpen} onHide={this.closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>Add Company</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Add_Company_Form closeModal={this.closeModal} />
          </Modal.Body>
        </Modal>
        <div className="__application-room-main">
          <SiteTitle />
          <Sign_In />
          <Register />
          <Forgot_Password />
          <Alert_Message_To_User
            show={this.props.showPopupMessage}
            handleChange={this.props.handleChange}
            dismiss={this.props.dismissModal}
            alert_message={this.props.alert_message}
            {...this.state}
          />
          {/* <TopNav user={this.props.user} {...this.state} /> */}
          <div className="__content">
            <SideBar user={this.props.user} {...this.state} />
            {/* <Page
            user={this.props.user}
            companies_owned={this.props.companies_owned}
            {...this.state}
          /> */}
            <div className="ExpOppTooMainUbaid">
              <div className="row">
                <div className="UbaidRightFilter">
                  {/* <div> */}
                  <Filters
                    query_search={this.state.query_search}
                    handleFilterChange={this.handleFilterChange}
                    filters={this.state.filters}
                    handleSingleDelete={this.handleSingleDelete}
                    resetFilters={this.resetFilters}
                    openModal={this.openModal}
                    getStage={this.getStage}
                    results={[]}
                    handleSearchInputChange={this.handleSearchInputChange}
                  />
                  <Footer />
                  {/* </div> */}
                </div>
                <div class="UbaidLeftOppExp">
                  <div className="row">
                    <div className="col">
                      <div className="card">
                        <div className="row">
                          <div className="col-sm-7 mt-2">
                            {/* {this.state.number_of_companies !== undefined && ( */}
                            <>
                              <h5
                                className="NumberOfPitchCompUBaid"
                                style={{ lineHeight: "56px" }}
                              >
                                Added Companies
                              </h5>
                            </>
                            {/* )} */}
                          </div>
                          <div
                            className="col-sm-5 hidden-sm-down"
                            style={{ paddingRight: 0 }}
                          >
                            <SortBy
                              sortby={this.state.sortby}
                              handleChange={this.handleChange}
                              mapHandler={this.mapHandler}
                              MapOpen={this.state.showMap}
                              map_loading={this.state.map_loading}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row ubaidOppCards">
                    {this.state.company_list.map((company, index) => (
                      <div className="ubaidExploreCards col-xl-3 col-lg-4 col-md-6 col-sm-12">
                        <ExploreAddedCard
                          key={index}
                          getStage={this.getStage}
                          getAvatarUrl={this.getAvatarUrl}
                          {...company}
                          index={index}
                          filters={this.state.filters}
                          showRegisterPopup={this.showRegisterModal}
                          isLoggedIn={this.props.isLoggedIn}
                          companies_owned={this.props.companies_owned}
                        />
                      </div>
                    ))}
                  </div>
                  {this.props.isLoggedIn === false && (
                    <Social_Follow_Popup
                      dismissModal={this.dismissRegisterModal}
                      showNewsFeedPopup={this.state.showRegisterPopup}
                    />
                  )}
                  {this.props.isLoggedIn === true &&
                    this.props.companies_owned === null && (
                      <Social_Register_Popup
                        dismissModal={this.dismissRegisterModal}
                        showNewsFeedPopup={this.state.showRegisterPopup}
                      />
                    )}
                  {this.state.loaded && this.state.noMoreContent && (
                    <h5 style={{ textAlign: "center" }}>
                      <br /> There is no more content to show at the moment{" "}
                      <br /> <br />
                    </h5>
                  )}
                  {!this.state.noMoreContent && (
                    <div>
                      <div className={classnames("SocialLoadingIcon")}>
                        <ThreeDotsIcon />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Added_Company_Container.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default Added_Company_Container;
