import React from "react";

import {
  Navbar,
  Nav,
  Row,
  Col,
  Card,
  ButtonToolbar,
  Container,
  FormLabel,
  Form,
} from "react-bootstrap";
import Button from "@material-ui/core/Button";

import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import Moment from "moment";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import "../sharebuttons.css";
import "../oppHeader.css";
import default_pic from "../../../img/default_company_pic.png";
import { AppContext } from "../../../App";
import URL from "url-parse";

import CFA from "../cfa";
import { timeout } from "async";
const styles = (theme) => ({
  root: {
    flex: 1,
  },
  text: {
    paddingTop: theme.spacing.unit * 2,
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
  },
  list: {
    flex: 1,
    padding: 0,
  },
  subHeader: {
    backgroundColor: theme.palette.background.paper,
  },
  media: {
    objectFit: "cover",
    height: "55vh",
    maxHeight: 480,
  },
  avatar: {
    position: "absolute",
    top: -90,
    margin: 10,
    width: 150,
    border: 2,
    background: "white",
    boxShadow: "0 3px 5px 2px lightgrey",
    height: 150,
  },
  button: {
    margin: theme.spacing.unit,
  },
  input: {
    display: "none",
  },
});

const messages = [
  {
    id: 1,
    primary: "WORLD VISION SOCIAL WASTE CHALLENGE",
    secondary: "World Vision Canada | Mississauga, Ontario, Canada",
    person: {
      default_pic,
    },
  },
];

class CompanyNavBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "some\ntext",
      copied: false,
      isOpen: false,
      isComplete: false,
      url: new URL(window.location.href, true),
    };
  }

  openModal = () => this.setState({ isOpen: true });
  closeModal = () => {
    setTimeout(() => {
      this.setState({ isOpen: false });
    }, 25);
  };

  usePrior = () => {
    this.setState({ isOpen: false });
    this.props.handleApplicationPopup();
  };

  completeCFA = () => {
    this.setState({ isOpen: false, isComplete: true });
  };

  discard = () => {
    setTimeout(() => {
      //this.setState({ isComplete: false, isOpen: false });
    }, 75);

    const com_id = localStorage.getItem("com_id");

    if (com_id && !com_id.length == 0) {
      axios
        .get(`/fetch_fundability_scores_owned?company_id=${com_id}`)
        .then((response) => {
          //this.setState({ isOpen: false });
          const lotteryNumbers = response.data.result;
          const [lastNumber] = lotteryNumbers.slice(-1);

          // console.log(lotteryNumbers.slice(-1));

          const newdata = lotteryNumbers.slice(-1);
          const obj = { ...newdata };

          const firstitem = obj[0].ratings;

          // localStorage.setItem("rating", firstitem);
          setTimeout(() => {
            this.setState({ isComplete: false, isOpen: false });
            this.props.handleApplicationPopup();
          }, 50);
        });
    }
  };
  onClick = ({ target: { innerHTML } }) => {};

  closeparentmodal = () => {
    this.setState({ isComplete: false, isOpen: false });
  };

  redirectToEdit = () => {
    const { _id } = this.props;
    window.location.href = `/applicant_room_settings_pb?opportunity_id=${_id}`;
  };

  redirectToOpportunity = () => {
    const { _id } = this.props;
    window.location.href = `/submit_opportunity?opportunity_id=${_id}`;
  };

  onClick = ({ target: { innerHTML } }) => {};

  onCopy = () => {
    this.setState({
      copied: true,
    });
  };
  static contextType = AppContext;

  render() {
    const { classes } = this.props;
    return (
      <>
        {/* start complete modal cfa */}
        <Modal
          show={this.state.isComplete}
          onHide={() => {
            this.setState({
              isComplete: false,
            });
          }}
          className="btn-close-popup-portel-01"
          backdrop="static"
        >
          <Modal.Header closeButton style={{ background: "#3f51b5" }} />
          <Modal.Body>
            <CFA
              closeparentmodal={this.closeparentmodal}
              discard={this.discard}
            />
          </Modal.Body>
        </Modal>
        {/* end of complete cfa modal */}

        <Modal
          show={this.state.isOpen}
          onHide={this.closeModal}
          backdrop="static"
          className="btn-close-popup-portel"
        >
          <Modal.Header closeButton />
          <Modal.Body>
            <span>
              {" "}
              This opportunity requires you to complete a{" "}
              <span style={{ fontWeight: "bold" }}>
                Critical Factor Assessment
              </span>
              .{" "}
            </span>
            <br />
            <br />
            <span>
              This self‐assessment is designed to give you quick feedback on
              your project or business idea. The questions are designed to help
              you weigh your project or business idea to see how it compares and
              stands up against other opportunities. Once you have completed
              this self‐assessment, you can use it as a basis for re‐thinking
              your original idea or progressing it to the next stage. The
              assessment will cover eight (8) critical factors.
            </span>
            <br />
            <br />

            <span>
              The Critical Factor Assessment is a framework to self elevate
              yourself as a founder/startup. It is a measure of self, and gives
              reviewers a chance to understand what you see as strengths and
              weaknesses you need assistance in improving.
            </span>
            <br />
            <br />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                onClick={this.completeCFA}
                id="buttons-grid-opp-button"
                variant="contained"
                color="primary"
                className={classes.button}
                xs={12}
              >
                Complete CFA
              </Button>

              {/* <Button
                onClick={this.usePrior}
                id="buttons-grid-opp-button"
                variant="contained"
                color="primary"
                className={classes.button}
                xs={12}
              >
                Use Prior CFA
              </Button> */}
            </div>
          </Modal.Body>
        </Modal>
        <BottomNavigation
          value={this.props.menuSelection}
          style={{
            width: "100%",
            textAlign: "center",
          }}
          onChange={this.props.handleMenuChange}
          class="companyNavBarOverflow bg-white py-2 text-black"
          showLabels
        >
          <span className="opportunity-name-nav">
            {this.props.opportunity_name}
          </span>
          <div style={{ marginLeft: "auto", display: "flex" }}>
            {/* <BottomNavigationAction
          label="Overview"
          value={0}
        />

          {!this.props.menuoppoBTN &&
            <>

              {this.props.showEditFour !==0 && (
                <BottomNavigationAction
                  onClick={() => this.props.handleNavigationClick('Description')}
                  label="Description"
                  value={"Description"}
                />
              )}
              {this.props.Founded_Date && (
                <BottomNavigationAction
                  onClick={() => this.props.handleNavigationClick('Founded')}
                  label="Founded"
                  value={"Founded"}
                />
              )}
              {this.props.showEditFive && (
                <BottomNavigationAction
                  onClick={() => this.props.handleNavigationClick('Social_links')}
                  label="Social"
                  value={"Social"}
                />
              )}
              {/* {console.log("this.props?.company_products",this.props?.company_products)}
              {console.log("this.props.companyData",this.props.companyData)} */}
            {/*{(this.props.companyData === "Startup" ||*/}
            {/*this.props.companyData === "Tech Startup") &&*/}
            {/*this.props?.company_products?.length > 0 && (*/}
            {/*  <BottomNavigationAction*/}
            {/*    onClick={() => this.props.handleNavigationClick('Products')}*/}
            {/*    label="Products"*/}
            {/*    value={"Products"}*/}
            {/*  />*/}
            {/*)}*/}
            {/* {console.log("this.props?.company_services",this.props?.company_services)} */}
            {/*{this.props?.company_services?.length > 0 && (*/}
            {/*  <BottomNavigationAction*/}
            {/*    onClick={() => this.props.handleNavigationClick('Services')}*/}
            {/*    label="Services"*/}
            {/*    value={"Services"}*/}
            {/*  />*/}
            {/*)}*/}
            {/* {this.props.formatted_address !==0 && (*/}
            {/*  <BottomNavigationAction*/}
            {/*    onClick={() => this.props.handleNavigationClick('Headquarters')}*/}
            {/*    label="Headquarters"*/}
            {/*    value={"Headquarters"}*/}
            {/*  />*/}
            {/*)}*/}
            {/*{console.log("this.props.contact_phone",this.props.contact_phone)}*/}
            {/*{this.props.contact_phone !==0 && (*/}
            {/*  <BottomNavigationAction*/}
            {/*    onClick={() => this.props.handleNavigationClick('Contact')}*/}
            {/*    label="Contact"*/}
            {/*    value={"Contact"}*/}
            {/*  />*/}
            {/*)}*/}

            {/* <BottomNavigationAction
          label="About"
          value={1}
        />
        <BottomNavigationAction
          label="Updates"
          value={2}
        />
        <BottomNavigationAction
          label="People"
          value={3}
        /> */}
            {/*{(((this.props.company_type[0] === "Startup" ||*/}
            {/*  this.props.company_type[0] === "Tech Startup") &&*/}
            {/*  this.props.userIsProfileOwner) ||*/}
            {/*  ((this.props.company_type[0] === "Startup" ||*/}
            {/*    this.props.company_type[0] === "Tech Startup") &&*/}
            {/*    this.props.allow_investor &&*/}
            {/*    this.props.companies_owned &&*/}
            {/*    this.props.companies_owned[1][0].company_type[0] ===*/}
            {/*    "Investor")) && (*/}
            {/*    <BottomNavigationAction*/}
            {/*      label="Pitch"*/}
            {/*      value={4}*/}
            {/*    />*/}
            {/*  )}*/}
            {/* {(this.props.company_type[0] === "Startup" ||
          this.props.company_type[0] === "Tech Startup") &&
          this.props.userIsProfileOwner && (
            <BottomNavigationAction
              label="Applications"
              value={4}
            />
          )} */}
            {/* {this.props.company_type[0] !== "Startup" &&
          this.props.company_type[0] !== "Tech Startup" &&
          this.props.userIsProfileOwner && (
            <BottomNavigationAction
              label="Opportunities"
              value={2}
            />
          )} */}
            {/* {this.props.userIsProfileOwner && (
          <BottomNavigationAction
            label="Settings"
            value={3}
          />
        )} */}

            {/* {this.props.company_type[0] == "Startup" &&
          this.props.company_type[0] == "Tech Startup" &&
          this.props.userIsProfileOwner && (
            <BottomNavigationAction
              label="Deal Memos"
              value={6}
              className="dealmemo"
            />
          )} */}
            {/*     {*/}
            {/*       this.props.company_type[0] == "Startup" && */}
            {/*         this.props.company_type[0] == "Tech Startup" &&*/}
            {/*      this.props.userIsProfileOwner && (*/}
            {/*  <BottomNavigationAction*/}
            {/*    className="invest-profile-page"*/}
            {/*    label="Invest"*/}
            {/*    value={7}*/}
            {/*  />*/}
            {/*)} */}

            {/*{*/}
            {/*  this.props.objective !== null &&*/}
            {/*  this.props.objective !== undefined &&*/}
            {/*  this.props.objective.length > 0 &&*/}
            {/*  <BottomNavigationAction*/}
            {/*    onClick={() => this.props.handleNavigationClick('objective')}*/}
            {/*    // className="invest-profile-page"*/}
            {/*    label="OBJECTIVE"*/}
            {/*    value={"OBJECTIVE"}*/}
            {/*  />*/}
            {/*}*/}
            {/*{this.props.venue_location !== undefined &&*/}
            {/*  this.props.venue_location !== null &&*/}
            {/*  this.props.venue_location.length > 0 &&*/}
            {/*  <BottomNavigationAction*/}
            {/*    onClick={() => this.props.handleNavigationClick('venue_location')}*/}
            {/*    // className="invest-profile-page"*/}
            {/*    label="Venue"*/}
            {/*    value={"Venue_Location"}*/}
            {/*  />*/}
            {/*}*/}
            {/*{*/}
            {/*  this.props.prize !== null &&*/}
            {/*  this.props.prize !== undefined &&*/}
            {/*  this.props.prize !== "" &&*/}
            {/*  <BottomNavigationAction*/}
            {/*    onClick={() => this.props.handleNavigationClick('Prize')}*/}
            {/*    // className="invest-profile-page"*/}
            {/*    label="Prize"*/}
            {/*    value={"Prize"}*/}
            {/*  />*/}
            {/*}*/}
            {/*{*/}
            {/*  this.props.amount !== null && this.props.amount !== undefined &&*/}
            {/*  <BottomNavigationAction*/}
            {/*    onClick={() => this.props.handleNavigationClick('Amount')}*/}
            {/*    // className="invest-profile-page"*/}
            {/*    label="Amount"*/}
            {/*    value={"Amount"}*/}
            {/*  />*/}
            {/*}*/}
            {/*{*/}
            {/*  this.props.prize_pitch !== null &&*/}
            {/*  this.props.prize_pitch !== undefined &&*/}
            {/*  <BottomNavigationAction*/}
            {/*    onClick={() => this.props.handleNavigationClick('Pitch_Prize')}*/}
            {/*    // className="invest-profile-page"*/}
            {/*    label="Pitch Prize"*/}
            {/*    value={"Pitch_Prize"}*/}
            {/*  />*/}

            {/*{*/}
            {/*  this.props.show_judge_on_opportunity !== undefined &&*/}
            {/*  this.props.show_judge_on_opportunity === true &&*/}
            {/*  this.props.judges !== undefined &&*/}
            {/*  this.props.judges !== null &&*/}
            {/*  this.props.judges.length > 0 &&*/}
            {/*  <BottomNavigationAction*/}
            {/*    onClick={() => this.props.handleNavigationClick('Team')}*/}
            {/*    // className="invest-profile-page"*/}
            {/*    label="Team"*/}
            {/*    value={"Team"}*/}
            {/*  />*/}
            {/*}*/}

            {this.props.menuoppoBTN &&
              !this.props.isLoggedIn &&
              !this.props.has_applicant_room && (
                <Button
                  className="Btn_oppo_header"
                  onClick={this.props.toggleSignUpPopup}
                  variant="outlined"
                  color="primary"
                >
                  Apply On Website
                </Button>
              )}
            {this.props.menuoppoBTN &&
              this.props.isLoggedIn &&
              !this.props.has_applicant_room && (
                <Button
                  className="Btn_oppo_header"
                  href={this.props.website_url}
                  target="_blank"
                  variant="outlined"
                  color="primary"
                >
                  Apply On Website
                </Button>
              )}
            {this.props.menuoppoBTN && this.props.has_applicant_room && (
              <span>
                {this.props.submitted_application ||
                this.props.opportunity_list.some(
                  (opp) => opp.pertains_to_opportunity === this.props._id,
                ) ? (
                  <Button
                    className="Btn_oppo_header"
                    onClick={this.redirectToEdit}
                    variant="contained"
                    color="secondary"
                  >
                    Edit
                  </Button>
                ) : (
                  <Button
                    className="Btn_oppo_header"
                    onClick={this.redirectToOpportunity}
                    variant="contained"
                  >
                    Pitch
                  </Button>
                )}
              </span>
            )}
          </div>
        </BottomNavigation>
      </>
    );
  }
}

CompanyNavBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CompanyNavBar);
// export default CompanyNavBar;
