import React, { useState } from "react";
import LoadingButton from "../../../components/loadingButton";
import axios from "axios";
import ReactMarkdown from "react-markdown";

const WebsiteInfoComponent = ({
  companyInfo,
  opportunity_data,
  updateCompanyData,
}) => {
  const [loading, setLoading] = useState(false);
  const [generatingWebsiteInfo, setGeneratingWebsiteInfo] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [generationStep, setGenerationStep] = useState("");
  const [progress, setProgress] = useState(0);

  const simulateProgress = async () => {
    // Extracting phase (3 seconds)
    setGenerationStep("Extracting Website Content");
    for (let i = 0; i <= 30; i++) {
      setProgress(i);
      await new Promise(r => setTimeout(r, 100));
    }
    
    // Analyzing phase (5 seconds)
    setGenerationStep("Analyzing Content");
    for (let i = 31; i <= 80; i++) {
      setProgress(i);
      await new Promise(r => setTimeout(r, 62.5));
    }

    // Summarizing phase
    setGenerationStep("Generating Summary");
  };

  const handleGenerateWebsiteInfo = async () => {
    setGeneratingWebsiteInfo(true);
    setErrorMessage("");
    setProgress(0);
    const websiteUrl =
      companyInfo.deal_info?.find(
        (field) => field.field_name === "company_website",
      )?.field_value || companyInfo.company_website;

    if (!websiteUrl) {
      setErrorMessage("No website URL found");
      setGeneratingWebsiteInfo(false);
      return;
    }

    simulateProgress();

    try {
      const response = await axios.post(
        "/summarize_website",
        {
          url: websiteUrl,
        },
        {
          params: {
            company_id: companyInfo.applicant_company,
            opportunity_id: opportunity_data._id,
          },
        },
      );
      updateCompanyData(companyInfo.applicant_company, {
        website_summary: response.data.summary,
      });
      setProgress(100);
    } catch (error) {
      console.error("Error generating website info:", error);
      setErrorMessage(
        "Due to high network load, we were unable to generate the website summary. Please try again later",
      );
    } finally {
      setGeneratingWebsiteInfo(false);
      setGenerationStep("");
      setProgress(0);
    }
  };

  return (
    <div>
      <h4>Website Summary</h4>
      <p className="text-grey text-sm">Summary of company website content</p>
      <LoadingButton
        onClick={handleGenerateWebsiteInfo}
        loading={generatingWebsiteInfo}
        className="summarize-button"
        style={{ marginBottom: "20px" }}
        text={
          generatingWebsiteInfo ? "Generating..." : "Generate Website Summary"
        }
      />

      {generatingWebsiteInfo && (
        <div style={{ marginTop: '20px', marginBottom: '20px' }}>
          <div style={{ marginBottom: '10px', fontWeight: 'bold' }}>
            {generationStep}...
          </div>
          <div style={{ 
            width: '100%', 
            height: '20px', 
            backgroundColor: '#f0f0f0',
            borderRadius: '10px',
            overflow: 'hidden'
          }}>
            <div style={{
              width: `${progress}%`,
              height: '100%',
              background: 'linear-gradient(90deg, #6902b9 0%, #1c71a1 100%)',
              transition: 'width 0.3s ease-in-out'
            }}></div>
          </div>
        </div>
      )}

      {errorMessage && <p className="text-red-500 mt-2">{errorMessage}</p>}
      <div className="review-content-card">
        {companyInfo.website_summary ? (
          <div className="text-sm py-4">
            <ReactMarkdown>{companyInfo.website_summary}</ReactMarkdown>
          </div>
        ) : (
          <p className="text-grey text-sm">No website summary available.</p>
        )}
      </div>
    </div>
  );
};

export default WebsiteInfoComponent;
