/* (2019-06-11) - A black background with a flame appearing in the middle.
Not used.
*/
import React from 'react';
import './loader.css'

const Loader = () => {
  return (

    <div className="flame-body">
      <div class="flame">
      </div>
    </div>


  )
};

export default Loader;
