import React from 'react';

const NewsFeedIcon = props => (
    <svg>
        <path d="M14,12H6v2h8V12z M6,18h8v-2H6V18z M16,12v6h2v-6H16z M20,2H4C2.896,2,2,2.896,2,4v6h18v10H4v-4H2v4
        c0,1.104,0.896,2,2,2h16c1.104,0,2-0.896,2-2V4C22,2.896,21.104,2,20,2z M20,8H4V4h16V8z M4,12H2v2h2V12z M7,5H5v2h2V5z M10,5H8v2
        h2V5z M13,5h-2v2h2V5z"></path>
    </svg>
)

export default NewsFeedIcon;