import React, { useState } from "react";
import classNames from "classnames";
import Chip from "@material-ui/core/Chip";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import {
  stage_list,
  business_type,
  industry_list,
} from "../../../../../boilerplate/list.json";
import LoadingButton from "../../../components/loadingButton";
import axios from "axios";

const ApplicationInfoComponent = ({
  applicationObject,
  companyInfo,
  opportunity_data,
  updateCompanyData,
}) => {
  const [editMode, setEditMode] = useState({});
  const [editedInfo, setEditedInfo] = useState({});
  const [loading, setLoading] = useState(false);
  const [generatingDealInfo, setGeneratingDealInfo] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [editedFundraising, setEditedFundraising] = useState({
    total_amount: companyInfo.fundraising?.total_amount || "",
    committed_amount: companyInfo.fundraising?.committed_amount || "",
    lead_investor: companyInfo.fundraising?.lead_investor || ""
  });

  const hasApplicationInfo = Object.keys(applicationObject).some(
    (key) =>
      applicationObject[key].Answer &&
      applicationObject[key].Answer.trim() !== "",
  );

  const getStage = (value, array) => {
    for (let i = 0; i < array.length; i++) {
      if (array[i].value === value) {
        return array[i].text;
      }
    }
    return "Unknown";
  };

  const handleEdit = (field) => {
    setEditMode({ ...editMode, [field]: true });
    setEditedInfo({ ...editedInfo, [field]: getDealInfo(field) });
  };

  const handleFundraisingEdit = (field) => {
    setEditMode({ ...editMode, [field]: true });
  };

  const handleFundraisingChange = (field, value) => {
    setEditedFundraising({
      ...editedFundraising,
      [field]: value
    });
  };

  const handleFundraisingSave = async (field) => {
    setLoading(true);
    try {
      const response = await axios.post("/edit_fundraising", {
        opportunity_id: opportunity_data._id,
        company_id: companyInfo.applicant_company,
        fundraising: {
          ...companyInfo.fundraising,
          [field]: editedFundraising[field]
        }
      });

      updateCompanyData(companyInfo.applicant_company, {
        fundraising: response.data.updatedApplication.fundraising
      });
      setEditMode({ ...editMode, [field]: false });
    } catch (error) {
      console.error("Error saving fundraising info:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async (field) => {
    setLoading(true);
    try {
      const response = await axios.post("/edit_deal_info", {
        opportunity_id: opportunity_data._id,
        company_id: companyInfo.applicant_company,
        field_name: field,
        field_value: editedInfo[field],
        field_state: "approved",
      });
      updateCompanyData(companyInfo.applicant_company, {
        deal_info: response.data.updatedApplication.deal_info,
      });
      setEditMode({ ...editMode, [field]: false });
      setLoading(false);
    } catch (error) {
      console.error("Error saving deal info:", error);
      setLoading(false);
    }
  };

  const handleCancel = (field) => {
    setEditMode({ ...editMode, [field]: false });
    setEditedInfo({ ...editedInfo, [field]: getDealInfo(field) });
  };

  const handleChange = (field, value) => {
    setEditedInfo({ ...editedInfo, [field]: value });
  };

  const getDealInfo = (field) => {
    const dealInfoField =
      companyInfo.deal_info &&
      companyInfo.deal_info.find((item) => item.field_name === field);
    if (dealInfoField && dealInfoField.field_value) {
      return dealInfoField.field_value;
    }
    const applicationField = Object.keys(applicationObject).find(
      (key) =>
        applicationObject[key].Title.toLowerCase() === field.toLowerCase(),
    );
    return applicationField && applicationObject[applicationField].Answer
      ? applicationObject[applicationField].Answer
      : companyInfo[field] || "Unspecified";
  };

  const getFieldState = (field) => {
    const dealInfoField =
      companyInfo.deal_info &&
      companyInfo.deal_info.find((item) => item.field_name === field);
    return dealInfoField ? dealInfoField.field_state : "declined";
  };

  const renderStatusDot = (field) => {
    if (field === "company_name") return null;
    const state = getFieldState(field);
    if (state === "approved") return null;
    return (
      <span
        style={{
          display: "inline-block",
          width: "8px",
          height: "8px",
          borderRadius: "50%",
          marginRight: "8px",
          backgroundColor: state === "pending" ? "#A020F0" : "#FF0000",
        }}
      ></span>
    );
  };

  const renderEditableField = (field, label) => (
    <tr className="border-bottom border-gray border-t">
      <td className="w-20 py-4 font-bold text-sm">
        {renderStatusDot(field)}
        {label}
      </td>
      <td className="text-sm py-4">
        {field !== "company_name" && editMode[field] ? (
          <div>
            <input
              type="text"
              value={editedInfo[field]}
              onChange={(e) => handleChange(field, e.target.value)}
              className="border rounded p-1 mr-2"
            />
            <Button 
              variant="contained" 
              color="primary" 
              size="small" 
              onClick={() => handleSave(field)}
              disabled={loading}
              style={{marginRight: '8px'}}
            >
              Save
            </Button>
            <Button 
              variant="outlined" 
              size="small" 
              onClick={() => handleCancel(field)}
            >
              Cancel
            </Button>
          </div>
        ) : (
          <div onClick={() => field !== "company_name" && handleEdit(field)}>
            {getDealInfo(field)}
          </div>
        )}
      </td>
    </tr>
  );

  const renderEditableFundraisingField = (field, label) => (
    <tr className="border-gray border-t border-bottom">
      <td className="py-4 font-bold text-sm">{label}</td>
      <td className="py-4 text-sm">
        {editMode[field] ? (
          <div>
            <input
              type="text"
              value={editedFundraising[field]}
              onChange={(e) => handleFundraisingChange(field, e.target.value)}
              className="border rounded p-1 mr-2"
            />
            <Button 
              variant="contained" 
              color="primary" 
              size="small" 
              onClick={() => handleFundraisingSave(field)}
              disabled={loading}
              style={{marginRight: '8px'}}
            >
              Save
            </Button>
            <Button 
              variant="outlined" 
              size="small" 
              onClick={() => setEditMode({ ...editMode, [field]: false })}
            >
              Cancel
            </Button>
          </div>
        ) : (
          <div onClick={() => handleFundraisingEdit(field)}>
            {companyInfo.fundraising?.[field] || "Unspecified"}
          </div>
        )}
      </td>
    </tr>
  );

  const handleGenerateDealInfo = async () => {
    setGeneratingDealInfo(true);
    setErrorMessage("");
    try {
      const response = await axios.post(
        "/get_deal_info",
        {
          applicationInfo: applicationObject,
        },
        {
          params: {
            company_id: companyInfo.applicant_company,
            opportunity_id: opportunity_data._id,
          },
        },
      );
      updateCompanyData(companyInfo.applicant_company, {
        deal_info: response.data.deal_info,
      });
    } catch (error) {
      console.error("Error generating deal info:", error);
      setErrorMessage(
        "Due to high network load, we were unable to generate the deal info. Please try again later",
      );
    } finally {
      setGeneratingDealInfo(false);
    }
  };

  return (
    <div>
      <h4>Deal Info</h4>
      <p className="text-grey text-sm">The details of the deal as submitted</p>
      <LoadingButton
        onClick={handleGenerateDealInfo}
        loading={generatingDealInfo}
        className="summarize-button"
        style={{ marginBottom: "20px" }}
        text={generatingDealInfo ? "Generating..." : "Generate Deal Info"}
      ></LoadingButton>
      {errorMessage && <p className="text-red-500 mt-2">{errorMessage}</p>}
      <div className="review-content-card" style={{ marginBottom: "20px" }}>
        <table className="table-fixed w-100 ">
          <tbody>
            {renderEditableField("company_name", "Business Name")}
            {renderEditableField("company_website", "Website")}
            {renderEditableField("address", "Location")}
            {renderEditableField("short_bio", "Description")}
            <tr className="border-bottom border-gray border-t p-3">
              <td className="w-20 py-2 font-bold text-sm align-top py-4">
                {renderStatusDot("business_model")}
                Business Models
              </td>
              <td className="text-sm">
                {editMode.business_model ? (
                  <div>
                    <Select
                      value={editedInfo.business_model || ""}
                      onChange={(e) =>
                        handleChange("business_model", e.target.value)
                      }
                    >
                      {business_type.map((type) => (
                        <MenuItem key={type} value={type}>
                          {type}
                        </MenuItem>
                      ))}
                    </Select>
                    <Button 
                      variant="contained" 
                      color="primary" 
                      size="small" 
                      onClick={() => handleSave("business_model")}
                      disabled={loading}
                      style={{marginLeft: '8px', marginRight: '8px'}}
                    >
                      Save
                    </Button>
                    <Button 
                      variant="outlined" 
                      size="small" 
                      onClick={() => handleCancel("business_model")}
                    >
                      Cancel
                    </Button>
                  </div>
                ) : (
                  <div onClick={() => handleEdit("business_model")}>
                    {getDealInfo("business_model")}
                  </div>
                )}
              </td>
            </tr>
            <tr>
              <td className="w-20 font-bold text-sm align-top py-4">
                {renderStatusDot("industry")}
                Industries Disrupted
              </td>
              <td className="text-sm py-4">
                {editMode.industry ? (
                  <div>
                    <Select
                      multiple
                      value={editedInfo.industry || []}
                      onChange={(e) => handleChange("industry", e.target.value)}
                      renderValue={(selected) => (
                        <div>
                          {Array.isArray(selected) &&
                            selected.map((value) => (
                              <Chip key={value} label={value} className="m-1" />
                            ))}
                        </div>
                      )}
                    >
                      {industry_list.map((industry) => (
                        <MenuItem key={industry} value={industry}>
                          {industry}
                        </MenuItem>
                      ))}
                    </Select>
                    <Button 
                      variant="contained" 
                      color="primary" 
                      size="small" 
                      onClick={() => handleSave("industry")}
                      disabled={loading}
                      style={{marginLeft: '8px', marginRight: '8px'}}
                    >
                      Save
                    </Button>
                    <Button 
                      variant="outlined" 
                      size="small" 
                      onClick={() => handleCancel("industry")}
                    >
                      Cancel
                    </Button>
                  </div>
                ) : (
                  <div
                    className="allTags"
                    onClick={() => handleEdit("industry")}
                  >
                    {(() => {
                      const industries = getDealInfo("industry");
                      return Array.isArray(industries) &&
                        industries.length > 0 ? (
                        industries.map((val, index) => (
                          <Chip
                            key={index}
                            variant="outlined"
                            className={classNames("exploreTag", "greenHl")}
                            color="primary"
                            size="small"
                            label={val}
                          />
                        ))
                      ) : (
                        <span>Unspecified</span>
                      );
                    })()}
                  </div>
                )}
              </td>
            </tr>
            <tr>
              <td className="w-20 font-bold text-sm align-top py-4">
                {renderStatusDot("stage")}
                Company Stage
              </td>
              <td className="text-sm">
                {editMode.stage ? (
                  <div>
                    <Select
                      value={editedInfo.stage || ""}
                      onChange={(e) => handleChange("stage", e.target.value)}
                    >
                      {stage_list.map((stage) => (
                        <MenuItem key={stage.value} value={stage.value}>
                          {stage.text}
                        </MenuItem>
                      ))}
                    </Select>
                    <Button 
                      variant="contained" 
                      color="primary" 
                      size="small" 
                      onClick={() => handleSave("stage")}
                      disabled={loading}
                      style={{marginLeft: '8px', marginRight: '8px'}}
                    >
                      Save
                    </Button>
                    <Button 
                      variant="outlined" 
                      size="small" 
                      onClick={() => handleCancel("stage")}
                    >
                      Cancel
                    </Button>
                  </div>
                ) : (
                  <div onClick={() => handleEdit("stage")}>
                    {(() => {
                      const stage = getDealInfo("stage");
                      return stage
                        ? getStage(stage, stage_list)
                        : "Unspecified";
                    })()}
                  </div>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <h4>Fundraising Info</h4>
      <p className="text-grey text-sm">Fundraising details</p>
      <div className="review-content-card" style={{ marginBottom: "20px" }}>
        <table className="table-fixed w-100">
          <tbody>
            {renderEditableFundraisingField("total_amount", "Total Amount")}
            {renderEditableFundraisingField("committed_amount", "Committed Amount")}
            {renderEditableFundraisingField("lead_investor", "Lead Investor")}
          </tbody>
        </table>
      </div>

      <h4>Portal Info</h4>
      <p className="text-grey text-sm">Portal submission details</p>
      <div className="review-content-card">
        {hasApplicationInfo ? (
          <table className="table-fixed w-100">
            <tbody>
              {Object.keys(applicationObject)
                .filter(
                  (key) =>
                    applicationObject[key].Answer &&
                    applicationObject[key].Answer.trim() !== "",
                )
                .map((key, index, array) => {
                  const isLastItem = index === array.length - 1;
                  return (
                    <tr
                      className={classNames("border-gray border-t", {
                        "border-bottom": !isLastItem,
                      })}
                      key={key}
                    >
                      <td className="py-4 font-bold text-sm">
                        {applicationObject[key].Title}
                      </td>
                      <td className="py-4 text-sm">
                        {applicationObject[key].Answer || "Unspecified"}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        ) : (
          <p className="text-grey text-sm">No portal information available.</p>
        )}
      </div>
    </div>
  );
};

export default ApplicationInfoComponent;
