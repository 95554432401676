import React, { Component } from "react";
import URL from "url-parse";
import axios from "axios";
import { Link, Routes, Route, useNavigate } from "react-router-dom";

import Lvideo from "../assets/videos/post_video.mp4";
import logo from "../../img/watermark-light.png";
import SignupForm from "../components/forms/signup/M-signupForm";
import SignupFormInvited from "../components/forms/signup/M-signupForm-invited";
import { Button } from "@material-ui/core";
import { Translate } from "aws-sdk";
import Navigation from "../../live/components/navbar";
import SiteTitle from "../../live/components/site_title";
import Community_Register_Login from "../../live/components/popups/community_register_login";
import {
  Sign_In,
  Register,
  Alert_Message_To_User,
  Development_Message_To_User,
  Development_Question_Form,
  Forgot_Password,
} from "../../live/components/popups";
import "./M-landingPage.scss";
import _uniqBy from "lodash/uniqBy";
import default_pic from "../../img/default_company_pic.png";
import default_background from "../../img/bg.png";
import img1 from "../../img/techstarslogo.png";
import img2 from "../../img/Telus-Logo.png";
import img3 from "../../img/rogerslogo.png";

import { AppContext } from "../../App";
class LandingPageInvited_Container extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: new URL(window.location.href, true),
    };
  }

  static contextType = AppContext;
  render() {
    return (
      <React.Fragment>
        <LandingPageInvited {...this.props} {...this.context} />
      </React.Fragment>
    );
  }
}

class LandingPageInvited extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: new URL(window.location.href, true),
      popup_header: "You Have Sucessfully Validated Your Account!",
      popup_body: "",
      show_popup: false,
      show_community_popup: false,
      already_seen: false,
      startup_list: [],
      services_list: [],
      enablers_list: [],
      company_list: [],
      startup_filters: { company_type: ["Startup", "Tech Startup"] },
      service_filters: { company_type: ["Service Provider"] },
      enabler_filters: {
        company_type: ["Accelerator", "Incubator", "Investor"],
      },
      loaded: false,
      fetchingNextPage: true,
      noMoreContent: false,
      mapped_companies: [],

      number_of_companies: 0,
      filters: { industry: ["COVID-19"] },
      query_search: "",
      sortby: "active",

      showLoader: true,
      showSignInPopup: false,
      showRegisterPopup: false,
      showMap: false,

      pitch_competitions: [],
      accelerators_incubators: [],
      funding: [],
      government: [],
      target_industries: "COVID-19",
    };
  }
  componentDidMount() {
    if (
      !this.state.url.query.ref_id ||
      (this.state.url.query.ref_id && this.state.url.query.ref_id.length === 0)
    ) {
      this.props.history.push(`/home_page`);
      document.location.reload(true);
    }
    if (this.state.url.query.token === undefined) {
      console.log("no token");
      this.fetchFeed();
    } else {
      axios
        .post(`/confirm_user_registration?token=${this.state.url.query.token}`)
        .then((response) => {
          if (response.data.status === "success") {
            this.props.persistUser(response.data);
            this.setState(
              {
                show_popup: true,
              },
              () => {
                this.props.persistUser(response.data);
              },
            );
          }
        });
    }
  }
  fetchFeed(reset) {
    var serialize = function (obj) {
      var str = [];
      for (var p in obj)
        if (obj.hasOwnProperty(p)) {
          str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
      return str.join("&");
    };
    if (this.state.showMap === true) {
      this.handleMapPopulate();
    }

    axios
      .get(
        `/fetch_explore_directory?${serialize({
          size: 4,
          count: reset ? 0 : this.state.enablers_list.length,
          search: this.state.query_search,
          filters: JSON.stringify(this.state.enabler_filters),
          sort: this.state.sortby,
        })}`,
      )
      .then((response) => {
        if (response.data.status === "success") {
          var updated_list = reset
            ? response.data.result
            : this.state.enablers_list.concat(response.data.result);
          updated_list = _uniqBy(updated_list, (co) => {
            return co._id;
          });

          this.setState({
            number_of_companies: response.data.total,
            noMoreContent: response.data.result.length < this.PAGE_LENGTH,
            enablers_list: updated_list,
            loaded: true,
            fetchingNextPage: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          loaded: true,
          fetchingNextPage: false,
        });
        console.log(error);
      });

    axios
      .get(
        `/fetch_explore_directory?${serialize({
          size: 4,
          count: reset ? 0 : this.state.startup_list.length,
          search: this.state.query_search,
          filters: JSON.stringify(this.state.startup_filters),
          sort: this.state.sortby,
        })}`,
      )
      .then((response) => {
        if (response.data.status === "success") {
          var updated_list = reset
            ? response.data.result
            : this.state.startup_list.concat(response.data.result);
          updated_list = _uniqBy(updated_list, (co) => {
            return co._id;
          });

          this.setState({
            number_of_companies: response.data.total,
            noMoreContent: response.data.result.length < this.PAGE_LENGTH,
            startup_list: updated_list,
            loaded: true,
            fetchingNextPage: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          loaded: true,
          fetchingNextPage: false,
        });
        console.log(error);
      });

    axios
      .get(
        `/fetch_explore_directory?${serialize({
          size: 4,
          count: reset ? 0 : this.state.services_list.length,
          search: this.state.query_search,
          filters: JSON.stringify(this.state.service_filters),
          sort: this.state.sortby,
        })}`,
      )
      .then((response) => {
        if (response.data.status === "success") {
          var updated_list = reset
            ? response.data.result
            : this.state.services_list.concat(response.data.result);
          updated_list = _uniqBy(updated_list, (co) => {
            return co._id;
          });

          this.setState({
            number_of_companies: response.data.total,
            noMoreContent: response.data.result.length < this.PAGE_LENGTH,
            services_list: updated_list,
            loaded: true,
            fetchingNextPage: false,
          });
        }
      })
      .catch((error) => {
        this.setState({
          loaded: true,
          fetchingNextPage: false,
        });
        console.log(error);
      });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps !== this.props &&
      this.state.url.query.commaff !== undefined &&
      this.state.url.query.commaff.length > 0 &&
      this.props.companies_owned !== undefined &&
      this.props.companies_owned !== null &&
      this.props.companies_owned[1] !== undefined &&
      this.props.companies_owned[1][0] !== undefined
    ) {
      console.log("rendering");
      axios
        .post("/community_email_response", {
          community_applicant: this.props.companies_owned[1][0]._id,
          applicant_response: this.state.url.query.response,
          commaff: this.state.url.query.commaff,
        })
        .then((response) => {
          if (response.data.status === "success") {
            this.props.history.push(
              `/community_members?community_id=${response.data.community_id}`,
            );
          }
        });
    } else if (
      prevProps !== this.props &&
      this.state.url.query.commaff !== undefined &&
      this.state.url.query.commaff.length > 0 &&
      this.props.companies_owned === null &&
      this.state.url.query.response !== undefined &&
      this.state.url.query.response === "Approved" &&
      this.state.already_seen === false
    ) {
      setTimeout(() => {
        if (this.props.companies_owned === null) {
          this.setState({
            show_community_popup: true,
            already_seen: true,
            popup_header: "Please Register or Login to Join this Community!",
          });
        }
      }, 500);
    }
  }

  dismissModal = (e, val) => {
    (e.target.className === "modal1" ||
      e.target.className === "cancelbtn" ||
      val === "true") &&
      this.setState({
        show_popup: false,
        show_community_popup: false,
      });
  };

  loginRedirect = () => {
    this.props.history.push(
      `/sign_in?commaff=${this.state.url.query.commaff}&response=${this.state.url.query.response}`,
    );
  };

  getAvatarUrl = (array, file_type) => {
    if (array?.length > 0) {
      let new_arr = array.filter((file) => {
        return file.file_type === file_type;
      });
      if (new_arr.length !== 0) {
        return new_arr[0].file_url_optimized_1000_400 || new_arr[0].file_url;
      } else if (file_type === "company background") {
        if (
          this.state.temp_background_url !== undefined &&
          this.state.temp_background_url !== null &&
          this.state.temp_background_url.length > 0
        ) {
          return this.state.temp_background_url;
        } else {
          return default_background;
        }
      } else {
        return default_pic;
      }
    } else if (file_type === "company background") {
      if (
        this.state.temp_background_url !== undefined &&
        this.state.temp_background_url !== null &&
        this.state.temp_background_url.length > 0
      ) {
        return this.state.temp_background_url;
      } else {
        return default_background;
      }
    } else {
      return default_pic;
    }
  };
  scrollTopHandler = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  render() {
    // const { classes } = this.props;

    return (
      <React.Fragment>
        <div className="Invited_landing">
          <header className="Invited_landing__header">
            <div className="Invited_landing__header-logo">
              <img src={logo} alt="Logo" />
            </div>
            <nav className="Invited_landing__header-menu">
              <Link to="/">Home</Link>
              <Link to="/about">About</Link>
              <Link to="/contact">Contact</Link>
            </nav>
          </header>
          <section className="Invited_landing__hero">
            <div className="Invited_landing__hero-text">
              <h1 className="Invited_landing__hero-text-title">
                AI Driven Venture Capital
              </h1>
              <h4 className="Invited_landing__hero-text-subtitle">
                Raise Capital, Invest in Startups
              </h4>
              <p className="Invited_landing__hero-text-body">
                Raising capital is an art that not every founder is good at.
                Startup diligence is a science that not every investor is good
                at. We combined art + science, so both can focus on what they
                are good at.
              </p>
            </div>
          </section>
          <section className="Invited_landing__signup">
            <h2>Join Our Community</h2>
            {this.props.isLoggedIn && this.props.companies_owned !== null ? (
              <Link to="/explore" target="_blank">
                <button
                  style={{
                    padding: "10px 20px",
                    margin: "10px",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                    fontSize: "1em",
                    backgroundColor: "#C49733",
                    color: "#fff",
                  }}
                >
                  You are logged in, click here to explore!
                </button>
              </Link>
            ) : (
              <SignupFormInvited history={this.props.history} {...this.props} />
            )}
          </section>
          <footer className="Invited_landing__footer">
            <img
              src={logo}
              alt="Footer Logo"
              className="Invited_landing__footer-logo"
            />
            <div>2024 StartupFuel Inc. All Rights Reserved.</div>
            <div className="Invited_landing__footer-social">
              <a
                href="https://www.facebook.com/FuelYourStartup/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-facebook-f"></i>
              </a>
              <a
                href="https://twitter.com/StartupFuel"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-twitter"></i>
              </a>
              <a
                href="https://www.youtube.com/channel/UCKDhPYBuAQ78Lwwcbt_hqvQ"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-youtube"></i>
              </a>
              <a
                href="https://linkedin.com/company/startupfuelapp"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-linkedin-in"></i>
              </a>
              <a
                href="https://www.instagram.com/startupfueltv/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-instagram"></i>
              </a>
            </div>
            <div className="Invited_landing__footer-links">
              <a href="https://medium.com/@StartupFuel">Blog</a>
              <Link to="/privacy_policy">Privacy Policy</Link>
              <Link to="/terms_conditions">Terms Of Service</Link>
            </div>
          </footer>
        </div>
      </React.Fragment>
    );
  }
}

export default LandingPageInvited_Container;
