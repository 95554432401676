import React, { Component } from "react";
import DrawerToggleButton from "../sideDrawer/M-drawerToggleButton";
import Logo from "../../../img/watermark-light.png";
import FlameLogo from "../../../img/startupfuel flame.png";

import "./_M-navbar.scss";

class Navbar extends Component {
  state = {
    background: "",
  };

  listenScrollEvent = (e) => {
    if (this.window.scrollY) {
      this.setState({
        background: "white",
      });
    } else {
      this.setState({
        background: "transparent",
      });
    }
  };

  //   componentDidMount() {
  //     window.addEventListener('scroll', this.listenScrollEvent)
  //   }

  render() {
    return (
      <header
        className="navigationbar"
        onScroll={this.listenScrollEvent}
        style={{ background: this.state.background }}
      >
        <nav className="navigationbar__navigation">
          <div className="navigationbar__spacer">
            <div className="navigationbar__toggle-button">
              <DrawerToggleButton click={this.props.drawerToggleClickHandler} />
            </div>
            <div className="navigationbar__logo">
              <a href="/">
                <img src={Logo} alt="logo" />
              </a>
            </div>
            <div className="navigationbar__navigation-items">
              <ul>
                <ul>
                  <li>
                    <a href="/opportunities">Opportunities</a>
                  </li>
                  <li>
                    <a href="/explore">Explore</a>
                  </li>
                  <li>
                    <a href="http://startupfuel.tv/services/">
                      Startup Services
                    </a>
                  </li>
                </ul>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    );
  }
}

export default Navbar;
