import React, { useEffect, useState } from "react";
import {
  MenuItem,
  Select,
  Chip,
  InputLabel,
  FormControl,
} from "@material-ui/core";
import axios from "axios";
import "./submit_opportunity.css";
import {
  industry_list,
  business_type,
  stage_list,
  country_list,
} from "../../../../boilerplate/list.json";

const businessTypeMapping = {
  "B2B - Business To Business": "B2B",
  "B2C - Business To Customers": "B2C",
  "B2G - Business To Government": "B2G",
  "Marketplace - Consumer To Consumer": "C2C",
};

const getReadableBusinessType = (value) => {
  for (let [key, val] of Object.entries(businessTypeMapping)) {
    if (val === value) return key;
  }
  return value;
};

function CompanyInfo({
  nextStep,
  formData,
  setFormData,
  user,
  companyInfo,
  setCompanyInfo,
}) {
  const [selectedIndustries, setSelectedIndustries] = useState(
    formData.companyInfoData.industries || [],
  );
  const [errors, setErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [initialFormData, setInitialFormData] = useState({});

  useEffect(() => {
    if (companyInfo && companyInfo._id) {
      const businessModel = Array.isArray(companyInfo.business_model)
        ? companyInfo.business_model[0]
        : companyInfo.business_model;

      const initialData = {
        companyWebsite:
          companyInfo.company_website === "undefined"
            ? ""
            : companyInfo.company_website || "",
        address: companyInfo.address || "",
        country: companyInfo.country || "",
        industries: companyInfo.industry || [],
        businessModel: getReadableBusinessType(businessModel),
        companyStage: companyInfo.stage || "",
        contactPhone:
          companyInfo.contact_phone === "undefined"
            ? ""
            : companyInfo.contact_phone || "",
      };
      setFormData({ ...formData, companyInfoData: initialData });
      setSelectedIndustries(companyInfo.industry || []);
      setInitialFormData(initialData);
    }
  }, [companyInfo, setFormData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      companyInfoData: { ...formData.companyInfoData, [name]: value },
    });
  };

  const handlePhoneChange = (e) => {
    const { name, value } = e.target;
    if (/^\d*$/.test(value)) {
      setFormData({
        ...formData,
        companyInfoData: { ...formData.companyInfoData, [name]: value.trim() },
      });
    }
  };

  const handleSelectChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedIndustries(typeof value === "string" ? value.split(",") : value);
    setFormData({
      ...formData,
      companyInfoData: {
        ...formData.companyInfoData,
        industries: typeof value === "string" ? value.split(",") : value,
      },
    });
  };

  const handleBusinessModelChange = (event) => {
    const {
      target: { value },
    } = event;
    setFormData({
      ...formData,
      companyInfoData: { ...formData.companyInfoData, businessModel: value },
    });
  };

  const handleChipDelete = (chipToDelete, type) => () => {
    if (type === "industry") {
      const newIndustries = selectedIndustries.filter(
        (chip) => chip !== chipToDelete,
      );
      setSelectedIndustries(newIndustries);
      setFormData({
        ...formData,
        companyInfoData: {
          ...formData.companyInfoData,
          industries: newIndustries,
        },
      });
    }
  };

  const validateFields = () => {
    let newErrors = {};
    const requiredFields = [
      "address",
      "country",
      "industries",
      "businessModel",
      "companyStage",
    ];

    requiredFields.forEach((field) => {
      if (field === "industries") {
        if (
          !formData.companyInfoData[field] ||
          formData.companyInfoData[field].length === 0
        ) {
          newErrors[field] = "This field is required.";
        }
      } else if (
        !formData.companyInfoData[field] ||
        (typeof formData.companyInfoData[field] === "string" &&
          formData.companyInfoData[field].trim().length === 0)
      ) {
        newErrors[field] = "This field is required.";
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const updateCompany = async () => {
    try {
      const payload = {
        contact_phone:
          String(formData.companyInfoData.contactPhone).trim() || "",
        company_website:
          String(formData.companyInfoData.companyWebsite).trim() || "",
        stage: String(formData.companyInfoData.companyStage).trim() || "",
        address: String(formData.companyInfoData.address).trim() || "",
        country: String(formData.companyInfoData.country).trim() || "",
        industry: formData.companyInfoData.industries,
        business_model:
          businessTypeMapping[formData.companyInfoData.businessModel] ||
          formData.companyInfoData.businessModel,
      };

      const result = await axios.post(
        `/edit_company_during_application?company_id=${companyInfo._id}`,
        payload,
      );

      console.log("Company updated successfully", result.data);
      setCompanyInfo(result.data.result);

      nextStep();
    } catch (error) {
      console.error("Error updating the company:", error);
      alert("An error occurred while updating the company.");
      setSubmitting(false);
    }
  };

  const handleSubmit = async () => {
    setSubmitting(true); // Set submitting state at the beginning
    if (validateFields()) {
      updateCompany();
    } else {
      setSubmitting(false); // Set submitting state to false if validation fails
    }
  };

  return (
    <div className="opportunity-submission-card">
      <h1>Company Info</h1>
      <h3>Provide Info about the company</h3>
      <div className="responsive-grid">
        <div className="form-group">
          <label>Company Website:</label>
          <input
            type="text"
            name="companyWebsite"
            placeholder="Enter your Company Website"
            value={formData.companyInfoData.companyWebsite || ""}
            onChange={handleInputChange}
            style={{ width: "100%" }}
          />
          {errors.companyWebsite && (
            <span className="error">{errors.companyWebsite}</span>
          )}
        </div>
        <div className="form-group">
          <label>
            Address<span className="required">*</span>:
          </label>
          <input
            type="text"
            name="address"
            placeholder="Enter your Address or City"
            value={formData.companyInfoData.address || ""}
            onChange={handleInputChange}
            style={{ width: "100%" }}
            required
          />
          {errors.address && <span className="error">{errors.address}</span>}
        </div>
        <div className="form-group">
          <label>
            Country<span className="required">*</span>:
          </label>
          <select
            name="country"
            value={formData.companyInfoData.country || ""}
            onChange={handleInputChange}
            required
          >
            <option value="">Select Country</option>
            {country_list.map((country) => (
              <option key={country} value={country}>
                {country}
              </option>
            ))}
          </select>
          {errors.country && <span className="error">{errors.country}</span>}
        </div>
        <div className="form-group">
          <label>Contact Phone:</label>
          <input
            type="text"
            name="contactPhone"
            placeholder="Enter your Contact Phone"
            value={formData.companyInfoData.contactPhone || ""}
            onChange={handlePhoneChange}
            style={{ width: "100%" }}
          />
          {errors.contactPhone && (
            <span className="error">{errors.contactPhone}</span>
          )}
        </div>
        <div className="form-group">
          <InputLabel id="industries-label">
            Industry<span className="required">* (Select Multiple)</span>:
          </InputLabel>
          <FormControl fullWidth variant="outlined" required>
            <Select
              labelId="industries-label"
              multiple
              value={selectedIndustries}
              onChange={handleSelectChange}
              renderValue={(selected) => (
                <div className="chips">
                  {selected.map((value) => (
                    <Chip
                      key={value}
                      label={value}
                      onDelete={handleChipDelete(value, "industry")}
                    />
                  ))}
                </div>
              )}
            >
              {industry_list.map((industry) => (
                <MenuItem key={industry} value={industry}>
                  {industry}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {errors.industries && (
            <span className="error">{errors.industries}</span>
          )}
        </div>
        <div className="form-group">
          <InputLabel id="business-model-label">
            Business Model<span className="required">*</span>:
          </InputLabel>
          <FormControl fullWidth variant="outlined" required>
            <Select
              labelId="business-model-label"
              value={formData.companyInfoData.businessModel || ""}
              onChange={handleBusinessModelChange}
            >
              {Object.keys(businessTypeMapping).map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {errors.businessModel && (
            <span className="error">{errors.businessModel}</span>
          )}
        </div>
        <div className="form-group">
          <label>
            Company Stage<span className="required">*</span>:
          </label>
          <select
            name="companyStage"
            value={formData.companyInfoData.companyStage || ""}
            onChange={handleInputChange}
            required
          >
            <option value="">Select Company Stage</option>
            {stage_list.map((stage) => (
              <option key={stage.value} value={stage.value}>
                {stage.text}
              </option>
            ))}
          </select>
          {errors.companyStage && (
            <span className="error">{errors.companyStage}</span>
          )}
        </div>
      </div>
      <button onClick={handleSubmit} disabled={submitting}>
        {submitting ? "Submitting..." : "Next"}
      </button>
    </div>
  );
}
export default CompanyInfo;
