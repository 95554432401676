import React, {useRef} from 'react';

const NavLinks = props => {
    const dropElm = useRef();
    let check = true;
    const dropHandler = () => {
        check ? dropElm.current.className = 'olympusLinkCard OlympusDropShow' : dropElm.current.className = 'olympusLinkCard';
        check = !check;
    }
    return (
        <div className='OlympicsNavLink'>
            <div className='OlympicsNavLinkInner' onClick={dropHandler}>
                <a href='#'>{props.title}</a>
                <span className='dropIconOlympus'></span>
            </div>
            <div className='olympusLinkCard' ref={dropElm}>
                <div className='olympusLinkCardDrop'>
                    {props.children}
                </div>
            </div>
        </div>
    )
}

export default NavLinks;
