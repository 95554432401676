import React from 'react';
import classnames from 'classnames';
import Moment from "moment";
import './CenterBody.css';
import post2 from '../Images/post__thumb1.jpg'
import SocialAPI from '../../../api/social';
import PostHeader from './PostHeader';
import PostSingleComment from './PostSingleComment';
import ProfileImage from '../../../components/profile_image';

class PostComments extends React.Component {
  constructor(props) {
    super(props);

    this.textBox = React.createRef();

    this.state = {
      comments: [],
      loaded: false,
    };
  }

  componentDidUpdate(prevProps) {
    // if parent post has changed, reset everything
    if (this.props.postId !== prevProps.postId) {
      this.setState({
        comments: [],
        loaded: false
      }, !this.props.commentsHidden ? this.loadComments : undefined); // if comments are supposed to show, load them
    }

    // if previously hidden and not loaded yet, load comments for first time
    else if (!this.props.commentsHidden && !this.state.loaded && prevProps.commentsHidden) {
      this.loadComments();
    }
  }

  clearTextfield() {
    this.setState({
      commentContent: undefined
    });
    this.textBox.current.value = '';
  }

  handleContentChange(event) {
    this.setState({
      commentContent: event.target.value
    });
  }

  handleCommentCancel(event) {
    this.clearTextfield();
    event.preventDefault();
  }

  handleCommentSubmit(event) {
    this.handleCommentPost(this.state.commentContent);
    event.preventDefault();
  }

  handleCommentPost(content) {
    SocialAPI.comment(this.props.postId, content)
      .then((response) => {
        this.loadComments();
        this.clearTextfield();
      })
  }

  loadComments() {
    SocialAPI.getComments(this.props.postId)
      .then((response) => {
        this.setState({
          loaded: true,
          comments: response.data.comments
        })
      })
  }

  render() {
    return (
      <div className={this.props.commentsHidden && 'hide'}>
        { !this.state.loaded && (
          <h6 style={ { textAlign: "center" } }><br />Loading comments...</h6>
          ) }
        { this.state.comments.map(function(item, i) {
            return (
              <PostSingleComment
                handleDeleteRefresh={ this.loadComments.bind(this) }
                key={ i }
                index={ i }
                item={ item } />
            )
          }.bind(this)) }
        <div className={classnames('AddCommentsNewsFeed', !this.state.loaded && 'hide')}>
          <ProfileImage />
          <div className="mainContent">
            <textarea
              ref={ this.textBox }
              onChange={ this.handleContentChange.bind(this) }
              value={ this.state.commentContent }
              placeholder="Add comment..." />
          </div>
          <div className={ classnames("ctaBtn", !(this.state.commentContent && this.state.commentContent.length > 0) && "hide") }>
            <button
              onClick={ this.handleCommentSubmit.bind(this) }
              className='newsfeedOrange'>
              Post
            </button>
          </div>
        </div>
      </div>
      );
  }

}

export default PostComments;
