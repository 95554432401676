/* (2019-06-11) - This is the sub menu that appears in the SIR/ApplicationRoom.
*/
import React, { Component, useRef, useState, useEffect  } from 'react';
import './index.css';
import { Nav, Navbar, NavDropdown, Image } from "react-bootstrap";
import URL from 'url-parse';

class TopNav1 extends React.Component {
  constructor(props){
    super(props);
    this.state={
      url: new URL(window.location.href, true),
      old_url:"",
      highlighted_explore:false,
      highlighted_opportunity:false,
    }
  }
    render() {
        return (
            <div>
                <Navbar className="top_topNav" bg="light" expand="lg">
                    <Navbar.Brand className="page-title"></Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse className="navbar_options_hide" id="basic-navbar-nav">
                        <Nav className="mr-auto">
                        <Nav.Link href={'/applicant_room?opportunity_id='+this.state.url.query.opportunity_id} id={this.state.url.pathname==='/applicant_room'?"submission":"sidetabs"}>SUBMISSIONS</Nav.Link>
                        <Nav.Link href={'/applicant_room_top?opportunity_id='+this.state.url.query.opportunity_id} id={this.state.url.pathname==='/applicant_room_top'?"submission":"sidetabs"}>TOP APPLICANTS</Nav.Link>
                        <Nav.Link href={'/applicant_room_team?opportunity_id='+this.state.url.query.opportunity_id} id={this.state.url.pathname==='/applicant_room_team'?"submission":"sidetabs"}>TEAM</Nav.Link>
                        {(this.props.opportunity_owner._id === this.props.user._id || this.props.user.email === 'ash@startupfuel.com')&&
                          <div>
                          <Nav.Link href={'/applicant_room_referral_partners?opportunity_id='+this.state.url.query.opportunity_id} id={this.state.url.pathname==='/applicant_room_referral_partners'?"submission":"sidetabs"}>REFERRAL PARTNERS</Nav.Link>
                          <Nav.Link href={'/applicant_room_flagged?opportunity_id='+this.state.url.query.opportunity_id} id={this.state.url.pathname==='/applicant_room_flagged'?"submission":"sidetabs"}>FLAGGED SUBMISSIONS</Nav.Link>
                        <Nav.Link href={'/applicant_room_settings?opportunity_id='+this.state.url.query.opportunity_id} id={this.state.url.pathname==='/applicant_room_settings'?"submission":"sidetabs"}>SETTINGS</Nav.Link>
                        </div>
                      }
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </div>
        )
    }
}

export default TopNav1