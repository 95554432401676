import React from "react";

import {
  AboutContainer,
  AboutInfo,
  AboutTitle,
  AboutSubtitle,
  AboutGreytitle,
  AboutDesc,
  AboutBtn,
  AboutBtnLink,
  GridContainer,
  GridItem,
  GridSubtitle,
  ItemDesc,
} from "./AboutElements";

import img1 from "../../../img/svg/Teamwork.svg";
import img2 from "../../../img/svg/FilterStartups.svg";
import img3 from "../../../img/svg/Customizable-Settings.svg";
import img4 from "../../../img/svg/TargetedMarketing.svg";
import img5 from "../../../img/ph-logo-1.png";
import img6 from "../../../img/techcrunch.png";

const AboutSection = () => {
  return (
    <>
      <AboutContainer id="about">
        <AboutInfo id="asseenin">
          <AboutGreytitle>As Seen In</AboutGreytitle>
          <GridContainer>
            <GridItem>
              <object
                width="350px"
                data={img5}
              ></object>
            </GridItem>
            <GridItem>
              <object
                width="350px"
                data={img6}
              ></object>
            </GridItem>
          </GridContainer>
          
        </AboutInfo>
        <AboutInfo>
          <AboutSubtitle>Data Driven Due Diligence</AboutSubtitle>
          <AboutTitle>How does the PitchBox help you?</AboutTitle>
          <AboutDesc>
            Pitchbox is an advanced due diligence system that empowers startup reviewers with data & intelligence.
            For all organizations reviewing startups: investors, accelerators, lenders, corporates etc.
          </AboutDesc>
          <AboutBtn>
            <AboutBtnLink
              to="//startupfuel.zohobookings.com/#/customer/pitchbox"
              target="_blank"
            >
              Book Your Free Demo
            </AboutBtnLink>
          </AboutBtn>
        </AboutInfo>

        <GridContainer>
          <GridItem>
            <object
              type="image/svg+xml"
              max-width="350px"
              height="255px"
              data={img1}
            ></object>
            <GridSubtitle>Enhanced Team Collaborations</GridSubtitle>
            <ItemDesc>
              Shared access control for internal team & external support team
              for scouting & due diligence
            </ItemDesc>
          </GridItem>

          <GridItem>
            <object
              type="image/svg+xml"
              max-width="350px"
              height="255px"
              data={img2}
            ></object>
            <GridSubtitle>Data Driven Deal Scouting</GridSubtitle>
            <ItemDesc>
              Save time by filtering and organizing deals based on thesis,
              impact and success probability
            </ItemDesc>
          </GridItem>

          <GridItem>
            <object
              type="image/svg+xml"
              max-width="350px"
              height="255px"
              data={img3}
            ></object>
            <GridSubtitle>Streamlined Due Diligence Process</GridSubtitle>
            <ItemDesc>
              Research based refinment to due diligence built with interviews to over 100 startup diligence groups.
            </ItemDesc>
          </GridItem>

          <GridItem>
            <object
              type="image/svg+xml"
              max-width="350px"
              height="255px"
              data={img4}
            ></object>
            <GridSubtitle>The Startup Score</GridSubtitle>
            <ItemDesc>
              A universal due-diligence tool that assess the health of a startup venture with great accuracy. 
            </ItemDesc>
          </GridItem>
        </GridContainer>
      </AboutContainer>
    </>
  );
};

export default AboutSection;
