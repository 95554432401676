/* (2019-06-11) - This is the settings page of the SIR/Application room, it
allows you to configure various settings for the SIR. A lot of the logic is
handled on other pages, this mainly just accepts the checkbox.
*/
import React, { Component } from "react";
import axios from "axios";
import URL from "url-parse";
import { Success_Popup } from "../../../components/popups";
import Checkbox from "@material-ui/core/Checkbox";
import ArgNav from "../argNav";
import { Switch } from "@material-ui/core";
import { NavItem } from "reactstrap";
import { Link } from "react-router-dom";
import "./index.scss";
import Navigation from "../../../components/navbar_pb";
import SettingsNavigation from "./settings-sidenav";
class Page extends Component {
  constructor(props) {
    super(props);

    this.state = {
      url: new URL(window.location.href, true),
      judges: [1],
      judges_can_register: false,
      users_can_see_feedback: false,
      additional_review_options: true,
      additional_review_criteria: [],
      temp_value: "",
      success_msg: "Saved!",
      show_success_popup: false,
      judges_get_email: false,
      opportunity_private: false,
      limit_number_of_reviews: false,
      number_of_reviews: null,
      has_applicant_registration_email: false,
      applicant_registration_email: {
        header: "Thank you for applying!",
        body: `We appreciate you taking the time to apply to our application.
              We will get back to your shortly regarding how you did.
              `,
      },
      opportunity_form_modify: false,
      custom_text_q1: "",
      custom_text_q2: "",
      opportunity_form: [],
    };
  }
  componentDidMount() {
    axios
      .get(
        `fetch_opportunity?opportunity_id=${this.state.url.query.opportunity_id}`,
      )
      .then((response) => {
        this.setState({
          ...response.data.result,
        });
      });
    axios
      .get(
        `/fetch_optional_questions?opportunity_id=${this.state.url.query.opportunity_id}`,
      )
      .then((response) => {
        this.setState({
          ...response.data.existing_opportunity_form,
        });
      });
  }
  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({
        judges: this.props.judges,
      });
    }
  }

  displaySaved = () => {
    this.setState(
      {
        show_success_popup: true,
      },
      () => {
        setTimeout(() => this.setState({ show_success_popup: false }), 1500);
      },
    );
  };

  handleChange = (e) => {
    const name = e.target.name;
    this.setState({
      [name]: e.target.value,
    });
  };
  handleEmailChange = (e) => {
    const name = e.target.name;
    this.setState({
      applicant_registration_email: {
        ...this.state.applicant_registration_email,
        [name]: e.target.value,
      },
    });
  };
  handleNumberReviewsSave = (e) => {
    axios.post(
      `/edit_opportunity_settings?opportunity_id=${this.state.url.query.opportunity_id}`,
      {
        judges_can_register: this.state.judges_can_register,
        users_can_see_feedback: this.state.users_can_see_feedback,
        judges_get_email: this.state.judges_get_email,
        limit_number_of_reviews: this.state.limit_number_of_reviews,
        number_of_reviews: this.state.number_of_reviews,
        opportunity_private: this.state.opportunity_private,
        opportunity_form_modify: this.state.opportunity_form_modify,
        has_applicant_registration_email:
          this.state.has_applicant_registration_email,
        additional_review_options: this.state.additional_review_options,
        additional_review_criteria: this.state.additional_review_criteria,
      },
    );
    this.displaySaved();
  };

  handleCheck = (e) => {
    const name = e.target.name;
    this.setState(
      {
        [name]: !this.state[name],
      },
      () => {
        axios.post(
          `/edit_opportunity_settings?opportunity_id=${this.state.url.query.opportunity_id}`,
          {
            judges_can_register: this.state.judges_can_register,
            users_can_see_feedback: this.state.users_can_see_feedback,
            judges_get_email: this.state.judges_get_email,
            limit_number_of_reviews: this.state.limit_number_of_reviews,
            opportunity_private: this.state.opportunity_private,
            opportunity_form_modify: this.state.opportunity_form_modify,
            has_applicant_registration_email:
              this.state.has_applicant_registration_email,
            additional_review_options: this.state.additional_review_options,
            additional_review_criteria: this.state.additional_review_criteria,
          },
        );
        this.displaySaved();
      },
    );
  };
  handleCustomForm = (e, key) => {
    const name = e.target.name;
    let target_key = this.state.opportunity_form[key];
    if (
      name === "display" &&
      this.state.opportunity_form[key]["display"] === true
    ) {
      let obj = {
        ...this.state.opportunity_form[key],
        ["required"]: false,
        ["display"]: false,
      };
      this.setState({
        opportunity_form: { ...this.state.opportunity_form, [key]: obj },
      });
    } else {
      let obj = {
        ...this.state.opportunity_form[key],
        [name]: !this.state.opportunity_form[key][name],
      };
      this.setState({
        opportunity_form: { ...this.state.opportunity_form, [key]: obj },
      });
    }
  };
  handleFormSave = (e) => {
    axios.post(
      `/edit_opportunity_form?opportunity_id=${this.state.url.query.opportunity_id}`,
      {
        opportunity_form: this.state.opportunity_form,
      },
    );
    this.displaySaved();
  };
  handleEmailSave = (e) => {
    axios.post(
      `/edit_opportunity_email?opportunity_id=${this.state.url.query.opportunity_id}`,
      {
        applicant_registration_email: {
          header: this.state.applicant_registration_email.header,
          body: this.state.applicant_registration_email.body,
        },
      },
    );
    this.displaySaved();
  };

  handleSave = (e) => {
    axios.post(
      `/create_optional_questions?opportunity_id=${this.state.url.query.opportunity_id}`,
      {
        custom_text_q1: this.state.custom_text_q1,
        custom_text_q2: this.state.custom_text_q2,
      },
    );
  };

  addCriteria = (e) => {
    this.setState(
      {
        additional_review_criteria: [
          ...this.state.additional_review_criteria,
          this.state.temp_value,
        ],
        temp_value: "",
      },
      () => {
        axios.post(
          `/edit_opportunity_settings?opportunity_id=${this.state.url.query.opportunity_id}`,
          {
            judges_can_register: this.state.judges_can_register,
            users_can_see_feedback: this.state.users_can_see_feedback,
            judges_get_email: this.state.judges_get_email,
            limit_number_of_reviews: this.state.limit_number_of_reviews,
            opportunity_private: this.state.opportunity_private,
            opportunity_form_modify: this.state.opportunity_form_modify,
            has_applicant_registration_email:
              this.state.has_applicant_registration_email,
            additional_review_options: this.state.additional_review_options,
            additional_review_criteria: this.state.additional_review_criteria,
          },
        );
        this.displaySaved();
      },
    );
  };
  deleteCriteria = (e, value) => {
    let newList = this.state.additional_review_criteria.splice(
      this.state.additional_review_criteria.indexOf(value),
      1,
    );
    this.setState(
      {
        additional_review_criteria:
          this.state.additional_review_criteria.filter((x) => x !== value),
      },
      () => {
        axios.post(
          `/edit_opportunity_settings?opportunity_id=${this.state.url.query.opportunity_id}`,
          {
            judges_can_register: this.state.judges_can_register,
            users_can_see_feedback: this.state.users_can_see_feedback,
            judges_get_email: this.state.judges_get_email,
            limit_number_of_reviews: this.state.limit_number_of_reviews,
            opportunity_private: this.state.opportunity_private,
            opportunity_form_modify: this.state.opportunity_form_modify,
            has_applicant_registration_email:
              this.state.has_applicant_registration_email,
            additional_review_options: this.state.additional_review_options,
            additional_review_criteria: this.state.additional_review_criteria,
          },
        );
        this.displaySaved();
        axios.post(
          `/edit_rating_criteria_for_opp?opportunity_id=${this.state.url.query.opportunity_id}`,
          {
            additional_review_criteria: this.state.additional_review_criteria,
            value: value,
          },
        );
      },
    );
  };

  render() {
    const { url } = this.state;
    return (
      <div>
        <Navigation
          opportunity_data={
            this.state.opportunity_data
              ? {
                  ...this.state.opportunity_data,
                  // Ensure that credits are present; if not, fallback to this.state.credits
                  credits:
                    this.state.opportunity_data.credits || this.state.credits,
                }
              : { credits: this.state.credits }
          }
        />{" "}
        <ArgNav
          opportunity_name={this.props.opportunity_name}
          opportunity_id={this.state.url.query.opportunity_id}
          pathname={this.state.url.pathname}
        />
        <div className="_content">
          <React.Fragment>
            <Success_Popup
              show={this.state.show_success_popup}
              alert_message={this.state.success_msg}
            />
            <div className="__application-room-settings">
              <div className="__header  __application-room-padding">
                <h3>Visibility Settings</h3>
              </div>
              <div className="__pb_settings_page">
                <SettingsNavigation />
                <div className="__main __application-room-padding">
                  <label>
                    <Switch
                      checked={!this.state.opportunity_private}
                      onChange={(event) => {
                        this.handleCheck(event);
                      }}
                      name="opportunity_private"
                      color="primary"
                    />
                    <p className="__settings_text">Enable Deal Portal</p>
                    <br></br>
                    <p className="__settings_text">
                      {" "}
                      <em>
                        By enabling Deal Portal, your fund's workspace will be
                        assigned a portal for deal founders to find from
                        StartupFuel and directly submit their PitchDeck and
                        other info to
                      </em>
                    </p>
                  </label>
                  {!this.state.opportunity_private && (
                    <p className="__paragraph">
                      Portal Link: (Copy and share this link with anyone you
                      would like submit a deal to the workspace) <br />{" "}
                      <Link
                        className="__link"
                        to={`/opportunity?opportunity_id=${this.state.url.query.opportunity_id}`}
                      >
                        <span>
                          {this.state.url.origin}
                          /opportunity?opportunity_id=
                          {this.state.url.query.opportunity_id}
                        </span>
                      </Link>
                    </p>
                  )}
                </div>
              </div>
            </div>
          </React.Fragment>
        </div>
      </div>
    );
  }
}

export default Page;
