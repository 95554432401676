import React from 'react';
import './Parallax.css';
import bigLogo from '../assets/bigLogo.png';
import unsplashImg from "../assets/a9d8881c-unsplash-gyf49rb7bve_0b407f00000000000001o.jpg";

const Parallax = props => (
    <div className='parallax'>
        <div className='pitchBoxContainer'>
            <img src={unsplashImg} />
            <div>
                <img src={bigLogo} className='mainLogoPitch' /><p></p>
                <span className='hideOnSmall'>#FuelTheRevolution</span>
            </div>
        </div>
    </div>
)

export default Parallax;