import axios from 'axios'

var API = {};

API.followStatus = function(company_id) {
  return axios.post(`/social/follow_status`, { target_id: company_id });
};

API.follow = function(company_id) {
  return axios.post(`/social/follow`, { target_id: company_id });
};

API.unfollow = function(company_id) {
  return axios.post(`/social/unfollow`, { target_id: company_id });
};

API.following_list = function() {
  return axios.get(`/social/following_list`);
};
API.following_list_profile = function(company_id) {
  return axios.get(`/social/following_list_profile?company_id=${company_id}`);
};

API.follower_list = function() {
  return axios.get(`/social/follower_list`);
};
API.follower_list_profile = function(company_id) {
  return axios.get(`/social/follower_list_profile?company_id=${company_id}`);
};

API.follow_counts = function() {
  return axios.get(`/social/follow_counts`);
};
API.follow_counts_profile = function(company_id) {
  return axios.get(`/social/follow_counts_profile?company_id=${company_id}`);
};

API.follow_suggestions = function(count) {
  return axios.get(`/social/follow_suggestions?count=${count}`);
};

API.get_notifications = function() {
  return axios.get(`/social/notifications`)
}
API.get_notifications_tab = function() {
  return axios.get(`/social/notifications_tab`)
}
API.get_notifications_length = function(comp_id) {
  return axios.get(`/social/notifications_length?comp_id=${comp_id}`)
}

API.toggle_notification_read = function(id) {
  return axios.post(`social/notification/mark_read`,{user_notification_id:id})
}

API.feed = function(lastPostId) {
  return axios.get(`/social/feed?lastPostId=${lastPostId}`);
};

API.post = function(content) {
  return axios.post(`/social/post`, { content: content });
};

API.comment = function(post_id, content) {
  return axios.post(`/social/comment`, { feed_post_id: post_id, content: content });
};

API.getComments = function(post_id) {
  return axios.get(`/social/comment`, { params: { feed_post_id: post_id } });
};

API.deletePost = function(post_id) {
  return axios.delete(`/social/post`, { data: { post_id: post_id } });
};

API.deleteComment = function(comment_id) {
  return axios.delete(`/social/comment`, { data: { comment_id: comment_id } });
};

API._like = function(target_id, type) {
  return axios.post(`/social/like`, { related_object_id: target_id, related_object_type: type });
}

API._dislike = function(target_id, type) {
  return axios.post(`/social/dislike`, { related_object_id: target_id, related_object_type: type });
}

API.likePost = function(target_id) {
  return API._like(target_id, "FeedPost");
};

API.likeComment = function(target_id) {
  return API._like(target_id, "FeedPostComment");
};

API.dislikePost = function(target_id) {
  return API._dislike(target_id, "FeedPost");
};

API.dislikeComment = function(target_id) {
  return API._dislike(target_id, "FeedPostComment");
};

API.likeList =  function(post_id){
  return axios.get(`/social/like_list`, { params: { post_id: post_id } });
}

export default API;
