import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { AppContext } from "../../../../App";
import Loader from "../../loader";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { z } from "zod";
import img1 from "../../../../img/trialpageimage.png";
import logo from "../../../../img/watermarksffinal.png";
import "./create_sandbox.css";
// Zod schema for form validation
const formSchema = z.object({
  firstName: z.string().min(2, "First Name must be at least 2 characters"),
  lastName: z.string().min(2, "Last Name must be at least 2 characters"),
  email: z.string().email("Email is invalid"),
  password: z.string().min(6, "Password must be at least 6 characters"),
  companyName: z.string().min(2, "Company Name must be at least 2 characters"),
  workspaceName: z
    .string()
    .min(2, "Workspace Name must be at least 2 characters"),
  workspaceType: z.string().min(1, "Workspace Type is required"),
  referrerSource: z.string().min(1, "Please select how you heard about us"),
  otherReferrerSource: z.string().optional(),
  acceptTerms: z.boolean().refine((val) => val === true, {
    message: "You must accept the terms and conditions",
  }),
});

export default function CreateSandbox() {
  const { user } = useContext(AppContext);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    companyName: "",
    workspaceName: "",
    workspaceType: "",
    referrerSource: "",
    otherReferrerSource: "",
    acceptTerms: false,
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showOtpPopup, setShowOtpPopup] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState("");
  const [sending, setSending] = useState(false);
  const [resendTimer, setResendTimer] = useState(0);

  useEffect(() => {
    if (resendTimer > 0) {
      const timer = setTimeout(() => setResendTimer(resendTimer - 1), 1000);
      return () => clearTimeout(timer);
    }
  }, [resendTimer]);

  const workspaceTypes = [
    "Accelerator",
    "Venture Capital Fund",
    "Angel Groups",
    "Pitch Competitions",
    "Hackathon",
    "Innovation Challenges",
    "Incubator",
    "Grants",
    "Debt Financing",
    "Other",
  ];

  const referrerSources = [
    "Networking/Conference",
    "LinkedIn",
    "Instagram",
    "Twitter/X",
    "Email",
    "Website",
    "Introduction",
    "Search Engine",
    "Other",
  ];

  // Keep all existing handler functions
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue =
      type === "checkbox"
        ? checked
        : name === "companyName" || name === "workspaceName"
          ? value
          : value.trim();
    setFormData({ ...formData, [name]: newValue });

    try {
      const fieldSchema = z.object({ [name]: formSchema.shape[name] });
      fieldSchema.parse({ [name]: newValue });
      setErrors({ ...errors, [name]: "" });
    } catch (err) {
      if (err instanceof z.ZodError) {
        const fieldError = err.errors[0]?.message;
        setErrors({ ...errors, [name]: fieldError });
      }
    }
  };

  const validateForm = () => {
    try {
      formSchema.parse(formData);
      setErrors({});
      return true;
    } catch (err) {
      if (err instanceof z.ZodError) {
        const newErrors = {};
        err.errors.forEach((error) => {
          newErrors[error.path[0]] = error.message;
        });
        setErrors(newErrors);
      }
      return false;
    }
  };

  const sendOtp = async () => {
    setSending(true);
    try {
      await axios.post("/resend_otp", { email: formData.email });
      setSending(false);
      setResendTimer(120);
    } catch (err) {
      setOtpError("Failed to send OTP. Please try again.");
      setSending(false);
    }
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    setIsSubmitting(true);
    try {
      const response = await axios.post("/create_user_with_otp", {
        first_name: formData.firstName,
        last_name: formData.lastName,
        email: formData.email,
        password: formData.password,
        referrer_source:
          formData.referrerSource === "Other"
            ? formData.otherReferrerSource
            : formData.referrerSource,
      });

      if (response.data.status === "existing_user") {
        setErrors({
          ...errors,
          apiError:
            "User already exists. Try logging in or contact support if you need assistance.",
        });
        return;
      }
      setShowOtpPopup(true);
    } catch (error) {
      console.error("Error submitting form:", error);
      setErrors({
        ...errors,
        apiError:
          error ||
          "An error occurred while submitting the form. Please try again.",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    if (!otp) {
      setOtpError("Please enter OTP");
      return;
    }
    if (otp.length !== 6) {
      setOtpError("OTP must be 6 digits");
      return;
    }

    try {
      setIsSubmitting(true);
      const response = await axios.post("/verify_otp", {
        email: formData.email,
        otp: otp,
      });

      if (response.data.status === "success") {
        setShowOtpPopup(false);
        const sandboxResponse = await axios.post("/create_company_sandbox", {
          company_name: formData.companyName,
          user_id: response.data.data._id,
          workspace_name: formData.workspaceName,
          workspace_type: formData.workspaceType,
        });

        if (sandboxResponse.data.status === "success") {
          window.location.href = `/applicant_room_dash?opportunity_id=${sandboxResponse.data.result.opportunity._id}`;
        } else if (sandboxResponse.data.status === "Already Submitted") {
          setOtpError("Company already exists");
          setShowOtpPopup(true);
        } else {
          setOtpError("Failed to create sandbox environment");
          setShowOtpPopup(true);
        }
      } else {
        setOtpError("Invalid OTP. Please try again.");
        setShowOtpPopup(true);
      }
    } catch (err) {
      setOtpError("Verification failed. Please try again.");
      setShowOtpPopup(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleOtpChange = (e) => {
    const value = e.target.value;
    if (value === "" || /^\d+$/.test(value)) {
      setOtp(value);
      setOtpError("");
    }
  };

  if (user.loading) {
    return <Loader />;
  }

  return (
    <div className="signin_container">
      <div className="signin_cover" style={{ width: "70%" }}>
        <img style={{ objectFit: "fill" }} src={img1} alt="" />
      </div>
      <div className="main_signin">
        <div className="side_signin_form ">
          <div className="logo-container">
            <img src={logo} alt="" />
          </div>
          <h1 className="title mb-4">DiligenceGPT Trial</h1>
          <p className="text-muted mb-4 description">
            Enter your details below to begin your free 14-day trial. A member
            of our team will be in touch should you have any questions or
            require technical support.
          </p>

          <div className="signin_form">
            <div className="form-group mb-4">
              <input
                type="text"
                name="firstName"
                placeholder="First Name"
                value={formData.firstName}
                onChange={handleInputChange}
                className="form-control block-example border"
              />
              {errors.firstName && (
                <span className="error mt-2">{errors.firstName}</span>
              )}
            </div>

            <div className="form-group mb-4">
              <input
                type="text"
                name="lastName"
                placeholder="Last Name"
                value={formData.lastName}
                onChange={handleInputChange}
                className="form-control block-example border"
              />
              {errors.lastName && (
                <span className="error mt-2">{errors.lastName}</span>
              )}
            </div>

            <div className="form-group mb-4">
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleInputChange}
                className="form-control block-example border"
              />
              {errors.email && (
                <span className="error mt-2">{errors.email}</span>
              )}
            </div>

            <div className="form-group mb-4" style={{ position: "relative" }}>
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                placeholder="Password"
                value={formData.password}
                onChange={handleInputChange}
                className="form-control block-example border"
              />
              <span
                onClick={() => setShowPassword(!showPassword)}
                style={{
                  position: "absolute",
                  right: "15px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                }}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </span>
              {errors.password && (
                <span className="error mt-2">{errors.password}</span>
              )}
            </div>

            <div className="form-group mb-4">
              <input
                type="text"
                name="companyName"
                placeholder="Company Name"
                value={formData.companyName}
                onChange={handleInputChange}
                className="form-control block-example border"
              />
              {errors.companyName && (
                <span className="error mt-2">{errors.companyName}</span>
              )}
            </div>

            <div className="form-group mb-4">
              <input
                type="text"
                name="workspaceName"
                placeholder="Workspace Name"
                value={formData.workspaceName}
                onChange={handleInputChange}
                className="form-control block-example border"
              />
              {errors.workspaceName && (
                <span className="error mt-2">{errors.workspaceName}</span>
              )}
            </div>

            <div className="form-group mb-4">
              <select
                name="workspaceType"
                value={formData.workspaceType}
                onChange={handleInputChange}
                className="form-control block-example border"
                style={{
                  appearance: "auto",
                  WebkitAppearance: "auto",
                  MozAppearance: "auto",
                }}
              >
                <option value="">Organization Type</option>
                {workspaceTypes.map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </select>
              {errors.workspaceType && (
                <span className="error mt-2">{errors.workspaceType}</span>
              )}
            </div>

            <div className="form-group mb-4">
              <select
                name="referrerSource"
                value={formData.referrerSource}
                onChange={handleInputChange}
                className="form-control block-example border"
                style={{
                  appearance: "auto",
                  WebkitAppearance: "auto",
                  MozAppearance: "auto",
                }}
              >
                <option value="">Where did you hear about us?</option>
                {referrerSources.map((source) => (
                  <option key={source} value={source}>
                    {source}
                  </option>
                ))}
              </select>
              {formData.referrerSource === "Other" && (
                <input
                  type="text"
                  name="otherReferrerSource"
                  placeholder="Please specify"
                  value={formData.otherReferrerSource}
                  onChange={handleInputChange}
                  className="form-control block-example border mt-2"
                />
              )}
              {errors.referrerSource && (
                <span className="error mt-2">{errors.referrerSource}</span>
              )}
            </div>

            <div className="form-group mb-4">
              <label
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
                className="p-2"
              >
                <input
                  type="checkbox"
                  name="acceptTerms"
                  checked={formData.acceptTerms}
                  onChange={handleInputChange}
                />
                <span>
                  I accept the terms and conditions and privacy policy
                </span>
              </label>
              {errors.acceptTerms && (
                <span className="error mt-2">{errors.acceptTerms}</span>
              )}
            </div>
            {errors.apiError && (
              <div className="alert alert-danger mb-4">{errors.apiError}</div>
            )}

            <button
              onClick={handleSubmit}
              disabled={isSubmitting}
              className="btn btn-primary btn-lg btn-block login-button w-100"
            >
              {isSubmitting ? "Starting Trial..." : "Start Trial"}
            </button>
          </div>
        </div>
      </div>

      {showOtpPopup && (
        <div className="otp-popup">
          <div className="otp-content">
            <button
              onClick={() => setShowOtpPopup(false)}
              className="close-btn"
            >
              ×
            </button>
            <h2>Verify Your Email</h2>
            <p>We've sent a verification code to {formData.email}</p>
            <form onSubmit={handleOtpSubmit}>
              <div className="otp-input-container">
                <label>Enter 6-digit OTP</label>
                <input
                  type="text"
                  placeholder="Enter OTP"
                  value={otp}
                  onChange={handleOtpChange}
                  maxLength={6}
                />
                {otpError && <div className="otp-error">{otpError}</div>}
              </div>
              <div className="otp-buttons">
                <button
                  type="button"
                  onClick={sendOtp}
                  disabled={sending || resendTimer > 0}
                  className="resend-btn"
                >
                  {sending
                    ? "Sending..."
                    : resendTimer > 0
                      ? `Resend in ${resendTimer}s`
                      : "Resend Code"}
                </button>
                <div className="verify-buttons">
                  <button
                    type="button"
                    onClick={() => setShowOtpPopup(false)}
                    className="cancel-btn"
                  >
                    Cancel
                  </button>
                  <button type="submit" className="verify-btn">
                    Verify
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}
