import React from 'react';

const Rating = props => (
    <div className='__static-ratings-card __application-room-padding'>
        <div className='__stars-rating'>
            <div className='__title __rating-title'>Overall</div>
            <div className='__graphical-ratings'>
                <i className='material-icons'>star</i>
                <i className='material-icons'>star</i>
                <i className='material-icons'>star</i>
                <i className='material-icons'>star</i>
                <i className='material-icons'>star</i>
                <i className='material-icons'>star</i>
                <i className='material-icons __outlined'>star</i>
                <i className='material-icons __outlined'>star</i>
                <i className='material-icons __outlined'>star</i>
                <i className='material-icons __outlined'>star</i>
                <div>Based on 7 reviews</div>
            </div>
            <div>7/10</div>
        </div>
        <div className='__stars-rating'>
            <div className='__rating-title'>Idea</div>
            <div className='__graphical-ratings'>
                <i className='material-icons'>star</i>
                <i className='material-icons'>star</i>
                <i className='material-icons'>star</i>
                <i className='material-icons'>star</i>
                <i className='material-icons'>star</i>
                <i className='material-icons'>star</i>
                <i className='material-icons'>star</i>
                <i className='material-icons'>star</i>
                <i className='material-icons __outlined'>star</i>
                <i className='material-icons __outlined'>star</i>
            </div>
            <div>8/10</div>
        </div>
        <div className='__stars-rating'>
            <div className='__rating-title'>Tech</div>
            <div className='__graphical-ratings'>
                <i className='material-icons'>star</i>
                <i className='material-icons'>star</i>
                <i className='material-icons'>star</i>
                <i className='material-icons'>star</i>
                <i className='material-icons'>star</i>
                <i className='material-icons'>star</i>
                <i className='material-icons'>star</i>
                <i className='material-icons __outlined'>star</i>
                <i className='material-icons __outlined'>star</i>
                <i className='material-icons __outlined'>star</i>
            </div>
            <div>7/10</div>
        </div>
        <div className='__stars-rating'>
            <div className='__rating-title'>Marketing</div>
            <div className='__graphical-ratings'>
                <i className='material-icons'>star</i>
                <i className='material-icons'>star</i>
                <i className='material-icons'>star</i>
                <i className='material-icons'>star</i>
                <i className='material-icons'>star</i>
                <i className='material-icons __outlined'>star</i>
                <i className='material-icons __outlined'>star</i>
                <i className='material-icons __outlined'>star</i>
                <i className='material-icons __outlined'>star</i>
                <i className='material-icons __outlined'>star</i>
            </div>
            <div>5/10</div>
        </div>
        <div className='__stars-rating'>
            <div className='__rating-title'>Team</div>
            <div className='__graphical-ratings'>
                <i className='material-icons'>star</i>
                <i className='material-icons'>star</i>
                <i className='material-icons'>star</i>
                <i className='material-icons'>star</i>
                <i className='material-icons'>star</i>
                <i className='material-icons'>star</i>
                <i className='material-icons'>star</i>
                <i className='material-icons'>star</i>
                <i className='material-icons __outlined'>star</i>
                <i className='material-icons __outlined'>star</i>
            </div>
            <div>8/10</div>
        </div>
        {props.children}
    </div>
)

export default Rating;