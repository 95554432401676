/* (2019-06-10) - This page is specifically for SF Admins.
This page allows an admin to make a user that can act as a referral partner.
*/
import React from "react";
import axios from "axios";
import "../main.css";
import {
  Button,
  Alert,
  Form,
  FormControl,
  FormLabel,
  Radio,
  Row,
} from "react-bootstrap";
import { Helmet } from "react-helmet";
import SiteTitle from "./components/site_title.js";
import { individual_user_type_list } from "../boilerplate/list.json";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import InputBase from "@material-ui/core/InputBase";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing.unit * 3,
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    width: "auto",
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

const styles = (theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing.unit,
  },
  bootstrapFormLabel: {
    fontSize: 18,
  },
});

class Create_User extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      first_name: "",
      last_name: "",
      user_type: "",
      excelDetails: {
        file: "",
        file_name: "",
        file_type: "",
      },
    };
  }

  handleChange = (e) => {
    const name = e.target.name;
    this.setState({
      [name]: e.target.value,
    });
  };

  validateResult = (data, callback) => {
    if (data.status === "success" || data.status === "partial") {
      callback(data, this);
    } else if (data.status === "redirect") {
      this.props.history.push(data.redirect_to);
    } else if (data.status === "error") {
    }
  };

  handleInputSubmit = (e) => {
    e.preventDefault();

    axios
      .post("/admin_create_user", this.state)
      .then((response) => {
        this.validateResult(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <SiteTitle />
        <div class="container">
          <span
            class="show-excel"
            style={{
              color: "Green",
              float: "right",
              paddingRight: "30px",
              fontSize: "30px",
            }}
          >
            <i class="fas fa-file-csv"></i>
          </span>
          <div class="main">
            <div class="panel-heading">
              <div class="panel-title text-center">
                <h1 class="title">Register a new user or referral partner</h1>
                <hr className="mb-5" />
              </div>
            </div>
            <div class="main-login main-center form-horizontal mb-5">
              <div className="form-gorup">
                <FormLabel className="control-label">User Type</FormLabel>
                <Select
                  value={this.state.user_type}
                  onChange={this.handleChange}
                  input={<Input id="select-multiple-chip" />}
                  name="user_type"
                  style={{ display: "inherit" }}
                  className={classes.textField}
                  renderValue={(selected) => (
                    <div>
                      <MenuItem
                        key={this.state.user_type}
                        value={this.state.user_type}
                      >
                        {this.state.user_type}
                      </MenuItem>
                    </div>
                  )}
                  MenuProps={MenuProps}
                >
                  {individual_user_type_list.map((name) => (
                    <MenuItem key={name} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
                <br />
                <FormLabel className="control-label">First Name</FormLabel>
                <FormControl
                  type="text"
                  value={this.state.first_name}
                  placeholder="First Name"
                  name="first_name"
                  onChange={this.handleChange}
                  className="form-control mb-3"
                />
              </div>
              <FormLabel className="control-label">Last Name</FormLabel>
              <FormControl
                type="text"
                value={this.state.last_name}
                placeholder="Last Name"
                name="last_name"
                onChange={this.handleChange}
                className="form-control mb-3"
              />
              <FormLabel className="control-label">Email</FormLabel>
              <FormControl
                type="email"
                value={this.state.email}
                placeholder="User Email"
                name="email"
                onChange={this.handleChange}
                className="form-control mb-3"
              />
              <FormLabel className="control-label">Password</FormLabel>
              <FormControl
                type="password"
                value={this.state.password}
                placeholder="User Password"
                name="password"
                onChange={this.handleChange}
                className="form-control mb-3"
              />
              <Button
                type="submit"
                onClick={this.handleInputSubmit}
                className="btn btn-primary btn-lg btn-block login-button my-1"
              >
                Submit
              </Button>
              <div className="text-center mt-3">
                <a href="/sign_in">Log-in</a>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Create_User.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(Create_User);
