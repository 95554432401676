import React, { useState, useEffect } from "react";
import { animateScroll as scroll } from "react-scroll";
import * as S from "./NavBarElements";

import img from "../../../img/NewPitchBoxLogo.png";

const NavBar = () => {
  const [scrollnav, setScrollnav] = useState(false);

  const changeNav = () => {
    if (window.scrollY >= 70) {
      setScrollnav(true);
    } else {
      setScrollnav(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", changeNav);
    return () => window.removeEventListener("scroll", changeNav);
  }, []);

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <S.NavContainer scrollnav={scrollnav}>
      <S.NavLogo to="/" onClick={toggleHome}>
        <S.Img src={img} />
      </S.NavLogo>

      <S.NavBtnWrap>
        <S.NavBtnLink
          to="//services.startupfuel.com/pitchbox-pricing"
          scrollnav={scrollnav}
          target="_blank"
        >
          Pricing
        </S.NavBtnLink>

        <S.NavBtnLink
          to="//startupfuel.zohobookings.com/#/customer/pitchbox"
          scrollnav={scrollnav}
          target="_blank"
        >
          Book Free Demo
        </S.NavBtnLink>
      </S.NavBtnWrap>
    </S.NavContainer>
  );
};

export default NavBar;
