/* (2019-06-11) - This is the main component of the team section of the SIR.
It handles all the functions for adding and editing judges. It also maps through
the judges and displays them.
*/
import React, { Component, Fragment } from "react";
import axios from "axios";
import URL from "url-parse";
import "./index.scss";
import StarRatings from "react-star-ratings";
import { ProgressBar, Row, Col } from "react-bootstrap";
import Comment from "./../comment/Comment";
import Grid from "@material-ui/core/Grid";
import { Card } from "@material-ui/core";
import default_pic from "../../../../img/default-avatar.png";
import default_background from "../../../../img/bg.png";

import {
  company_type_list,
  industry_list,
  stage_list,
  business_type,
  vertical_list,
} from "../../../../boilerplate/list.json";

import {
  Button,
  Alert,
  Form,
  FormControl,
  FormLabel,
  Radio,
} from "react-bootstrap";
import { individual_user_type_list } from "../../../../boilerplate/list.json";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import { AppContext } from "../../../../App";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import SortBy from "../../../components/explore_sort_filter";

import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import DirectionsIcon from "@material-ui/icons/Directions";
import Icon from "@material-ui/core/Icon";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";

import ArgNav from "../argNav";






const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);
const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  icon: {
    margin: theme.spacing.unit * 2,
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
});
class Page_Container extends React.Component {
  constructor(props) {
    super(props);
  }
  static contextType = AppContext;
  render() {
    return <Page {...this.props} {...this.context} />;
  }
}
class Page extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      url: new URL(window.location.href, true),
      companies: [],
      selected_company_id: "",
      showCreateJudge: false,
      selected_company: "",
      showOpenReview: false,
      email: "",
      password: "",
      first_name: "",
      last_name: "",
      sub_vertical: "",
      user_type: "Judge",
      success_msg: "Member has succesfully been added!",
      showSuccessMsg: false,
      selected_judge_id: "",
      judges_with_reviews: [],
    };
  }

  componentDidMount() {
    axios
      .get(
        `fetch_companies_opportunity_applied?opportunity_id=${this.state.url.query.opportunity_id}`
      )
      .then((response) => {
        this.setState({
          companies: response.data.full_list,
        });
      });
    axios
      .get(
        `fetch_opportunity?opportunity_id=${this.state.url.query.opportunity_id}`
      )
      .then((response) => {
        this.setState({
          ...response.data.result,
        });
      });
  }

  getAvatarUrl = (array, file_type) => {
    let new_arr = array.filter((file) => {
      return file.file_type === file_type;
    });
    if (file_type === "company avatar") {
      if (new_arr.length !== 0) {
        return new_arr[0].file_url;
      } else {
        return default_pic;
      }
    }
    if (file_type === "company background") {
      if (new_arr.length !== 0) {
        return new_arr[0].file_url;
      } else {
        return default_background;
      }
    }
  };
  getStage = (value, array) => {
    for (let i = 0; i < array.length; i++) {
      if (array[i].value === value) {
        return array[i].text;
      }
    }
  };

  handleToggle = (e) => {
    e.preventDefault();
    this.setState({
      showCreateJudge: !this.state.showCreateJudge,
    });
  };
  handleChange = (e) => {
    const name = e.target.name;
    this.setState({
      [name]: e.target.value,
      showSuccessMsg: false,
    });
  };
  validateResult = (data, callback) => {
    if (data.status === "success" || data.status === "partial") {
      callback(data, this);
    } else if (data.status === "redirect") {
      this.props.history.push(data.redirect_to);
    } else if (data.status === "error") {
    }
  };

  handleInputSubmit = (e) => {
    e.preventDefault();

    axios
      .post("/create_user_judge", this.state)
      .then((response) => {
        this.props.persistUser(response.data.data);
        axios
          .post("/create_judge_opportunity_affiliation", {
            user: response.data.data._id,
            opportunity: this.state.url.query.opportunity_id,
          })
          .then((response) => {});
      })
      .catch((error) => {
        console.log(error);
      });
  };

  showEditJudge = (judge_id) => {
    if (judge_id !== this.state.selected_judge_id) {
      this.setState({
        selected_judge_id: judge_id,
      });
    } else {
      this.setState({
        selected_judge_id: "",
      });
    }
  };
  handleEditJudge = (e, judge_id) => {
    e.preventDefault();
    axios
      .post(`/edit_judge_for_opportunity`, {
        judge_id: judge_id,
        sub_vertical: this.state.sub_vertical,
      })
      .then((response) => {
        this.setState({
          selected_judge_id: "",
        });
        this.props.refresh_judges();
      });
  };
  handleDeleteJudge = (e, judge_id) => {
    e.preventDefault();
    axios
      .post(
        `/delete_judge_from_opportunity?opportunity_id=${this.state.url.query.opportunity_id}`,
        {
          judge_id: judge_id,
        }
      )
      .then((response) => {
        this.props.refresh_judges();
      });
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
                <div className='__content mx-2'> 
                  <div className="__pb-page py-2">
                  <div className="__application-room-margin">
                    <div className="__judges-table-card">
                      <table class="table align-items-center mb-0 outline-none">
                          <thead>
                            <tr>
                              <th class="__judge-name-column">Team Member</th>
                              <th class="__judge-email-column">Email</th>
                              <th class="__judge-email-column">Role</th>
                              
                              <th class="__judge-reviews-column">Deals Added</th>
                            </tr>
                          </thead>
                          <tbody>
                          {this.props.judges_with_reviews &&
                          this.props.judges_with_reviews.map((row) => (
                            <tr>
                              <td class="judge-name-column">
                                <div class="d-flex px-2">
                                  <div class="my-auto">
                                      <h6 class="mt-1 text-sm __email-row-name">{row.first_name + " " + row.last_name}</h6>
                                  </div>
                                </div>
                              </td>
                              <td class="judge-email-column">
                                <div class="d-flex px-2">
                                  <div class="my-auto">

                                      <h6 class="mb-0 text-xs">{row.email}</h6>
                                  </div>
                                </div>
                              </td>
                              <td class="judge-role-column">
                                <div class="d-flex px-2">
                                  <div class="my-auto">
                                      <h6 class="mt-1 text-sm __email-row-name">{row.sub_vertical}</h6>
                                  </div>
                                </div>
                              </td>
                              <td class="__judge-reviews-column">
                                <div className="">
                                  <div className="allTags">
                                    <div class="my-auto">
                                      <h6 class="mb-0 text-sm">
                                      {row.reviews.length}
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))}

                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
        </div>
      </div>
    );
  }
}

Page_Container.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Page_Container);
