/* (2019-06-10) - This is the page that appears after a user registers using
  the register link that appears in the menu.
*/
import React from 'react';
import '../main.css';
import PropTypes from 'prop-types';
import URL from 'url-parse';
import Navigation from './components/navbar'
import SiteTitle from './components/site_title.js'
import { Sign_In, Register, Forgot_Password } from './components/popups'
import { AppContext } from '../App'
import { withStyles } from '@material-ui/core/styles';
import SideMenu from './SideMenu';
import { Link } from 'react-router-dom';
import './welcomeUBaid.css';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing.unit,
  },
  bootstrapFormLabel: {
    fontSize: 18,
  },
});

class Create_User_Company_Container extends React.Component {
  constructor(props) {
    super(props);
  }
  static contextType = AppContext;
  render() {
    return (
      <Create_User_Company
        {...this.props}
        {...this.context} />
    )
  }
}

class Create_User_Company extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: new URL(window.location.href, true),
      email: '',
      password: '',
      first_name: "",
      last_name: '',
      company_name: "",
      company_type: "",
    }
  }

  handleChange = (e) => {
    const name = e.target.name;
    this.setState({
      [name]: e.target.value
    }, () => {
      if (name === "address") {
        this.getLocation()
      }
    })
  }

  handleChangeMultiple = (e) => {
    const name = e.target.name;
    let selected_values = []
    Array.from(e.target.selectedOptions).forEach(function (item) {
      selected_values.push(item.value);
    });
    this.setState({
      [name]: selected_values
    })
  }
  getStage = (value, array) => {
    for (let i = 0; i < array.length; i++) {
      if (array[i].value === value) {
        return array[i].text
      }
    }
  }


  render() {
    let company_owned = this.props.companies_owned!==undefined && this.props.companies_owned!==null && this.props.companies_owned[1]!==undefined && this.props.companies_owned[1]!==null?this.props.companies_owned[1][0].company_type[0]:""
    const iframe ='<iframe frameborder="0" style="height:1500px;width:99%;border:none;overflow:none;" src="https://forms.zohopublic.com/startupfuelcom/form/FundMyStartupInvestorRegistration/formperma/0ztB-Nq8fIIZqqL1ULGoZOb8fcq40mNRXeGeSydfevI"></iframe>'
    const iframe_func= function () {
      return {
        __html: iframe
      }
    }
    return (

      <React.Fragment>
        <SiteTitle />
        <Navigation />
        <Sign_In />
        <Register />
        <SideMenu />
        <Forgot_Password />
        <div class="container ubaidWelcomePageParent">
        <div dangerouslySetInnerHTML={ iframe_func() } />

        </div>
      </React.Fragment>
    )
  }
}
Create_User_Company_Container.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(Create_User_Company_Container);
