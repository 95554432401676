import React from "react";

export default function ComingSoon() {
  return (
    <div className="px-3">
      <h4>Coming Soon</h4>
      <p className="text-grey text-sm">
        This feature is currently under development. Stay tuned for updates!
      </p>
      <div className="flex justify-between">
        <div className="mr-4">
          <button className="summarize-button" disabled>
            Coming Soon...
          </button>
        </div>
      </div>
    </div>
  );
}
