import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import {
  FormControl,
  FormLabel,
  Button,
  Form,
  Row,
  Col,
  Card,
} from "react-bootstrap";
import update from "immutability-helper";
import URL from "url-parse";

import {
  company_type_list,
  industry_list,
  stage_list,
  business_type,
  vertical_list,
  type_of_service_provider,
  product_categories,
} from "../../../boilerplate/list.json";
import popupPic from "../../../img/rocket.png";
import developmentPic from "../../../img/development_icon.png";
import Grid from "@material-ui/core/Grid";
import { AppContext } from "../../../App";
import axios from "axios";
import waterfall from "async/waterfall";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import FormGroup from "@material-ui/core/FormGroup";
import CardMedia from "@material-ui/core/CardMedia";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input";
import Icon from "@material-ui/core/Icon";
import classNames from "classnames";
import InputLabel from "@material-ui/core/InputLabel";
import Chip from "@material-ui/core/Chip";
import ListItemText from "@material-ui/core/ListItemText";
import { SocialIcon } from "react-social-icons";
import VideoIcon from "@material-ui/icons/Videocam";
import ImageIcon from "@material-ui/icons/Image";
import StarRatings from "react-star-ratings";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function getStyles(name, that) {
  return {
    fontWeight:
      that.state.name.indexOf(name) === -1
        ? that.props.theme.typography.fontWeightRegular
        : that.props.theme.typography.fontWeightMedium,
  };
}
const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: theme.spacing.unit / 4,
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 100,
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 100,
  },
});

const currencies = [
  {
    value: "USD",
    label: "$",
  },
  {
    value: "EUR",
    label: "€",
  },
  {
    value: "BTC",
    label: "฿",
  },
  {
    value: "JPY",
    label: "¥",
  },
];

export class Create_Product_Popup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formIsValids: false,
      rating: 0,
      comments: "",
      errors: {},
    };
  }
  componentDidUpdate(prevProps) {}
  changeRating = (newRating, name) => {
    this.setState({
      [name]: newRating,
    });
  };

  handleChange = (e) => {
    const name = e.target.name;
    this.setState({
      [name]: e.target.value,
    });
  };
  handleToggle = (e) => {
    const name = e.target.name;
    this.setState({
      [name]: !this.state[name],
    });
  };

  handleValidation = (event) => {
    let errors = {};
    let formIsValid = true;

    //Opportunity Name
    if (this.state.rating === 0) {
      formIsValid = false;
      errors["rating"] = " Please provide a rating";
    }
    if (this.state.comments.length === 0) {
      formIsValid = false;
      errors["comments"] = " Please leave a comment";
    }

    this.setState({ errors: errors }, () => {
      if (formIsValid) {
        this.setState({
          formIsValids: true,
        });

        this.handleInputSubmit(event);
      }
    });
  };

  handleInputSubmit = (e) => {
    e.preventDefault();
    let review = {
      rating: this.state.rating,
      comment: this.state.comments,
      reviewee: this.props.reviewee,
    };
    this.props.submit_review(review);
  };
  getStage = (value, array) => {
    for (let i = 0; i < array.length; i++) {
      if (array[i].value === value) {
        return array[i].text;
      }
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <div
        style={this.props.show ? { display: "block" } : { display: "none" }}
        onClick={(event) => this.props.dismissModal(event)}
        class="modal1"
      >
        <form class="modal-content animate">
          <div
            class="container"
            style={{ padding: "30px", textAlign: "center" }}
          >
            <div>
              <h3 style={{ textAlign: "center" }}>
                Leave a review for {this.props.reviewee}?
              </h3>
            </div>
            <br />
            <FormLabel className="control-label">
              How would you rate your experience?
            </FormLabel>
            <span className="text-danger">{this.state.errors["rating"]}</span>
            <br />
            <StarRatings
              rating={this.state.rating}
              starRatedColor="#E6B800"
              changeRating={this.changeRating}
              numberOfStars={10}
              name="rating"
              starDimension="25px"
              starSpacing="0"
            />
            <br />
            <div style={{ width: "70%", margin: "auto", marginTop: "20px" }}>
              <span className="text-danger">
                {this.state.errors["comments"]}
              </span>
              <TextField
                id="outlined-textarea"
                label="Comments"
                placeholder="Describe your experience..."
                multiline
                fullWidth
                onChange={this.handleChange}
                value={this.state.comments}
                name="comments"
                variant="outlined"
              />
            </div>
            <br />
            <br />

            <Button
              onClick={(event) => this.handleValidation(event)}
              className="btn btn-primary btn-lg btn-block login-button"
            >
              Submit
            </Button>
          </div>
        </form>
      </div>
    );
  }
}
Create_Product_Popup.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(Create_Product_Popup);
