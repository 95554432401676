/* (2019-06-11) - The filters used on the explore page.
 */
import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import {
  country_list,
  opportunity_type_list,
  industry_list,
  stage_list,
  business_type,
  company_type_list,
} from "../../boilerplate/list.json";
import Button from "@material-ui/core/Button";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
  formControl: {
    margin: theme.spacing.unit,
    width: "100%",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: theme.spacing.unit / 4,
  },
  noLabel: {
    marginTop: theme.spacing.unit * 3,
  },
  button: {
    margin: theme.spacing.unit,
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: "auto",
    },
  },
};

function getStyles(name, that) {
  return {
    fontWeight:
      that.state.name.indexOf(name) === -1
        ? that.props.theme.typography.fontWeightRegular
        : that.props.theme.typography.fontWeightMedium,
  };
}

function handleDelete() {
  alert("You clicked the delete icon.");
}

function handleClick() {
  alert("You clicked the Reset.");
}

class MultipleSelect extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    name: [],
  };

  keyPressed(event) {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root + " ubaidFilterBoxMain"}>
        <div className="FilterUbaidForm FilterBoxFormSearch">
          <FormControl className={classes.formControl + " ubaidForm"}>
            <form>
              <input
                value={this.props.query_search}
                placeholder="Search for..."
                onKeyPress={this.keyPressed}
                ref={(input) => (this.search = input)}
                onChange={this.props.handleSearchInputChange}
                class="form-control mt-2"
              />
              {this.props.results.map((r) => (
                <li key={r.id}>{r.name}</li>
              ))}
            </form>
          </FormControl>
        </div>
        <div className="FilterUbaidForm UbaidFormMarginLr">
          <FormControl className={classes.formControl + " ubaidForm"}>
            <InputLabel htmlFor="select-multiple-chip">Industry</InputLabel>
            <Select
              multiple
              value={this.props.filters.industry || []}
              onChange={this.props.handleFilterChange}
              input={<Input id="select-multiple-chip" />}
              name="industry"
              renderValue={(selected) => (
                <div className={classes.chips}>
                  {selected.map((value) => (
                    <Chip
                      key={value}
                      label={value}
                      className={classes.chip}
                      onDelete={this.props.handleSingleDelete(
                        value,
                        "industry"
                      )}
                    />
                  ))}
                </div>
              )}
              MenuProps={MenuProps}
            >
              {industry_list.map((name) => (
                <MenuItem key={name} value={name} style={getStyles(name, this)}>
                  <Checkbox
                    checked={
                      this.props.filters.industry !== undefined
                        ? this.props.filters.industry.indexOf(name) > -1
                        : ""
                    }
                  />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="FilterUbaidForm UbaidFormMarginR">
          <FormControl className={classes.formControl + " ubaidForm"}>
            <InputLabel htmlFor="select-multiple-chip">Country</InputLabel>
            <Select
              multiple
              value={this.props.filters.country || []}
              onChange={this.props.handleFilterChange}
              input={<Input id="select-multiple-chip" />}
              name="country"
              renderValue={(selected) => (
                <div className={classes.chips}>
                  {selected.map((value) => (
                    <Chip
                      key={value}
                      label={value}
                      className={classes.chip}
                      onDelete={this.props.handleSingleDelete(value, "country")}
                    />
                  ))}
                </div>
              )}
              MenuProps={MenuProps}
            >
              {country_list.map((name) => (
                <MenuItem key={name} value={name} style={getStyles(name, this)}>
                  <Checkbox
                    checked={
                      this.props.filters.country !== undefined
                        ? this.props.filters.country.indexOf(name) > -1
                        : ""
                    }
                  />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="FilterUbaidForm UbaidFormMarginR">
          <FormControl className={classes.formControl + " ubaidForm"}>
            <InputLabel htmlFor="select-multiple-chip">User Type</InputLabel>
            <Select
              multiple
              value={this.props.filters.company_type || []}
              onChange={this.props.handleFilterChange}
              input={<Input id="select-multiple-chip" />}
              name="company_type"
              renderValue={(selected) => (
                <div className={classes.chips}>
                  {selected.map((value) => (
                    <Chip
                      key={value}
                      label={value}
                      className={classes.chip}
                      onDelete={this.props.handleSingleDelete(
                        value,
                        "company_type"
                      )}
                    />
                  ))}
                </div>
              )}
              MenuProps={MenuProps}
            >
              {company_type_list.map((name) => (
                <MenuItem key={name} value={name} style={getStyles(name, this)}>
                  <Checkbox
                    checked={
                      this.props.filters.company_type !== undefined
                        ? this.props.filters.company_type.indexOf(name) > -1
                        : ""
                    }
                  />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="FilterUbaidForm UbaidFormMarginR">
          <FormControl className={classes.formControl + " ubaidForm"}>
            <InputLabel htmlFor="select-multiple-chip">Stage</InputLabel>
            <Select
              multiple
              value={this.props.filters.stage || []}
              onChange={this.props.handleFilterChange}
              input={<Input id="select-multiple-chip" />}
              name="stage"
              renderValue={(selected) => (
                <div className={classes.chips}>
                  {selected.map((value) => (
                    <Chip
                      key={value}
                      label={this.props.getStage(value, stage_list)}
                      className={classes.chip}
                      onDelete={this.props.handleSingleDelete(value, "stage")}
                    />
                  ))}
                </div>
              )}
              MenuProps={MenuProps}
            >
              {stage_list.map((name) => (
                <MenuItem
                  key={name.value}
                  value={name.value}
                  style={getStyles(name, this)}
                >
                  <Checkbox
                    checked={
                      this.props.filters.stage !== undefined
                        ? this.props.filters.stage.indexOf(name.value) > -1
                        : ""
                    }
                  />
                  <ListItemText primary={name.text} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div>
          <Button
            variant="contained"
            color="primary"
            // className={classes.button + " FilterUbaidBTNIn"}
            style={{ marginRight: "2px", marginTop: "9px" }}
            onClick={this.props.resetFilters}
          >
            Reset
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ marginTop: "9px" }}
            // className={classes.button + " FilterUbaidBTNIn"}
            onClick={this.props.openModal}
          >
            Add Company
          </Button>
        </div>
      </div>
    );
  }
}

MultipleSelect.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, {
  withTheme: true,
})(MultipleSelect);
