import React from "react";
import axios from "axios";
import { Route } from "react-router-dom";
import update from "immutability-helper";
import "./inbox.scss";
import "./components/profile.css";
import {
  Button,
  Alert,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  Card,
  Col,
  Row,
  Image,
  Navbar,
  Jumbotron,
  Nav,
  NavItem,
} from "react-bootstrap";
import { Helmet } from "react-helmet";
import URL from "url-parse";
import default_pic from "../img/default_company_pic.png";
import default_background from "../img/bg.png";
import StartupFuel from "../img/watermarksffinal.png";
import Loader from "./components/loader";
import { AppContext } from "../App";
import Navigation from "./components/navbar";
import {
  Company_Update,
  Sign_In,
  Register,
  Forgot_Password,
  Alert_Message_To_User,
  User_Profile_Photo_Update,
  Explore_Share_Popup,
  Generic_Success_Message,
} from "./components/popups";
import Hire_For_Project from "./components/popups/hire_for_project";
import { User_Profile_Card } from "./components/cards";
import { stage_list } from "../boilerplate/list.json";
import SiteTitle from "./components/site_title.js";
import { withStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import InputBase from "@material-ui/core/InputBase";
import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";
import DisplayToggle from "./components/DisplayToggle";
import SideMenu from "./SideMenu";
import classnames from "classnames";
import "./inbox__ubaid.scss";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Moment from "moment";
import ThreeDotsIcon from "../newsfeed/icons/ThreeDotsIcon";
import ProfileImage from "./components/profile_image";
import Linkify from "react-linkify";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function getStyles(name, that) {
  return {
    fontWeight:
      that.state.name.indexOf(name) === -1
        ? that.props.theme.typography.fontWeightRegular
        : that.props.theme.typography.fontWeightMedium,
  };
}
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);
const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: theme.spacing.unit / 4,
  },
  textField: {
    width: "100%",
    display: "block",
    padding: "15px 0",
    maxWidth: 500,
    margin: "0 auto",
    border: "1px solid #ccc",
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 100,
  },
});
class User_Profile_Container extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return <User_Profile {...this.props} {...this.context} />;
  }
}
User_Profile_Container.contextType = AppContext;
class User_Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: new URL(window.location.href, true),
      conversations: [],
      company_id: "",
      new_message: "",
      selectedConversationId: 1,
      user_initialized: false,
      message_recipient: "5cd191c6e2b041002986837c",
      current_conversation: "",
      loaded: false,
      showConversationList: true,
      showConversationDetails: true,
      loading: true,
      employer: "",
      employee: "",

      show_project: false,
      showSuccessPopup: false,
      menu_selected: 0,
    };
  }

  submit_message = (e) => {
    e.preventDefault();
    let message = {
      to: this.state.message_recipient,
      from: this.state.company_id,
      content: this.state.new_message,
      time: new Date(),
    };
    let messages = this.state.current_conversation.messages.concat([message]);
    this.setState({
      conversations: this.state.conversations.map((el, index) =>
        el._id === this.state.current_conversation._id
          ? Object.assign({}, el, {
              messages: this.state.conversations[index].messages.concat([
                message,
              ]),
            })
          : el,
      ),
      new_message: "",
      current_conversation: {
        ...this.state.current_conversation,
        messages: messages,
      },
    });
    axios
      .post("/update_conversation", {
        message_id: this.state.current_conversation._id,
        messages: messages,
        to: this.state.message_recipient,
      })
      .then((response) => {});
  };
  componentDidMount() {
    axios.get("/fetch_conversations").then((response) => {
      if (response.data.status === "success") {
        this.setState(
          {
            conversations: response.data.result,
            original_conversations: response.data.result,
            current_conversation: response.data.result[0],
            selectedConversationId: response.data.result[0]._id,
          },
          () => {
            this.setState({
              employee:
                this.state.current_conversation.users_information.filter(
                  (comp) => comp.comp_id !== this.state.company_id,
                ),
              employer:
                this.state.current_conversation.users_information.filter(
                  (comp) => comp.comp_id === this.state.company_id,
                ),
            });
          },
        );
      } else {
        this.setState({
          loading: false,
        });
      }
    });
  }
  componentDidUpdate(prevProps) {
    const isMobileDevice = window.matchMedia("(max-width: 760px)").matches;
    const { showConversationList, showConversationDetails } = this.state;
    if (isMobileDevice) {
      const isMessageUrl = this.props.history.location.pathname
        .trim()
        .includes("/inbox/message");
      showConversationList &&
        isMessageUrl &&
        this.setState({
          showConversationList: false,
          showConversationDetails: true,
        });
      showConversationDetails &&
        !isMessageUrl &&
        this.setState({
          showConversationList: true,
          showConversationDetails: false,
        });
    } else {
      !showConversationList ||
        (!showConversationDetails &&
          this.setState({
            showConversationDetails: true,
            showConversationList: true,
          }));
    }

    if (
      prevProps !== this.props &&
      this.props.companies_owned !== undefined &&
      this.props.companies_owned !== null &&
      this.props.companies_owned[1] !== undefined &&
      this.props.companies_owned[1] !== null &&
      this.props.companies_owned[1][0] !== undefined &&
      !this.state.user_initialized
    )
      this.setState(
        {
          company_id: this.props.companies_owned[1][0]._id,
          user: this.props.user._id,
          user_initialized: true,
          loaded: true,
        },
        () => {
          if (this.state.conversations.length > 0) {
            this.setState({
              message_recipient: this.state.current_conversation.users.filter(
                (user) => user !== this.state.company_id,
              )[0],
              employer:
                this.state.current_conversation.users_information.filter(
                  (comp) => comp.comp_id === this.state.company_id,
                ),
              employee:
                this.state.current_conversation.users_information.filter(
                  (comp) => comp.comp_id !== this.state.company_id,
                ),
            });
          }
        },
      );
  }

  read_conversation = () => {
    axios
      .post("/read_conversation", {
        message_id: this.state.current_conversation._id,
      })
      .then((response) => {
        if (response.data.status === "success") {
          console.log("success");
          axios.get("/fetch_conversations").then((response) => {
            if (response.data.status === "success") {
              this.setState(
                {
                  original_conversations: response.data.result,
                },
                () => {
                  let engaged_convos = this.state.conversations.filter(
                    (convo) =>
                      convo.messages.some(
                        (msg) => msg.from != this.state.company_id,
                      ),
                  );
                  if (this.state.menu_selected === 0) {
                    this.setState({
                      conversations: this.state.original_conversations,
                    });
                  } else {
                    this.setState({
                      conversations: engaged_convos,
                    });
                  }
                },
              );
            }
          });
        }
      });
  };
  handleMenuChange = (event, value) => {
    this.setState(
      {
        menuSelection: value,
      },
      () => {
        this.props.history.push(
          `/profile?company_id=${this.state.url.query.company_id}&tab=${this.state.menuSelection}`,
        );
      },
    );
  };
  engaged_convo_clicked = (convo_list) => {
    this.setState({
      conversations: convo_list,
      menu_selected: 1,
    });
  };
  reset_convo_list = () => {
    this.setState({
      conversations: this.state.original_conversations,
      menu_selected: 0,
    });
  };

  handleChange = (e) => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  openConversation = (id) => {
    if (id !== this.state.selectedConversationId) {
      this.setState({
        new_message: "",
      });
    }
    let arr = this.state.conversations.filter((conv) => conv._id === id);
    this.props.history.push("/inbox/message");
    this.setState(
      {
        current_conversation: arr[0],
      },
      () => {
        this.setState({
          message_recipient: this.state.current_conversation.users.filter(
            (user) => user !== this.state.company_id,
          )[0],
          selectedConversationId: this.state.current_conversation._id,
          employee: this.state.current_conversation.users_information.filter(
            (comp) => comp.comp_id !== this.state.company_id,
          ),
          employer: this.state.current_conversation.users_information.filter(
            (comp) => comp.comp_id === this.state.company_id,
          ),
        });
        this.read_conversation();
      },
    );
  };
  dismissModal = (e) => {
    (e.target.className === "modal1" || e.target.className === "cancelbtn") &&
      this.setState({
        show_project: false,
        showSuccessPopup: false,
      });
  };
  show_success_popup = () => {
    this.setState({
      show_project: false,
      showSuccessPopup: true,
    });
  };
  show_hire = (e) => {
    this.setState({
      show_project: true,
    });
  };

  render() {
    const { showConversationDetails, showConversationList } = this.state;
    return (
      <React.Fragment>
        <SiteTitle />
        <Navigation />
        <Sign_In />
        <Forgot_Password />
        <Register />
        <SideMenu />
        <Hire_For_Project
          show={this.state.show_project}
          show_success_popup={this.show_success_popup}
          dismissModal={this.dismissModal}
          service={this.state.current_conversation.associated_service}
          employee={
            Array.isArray(this.state.employee) &&
            this.state.employee[0] !== undefined &&
            this.state.employee[0].comp_id
          }
          employer={
            Array.isArray(this.state.employer) &&
            this.state.employer[0] !== undefined &&
            this.state.employer[0].comp_id
          }
        />
        <Generic_Success_Message
          show={this.state.showSuccessPopup}
          popup_header="Invitation Successfully Sent!"
          dismiss={this.dismissModal}
        />
        <div
          className="App UbaidLeft70 __ubaid__inbox"
          style={{ marginTop: "50px", height: "80vh" }}
        >
          {this.state.loaded ? (
            <>
              {this.state.conversations.length > 0 ? (
                <div
                  className="inbox-container"
                  style={{
                    width: "80%",
                    margin: "auto",
                    height: "-webkit-fill-available",
                  }}
                >
                  {showConversationList && (
                    <ConversationList
                      conversations={this.state.conversations}
                      onConversationSelected={this.openConversation}
                      selectedConversationId={this.state.selectedConversationId}
                      currentSection={this.state.currentSection}
                      current_company={this.state.company_id}
                      engaged_convo_clicked={this.engaged_convo_clicked}
                      reset_convo_list={this.reset_convo_list}
                      original_conversations={this.state.original_conversations}
                    />
                  )}
                  {showConversationDetails && (
                    <ConversationDetails
                      goBack={this.props.history.goBack}
                      conversation={this.state.current_conversation}
                      onDelete={(id) => {
                        this.deleteMessage(id);
                      }}
                      new_message={this.state.new_message}
                      handleChange={this.handleChange}
                      submit_message={this.submit_message}
                      current_company={this.state.company_id}
                      read_conversation={this.read_conversation}
                      show_hire={this.show_hire}
                    />
                  )}
                </div>
              ) : (
                <h3>You have no messages at this time.</h3>
              )}
            </>
          ) : (
            <>
              {this.state.loading ? (
                <div className={classnames("SocialLoadingIcon")}>
                  <ThreeDotsIcon />
                </div>
              ) : (
                <h1 style={{ textAlign: "center" }}>
                  You must sign in to view your inbox
                </h1>
              )}
            </>
          )}
        </div>
      </React.Fragment>
    );
  }
}
const ConversationListItem = ({
  conversation,
  onConversationClicked,
  selected,
  current_company,
}) => {
  let classes = "conversation-item";
  if (selected) {
    classes += " selected";
  }
  let company =
    conversation !== undefined &&
    conversation.users_information !== undefined &&
    current_company !== undefined
      ? conversation.users_information.filter(
          (comp) => comp.comp_id !== current_company,
        )
      : [];

  return (
    <>
      {conversation !== undefined &&
      company.length > 0 &&
      conversation.users_information !== undefined &&
      current_company !== undefined ? (
        <div
          onClick={() => {
            onConversationClicked(conversation._id);
          }}
          className={`${classes}`}
        >
          <>
            <div
              className="conversation-item__unread-dot"
              data-read={
                conversation.users_information.filter(
                  (user) => user.comp_id === current_company,
                )[0].read
              }
            ></div>
            <div className="conversation-item__subject truncate">
              {company[0].company_name} ({company[0].company_type}) -{" "}
              {conversation.subject}
            </div>
            <div className="conversation-item__details">
              <span className="conversation-item__from truncate">
                {
                  conversation.messages[conversation.messages.length - 1]
                    .content
                }
              </span>
              <span className="conversation-item__time truncate">
                {Moment(conversation.last_updated).format("MMMM Do YYYY")}
              </span>
            </div>
          </>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
class ConversationDetails extends React.Component {
  componentDidMount() {
    this.scrollToBottomAuto();
  }

  componentDidUpdate() {
    this.scrollToBottomSmooth();
  }

  scrollToBottomAuto() {
    this.el.scrollIntoView({ behavior: "auto" });
  }
  scrollToBottomSmooth() {
    this.el.scrollIntoView({ behavior: "smooth" });
  }
  handleText(msg) {
    var urlRegex = /(https?:\/\/[^\s]+)/g;
    return msg.replace(urlRegex, function (url) {
      return '<a href="' + url + '">' + url + "</a>";
    });
  }
  render() {
    var {
      conversation,
      onDelete,
      new_message,
      handleChange,
      submit_message,
      current_company,
      read_conversation,
      show_hire,
    } = this.props;
    return (
      <>
        <section className={`msger ${this.props.className}`}>
          <div className="conversation-content__header">
            <div className="conversation-content__subject">
              <i className="fas fa-arrow-left" onClick={this.props.goBack}></i>
              <strong>{conversation.subject}</strong>
              {conversation.users_information.filter(
                (comp) => comp.comp_id !== current_company,
              )[0] &&
                conversation.users_information.filter(
                  (comp) => comp.comp_id !== current_company,
                )[0].company_type === "Service Provider" && (
                  <button
                    type="submit"
                    className="__hire-btn"
                    onClick={show_hire}
                  >
                    Hire
                  </button>
                )}
            </div>

            <div className="conversation-content__time">
              {Moment(conversation.last_updated).format("MMMM Do YYYY")}
            </div>
            {conversation.users_information.some(
              (comp) => comp.comp_id !== current_company,
            ) ? (
              <div className="conversation-content__from">
                {
                  conversation.users_information.filter(
                    (comp) => comp.comp_id !== current_company,
                  )[0].company_name
                }
              </div>
            ) : (
              <></>
            )}
          </div>
          <main class="msger-chat">
            {conversation.messages !== undefined &&
              conversation.messages.length > 0 &&
              conversation.messages.map((msg) => (
                <>
                  {msg.from !== current_company ? (
                    <>
                      <div class="msg left-msg">
                        <div
                          class="msg-img"
                          style={{ width: "36px", height: "36px" }}
                        >
                          <a href={`/profile?company_id=${msg.from}`}>
                            <ProfileImage company_id={msg.from} />
                          </a>
                        </div>

                        <div
                          class="msg-bubble"
                          style={{ background: "#ececec" }}
                        >
                          <div class="msg-info">
                            <div class="msg-info-name">
                              {
                                conversation.users_information.filter(
                                  (user) => user.comp_id === msg.from,
                                )[0].company_name
                              }
                            </div>
                          </div>

                          <div class="msg-text" style={{ textAlign: "left" }}>
                            <Linkify>{msg.content}</Linkify>
                          </div>
                          <div
                            style={{ float: "left", fontSize: "0.55em" }}
                            class="msg-info-time"
                          >
                            {Moment(msg.time).format("MMMM Do, h:mm a")}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div class="msg right-msg">
                      <div
                        class="msg-img"
                        style={{ width: "36px", height: "36px" }}
                      >
                        <a href={`/profile?company_id=${msg.from}`}>
                          <ProfileImage company_id={msg.from} />
                        </a>
                      </div>

                      <div class="msg-bubble" style={{ background: "#579ffb" }}>
                        <div class="msg-info">
                          <div class="msg-info-name">
                            {
                              conversation.users_information.filter(
                                (user) => user.comp_id === msg.from,
                              )[0].company_name
                            }
                          </div>
                        </div>

                        <div class="msg-text" style={{ textAlign: "right" }}>
                          <Linkify>{msg.content}</Linkify>
                        </div>
                        <div
                          style={{ float: "right", fontSize: "0.55em" }}
                          class="msg-info-time"
                        >
                          {Moment(msg.time).format("MMMM Do, h:mm a")}
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ))}
            <div
              ref={(el) => {
                this.el = el;
              }}
            />
          </main>

          <form class="msger-inputarea">
            <input
              type="text"
              class="msger-input"
              name="new_message"
              autoComplete="off"
              onChange={handleChange}
              onClick={read_conversation}
              value={new_message}
              placeholder="Enter your message..."
            />
            <button
              type="submit"
              class="msger-send-btn"
              onClick={submit_message}
            >
              Send
            </button>
          </form>
        </section>
      </>
    );
  }
}

/* ConversationList contains a list of Conversation components */
const ConversationList = ({
  conversations,
  onConversationSelected,
  selectedConversationId,
  current_company,
  engaged_convo_clicked,
  reset_convo_list,
  original_conversations,
}) => {
  const [value, setValue] = React.useState(0);

  const handleMenChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 1) {
      engaged_convo_clicked(engaged_convos);
    } else {
      reset_convo_list();
    }
  };
  if (conversations.length === 0) {
    return (
      <div className="conversation-list empty">
        Nothing to see here, great job!
      </div>
    );
  }
  let engaged_convos = conversations.filter((convo) =>
    convo.messages.some((msg) => msg.from != current_company),
  );

  return (
    <div className="conversation-list">
      <BottomNavigation
        value={value}
        style={{
          width: "100%",
          backgroundColor: "#f1e8e89c",
          textAlign: "center",
        }}
        onChange={handleMenChange}
        class="companyNavBarOverflow"
        showLabels
      >
        <BottomNavigationAction
          label="All"
          value={0}
          icon={original_conversations.length}
        />
        <BottomNavigationAction
          label="Active"
          value={1}
          icon={engaged_convos.length}
        />
      </BottomNavigation>

      {conversations.map((conversation) => {
        return (
          <ConversationListItem
            onConversationClicked={(id) => {
              onConversationSelected(id);
            }}
            conversation={conversation}
            selected={selectedConversationId === conversation._id}
            current_company={current_company}
          />
        );
      })}
    </div>
  );
};
User_Profile_Container.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(User_Profile_Container);
