/* (2019-06-10) - This is the company profile page, it contains the background
photo, profile photo, and all of the content up to the tabs. It then references
the components used on the rest of the page. The components it references  are
the each of the individual tabs (overview, pitch deck, opportunities, settings)
These components can be found under /components/profiles
*/
import React from "react";
import axios from "axios";
import update from "immutability-helper";
import { SocialIcon } from "react-social-icons";
import {
  Navbar,
  Nav,
  Row,
  Col,
  Card,
  Button,
  ButtonToolbar,
  Container,
  FormLabel,
} from "react-bootstrap";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import "./company_follwer_banner.css";
import "./components/profile.css";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import { Explore_Share_Popup } from "../live/components/popups";
import SocialAPI from "./api/social";
import "./company_custom_ubaid.css";
import "./company_custom_u.css";
import SummaryIcon from "./../img/SummaryIcon.png";
import Tooltip from "@material-ui/core/Tooltip";

import Profile_Completeness from "../live/components/profile_completeness";
import { Helmet } from "react-helmet";
import URL from "url-parse";
import Create_Product_Popup from "./components/popups/create_a_product";
import Start_A_Conversation_Popup from "./components/popups/start_a_conversation";
import Edit_Product_Popup from "./components/popups/edit_a_product";
import Create_Service_Popup from "./components/popups/create_a_service";
import Edit_Service_Popup from "./components/popups/edit_a_service";
import Followers_List_Popup from "./components/popups/profile_followers";
import Following_List_Popup from "./components/popups/profile_following";
import ProfileImage from "./components/profile_image";
import default_pic from "../img/default_company_pic.png";
import default_background from "../img/bg.png";
import rocket from "../img/rocketlaunch.jpg";
import StartupFuel from "../img/watermarksffinal.png";
import { IoIosArrowRoundUp } from "react-icons/io";
import { MdMyLocation } from "react-icons/md";
import { FaInstagram } from "react-icons/fa";
import { FiTwitter } from "react-icons/fi";
import { RiFacebookCircleLine } from "react-icons/ri";
import { MdOutlineShare } from "react-icons/md";

import Loader from "./components/loader";
import {
  Profile_Photo_Update,
  Cover_Photo_Update,
  Company_Update,
  Sign_In,
  Register,
  Alert_Message_To_User,
  Development_Message_To_User,
  Development_Question_Form,
  Forgot_Password,
  Generic_Success_Message,
} from "./components/popups";
import {
  country_list,
  opportunity_type_list,
  industry_list,
  stage_list,
  business_type,
  company_type_list,
  base_profile_fields,
} from "../boilerplate/list.json";
import { distinctions_list } from "../boilerplate/distinctions_list";
import SiteTitle from "./components/site_title.js";
import Overview from "./components/profiles/overview.js";
import PitchDeck from "./components/profiles/pitch_deck.js";
import Settings from "./components/profiles/settings.js";
import Opportunities from "./components/profiles/opportunities.js";
import Application_To_Opportunities from "./components/profiles/application_to_opportunities.js";
import Analytics from "./components/profiles/analytics.js";
import Assessments from "./components/profiles/assessments";
import "./components/profiles/picture.css";
import Moment from "moment";

import { AppContext } from "../App";
import Navigation from "./components/navbar";
import Footer from "./components/footer";

import { Map, GoogleApiWrapper, InfoWindow, Marker } from "google-maps-react";
import Geocode from "react-geocode";

import CurrentLocation from "./Map";
import SideMenu from "./SideMenu";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function getStyles(name, that) {
  return {
    fontWeight:
      that.state.name.indexOf(name) === -1
        ? that.props.theme.typography.fontWeightRegular
        : that.props.theme.typography.fontWeightMedium,
  };
}

const currencies = [
  {
    value: "USD",
    label: "$",
  },
  {
    value: "EUR",
    label: "€",
  },
  {
    value: "BTC",
    label: "฿",
  },
  {
    value: "JPY",
    label: "¥",
  },
];

class Profile_Container extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return <Profile {...this.props} {...this.context} />;
  }
}
Profile_Container.contextType = AppContext;
class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: new URL(window.location.href, true),
      group_list: [],
      showEditPopup: false,
      showSharePopup: false,

      showPopupMessage: false,
      showDevelopmentPopupSuccessMessage: false,
      showDevelopmentPopupMessage: false,
      communities: [],

      showCreateProductPopup: false,
      showEditProductPopup: false,
      selected_product_id: "",

      showCreateServicePopup: false,
      showEditServicePopup: false,
      selected_service_id: "",

      showFollowersPopup: false,
      showFollowingPopup: false,
      follow_count: 0,
      following_count: 0,

      showCoverPhotoPopup: false,
      showProfilePhotoPopup: false,
      showEditTeam: false,
      showEditTeamMember: 999,
      showEditOne: false,
      showEditTwo: false,
      showEditThree: false,
      showEditFour: false,
      showEditFive: false,
      showEditSix: false,
      showEditSeven: false,
      showEditEight: false,
      showEditNine: false,
      showEditTen: false,
      showEditEleven: false,
      showEditTwelve: false,
      showEditThirteen: false,
      showEditFourteen: false,
      showEditFifteen: false,
      showEditSixteen: false,
      showEditSeventeen: false,
      showEditEighteen: false,
      showEditNineteen: false,
      showEditTwenty: false,
      showEditTwentyOne: false,
      showEditTwentyTwo: false,
      showEditTwentyThree: false,
      showEditTwentyFour: false,
      showEditTwentyFive: false,

      showCreateMessagePopup: false,
      showCreateMessageSuccessPopup: false,

      showSignInPopup: false,
      inPreviewMode: false,
      allow_investor: false,
      alert_message: "",

      is_following: false,
      follow_loading: false,

      menuSelection: 0,
      team_list: [],
      manual_team_list: [],
      new_team_member: {
        teamMemberProfilePictureDetails: {
          file: "",
          file_name: "",
          file_type: "",
        },
        name: "",
        title: "",
        linkedIn: "",
      },

      userIsProfileOwner: false,
      avatarDetails: {
        file: "",
        file_name: "",
        file_type: "",
      },
      backgroundDetails: {
        file: "",
        file_name: "",
        file_type: "",
      },
      pitchdeckDetails: {
        file: "",
        file_name: "",
        file_type: "",
      },
      background_video_link: "",

      loading: true,

      //for form
      company_name: "",
      company_type: "Startup",
      street_number: "",
      short_bio: "",
      detailed_description: "",
      company_website: "",
      street_name: "",
      city: "",
      province: "",
      country: "",
      industry: "",
      stage: "",
      business_model: "",
      distinction: [],
      company_files: [],

      facebook: "",
      twitter: "",
      linkedIn: "",
      instagram: "",
      pitch_video_link: "",
      old_pitch_video_link: "",
      process_pitch_video: "",
      opportunity_list: [],
      company_products: [],
      company_services: [],

      iOS_app_url: "",
      android_app_url: "",

      looking_for: "", //opportunitiies, opportunity type, raising funds, purpose, etc
      objective: "",
      profitable: "",
      problem: "",
      target_market: "",
      market_size: "",
      solution: "",
      competitors: "",
      differentiation: "",
      revenue_model: "",
      revenue_since_inception: "",
      growth: "",
      traction: "",
      marketing_strategy: "",
      team_description: "",

      attempting_to_raise: 0,
      last_raise: "",
      evaluation: 0,
      raising_funds: "",
      date_of_inception: "",
      month_of_inception: "",
      year_of_inception: "",
      profile_completeness: 0,
      show_profile_completeness: true,

      contact_email: "",
      contact_phone: "",

      incubator_accelerator_history: "",

      fintech_sub_vertical: "",
      finance_mtl_q1: "",
      finance_mtl_q2: "",
      finance_mtl_q3: "",
      finance_mtl_q4: "",

      lat: 0,
      long: 0,
      address: "",
      old_address: "",
      formatted_address: "",
      location_processed: 0,
      running: 0,

      needs_help_with: "",
      popup_header: "",
      popup_body: "",

      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
      settings: {
        weeklyOpportunities: true,
        weeklyServices: true,
        likesEmail: true,
        commentsEmail: true,
        followerEmail: true,
        followPostEmail: true,
      },
      companies_owned2: [],
      currency: "USD",
      currencies: [
        {
          value: "USD",
          label: "$",
        },
        {
          value: "EUR",
          label: "€",
        },
        {
          value: "BTC",
          label: "฿",
        },
        {
          value: "JPY",
          label: "¥",
        },
      ],
    };
  }

  handleSuccessfulConversation = () => {
    this.setState({
      showCreateMessagePopup: false,
      showCreateMessageSuccessPopup: true,
    });
  };

  handleToggleEditTeam = (e, value) => {
    if (this.state.showEditTeamMember !== value) {
      this.setState({
        showEditTeamMember: value,
      });
    } else {
      this.setState({
        showEditTeamMember: 999,
      });
    }
  };
  handleTeamEditSubmit = (e) => {
    this.setState(
      {
        showEditTeamMember: 999,
      },
      () => {
        this.handleInputSubmit(e);
      },
    );
  };
  handleManualTeamChange = (e, index, key) => {
    let arr = this.state.manual_team_list;
    arr[index][key] = e.target.value;
    this.setState({
      manual_team_list: arr,
    });
  };
  handleMainTeamChange = (e) => {
    let name = e.target.name;
    const new_arr = this.state.team_list;
    new_arr[0].user_company_role = e.target.value;
    this.setState({
      team_list: new_arr,
    });
  };
  handleToggleEdit = (e, value) => {
    let name = value;
    this.setState({
      [name]: !this.state[name],
    });
  };
  handleMainTeamSubmit = (e) => {
    e.preventDefault();
    axios
      .post(`/edit_user_company_profile?user_id=${this.props.user._id}`, {
        user_company_role: this.state.team_list[0].user_company_role,
      })
      .then((response) => {
        this.setState({
          showEditTeam: false,
        });
      });
  };
  handleFileUpload = (e) => {
    let file = e.target.files[0];
    if (e.target.dataset.fileType === "avatar") {
      this.setState({
        avatarDetails: {
          file,
          file_name: "profile avatar",
          file_type: `company ${e.target.dataset.fileType}`,
          object_ref: "company",
          object_ref_value: this.state.url.query.company_id,
        },
      });
    } else if (e.target.dataset.fileType === "pitchdeck") {
      this.setState({
        pitchdeckDetails: {
          file,
          file_name: "pitchdeck",
          file_type: `company ${e.target.dataset.fileType}`,
          object_ref: "company",
          object_ref_value: this.state.url.query.company_id,
        },
      });
    } else if (e.target.dataset.fileType === "team member") {
      this.setState({
        new_team_member: {
          ...this.state.new_team_member,
          teamMemberProfilePictureDetails: {
            file,
            file_name: "team profile",
            file_type: `company ${e.target.dataset.fileType}`,
            object_ref: "company",
            object_ref_value: this.state.url.query.company_id,
          },
        },
      });
    } else {
      this.setState({
        backgroundDetails: {
          file,
          file_name: "profile background",
          file_type: `company ${e.target.dataset.fileType}`,
          object_ref: "company",
          object_ref_value: this.state.url.query.company_id,
        },
      });
    }
  };

  getStage = (value, array) => {
    for (let i = 0; i < array.length; i++) {
      if (array[i].value === value) {
        return array[i].text;
      }
    }
  };
  getDistinction = (value, field) => {
    const prope = distinctions_list.find((p) => p.value === value);
    return prope[field];
  };
  getCurrencySymbol = (value, array) => {
    for (let i = 0; i < array.length; i++) {
      if (array[i].value === value) {
        return array[i].label;
      }
    }
  };

  handleFileSubmit = (type) => {
    let config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    let formData = new FormData();

    for (let key in this.state[type]) {
      formData.append(key, this.state[type][key]);
    }
    if (typeof this.state[type].file === "object") {
      axios
        .post(
          `/upload_file_secure?company_id=${this.state.url.query.company_id}`,
          formData,
          config,
        )
        .then((response) => {
          if (response.data.status === "success") {
            this.fetchData();
            this.getAvatarUrl();
            window.location.reload();

            if (
              (type === "avatarDetails" &&
                this.state.company_files.some(
                  (file) => file.file_type === "company avatar",
                )) ||
              (type === "backgroundDetails" &&
                this.state.company_files.some(
                  (file) => file.file_type === "company background",
                )) ||
              (type === "pitchdeckDetails" &&
                this.state.company_files.some(
                  (file) => file.file_type === "company pitchdeck",
                ))
            ) {
              //if (this.state.company_files.length > 0){
              let index = this.state.company_files.findIndex(
                (item) => item.file_type === response.data.result.file_type,
              );
              index = index === -1 ? this.state.company_files.length : index;
              this.state.company_files
                .filter(
                  (item_1) =>
                    item_1.file_type === response.data.result.file_type,
                )
                .map((item_2) => {
                  this.setState({
                    company_files: update(this.state.company_files, {
                      [index]: {
                        file_url: {
                          $set: response.data.result.file_url,
                        },
                      },
                    }),
                    // [type]: {
                    //   file: '',
                    //   file_name: '',
                    //   file_type: ''
                    // }
                    showEditPopup: false,
                  });
                });
              this.computeProfileCompleteness();
            } else {
              this.setState({
                company_files: [
                  ...this.state.company_files,
                  ...[
                    {
                      file_type: response.data.result.file_type,
                      file_url: response.data.result.file_url,
                    },
                  ],
                ],

                showEditPopup: false,
              });
              this.computeProfileCompleteness();
            }
          } // do else later
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      return;
    }
  };

  handleTeamFileSubmit = (type, callback) => {
    let config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    let formData = new FormData();
    for (let key in this.state.new_team_member[type]) {
      formData.append(key, this.state.new_team_member[type][key]);
    }
    if (typeof this.state.new_team_member[type].file === "object") {
      axios
        .post(
          `/upload_team_member_file?company_id=${this.state.url.query.company_id}`,
          formData,
          config,
        )
        .then((response) => {
          if (response.data.status === "success") {
            this.setState({
              new_team_member: {
                ...this.state.new_team_member,
                teamMemberProfilePictureDetails: {
                  file_type: response.data.result.file_type,
                  file_url: response.data.result.file_url,
                },
              },
            });
            callback();
          } // do else later
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      callback();
    }
  };

  computeProfileCompleteness = () => {
    let percent = 0;
    Object.keys(base_profile_fields).map((field) => {
      if (
        this.state[field] !== null &&
        base_profile_fields[field].type !== "file" &&
        this.state[field].length !== 0
      ) {
        percent += base_profile_fields[field].percent;
        this.setState({
          profile_completeness: percent,
        });
      } else if (
        base_profile_fields[field].type === "file" &&
        this.state.company_files.some(
          (x) => x.file_type === base_profile_fields[field].file_type,
        )
      ) {
        percent += base_profile_fields[field].percent;
        this.setState({
          profile_completeness: percent,
        });
      }
    });
  };
  handleProfileCompletenessSave = (e, state_field, value) => {
    if (state_field !== "date_of_inception") {
      this.setState(
        {
          ...this.state,
          [state_field]: value,
        },
        () => {
          this.handleInputSubmit(e);
          this.computeProfileCompleteness();
        },
      );
    } else {
      this.setState({
        date_of_inception: value,
      });
      const x = Moment(value).format("MMMM Do YYYY");
      const date_arr = String(x).split(" ");
      this.setState(
        {
          month_of_inception: date_arr[0],
          year_of_inception: date_arr[2],
        },
        () => {
          this.handleInputSubmit(e);
          this.computeProfileCompleteness();
        },
      );
    }
  };

  needsHelp = (e) => {
    this.setState(
      {
        needs_help_with: e.target.name,
      },
      () => {
        if (this.state.needs_help_with === "website") {
          this.setState(
            {
              popup_header: "Help is on its way!",
              popup_body:
                "We will have a web developer touch base with you shortly.",
            },
            () => {
              this.handleDevelopmentPopup();
            },
          );
        }
        if (this.state.needs_help_with === "iOS App") {
          this.setState(
            {
              popup_header: "Help is on its way!",
              popup_body:
                "We will have an iOS developer touch base with you shortly.",
            },
            () => {
              this.handleDevelopmentPopup();
            },
          );
        }
        if (this.state.needs_help_with === "android app") {
          this.setState(
            {
              popup_header: "Help is on its way!",
              popup_body:
                "We will have an android developer touch base with you shortly.",
            },
            () => {
              this.handleDevelopmentPopup();
            },
          );
        }
      },
    );
  };
  sendGetRequest = (url, callback) => {
    axios
      .get(url)
      .then((response) => {
        callback(response.data, this);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  deleteCompany = (e) => {
    e.preventDefault();
    axios
      .get(`/delete_company?company_id=${this.state.url.query.company_id}`)
      .then((response) => {
        this.props.history.push("/explore");
      });
  };
  deleteOpportunity = (e, opp_id) => {
    e.preventDefault();
    axios
      .get(`/delete_opportunity?opportunity_id=${opp_id}`)
      .then((response) => {
        this.fetchAssets(
          `opportunities_companies_owned?company_id=${this.state.url.query.company_id}`,
          function (data, _this) {
            if (data.status === "success" || data.status === "partial") {
              _this.setState({
                opportunities_owned:
                  data.result.length > 0 ? data.result : null,
              });
            }
          },
        );
      });
  };
  requestedHelp = (e) => {
    e.preventDefault();
    this.handleDevelopmentPopup();
    axios
      .post("/request_tech_work", {
        needs_help_with: this.state.needs_help_with,
        email: this.props.email,
        first_name: this.props.user.first_name,
        last_name: this.props.user.last_name,
        company_name: this.props.companies_owned[1][0].company_name,
      })
      .then((response) => {
        this.handleDevelopmentSuccessPopup();
      });
  };
  fetchAssets = (asset_type, callback) => {
    axios
      .get(`/fetch_${asset_type}`)
      .then((response) => {
        callback(response.data, this);
      })
      .catch((error) => {
        //this.props.history.push("/explore");
      });
  };

  fetchFollowStatus = () => {
    SocialAPI.followStatus(this.state.url.query.company_id).then((response) => {
      this.setState({
        is_following: response.data.is_following,
      });
    });
  };

  handleFollow = () => {
    this.setState({ follow_loading: true }, () => {
      if (!this.state.is_following) {
        SocialAPI.follow(this.state.url.query.company_id).then((response) => {
          this.setState({
            follow_loading: false,
            is_following: true,
          });
        });
      } else {
        SocialAPI.unfollow(this.state.url.query.company_id).then((response) => {
          this.setState({
            follow_loading: false,
            is_following: false,
          });
        });
      }
    });
  };

  check_profile_view = () => {
    if (
      this.props.companies_owned[1][0]._id !== this.state.url.query.company_id
    ) {
      axios.post("new_profile_view", {
        company_that_was_viewed: this.state.url.query.company_id,
        company_that_did_the_viewing: this.props.companies_owned[1][0]._id,
        company_name_that_did_the_viewing:
          this.props.companies_owned[1][0].company_name,
        company_type_that_did_the_viewing:
          this.props.companies_owned[1][0].company_type[0],
        company_image_that_did_the_viewing:
          this.props.user.files !== undefined &&
          this.props.user.files[0] !== undefined &&
          this.props.user.files[0].file_url !== undefined &&
          this.props.user.files[0].file_url !== null
            ? this.props.user.files[0].file_url
            : "",
      });
    }
  };

  componentDidMount() {
    this.fetchFollowStatus();
    this.fetchData();
    this.get_affiliated_opportunities();
    this.checkUserOwner();

    if (this.state.url.query.tab !== undefined) {
      this.setState({
        menuSelection: parseInt(this.state.url.query.tab),
      });
    }
    this.fetchAssets(
      `company_profile?company_id=${this.state.url.query.company_id}`,

      function (data, _this) {
        if (data.status === "success" || data.status === "partial") {
          _this.setState(
            {
              loading: false,
              ...data.result[0],
            },
            function () {
              _this.computeProfileCompleteness();
              _this.fetchAssets(
                `opportunities_companies_owned?company_id=${_this.state.url.query.company_id}`,
                function (data, __this) {
                  if (data.status === "success" || data.status === "partial") {
                    __this.setState({
                      opportunities_owned:
                        data.result.length > 0 ? data.result : null,
                    });
                  }
                },
              );
              axios
                .get(
                  `/fetch_applicant_communities?company_id=${_this.state.url.query.company_id}`,
                )
                .then((response) => {
                  _this.setState({
                    communities: response.data.result,
                  });
                });

              _this.fetchAssets(
                `profile_team?company_id=${_this.state.url.query.company_id}`,
                function (data, __this) {
                  console("profile_team");
                  console(data.result);
                  if (data.status === "success" || data.status === "partial") {
                    __this.setState({
                      team_list: data.result.length > 0 ? data.result : null,
                    });
                  }
                },
              );
              axios
                .post(
                  `/fetch_company_products?company_id=${_this.state.url.query.company_id}`,
                )
                .then((response) => {
                  if (
                    response.data.status === "success" ||
                    response.data.status === "partial"
                  ) {
                    this.setState({
                      company_products:
                        response.data.products.length > 0
                          ? response.data.products
                          : [],
                    });
                  }
                });
              axios
                .post(
                  `/fetch_company_services?company_id=${_this.state.url.query.company_id}`,
                )
                .then((response) => {
                  if (
                    response.data.status === "success" ||
                    response.data.status === "partial"
                  ) {
                    this.setState({
                      company_services:
                        response.data.services.length > 0
                          ? response.data.services
                          : [],
                    });
                  }
                });
            },
          );
        }
      },
    );
  }
  componentDidUpdate(prevProps) {
    if (
      Array.isArray(this.props.companies_owned) &&
      prevProps.companies_owned !== this.props.companies_owned
    ) {
      this.checkUserOwner();
      console.log("companies_user_owns");
      console.log(this.props.companies_owned);
      if (this.props.companies_owned.length <= 1) {
        /*this.setState({
          userIsProfileOwner: false,
        });*/
      }
    }
  }
  handleBackgroundVideo = (e, value) => {
    e.preventDefault();
    const url_arr = value.split("=");
    e.persist();
    this.setState(
      {
        background_video_link: "https://www.youtube.com/embed/" + url_arr[1],
        backgroundDetails: {
          file: "",
          file_name: "",
          file_type: "",
        },
      },
      () => {
        axios
          .post(`/edit_company?company_id=${this.state.url.query.company_id}`, {
            ...this.state,
            uid: this.props.user._id,
          })
          .then((response) => {
            this.fetchData();
            this.getAvatarUrl();
            window.location.reload();
          });
      },
    );
  };
  checkUserOwner = () => {
    console.log("check User Owner 222");
    //console.log(this.state.companies_owned2);

    /*var isOwned = false;
    this.state.companies_owned2.forEach(function (company) {
      if( this.state.url.query.company_id === company._id ) {
        isOwned = true;
      }
    }); 

    if(isOwned) {
      this.setState({
        userIsProfileOwner: true,
        running: 1,
      });
    }*/

    axios.get(`/companies_user_owns2`).then((response) => {
      var isOwned = false;

      for (let index = 0; index < response.data.result.length; index++) {
        console.log(
          "this.state.url.query.company_id = " +
            this.state.url.query.company_id,
        );
        console.log(
          "1                    company_id = " +
            (response.data.result[index]._id || "null"),
        );
        if (
          response.data.result[index]._id &&
          this.state.url.query.company_id === response.data.result[index]._id
        ) {
          isOwned = true;
          break;
        }
      }

      if (isOwned) {
        this.setState({
          userIsProfileOwner: true,
          running: 1,
        });
      }
    });

    setTimeout(() => {
      console.log("this.state.userIsProfileOwner");
      console.log(this.state.userIsProfileOwner);
    }, 3000);

    /*
    if (
      Array.isArray(this.props.companies_owned) &&
      this.props.companies_owned.length > 1 &&
      this.state.running === 0
    ) {
      if (this.props.companies_owned[1][0].company_type[0] === "Investor") {
        this.setState({
          viewing_user_company_type: "Investor",
          running: 1,
        });
      }
      if (
        this.props.companies_owned[1][0]._id === this.state.url.query.company_id
      ) {
        this.setState({
          userIsProfileOwner: true,
          running: 1,
        });
      }
      if (
        this.props.companies_owned[1][0]._id !== this.state.url.query.company_id
      ) {
        this.check_profile_view();

        this.setState({
          userIsProfileOwner: false,
          running: 1,
        });
      }
    }*/
  };
  saveTeamChange = (e) => {
    e.persist();
    this.handleTeamFileSubmit("teamMemberProfilePictureDetails", () => {
      this.setState(
        {
          manual_team_list: [
            ...this.state.manual_team_list,
            {
              ...this.state.new_team_member,
            },
          ],
        },
        () => {
          this.handleInputSubmit(e);
        },
      );
    });
  };
  handleTeamDelete = (e, index) => {
    let array = [...this.state.manual_team_list];
    array.splice(index, 1);
    e.persist();
    this.setState(
      {
        manual_team_list: array,
      },
      () => {
        this.handleInputSubmit(e);
      },
    );
  };
  handleTeamChange = (e) => {
    const name = e.target.name;
    this.setState({
      new_team_member: {
        ...this.state.new_team_member,
        [name]: e.target.value,
      },
    });
  };
  handleSettingsChange = (e) => {
    const name = e.target.name;
    console.log(name);
    e.persist();
    if (this.state.settings[name] !== undefined) {
      this.setState(
        {
          settings: {
            ...this.state.settings,
            [name]: !this.state.settings[name],
          },
        },
        () => {
          this.handleInputSubmit(e);
        },
      );
    } else {
      this.setState(
        {
          settings: {
            ...this.state.settings,
            [name]: false,
          },
        },
        () => {
          this.handleInputSubmit(e);
        },
      );
    }
  };

  handleChange = (e) => {
    const name = e.target.name;
    this.setState({
      [name]: e.target.value,
    });
  };
  handleInvestorChange = (e) => {
    const name = e.target.name;
    e.persist();
    this.setState(
      {
        allow_investor: !this.state.allow_investor,
        alert_message: "You have your changed your investor settings!",
      },
      () => {
        this.handleInputSubmit(e);
      },
    );
  };
  handleChangeCurrency = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };
  handleDateChange = (date) => {
    this.setState({
      date_of_inception: date,
    });
    const x = Moment(date).format("MMMM Do YYYY");
    const date_arr = String(x).split(" ");
    this.setState({
      month_of_inception: date_arr[0],
      year_of_inception: date_arr[2],
    });
  };

  handleMenuChange = (event, value) => {
    this.setState(
      {
        menuSelection: value,
      },
      () => {
        this.props.history.push(
          `/profile?company_id=${this.state.url.query.company_id}&tab=${this.state.menuSelection}`,
        );
      },
    );
  };
  handleEdit = (args) => {
    this.setState({
      ...this.state,
      [args]: !this.state[args],
    });
  };
  handleChangeMultiple = (e) => {
    const name = e.target.name;
    let selected_values = [];
    Array.from(e.target.selectedOptions).forEach(function (item) {
      selected_values.push(item.value);
    });
    this.setState({
      [name]: selected_values,
    });
  };
  get_affiliated_opportunities = () => {
    axios
      .get(
        `/fetch_opportunities_companies_applied?company_id=${this.state.url.query.company_id}`,
      )
      .then((response) => {
        this.setState({
          opportunity_list:
            response.data.result.length > 0 ? response.data.result : null,
        });
      });
  };

  getAvatarUrl = (array, file_type) => {
    if (array?.length > 0) {
      let new_arr = array.filter((file) => {
        return file.file_type === file_type;
      });
      if (new_arr.length !== 0) {
        return new_arr[0].file_url_optimized_1000_400 || new_arr[0].file_url;
      } else if (file_type === "company background") {
        if (
          this.state.temp_background_url !== undefined &&
          this.state.temp_background_url !== null &&
          this.state.temp_background_url.length > 0
        ) {
          return this.state.temp_background_url;
        } else {
          return default_background;
        }
      } else {
        return default_pic;
      }
    } else if (file_type === "company background") {
      if (
        this.state.temp_background_url !== undefined &&
        this.state.temp_background_url !== null &&
        this.state.temp_background_url.length > 0
      ) {
        return this.state.temp_background_url;
      } else {
        return default_background;
      }
    } else {
      return default_pic;
    }
  };
  getFileUrl = (array, file_type) => {
    if (array?.length > 0) {
      let new_arr = array.filter((file) => {
        return file.file_type === file_type;
      });
      if (new_arr.length !== 0) {
        return new_arr[0].file_url;
      }
    } else {
      return 0;
    }
  };

  handleEditPopup = (e) => {
    this.setState({
      showEditPopup: !this.state.showEditPopup,
    });
  };
  handleAlertPopup = () => {
    this.setState({
      showPopupMessage: !this.state.showPopupMessage,
    });
  };
  handleDevelopmentPopup = () => {
    this.setState({
      showDevelopmentPopupMessage: !this.state.showDevelopmentPopupMessage,
    });
  };
  handleDevelopmentSuccessPopup = () => {
    this.setState({
      showDevelopmentPopupSuccessMessage:
        !this.state.showDevelopmentPopupSuccessMessage,
    });
  };
  handleProfilePhotoEditPopup = (e) => {
    this.setState({
      showProfilePhotoPopup: !this.state.showProfilePhotoPopup,
    });
  };

  handleCoverPhotoEditPopup = (e) => {
    this.setState({
      showCoverPhotoPopup: !this.state.showCoverPhotoPopup,
    });
  };

  dismissModal = (e) => {
      this.setState({
        showEditPopup: false,
        showCoverPhotoPopup: false,
        showProfilePhotoPopup: false,
        showPopupMessage: false,
        showDevelopmentPopupMessage: false,
        showDevelopmentPopupSuccessMessage: false,
        showFollowersPopup: false,
        showFollowingPopup: false,

        showCreateProductPopup: false,
        showEditProductPopup: false,
        showCreateServicePopup: false,
        showEditServicePopup: false,
        showSharePopup: false,
        showCreateMessagePopup: false,
        showCreateMessageSuccessPopup: false,
      });
  };

  togglePopup = (e) => {
    this.setState({
      showSignInPopup: !this.state.showSignInPopup,
    });
  };
  toggleCreateProductPopup = (e) => {
    this.setState(
      {
        showCreateProductPopup: !this.state.showCreateProductPopup,
      },
      () => {
        if (this.state.showCreateProductPopup === false) {
          axios
            .post(
              `/fetch_company_products?company_id=${this.state.url.query.company_id}`,
            )
            .then((response) => {
              if (
                response.data.status === "success" ||
                response.data.status === "partial"
              ) {
                this.setState({
                  company_products:
                    response.data.products.length > 0
                      ? response.data.products
                      : [],
                });
              }
            });
        }
      },
    );
  };
  toggleEditProductPopup = (e) => {
    this.setState(
      {
        showEditProductPopup: !this.state.showEditProductPopup,
      },
      () => {
        if (this.state.showEditProductPopup === false) {
          axios
            .post(
              `/fetch_company_products?company_id=${this.state.url.query.company_id}`,
            )
            .then((response) => {
              if (
                response.data.status === "success" ||
                response.data.status === "partial"
              ) {
                this.setState({
                  company_products:
                    response.data.products.length > 0
                      ? response.data.products
                      : [],
                });
              }
            });
        }
      },
    );
  };

  toggleCreateServicePopup = (e) => {
    this.setState(
      {
        showCreateServicePopup: !this.state.showCreateServicePopup,
      },
      () => {
        if (this.state.showCreateServicePopup === false) {
          axios
            .post(
              `/fetch_company_services?company_id=${this.state.url.query.company_id}`,
            )
            .then((response) => {
              if (
                response.data.status === "success" ||
                response.data.status === "partial"
              ) {
                this.setState({
                  company_services:
                    response.data.services.length > 0
                      ? response.data.services
                      : [],
                });
              }
            });
        }
      },
    );
  };
  toggleEditServicePopup = (e) => {
    this.setState(
      {
        showEditServicePopup: !this.state.showEditServicePopup,
      },
      () => {
        if (this.state.showEditServicePopup === false) {
          axios
            .post(
              `/fetch_company_services?company_id=${this.state.url.query.company_id}`,
            )
            .then((response) => {
              if (
                response.data.status === "success" ||
                response.data.status === "partial"
              ) {
                this.setState({
                  company_services:
                    response.data.services.length > 0
                      ? response.data.services
                      : [],
                });
              }
            });
        }
      },
    );
  };
  toggleShowFollowPopup = (e) => {
    this.setState({
      [e.target.name]: !this.state[e.target.name],
    });
  };
  handleMessagePopup = (e) => {
    this.setState({
      showCreateMessagePopup: true,
    });
  };
  handleDeleteProduct = (e, product_id) => {
    e.preventDefault();
    axios.post(`/delete_product?product_id=${product_id}`);
    axios
      .post(
        `/fetch_company_products?company_id=${this.state.url.query.company_id}`,
      )
      .then((response) => {
        if (
          response.data.status === "success" ||
          response.data.status === "partial"
        ) {
          this.setState({
            company_products:
              response.data.products.length > 0 ? response.data.products : [],
          });
        } else {
          this.setState({
            company_products: [],
          });
        }
      });
  };
  handleDeleteService = (e, service_id) => {
    e.preventDefault();
    axios.post(`/delete_service?service_id=${service_id}`);
    axios
      .post(
        `/fetch_company_services?company_id=${this.state.url.query.company_id}`,
      )
      .then((response) => {
        if (
          response.data.status === "success" ||
          response.data.status === "partial"
        ) {
          this.setState({
            company_services:
              response.data.services.length > 0 ? response.data.services : [],
          });
        } else {
          this.setState({
            company_services: [],
          });
        }
      });
  };

  handleInputSubmit = (e) => {
    e.preventDefault();
    if (e.target !== null && e.target.name !== "allow_investor") {
      this.setState({
        ...this.state,
        [e.target.name]: !this.state[e.target.name],
      });
    }
    axios
      .post(`/edit_company?company_id=${this.state.url.query.company_id}`, {
        ...this.state,
        uid: this.props.user._id,
      })
      .then((response) => {
        this.getAvatarUrl();
      })
      .catch((error) => {
        console.log(error);
      });

    this.handleFileSubmit("avatarDetails");
    setTimeout(() => {
      if (this.state.backgroundDetails.file.length !== 0) {
        this.handleFileSubmit("backgroundDetails");
        this.setState(
          {
            background_video_link: "",
          },
          () => {
            axios
              .post(
                `/edit_company?company_id=${this.state.url.query.company_id}`,
                { ...this.state, uid: this.props.user._id },
              )
              .then((response) => {
                this.getAvatarUrl();
              });
          },
        );
      }
    }, 1000);

    this.handleFileSubmit("pitchdeckDetails");
    this.computeProfileCompleteness();
  };

  processYoutubeVideo = () => {
    if (this.state.old_pitch_video_link !== this.state.pitch_video_link) {
      this.setState({
        old_pitch_video_link: this.state.pitch_video_link,
      });
      const url_arr = this.state.pitch_video_link.split("=");
      this.setState({
        process_pitch_video: "https://www.youtube.com/embed/" + url_arr[1],
      });
    }
  };
  handleSharePopup = (e) => {
    this.setState({
      showSharePopup: !this.state.showSharePopup,
    });
  };

  getLocation = () => {
    Geocode.setApiKey("AIzaSyAo8kBbOGTAr2C5kFNic3fZYUxA7KYNp7M");

    // Enable or disable logs. Its optional.
    Geocode.enableDebug();

    // Get latidude & longitude from address.
    if (this.state.old_address !== this.state.address) {
      this.setState(
        {
          old_address: this.state.address,
        },
        () => {
          Geocode.fromAddress(this.state.address).then(
            (response) => {
              const address_array =
                response.results[0].formatted_address.split(", ");
              const { lat, lng } = response.results[0].geometry.location;
              if (address_array.length === 4) {
                this.setState({
                  location_processed: 1,
                  lat: lat,
                  long: lng,
                  city: address_array[1],
                  province: address_array[2],
                  country: address_array[3],
                  formatted_address: response.results[0].formatted_address,
                });
              } else if (address_array.length === 3) {
                this.setState({
                  location_processed: 1,
                  lat: lat,
                  long: lng,
                  city: address_array[0],
                  province: address_array[1],
                  country: address_array[2],
                  formatted_address: response.results[0].formatted_address,
                });
              }
            },
            (error) => {
              console.error(error);
            },
          );
        },
      );
    }
  };
  handleEditProduct = (e, product_id) => {
    e.preventDefault();
    this.setState(
      {
        selected_product_id: product_id,
      },
      () => {
        this.setState({
          showEditProductPopup: !this.state.showEditProductPopup,
        });
      },
    );
  };

  handleEditService = (e, service_id) => {
    e.preventDefault();
    this.setState(
      {
        selected_service_id: service_id,
      },
      () => {
        this.setState({
          showEditServicePopup: !this.state.showEditServicePopup,
        });
      },
    );
  };

  fetchData = () => {
    SocialAPI.follow_counts_profile(this.state.url.query.company_id)
      .then((response) => {
        if (response.data.status === "success") {
          this.setState({
            following_count: response.data.following_count,
            follower_count: response.data.follower_count,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleSingleDelete = (e, state, value) => {
    e.preventDefault();
    console.info("You clicked the delete icon.");
  };

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <SiteTitle
          title={`StartupFuel - ${this.state.company_name}`}
          meta_description={this.state.short_bio}
          meta_keywords={`${this.state.company_name}, ${this.state.company_type[0]}`}
        />
        <Navigation />
        <Generic_Success_Message
          show={this.state.showCreateMessageSuccessPopup}
          popup_header="Message Successfully Sent!"
          dismiss={this.dismissModal}
        />
        <Followers_List_Popup
          dismissModal={this.dismissModal}
          {...this.state}
        />
        <Following_List_Popup
          dismissModal={this.dismissModal}
          {...this.state}
        />
        <Create_Product_Popup
          dismissModal={this.dismissModal}
          toggleCreateProductPopup={this.toggleCreateProductPopup}
          {...this.state}
        />
        <Edit_Product_Popup
          dismissModal={this.dismissModal}
          toggleEditProductPopup={this.toggleEditProductPopup}
          {...this.state}
        />
        <Create_Service_Popup
          dismissModal={this.dismissModal}
          toggleCreateServicePopup={this.toggleCreateServicePopup}
          {...this.state}
        />
        <Edit_Service_Popup
          dismissModal={this.dismissModal}
          toggleEditServicePopup={this.toggleEditServicePopup}
          {...this.state}
        />
        <Start_A_Conversation_Popup
          dismissModal={this.dismissModal}
          companies_owned={this.props.companies_owned}
          handleSuccessfulConversation={this.handleSuccessfulConversation}
          target_id={this.state._id}
          target_name={this.state.company_name}
          target_type={this.state.company_type[0]}
          {...this.state}
        />
        <Sign_In />
        <Forgot_Password />
        <Alert_Message_To_User
          show={this.props.showPopupMessage}
          handleChange={this.props.handleChange}
          dismiss={this.props.dismissModal}
          alert_message={this.props.alert_message}
          {...this.state}
        />
        <Register />
        <SideMenu />
        <Profile_Photo_Update
          show={this.state.showProfilePhotoPopup}
          handleChange={this.handleChange}
          handleChangeMultiple={this.handleChangeMultiple}
          handleInputSubmit={this.handleInputSubmit}
          handleFileUpload={this.handleFileUpload}
          handleFileSubmit={this.handleFileSubmit}
          dismiss={this.dismissModal}
          {...this.state}
        />
        <Cover_Photo_Update
          show={this.state.showCoverPhotoPopup}
          handleChange={this.handleChange}
          handleChangeMultiple={this.handleChangeMultiple}
          handleInputSubmit={this.handleInputSubmit}
          handleFileUpload={this.handleFileUpload}
          handleFileSubmit={this.handleFileSubmit}
          handleBackgroundVideo={this.handleBackgroundVideo}
          dismiss={this.dismissModal}
          {...this.state}
        />
        <Alert_Message_To_User
          show={this.state.showPopupMessage}
          handleChange={this.handleChange}
          dismiss={this.dismissModal}
          {...this.state}
        />
        <Development_Message_To_User
          show={this.state.showDevelopmentPopupSuccessMessage}
          handleChange={this.handleChange}
          dismiss={this.dismissModal}
          {...this.state}
        />
        <Development_Question_Form
          show={this.state.showDevelopmentPopupMessage}
          requestedHelp={this.requestedHelp}
          handleChange={this.handleChange}
          dismiss={this.dismissModal}
          {...this.state}
        />
        <Explore_Share_Popup
          {...this.state}
          handleChange={this.handleChange}
          handleSharePopup={this.handleSharePopup}
        />
        {this.state.loading === false ? (
          <div
            className="App __company__profile"
            style={{ backgroundColor: "#eee" }}
          >
            <Container
              className="__profile_completedness"
              style={{ backgroundColor: "#eee" }}
            >
              <div className="__profile_page_container profile-page-new-design-right">
                <div className="profile_content ml-4">
                  <Row className="profile_section">
                    <Col xs lg="12" className="company_conver_con mt-4">
                      {this.state.userIsProfileOwner &&
                        !this.state.inPreviewMode && (
                          <i
                            onClick={this.handleCoverPhotoEditPopup}
                            style={{
                              backgroundColor: "#5959a57a",
                              marginRight: "25px",
                            }}
                            className={"fa fa-pencil not-clicked"}
                          ></i>
                        )}
                      {this.state.background_video_link !== null &&
                      this.state.background_video_link.length === 0 ? (
                        <div
                          className="card-image-container"
                          style={{
                            backgroundImage:
                              "url(" +
                              this.getAvatarUrl(
                                this.state.company_files,
                                "company background",
                              ) +
                              ")",
                          }}
                        ></div>
                      ) : (
                        <iframe
                          style={{ margin: 0 }}
                          width="100%"
                          height="500px"
                          src={this.state.background_video_link}
                          frameborder="0"
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen
                        ></iframe>
                      )}
                      <div className="UbaidCompanyDetailsCon mt-2">
                        <div className="profile_selection">
                          {this.getLocation()}
                          {this.processYoutubeVideo()}
                          {this.state.menuSelection === 5 && (
                            <Analytics
                              deleteOpportunity={this.deleteOpportunity}
                              handleChange={this.handleChange}
                              handleEdit={this.handleEdit}
                              handleAlertPopup={this.handleAlertPopup}
                              handleMenuChange={this.handleMenuChange}
                              handleChangeMultiple={this.handleChangeMultiple}
                              handleInputSubmit={this.handleInputSubmit}
                              handleFileUpload={this.handleFileUpload}
                              handleFileSubmit={this.handleFileSubmit}
                              dismiss={this.dismissModal}
                              getAvatarUrl={this.getAvatarUrl}
                              getFileUrl={this.getFileUrl}
                              opportunities_owned={
                                this.state.opportunities_owned
                              }
                              handleSettingsChange={this.handleSettingsChange}
                              companies_owned={this.props.companies_owned}
                              {...this.state}
                            />
                          )}
                          {this.state.menuSelection === 6 && (
                            <Assessments
                              deleteOpportunity={this.deleteOpportunity}
                              handleChange={this.handleChange}
                              handleEdit={this.handleEdit}
                              handleAlertPopup={this.handleAlertPopup}
                              handleMenuChange={this.handleMenuChange}
                              handleChangeMultiple={this.handleChangeMultiple}
                              handleInputSubmit={this.handleInputSubmit}
                              handleFileUpload={this.handleFileUpload}
                              handleFileSubmit={this.handleFileSubmit}
                              dismiss={this.dismissModal}
                              getAvatarUrl={this.getAvatarUrl}
                              getFileUrl={this.getFileUrl}
                              opportunities_owned={
                                this.state.opportunities_owned
                              }
                              handleSettingsChange={this.handleSettingsChange}
                              companies_owned={this.props.companies_owned}
                              {...this.state}
                            />
                          )}
                          {this.state.menuSelection === 4 && (
                            <Application_To_Opportunities
                              deleteOpportunity={this.deleteOpportunity}
                              handleChange={this.handleChange}
                              handleEdit={this.handleEdit}
                              handleAlertPopup={this.handleAlertPopup}
                              handleMenuChange={this.handleMenuChange}
                              handleChangeMultiple={this.handleChangeMultiple}
                              handleInputSubmit={this.handleInputSubmit}
                              handleFileUpload={this.handleFileUpload}
                              handleFileSubmit={this.handleFileSubmit}
                              dismiss={this.dismissModal}
                              getAvatarUrl={this.getAvatarUrl}
                              getFileUrl={this.getFileUrl}
                              opportunities_owned={
                                this.state.opportunities_owned
                              }
                              handleSettingsChange={this.handleSettingsChange}
                              companies_owned={this.props.companies_owned}
                              {...this.state}
                            />
                          )}
                          {this.state.menuSelection === 2 && (
                            <Opportunities
                              deleteOpportunity={this.deleteOpportunity}
                              handleChange={this.handleChange}
                              handleEdit={this.handleEdit}
                              handleAlertPopup={this.handleAlertPopup}
                              handleMenuChange={this.handleMenuChange}
                              handleChangeMultiple={this.handleChangeMultiple}
                              handleInputSubmit={this.handleInputSubmit}
                              handleFileUpload={this.handleFileUpload}
                              handleFileSubmit={this.handleFileSubmit}
                              dismiss={this.dismissModal}
                              getAvatarUrl={this.getAvatarUrl}
                              getFileUrl={this.getFileUrl}
                              opportunities_owned={
                                this.state.opportunities_owned
                              }
                              handleSettingsChange={this.handleSettingsChange}
                              companies_owned={this.props.companies_owned}
                              {...this.state}
                            />
                          )}
                          {this.state.menuSelection === 3 && (
                            <Settings
                              deleteCompany={this.deleteCompany}
                              handleChange={this.handleChange}
                              handleEdit={this.handleEdit}
                              handleAlertPopup={this.handleAlertPopup}
                              handleMenuChange={this.handleMenuChange}
                              handleChangeMultiple={this.handleChangeMultiple}
                              handleInputSubmit={this.handleInputSubmit}
                              handleFileUpload={this.handleFileUpload}
                              handleFileSubmit={this.handleFileSubmit}
                              dismiss={this.dismissModal}
                              getAvatarUrl={this.getAvatarUrl}
                              getFileUrl={this.getFileUrl}
                              companies_owned={this.props.companies_owned}
                              handleSettingsChange={this.handleSettingsChange}
                              user_email={this.props.user.email}
                              {...this.state}
                            />
                          )}
                          {this.state.menuSelection === 1 && (
                            <PitchDeck
                              getCurrencySymbol={this.getCurrencySymbol}
                              processYoutubeVideo={this.processYoutubeVideo}
                              handleChange={this.handleChange}
                              handleInvestorChange={this.handleInvestorChange}
                              handleChangeCurrency={this.handleChangeCurrency}
                              handleEdit={this.handleEdit}
                              handleAlertPopup={this.handleAlertPopup}
                              handleMenuChange={this.handleMenuChange}
                              handleChangeMultiple={this.handleChangeMultiple}
                              handleInputSubmit={this.handleInputSubmit}
                              handleFileUpload={this.handleFileUpload}
                              handleFileSubmit={this.handleFileSubmit}
                              dismiss={this.dismissModal}
                              getLocation={this.getLocation}
                              getAvatarUrl={this.getAvatarUrl}
                              getFileUrl={this.getFileUrl}
                              needsHelp={this.needsHelp}
                              companies_owned={this.props.companies_owned}
                              {...this.state}
                            />
                          )}
                          {this.state.menuSelection === 0 &&
                            this.state.old_address === this.state.address && (
                              <Overview
                                isLoggedIn={this.props.isLoggedIn}
                                handleTeamEditSubmit={this.handleTeamEditSubmit}
                                handleManualTeamChange={
                                  this.handleManualTeamChange
                                }
                                handleToggleEditTeam={this.handleToggleEditTeam}
                                handleMainTeamChange={this.handleMainTeamChange}
                                handleToggleEdit={this.handleToggleEdit}
                                handleMainTeamSubmit={this.handleMainTeamSubmit}
                                handleEditProduct={this.handleEditProduct}
                                handleDeleteProduct={this.handleDeleteProduct}
                                toggleCreateProductPopup={
                                  this.toggleCreateProductPopup
                                }
                                handleEditService={this.handleEditService}
                                handleDeleteService={this.handleDeleteService}
                                toggleCreateServicePopup={
                                  this.toggleCreateServicePopup
                                }
                                handleEdit={this.handleEdit}
                                handleDateChange={this.handleDateChange}
                                handleTeamChange={this.handleTeamChange}
                                handleTeamDelete={this.handleTeamDelete}
                                handleChange={this.handleChange}
                                handleMenuChange={this.handleMenuChange}
                                handleChangeMultiple={this.handleChangeMultiple}
                                handleInputSubmit={this.handleInputSubmit}
                                handleFileUpload={this.handleFileUpload}
                                handleFileSubmit={this.handleFileSubmit}
                                saveTeamChange={this.saveTeamChange}
                                dismiss={this.dismissModal}
                                getLocation={this.getLocation}
                                getAvatarUrl={this.getAvatarUrl}
                                needsHelp={this.needsHelp}
                                companies_owned={this.props.companies_owned}
                                {...this.state}
                              />
                            )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="pc-section bg-white rounded-lg profile-right-section-main-w">
                  {this.state.profile_completeness !== 100 &&
                    this.state.userIsProfileOwner &&
                    !this.state.inPreviewMode &&
                    this.state.show_profile_completeness && (
                      <Profile_Completeness
                        handleChange={this.handleChange}
                        handleFileUpload={this.handleFileUpload}
                        handleFileSubmit={this.handleFileSubmit}
                        handleInputSubmit={this.handleInputSubmit}
                        handleEdit={this.handleEdit}
                        handleProfileCompletenessSave={
                          this.handleProfileCompletenessSave
                        }
                        {...this.state}
                      />
                    )}
                  <div className="UbaidCompanyProfilePic">
                    <div className="logo">
                      <button
                        className="__company-share"
                        type="button"
                        onClick={this.handleSharePopup}
                      >
                        {" "}
                        <i
                          style={{ color: "black" }}
                          className="fas fa-share-alt"
                        ></i>
                      </button>
                      <div className="profilePic-container profile-new-design-logo">
                        <img
                          style={{ height: "100%" }}
                          src={this.getAvatarUrl(
                            this.state.company_files,
                            "company avatar",
                          )}
                        />
                        {this.state.userIsProfileOwner && (
                          <div>
                            <div className="profilePic-overlay"></div>
                            <span
                              onClick={this.handleProfilePhotoEditPopup}
                              className="profilePic-span"
                            >
                              Edit Profile Picture
                            </span>
                          </div>
                        )}
                      </div>
                      <div style={{ background: "black" }}></div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        paddingTop: "2vh",
                      }}
                      className="social-icon-profile-page"
                    >
                      {this.state.facebook !== null &&
                        this.state.facebook.length !== 0 && (
                          <a href={this.state.facebook} target="_blank">
                            <RiFacebookCircleLine />
                          </a>
                          // <SocialIcon
                          //   style={{ margin: "3px", height: "4vh", width: "4vh"}}
                          //   url={this.state.facebook}
                          //   // bgColor="#5A6473"
                          //   target="_blank"
                          // />
                        )}
                      {this.state.twitter.length !== 0 && (
                        // <SocialIcon
                        //   style={{ margin: "3px", height: "5vh", width: "5vh"}}
                        //   url={this.state.twitter}
                        //   // bgColor="#5A6473"
                        //   target="_blank"
                        // />
                        <a href={this.state.twitter} target="_blank">
                          <FiTwitter />
                        </a>
                      )}
                      {this.state.instagram.length !== 0 && (
                        // <SocialIcon
                        // style={{ margin: "3px", height: "5vh", width: "5vh"}}
                        //   url={this.state.instagram}
                        //   // bgColor="#5A6473"
                        //   target="_blank"
                        // />
                        <a href={this.state.instagram} target="_blank">
                          <FaInstagram />
                        </a>
                      )}
                      <div class="share-icon-profile-page">
                        <p onClick={this.handleSharePopup}>
                          <MdOutlineShare />
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="head">
                    <h2 className="ubaidCompanyBasicDetails py-2">
                      <span className="text-base">
                        {this.state.company_name}
                      </span>
                      <div className="UbaidComSubTitle text-sm">
                        {/* <i
                            style={{ color: "#007bff" }}
                            className="fa fa-check-circle ubaidCircle animate-reveal animate-first"
                          ></i> */}

                        <p className="animate-first">
                          {this.state.company_type}
                        </p>
                      </div>
                    </h2>
                    {!this.state.showEditOne && (
                      <Typography
                        variant="p"
                        color="textPrimary"
                        class="my-3 link-website-profile"
                      >
                        {this.state.company_website.length !== 0 && (
                          <a
                            href={
                              this.state.company_website.includes("http")
                                ? this.state.company_website
                                : `http://${this.state.company_website}`
                            }
                            target="_blank"
                          >
                            <IoIosArrowRoundUp /> {this.state.company_website}
                          </a>
                        )}
                        {this.state.formatted_address.length !== 0 && (
                          <p>
                            <MdMyLocation /> {this.state.city},
                            {this.state.province},{this.state.country}
                          </p>
                        )}
                      </Typography>
                    )}
                    <div className="UbaidFollowCompanyCon">
                      <div className="ubaidFollowComapny">
                        <div>
                          <span>
                            {this.state.follower_count} Followers
                            <a
                              name="showFollowersPopup"
                              onClick={this.toggleShowFollowPopup}
                            ></a>
                          </span>
                        </div>
                      </div>
                      {/* {this.state.distinction !== undefined &&
                      this.state.distinction !== null &&
                      this.state.distinction.length > 0 && (
                        <div className="ExpUbaidDupSocalIconsCon">
                          {this.state.distinction.map((dis) => (
                            <Tooltip
                              title={this.getDistinction(dis, "title")}
                            >
                              <i
                                className={this.getDistinction(
                                  dis,
                                  "img_url"
                                )}
                                style={{
                                  background: `${this.getDistinction(
                                    dis,
                                    "color"
                                  )}`,
                                }}
                              ></i>
                            </Tooltip>
                          ))}
                          {this.state.communities !== undefined &&
                            this.state.communities !== null &&
                            this.state.communities.length > 0 &&
                            this.state.communities.map((comm) => (
                              <Tooltip
                                title={`${comm.company.company_name}'s Community`}
                              >
                                <div className="communityBadge">
                                  <ProfileImage
                                    className="communityImage"
                                    company_id={comm.opp_company}
                                  />
                                </div>
                              </Tooltip>
                            ))}
                        </div>
                      )}
                    {this.state.userIsProfileOwner && (
                      <Button
                        variant="contained"
                        className="UbaidViewAsPulicPr"
                        onClick={() => this.handleEdit("inPreviewMode")}
                        style={{
                          fontSize: ".81rem",
                          backgroundColor: "#ca3a64",
                          textAlign: "center",
                          whiteSpace: "nowrap",
                          padding: "10px 20px",
                        }}
                      >
                        {!this.state.inPreviewMode
                          ? "Public Preview"
                          : "Exit Preview"}
                      </Button>
                    )} */}
                      {!this.state.userIsProfileOwner &&
                        this.props.companies_owned !== undefined &&
                        this.props.companies_owned !== null &&
                        this.props.companies_owned[1] !== undefined &&
                        this.props.companies_owned[1][0] !== undefined &&
                        !this.state.showEditOne && (
                          <>
                            {/* <button
                            className="UbaidFollowBtn"
                            onClick={this.handleMessagePopup}
                            style={{ marginRight: "5px" }}
                          >
                            <i className="fa fa-envelope-square"></i>
                            Message
                          </button> */}
                            <div className="btn-follow-profile">
                              <button
                                className="UbaidFollowBtn"
                                onClick={this.handleFollow}
                              >
                                <i className="fa fa-plus"></i>
                                {this.state.is_following
                                  ? "Unfollow"
                                  : "Follow"}
                              </button>
                            </div>
                          </>
                        )}
                    </div>
                    <div className="btn-message-founder-wraper-profile">
                      {this.state.distinction !== undefined &&
                        this.state.distinction !== null &&
                        this.state.distinction.length > 0 && (
                          <div className="ExpUbaidDupSocalIconsCon">
                            {this.state.distinction.map((dis) => (
                              <Tooltip
                                title={this.getDistinction(dis, "title")}
                              >
                                <i
                                  className={this.getDistinction(
                                    dis,
                                    "img_url",
                                  )}
                                  style={{
                                    background: `${this.getDistinction(
                                      dis,
                                      "color",
                                    )}`,
                                  }}
                                ></i>
                              </Tooltip>
                            ))}
                            {this.state.communities !== undefined &&
                              this.state.communities !== null &&
                              this.state.communities.length > 0 &&
                              this.state.communities.map((comm) => (
                                <Tooltip
                                  title={`${comm.company.company_name}'s Community`}
                                >
                                  <div className="communityBadge">
                                    <ProfileImage
                                      className="communityImage"
                                      company_id={comm.opp_company}
                                    />
                                  </div>
                                </Tooltip>
                              ))}
                          </div>
                        )}
                      {this.state.userIsProfileOwner && (
                        <Button
                          variant="contained"
                          className="UbaidViewAsPulicPr"
                          onClick={() => this.handleEdit("inPreviewMode")}
                          style={{
                            fontSize: ".81rem",
                            backgroundColor: "#ca3a64",
                            textAlign: "center",
                            whiteSpace: "nowrap",
                            padding: "10px 20px",
                          }}
                        >
                          {!this.state.inPreviewMode
                            ? "Public Preview"
                            : "Exit Preview"}
                        </Button>
                      )}
                      {!this.state.userIsProfileOwner &&
                        this.props.companies_owned !== undefined &&
                        this.props.companies_owned !== null &&
                        this.props.companies_owned[1] !== undefined &&
                        this.props.companies_owned[1][0] !== undefined &&
                        !this.state.showEditOne && (
                          <>
                            <button
                              className="UbaidFollowBtn"
                              onClick={this.handleMessagePopup}
                              style={{ marginRight: "5px" }}
                            >
                              <i className="fa fa-envelope-square"></i>
                              Message
                            </button>
                          </>
                        )}
                    </div>
                    {this.state.showEditOne && (
                      <div>
                        <br />
                        <div className="UbaidFlex UbaidTopInput">
                          <Icon
                            className={classNames("fas fa-map-marker-alt")}
                            color="error"
                            style={{
                              fontSize: 26,
                              paddingLeft: "0",
                              width: "30px",
                              marginLeft: "5px",
                              marginRight: "5px",
                              height: "auto",
                            }}
                          />
                          <input
                            type="text"
                            name="address"
                            placeholder="Address"
                            className="ubaidInput"
                            value={this.state.address}
                            onChange={this.handleChange}
                          />
                        </div>
                        <br />
                        <div className="UbaidFlex">
                          <SocialIcon
                            className="ubaidInputIcon"
                            url="facebook.com"
                          />
                          <input
                            type="text"
                            className="ubaidInput"
                            name="facebook"
                            value={this.state.facebook}
                            onChange={this.handleChange}
                          />
                        </div>
                        <br />
                        <div className="UbaidFlex">
                          <SocialIcon
                            className="ubaidInputIcon"
                            url="twitter.com"
                          />
                          <input
                            type="text"
                            name="twitter"
                            className="ubaidInput"
                            value={this.state.twitter}
                            onChange={this.handleChange}
                          />
                        </div>
                        <br />
                        <div className="UbaidFlex">
                          <SocialIcon
                            className="ubaidInputIcon"
                            url="instagram.com"
                          />
                          <input
                            type="text"
                            name="instagram"
                            className="ubaidInput"
                            value={this.state.instagram}
                            onChange={this.handleChange}
                          />
                        </div>
                        <br />
                        <div className="UbaidFlex">
                          <div className="ubaidInputIcon">
                            <img src={SummaryIcon} width="30" />
                          </div>
                          <textarea
                            type="textarea"
                            name="short_bio"
                            placeholder="Short Summary"
                            className="ubaidInput ubaidTextArea"
                            style={{ display: "block" }}
                            value={this.state.short_bio}
                            onChange={this.handleChange}
                          />
                        </div>
                        <br />
                      </div>
                    )}
                    {!this.state.showEditOne ? (
                      <div>
                        <ButtonToolbar className="ubaidMainButton">
                          {this.state.industry &&
                            this.state.industry.length > 0 &&
                            this.state.industry.map((val) => (
                              <Button
                                key={val}
                                style={{ backgroundColor: "#3b5998" }}
                                variant="inherit"
                                href="#"
                              >
                                {val}
                              </Button>
                            ))}

                          {this.state.business_model &&
                            this.state.business_model.length > 0 && (
                              <Button
                                style={{ backgroundColor: "#00aced" }}
                                variant="inherit"
                                href="#"
                              >
                                {this.state.business_model}
                              </Button>
                            )}

                          {this.state.stage && this.state.stage.length > 0 && (
                            <Button
                              style={{ backgroundColor: "#3f729b" }}
                              variant="inherit"
                              href="#"
                            >
                              {this.getStage(this.state.stage, stage_list)}
                            </Button>
                          )}
                        </ButtonToolbar>
                        <p className="company_short_bio_Ubaid">
                          {this.state.short_bio.length < 1 &&
                          this.state.meta_short_bio !== undefined &&
                          this.state.meta_short_bio !== null &&
                          this.state.meta_short_bio.length > 0
                            ? this.state.meta_short_bio
                            : this.state.short_bio}
                        </p>
                      </div>
                    ) : (
                      <div>
                        <div className="Ubaid_Company_Rank_Input_Con">
                          <FormControl className="Ubaid_Company_Rank_Input">
                            <InputLabel htmlFor="select-multiple-chip">
                              Industries
                            </InputLabel>
                            <Select
                              className="inputChild"
                              multiple
                              value={this.state.industry || []}
                              onChange={this.handleChange}
                              input={<Input id="select-multiple-chip" />}
                              name="industry"
                              renderValue={(selected) =>
                                selected.map((value, index) => (
                                  <Chip
                                    key={value}
                                    label={value}
                                    onDelete={(event) =>
                                      this.handleSingleDelete(
                                        event,
                                        "industry",
                                        index,
                                      )
                                    }
                                  />
                                ))
                              }
                              MenuProps={MenuProps}
                            >
                              {industry_list.map((name) => (
                                <MenuItem key={name} value={name}>
                                  <Checkbox
                                    checked={
                                      this.state.industry.indexOf(name) > -1
                                    }
                                  />
                                  <ListItemText primary={name} />
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <FormControl className="Ubaid_Company_Rank_Input_Child marginLeftRight">
                            <InputLabel htmlFor="select-multiple-chip">
                              Business Model
                            </InputLabel>
                            <Select
                              value={this.state.business_model}
                              onChange={this.handleChange}
                              input={<Input id="select-multiple-chip" />}
                              name="business_model"
                              renderValue={(selected) => (
                                <Chip
                                  key={this.state.business_model}
                                  label={this.state.business_model}
                                />
                              )}
                              MenuProps={MenuProps}
                            >
                              {business_type.map((name) => (
                                <MenuItem key={name} value={name}>
                                  {name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <FormControl className="Ubaid_Company_Rank_Input_Child">
                            <InputLabel htmlFor="select-multiple-chip">
                              Company Stage
                            </InputLabel>
                            <Select
                              value={this.state.stage}
                              onChange={this.handleChange}
                              input={<Input id="select-multiple-chip" />}
                              name="stage"
                              renderValue={(selected) => (
                                <div>
                                  <Chip
                                    key={this.state.stage}
                                    label={this.getStage(
                                      this.state.stage,
                                      stage_list,
                                    )}
                                  />
                                </div>
                              )}
                              MenuProps={MenuProps}
                            >
                              {stage_list.map((name) => (
                                <MenuItem key={name.text} value={name.value}>
                                  {name.text}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>

                        <FormControl className="Ubaid_Company_Rank_Input_Bottom Ubaid_Company_Rank_Input">
                          <InputLabel htmlFor="select-multiple-chip">
                            Founder Diversity
                          </InputLabel>
                          <Select
                            className="inputChild"
                            multiple
                            value={this.state.distinction || []}
                            onChange={this.handleChange}
                            input={<Input id="select-multiple-chip" />}
                            name="distinction"
                            renderValue={(selected) =>
                              selected.map((value) => (
                                <Chip
                                  key={value}
                                  label={this.getDistinction(value, "title")}
                                  onDelete={this.handleSingleDelete}
                                />
                              ))
                            }
                            MenuProps={MenuProps}
                          >
                            {distinctions_list.map((name) => (
                              <MenuItem key={name.value} value={name.value}>
                                <Checkbox
                                  checked={
                                    this.state.distinction !== null
                                      ? this.state.distinction.indexOf(
                                          name.value,
                                        ) > -1
                                      : ""
                                  }
                                />
                                <ListItemText primary={name.title} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>

                        <div className="UbaidBtnsParent">
                          <Button
                            className="ubaidBtns"
                            variant="contained"
                            name="showEditOne"
                            onClick={this.handleInputSubmit}
                            style={{
                              backgroundColor: "#007bff",
                              textAlign: "center",
                            }}
                          >
                            Save
                          </Button>
                          <Button
                            className="ubaidBtns"
                            variant="contained"
                            onClick={() => this.handleEdit("showEditOne")}
                            style={{
                              backgroundColor: "#ca3a64",
                              textAlign: "center",
                            }}
                          >
                            Close
                          </Button>
                        </div>
                      </div>
                    )}
                    {this.state.userIsProfileOwner && (
                      <span>
                        {!this.state.inPreviewMode && (
                          <i
                            onClick={() => this.handleEdit("showEditOne")}
                            className={
                              "fa fa-pencil " +
                              (this.state.showEditOne
                                ? "clicked"
                                : "not-clicked") +
                              " ubaidPencilIcon"
                            }
                          ></i>
                        )}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </Container>
          </div>
        ) : (
          <Loader />
        )}
      </React.Fragment>
    );
  }
}

Profile_Container.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default GoogleApiWrapper({
  apiKey: "AIzaSyAo8kBbOGTAr2C5kFNic3fZYUxA7KYNp7M",
})(Profile_Container);
