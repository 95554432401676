import React, { useState, useEffect, useRef } from "react";
import { TypeAnimation } from "react-type-animation";
import { ExpandMore, ExpandLess, Delete } from "@material-ui/icons";
import html2pdf from "html2pdf.js";
import PdfHeader from "./pdfHeader";
import PdfFooter from "./pdfFooter";
import LoadingButton from "../../loadingButton";

export default function DataSufficiencyScore({
  companyInfo,
  applicationObject,
  opportunityInfo,
  handleCreditChange,
  updateCompanyData,
}) {
  const [dataSufficiencyScores, setDataSufficiencyScores] = useState([]);
  const [isSummarizing, setIsSummarizing] = useState(false);
  const [dataSufficiencyScoreError, setDataSufficiencyScoreError] =
    useState("");
  const [isDeleting, setIsDeleting] = useState(false);
  const [isNewlyGenerated, setIsNewlyGenerated] = useState(false);
  const [animationComplete, setAnimationComplete] = useState({});
  const [expandedScores, setExpandedScores] = useState({});
  const [downloadingIndex, setDownloadingIndex] = useState(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(null);
  const [generationStep, setGenerationStep] = useState("");
  const [progress, setProgress] = useState(0);
  const pdfRefs = useRef({});

  useEffect(() => {
    if (companyInfo.aiGenerations?.datasufficiencyscores) {
      setDataSufficiencyScores(
        [...companyInfo.aiGenerations.datasufficiencyscores].reverse(),
      );
    }
  }, [companyInfo]);

  const handleDelete = async (id) => {
    setIsDeleting(true);
    try {
      const response = await fetch(`/delete_ai_generation/${id}`, {
        method: "DELETE",
      });

      if (!response.ok) {
        throw new Error("Failed to delete score");
      }

      setDataSufficiencyScores((scores) =>
        scores.filter((score) => score._id !== id),
      );

      const updatedScores =
        companyInfo.aiGenerations.datasufficiencyscores.filter(
          (score) => score._id !== id,
        );

      const updates = {
        aiGenerations: {
          ...companyInfo.aiGenerations,
          datasufficiencyscores: updatedScores,
        },
      };

      updateCompanyData(companyInfo.applicant_company, updates);
    } catch (error) {
      console.error("Error deleting score:", error);
      setDataSufficiencyScoreError("Failed to delete score. Please try again.");
    }
    setShowDeleteConfirm(null);
    setIsDeleting(false);
  };

  const simulateProgress = async () => {
    // Extracting phase (3 seconds)
    setGenerationStep("Extracting");
    for (let i = 0; i <= 30; i++) {
      setProgress(i);
      await new Promise((r) => setTimeout(r, 100));
    }

    // Summarizing phase (5 seconds)
    setGenerationStep("Summarizing");
    for (let i = 31; i <= 80; i++) {
      setProgress(i);
      await new Promise((r) => setTimeout(r, 62.5));
    }

    // Generating phase (remaining time)
    setGenerationStep("Generating");
  };

  const handleDataSufficiencyScore = async () => {
    const { pitchdeckUrl } = companyInfo;
    const companyId = companyInfo.applicant_company;
    const opportunityId = opportunityInfo._id;

    if (!applicationObject && !pitchdeckUrl) {
      setDataSufficiencyScoreError(
        "Application Info and Pitchdeck URL are missing.",
      );
      return;
    }

    if (opportunityInfo.credits < 1) {
      setDataSufficiencyScoreError(
        "You do not have enough credits to generate a Data Sufficiency score.",
      );
      return;
    }

    setIsSummarizing(true);
    setDataSufficiencyScoreError("");
    setProgress(0);
    simulateProgress();

    try {
      const requestBody = {};

      const hasValidAnswers =
        applicationObject &&
        Object.values(applicationObject).some(
          (answer) => typeof answer === "string" && answer.trim() !== "",
        );

      if (hasValidAnswers) {
        requestBody.applicationObject = applicationObject;
      }

      if (pitchdeckUrl) {
        requestBody.pitchdeckUrl = pitchdeckUrl;
      }

      const response = await fetch(
        `/fetch_data_sufficiency_score?company_id=${companyId}&opportunity_id=${opportunityId}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(requestBody),
        },
      );

      if (response.status === 404) {
        setDataSufficiencyScoreError(
          "Pitch deck or necessary information is missing. Please upload a pitch deck.",
        );
        return;
      }

      if (!response.ok) {
        throw new Error(
          "Due to high network load, we were unable to generate the data sufficiency score. Please try again later",
        );
      }

      const data = await response.json();
      console.log("Response from server:", data);

      handleCreditChange(-data.creditsUsed);

      const newDataSufficiencyScore = {
        datasufficiencyscore: data.analysis.datasufficiencyscore,
        created_on: data.analysis.created_on,
        credits_used: data.analysis.credits_used,
        _id: data.analysis._id,
      };

      const updatedScores = [
        newDataSufficiencyScore,
        ...(companyInfo.aiGenerations?.datasufficiencyscores || []),
      ];

      const updates = {
        aiGenerations: {
          ...companyInfo.aiGenerations,
          datasufficiencyscores: updatedScores,
        },
      };

      updateCompanyData(companyId, updates);

      setDataSufficiencyScores([...updatedScores].reverse());
      setIsNewlyGenerated(true);
      setAnimationComplete({});
      setExpandedScores((prev) => ({
        ...prev,
        0: true,
      }));
      setProgress(100);
    } catch (error) {
      console.error("Error fetching Data Sufficiency score:", error);
      setDataSufficiencyScoreError(
        "Due to high network load, we were unable to generate the data sufficiency score. Please try again later.",
      );
    } finally {
      setIsSummarizing(false);
      setGenerationStep("");
      setProgress(0);
    }
  };

  const handleAnimationComplete = (category, scoreIndex) => {
    setAnimationComplete((prevState) => ({
      ...prevState,
      [`${scoreIndex}-${category}`]: true,
    }));

    const allComplete = Object.values(animationComplete).every(
      (value) => value,
    );
    if (allComplete) {
      setIsNewlyGenerated(false);
    }
  };

  const renderTypingAnimation = (text, category, scoreIndex) => (
    <TypeAnimation
      sequence={[text, () => handleAnimationComplete(category, scoreIndex)]}
      wrapper="span"
      cursor={true}
      speed={85}
      repeat={0}
      style={{ whiteSpace: "pre-line" }}
    />
  );

  const toggleScoreExpansion = (index) => {
    setExpandedScores((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  const downloadPdf = async (scoreIndex) => {
    setDownloadingIndex(scoreIndex);
    if (!pdfRefs.current[scoreIndex]) {
      toggleScoreExpansion(scoreIndex);
      await new Promise((resolve) => setTimeout(resolve, 300));
    }
    await initiateDownload(scoreIndex);
    setDownloadingIndex(null);
  };

  const initiateDownload = async (scoreIndex) => {
    const element = pdfRefs.current[scoreIndex];
    if (!element) return;
    const heightPx = element.scrollHeight;
    const heightCM = heightPx / 35.35;

    const options = {
      filename:
        companyInfo.company_name + " Data Sufficiency Score | StartupFuel.pdf",
      html2canvas: { dpi: 192, letterRendering: true, scale: 2 },
      jsPDF: {
        orientation: "portrait",
        unit: "cm",
        format: [heightCM, 29.7],
      },
    };

    try {
      await html2pdf().from(element).set(options).save();
    } catch (error) {
      console.error("Error downloading PDF:", error);
      setDataSufficiencyScoreError("Failed to download PDF. Please try again.");
    }
  };

  const renderScoreDetails = (score, index) => {
    if (
      !score ||
      !score.datasufficiencyscore ||
      Object.keys(score.datasufficiencyscore).length === 0
    )
      return null;

    const overallCategory = "Overall";
    const categories = Object.keys(score.datasufficiencyscore).filter(
      (category) => category.toLowerCase() !== "overall" && category !== "_id",
    );

    const overallScore = Math.round(
      score.datasufficiencyscore[overallCategory]?.Score || 0,
    );

    return (
      <div className="report-container" key={index}>
        <div
          className="overall-summary"
          onClick={() => toggleScoreExpansion(index)}
        >
          <div className="overall-summary-title-container">
            <h2 className="overall-score">
              Data Sufficiency Score {dataSufficiencyScores.length - index}:{" "}
              <span className="overall-score">{overallScore}%</span>
              {expandedScores[index] ? <ExpandLess /> : <ExpandMore />}
            </h2>
            <div className="score-details-right">
              <p className="credits-used">Credits Used: {score.credits_used}</p>
              <p className="created-on">
                Date Created: {new Date(score.created_on).toLocaleDateString()}
              </p>
            </div>
          </div>

          <div className="score-button-container">
            <LoadingButton
              className="button button-secondary"
              onClick={(e) => {
                e.stopPropagation();
                downloadPdf(index);
              }}
              loading={downloadingIndex === index}
              text={
                downloadingIndex === index ? "Downloading..." : "Download PDF"
              }
            />
            <button
              className="button button-danger ml-2"
              onClick={(e) => {
                e.stopPropagation();
                setShowDeleteConfirm(score._id);
              }}
            >
              <Delete />
            </button>
          </div>
          {showDeleteConfirm === score._id && (
            <div>
              <p>Are you sure you want to delete this score?</p>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <LoadingButton
                  className="button button-danger mr-2"
                  onClick={() => handleDelete(score._id)}
                  loading={isDeleting}
                  text="Delete"
                />
                <button
                  className="button button-secondary"
                  onClick={() => setShowDeleteConfirm(null)}
                >
                  Cancel
                </button>
              </div>
            </div>
          )}
        </div>

        {expandedScores[index] && (
          <div
            id={`score-content-${index}`}
            ref={(el) => (pdfRefs.current[index] = el)}
          >
            <PdfHeader
              company={companyInfo}
              created_on={score.created_on}
              docType="Data Sufficiency Score"
            />
            <div style={{ padding: "20px" }} className="category-item">
              <h2 className="category-title">Overall Score: {overallScore}%</h2>
              <div className="overall-score-container">
                <div
                  className="overall-score-bar"
                  style={{
                    width: `${overallScore}%`,
                  }}
                ></div>
              </div>
              <p className="summary-text">
                {isNewlyGenerated && index === 0
                  ? renderTypingAnimation(
                      score.datasufficiencyscore[overallCategory]?.Reason ||
                        "No overall summary available.",
                      "overall",
                      index,
                    )
                  : score.datasufficiencyscore[overallCategory]?.Reason ||
                    "No overall summary available."}
              </p>

              {categories.map((category, catIndex) => (
                <div key={catIndex} className="category-item">
                  <h3 className="category-title">
                    {category}:{" "}
                    {score.datasufficiencyscore[category].Score
                      ? `${Math.round(score.datasufficiencyscore[category].Score)}%`
                      : "N/A"}
                  </h3>
                  <div className="score-container">
                    <div
                      className="score-bar"
                      style={{
                        width: `${score.datasufficiencyscore[category].Score || 0}%`,
                      }}
                    ></div>
                  </div>
                  <p className="category-reason">
                    {isNewlyGenerated && index === 0
                      ? renderTypingAnimation(
                          score.datasufficiencyscore[category].Reason ||
                            "No reason provided.",
                          category,
                          index,
                        )
                      : score.datasufficiencyscore[category].Reason ||
                        "No reason provided."}
                  </p>
                </div>
              ))}
            </div>
            <PdfFooter />
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="px-3">
      <h4>Data Sufficiency Score</h4>
      <p className="text-grey text-sm">
        Generate a Data Sufficiency Score using the patent-pending StartupFuel
        Scoring DiligenceGPT Model.
      </p>
      <div className="flex justify-between">
        <div className="mr-4">
          <LoadingButton
            onClick={handleDataSufficiencyScore}
            loading={isSummarizing}
            text={
              isSummarizing
                ? "Generating..."
                : "Generate Data Sufficiency Score"
            }
            className="summarize-button"
          />
        </div>
      </div>
      {isSummarizing && (
        <div style={{ marginTop: "20px", marginBottom: "20px" }}>
          <div style={{ marginBottom: "10px", fontWeight: "bold" }}>
            {generationStep}...
          </div>
          <div
            style={{
              width: "100%",
              height: "20px",
              backgroundColor: "#f0f0f0",
              borderRadius: "10px",
              overflow: "hidden",
            }}
          >
            <div
              style={{
                width: `${progress}%`,
                height: "100%",
                background: "linear-gradient(90deg, #6902b9 0%, #1c71a1 100%)",
                transition: "width 0.3s ease-in-out",
              }}
            ></div>
          </div>
        </div>
      )}
      {dataSufficiencyScoreError && (
        <p className="error">{dataSufficiencyScoreError}</p>
      )}
      {dataSufficiencyScores.map((score, index) =>
        renderScoreDetails(score, index),
      )}
    </div>
  );
}
