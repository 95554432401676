import React, { useState } from "react";
import Chip from "@material-ui/core/Chip";
import classnames from "classnames";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import "./dealInfo.css";
import {
  stage_list,
  business_type,
  industry_list,
} from "../../../../../boilerplate/list.json";
import axios from "axios";

export default function ({ companyInfo, user, updateCompanyData }) {
  const [editMode, setEditMode] = useState({});
  const [editedInfo, setEditedInfo] = useState({});
  const [loading, setLoading] = useState(false);

  const isOwner = user._id === companyInfo.owner?._id;

  const getStage = (value, array) => {
    for (let i = 0; i < array.length; i++) {
      if (array[i].value === value) {
        return array[i].text;
      }
    }
    return "Unknown";
  };

  const handleEdit = (field) => {
    if (!isOwner) return;
    setEditMode({ ...editMode, [field]: true });
    setEditedInfo({ ...editedInfo, [field]: companyInfo[field] });
  };

  const handleChange = (field, value) => {
    setEditedInfo({ ...editedInfo, [field]: value });
  };

  const handleSave = async (field) => {
    setLoading(true);
    try {
      const response = await axios.post(
        "/edit_company_during_application",
        {
          [field]: editedInfo[field],
        },
        {
          params: {
            company_id: companyInfo.applicant_company,
          },
        },
      );

      if (response.data.status === "success") {
        setEditMode({ ...editMode, [field]: false });
        updateCompanyData(companyInfo.applicant_company, editedInfo);
      }
    } catch (error) {
      console.error("Error saving company info:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h4>Company Info</h4>
      <p className="text-grey text-sm">Company Details</p>
      <div className="review-content-card">
        <table className="table-fixed w-100 ">
          <tbody>
            <tr className="border-bottom border-gray-100 border-t">
              <td className="py-4 font-bold text-sm">Business Name</td>
              <td className="py-4 text-sm">
                {editMode.company_name ? (
                  <input
                    type="text"
                    value={editedInfo.company_name}
                    onChange={(e) =>
                      handleChange("company_name", e.target.value)
                    }
                    className="border rounded p-1 mr-2"
                    onBlur={() => handleSave("company_name")}
                  />
                ) : (
                  <div onClick={() => handleEdit("company_name")}>
                    {companyInfo.company_name || "Unspecified"}
                  </div>
                )}
              </td>
            </tr>
            <tr className="border-bottom border-gray border-t">
              <td className="w-20 py-4 font-bold text-sm">Website</td>
              <td className="text-sm py-4">
                {editMode.company_website ? (
                  <input
                    type="text"
                    value={editedInfo.company_website}
                    onChange={(e) =>
                      handleChange("company_website", e.target.value)
                    }
                    className="border rounded p-1 mr-2"
                    onBlur={() => handleSave("company_website")}
                  />
                ) : (
                  <div onClick={() => handleEdit("company_website")}>
                    {companyInfo.company_website &&
                    companyInfo.company_website.length !== 0 ? (
                      <a
                        href={
                          companyInfo.company_website.includes("http")
                            ? companyInfo.company_website
                            : `http://${companyInfo.company_website}`
                        }
                        target="_blank"
                      >
                        {companyInfo.company_website}
                      </a>
                    ) : (
                      "Unspecified"
                    )}
                  </div>
                )}
              </td>
            </tr>
            <tr className="border-bottom border-gray border-t">
              <td className="w-20 py-4 font-bold text-sm">Location</td>
              <td className="text-sm py-4">
                {editMode.address ? (
                  <input
                    type="text"
                    value={editedInfo.address}
                    onChange={(e) => handleChange("address", e.target.value)}
                    className="border rounded p-1 mr-2"
                    onBlur={() => handleSave("address")}
                  />
                ) : (
                  <div onClick={() => handleEdit("address")}>
                    {companyInfo.address || "Unspecified"}
                  </div>
                )}
              </td>
            </tr>
            <tr className="border-bottom border-gray border-t p-3">
              <td className="w-20 py-4 font-bold text-sm align-top">
                Description
              </td>
              <td className="text-sm py-4">
                {editMode.short_bio ? (
                  <input
                    type="text"
                    value={editedInfo.short_bio}
                    onChange={(e) => handleChange("short_bio", e.target.value)}
                    className="border rounded p-1 mr-2"
                    onBlur={() => handleSave("short_bio")}
                  />
                ) : (
                  <div onClick={() => handleEdit("short_bio")}>
                    {companyInfo.short_bio || "Unspecified"}
                  </div>
                )}
              </td>
            </tr>
            <tr className="border-bottom border-gray border-t p-3">
              <td className="w-20 py-2 font-bold text-sm align-top py-4">
                Business Models
              </td>
              <td className="text-sm py-4">
                {editMode.business_model ? (
                  <Select
                    value={editedInfo.business_model || ""}
                    onChange={(e) =>
                      handleChange("business_model", e.target.value)
                    }
                    onBlur={() => handleSave("business_model")}
                  >
                    {business_type.map((type) => (
                      <MenuItem key={type} value={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </Select>
                ) : (
                  <div onClick={() => handleEdit("business_model")}>
                    {companyInfo.business_model || "Unspecified"}
                  </div>
                )}
              </td>
            </tr>
            <tr>
              <td className="w-20 font-bold text-sm align-top py-4">
                Industries Disrupted
              </td>
              <td className="text-sm py-4">
                {editMode.industry ? (
                  <Select
                    multiple
                    value={editedInfo.industry || []}
                    onChange={(e) => handleChange("industry", e.target.value)}
                    onBlur={() => handleSave("industry")}
                    renderValue={(selected) => (
                      <div>
                        {selected.map((value) => (
                          <Chip key={value} label={value} className="m-1" />
                        ))}
                      </div>
                    )}
                  >
                    {industry_list.map((industry) => (
                      <MenuItem key={industry} value={industry}>
                        {industry}
                      </MenuItem>
                    ))}
                  </Select>
                ) : (
                  <div
                    onClick={() => handleEdit("industry")}
                    className="allTags"
                  >
                    {companyInfo.industry && companyInfo.industry.length > 0
                      ? companyInfo.industry.map((val) => (
                          <Chip
                            key={val}
                            variant="outlined"
                            className={classnames("exploreTag", "greenHl")}
                            color="primary"
                            size="small"
                            label={val}
                          />
                        ))
                      : "Unspecified"}
                  </div>
                )}
              </td>
            </tr>
            <tr>
              <td className="w-20 font-bold text-sm align-top py-4">
                Company Stage
              </td>
              <td className="text-sm">
                {editMode.stage ? (
                  <Select
                    value={editedInfo.stage || ""}
                    onChange={(e) => handleChange("stage", e.target.value)}
                    onBlur={() => handleSave("stage")}
                  >
                    {stage_list.map((stage) => (
                      <MenuItem key={stage.value} value={stage.value}>
                        {stage.text}
                      </MenuItem>
                    ))}
                  </Select>
                ) : (
                  <div onClick={() => handleEdit("stage")}>
                    {companyInfo.stage
                      ? getStage(companyInfo.stage, stage_list)
                      : "Unspecified"}
                  </div>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
