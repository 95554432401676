import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import {
  FormControl,
  FormLabel,
  Button,
  Form,
  Row,
  Col,
  Card,
} from "react-bootstrap";
import update from "immutability-helper";
import URL from "url-parse";

import {
  company_type_list,
  industry_list,
  stage_list,
  business_type,
  vertical_list,
  type_of_service_provider,
} from "../../../boilerplate/list.json";
import popupPic from "../../../img/rocket.png";
import developmentPic from "../../../img/development_icon.png";
import Grid from "@material-ui/core/Grid";
import { AppContext } from "../../../App";
import axios from "axios";
import waterfall from "async/waterfall";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import FormGroup from "@material-ui/core/FormGroup";
import CardMedia from "@material-ui/core/CardMedia";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input";
import Icon from "@material-ui/core/Icon";
import classNames from "classnames";
import InputLabel from "@material-ui/core/InputLabel";
import Chip from "@material-ui/core/Chip";
import ListItemText from "@material-ui/core/ListItemText";
import { SocialIcon } from "react-social-icons";
import VideoIcon from "@material-ui/icons/Videocam";
import ImageIcon from "@material-ui/icons/Image";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function getStyles(name, that) {
  return {
    fontWeight:
      that.state.name.indexOf(name) === -1
        ? that.props.theme.typography.fontWeightRegular
        : that.props.theme.typography.fontWeightMedium,
  };
}
const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: theme.spacing.unit / 4,
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 100,
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 100,
  },
});

const currencies = [
  {
    value: "USD",
    label: "$",
  },
  {
    value: "EUR",
    label: "€",
  },
  {
    value: "BTC",
    label: "฿",
  },
  {
    value: "JPY",
    label: "¥",
  },
];

export class Create_Product_Popup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      serviceImageDetails: {
        file: "",
        file_name: "",
        file_type: "",
      },

      type: "Physical",
      //for all products
      product_name: "",
      product_description: "",
      product_price: 0,
      product_link: "",

      //for digital products
      payment_plan: "",
      applicable_industries: [],
      applicable_business_models: [],
      applicable_business_stages: [],
      applicable_user_types: [],
      confirming_b2b: false,

      //for service providers
      type_of_consultant: "",
      title_of_service: "",
      description_of_service: "",
      pricing_model: "",
      service_price: 0,
      service_link: "",

      wants_startupfuel_approval: false,

      formIsValids: false,
      errors: {},
    };
  }

  handleChange = (e) => {
    const name = e.target.name;
    this.setState({
      [name]: e.target.value,
    });
  };
  handleToggle = (e) => {
    const name = e.target.name;
    this.setState({
      [name]: !this.state[name],
    });
  };
  handleFileUpload = (e) => {
    let file = e.target.files[0];
    this.setState({
      serviceImageDetails: {
        file,
        file_name: "service avatar",
        file_type: `service ${e.target.dataset.fileType}`,
        object_ref: "service",
        object_ref_value: this.props.url.query.company_id,
      },
    });
  };
  handleServiceFileSubmit = (service_id) => {
    let config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    let formData = new FormData();
    for (let key in this.state.serviceImageDetails) {
      formData.append(key, this.state.serviceImageDetails[key]);
    }
    if (typeof this.state.serviceImageDetails.file === "object") {
      axios
        .post(
          `/upload_service_file?company_id=${this.props.url.query.company_id}&service_id=${service_id}`,
          formData,
          config
        )
        .then((response) => {
          if (response.data.status === "success") {
          } // do else later
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      return;
    }
  };

  handleValidation = (event) => {
    let errors = {};
    let formIsValid = true;

    //Opportunity Name
    if (this.state.title_of_service.length === 0) {
      formIsValid = false;
      errors["title_of_service"] = " Please enter a title for your service.";
    }
    if (this.state.type_of_consultant.length === 0) {
      formIsValid = false;
      errors["type_of_consultant"] = " Please enter the area you service!";
    }
    if (this.state.description_of_service.length === 0) {
      formIsValid = false;
      errors["description_of_service"] =
        " Please enter a description of your service.";
    }
    if (this.state.pricing_model.length === 0) {
      formIsValid = false;
      errors["pricing_model"] =
        " Please enter a payment model for your service.";
    }

    this.setState({ errors: errors }, () => {
      if (formIsValid) {
        this.setState({
          formIsValids: true,
        });

        this.handleInputSubmit(event);
      }
    });
  };
  validateResult = (data, callback) => {
    if (data.status === "success" || data.status === "partial") {
      callback(data, this);
    } else if (data.status === "redirect") {
      this.props.history.push(data.redirect_to);
    } else if (data.status === "error") {
    }
  };

  handleInputSubmit = (e) => {
    e.preventDefault();
    axios
      .post(
        `/create_company_service?company_id=${this.props.url.query.company_id}`,
        this.state
      )
      .then((response) => {
        this.handleServiceFileSubmit(response.data.result._id);
        this.setState({
          productImageDetails: {
            file: "",
            file_name: "",
            file_type: "",
          },

          applicable_industries: [],
          applicable_business_models: [],
          applicable_business_stages: [],
          applicable_user_types: [],
          confirming_b2b: false,

          //for service providers
          type_of_consultant: "",
          title_of_service: "",
          description_of_service: "",
          pricing_model: "",
          service_price: 0,
          service_link: "",

          wants_startupfuel_approval: false,
        });
        this.props.toggleCreateServicePopup(e);
      });
  };
  getStage = (value, array) => {
    for (let i = 0; i < array.length; i++) {
      if (array[i].value === value) {
        return array[i].text;
      }
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <div
        style={
          this.props.showCreateServicePopup
            ? { display: "block" }
            : { display: "none" }
        }
        onClick={(event) => this.props.dismissModal(event)}
        class="modal1"
      >
        <form class="modal-content animate">
          <div class="container" style={{ padding: "30px" }}>
            <FormLabel className="control-label">
              "Upload an image that represents your service."
            </FormLabel>
            <FormControl
              type="file"
              accept=".gif,.jpg,.jpeg,.png"
              data-file-type="avatar"
              placeholder="Enter an image for your service."
              name="avatar"
              onChange={this.handleFileUpload}
              className="form-control mb-3"
            />
            <div>
              <br />

              <FormLabel className="control-label">
                What service area are you in? Ex. Tech, Marketing, etc.
              </FormLabel>
              <span className="text-danger">
                {this.state.errors["type_of_consultant"]}
              </span>
              <br />
              <Select
                value={this.state.type_of_consultant}
                onChange={this.handleChange}
                input={<Input id="select-multiple-chip" />}
                name="type_of_consultant"
                style={{ width: "95%" }}
                className={classes.textField}
                renderValue={(selected) => (
                  <div>
                    <MenuItem
                      key={this.state.type_of_consultant}
                      value={this.state.type_of_consultant}
                    >
                      {this.state.type_of_consultant}
                    </MenuItem>
                  </div>
                )}
                MenuProps={MenuProps}
              >
                {type_of_service_provider.map((name) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
              <div className="form-gorup">
                <FormLabel className="control-label">
                  Name of Service You Wish to List
                </FormLabel>
                <span className="text-danger">
                  {this.state.errors["title_of_service"]}
                </span>
                <FormControl
                  type="text"
                  value={this.state.title_of_service}
                  //className={this.props.classes.textField}
                  placeholder="Enter the name of your service...Ex. 5 Page Professional Website, "
                  name="title_of_service"
                  onChange={this.handleChange}
                  className="form-control mb-3"
                />
              </div>
              <div className="form-gorup">
                <span className="text-danger">
                  {this.state.errors["description_of_service"]}
                </span>
                <TextField
                  id="outlined-multiline-flexible"
                  label="Service Description"
                  multiline
                  placeholder="Enter a description of the service you are providing...Ex. I will build you a 5 page website on wordpress with great security!"
                  value={this.state.description_of_service}
                  onChange={this.handleChange}
                  name="description_of_service"
                  margin="normal"
                  fullWidth={true}
                  variant="outlined"
                  inputProps={{
                    maxLength: 400,
                  }}
                />
              </div>
              <FormLabel className="control-label">
                Service Pricing Model
              </FormLabel>
              <span className="text-danger">
                {this.state.errors["pricing_model"]}
              </span>
              <RadioGroup
                aria-label="position"
                name="pricing_model"
                value={this.state.pricing_model}
                onChange={this.handleChange}
                row
              >
                <FormControlLabel
                  value="Single"
                  control={<Radio color="primary" />}
                  label="One Time Payment/Fixed Price"
                  labelPlacement="top"
                />
                <FormControlLabel
                  value="Hourly"
                  control={<Radio color="primary" />}
                  label="Hourly Rate"
                  labelPlacement="top"
                />
              </RadioGroup>
              <FormLabel className="control-label">
                Price (USD)
                {(this.state.pricing_model === "Hourly" && "/hr") ||
                  (this.state.pricing_model === "Weekly" && "/week") ||
                  (this.state.pricing_model === "Monthly" && "/month")}
              </FormLabel>
              <FormControl
                type="number"
                value={this.state.service_price}
                placeholder="100.00"
                name="service_price"
                onChange={this.handleChange}
                className="form-control mb-3"
              />

              <FormLabel className="control-label">
                Provide a link where people can find more information on the
                service.
              </FormLabel>
              <FormControl
                type="url"
                value={this.state.service_link}
                placeholder="https://company.com/service"
                name="service_link"
                onChange={this.handleChange}
                className="form-control mb-3"
              />
            </div>

            <FormControlLabel
              control={
                <Checkbox
                  value={this.state.confirming_b2b}
                  onChange={this.handleToggle}
                  name="confirming_b2b"
                  color="primary"
                />
              }
              label="Is this service provided to other businesses? (Are you a B2B company?)"
            />
            {this.state.confirming_b2b && (
              <div>
                <InputLabel htmlFor="select-multiple-chip">
                  Targeted Company User Types (Startups, Investors, Events, etc)
                </InputLabel>
                <br />
                <Select
                  multiple
                  value={this.state.applicable_user_types}
                  onChange={this.handleChange}
                  input={<Input id="select-multiple-chip" />}
                  name="applicable_user_types"
                  style={{ width: "95%" }}
                  className={classes.textField}
                  renderValue={(selected) => (
                    <div className={classes.chips}>
                      {selected.map((value) => (
                        <Chip
                          key={value}
                          label={value}
                          className={classes.chip}
                        />
                      ))}
                    </div>
                  )}
                  MenuProps={MenuProps}
                >
                  {company_type_list.map((name) => (
                    <MenuItem key={name} value={name}>
                      <Checkbox
                        checked={
                          this.state.applicable_user_types.indexOf(name) > -1
                        }
                      />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
                <InputLabel htmlFor="select-multiple-chip">
                  Targeted Company Business Models
                </InputLabel>
                <Select
                  multiple
                  value={this.state.applicable_business_models}
                  onChange={this.handleChange}
                  input={<Input id="select-multiple-chip" />}
                  name="applicable_business_models"
                  style={{ width: "95%" }}
                  className={classes.textField}
                  renderValue={(selected) => (
                    <div className={classes.chips}>
                      {selected.map((value) => (
                        <Chip
                          key={value}
                          label={value}
                          className={classes.chip}
                        />
                      ))}
                    </div>
                  )}
                  MenuProps={MenuProps}
                >
                  {business_type.map((name) => (
                    <MenuItem key={name} value={name}>
                      <Checkbox
                        checked={
                          this.state.applicable_business_models.indexOf(name) >
                          -1
                        }
                      />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
                <br />
                <InputLabel htmlFor="select-multiple-chip">
                  Industries
                </InputLabel>
                <br />
                <Select
                  multiple
                  value={this.state.applicable_industries}
                  onChange={this.handleChange}
                  input={<Input id="select-multiple-chip" />}
                  name="applicable_industries"
                  style={{ width: "95%" }}
                  className={classes.textField}
                  renderValue={(selected) => (
                    <div className={classes.chips}>
                      {selected.map((value) => (
                        <Chip
                          key={value}
                          label={value}
                          className={classes.chip}
                        />
                      ))}
                    </div>
                  )}
                  MenuProps={MenuProps}
                >
                  {industry_list.map((name) => (
                    <MenuItem key={name} value={name}>
                      <Checkbox
                        checked={
                          this.state.applicable_industries.indexOf(name) > -1
                        }
                      />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
                <br />
                <FormLabel className="control-label">Company Stage</FormLabel>
                <br />
                <Select
                  multiple
                  value={this.state.applicable_business_stages}
                  onChange={this.handleChange}
                  input={<Input id="select-multiple-chip" />}
                  name="applicable_business_stages"
                  style={{ width: "95%" }}
                  className={classes.textField}
                  renderValue={(selected) => (
                    <div className={classes.chips}>
                      {selected.map((value) => (
                        <Chip
                          key={value}
                          label={this.getStage(value, stage_list)}
                          className={classes.chip}
                        />
                      ))}
                    </div>
                  )}
                  MenuProps={MenuProps}
                >
                  {stage_list.map((name) => (
                    <MenuItem key={name.text} value={name.value}>
                      <Checkbox
                        checked={
                          this.state.applicable_business_stages.indexOf(
                            name.value
                          ) > -1
                        }
                      />
                      <ListItemText primary={name.text} />
                    </MenuItem>
                  ))}
                </Select>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={this.state.wants_startupfuel_approval}
                      onChange={this.handleToggle}
                      name="wants_startupfuel_approval"
                      color="primary"
                    />
                  }
                  label="Would you like to have this service featured in the Startup Services Directory? *If you click yes, it will go through an approval process."
                />
              </div>
            )}
            <Button
              onClick={(event) => this.handleValidation(event)}
              className="btn btn-primary btn-lg btn-block login-button"
            >
              Submit
            </Button>
          </div>
        </form>
      </div>
    );
  }
}
Create_Product_Popup.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(Create_Product_Popup);
