/* (2019-06-11) - The card that is displayed to represent the companies on
the explore page.
*/
import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import default_background from "../../img/bg.png";
import classnames from "classnames";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import CardActionArea from "@material-ui/core/CardActionArea";
import Typography from "@material-ui/core/Typography";
import red from "@material-ui/core/colors/red";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ShareIcon from "@material-ui/icons/Share";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Chip from "@material-ui/core/Chip";
import FaceIcon from "@material-ui/icons/Face";
import DoneIcon from "@material-ui/icons/Done";
import FolderIcon from "@material-ui/icons/Folder";
import Button from "@material-ui/core/Button";
import Moment from "moment";
import { Explore_Share_Popup } from "./popups";
import {
  country_list,
  opportunity_type_list,
  industry_list,
  stage_list,
  business_type,
} from "../../boilerplate/list.json";

const styles = (theme) => ({
  button: {
    margin: theme.spacing.unit,
  },
  input: {
    display: "none",
  },
  root: {
    marginBottom: theme.spacing.unit * 2,
  },
  header: {
    overflow: "hidden",
  },
  chip: {
    margin: theme.spacing.unit,
  },
  CardHeight: {
    height: 200,
    overflow: "hidden",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  actions: {
    display: "flex",
  },
  expand: {
    transform: "rotate(0deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
    marginLeft: "0",
    [theme.breakpoints.up("sm")]: {
      marginRight: -8,
    },
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  type: {
    fontSize: "1em",
    color: "red",
    textAlign: "center",
    fontWeight: 0,
  },
  footer: {
    display: "flex",
  },
  title: {
    fontSize: "1rem",
    overflow: "hidden",
    height: 25,
  },
  grow: {
    flexGrow: 1,
  },
});

function handleClick() {
  alert("You clicked the Chip.");
}

class RecipeReviewCard extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    expanded: false,
    showSharePopup: false,
  };

  handleExpandClick = () => {
    this.setState((state) => ({ expanded: !state.expanded }));
  };
  handleSharePopup = (e) => {
    this.setState({
      showSharePopup: !this.state.showSharePopup,
    });
  };
  render() {
    const { classes } = this.props;

    return (
      <Card className={`${classes.Card} ${classes.grow} ${classes.root}`}>
        <Explore_Share_Popup
          {...this.state}
          {...this.props}
          handleChange={this.handleChange}
          handleSharePopup={this.handleSharePopup}
        />
        <CardHeader
          avatar={
            <Avatar
              aria-label="Recipe"
              src={this.props.getAvatarUrl(
                this.props.company_files,
                "company avatar"
              )}
              className={classes.avatar}
            />
          }
          title={
            <a href={"/profile?company_id=" + this.props._id}>
              <Typography variant="h6" className={classes.title} gutterBottom>
                {this.props.company_name}
              </Typography>
            </a>
          }
          subheader={"#" + this.props.company_type}
          className={classes.header}
          action={
            <IconButton onClick={this.handleSharePopup} aria-label="Share">
              <ShareIcon />
            </IconButton>
          }
        />
        <CardActionArea href={"/profile?company_id=" + this.props._id}>
          <CardMedia
            className={classes.media}
            image={
              this.props.company_files[1] !== undefined
                ? this.props.getAvatarUrl(
                    this.props.company_files,
                    "company background"
                  )
                : default_background
            }
            title="background_picture"
          />
          <CardContent className={classes.CardHeight}>
            <Grid container direction="row">
              <Grid
                container
                direction="column"
                xs={5}
                sm={5}
                alignItems="center"
              >
                <Typography variant="caption" gutterBottom>
                  Industry
                </Typography>
                {this.props.industry.slice(0, 3).map((val) => (
                  <Chip
                    size="small"
                    //icon={<FaceIcon />}
                    label={val == "Artificial Intelligence" ? "A.I" : val}
                    //onClick={handleClick}
                    className={classes.chip}
                  />
                ))}
              </Grid>
              <Grid
                container
                direction="column"
                xs={3}
                sm={3}
                alignItems="center"
              >
                <Typography variant="caption" gutterBottom>
                  Stage
                </Typography>

                <Chip
                  size="small"
                  //icon={<FaceIcon />}
                  label={this.props.getStage(this.props.stage, stage_list)}
                  //onClick={handleClick}
                  className={classes.chip}
                />
              </Grid>
              <Grid
                container
                direction="column"
                xs={4}
                sm={4}
                alignItems="center"
              >
                <Typography variant="caption" gutterBottom>
                  Location
                </Typography>
                <Chip
                  size="small"
                  //icon={<FaceIcon />}
                  label={this.props.country}
                  //onClick={handleClick}
                  className={classes.chip}
                />
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea>
        <Grid className={classes.footer}>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-start"
            className="col-3"
          >
            <CardActions className={classes.actions}>
              <IconButton
                className={classnames(classes.expand, {
                  [classes.expandOpen]: this.state.expanded,
                })}
                onClick={this.handleExpandClick}
                aria-expanded={this.state.expanded}
                aria-label="Show more"
              >
                <ExpandMoreIcon />
              </IconButton>
            </CardActions>
          </Grid>
          <Grid container direction="row" justify="center" alignItems="center">
            <div></div>
          </Grid>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-end"
            className="col-4"
          >
            <Button
              variant="contained"
              color="primary"
              onClick={(event) =>
                this.props.deleteCompany(event, this.props._id)
              }
              className={classes.button}
              size="small"
            >
              Delete
            </Button>
          </Grid>
        </Grid>
        <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <div>
                <Typography paragraph>Description:</Typography>
                <Typography paragraph>{this.props.short_bio}</Typography>
              </div>
            </Grid>
          </CardContent>
        </Collapse>
      </Card>
    );
  }
}

RecipeReviewCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RecipeReviewCard);
