import React from "react";
import './Olympus.css';
import Nav from "./nav/Nav";
import Sidebar from "./Sidebar/Sidebar";
import NewsFeedIcon from "./assets/NewsFeedIcon";
import ActivityIcon from "./assets/ActivityIcon";
import MemberIcon from "./assets/MemberIcon";
import GroupIcon from "./assets/GroupIcon";
import PhotoIcon from "./assets/PhotoIcon";
import MusicIcon from "./assets/MusicIcon";
import VideoIcon from "./assets/VideoIcon";
import EventIcon from "./assets/EventIcon";
import ForumIcon from "./assets/ForumIcon";
import GridIcon from "./assets/GridIcon";
import MainNav from "./MainNav/MainNav";
import LoginPart from "./LoginPart/LoginPart";
import MainBody from './Body/MainBody';
import LoginPage from './nav/LoginPage';

const Olympus = props => (
    <div className='Olympus'>
        <Nav />
        <LoginPage />
        <Sidebar>
            <a href='#'><NewsFeedIcon />Members Newsfeed</a>
            <a href='#'><ActivityIcon />Activity</a>
            <a href='#'><MemberIcon />Members</a>
            <a href='#'><GroupIcon />Groups</a>
            <a href='#'><PhotoIcon />Photos</a>
            <a href='#'><MusicIcon />Music</a>
            <a href='#'><VideoIcon />Video</a>
            <a href='#'><EventIcon />Event</a>
            <a href='#'><ForumIcon />Forum</a>
            <a href='#'><GridIcon />Grid</a>
        </Sidebar>
        <MainNav />
        <LoginPart />
        <MainBody />
    </div>
)

export default Olympus;