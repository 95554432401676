/* (2019-06-10) - This page is displayed to the user after they click the reset
password link. This page allows the user to change their password.
*/
import React from 'react';
import axios from 'axios'
import '../main.css';
import { AppContext } from '../App'
import { Button, Alert, FormControl, FormLabel, Card } from 'react-bootstrap';
import { Helmet } from "react-helmet";
import URL from 'url-parse';
import Navigation from './components/navbar'
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import InputBase from '@material-ui/core/InputBase';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function getStyles(name, that) {
  return {
    fontWeight: that.state.name.indexOf(name) === -1
      ? that.props.theme.typography.fontWeightRegular
      : that.props.theme.typography.fontWeightMedium,
  };
}
const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing.unit / 4,
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 100,
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 100,
  },
});

class Reset_Password_Container extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <Reset_Password
        {...this.props}
        {...this.context} />
    )
  }
}
Reset_Password_Container.contextType = AppContext;

class Reset_Password extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: new URL(window.location.href, true),
      errors: {},
      error_msg: "",
      email_msg: "",
      new_password: "",
      new_password_again: "",
    //   username: '',
    //   password: '',
    //
    //   loggedIn: false,
    //   url: new URL(this.props.location.pathname + this.props.location.search, true),
    //
    //   showAlert: false,
    //
    }
  }
  handleChange = (e) => {
    const name = e.target.name;
    this.setState({
      [name]: e.target.value
    })
  }
  submitPasswordChange = (e) => {
    e.preventDefault()
    axios.post(`/submit_new_pass_after_reset?token=${this.state.url.query.token}`, {
      new_password: this.state.new_password,
      new_password_again: this.state.new_password_again
    })
      .then((response) => {
        this.setState({
          error_msg: response.data.message
        })
        if (response.data.status === 'success') {
          this.props.history.push('/sign_in')
        }

      })
  }



  render() {
    const {classes} = this.props;
    return (
      <React.Fragment>
        <Helmet>
          <title>
            Fastest Growing Innovation Network in the World - StartupFuel
          </title>
        </Helmet>
        <Navigation />
        <div class="container">
          <div class="main">
            <div class="panel-heading">
              <div class="panel-title text-center">
                <h1 class="title">Reset Your Password</h1>
                <hr className="mb-5" />
              </div>
            </div>
            <div>
              <Card.Title>
                Update your password
              </Card.Title>
              <p style={ { marginBottom: '0' } }>
                Enter your new password
              </p>
              <TextField
                type="password"
                id="outlined-multiline-static"
                style={ { width: "90%" } }
                value={ this.state.new_password }
                name="new_password"
                className={ classes.textField }
                onChange={ this.handleChange }
                margin="normal"
                variant="outlined" />
              <p style={ { marginBottom: '0' } }>
                Enter your new password again
              </p>
              <TextField
                type="password"
                id="outlined-multiline-static"
                style={ { width: "90%" } }
                value={ this.state.new_password_again }
                name="new_password_again"
                className={ classes.textField }
                onChange={ this.handleChange }
                margin="normal"
                variant="outlined" />
              <Button
                variant="contained"
                onClick={ this.submitPasswordChange }
                style={ { backgroundColor: '#007bff', textAlign: 'center' } }
                className={ classes.button }>
                Save
              </Button>
              { this.state.error_msg.length > 0 &&
                <div>
                  <p>
                    { this.state.error_msg }
                  </p>
                  { this.state.error_msg === 'Your password has been changed! You will receive a confirmation email shortly.' &&
                    <p>
                      Go back to the <a href='/explore'>homepage</a>
                    </p> }
                </div> }
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}
Reset_Password_Container.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(Reset_Password_Container);
