import React from "react";
import {
  FormControl,
  FormLabel,
  Button,
  Form,
  Row,
  Col,
  Card,
} from "react-bootstrap";
import update from "immutability-helper";
import URL from "url-parse";

import {
  company_type_list,
  industry_list,
  stage_list,
  business_type,
  vertical_list,
} from "../../../boilerplate/list.json";
import popupPic from "../../../img/combined.png";
import developmentPic from "../../../img/development_icon.png";
import Grid from "@material-ui/core/Grid";
import { AppContext } from "../../../App";
import { withStyles } from "@material-ui/core/styles";
import axios from "axios";
import waterfall from "async/waterfall";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import FormGroup from "@material-ui/core/FormGroup";
import CardMedia from "@material-ui/core/CardMedia";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input";
import Icon from "@material-ui/core/Icon";
import classNames from "classnames";
import InputLabel from "@material-ui/core/InputLabel";
import Chip from "@material-ui/core/Chip";
import ListItemText from "@material-ui/core/ListItemText";
import { SocialIcon } from "react-social-icons";
import VideoIcon from "@material-ui/icons/Videocam";
import ImageIcon from "@material-ui/icons/Image";
import Geocode from "react-geocode";

import "../oppHeader.css";
import "../profiles/picture.css";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const styles = (theme) => ({
  root: {
    backgroundColor: "#f5f5f5",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  media: {
    height: 140,
  },
  developmentMedia: {
    maxWidth: "200px",
    height: "200px",
    margin: "auto",
    marginTop: "20px",
    marginBottom: "20px",
  },
  p: {
    margin: 0,
  },
});

export const withContext = (WrappedComponent, context) => {
  return class extends React.Component {
    constructor(props) {
      super(props);
    }
    static contextType = context;
    render() {
      return <WrappedComponent {...this.props} {...this.context} />;
    }
  };
};

export class Stay_In_Touch_Comp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      first_name: "",
      last_name: "",
      referral_partners: [],
      referred_by: null,
      confirmed_privacy_poicy_terms: false,
      registered: false,

      formIsValids: false,
      errors: {},
    };
  }

  handleChange = (e) => {
    const name = e.target.name;
    this.setState({
      [name]: e.target.value,
    });
  };

  handleUserValidation = (event) => {
    event.preventDefault();

    this.setState({
      showButtonLoader: !this.state.showButtonLoader,
    });
    let errors = {};
    let formIsValid = true;

    if (this.state.first_name.length === 0) {
      formIsValid = false;
      errors["first"] = " Please Enter an your first Name";
    }
    if (this.state.last_name.length === 0) {
      formIsValid = false;
      errors["last"] = " Please Enter an your last Name";
    }
    if (this.state.password.length === 0) {
      formIsValid = false;
      errors["password"] = " Cannot be empty";
    }

    if (this.state.email.length === 0) {
      formIsValid = false;
      errors["email"] = " Cannot be empty";
    }
    if (this.state.confirmed_privacy_poicy_terms === false) {
      formIsValid = false;
      errors["privacy_policy"] =
        "Must accept the privacy policy and terms of service to make an account.";
    }

    if (typeof this.state.email !== "undefined") {
      let lastAtPos = this.state.email.lastIndexOf("@");
      let lastDotPos = this.state.email.lastIndexOf(".");

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          this.state.email.indexOf("@@") == -1 &&
          lastDotPos > 2 &&
          this.state.email.length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        errors["email"] =
          " Email is not valid, please structure it like 'user@example.com'";
      }
    }
    this.setState({ errors: errors });
    if (formIsValid) {
      this.setState({ formIsValids: true });
      return waterfall(
        [
          (callback) => {
            axios
              .post("/create_user_with_verification", {
                first_name: this.state.first_name,
                last_name: this.state.last_name,
                email: this.state.email,
                referred_by: this.state.referred_by,
                password: this.state.password,
              })
              .then((result) => {
                if (result.data.status === "existing user") {
                  let errors = {};
                  let formIsValid = true;
                  errors["email"] = " User already exists";
                  this.setState({ errors: errors });
                }
                if (result.data.status !== "existing user") {
                  callback(null, result.data);
                  //document.location.reload(true);
                }
                this.setState({
                  showButtonLoader: false,
                });
              });
          },
        ],
        (err, done) => {
          if (err) console.log(err);
          this.setState({
            registered: true,
          });
          //this.props.persistUser(done.data)
        }
      );
    }
  };

  handleCheck = (e) => {
    let name = e.target.name;
    this.setState({
      [name]: !this.state[name],
    });
  };

  render() {
    return (
      <div
        style={
          this.props.showSignUpPopup
            ? { display: "block" }
            : { display: "none" }
        }
        onClick={(e) => this.props.dismissModal(e, "showSignUpPopup")}
        class="modal1"
      >
        <form class="modal-content animate" style={{ paddingBottom: "10px" }}>
          <CardMedia image={popupPic} title="Rocket" className="applyMedia" />
          <div class="container">
            <div className="form-gorup">
              <br />
              <h5 style={{ textAlign: "center" }}>
                {" "}
                Register to gain access to {this.props.opportunity_name} & many
                more!{" "}
              </h5>
              <FormLabel className="control-label">First Name</FormLabel>
              <span className="text-danger">{this.state.errors["first"]}</span>
              <FormControl
                type="text"
                value={this.state.first_name}
                placeholder="First Name"
                name="first_name"
                onChange={this.handleChange}
                className="form-control"
              />
            </div>
            <FormLabel className="control-label">Last Name</FormLabel>
            <span className="text-danger">{this.state.errors["last"]}</span>
            <FormControl
              type="text"
              value={this.state.last_name}
              placeholder="Last Name"
              name="last_name"
              onChange={this.handleChange}
              className="form-control mb-3"
            />
            <FormLabel className="control-label">Email</FormLabel>
            <span className="text-danger">{this.state.errors["email"]}</span>
            <FormControl
              type="email"
              value={this.state.email}
              placeholder="User Email"
              name="email"
              onChange={this.handleChange}
              className="form-control mb-3"
            />

            <FormLabel className="control-label">Password</FormLabel>
            <span className="text-danger">{this.state.errors["password"]}</span>
            <FormControl
              type="password"
              value={this.state.password}
              placeholder="User Password"
              name="password"
              onChange={this.handleChange}
              className="form-control mb-3"
            />

            <div
              style={{
                margin: "auto",
                display: "flex",
                padding: "16px 0 6px 0",
              }}
            >
              <Checkbox
                checked={this.state.confirmed_privacy_poicy_terms}
                onChange={(event) => {
                  this.handleCheck(event);
                }}
                name="confirmed_privacy_poicy_terms"
                color="primary"
              />
              <span>
                <p style={{ verticalAlign: "middle", marginTop: "1rem" }}>
                  {" "}
                  I agree to the{" "}
                  <a href="/privacy_policy" target="_blank">
                    privacy policy
                  </a>{" "}
                  &{" "}
                  <a href="/terms_conditions" target="_blank">
                    terms of service
                  </a>
                  .
                </p>{" "}
              </span>
            </div>
            {!this.state.registered ? (
              <Button
                onClick={this.handleUserValidation}
                className="btn btn-primary btn-lg btn-block login-button"
              >
                Register & Continue
              </Button>
            ) : (
              <>
                <p>
                  {" "}
                  Thank you for registering, please check for a confirmation
                  email! Feel free to continue and visit to the website in the
                  meantime.
                </p>
                <a target="_blank" href={this.props.link}>
                  {" "}
                  <Button className="btn btn-primary btn-lg btn-block login-button">
                    Visit Website
                  </Button>
                </a>
              </>
            )}
          </div>
        </form>
      </div>
    );
  }
}

export const Stay_In_Touch = withContext(
  withStyles(styles)(Stay_In_Touch_Comp),
  AppContext
);
