import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import {
  FormControl,
  FormLabel,
  Button,
  Form,
  Row,
  Col,
  Card,
} from "react-bootstrap";
import update from "immutability-helper";
import URL from "url-parse";

import {
  company_type_list,
  industry_list,
  stage_list,
  business_type,
  vertical_list,
  type_of_service_provider,
  product_categories,
} from "../../../boilerplate/list.json";
import popupPic from "../../../img/rocket.png";
import developmentPic from "../../../img/development_icon.png";
import Grid from "@material-ui/core/Grid";
import { AppContext } from "../../../App";
import axios from "axios";
import waterfall from "async/waterfall";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import FormGroup from "@material-ui/core/FormGroup";
import CardMedia from "@material-ui/core/CardMedia";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input";
import Icon from "@material-ui/core/Icon";
import classNames from "classnames";
import InputLabel from "@material-ui/core/InputLabel";
import Chip from "@material-ui/core/Chip";
import ListItemText from "@material-ui/core/ListItemText";
import { SocialIcon } from "react-social-icons";
import VideoIcon from "@material-ui/icons/Videocam";
import ImageIcon from "@material-ui/icons/Image";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function getStyles(name, that) {
  return {
    fontWeight:
      that.state.name.indexOf(name) === -1
        ? that.props.theme.typography.fontWeightRegular
        : that.props.theme.typography.fontWeightMedium,
  };
}
const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: theme.spacing.unit / 4,
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 100,
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 100,
  },
});

const currencies = [
  {
    value: "USD",
    label: "$",
  },
  {
    value: "EUR",
    label: "€",
  },
  {
    value: "BTC",
    label: "฿",
  },
  {
    value: "JPY",
    label: "¥",
  },
];

export class Hire_For_Project extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      project_type: "new_project",
      title: "",
      description: "",
      pricing_model: "",
      price: 0,

      formIsValids: false,
      errors: {},
      loaded: false,
    };
  }
  componentDidUpdate(prevProps) {
    if (
      this.props !== prevProps &&
      !this.state.loaded & (this.props.service !== undefined)
    ) {
      console.log("HERI");
      axios
        .post(`/fetch_service_details?service_id=${this.props.service}`)
        .then((response) => {
          let service = response.data.service;
          console.log(service);
          this.setState({
            title: service.title_of_service,
            description: service.description_of_service,
            price: service.service_price,
            pricing_model: service.pricing_model,
            loaded: true,
          });
        });
    }
  }

  handleChange = (e) => {
    const name = e.target.name;
    this.setState({
      [name]: e.target.value,
    });
  };
  handleToggle = (e) => {
    const name = e.target.name;
    this.setState({
      [name]: !this.state[name],
    });
  };
  handleFileUpload = (e) => {
    let file = e.target.files[0];
    this.setState({
      productImageDetails: {
        file,
        file_name: "product avatar",
        file_type: `product ${e.target.dataset.fileType}`,
        object_ref: "product",
        object_ref_value: this.props.url.query.company_id,
      },
    });
  };
  handleProductFileSubmit = (product_id) => {
    let config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    let formData = new FormData();
    for (let key in this.state.productImageDetails) {
      formData.append(key, this.state.productImageDetails[key]);
    }
    if (typeof this.state.productImageDetails.file === "object") {
      axios
        .post(
          `/upload_product_file?company_id=${this.props.url.query.company_id}&product_id=${product_id}`,
          formData,
          config
        )
        .then((response) => {
          if (response.data.status === "success") {
          } // do else later
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      return;
    }
  };

  handleValidation = (event) => {
    let errors = {};
    let formIsValid = true;

    //Opportunity Name
    if (this.state.title.length === 0) {
      formIsValid = false;
      errors["title"] = " Please enter a title for your project.";
    }
    if (this.state.description.length === 0) {
      formIsValid = false;
      errors["description"] = " Please Enter a description of your project";
    }
    if (this.state.price.length === 0) {
      formIsValid = false;
      errors["price"] = " Please Enter a price of your project";
    }
    if (this.state.price <= 0) {
      formIsValid = false;
      errors["price"] = " Please Enter a price greater than 0";
    }

    this.setState({ errors: errors }, () => {
      if (formIsValid) {
        this.setState({
          formIsValids: true,
        });

        this.handleInputSubmit(event);
      }
    });
  };
  validateResult = (data, callback) => {
    if (data.status === "success" || data.status === "partial") {
      callback(data, this);
    } else if (data.status === "redirect") {
      this.props.history.push(data.redirect_to);
    } else if (data.status === "error") {
    }
  };

  handleInputSubmit = (e) => {
    e.preventDefault();
    axios
      .post("/create_project", {
        project_type: this.state.project_type,
        title: this.state.title,
        description: this.state.description,
        pricing_model: this.state.pricing_model,
        price: this.state.price,
        employer_confirmation: true,
        employee_confirmation: false,
        employer: this.props.employer,
        employee: this.props.employee,
      })
      .then((response) => {
        if (response.data.status === "success") {
          this.props.show_success_popup();
        }
      });
  };
  getStage = (value, array) => {
    for (let i = 0; i < array.length; i++) {
      if (array[i].value === value) {
        return array[i].text;
      }
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <div
        style={this.props.show ? { display: "block" } : { display: "none" }}
        onClick={(event) => this.props.dismissModal(event)}
        class="modal1"
      >
        <form class="modal-content animate">
          <div class="container" style={{ padding: "30px" }}>
            <div>
              <h2 style={{ textAlign: "center" }}>
                Hire{" "}
                {Array.isArray(this.props.employee) &&
                  this.props.employee[0] &&
                  this.props.employee[0].company_name}
              </h2>
              <div className="form-gorup">
                <FormLabel className="control-label">Project Title</FormLabel>
                <span className="text-danger">
                  {this.state.errors["title"]}
                </span>
                <FormControl
                  type="text"
                  value={this.state.title}
                  //className={this.props.classes.textField}
                  placeholder="Enter a project Title "
                  name="title"
                  onChange={this.handleChange}
                  className="form-control mb-3"
                />
              </div>
              <div className="form-gorup">
                <FormLabel className="control-label">
                  Project Description
                </FormLabel>
                <span className="text-danger">
                  {this.state.errors["description"]}
                </span>

                <TextField
                  id="outlined-multiline-flexible"
                  label="Project Description"
                  multiline
                  placeholder="Make me a website that looks like GOOGLE.com!!!"
                  value={this.state.description}
                  onChange={this.handleChange}
                  name="description"
                  margin="normal"
                  fullWidth={true}
                  variant="outlined"
                  inputProps={{
                    maxLength: 400,
                  }}
                />
              </div>
              <FormLabel className="control-label">Pricing Model</FormLabel>
              <span className="text-danger">
                {this.state.errors["pricing_model"]}
              </span>
              <RadioGroup
                aria-label="position"
                name="pricing_model"
                value={this.state.pricing_model}
                onChange={this.handleChange}
                row
              >
                <FormControlLabel
                  value="Single"
                  control={<Radio color="primary" />}
                  label="One Time Payment/Fixed Price"
                  labelPlacement="top"
                />
                <FormControlLabel
                  value="Hourly"
                  control={<Radio color="primary" />}
                  label="Hourly Rate"
                  labelPlacement="top"
                />
              </RadioGroup>
              <FormLabel className="control-label">
                Price (USD)
                {(this.state.pricing_model === "Hourly" && "/hr") ||
                  (this.state.pricing_model === "Weekly" && "/week") ||
                  (this.state.pricing_model === "Monthly" && "/month")}
              </FormLabel>
              <span className="text-danger">{this.state.errors["price"]}</span>
              <FormControl
                type="number"
                value={this.state.price}
                placeholder="20.00"
                name="price"
                onChange={this.handleChange}
                className="form-control mb-3"
              />
            </div>

            <Button
              onClick={(event) => this.handleValidation(event)}
              className="btn btn-primary btn-lg btn-block login-button"
            >
              Hire
            </Button>
          </div>
        </form>
      </div>
    );
  }
}
Hire_For_Project.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(Hire_For_Project);
