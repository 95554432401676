/* (2019-06-10) - This component displays the important dates on an individual
opportunity page.
*/
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import default_pic from '../../img/default_company_pic.png'
import default_background from '../../img/bg.png'
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Moment from "moment";
import "./OppDates.css";

const styles = theme => ({
  root: {
    flex: 1,
  },
  text: {
    paddingTop: theme.spacing.unit * 2,
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
  },
  subHeader: {
    backgroundColor: theme.palette.background.paper,
  },
  divider: {
    width: 1,
    height: 28,
    margin: 4,
  },
});

class OppDate extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {classes} = this.props;

    return (
      <Grid
        container
        className='OppDates'
        direction="row"
        justify="center"
        alignItems="center">
        { this.props.start_intake_date !== null &&
          <Grid
            item
            xs={ 12 }
            sm={ 4 }>
            <Typography
              style={ { textAlign: "center" } }

              gutterBottom>
              Application Start :
              <Typography
              style={ { textAlign: "center" } }
              variant="h6"
              gutterBottom>
              { Moment(this.props.start_intake_date).format('LL') }
            </Typography> 
            </Typography>
          </Grid> }

        { this.props.end_intake_date !== null &&
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            item
            xs={ 12 }
            sm={ 4 }>
            <Typography
              style={ { textAlign: "center" } }

              gutterBottom>
              Application Close : 
              <Typography
              style={ { textAlign: "center" } }
              variant="h6"
              gutterBottom>
              { Moment(this.props.end_intake_date).format('LL') }
            </Typography>
            </Typography>
          </Grid> }
      </Grid>

      );
  }
}

OppDate.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(OppDate);
