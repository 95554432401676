import React from "react";
import "./dealInfo/dealInfo.css";

export default function Submenu({
  activeItem,
  setActiveItem,
  menuItems,
  state = "approved",
}) {
  const renderStatusDot = () => {
    if (state === "approved") return null;
    return (
      <span
        style={{
          display: "inline-block",
          width: "8px",
          height: "8px",
          borderRadius: "50%",
          marginRight: "8px",
          backgroundColor: state === "pending" ? "#A020F0" : "#FF0000",
        }}
      ></span>
    );
  };

  return (
    <div className="review-submenu">
      <ul className="review-submenu-list">
        {menuItems.map((item) => (
          <li
            key={item}
            onClick={() => setActiveItem(item)}
            className={activeItem === item ? "active" : ""}
          >
            {item === "Deal Info" && renderStatusDot()}
            {item}
          </li>
        ))}
      </ul>
    </div>
  );
}
