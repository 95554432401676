/* (2019-06-10) - This page is actually a page of the SIR/ApplicationRoom
It doesnt contain much just houses the components. The actual functions for
the settings and the components can be found in:
 /application_room/components/settings. The sidebar contains access
 to the other pages associate with the SIR.
*/
import React, { Component } from 'react';
import { AppContext } from '../../App'
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import axios from 'axios'
import URL from 'url-parse';

import "../application_room/applicant_room.css"

import SiteTitle from '../components/site_title.js'
import { Sign_In, Register, Forgot_Password, Alert_Message_To_User } from '../components/popups'
import Navigation from '../components/navbar'
import Settings from "../application_room/components/settings/settings_reviews"
import SideBar from "../application_room/components/Sidebar"
import TopNav from "../application_room/components/topNav"

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing.unit,
  },
  bootstrapFormLabel: {
    fontSize: 18,
  },
});

class Applicant_Room_Container extends React.Component {
  constructor(props) {
    super(props);
  }
  static contextType = AppContext;
  render() {
    return (
      <Applicant_Room
        {...this.props}
        {...this.context} />
    )
  }
}

class Applicant_Room extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: new URL(window.location.href, true),
      judges: "",
      opportunity_owner: "",
      show_page: true,
      opportunity_data:{}
    }

  }
  componentDidMount() {
    axios.get(`/fetch_opportunity?opportunity_id=${this.state.url.query.opportunity_id}`)
      .then((response) => {
        this.setState({
          opportunity_data: response.data.result
        })
      })
    axios.get(`fetch_judges_for_opportunity?opportunity_id=${this.state.url.query.opportunity_id}`)
      .then((response) => {
        this.setState({
          judges: response.data.judges,
          opportunity_owner: response.data.owner,
          access_ids: response.data.access_ids
        }, () => {
          setTimeout(() => {
              if (this.state.access_ids.includes(this.props.user._id)||this.props.user.email==="trevor@pangeafirm.com"||this.props.user.email==="ash@startupfuel.com") {
            } else {
              //this.props.history.push('/explore')
            }
          }, 1000);

        })
      })

  }

  render() {
    return (
      <div className='__application-room-main'>
          <SiteTitle />
          <Sign_In />
          <Register />
          <Forgot_Password />
          <SideBar
            user={this.props.user}
            {...this.state} />
        <Settings
          user={this.props.user}
          judges={this.state.judges}
          opportunity_name={this.state.opportunity_data.opportunity_name}
          {...this.state} />
      </div>
    );
  }
}

Applicant_Room_Container.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(Applicant_Room_Container);
