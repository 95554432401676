/* (2019-06-11) - This is the sub menu that appears in the SIR/ApplicationRoom.
*/
import React, { Component, useRef, useState, useEffect  } from 'react';
import './index.css';
import { Nav, Navbar, NavDropdown, Image, InputGroupAddon } from "react-bootstrap";
import URL from 'url-parse';

//import "assets/plugins/nucleo/css/nucleo.css";
//import "@fortawesome/fontawesome-free/css/all.min.css";
//import "assets/scss/argon-dashboard-react.scss";
import "../../../../pitch_box/assets/scss/argon-dashboard-react.scss";
import { Link } from "react-router-dom";
// reactstrap components
import {
  UncontrolledCollapse,
  NavbarBrand,
  NavItem,
  NavLink,
  Container,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
} from "reactstrap";

class ArgNav extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      url: new URL(window.location.href, true),
    }
  }
    render() {
        return (
          <div>
            <div className="pitchbox-mobile-menu">
                <Navbar className="navbar-top navbar-horizontal navbar-dark arg-navbar-secondary" expand="sm">
                  <Container>
                      <Nav className="me-auto" navbar>
                        <div className='__pb_navbar_top'>
                          <div className='__pb_name font-bold'>
                            <a class="__arg-nav-mobile-logo" to={"/explore"}><img class="img" src="/img/pblogo.png"/></a>
                            <NavItem className="arg-navbar-secondary-name-logo ">
                              <NavLink className="nav-link-icon" to={
                                "/applicant_room_dash?opportunity_id=" +
                                this.props.opportunity_id
                              } tag={Link}>
                              <div className="nav-link-inner--text font-bold ellipsis">{this.props.opportunity_name}</div>
                              </NavLink>
                            </NavItem>
                            </div>
                        </div>
                      </Nav>
                  </Container>
                </Navbar>
            </div>
            <div className="pitchbox-mobile-menu-bottom">
              <Navbar className="navbar-top navbar-horizontal navbar-dark arg-navbar-secondary" expand="sm">
                <Container>
                    <Nav className="me-auto" navbar>
                      <div className='__pb_navbar'>
                        <div className='__pb_nav'>
                        <NavItem className={`${this.state.url.pathname === "/applicant_room_dash" ? "active" : ""}`}>
                          <NavLink
                            className="nav-link-icon" 
                            to={
                              "/applicant_room_dash?opportunity_id=" +
                              this.props.opportunity_id
                            }
                            id={
                              this.props.pathname ===
                              "/applicant_room_dash"
                                ? "submission"
                                : "sidetabs"
                            }
                            tag={Link}
                          >
                            <i className="material-icons show-inline-767">analytics</i>
                            {/* <div className="pitchbox-mobile-menu-labels hide-767">Dashboard</div> */}
                          </NavLink>
                        </NavItem>
                        <NavItem className={`${this.state.url.pathname === "/applicant_room" ? "active" : ""}`}>
                          <NavLink className="nav-link-icon" 
                          to={
                            "/applicant_room?opportunity_id=" +
                            this.props.opportunity_id
                          }
                          id={
                            this.props.pathname === "/applicant_room"
                              ? "submission"
                              : "sidetabs"
                          } tag={Link}>
                            <div><i className="material-icons show-inline-767">tornado</i></div>
                            {/* <div className="pitchbox-mobile-menu-labels hide-767">Deals</div> */}
                          </NavLink>
                        </NavItem>
                        <NavItem className={`${this.state.url.pathname === "/applicant_room_top" ? "active" : ""}`}>
                          <NavLink className="nav-link-icon" 
                          to={
                            "/applicant_room_top?opportunity_id=" +
                            this.props.opportunity_id
                          }
                          id={
                            this.props.pathname === "/applicant_room"
                              ? "submission"
                              : "sidetabs"
                          } tag={Link}>
                            <div><i className="material-icons show-inline-767">star</i></div>
                            {/* <div className="pitchbox-mobile-menu-labels hide-767">Top</div> */}
                          </NavLink>
                        </NavItem>
                        <NavItem className={`${this.state.url.pathname === "/applicant_room_team" ? "active" : ""}`}>
                          <NavLink
                            className="nav-link-icon"
                            to={
                              "/applicant_room_team?opportunity_id=" +
                              this.props.opportunity_id
                            }
                            id={
                              this.props.pathname ===
                              "/applicant_room_team"
                                ? "submission"
                                : "sidetabs"
                            }
                            tag={Link}
                          >
                            <i className="material-icons show-inline-767">group</i>
                            {/* <div className="pitchbox-mobile-menu-labels hide-767">Team</div> */}
                          </NavLink>
                        </NavItem>
                       
                        <NavItem className={`${ ( 
                            (this.state.url.pathname === "/applicant_room_settings") ||
                            (this.state.url.pathname === "/applicant_room_settings_judges") ||
                            (this.state.url.pathname === "/applicant_room_settings_reviews") ||
                            (this.state.url.pathname === "/applicant_room_settings_applicants") ||
                            (this.state.url.pathname === "/applicant_room_settings_visibility")
                          )  ? "active" : ""}`}>
                          <NavLink className="nav-link-icon" 
                          to={
                            "/applicant_room_settings_judges?opportunity_id=" +
                            this.props.opportunity_id
                          }
                          id={
                            this.props.pathname === "/applicant_room_settings_judges"
                              ? "submission"
                              : "sidetabs"
                          } tag={Link}>
                            <div><i title="Settings" className="material-icons show-inline-767">settings</i></div>
                            {/* <div className="pitchbox-mobile-menu-labels hide-767">Settings</div> */}
                          </NavLink>
                        </NavItem>
                        {/*<NavItem>
                          <UncontrolledDropdown nav>
                            <DropdownToggle className="settings-dropdown" nav>
                              <div><i className="material-icons show-inline-767">settings</i></div>
                              <div className="pitchbox-mobile-menu-labels hide-767">
                                Settings</div>
                              
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" >
                              <DropdownItem to={"/applicant_room_settings_judges?opportunity_id=" + this.props.opportunity_id } tag={Link}>
                                <i className="material-icons">gavel</i>
                                <span>Team</span>
                              </DropdownItem>
                              <DropdownItem to={"/applicant_room_settings_reviews?opportunity_id=" + this.props.opportunity_id } tag={Link}>
                                <i className="material-icons">grading</i>
                                <span>Review</span>
                              </DropdownItem>
                              <DropdownItem to={"/applicant_room_settings_applicants?opportunity_id=" + this.props.opportunity_id } tag={Link}>
                                <i className="material-icons">accessibility</i>
                                <span>Applicant</span>
                              </DropdownItem>
                              <DropdownItem to={"/applicant_room_settings_visibility?opportunity_id=" + this.props.opportunity_id } tag={Link}>
                                <i className="material-icons">visibility</i>
                                <span>Visibility</span>
                              </DropdownItem>
                              <DropdownItem to={"/applicant_room_settings_visibility?opportunity_id=" + this.props.opportunity_id } tag={Link}>
                                <i className="material-icons">payment</i>
                                <span>Payment</span>
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </NavItem> */}
                        
                        </div>
                        
                      </div>
                    </Nav>
                </Container>
              </Navbar>       
            </div>
          </div>
        )
    }
}

export default ArgNav
