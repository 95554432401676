import React from "react";
import StarRatings from "react-star-ratings";

const Rating = ({
  title,
  rating,
  changeRating,
  name,
  className,
  starDimension = 20,
}) => (
  <div className={`__rating`}>
    <div style={{ fontSize: "1rem", marginTop: "1rem" }}>{title}</div>
    <div className="__graphical-reviews">
      <StarRatings
        rating={rating}
        starRatedColor="#C49733"
        numberOfStars={10}
        starDimension={starDimension}
        starSpacing="2"
        changeRating={changeRating}
        name={name}
      />
      {!name && <span>{rating.toFixed(1)}</span>}
    </div>
  </div>
);

export default Rating;
