import React, { useState } from 'react';
import classnames from 'classnames';
import './CenterBody.css';
import Post from './Post';
import CreatePost from './CreatePost';
import SocialAPI from '../../live/api/social';
import author from '../Images/author-page.jpg';
import ComputerIcon from '../icons/ComputerIcon';
import ThreeDotsIcon from '../icons/ThreeDotsIcon';


class CenterBody extends React.Component {
  constructor(props) {
    super(props);

    this.PAGE_LENGTH = 8;

    this.state = {
      feed: [],
      loaded: false,
      fetchingNextPage: false,
      noMoreContent: false
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleInfiniteScroll.bind(this), true);
    this.setState({
      fetchingNextPage: true
    }, this.fetchFeed);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleInfiniteScroll);
  }

  handleInfiniteScroll() {
    if ((window.innerHeight + window.scrollY) >= (document.body.offsetHeight - 100) && !this.state.fetchingNextPage) {
      this.setState({
        fetchingNextPage: true
      }, function() {
        // add artifical delay on infinite scroll, not to spam the server
        setTimeout(this.fetchFeed.bind(this), 500);
      }.bind(this));
    }
  }

  resetFeed() {
    this.setState({
      fetchingNextPage: true,
      noMoreContent: false
    }, this.fetchFeed.bind(this, true));
  }

  fetchFeed(reset) {
    var lastPost = (this.state.feed.length > 0 && this.state.feed[this.state.feed.length - 1]);
    var lastPostId = (lastPost && lastPost._id);


    SocialAPI.feed(reset || lastPostId)
      .then((response) => {
        if (response.data.status === 'success') {
          this.setState({
            noMoreContent: (response.data.feed.length < this.PAGE_LENGTH),
            feed: reset ? response.data.feed : this.state.feed.concat(response.data.feed),
            loaded: true,
            fetchingNextPage: false
          });
        }
      })
      .catch((error) => {
        this.setState({
          loaded: true,
          fetchingNextPage: false
        });
        console.log(error);
      });
  }

  handleLike = (i)=> {
    this.setState({
      feed:this.state.feed.map((value,index) => (index === i ? {...value, totalLikes:this.state.feed[i].totalLikes+1,currentUserLiked:true} : value))
    })

  }

  handleUnLike = (i)=> {
    this.setState({
      feed:this.state.feed.map((value,index) => (index === i ? {...value, totalLikes:this.state.feed[i].totalLikes-1,currentUserLiked:false} : value))
    })

  }

  render() {
    return (
      <div className='CenterBodyNewsFeed'>
        { this.state.loaded && (
          <CreatePost onSuccessfulPost={ this.resetFeed.bind(this) }
           />
          ) }
        { this.state.feed.map((item, i)=> (
              <Post
                key={ i }
                index={ i }
                post={ item }
                handleLike={this.handleLike}
                handleUnLike={this.handleUnLike}
                 />
            ))
           }
        { this.state.loaded && this.state.noMoreContent && (
          <h5 style={ { textAlign: "center" } }>
          <br />
        There is no more content to show at the moment
            <br />
            <br />
          </h5>
          ) }
        { !this.state.noMoreContent && (
          <div>
            <div className={ classnames('SocialLoadingIcon') }>
              <ThreeDotsIcon />
            </div>
          </div>
          ) }
      </div>
    )
  }
}

export default CenterBody;
