import React, { useState } from 'react';
import './CenterBody.css';
import SocialAPI from '../../live/api/social';
import ComputerIcon from '../icons/ComputerIcon';
import ThreeDotsIcon from '../icons/ThreeDotsIcon';
import ProfileImage from '../../live/components/profile_image';
import TextField from '@material-ui/core/TextField';

class FeedPostShare extends React.Component {
  constructor(props) {
    super(props)
    this.textBox = React.createRef();
    this.state = {};
  }

  handlePostContentChange = (event) => {
    this.setState({
      postContent: event.target.value
    });
  }

  handlePostSubmit = (event) => {
    this.handlePost(this.state.postContent);
    event.preventDefault();
  }

  handlePost = (content) => {
    SocialAPI.post(content)
      .then((response) => {
        // refresh feed
        this.props.onSuccessfulPost();

        this.setState({
          postContent: ""
        });
      })
  }

  render() {
    return (
      <div className='newsfeedPost'>
        <div className='addPostNews'>
          <ProfileImage />
          <div className='shareyouthinkNews' style={{marginTop:"16px"}}>
          <TextField
            InputProps={{ 'aria-label': 'naked','disableUnderline': 'true'  }}
            placeholder="Share company updates and news here"
            multiline
            fullWidth
            onChange={ this.handlePostContentChange }
            value={ this.state.postContent }


          />

          </div>
        </div>
        <div className='newsFeedPostFooter'>
          <div className='newsfeedPostBtn'>
            <button
              onClick={ this.handlePostSubmit }
              className='newsfeedOrange'>
              Post
            </button>
          </div>
        </div>
      </div>
    )
  }
}

export default FeedPostShare;
