var React = require('react');
var classnames = require('classnames');

class DisplayToggle extends React.Component {
  constructor (props) {
    super(props);
  }

  getDefaultProps() {
    return {
      hide: false,
      show: true,
      remove: true,
      block: true
    };
  }

  hideElement(props) {
    var hide = !props.show;
    hide = hide || props.hide;
    return hide;
  }

  shouldComponentUpdate(nextProps, nextState) {
    var thisPropsHide = this.hideElement(this.props);
    var nextPropsHide = this.hideElement(nextProps);

    return !(thisPropsHide && nextPropsHide);
  }

  render() {
    var hideElement = this.hideElement(this.props);

    var classes = classnames(this.props.className, {
      'hide': hideElement
    });
    var children = this.props.remove && hideElement ? [] : this.props.children;

    if (this.props.block) {
      return (
        <div className={classes}>{children}</div>
      );
    } else {
      return (
        <span className={classes}>{children}</span>
      );
    }
  }
}

export default DisplayToggle;
