import React, { useContext, useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { AppContext } from "../../App";
import Loader from "./loader";
import axios from "axios";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { user } = useContext(AppContext);
  const [hasAccess, setHasAccess] = useState(true); // State to track access
  const urlParams = new URLSearchParams(rest.location.search);
  const opportunityId = urlParams.get("opportunity_id");

  console.log(`User: ${JSON.stringify(user)}`);

  useEffect(() => {
    if (user._id) {
      if (opportunityId) {
        axios
          .get(`/has_opportunity_access?opportunity_id=${opportunityId}`)
          .then((response) => {
            if (response.data.status === "error") {
              setHasAccess(false); // Set access state to false if error
            }
          })
          .catch((error) => {
            console.error("Error checking opportunity access", error);
            setHasAccess(false); // Handle any error by denying access
          });
      }
    }
  }, [user._id, rest.location.search]);

  if (user.loading) {
    return <Loader />;
  }

  // If user has no access, redirect to welcome page
  if (!hasAccess) {
    return <Redirect to="/welcome" />;
  }

  // If the user has an _id, render the component
  return (
    <Route
      {...rest}
      render={(props) =>
        user._id ? (
          <Component {...props} />
        ) : (
          <Redirect to="/sign_in" /> // Redirect to login page if not authenticated
        )
      }
    />
  );
};

export default PrivateRoute;
