/* (2019-06-11) - The card that is displayed to represent the companies on
the explore page.
*/
import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import default_background from "../../img/bg.png";
import classnames from "classnames";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import CardActionArea from "@material-ui/core/CardActionArea";
import Typography from "@material-ui/core/Typography";
import red from "@material-ui/core/colors/red";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ShareIcon from "@material-ui/icons/Share";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Chip from "@material-ui/core/Chip";
import FaceIcon from "@material-ui/icons/Face";
import DoneIcon from "@material-ui/icons/Done";
import FolderIcon from "@material-ui/icons/Folder";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";
import MessageIcon from "@material-ui/icons/Message";
import Moment from "moment";
import { Explore_Share_Popup, Generic_Success_Message } from "./popups";
import Start_A_Conversation_Popup from "./popups/start_a_conversation";
import Projects_Register_Popup from "./popups/projects_register_popup";

import {
  country_list,
  opportunity_type_list,
  industry_list,
  stage_list,
  business_type,
} from "../../boilerplate/list.json";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Hire_For_Project from "./popups/hire_for_project";

const CustomTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#e0e0e0",
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const styles = (theme) => ({
  button: {
    margin: theme.spacing.unit,
  },
  input: {
    display: "none",
  },
  root: {
    marginBottom: theme.spacing.unit * 2,
  },
  header: {
    overflow: "hidden",
  },
  chip: {
    margin: theme.spacing.unit,
  },
  CardHeight: {
    height: 200,
    overflow: "hidden",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  actions: {
    display: "flex",
  },
  expand: {
    transform: "rotate(0deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
    marginLeft: "0",
    [theme.breakpoints.up("sm")]: {
      marginRight: -8,
    },
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  type: {
    fontSize: "1em",
    color: "red",
    textAlign: "center",
    fontWeight: 0,
  },
  footer: {
    display: "flex",
  },
  title: {
    fontSize: "1rem",
    overflow: "hidden",
    height: 25,
  },
  grow: {
    flexGrow: 1,
  },
});

function handleClick() {
  alert("You clicked the Chip.");
}

class RecipeReviewCard extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    expanded: false,
    showSharePopup: false,
    showConversationPopup: false,
    showCreateMessageSuccessPopup: false,
    showCheckout: false,
    show_project: false,
    showSuccessPopup: false,
    show_register: false,
  };
  show_success_popup = () => {
    this.setState({
      show_project: false,
      showSuccessPopup: true,
    });
  };
  show_hire = (e) => {
    this.setState({
      show_project: true,
    });
  };
  showregister = (e) => {
    this.setState({
      show_register: true,
    });
  };

  handleExpandClick = () => {
    this.setState((state) => ({ expanded: !state.expanded }));
  };
  handleSharePopup = (e) => {
    this.setState({
      showSharePopup: !this.state.showSharePopup,
    });
  };
  getImage = (array, type) => {
    if (array?.length > 0) {
      let img_obj = array.filter((x) => x.file_name === type);
      if (img_obj.length > 0) {
        return img_obj[0].file_url;
      } else {
        return default_background;
      }
    } else {
      return default_background;
    }
  };

  handleShowCheckout = (e) => {
    e.preventDefault();
    this.setState({
      showCheckout: !this.state.showCheckout,
    });
  };

  handleConversationPopup = (e) => {
    e.preventDefault();
    this.setState({
      showConversationPopup: !this.state.showConversationPopup,
    });
  };

  handleSuccessfulConversation = () => {
    this.setState({
      showConversationPopup: false,
      showCreateMessageSuccessPopup: true,
    });
  };
  dismissModal = (e) => {
    (e.target.className === "modal1" || e.target.className === "cancelbtn") &&
      this.setState({
        showCreateMessageSuccessPopup: false,
        showConversationPopup: false,
        showCheckout: false,
        show_project: false,
        showSuccessPopup: false,
        show_register: false,
      });
  };

  render() {
    const { classes } = this.props;

    return (
      <Card className={`${classes.Card} ${classes.grow} ${classes.root}`}>
        <div
          className="exploreCardTitle"
          style={{ whiteSpace: "nowrap" }}
        ></div>
        <Explore_Share_Popup
          {...this.state}
          {...this.props}
          handleChange={this.handleChange}
          handleSharePopup={this.handleSharePopup}
        />
        <Projects_Register_Popup
          show={this.state.show_register}
          companies_owned={this.props.companies_owned}
          dismissModal={this.dismissModal}
        />
        <Start_A_Conversation_Popup
          dismissModal={this.dismissModal}
          companies_owned={this.props.companies_owned}
          handleSuccessfulConversation={this.handleSuccessfulConversation}
          subject={`I am interested in ${this.props.title_of_service}`}
          showCreateMessagePopup={this.state.showConversationPopup}
          target_id={this.props.company._id}
          company_name={this.props.company.company_name}
          target_type={this.props.company.company_type[0]}
          service={this.props.service._id}
        />
        <Generic_Success_Message
          show={this.state.showCreateMessageSuccessPopup}
          popup_header="Message Successfully Sent!"
          dismiss={this.dismissModal}
        />
        <Hire_For_Project
          show={this.state.show_project}
          show_success_popup={this.show_success_popup}
          dismissModal={this.dismissModal}
          service={this.props.service._id}
          employee={this.props.service.affiliated_company}
          employer={
            this.props.companies_owned !== null &&
            this.props.companies_owned[1][0]._id
          }
        />
        <Generic_Success_Message
          show={this.state.showSuccessPopup}
          popup_header="Invitation Successfully Sent!"
          dismiss={this.dismissModal}
        />

        {/* <CardHeader
          avatar={
            <Avatar aria-label="Recipe" src={this.getImage(this.props.service_company_files, "profile avatar")} className={classes.avatar} />
          }
          title={<a target="_blank" href={this.props.service_link}><Typography variant="h6" className={classes.title} gutterBottom>
            {this.props.title_of_service}
          </Typography></a>}
          subheader={this.props.type_of_consultant + " Service"}
          className={classes.header}
          action={
            <CardActions disableSpacing style={{ paddingLeft: 0 }}>
            </CardActions>
          }
        /> */}
        <div className="exploreCardHeader">
          <div className="exploreCardTitle" style={{ whiteSpace: "nowrap" }}>
            <a target="_blank" href={this.props.service_link}>
              {this.props.title_of_service}
            </a>
            <div style={{ display: "flex" }}>
              <div
                style={{
                  maxWidth: "40px",
                  backgroundImage:
                    "url(" +
                    this.getImage(
                      this.props.service_company_files,
                      "profile avatar"
                    ) +
                    ")",
                }}
                className="exploreCardAvtar"
              ></div>
              <div style={{ marginTop: "10px", marginLeft: "10px" }}>
                by{" "}
                <a
                  style={{ display: "unset" }}
                  href={`/profile?company_id=${this.props.company._id}`}
                >
                  {this.props.company.company_name}
                </a>
              </div>
            </div>
          </div>
          {this.props.companies_owned !== undefined &&
            this.props.companies_owned !== null &&
            this.props.companies_owned[1] !== undefined &&
            this.props.companies_owned[1][0] !== undefined && (
              <CardActions
                disableSpacing
                style={{ paddingLeft: 0 }}
                className={this.state.follow_loading && "follow-loading"}
              >
                <IconButton
                  id="service_directory_message"
                  onClick={this.handleConversationPopup}
                  size="small"
                >
                  <MessageIcon />
                </IconButton>
              </CardActions>
            )}
        </div>

        <CardActionArea target="_blank" href={this.props.service_link}>
          <CardMedia
            className={classes.media}
            image={this.getImage(
              this.props.service_company_files,
              "service avatar"
            )}
            title="background_picture"
          />
        </CardActionArea>

        <CardContent
          className="exploreCardContent OopSerToolCardContent"
          style={{ whiteSpace: "pre-line" }}
        >
          <>
            <strong>#{this.props.type_of_consultant + "Service"}</strong>
            <br />
            {this.props.description_of_service}
          </>
        </CardContent>

        <Grid className={classes.footer}>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-start"
            className="col-3"
          >
            <CardActions className={classes.actions}>
              {this.props.confirming_b2b ? (
                <IconButton
                  className={
                    classnames(classes.expand, {
                      [classes.expandOpen]: this.state.expanded,
                    }) + " OopSerToolCardFooter"
                  }
                  onClick={this.handleExpandClick}
                  aria-expanded={this.state.expanded}
                  aria-label="Show more"
                >
                  <ExpandMoreIcon />
                </IconButton>
              ) : (
                <div style={{ height: "40px" }}></div>
              )}
            </CardActions>
          </Grid>
          <Grid container direction="row" justify="center" alignItems="center">
            <div>
              <em>
                ${this.props.service_price}
                {this.props.pricing_model === "Hourly" && "/hr"}
                {this.props.pricing_model === "Monthly" && "/month"}
                {this.props.pricing_model === "Yearly" && "/year"}
              </em>
            </div>
          </Grid>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-end"
            className="col-4"
          >
            {this.props.companies_owned !== undefined &&
            this.props.companies_owned !== null &&
            this.props.companies_owned[1] !== undefined &&
            this.props.companies_owned[1] !== null ? (
              <Button
                id="service_directory_hire"
                variant="contained"
                color="primary"
                onClick={this.show_hire}
                className={classes.button + " OopSerToolCardFooterBtn"}
                size="small"
              >
                Hire
              </Button>
            ) : (
              <Button
                id="service_directory_hire"
                variant="contained"
                color="primary"
                onClick={this.showregister}
                className={classes.button + " OopSerToolCardFooterBtn"}
                size="small"
              >
                Hire
              </Button>
            )}
          </Grid>
        </Grid>
        <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
          <CardContent>
            {this.props.applicable_user_types !== undefined &&
              this.props.applicable_user_types !== null && (
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <CustomTableCell className="text-center">
                        Serviced User Types
                      </CustomTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow style={{ height: "auto" }}>
                      <div style={{ margin: "7px 0", textAlign: "center" }}>
                        {this.props.applicable_user_types.map((val) => (
                          <Chip
                            size="small"
                            //icon={<FaceIcon />}
                            label={
                              val == "Artificial Intelligence" ? "A.I" : val
                            }
                            //onClick={handleClick}
                            className={classes.chip}
                          />
                        ))}
                      </div>
                    </TableRow>
                  </TableBody>
                </Table>
              )}
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <CustomTableCell className="text-center">
                    Company Industries
                  </CustomTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow style={{ height: "auto" }}>
                  <div style={{ margin: "7px 0", textAlign: "center" }}>
                    {this.props.applicable_industries.map((val) => (
                      <Chip
                        size="small"
                        //icon={<FaceIcon />}
                        label={val == "Artificial Intelligence" ? "A.I" : val}
                        //onClick={handleClick}
                        className={classes.chip}
                      />
                    ))}
                  </div>
                </TableRow>
              </TableBody>
            </Table>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <CustomTableCell className="text-center">
                    Company Stages
                  </CustomTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow style={{ height: "auto" }}>
                  <div style={{ margin: "7px 0", textAlign: "center" }}>
                    {this.props.applicable_business_stages.map((val) => (
                      <Chip
                        size="small"
                        //icon={<FaceIcon />}
                        label={this.props.getStage(parseFloat(val), stage_list)}
                        //onClick={handleClick}
                        className={classes.chip}
                      />
                    ))}
                  </div>
                </TableRow>
              </TableBody>
            </Table>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <CustomTableCell className="text-center">
                    Business Models
                  </CustomTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow style={{ height: "auto" }}>
                  <div style={{ margin: "7px 0", textAlign: "center" }}>
                    {this.props.applicable_business_models.map((val) => (
                      <Chip
                        size="small"
                        //icon={<FaceIcon />}
                        label={val == "Artificial Intelligence" ? "A.I" : val}
                        //onClick={handleClick}
                        className={classes.chip}
                      />
                    ))}
                  </div>
                </TableRow>
              </TableBody>
            </Table>
            {/* <Grid container direction="row" justify="space-between" >
              <Grid container direction="column" xs={5} sm={5} alignItems="center">
                <Typography variant="caption" gutterBottom>
                Company Industries
              </Typography>
                {this.props.applicable_industries.map(val => (
                  <Chip
                    size="small"
                    //icon={<FaceIcon />}
                    label={val == 'Artificial Intelligence' ? "A.I" : val}
                    //onClick={handleClick}
                    className={classes.chip}
                  />
                ))}
              </Grid>
              <Grid container direction="column" xs={3} sm={3} alignItems="center">
                <Typography variant="caption" gutterBottom>
                Company Stages
              </Typography>
                {this.props.applicable_business_stages.map(val => (
                <Chip
                  size="small"
                  //icon={<FaceIcon />}
                  label={this.props.getStage(parseFloat(val), stage_list)}
                  //onClick={handleClick}
                  className={classes.chip}
                />
              ))}
              </Grid>
              <Grid container direction="column" xs={4} sm={4} alignItems="center">
                <Typography variant="caption" gutterBottom>
                 Business Models
              </Typography>
              {this.props.applicable_business_models.map(val => (
                <Chip
                  size="small"
                  //icon={<FaceIcon />}
                  label={val == 'Artificial Intelligence' ? "A.I" : val}
                  //onClick={handleClick}
                  className={classes.chip}
                />
              ))}
              </Grid>
            </Grid> */}
          </CardContent>
        </Collapse>
      </Card>
    );
  }
}

RecipeReviewCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RecipeReviewCard);
