import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";

export const Hero = styled.div`
  position: relative;
  padding-left: 5vw;
  padding-right: 5vw;
  padding-top: 7rem;
  padding-bottom: 4.6875rem;
  background-image: linear-gradient(
      217deg,
      rgba(46, 140, 237, 1),
      rgba(255, 0, 0, 0) 70.71%
    ),
    linear-gradient(
      127deg,
      rgba(211, 54, 32, 1),
      rgba(140, 0, 255, 0.3) 70.71%
    ),
    linear-gradient(336deg, rgba(140, 0, 255, 0.8), rgba(0, 0, 255, 0) 70.71%);

  background-size: 400%;
  background-position: left;
  animation: gradient 4s ease infinite alternate;

  @keyframes gradient {
    0% {
      background-position: left;
    }
    50% {
      background-position: center left;
    }
    100% {
      background-position: right top;
    }
  }

  @media screen and (min-width: 35rem) {
    padding-bottom: 7.1123rem;
    padding-right: 0;
  }

  @media screen and (min-width: 50rem) {
    padding-left: 9vw;
    padding-right: 0;
    /* padding-top: 9.375rem; */
    padding-bottom: 3.1235rem;
  }
`;

export const Overlay = styled.div`
  background-color: rgba(140, 0, 255, 0.3);
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  mix-blend-mode: overlay;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 35rem) {
    width: 100%;
  }

  /* 
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(35rem,1fr));
	direction: ltr;

	@media screen and (max-width: 35rem) {
		grid-template-columns: 100%;
	} */
`;

export const HeroContent = styled.div`
  display: block;
  margin-top: 0;
  z-index: 5;

  @media screen and (max-width: 35rem) {
    margin-top: 25rem;
    margin-left: 2rem;
  }
`;

export const HeroTitle = styled.h1`
  color: var(--color-white);
  z-index: 10;
  text-align: left;
  font-size: 3.125rem;
  line-height: 4rem;
  font-weight: 700;
  text-shadow: 0.1rem 0.1rem 0.5rem #3e356e;
  margin-bottom: 1.5rem;
  letter-spacing: 0.2rem;

  @media screen and (max-width: 35rem) {
    text-align: center;
    font-size: 2rem;
    line-height: 1.4;
  }
`;

export const HeroDesc = styled.ul`
  color: var(--color-white);
  text-align: left;
  font-size: 1.25rem;
  font-weight: 400;
  margin-bottom: 4rem;
  text-shadow: 0.1rem 0.1rem 0.5rem #3e356e;
  margin-top: 1.25rem;
  padding-left: 1rem;

  & > li {
    margin-top: 1em;
    padding-left: 0;
  }

  @media screen and (max-width: 35rem) {
    font-size: 0.9rem;
    padding-left: 0;

    text-shadow: none;
    color: var(--color-white);
  }
`;

export const TopWrap = styled.div`
  display: none;
  z-index: 10;
  position: absolute;

  @media screen and (max-width: 35rem) {
    display: block;
    top: 0;
    margin-top: 7rem;
  }
`;

export const BottomWrap = styled.div`
  display: block;
  z-index: 10;
  width: 50%;
  /* position: absolute;
	right: 0;
	top: 0; */

  @media screen and (max-width: 35rem) {
    display: none;
  }
`;

export const Img = styled.img`
  width: 100%;
  /* max-width: 900px; */
`;

export const EmailWrap = styled.div`
  display: flex;
  justify-content: space-between;
  background: var(--color-white);
  padding: 0.3rem;
  border-radius: 0.5rem;
  width: 100%;
`;

export const EmailInput = styled.input`
  color: var(--color-black);
  padding: 0.5rem;
  font-size: 1.2rem;
  width: 100%;
  border-radius: 0.5rem;
  border: none;
  outline: none;
  box-shadow: none;

  &:focus,
  &:active {
    border: none;
    outline: none;
    box-shadow: none !important;
  }

  @media screen and (max-width: 35rem) {
    font-size: 1rem;
  }
`;

export const HeroBtnLink = styled(LinkR)`
  white-space: nowrap;
  font-weight: normal;
  font-size: 1.15rem;
  border-radius: 0.5rem;
  padding: 1rem;
  background: var(--color-dark-neutral);
  color: var(--color-white);
  margin-right:1rem;

  &:hover {
    background: var(--color-darker-neutral);
  }
  @media screen and (max-width: 35rem) {
    font-size: 1rem;
    padding: 0.7rem;
  }
`;

export const HeroBtnLinkWhite = styled(LinkR)`
  white-space: nowrap;
  font-weight: normal;
  font-size: 1.15rem;
  border-radius: 0.5rem;
  padding: 1rem;
  background: var(--color-light-neutral);
  color: var(--color-dark);
  margin-right:1rem;

  &:hover {
    background: var(--color-darker-neutral);
  }
  @media screen and (max-width: 35rem) {
    font-size: 1rem;
    padding: 0.7rem;
  }
`;
