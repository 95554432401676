import React, { Component } from 'react';
// import './sass/main.css';







import Navbar from '../components/navbar/M-navbar';
import SideDrawer from '../components/sideDrawer/M-sideDrawer';
import Backdrop from '../components/backdrop/M-backdrop';




class Layout extends Component {

  state = {
    sideDrawerOpen: false

  };

  drawerToggleClickHandler = () => {

    this.setState((prevState) => {

      return {
        sideDrawerOpen: !prevState.sideDrawerOpen
      };

    });

  }

  backdropClickHandler = () => {
    this.setState({
      sideDrawerOpen: false
    })
  }


  render() {


    let backdrop;

    if (this.state.sideDrawerOpen) {

      backdrop = <Backdrop click={ this.backdropClickHandler } />;
    }
    return (
      <div>
        <Navbar drawerToggleClickHandler={ this.drawerToggleClickHandler } />
        <SideDrawer show={ this.state.sideDrawerOpen } />
        { backdrop }
      </div>

      );
  }

}

export default Layout;
