import React from 'react';

import '../test_mj-dev/sass/sass-css/_privacy_policy.scss';
import Navbar from './components/navbar';
import SideMenu from './SideMenu';
import './ubaidPrivacyandPolicy.css';



const PrivacyPolicyPage = props => {

	return (
		<React.Fragment>
			<Navbar />
			<SideMenu />
			<div className="privacyPolicyPage">

				<div className="termsConditionsPage__title"><h1><b>Privacy Policy</b></h1></div>
				<strong>Privacy Policy</strong>

				&nbsp;

				This Privacy Policy applies to all Websites that we own and operate, including the Websites located at www.startupfuel.tv and all related Websites, our applications and/or APIs, and online services (our “Website”), as well as our other services, offerings, and other interactions (e.g., customer service inquiries, user collaborations, etc.) you may have with us (together with our Website, collectively referred to as our “Services”). If you do not agree with the data practices described in this Privacy Policy, you should not use our Services. This Privacy Policy does not apply to any third party applications or software that integrate with the Services through our Website (“Third Party Services”), or any other third party products, services or businesses.

				&nbsp;

				<strong>Information We Collect and Receive</strong>

				&nbsp;

				We collect personal and business information about you in the following ways:

				Information you give us

				Information that you may provide through the Services or otherwise communicate with us includes:
				<ul>
					<li>Personal and Business Contact information, such as your first name, last name, postal address, email address, telephone number, job title, employer name and business name where applicable.</li>
					<li>Profile information, such as your username and password, picture, industry, interests and preferences.</li>
					<li>Content you post on our Website (documents, text, images, photographs, messages, comments or any other kind of content that is not your e-mail address).</li>
					<li>Feedback and correspondence, such as information you provide in your responses to surveys, when you participate in market research activities, report a problem with our Website, receive customer support or otherwise correspond with us.</li>
					<li>Transaction information, registrations you make through our Website;</li>
					<li>Usage information, such as information about how you use our Website and interact with us.</li>
					<li>Marketing information, such as your preferences for receiving marketing communications and details about how you engage with them.</li>
				</ul>
				<strong>Information from Social Networking Websites.</strong>

				Our Website includes interfaces that allow you to connect with social networking Websites (each a “SNS”). If you connect to a SNS through our Website you authorise us to access, use and store the information that you agreed the SNS could provide to us based on your settings on that SNS. We will access, use and store that information in accordance with this Notice. You can revoke our access to the information you provide in this way at any time by amending the appropriate settings from within your account settings on the applicable SNS.

				<strong>Information we get from others.</strong>

				We do not purchase data about you from other individuals or organizations.

				We may combine other publicly available information, such as that provided by business networking Websites and databases, information related to the organization for which you work and personal interests and skill sets, with the personal information that you provide to us through our Services.

				We also may obtain information from those in the StartupFuel network such as our event organizers, contractors, and local representatives who have agreed to a relationship with us in order to deliver our Services. This may include information about potential interest in our programs, company and individual progress, interest in local events, and attendance at other similar events.

				<strong>Information automatically collected</strong>

				We may automatically log information about you and your computer or mobile device when you access our Website. For example, we may log your computer or mobile device operating system name and version, manufacturer and model, browser type, browser language, screen resolution, the Website you visited before browsing to our Website, pages you viewed, how long you spent on a page, access times and information about your use of and actions on our Website.  We collect this information about you using cookies. Please refer to the Cookies and Similar Technologies section below for more details.

				<strong>Changes to your personal information</strong>

				It is important that the personal information we hold about you is accurate and current.  Please let us know if your personal information changes during your relationship with us by updating your registration profile or emailing us at <a href="mailto:privacy@startupfuel.com">privacy@startupfuel.com</a>

				&nbsp;

				<strong>Cookies and Similar Technologies</strong>

				We may collect information using “cookies.” Cookies are small data files stored on the hard drive of your computer or mobile device by a website. We may use both session cookies (which expire once you close your web browser) and persistent cookies (which stay on your computer or mobile device until you delete them) to provide you with a more personal and interactive experience on our Website. Cookies are used to improve the user experience by remembering previous interactions with the Website.

				We use two broad categories of cookies: (1) first party cookies, served directly by us to your computer or mobile device, which we use to recognize your computer or mobile device when it revisits our Website; and (2) third party cookies, which are served by service providers on our Website, and can be used by such service providers to recognize your computer or mobile device when it visits other websites.

<strong>Cookies we use</strong>

				Our Website uses the following types of cookies for the purposes set out below:

				&nbsp;

				&nbsp;
				{/* <table width="792">
					<tbody>
						<tr>
							<td width="159">Type of cookie</td>
							<td width="633">Purpose</td>
						</tr>
						<tr>
							<td width="159"><em>Essential Cookies</em></td>
							<td width="633">These cookies are essential to provide you with services available through our Website and to enable you to use some of its features. Without these cookies, the services that you have asked for cannot be provided, and we only use these cookies to provide you with those services.</td>
						</tr>
						<tr>
							<td width="159"><em>Analytics and Performance Cookies</em></td>
							<td width="633">These cookies are used to collect information about traffic to our Website and how users use our Website. The information gathered may include the number of visitors to our Website, the webWebsites that referred them to our Website, the pages they visited on our Website, what time of day they visited our Website, whether they have visited our Website before, and other similar information.  We use this information to help operate our Website more efficiently, to gather broad demographic information and to monitor the level of activity on our Website.

							We use Google Analytics for this purpose. Google Analytics uses its own cookies. It is only used to improve how our Website works. You can find out more information about Google Analytics cookies <a href="https://developers.google.com/analytics/resources/concepts/gaConceptsCookies">here</a> and about how Google protects your data <a href="http://www.google.com/analytics/learn/privacy.html">here</a>.  You can prevent the use of Google Analytics relating to your use of our Website by downloading and installing the browser plugin available <a href="http://tools.google.com/dlpage/gaoptout?hl=en-GB">here</a>.</td>
						</tr>
						<tr>
							<td width="159"><em>Social Media Cookies</em></td>
							<td width="633">These cookies are used when you share information using a social media sharing button or “like” button on our Website or you link your account or engage with our content on or through a social networking webWebsite such as Facebook or Twitter. The social network will record that you have done this.</td>
						</tr>
					</tbody>
				</table> */}
				<div className='ubaidPolicy'>
					<div className='UbaidPolicyFlex'>
						<b>Type of cookie</b>
						<div><b>Purpose</b></div>
					</div>
					<div className='UbaidPolicyFlex'>
						<b>Essential Cookies</b>
						<div>These cookies are essential to provide you with services available through our Website and to enable you to use some of its features. Without these cookies, the services that you have asked for cannot be provided, and we only use these cookies to provide you with those services.</div>
					</div>
					<div className='UbaidPolicyFlex'>
						<b>Analytics and Performance Cookies</b>
						<div>
							These cookies are used to collect information about traffic to our Website and how users use our Website.
							The information gathered may include the number of visitors to our Website, the webWebsites that referred them to our Website,
							the pages they visited on our Website, what time of day they visited our Website, whether they have visited our Website before,
							and other similar information.  We use this information to help operate our Website more efficiently, to gather broad demographic
							information and to monitor the level of activity on our Website.
							We use Google Analytics for this purpose. Google Analytics uses its own cookies. It is only used to improve how our Website works. You can find out more information about Google Analytics cookies <a href="https://developers.google.com/analytics/resources/concepts/gaConceptsCookies">here</a> and about how Google protects your data <a href="http://www.google.com/analytics/learn/privacy.html">here</a>.  You can prevent the use of Google Analytics relating to your use of our Website by downloading and installing the browser plugin available <a href="http://tools.google.com/dlpage/gaoptout?hl=en-GB">here</a>.
						</div>
					</div>
					<div className='UbaidPolicyFlex'>
						<b>Social Media Cookies</b>
						<div>These cookies are used when you share information using a social media sharing button or “like” button on our Website or you link your account or engage with our content on or through a social networking webWebsite such as Facebook or Twitter. The social network will record that you have done this.</div>
					</div>
				</div>
				&nbsp;

				&nbsp;

				<strong>Disabling cookies</strong>

				You can typically remove or reject cookies via your browser settings. In order to do this, follow the instructions provided by your browser (usually located within the “settings,” “help” “tools” or “edit” facility).  Many browsers are set to accept cookies until you change your settings.

				Further information about cookies, including how to see what cookies have been set on your computer or mobile device and how to manage and delete them, visit <a href="http://www.allaboutcookies.org/">www.allaboutcookies.org</a>.

				If you do not accept our cookies, you may experience some inconvenience in your use of our Website. For example, we may not be able to recognize your computer or mobile device and you may need to log in every time you visit our Website.

				&nbsp;

<strong>Pixel tags</strong>

				We may also use pixel tags (which are also known as web beacons and clear GIFs) on our Website to track the actions of users on our Website. Unlike cookies, which are stored on the hard drive of your computer or mobile device by a webWebsite, pixel tags are embedded invisibly on webpages. Pixel tags measure the success of our marketing campaigns and compile statistics about usage of the Website, so that we can manage our content more effectively. The information we collect using pixel tags is not linked to our users’ personal information.

<strong>Do Not Track Signals</strong>

				Some Internet browsers may be configured to send “Do Not Track” signals to the online services that you visit. We currently do not respond to do not track signals. To find out more about “Do Not Track,” please visit <a href="http://www.allaboutdnt.com/">http://www.allaboutdnt.com</a>.

				&nbsp;

<strong>How We Use Your Information</strong>

				&nbsp;

				To provide our Services

				If you have an account with us or use our Website, we use your personal information to:
<ul>
					<li>operate, maintain, administer and improve our Website;</li>
					<li>manage and communicate with you regarding your account, if you have one, including by sending you service announcements, technical notices, updates, security alerts, and support and administrative messages;</li>
					<li>process and manage requests you make through our Website, including to track and administer Services you have requested;</li>
					<li>better understand your needs and interests, and personalize your experience with the Website; and</li>
					<li>provide support and maintenance for the Website and our Services.</li>
				</ul>
				&nbsp;

				To communicate with you

				If you request information from us, register on our Website, or participate in our surveys, promotions or activities, we may send you updates, newsletters, surveys, offers, and other promotional materials that may be targeted based on your personal information as permitted by law. We also may, from time to time, contact you about a particular offering that may be of interest to you based on publicly available information or information obtained through the StartupFuel network. You will have the ability to opt out of such communications.

				To comply with law

				We use your personal information as we believe necessary or appropriate to comply with applicable laws, lawful requests and legal process, such as to respond to court summons or requests from government authorities.

				With your consent

				We may use or share your personal information with your consent, such as when you: (i) consent to participate in additional surveys on gender and race/ethnicity (where legally permissible), (ii) let us post your testimonials or endorsements on our Websites, (iii) instruct us to take a specific action with respect to your personal information, or (iv) opt into third party marketing communications.

				To create aggregated or de-identified information

				We may disclose or use aggregated or de-identified information for any purpose. For example, we may share aggregated or de-identified information with prospects, partners, or sponsors for business or research purposes, such as when we analyze and report on demographic trends to measure our objective of having healthy diversity ratios across our programs.

				For compliance, fraud prevention and safety

				We use your personal information as we believe necessary or appropriate to (a) enforce the terms and conditions that govern the Service; (b) protect our rights, privacy, safety or property, and/or that of you or others; and (c) protect, investigate and deter against fraudulent, harmful, unauthorized, unethical or illegal activity.

				How We Share Your Information

				Except as described in this Privacy Policy, we do not share the personal information that you provide to us with other organizations and we will never sell your data. We disclose personal information to third parties for the following purposes:
<ul>
					<li>Partners and User Groups. We may disclose your personal information to our partners with whom we have contracted to deliver industry-focused Accelerator and Startup Programs, and to other users of our Services, solely for the purpose of providing our products and services, including sharing with other members of the groups for which you are registered (e.g. startups, investors). For example, when you may seek advice on improving your business’ operational effectiveness, your personal information may be shared with the identified third party business partner in order to provide additional expertise, with your consent.</li>
					<li>We may disclose your personal information to our parent company, subsidiaries, joint ventures, or other corporate affiliates for purposes consistent with this Privacy Policy.</li>
					<li>Service Providers. We may employ third party companies and individuals to administer and provide the Services on our behalf (such as account creation, quality assurance testing, technical support, hosting, email delivery and database management services). These third parties may use your information only as directed by StartupFuel and in a manner consistent with this Privacy Policy, and are prohibited from using or disclosing your information for any other purpose.</li>
					<li>Professional advisors.  We may disclose your personal information to professional advisors, such as lawyers, bankers, auditors and insurers, where necessary in the course of the professional services that they render to us.</li>
					<li>Compliance with Laws and Law Enforcement; Protection and Safety. StartupFuel may disclose information about you to government or law enforcement officials or private parties as required by law, and disclose and use such information as we believe necessary or appropriate to (a) comply with applicable laws and lawful requests and legal process, such as to respond to court summons or requests from government authorities; (b) enforce the terms and conditions that govern the Services; (c) protect our rights, privacy, safety or property, and/or that of you or others; and (d) protect, investigate and deter against fraudulent, harmful, unauthorized, unethical or illegal activity.</li>
					<li>Business Transfers. We may sell, transfer or otherwise share some or all of its business or assets, including your personal information, in connection with a business deal (or potential business deal) such as a merger, consolidation, acquisition, reorganization or sale of assets or in the event of bankruptcy, in which case we will make reasonable efforts to require the recipient to honour this Privacy Policy.</li>
				</ul>
				Your Choices
<ul>
					<li>Access, Update, Correct or Delete Your Information. All account holders may review, update, correct or delete the personal information in their registration profile by logging into their account. You may also contact us at <a href="mailto:privacy@startupfuel.com">privacy@startupfuel.com</a> to accomplish the foregoing or if you have additional requests or questions.</li>
					<li>Email communications. You may opt out of newsletters and marketing-related emails by following the unsubscribe instructions on a link at the bottom of each such email, or by contacting us at <a href="mailto:privacy@startupfuel.com">privacy@startupfuel.com</a>. You may continue to receive service-related and other non-marketing emails.</li>
					<li>Withdrawing consent. If you gave us consent to use your personal information, but wish to update or delete it, please contact us at <a href="mailto:privacy@startupfuel.com">privacy@startupfuel.com</a>.</li>
					<li>Choosing not to share your personal information. Where we are required by law to collect your personal information, or where we need your personal information in order to provide the Services to you, if you do not provide this information when requested (or you later ask to delete it), we may not be able to provide you with the Services and may need to close your account. We will tell you what information you must provide to receive the Services by designating it as required in the Services or through other appropriate means.</li>
					<li>Uninstalling our applications. You can stop all collection of information by the Application(s) by uninstalling the Application(s). You may use the standard uninstall processes as may be available as part of your mobile device or via the mobile application marketplace or network.</li>
				</ul>
				&nbsp;

<strong>Security</strong>

				The security of your personal information important to us.  We take a number of organizational, technical and physical measures designed to protect the personal information we collect, both during transmission and once we receive it.  However, no security safeguards are 100% secure and we cannot guarantee the security of your information.

				&nbsp;

<strong>Other Websites and Services</strong>

				Our Website may contain links to other websites and services. These links are not an endorsement, authorization or representation that we are affiliated with that third party. We do not exercise control over third party websites or services, and are not responsible for their actions. Other websites and services follow different rules regarding the use or disclosure of the personal information you submit to them. We encourage you to read the privacy policies of the other websites you visit and services you use.

<strong>User Generated Content</strong>

				We may make available on our Website, or link to, features that allow you to share information online (e.g., on message boards, in chat areas, in file uploads etc.). Please be aware that whenever you voluntarily disclose personal information online, that information becomes public and can be collected and used by others. We have no control over, and take no responsibility for, the use, storage or dissemination of such publicly-disclosed personal information. By posting personal information online in public forums, you may receive unsolicited messages from other parties.

<strong>Changes to this Privacy Policy</strong>

				We reserve the right to modify this Privacy Policy at any time. We encourage you to periodically review this page for the latest information on our privacy practices. If we make material changes to this Privacy Policy you will be notified via e-mail (if you have an account where we have your contact information) or another manner through the Services that we believe reasonably likely to reach you (which may include posting a new privacy policy on our Website – or a specific announcement on this page or on our blog).

				Any modifications to this Privacy Policy will be effective upon our posting of the new terms and/or upon implementation of the new changes on the Website (or as otherwise indicated at the time of posting). In all cases, your continued use of our Services after the posting of any modified Privacy Policy indicates your acceptance of the terms of the modified Privacy Policy.

				&nbsp;

<strong>Retention</strong>

				We will only retain your personal information for as long as necessary to fulfil the purposes for which it was collected, including for the purposes of satisfying any legal, accounting, or reporting requirements.

				To determine the appropriate retention period for personal information, we consider the amount, nature, and sensitivity of the personal information, the potential risk of harm from unauthorized use or disclosure of your personal information, the purposes for which we process your personal information and whether we can achieve those purposes through other means, and the applicable legal requirements.

				In some circumstances, we may anonymize your personal information (so that it can no longer be associated with you) in which case we may use this information indefinitely without further notice to you.

<strong>Children’s Privacy</strong>

				Our Products and Services are not directed to children under the age of 13, and we do not intentionally gather personal information from visitors who are under the age of 13, without their parental or guardian’s consent. If a parent or guardian becomes aware that his or her child has provided us with information without their consent, he or she should contact us at the contact information provided above. We will delete such information from our files as soon as reasonably practicable.

				Contact Us

If you have any questions or concerns or complaints about our Privacy Policy or our data collection or processing practices, please contact us at <a href="mailto:privacy@startupfuel.com">privacy@startupfuel.com</a>, or write to us at:

				&nbsp;

				StartupFuel

				413 Brimorton Road, Toronto, ON, M1H 2E4

<strong> </strong>

				<strong>Compliance with the Personal Information Protection and Electronic Documents Act (PIPEDA)</strong>

				&nbsp;

				StartupFuel is headquartered in Canada and is governed by PIPEDA, personal information includes any factual or subjective information, recorded or not, about an identifiable individual. This includes information in any form, such as:

				&nbsp;
<ul>
					<li>age, name, ID numbers, income, ethnic origin, or blood type;</li>
					<li>opinions, evaluations, comments, social status, or disciplinary actions; and</li>
					<li>employee files, credit records, loan records, medical records, existence of a dispute between a consumer and a merchant, intentions (for example, to acquire goods or services, or change jobs).</li>
				</ul>
				&nbsp;

<strong>Our Responsibilities under the Act</strong>

				&nbsp;

				We have a duty to follow the code for the protection of personal information. This code is included in the Act as Schedule 1.

				&nbsp;

				These principles are rooted in international data protection standards and are based on the Canadian Standards Association's Model Privacy Code for the Protection of Personal Information. The code was developed with input from businesses, governments, consumer associations and other privacy stakeholders.Your information, including personal information that we collect from you, may be transferred to, stored at and processed by us and our affiliates and other third parties outside the country in which you reside, including, but not limited to the Canada, where data protection and privacy regulations may not offer the same level of protection as in your jurisdiction. We will take all reasonable steps to ensure that your data is treated securely and in accordance with this Policy. References to “personal information” in this Privacy Policy are equivalent to “personal data” governed by European Union data protection legislation. We use the following safeguards if StartupFuel transfers personal information originating from the European Union to other countries not deemed adequate under applicable data protection law.

				&nbsp;

				&nbsp;

				&nbsp;

				&nbsp;

<strong>Controller</strong>

				StartupFuel is the controller of your personal information for purposes of European data protection legislation.

				&nbsp;

				With respect to personal data received or transferred, StartupFuel is subject to the regulatory enforcement powers of the Canadian Privacy Commissioner.

				We commit to cooperate with the EU data protection authorities and comply with advice given by such authorities with respect to any human resources data transferred from the EU in the context of the employment relationship. In certain situations, we may be required to disclose personal data in response to lawful requests by public authorities, including to meet national security or law enforcement requirements.

We commit to resolve complaints about our collection or use of your personal information. EU individuals with inquiries or complaints regarding our privacy PIPEDA compliance policy should first contact StartupFuel at <a href="mailto:privacy@startupfuel.com">privacy@startupfuel.com</a>. If you have an unresolved privacy or data use concern that we have not addressed satisfactorily.

				&nbsp;

				You may be entitled to invoke binding arbitration when other dispute resolution procedures have been exhausted.

				&nbsp;

<strong>Legal bases for processing</strong>
				{/* <table width="792">
					<tbody>
						<tr>
							<td width="180"><strong>Processing purpose </strong>(click link for details)</td>
							<td width="612"><strong>Legal basis</strong></td>
						</tr>
						<tr>
							<td width="180">To provide our Services</td>
							<td width="612">Processing is necessary to perform the contract governing our provision of the Services or to take steps that you request prior to signing up for the Services.</td>
						</tr>
						<tr>
							<td width="180">To communicate with you To create aggregated or de-identified information  For compliance, fraud prevention and safety</td>
							<td width="612">These processing activities constitute our legitimate interests.  We make sure we consider and balance any potential impact on you (both positive and negative) and your rights before we process your personal information for our legitimate interests. We do not use your personal information for activities where our interests are overridden by the impact on you (unless we have your consent or are otherwise required or permitted to by law).</td>
						</tr>
						<tr>
							<td width="180">To comply with law</td>
							<td width="612">Processing is necessary to comply with our legal obligations</td>
						</tr>
						<tr>
							<td width="180">With your consent</td>
							<td width="612">Processing is based on your consent. Where we rely on your consent you have the right to withdraw it anytime in the manner indicated in the Service or by contacting us at <a href="mailto:privacy@startupfuel.com">privacy@startupfuel.com</a></td>
						</tr>
					</tbody>
				</table> */}
				<div className='ubaidPolicy'>
					<div className='UbaidPolicyFlex'>
						<span><strong>Processing purpose </strong>(click link for details)</span>
						<b>Legal basis</b>
					</div>
					<div className='UbaidPolicyFlex'>
						<span>To provide our Services</span>
						Processing is necessary to perform the contract governing our provision of the Services or to take steps that you request prior to signing up for the Services.
					</div>
					<div className='UbaidPolicyFlex'>
						<span>To communicate with you To create aggregated or de-identified information  For compliance, fraud prevention and safety</span>
						<div>
							These processing activities constitute our legitimate interests.  We make sure we consider and balance any potential impact on you (both positive and negative) and your rights before we process your personal information for our legitimate interests. We do not use your personal information for activities where our interests are overridden by the impact on you (unless we have your consent or are otherwise required or permitted to by law).
							Processing is based on your consent. Where we rely on your consent you have the right to withdraw it anytime in the manner indicated in the Service or by contacting us at <a href="mailto:privacy@startupfuel.com">privacy@startupfuel.com</a>
						</div>
					</div>
					<div className="UbaidPolicyFlex">
						<span>To comply with law</span>
						Processing is necessary to comply with our legal obligations
					</div>
					<div className='UbaidPolicyFlex'>
						<span>With your consent</span>
						<div>
						Processing is based on your consent. Where we rely on your consent you have the right to withdraw it anytime in the manner indicated in the Service or by contacting us at <a href="mailto:privacy@startupfuel.com">privacy@startupfuel.com</a>
						</div>
					</div>
				</div>
				<strong> </strong>

				&nbsp;

				&nbsp;

				We only use your personal information as permitted by law.  We are required to inform you of the legal bases of our processing of your personal information, which are described in the table below.

<strong>Use for new purposes</strong>

				We may use your personal information for reasons not described in this Privacy Policy where permitted by law and the reason is compatible with the purpose for which we collected it.  If we need to use your personal information for an unrelated purpose, we will notify you and explain the applicable legal basis.

<strong>Your Rights</strong>

				European data protection laws give you certain rights regarding your personal information. You may ask us to take the following actions in relation to your personal information that we hold:
<ul>
					<li>Opt-out. Stop sending you direct marketing communications. You may continue to receive service-related and other non-marketing emails.</li>
					<li> Provide you with information about our processing of your personal information and give you access to your personal information.</li>
					<li> Update or correct inaccuracies in your personal information.</li>
					<li> Delete your personal information.</li>
					<li> Transfer a machine-readable copy of your personal information to you or a third party of your choice.</li>
					<li> Restrict the processing of your personal information.</li>
					<li>Object to our reliance on our legitimate interests as the basis of our processing of your personal information that impacts your rights.</li>
				</ul>
				You can submit these requests by email to <a href="mailto:privacy@startupfuel.com">privacy@startupfuel.com</a>.  We may request specific information from you to help us confirm your identity and process your request. Applicable law may require or permit us to decline your request. If we decline your request, we will tell you why, subject to legal restrictions. If you would like to submit a complaint about our use of your personal information or response to your requests regarding your personal information, you may contact us at <a href="mailto:privacy@startupfuel.com">privacy@startupfuel.com</a> or submit a complaint to the data protection regulator in your jurisdiction. You can find your data protection regulator <a href="http://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm">here</a>.

				&nbsp;

<strong>Cross-Border Data Transfer</strong>

				Whenever we transfer your personal information out of the EEA to countries not deemed by the European Commission to provide an adequate level of personal information protection, the transfer will be based on one of the following safeguards recognized by the European Commission as providing adequate protection for personal information, where required by EU data protection legislation:
<ul>
					<li>Contracts approved by the European Commission which impose data protection obligations on the parties to the transfer.  For further details, see European Commission Model contracts for the transfer of personal information to third countries.</li>
				</ul>
				&nbsp;

Please <a href="http://startupfuel.tv/contact-us/">contact us</a> if you want further information on the specific mechanism used by us when transferring your personal information out of the EEA.



			</div>
		</React.Fragment>

	)

}





export default PrivacyPolicyPage;
