import React from 'react';
import HeartIcon from '../icons/HeartIcon';
import classnames from 'classnames';
import ProfileImage from '../../../components/profile_image';
import ThreeDotsIcon from '../icons/ThreeDotsIcon';

class PostHeader extends React.Component {
  constructor(props) {
    super(props);
    this.dropDown = React.createRef();
  }

  showDropdown(e) {
    e.preventDefault();
    e.stopPropagation();
    var dropDown = this.dropDown.current;

    if (!dropDown.classList.contains('active')) {
      dropDown.classList.add('active');
    }
  }

  hideDropdown(e) {
    e.preventDefault();
    e.stopPropagation();
    var dropDown = this.dropDown.current;

    if (dropDown.classList.contains('active')) {
      dropDown.classList.remove('active');
    }
  }

  render() {
    let className;
    this.props.likes !== undefined ? className = 'PostCardsNewsFeedHeaderWithBorder' : className = ''
    return (
      <div className={ className }>
        <div className='PostCardsNewsFeedHeader'>
          <ProfileImage
            company_id={ this.props.company_id }
            user_id={ this.props.user_id } />
          <div className="mainContent">
            <a href={ this.props.company_id ? '/profile?company_id=' + this.props.company_id : '#' }>
              { this.props.name }
            </a>
            { this.props.child }
            <br />
            { this.props.time }
          </div>
          <div
            className={classnames('optionsBtn', !this.props.isPostOwner && 'hide')}
            onClick={ this.showDropdown.bind(this) }>
            <ThreeDotsIcon />
            <div
              ref={ this.dropDown }
              onClick={ this.hideDropdown.bind(this) }
              className={"socialDropDownMenu"}>
              <div className="warning" onClick={ this.props.handleDelete }>
                Delete&nbsp;Post
              </div>
              <div>
                Report&nbsp;Content
              </div>
            </div>
          </div>
        </div>
        <div className='PostChildrenNewsFeed'>
          { this.props.children }
        </div>
        { className !== '' && (
          <div className='PostSocialIcons'>
            <a
              className={ classnames('PostSocialLikeButton', this.props.currentlyLiked && 'active') }
              href='#'
              onClick={ this.props.handleLikeToggle }>
              <HeartIcon />
              { this.props.likes }
            </a>
          </div>
          ) }
      </div>
    )
  }
}

export default PostHeader;
