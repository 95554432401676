/* (2019-06-10) - This page is actually a page of the SIR/ApplicationRoom
It contains all of the applicants that have applied to part of the opportunity.
It basically collects all of the applicants by querying the opportunity_applicants
schema. The components used on this page can be found:
 /application_room/components/submissions. The sidebar contains access
 to the other pages associate with the SIR.
*/
import React from "react";
import { AppContext } from "../App";
import PropTypes from "prop-types";
import axios from "axios";
import URL from "url-parse";
import "./application-room-index.scss";
import SideBar from "./application_room/components/Sidebar";
import SideMenu from "./SideMenu";
import TeamMgmt from "./application_room/components/team_management/team";

import SiteTitle from "./components/site_title.js";
import {
  Sign_In,
  Register,
  Forgot_Password,
  Alert_Message_To_User,
} from "./components/popups";
import Page from "./application_room/components/dash/dash";
import Navigation from "./components/navbar_pb";

class Applicant_Room_Container extends React.Component {
  constructor(props) {
    super(props);
  }
  static contextType = AppContext;
  render() {
    return <Applicant_Room {...this.props} {...this.context} />;
  }
}

class Applicant_Room extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: new URL(window.location.href, true),
      judges: "",
      opportunity_owner: "",
      show_page: true,
      opportunity_data: {},
      UbaidSubClass: "",
    };
  }
  componentDidMount() {
    axios
      .get(
        `/fetch_opportunity?opportunity_id=${this.state.url.query.opportunity_id}`,
      )
      .then((response) => {
        this.setState({
          opportunity_data: response.data.result,
        });
      });
    axios
      .get(
        `fetch_judges_for_opportunity?opportunity_id=${this.state.url.query.opportunity_id}`,
      )
      .then((response) => {
        this.setState(
          {
            judges: response.data.judges,
            opportunity_owner: response.data.owner,
            access_ids: response.data.access_ids,
          },
          () => {
            setTimeout(() => {
              if (
                this.state.access_ids.includes(this.props.user._id) ||
                this.props.user.email === "ash@startupfuel.com"
              ) {
              } else {
                //this.props.history.push("/explore");
              }
            }, 1000);
          },
        );
      });
  }
  render() {
    return (
      <div className="__application-room-main">
        <Navigation opportunity_data={this.state.opportunity_data} />
        <SiteTitle />
        <Sign_In />
        <Register />
        <Forgot_Password />
        <Alert_Message_To_User
          show={this.props.showPopupMessage}
          handleChange={this.props.handleChange}
          dismiss={this.props.dismissModal}
          alert_message={this.props.alert_message}
          {...this.state}
        />

        <SideBar user={this.props.user} {...this.state} />
        <Page
          user={this.props.user}
          companies_owned={this.props.companies_owned}
          opportunity_name={this.state.opportunity_data.opportunity_name}
          {...this.state}
        />
      </div>
    );
  }
}

Applicant_Room_Container.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default Applicant_Room_Container;
