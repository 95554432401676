/* (2019-06-10) - This component is the top portion of what is displayed
on an individual opportunity page.Images, title, affiliated company etc
*/
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { AppContext } from "../../App";
import URL from "url-parse";
import { withStyles } from "@material-ui/core/styles";
import default_pic from "../../img/default_company_pic.png";
import default_background from "../../img/bg.png";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Share from "./share_popover";
import { CopyToClipboard } from "react-copy-to-clipboard";
import "./sharebuttons.css";
import "./oppHeader.css";
import Moment from "moment";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import axios from "axios";

import CFA from "./cfa";
import { timeout } from "async";
const styles = (theme) => ({
  root: {
    flex: 1,
  },
  text: {
    paddingTop: theme.spacing.unit * 2,
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
  },
  list: {
    flex: 1,
    padding: 0,
  },
  subHeader: {
    backgroundColor: theme.palette.background.paper,
  },
  media: {
    objectFit: "cover",
    height: "55vh",
    maxHeight: 480,
  },
  avatar: {
    position: "absolute",
    top: -90,
    margin: 10,
    width: 150,
    border: 2,
    background: "white",
    boxShadow: "0 3px 5px 2px lightgrey",
    height: 150,
  },
  button: {
    margin: theme.spacing.unit,
  },
  input: {
    display: "none",
  },
});

const messages = [
  {
    id: 1,
    primary: "WORLD VISION SOCIAL WASTE CHALLENGE",
    secondary: "World Vision Canada | Mississauga, Ontario, Canada",
    person: {
      default_pic,
    },
  },
];

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "some\ntext",
      copied: false,
      isOpen: false,
      isComplete: false,
      url: new URL(window.location.href, true),
    };
  }

  // componentDidMount(prevProps) {
  //   if (
  //     Array.isArray(this.props.companies_owned) &&
  //     prevProps.companies_owned !== this.props.companies_owned
  //   ) {
  //     if (this.props.companies_owned.length > 1) {
  //       console.log("get the company id", this.props.companies_owned[1][0]._id);
  //     }
  //   }
  // }

  openModal = () => this.setState({ isOpen: true });
  closeModal = () => {
    setTimeout(() => {
      this.setState({ isOpen: false });
    }, 25);
  };

  usePrior = () => {
    this.setState({ isOpen: false });
    this.props.handleApplicationPopup();
  };

  completeCFA = () => {
    this.setState({ isOpen: false, isComplete: true });
  };

  discard = () => {
    setTimeout(() => {
      //this.setState({ isComplete: false, isOpen: false });
    }, 75);

    const com_id = localStorage.getItem("com_id");

    if (com_id && !com_id.length == 0) {
      axios
        .get(`/fetch_fundability_scores_owned?company_id=${com_id}`)
        .then((response) => {
          //this.setState({ isOpen: false });
          const lotteryNumbers = response.data.result;
          const [lastNumber] = lotteryNumbers.slice(-1);

          // console.log(lotteryNumbers.slice(-1));

          const newdata = lotteryNumbers.slice(-1);
          const obj = { ...newdata };

          const firstitem = obj[0].ratings;

          // localStorage.setItem("rating", firstitem);
          setTimeout(() => {
            this.setState({ isComplete: false, isOpen: false });
            this.props.handleApplicationPopup();
          }, 50);
        });
    }
  };
  onClick = ({ target: { innerHTML } }) => {};

  closeparentmodal = () => {
    this.setState({ isComplete: false, isOpen: false });
  };

  onClick = ({ target: { innerHTML } }) => {};

  onCopy = () => {
    this.setState({
      copied: true,
    });
  };
  static contextType = AppContext;

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        {/* start complete modal cfa */}
        <Modal
          className="close-btn-popup-new"
          show={this.state.isComplete}
          onHide={() => {
            this.setState({
              isComplete: false,
            });
          }}
        >
          <Modal.Header closeButton style={{ background: "#3f51b5" }} />
          <Modal.Body>
            <CFA
              closeparentmodal={this.closeparentmodal}
              discard={this.discard}
            />
          </Modal.Body>
        </Modal>
        {/* end of complete cfa modal */}

        <Modal show={this.state.isOpen} onHide={this.closeModal}>
          <Modal.Header closeButton />
          <Modal.Body>
            <span>
              {" "}
              This opportunity requires you to complete a{" "}
              <span style={{ fontWeight: "bold" }}>
                Critical Factor Assessment
              </span>
              .{" "}
            </span>
            <br />
            <br />
            <span>
              This self‐assessment is designed to give you quick feedback on
              your project or business idea. The questions are designed to help
              you weigh your project or business idea to see how it compares and
              stands up against other opportunities. Once you have completed
              this self‐assessment, you can use it as a basis for re‐thinking
              your original idea or progressing it to the next stage. The
              assessment will cover eight (8) critical factors.
            </span>
            <br />
            <br />

            <span>
              The Critical Factor Assessment is a framework to self elevate
              yourself as a founder/startup. It is a measure of self, and gives
              reviewers a chance to understand what you see as strengths and
              weaknesses you need assistance in improving.
            </span>
            <br />
            <br />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                onClick={this.completeCFA}
                id="buttons-grid-opp-button"
                variant="contained"
                color="primary"
                className={classes.button}
                xs={12}
              >
                Complete CFA
              </Button>

              {/* <Button
                onClick={this.usePrior}
                id="buttons-grid-opp-button"
                variant="contained"
                color="primary"
                className={classes.button}
                xs={12}
              >
                Use Prior CFA
              </Button> */}
            </div>
          </Modal.Body>
        </Modal>

        <CssBaseline />
        <Card square className={classes.card} elevation={3}>
          <div className="main-oppo-new-design-wraper">
            {/*<div className="left-side-content-oppo">
              <Grid
                direction="row"
                justify="space-between"
                alignItems="center"
                item
                xs={12}
                sm={12}
                className="opp_header"
              >
                <div className="opp-section">
                  <List className={classes.list}>
                    <div className="profile-img-text-wraper">
                      <div
                        className="opportunity-profile-pic-new"
                        >
                        <div className="oppo-text-left">
                          <h4>
                            {this.props.opportunity_name}
                          </h4>
                        </div>
                        <div className="profile-img-oppo">
                          <div className="profile-img-text-wraper">
                            <img src={this.props.affiliated_company.company_files !== undefined
                              ? this.props.getAvatarUrl(
                                this.props.affiliated_company.company_files,
                                "company avatar"
                              )
                              : default_pic}/>
                              </div>
                              <div>
                               <p className="type-oppo-new">{this.props.opportunity_type}</p>
                               <div className="oppo-location-wraper">
                          <div>
                          <p><a
                            href={`/profile?company_id=${this.props.affiliated_company._id}`}
                          >
                            {this.props.affiliated_company.company_name}{" "}
                          </a>
                          </p>
                          <p>
                          {this.props.affiliated_company.city !== undefined &&
                            this.props.affiliated_company.city + " . "}
                          {this.props.affiliated_company.province !== undefined &&
                            this.props.affiliated_company.province + " . "}
                          {this.props.affiliated_company.country !== undefined &&
                            this.props.affiliated_company.country}
                          </p>
                          </div>
                           <div className="button-apply-oppo">
                          {!this.props.isLoggedIn && !this.props.has_applicant_room && (
                            <Button
                              onClick={this.props.toggleSignUpPopup}
                              id="buttons-grid-opp-button"
                              variant="outlined"
                              color="primary"
                              className={classes.button}
                              xs={12}
                            >
                              Apply On Website
                            </Button>
                          )}
                          {this.props.isLoggedIn && !this.props.has_applicant_room && (
                            <Button
                              href={this.props.website_url}
                              target="_blank"
                              id="buttons-grid-opp-button"
                              variant="outlined"
                              color="primary"
                              className={classes.button}
                              xs={12}
                            >
                              Apply On Website
                            </Button>
                          )}
                             {this.props.has_applicant_room && (
                            <span>
                              {" "}
                              {this.props.submitted_application ||
                                this.props.opportunity_list.some(
                                  (opp) =>
                                    opp.pertains_to_opportunity === this.props._id
                                ) ? (
                                <span>
                                  <Button
                                    onClick={this.props.handleApplicationPopup}
                                    id="buttons-grid-opp-button"
                                    variant="contained"
                                    disabled={
                                      Moment(this.props.end_intake_date).isAfter(
                                        Date()
                                      )
                                        ? false
                                        : true
                                    }
                                    color="secondary"
                                    className={classes.button}
                                    xs={12}
                                  >
                                    Edit Application
                                  </Button>
                                </span>
                              ) : (
                                <Button
                                  onClick={this.openModal}
                                  id="buttons-grid-opp-button"
                                  variant="contained"
                                  disabled={
                                    Moment(this.props.end_intake_date).isAfter(Date())
                                      ? false
                                      : true
                                  }
                                  className={classes.button + " OppApplyButton"}
                                >
                                  Apply Now
                                </Button>
                              )}
                            </span>
                          )}
                          </div>
                        </div>
                              </div>
                        </div>
                      </div>

                       <div
                        className="OppHeaderButtonsUbaid"
                        id="opp-buttons"
                        style={{ width: "100%" }}
                      >
                        <Grid
                          container
                          direction="column"
                          id="buttons-grid"
                          item
                          xs={12}
                          sm={3}
                        >
                          {!this.props.isLoggedIn && !this.props.has_applicant_room && (
                            <Button
                              onClick={this.props.toggleSignUpPopup}
                              id="buttons-grid-opp-button"
                              variant="outlined"
                              color="primary"
                              className={classes.button}
                              xs={12}
                            >
                              Apply On Website
                            </Button>
                          )}
                          {this.props.isLoggedIn && !this.props.has_applicant_room && (
                            <Button
                              href={this.props.website_url}
                              target="_blank"
                              id="buttons-grid-opp-button"
                              variant="outlined"
                              color="primary"
                              className={classes.button}
                              xs={12}
                            >
                              Apply On Website
                            </Button>
                          )}
                          {this.props.has_applicant_room && (
                            <span>
                              {this.props.submitted_application ||
                                this.props.opportunity_list.some(
                                  (opp) =>
                                    opp.pertains_to_opportunity === this.props._id
                                ) ? (
                                <span>
                                  <Button
                                    onClick={this.props.handleApplicationPopup}
                                    id="buttons-grid-opp-button"
                                    variant="contained"
                                    disabled={
                                      Moment(this.props.end_intake_date).isAfter(
                                        Date()
                                      )
                                        ? false
                                        : true
                                    }
                                    color="secondary"
                                    className={classes.button}
                                    xs={12}
                                  >
                                    Edit Application
                                  </Button>
                                </span>
                              ) : (
                                <Button
                                  onClick={this.openModal}
                                  id="buttons-grid-opp-button"
                                  variant="contained"
                                  disabled={
                                    Moment(this.props.end_intake_date).isAfter(Date())
                                      ? false
                                      : true
                                  }
                                  className={classes.button + " OppApplyButton"}
                                >
                                  Apply Now
                                </Button>
                              )}{" "}
                            </span>
                          )}
                        </Grid>
                      </div>
                    </div>
                  </List>
                </div>
              </Grid>
            </div>*/}
            <div className="right-side-content-oppo">
              {this.context.companies_owned !== undefined &&
                this.context.companies_owned !== null &&
                this.context.companies_owned[1] !== undefined &&
                this.context.companies_owned[1].opportunity !== undefined &&
                this.context.companies_owned[1].opportunity[0] !== undefined &&
                this.context.companies_owned[1].opportunity.some(
                  (e) => e._id === this.state.url.query.opportunity_id,
                ) && (
                  <a
                    href={`/applicant_room_settings_pb?opportunity_id=${this.state.url.query.opportunity_id}`}
                  >
                    <div className="UBaidEditOppMain">
                      <i class="fa fa-pencil UBaidEditOpp"></i>
                    </div>
                  </a>
                )}
              <CardMedia
                component="img"
                className={classes.media + " OppCoverPhoto"}
                image={
                  this.props.background_cover !== undefined
                    ? this.props.background_cover
                    : default_background
                }
              />
            </div>
          </div>
        </Card>
      </React.Fragment>
    );
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Header);
