import React from 'react';

import Nav from '../../../newsfeed/Nav/Nav';
import { AppContext } from '../../../App'

import SideMenu from '../../../live/SideMenu';
import SocialOnboardingView from './create_opportunity';
import './UbaidCreateOpp.css';

class PageContainer extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <SocialOnboardingContainer
        {...this.props}
        {...this.context} />
    )
  }
}
PageContainer.contextType = AppContext;

class SocialOnboardingContainer extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <Nav />
        <SideMenu />
        <div className='newsFeedBody'>
          <div className='newsfeed NewsFeedBodyMain'>
            <SocialOnboardingView {...this.props} />
          </div>
        </div>
      </div>
    )
  }
}

export default PageContainer;
