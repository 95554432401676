import React from 'react';
import MainLayout from './MainLayout';
import CenterBody from './CenterBody/CenterBody';
import { AppContext } from '../App'

class NewsFeed_Container extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <NewsFeed
        {...this.props}
        {...this.context} />
    )
  }
}
NewsFeed_Container.contextType = AppContext;

class NewsFeed extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <MainLayout {... this.props}>
        <CenterBody />
      </MainLayout>
    )
  }
}

export default NewsFeed_Container;
