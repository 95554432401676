/* (2019-06-11) - The card that is displayed to represent the companies on
the explore page.
*/
import React from 'react';
import classnames from 'classnames';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import DoneIcon from '@material-ui/icons/Done';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CardActionArea from '@material-ui/core/CardActionArea';
import Typography from '@material-ui/core/Typography';
import ProfileImage from '../../../live/components/profile_image';
import Moment from "moment";
import SocialAPI from '../../api/social';
import Button from '@material-ui/core/Button';

class SimpleExploreCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_following: false,
    };
  }

  handleFollow = (e) => {
    e.preventDefault();
    this.setState({ follow_loading: true }, () => {
      if (!this.state.is_following) {
        SocialAPI.follow(this.props._id)
          .then((response) => {
            this.setState({
              follow_loading: false,
              is_following: true
            }, this.props.handleFollow);
          }).catch((err) => {
            this.setState({
              follow_loading: false
            });
          });
      }
    });
  }

  render() {
    return (
      <Card className="simpleExploreCard">

        <CardHeader
        avatar={<Avatar aria-label="recipe" style={{backgroundColor:"#f44336"}}>
            {this.props.icon}
          </Avatar>}
          title={this.props.title}
          subheader={this.props.subtitle}
          action={ <CardActions
                     disableSpacing
                     style={ { paddingLeft: 0 } }>
                   </CardActions> } />
        <CardContent>
            <div className="cardContent">
            {  this.props.description}
          </div>
        </CardContent>
        <CardActions className='createOppCardAction'>
        <Button  size="small" color="primary"  className={this.props.current_state_value.includes(this.props.value)? "UbaidUnfollow" : "ubaidFollow"} onClick={(event)=>this.props.handleCardSelect(event,this.props.db_value,this.props.value)}>
        {this.props.current_state_value.includes(this.props.value)?"Selected":"Select"}
        </Button>

      </CardActions>
      </Card>
      );
  }
}
export default SimpleExploreCard;
