import React, { useState, useEffect } from "react";
import axios from "axios";
import "./submit_opportunity.css";

function Application({
  prevStep,
  formData,
  setFormData,
  opportunityData,
  companyInfo,
}) {
  const [showPopup, setShowPopup] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (companyInfo && companyInfo._id) {
      const initialData = {};
      Object.keys(companyInfo).forEach((key) => {
        initialData[key] = companyInfo[key] || "";
      });
      setFormData((prevData) => ({
        ...prevData,
        applicationData: { ...prevData.applicationData, ...initialData },
      }));
    }
  }, [companyInfo, setFormData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      applicationData: { ...prevData.applicationData, [name]: value },
    }));

    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" })); // Clear the error message when the user starts typing
  };

  const questionsArray = Object.keys(opportunityData.opportunity_form).map(
    (key) => ({
      key,
      ...opportunityData.opportunity_form[key],
    }),
  );

  const validateFields = () => {
    const newErrors = {};
    questionsArray.forEach((question) => {
      if (
        question.display &&
        question.required &&
        (!formData.applicationData[question.key] ||
          formData.applicationData[question.key].trim() === "")
      ) {
        newErrors[question.key] = "This field is required.";
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    if (validateFields()) {
      try {
        // Merge prefilled data from companyInfo with formData.applicationData
        const completeApplicationData = {
          ...companyInfo,
          ...formData.applicationData,
        };

        // Filter out any undefined, null, or empty fields
        const filteredApplicationData = Object.fromEntries(
          Object.entries(completeApplicationData).filter(
            ([_, value]) =>
              value !== undefined && value !== null && value !== "",
          ),
        );

        console.log("filteredApplicationData", filteredApplicationData);

        // Submit the company data
        await axios.post(
          `/edit_company_during_application?company_id=${companyInfo._id}`,
          filteredApplicationData,
        );

        // Submit the opportunity application
        await axios.post(`/create_opportunity_application`, {
          applicant_company: companyInfo._id,
          pertains_to_opportunity: opportunityData._id,
          changed_by: "6614c18cd21288ea18db6802",
        });

        setShowPopup(true);
      } catch (error) {
        console.error("Error submitting the form:", error);
        alert("An error occurred while submitting the form.");
      } finally {
        setIsSubmitting(false);
      }
    } else {
      setIsSubmitting(false); // Stop submitting if validation fails
    }
  };

  const handleBackToOpportunity = () => {
    window.location.href = `/opportunity?opportunity_id=${opportunityData._id}`;
  };

  return (
    <div className="opportunity-submission-card">
      <h2>Application</h2>
      <h3>Fill out the below Application questionnaire</h3>
      <div>
        {questionsArray.map((question, index) => {
          if (question.display) {
            return (
              <div key={index} className="question-container">
                <label htmlFor={question.key}>
                  {question.title}
                  {question.required && <span className="required">*</span>}
                </label>
                <textarea
                  id={question.key}
                  name={question.key}
                  placeholder={question.helper_text}
                  value={formData.applicationData[question.key] || ""}
                  onChange={handleInputChange}
                  required={question.required}
                />
                {errors[question.key] && (
                  <span className="error">{errors[question.key]}</span>
                )}
              </div>
            );
          } else {
            return null;
          }
        })}
      </div>
      <div className="submission-container">
        <button onClick={prevStep} disabled={isSubmitting}>
          Back
        </button>
        <button onClick={handleSubmit} disabled={isSubmitting}>
          {isSubmitting ? "Submitting..." : "Submit Application"}
        </button>
      </div>

      {showPopup && (
        <div className="popup-overlay">
          <div className="popup-content">
            <h2>Application Submitted</h2>
            <p>Your application has been successfully submitted!</p>
            <button className="popup-button" onClick={handleBackToOpportunity}>
              Back to Opportunity
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Application;
