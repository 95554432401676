import React from 'react';
import { lighten, makeStyles, withStyles } from '@material-ui/core/styles';
import SocialAPI from '../../live/api/social';
import ProfileImage from '../../live/components/profile_image';
import Explore_Profile_Card from '../../live/components/explore_card'
import SimpleExploreCard from '../../live/components/simple_explore_card'
import LinearProgress from '@material-ui/core/LinearProgress';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';

const BorderLinearProgress = withStyles({
  root: {
    height: 10,
    borderRadius: 20
  },
  bar: {
    borderRadius: 20,
  },
})(LinearProgress);

class SocialOnboardingView extends React.Component {
  constructor(props) {
    super(props);
    this.REQUIRED_FOLLOWS = 3;
    this.state = {
      feed: [],
      loading: false,
      newFollowCount: 0
    };
  }

  componentDidMount() {
    this.setState({
      loading: true
    }, () => {
      this.fetchFeed().then(this.fetchProfile);
    });
  }

  fetchFeed = () => {
    return SocialAPI.follow_suggestions(30)
      .then((response) => {
        if (response.data.status === 'success') {
          this.setState({
            feed: response.data.items || []
          });
        }
      })
      .catch((error) => {
        this.setState({
          loading: false
        });
      });
  }

  fetchProfile = () => {
    SocialAPI.follow_counts()
      .then((response) => {
        if (response.data.status === 'success') {
          this.setState({
            loading: false,
            display_name: response.data.display_name,
            following_count: response.data.following_count,
            newFollowCount: response.data.following_count >= this.REQUIRED_FOLLOWS ? this.REQUIRED_FOLLOWS : 0,
            follower_count: response.data.follower_count,
            company_name: response.data.company_name
          });
        }
      })
      .catch((error) => {
        this.setState({
          loading: false
        });
      });
  }

  handleFollow() {
    this.setState({
      newFollowCount: this.state.newFollowCount + 1
    });
  }

  handleNext(e) {
    e.preventDefault();
    this.props.history.push('/newsfeed');
  }

  render() {
    var isComplete = this.state.newFollowCount >= this.REQUIRED_FOLLOWS;

    return (
      <div className="SocialOnboarding">
        <div className="row">
          <div className="col">
            <h5>Please follow a minimum of { this.REQUIRED_FOLLOWS } companies before continuing:</h5>
            <br />
            <BorderLinearProgress
              style={ { "flex-grow": 1 } }
              variant="determinate"
              color="primary"
              value={ Math.max(1, (this.state.newFollowCount * (100.0 / this.REQUIRED_FOLLOWS))) } />
            <br />
          </div>
        </div>
        <div className="row ubaidOppCards">
          { this.state.feed.map((company, index) => (
              <div
                key={ index }
                className="ubaidExploreCards col-xl-4 col-lg-4 col-md-6 col-sm-12">
                <SimpleExploreCard
                  {...company}
                  handleFollow={ this.handleFollow.bind(this) }
                  index={ index } />
              </div>
            )) }
        </div>
        <AppBar
          position="fixed"
          color="default"
          style={ { top: 'auto', bottom: '0' } }>
          <Toolbar>
            <Button
              className="ctaButton"
              disabled={ !isComplete }
              size="large"
              variant="contained"
              color="primary"
              onClick={ this.handleNext.bind(this) }>
              Done
            </Button>
            <Button
              disabled
              size="large">
              { isComplete ? `All Set!` : `${this.REQUIRED_FOLLOWS - this.state.newFollowCount} more left` }
            </Button>
          </Toolbar>
        </AppBar>
      </div>
    )
  }
}

export default SocialOnboardingView;
