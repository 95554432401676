import React from 'react';
import axios from 'axios'
import classnames from 'classnames';
import default_pic from '../../img/default_company_pic.png'

// cache image responses, until next refresh
window.ProfileImageCache = {};
window.RequestPool = {};

class ProfileImage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      requesting: true
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.user_id !== prevProps.user_id || this.props.company_id !== prevProps.company_id) {
      this.setState({ requesting: false }, this.addImageRequest)
    }
  }

  componentDidMount() {
    setTimeout(this.processImageRequest.bind(this), 1);
  }

  addImageRequest() {
    if (this.state.requesting) {
      // already requesting in this component
      return;
    }

    this.setState({ requesting: true }, this.processImageRequest);
  }

  processImageRequest() {
    var cacheKey = this.imageCacheKey();

    if (window.ProfileImageCache[cacheKey]) {
      return this.setImage(false, window.ProfileImageCache[cacheKey]);
    }

    if (!window.RequestPool[cacheKey]) {
      // we are the first one making this call so we need start the pool
      window.RequestPool[cacheKey] = [];

      this.fetchImage(function(src) {
        // cache it right away
        this.setImage(true, src);
        // let other waiting components know its ready
        window.RequestPool[cacheKey].forEach(function(fn) {
          fn.call(null, src);
        });
      }.bind(this));
    } else {
      // we are not the first to make the call, so we wait...
      window.RequestPool[cacheKey].push(this.setImage.bind(this, false));
    }
  }

  fetchImage(cb) {
    var params = [];

    if (this.props.user_id) {
      params.push(`user_id=${this.props.user_id}`);
    }

    if (this.props.company_id) {
      params.push(`company_id=${this.props.company_id}`);
    }

    axios.get(`/profile_image?${params.join('&')}`)
      .then((response) => {
        if (response.data.status === 'success') {
          cb(response.data.image.file_url || default_pic);
        }
      })
      .catch((error) => {
        cb(default_pic);
      });
  }

  setImage(set_cache, image_src) {
    if (set_cache) {
      window.ProfileImageCache[this.imageCacheKey()] = image_src;
    }

    this.setState({
      requesting: false,
      image_src: image_src,
      loaded: true
    });
  }

  imageCacheKey() {
    return `img-${this.props.user_id || 'current'}-${this.props.company_id || 'current'}`
  }

  render() {
    return (
      <span className={classnames(this.props.className, 'profileImage')}>
        <img src={this.state.image_src} />
      </span>
    )
  }
}

export default ProfileImage;
