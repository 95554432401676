import React from "react";
import "./Pitchbox.css";
import Header from "./Header/Header";
import financemontrealLogo from "./assets/bb1974f3-financemontreal_02b02a00000000000001o.jpg";
import schulichLogo from "./assets/224af7f2-schulich_02g02f00000000000001o.jpg";
import westernlogo from "./assets/21fffb74-westernlogo.svg";
import HowItWorks from "./HowItWorks/HowItWorks";
import Parallax from "./Parallax/Parallax";
import FAQsPitch from "./FAQsPitch/FAQsPitch";
import Testimonials from "./Testimonials/Testimonials";

const Pitchbox = (props) => (
  <div className="PitchboxStartup">
    <Header />
    <div className="belowHeaderPitch">
      These organizations/institutions have already started using StartupFuel's
      PitchBox
      <br />
      <img src={financemontrealLogo} alt="" />
      <img src={schulichLogo} alt="" />
      <img src={westernlogo} alt="" />
    </div>
    <HowItWorks />
    <Parallax />
    <FAQsPitch />
    <Testimonials />
    <div className="pitchFooter">
      @2024 StartupFuel Inc. All Rights Reserved.
    </div>
  </div>
);

export default Pitchbox;
