import React, { useContext, useEffect, useState } from "react";
import OpportunitySubmissionHeader from "./submit_opportunity_header";
import Application from "./application";
import CompanyInfo from "./company_info";
import "./submit_opportunity.css";
import OpportunityInfo from "./opportunity_info";
import BasicInfoStep from "./user_info";
import { AppContext } from "../../../../App";
import Loader from "../../loader";
import axios from "axios";
import UploadDocuments from "./uploadDocuments";

function SubmitOpportunity() {
  const { user, isLoggedIn, companyData } = useContext(AppContext);
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    cfaData: {}, // Add structure as per your CfaForm
    companyInfoData: {}, // Add structure as per your CompanyInfo
    applicationData: {}, // Add structure as per your Application
  });
  const [companyInfo, setCompanyInfo] = useState({});
  const [opportunityData, setOpportunityData] = useState(null);
  const [files, setFiles] = useState([]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const opportunityId = urlParams.get("opportunity_id");
    const fetchCompanyFiles = async (companyId) => {
      try {
        const response = await axios.get(
          `/fetch_company_files?company_id=${companyId}`,
        );
        console.log(response.data);
        setFiles(response.data.files);
      } catch (error) {
        console.error("Error fetching company files:", error);
      }
    };

    if (!opportunityId) {
      window.location.href = "https://app.startupfuel.com";
    } else {
      const fetchOpportunity = async () => {
        try {
          const response = await axios.get(
            `/fetch_opportunity?opportunity_id=${opportunityId}`,
          );
          console.log(response.data);
          setOpportunityData(response.data.result);
          fetchCompanyFiles(response.data.result.affiliated_company);
        } catch (error) {
          console.error("Error fetching opportunity data:", error);
        }
      };

      fetchOpportunity();
    }
  }, []);

  useEffect(() => {
    if (companyData && companyData._id) {
      setCompanyInfo(companyData);
      console.log("Company data:", companyData);
    }
  }, [companyData]);

  const nextStep = () => {
    if (currentStep < 4) {
      setCurrentStep(currentStep + 1);
    }
  };

  const prevStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <BasicInfoStep
            formData={formData}
            setFormData={setFormData}
            nextStep={nextStep}
            isLoggedIn={isLoggedIn}
            setCompanyInfo={setCompanyInfo}
          />
        );
      case 2:
        return (
          <UploadDocuments
            nextStep={nextStep}
            formData={formData}
            setFormData={setFormData}
            user={user}
            files={files}
            setFiles={setFiles}
            opportunityData={opportunityData}
            companyInfo={companyInfo}
            setCompanyInfo={setCompanyInfo}
          />
        );
      case 3:
        return (
          <CompanyInfo
            nextStep={nextStep}
            formData={formData}
            setFormData={setFormData}
            user={user}
            companyInfo={companyInfo}
            setCompanyInfo={setCompanyInfo}
          />
        );
      case 4:
        return (
          <Application
            prevStep={prevStep}
            formData={formData}
            setFormData={setFormData}
            opportunityData={opportunityData}
            companyInfo={companyInfo}
          />
        );
      default:
        return null;
    }
  };

  if (user.loading || !opportunityData) {
    return <Loader />;
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <OpportunitySubmissionHeader activeStep={currentStep} />
      <div className="opportunity-submission-flex">
        {renderStep()}
        <OpportunityInfo opportunityData={opportunityData} files={files} />
      </div>
    </div>
  );
}

export default SubmitOpportunity;
