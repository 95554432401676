import React, { useState } from 'react';
import './Sidebar.css';
import MenuIcon from '../../newsfeed/icons/MenuIcon';
import CloseIcon from '../../newsfeed/icons/CloseIcon';

const Sidebar = props => {
    const [className, setClass] = useState({
        class: "SidebarOlympus"
    })
    const classHandler = () => {
        if (className.class === 'SidebarOlympus') {
            setClass({class: 'SidebarOlympusShow'})
        } else{
            setClass({class: 'SidebarOlympus'})
        }
    }
    return (
        <div>
            <span className='sideBarToggle' onClick={classHandler}>
                {className.class === 'SidebarOlympus' ? <MenuIcon /> : <CloseIcon />}
            </span>
            <div className={className.class}>
                {props.children}
            </div>
        </div>
    )
}

export default Sidebar;