import React, {useEffect } from 'react';
import ShopifyBuy from '@shopify/buy-button-js';


const BuyButton = () => {
    const client = ShopifyBuy.buildClient({
        domain: 'startupfuelinc.myshopify.com',
        storefrontAccessToken: 'e22df30a04c33fab73aacf85701781ad',
    });
    const ui = ShopifyBuy.UI.init(client);  

    useEffect(() => {
      ui.createComponent('collection', {
        id: '445346840850', 
        node: document.getElementById('shopify-buy-button'),
        moneyFormat: '%24%7B%7Bamount%7D%7D',
        options: {
            "product": {
                "iframe": false,
                "styles": {
                  "product": {
                    "@media (min-width: 601px)": {
                      "max-width": "calc(25% - 20px)",
                      "margin-left": "20px",
                      "margin-bottom": "50px",
                      "width": "calc(25% - 20px)"
                    },
                    "img": {
                      "height": "calc(100% - 15px)",
                      "position": "absolute",
                      "left": "0",
                      "right": "0",
                      "top": "0"
                    },
                    "imgWrapper": {
                      "padding-top": "calc(75% + 15px)",
                      "position": "relative",
                      "height": "0"
                    }
                  },
                  "button": {
                    ":hover": {
                      "background-color": "#B0882E"
                    },
                    "background-color": "#C49733",
                    ":focus": {
                      "background-color": "#B0882E"
                    },
                    "padding-left": "31px",
                    "padding-right": "31px"
                  }
                },
                "buttonDestination": "checkout",
                "contents": {
                  "button": false,
                  "buttonWithQuantity": true
                },
                "text": {
                  "button": "Buy now"
                }
              },
              "productSet": {
                "styles": {
                  "products": {
                    "@media (min-width: 601px)": {
                      "margin-left": "-20px"
                    }
                  }
                }
              },
              "modalProduct": {
                "contents": {
                  "img": false,
                  "imgWithCarousel": true,
                  "button": false,
                  "buttonWithQuantity": true
                },
                "styles": {
                  "product": {
                    "@media (min-width: 601px)": {
                      "max-width": "100%",
                      "margin-left": "0px",
                      "margin-bottom": "0px"
                    }
                  },
                  "button": {
                    ":hover": {
                      "background-color": "#B0882E"
                    },
                    "background-color": "#C49733",
                    ":focus": {
                      "background-color": "#B0882E"
                    },
                    "padding-left": "31px",
                    "padding-right": "31px"
                  }
                },
                "text": {
                  "button": "Add to cart"
                }
              },
              "option": {},
              "cart": {
                "iframe": false,
                "styles": {
                  "button": {
                    ":hover": {
                      "background-color": "#B0882E"
                    },
                    "background-color": "#C49733",
                    ":focus": {
                      "background-color": "#B0882E"
                    }
                  }
                },
                "text": {
                  "total": "Subtotal",
                  "button": "Checkout"
                }
              },
              "toggle": {
                "styles": {
                  "toggle": {
                    "background-color": "#C49733",
                    ":hover": {
                      "background-color": "#B0882E"
                    },
                    ":focus": {
                      "background-color": "#B0882E"
                    }
                  }
                }
              }
        }
    })
    }, []);

    return (
        <div id="shopify-buy-button"></div>
    );
    
  };
  
  export default BuyButton;