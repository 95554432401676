/* (2019-06-10) - This page is actually a page of the PitchBox
It doesnt contain much just houses the components.
Tha functions to display the top applicants of the SIR based off of
the judges ratings and the other components can be found in
 /application_room/components/top_companies. The sidebar contains access
 to the other pages associate with the SIR.
*/
import React from "react";
import { AppContext } from "../App";
import PropTypes from "prop-types";
import axios from "axios";
import URL from "url-parse";

import SiteTitle from "./components/site_title.js";
import {
  Sign_In,
  Register,
  Forgot_Password,
  Alert_Message_To_User,
} from "./components/popups";
import Navigation from "./components/navbar_pb";
import Page from "./application_room/components/top_companies/page";
import SideBar from "./application_room/components/Sidebar";
import TopNav from "./application_room/components/topNav";

class Applicant_Room_Container extends React.Component {
  constructor(props) {
    super(props);
  }
  static contextType = AppContext;
  render() {
    return <Applicant_Room {...this.props} {...this.context} />;
  }
}

class Applicant_Room extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: new URL(window.location.href, true),
      judges: "",
      opportunity_owner: "",
      opportunity_data: {},
      show_page: true,
      UbaidSubClass: "",
    };
  }
  componentDidMount() {
    axios
      .get(
        `/fetch_opportunity?opportunity_id=${this.state.url.query.opportunity_id}`,
      )
      .then((response) => {
        this.setState({
          opportunity_data: response.data.result,
        });
      });
    axios
      .get(
        `fetch_judges_for_opportunity?opportunity_id=${this.state.url.query.opportunity_id}`,
      )
      .then((response) => {
        this.setState(
          {
            judges: response.data.judges,
            opportunity_owner: response.data.owner,
            access_ids: response.data.access_ids,
          },
          () => {
            setTimeout(() => {
              if (
                this.state.access_ids.includes(this.props.user._id) ||
                this.props.user.email === "trevor@pangeafirm.com" ||
                this.props.user.email === "ash@startupfuel.com"
              ) {
              } else {
                //this.props.history.push('/explore')
              }
            }, 1000);
          },
        );
      });
  }
  render() {
    return (
      <div className="__application-room-main">
        <Navigation opportunity_data={this.state.opportunity_data} />
        <SiteTitle />
        <Sign_In />
        <Register />
        <Forgot_Password />
        <Alert_Message_To_User
          show={this.props.showPopupMessage}
          handleChange={this.props.handleChange}
          dismiss={this.props.dismissModal}
          alert_message={this.props.alert_message}
          {...this.state}
        />
        <SideBar user={this.props.user} {...this.state} />
        <Page
          user={this.props.user}
          companies_owned={this.props.companies_owned}
          opportunity_name={this.state.opportunity_data.opportunity_name}
          {...this.state}
        />
      </div>
    );
  }
}

Applicant_Room_Container.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default Applicant_Room_Container;
