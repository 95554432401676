/* (2019-06-10) - This page is displayed to the user after they have registered
an account but do not have a company associated with their user. It allows them
to create a company which becomes affiliated with them.
*/
import React from "react";
import axios from "axios";
import "../main.css";
import {
  Button,
  Alert,
  Form,
  FormControl,
  FormLabel,
  Radio,
  Row,
} from "react-bootstrap";
import PropTypes from "prop-types";

import FormGroup from "@material-ui/core/FormGroup";
import { Helmet } from "react-helmet";
import URL from "url-parse";
import {
  company_type_list,
  industry_list,
  stage_list,
  business_type,
} from "../boilerplate/list.json";
import Navigation from "./components/navbar";
import Footer from "./components/footer_side";
import SiteTitle from "./components/site_title.js";
import {
  Sign_In,
  Register,
  Forgot_Password,
  Alert_Message_To_User,
} from "./components/popups";
import waterfall from "async/waterfall";
import { AppContext } from "../App";

import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";

import InputLabel from "@material-ui/core/InputLabel";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputBase from "@material-ui/core/InputBase";
import { withStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input";
import Chip from "@material-ui/core/Chip";
import Icon from "@material-ui/core/Icon";
import classNames from "classnames";
import TextField from "@material-ui/core/TextField";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing.unit * 3,
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    width: "auto",
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

const styles = (theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing.unit,
  },
  bootstrapFormLabel: {
    fontSize: 18,
  },
});

class Create_Company_Container extends React.Component {
  constructor(props) {
    super(props);
  }
  static contextType = AppContext;
  render() {
    return <Create_Company {...this.props} {...this.context} />;
  }
}

class Create_Company extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: new URL(window.location.href, true),
      email: "",
      password: "",
      first_name: "",
      last_name: "",
      company_name: "",
      company_type: "",
      street_number: "",
      short_bio: "",
      website: "",
      street_name: "",
      city: "",
      province: "Ontario",
      country: "Canada",
      industry: ["Fintech"],
      address: "",
      stage: 1,
      business_model: "B2B",
      formIsValids: false,
      errors: {},
      settings: {
        weeklyOpportunities: true,
        weeklyServices: true,
      },
      showButtonLoader: false,
    };
  }

  componentDidMount() {}

  handleChange = (e) => {
    const name = e.target.name;
    this.setState({
      [name]: e.target.value,
    });
  };

  handleChangeMultiple = (e) => {
    const name = e.target.name;
    let selected_values = [];
    Array.from(e.target.selectedOptions).forEach(function (item) {
      selected_values.push(item.value);
    });
    this.setState({
      [name]: selected_values,
    });
  };
  getStage = (value, array) => {
    for (let i = 0; i < array.length; i++) {
      if (array[i].value === value) {
        return array[i].text;
      }
    }
  };
  validateResult = (data, callback) => {
    if (data.status === "success" || data.status === "partial") {
      callback(data, this);
    } else if (data.status === "redirect") {
      this.props.history.push(data.redirect_to);
    } else if (data.status === "error") {
    }
  };
  handleValidation = (event) => {
    this.setState({
      showButtonLoader: !this.state.showButtonLoader,
    });
    let errors = {};
    let formIsValid = true;

    if (this.state.company_name.length === 0) {
      formIsValid = false;
      errors["company_name"] = " Cannot be empty";
    }
    this.setState(
      {
        errors: errors,
      },
      () => {
        if (formIsValid) {
          this.setState(
            {
              formIsValids: true,
            },
            () => {
              this.handleInputSubmit(event);
            }
          );
        }
      }
    );
  };
  handleInputSubmit = (e) => {
    e.preventDefault();
    if (this.state.formIsValids) {
      axios
        .post("/create_company", {
          company_name: this.state.company_name,
          company_type: this.state.company_type,
          street_number: this.state.street_number,
          short_bio: this.state.short_bio,
          website: this.state.website,
          street_name: this.state.street_number,
          address: this.state.address,
          city: this.state.city,
          province: this.state.province,
          country: this.state.country,
          industry: this.state.industry,
          stage: this.state.stage,
          business_model: this.state.business_model,
        })
        .then((result) => {
          this.props.history.push(
            `profile?company_id=${result.data.result._id}`
          );
        });
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <SiteTitle />
        <Navigation />
        <Sign_In />
        <Register />
        <div class="container">
          <div class="main">
            <div class="panel-heading">
              <div class="panel-title text-center">
                <h1 class="title">Company Registration page</h1>
                <h4>
                  Register a company to get the full benefits using from
                  StartupFuel
                </h4>
              </div>
            </div>
            <div class="main-login main-center form-horizontal mb-5">
              <h3 className="text-center">Company Info:</h3>
              <hr />
              <div className="form-gorup">
                <FormLabel className="control-label">Company Name</FormLabel>
                <span className="text-danger">
                  {this.state.errors["company_name"]}
                </span>
                <FormControl
                  type="text"
                  value={this.state.company_name}
                  placeholder="Company Name"
                  name="company_name"
                  onChange={this.handleChange}
                  className="form-control mb-3"
                />
              </div>
              <div className="form-gorup">
                <FormLabel className="control-label">Company Type</FormLabel>
                <br />
                <Select
                  value={this.state.company_type}
                  onChange={this.handleChange}
                  input={<Input id="select-multiple-chip" />}
                  name="company_type"
                  style={{ width: "100%" }}
                  className={classes.textField}
                  renderValue={(selected) => (
                    <div>
                      <MenuItem
                        key={this.state.company_type}
                        value={this.state.company_type}
                      >
                        {this.state.company_type}
                      </MenuItem>
                    </div>
                  )}
                  MenuProps={MenuProps}
                >
                  {company_type_list.map((name) => (
                    <MenuItem key={name} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <br />
              <FormLabel className="control-label">
                Short Bio (Optional)
              </FormLabel>
              <br />
              <TextField
                id="outlined-multiline-static"
                label="Please provide a short biography"
                multiline
                rows="4"
                className={classes.textField}
                margin="normal"
                variant="outlined"
                value={this.state.short_bio}
                name="short_bio"
                onChange={this.handleChange}
                style={{ width: "100%" }}
              />
              <br />
              <FormLabel className="control-label">
                Company Website (Optional)
              </FormLabel>
              <FormControl
                type="text"
                value={this.state.website}
                placeholder="www.yourwebsite.com"
                name="website"
                onChange={this.handleChange}
                className="form-control mb-3"
              />
              <FormLabel className="control-label">
                Company Headquarters
              </FormLabel>
              <br />
              <Icon
                className={classNames(
                  classes.iconHover,
                  "fas fa-map-marker-alt"
                )}
                color="error"
                style={{
                  fontSize: 30,
                  margin: "3px",
                  width: "50px",
                  paddingLeft: "15px",
                }}
              />
              <input
                type="text"
                style={{ width: "70%" }}
                name="address"
                value={this.state.address}
                onChange={this.handleChange}
              />
              <br />
              <br />
              <div className="form-gorup">
                <FormLabel className="control-label">Business Model</FormLabel>
                <br />
                <Select
                  value={this.state.business_model}
                  onChange={this.handleChange}
                  input={<Input id="select-multiple-chip" />}
                  name="business_model"
                  style={{ width: "100%" }}
                  className={classes.textField}
                  renderValue={(selected) => (
                    <div>
                      <MenuItem
                        key={this.state.business_model}
                        value={this.state.business_model}
                      >
                        {this.state.business_model}
                      </MenuItem>
                    </div>
                  )}
                  MenuProps={MenuProps}
                >
                  {business_type.map((name) => (
                    <MenuItem key={name} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <br />
              <InputLabel htmlFor="select-multiple-chip">Industries</InputLabel>
              <br />
              <Select
                multiple
                value={this.state.industry}
                onChange={this.handleChange}
                input={<Input id="select-multiple-chip" />}
                name="industry"
                style={{ width: "100%" }}
                className={classes.textField}
                renderValue={(selected) => (
                  <div className={classes.chips}>
                    {selected.map((value) => (
                      <Chip
                        key={value}
                        label={value}
                        className={classes.chip}
                      />
                    ))}
                  </div>
                )}
                MenuProps={MenuProps}
              >
                {industry_list.map((name) => (
                  <MenuItem key={name} value={name}>
                    <Checkbox
                      checked={this.state.industry.indexOf(name) > -1}
                    />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
              <div className="form-gorup">
                <br />
                <FormLabel className="control-label">Company Stage</FormLabel>
                <br />
                <Select
                  value={this.state.stage}
                  onChange={this.handleChange}
                  input={<Input id="select-multiple-chip" />}
                  name="stage"
                  style={{ width: "100%" }}
                  className={classes.textField}
                  renderValue={(selected) => (
                    <div>
                      <MenuItem key={this.state.stage} value={this.state.stage}>
                        {this.getStage(this.state.stage, stage_list)}
                      </MenuItem>
                    </div>
                  )}
                  MenuProps={MenuProps}
                >
                  {stage_list.map((name) => (
                    <MenuItem key={name.text} value={name.value}>
                      {name.text}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <Button
                type="submit"
                onClick={this.handleValidation}
                className="btn btn-primary btn-lg btn-block login-button mt-3"
              >
                Submit
              </Button>
              {Object.keys(this.state.errors).length >= 1 && (
                <p style={{ color: "red" }}>
                  Please fix the errors listed above
                </p>
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
Create_Company_Container.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(Create_Company_Container);
