/* (2019-06-10) - This page lists all the opportunities on the site, it also
contains components that allow you to query and find opportunities. It maps
through a list of the opportunities => passes them to a card. The sort and
filters are also used. The components can be found in /components. This
page contains the functions that put all of the pieces together.
*/
import React from "react";
import "../main.css";
import { AppContext } from "../App";
import Navigation from "./components/navbar";
import {
  Sign_In,
  Register,
  Forgot_Password,
  Alert_Message_To_User,
} from "./components/popups";
import SideMenu from "./SideMenu";
import "./opportunitiesUbaid.css";
import ArgonDashboard from "./components/dashboard/ArgonDashboard/ArgonDashboard";
import SiteTitle from "./components/site_title";

class Dashboard extends React.Component {
  render() {
    return (
      <React.Fragment>
        <SiteTitle
          title="StartupFuel - Funding"
          meta_description="Find relevant funding opportunities for your startup to apply to."
          meta_keywords="Funding, Angel groups, investment, VC, Venture Capital, Pitch, StartupFuel, Startup, Startups, Pitch, Investors, Accelerators, Startup Services"
        />
        <Navigation />
        <Sign_In />
        <Forgot_Password />
        <Alert_Message_To_User
          show={this.props.showPopupMessage}
          handleChange={this.props.handleChange}
          dismiss={this.props.dismissModal}
          alert_message={this.props.alert_message}
          {...this.state}
        />
        <Register />
        <SideMenu />
        <div className="ExpOppTooMainUbaid">
          <ArgonDashboard />
        </div>
      </React.Fragment>
    );
  }
}

export default Dashboard;
