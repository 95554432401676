import React from 'react';
import moment from 'moment';
import classnames from 'classnames';
import ProfileImage from '../../../components/profile_image';
import ThreeDotsIcon from '../icons/ThreeDotsIcon.js';
import SocialAPI from '../../../api/social_community';

class NetworkActivity extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      feed: [],
      loading: false
    };
  }

  componentDidMount() {
    this.setState({
      loading: true
    }, this.fetchFeed);
  }

  fetchFeed = () => {
    SocialAPI.get_notifications()
      .then((response) => {
        if (response.data.status === 'success') {
          this.setState({
            feed: response.data.items || [],
            loading: false
          });
        }
      })
      .catch((error) => {
        this.setState({
          loading: false
        });
      });
  }

  render() {
    return (
      <div className='SuggestFriendsNews SocialSideBarCard'>
        <div className='NewsCardTitle'>
          Notifications
        </div>
        <div className={ classnames('SocialLoadingIcon', {
                           'hide': !this.state.loading
                         }) }>
          <ThreeDotsIcon />
        </div>
        { this.state.feed.length == 0 && !this.state.loading && (
          <h6 style={ { textAlign: "center" } }><br />No Activity</h6>
          ) }
        { this.state.feed.map(function(item, i) {
            return (
              <a
                key={i}
                href={"/profile?company_id=" + item.from_company}
                className='SocialSideItem'>
                <ProfileImage
                  company_id={ item.from_company } />
                <div class="mainContent">
                    <div>{ item.content }</div>
                    <div class="small">{ moment(item.created_on).fromNow() }</div>
                </div>
              </a>
            )
          }) }
      </div>
    )
  }
}
;

export default NetworkActivity;
