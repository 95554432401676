import React from 'react';
import SocialProfile from './SocialProfile';
import NetworkActivity from '../RightBody/NetworkActivity';

const LeftBody = props => (
  <div className='LeftPartNews'>
    <SocialProfile />
    <NetworkActivity />
  </div>
)

export default LeftBody;
