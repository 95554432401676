/* (2019-06-11) - This is the main component of the team section of the SIR.
It handles all the functions for adding and editing judges. It also maps through
the judges and displays them.
*/
import React, { Component, Fragment } from "react";
import axios from "axios";
import URL from "url-parse";
import "./index.scss";
import StarRatings from "react-star-ratings";
import { ProgressBar, Row, Col } from "react-bootstrap";
import Comment from "../comment/Comment";
import Grid from "@material-ui/core/Grid";
import default_pic from "../../../../img/default_company_pic.png";
import default_background from "../../../../img/bg.png";

//import AragonBodyPart from "../../../components/dashboard/ArgonDashboard/AragonBody/AragonBodyPart/AragonBodyPart";
import AragonBodyPart from "./AragonBodyPart/AragonBodyPart";
//import Charts from "../../../components/dashboard/ArgonDashboard/AragonBody/Charts/Charts";
import Charts from "./Charts/Charts";

import {
  company_type_list,
  industry_list,
  stage_list,
  business_type,
  vertical_list,
} from "../../../../boilerplate/list.json";

import {
  Button,
  Alert,
  Form,
  FormControl,
  FormLabel,
  Radio,
} from "react-bootstrap";
import { individual_user_type_list } from "../../../../boilerplate/list.json";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import { AppContext } from "../../../../App";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import SortBy from "../../../components/explore_sort_filter";

import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import DirectionsIcon from "@material-ui/icons/Directions";
import Icon from "@material-ui/core/Icon";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";

import ArgNav from "../argNav";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);
const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  icon: {
    margin: theme.spacing.unit * 2,
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
  mobilePopup: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 9999,
  },
  mobilePopupContent: {
    background: "white",
    padding: "20px",
    borderRadius: "5px",
    textAlign: "center",
    width: "90%",
    maxWidth: "400px",
    maxHeight: "90vh",
    overflowY: "auto",
  },
  closeButton: {
    padding: "10px 20px",
    border: "none",
    backgroundColor: "#007bff",
    color: "white",
    borderRadius: "5px",
    cursor: "pointer",
    marginTop: "20px",
  },
});
class Page extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      url: new URL(window.location.href, true),
      companies: [],
      selected_company_id: "",
      showCreateJudge: false,
      selected_company: "",
      showOpenReview: false,
      email: "",
      password: "",
      first_name: "",
      last_name: "",
      sub_vertical: "",
      user_type: "Judge",
      success_msg: "Member has succesfully been added!",
      showSuccessMsg: false,
      selected_judge_id: "",
      judges_with_reviews: [],
      isMobile: false,
    };
  }

  componentDidMount() {
    this.fetchData();
    this.checkMobile();
    window.addEventListener("resize", this.checkMobile);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.checkMobile);
  }

  checkMobile = () => {
    this.setState({ isMobile: window.innerWidth <= 768 });
  };

  fetchData = () => {
    const { opportunity_id } = this.state.url.query;

    Promise.all([
      axios.get(
        `fetch_companies_opportunity_applied?opportunity_id=${opportunity_id}`,
      ),
      axios.get(`fetch_opportunity?opportunity_id=${opportunity_id}`),
    ])
      .then(([companiesResponse, opportunityResponse]) => {
        this.setState({
          companies: companiesResponse.data.full_list,
          ...opportunityResponse.data.result,
        });
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  getAvatarUrl = (array, file_type) => {
    let new_arr = array.filter((file) => {
      return file.file_type === file_type;
    });
    if (file_type === "company avatar") {
      if (new_arr.length !== 0) {
        return new_arr[0].file_url;
      } else {
        return default_pic;
      }
    }
    if (file_type === "company background") {
      if (new_arr.length !== 0) {
        return new_arr[0].file_url;
      } else {
        return default_background;
      }
    }
  };
  getStage = (value, array) => {
    for (let i = 0; i < array.length; i++) {
      if (array[i].value === value) {
        return array[i].text;
      }
    }
  };

  handleToggle = (e) => {
    e.preventDefault();
    this.setState({
      showCreateJudge: !this.state.showCreateJudge,
    });
  };
  handleChange = (e) => {
    const name = e.target.name;
    this.setState({
      [name]: e.target.value,
      showSuccessMsg: false,
    });
  };
  validateResult = (data, callback) => {
    if (data.status === "success" || data.status === "partial") {
      callback(data, this);
    } else if (data.status === "redirect") {
      this.props.history.push(data.redirect_to);
    } else if (data.status === "error") {
    }
  };

  handleInputSubmit = (e) => {
    e.preventDefault();

    axios
      .post("/create_user_judge", this.state)
      .then((response) => {
        this.props.persistUser(response.data.data);
        axios
          .post("/create_judge_opportunity_affiliation", {
            user: response.data.data._id,
            opportunity: this.state.url.query.opportunity_id,
          })
          .then((response) => {});
      })
      .catch((error) => {
        console.log(error);
      });
  };

  showEditJudge = (judge_id) => {
    if (judge_id !== this.state.selected_judge_id) {
      this.setState({
        selected_judge_id: judge_id,
      });
    } else {
      this.setState({
        selected_judge_id: "",
      });
    }
  };
  handleEditJudge = (e, judge_id) => {
    e.preventDefault();
    axios
      .post(`/edit_judge_for_opportunity`, {
        judge_id: judge_id,
        sub_vertical: this.state.sub_vertical,
      })
      .then((response) => {
        this.setState({
          selected_judge_id: "",
        });
        this.props.refresh_judges();
      });
  };
  handleDeleteJudge = (e, judge_id) => {
    e.preventDefault();
    axios
      .post(
        `/delete_judge_from_opportunity?opportunity_id=${this.state.url.query.opportunity_id}`,
        {
          judge_id: judge_id,
        },
      )
      .then((response) => {
        this.props.refresh_judges();
      });
  };

  redirectToDesktopSite = () => {
    window.location.href = "https://startupfuel.com";
  };

  render() {
    const { classes } = this.props;
    const { isMobile, url, opportunity_name } = this.state;

    if (isMobile) {
      return (
        <div className={classes.mobilePopup}>
          <div className={classes.mobilePopupContent}>
            <p>
              This application is best viewed on desktop. Please switch to a
              desktop computer to start using DiligenceGPT.
            </p>
            <button
              className={classes.closeButton}
              onClick={this.redirectToDesktopSite}
            >
              Close
            </button>
          </div>
        </div>
      );
    }

    return (
      <div>
        <ArgNav
          opportunity_name={opportunity_name}
          opportunity_id={url.query.opportunity_id}
          pathname={url.pathname}
        />
        <AragonBodyPart />
        <Charts />
      </div>
    );
  }
}

Page.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Page);
