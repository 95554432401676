import React,{useState} from 'react';
import { Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import { logo } from "../../../../img/oldlogo.png";
import { imgtear } from "../../../../img/img-tearsheet-01.png";
import { AiFillLinkedin } from "react-icons/ai";
import {
    stage_list,
  } from "../../../../boilerplate/list.json";

function TearsheetPdf({tamplateData,dealInfoDetial}) {


    return (
        <Document>
           <Page>
            <View>
                <Text>this iss cc cjc</Text>
            </View>
           </Page>
        </Document>
    )
}

export default TearsheetPdf