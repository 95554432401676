import React from 'react';
import axios from 'axios'
import { FormControl, Container } from 'react-bootstrap';
import '../test_mj-dev/sass/sass-css/_privacy_policy.scss';
import Navbar from './components/navbar';
import SideMenu from './SideMenu';
import { withStyles } from '@material-ui/core/styles';
import { AppContext } from '../App'
import PropTypes from 'prop-types';
import './projects-ubaid.scss';

import { Generic_Success_Message } from './components/popups'

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import ProjectNavBar from './components/project_nav_bar';
import VoluntaryPayment from './components/popups/voluntary_payment';
import RequestPayment from './components/popups/request_payment';
import LeaveAReview from './components/popups/leave_a_review';
import StripeSignup from './components/popups/stripe_signup';
import ViewAReview from './components/popups/view_a_review';
import StripeCheckoutBasic from './components/popups/stripe_checkout_basic';
import Moment from "moment";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);
const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  }, icon: {
    margin: theme.spacing.unit * 2,
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
});

class Page_Container extends React.Component {
  constructor(props) {
    super(props);
  }
  static contextType = AppContext;
  render() {
    return (<div>
      <Page {...this.props} {...this.context} />
    </div>
    )
  }
}
class Page extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      services: [],
      show_edit: [],
      edited_service: {},
      projects: [],
      pending_projects: [],
      active_projects: [],
      completed_projects: [],
      paused_projects: [],
      menuSelection: 0,

      selected_project: {},
      show_voluntary_payment: false,
      show_checkout: false,
      voluntary_payment_amount: 0,
      show_request_payment: false,
      show_leave_a_review: false,
      show_view_a_review: false,
      review_to_view: "",
      show_connect_to_stripe: false,
      show_success_msg: false,

    }
  }
  componentDidMount() {
    this.handleFetchProjects()
  }

  handleFetchProjects = () => {
    axios.get('/fetch_projects').then((response) => {
      this.setState({
        projects: response.data.result
      }, () => {
        this.setState({
          pending_projects: this.state.projects.filter((project) => project.status === "pending"),
          active_projects: this.state.projects.filter((project) => project.status === "active"),
          paused_projects: this.state.projects.filter((project) => project.status === "paused"),
          completed_projects: this.state.projects.filter((project) => project.status === "completed"),
        })
      })
    })

  }

  handleAccept = (e, project_id) => {
    e.preventDefault()
    if (this.state.projects.filter((project) => project._id === project_id)[0].employee._id === this.props.companies_owned[1][0]._id) {
      axios.post('/check_stripe_account').then((response) => {
        if (response.data.status === "stripe_connected") {
          axios.post('/accept_project', { project_id: project_id }).then((response) => {
            if (response.data.status === "success") {
              this.handleFetchProjects()
            }
          })
        } else {
          this.setState({
            show_connect_to_stripe: true
          })
        }
      })
    } else {
      axios.post('/accept_project', { project_id: project_id }).then((response) => {
        if (response.data.status === "success") {
          this.handleFetchProjects()
        }
      })
    }

  }

  handleDecline = (e, project_id) => {
    e.preventDefault()
    axios.post('/decline_project', { project_id: project_id }).then((response) => {
      if (response.data.status === "success") {
        this.handleFetchProjects()
      }
    })
  }

  handleCancel = (e, project_id) => {
    e.preventDefault()
    axios.post('/cancel_project', { project_id: project_id }).then((response) => {
      if (response.data.status === "success") {
        this.handleFetchProjects()
      }
    })
  }
  handlePause = (e, project_id) => {
    e.preventDefault()
    axios.post('/pause_project', { project_id: project_id }).then((response) => {
      if (response.data.status === "success") {
        this.handleFetchProjects()
      }
    })
  }
  handleComplete = (e, project_id) => {
    console.log("run")
    e.preventDefault()
    console.log("run2")
    axios.post('/complete_project', { project_id: project_id }).then((response) => {
      if (response.data.status === "success") {
        this.handleFetchProjects()
      }
    })
  }
  handleResume = (e, project_id) => {
    e.preventDefault()
    axios.post('/resume_project', { project_id: project_id }).then((response) => {
      if (response.data.status === "success") {
        this.handleFetchProjects()
      }
    })
  }

  handleMenuChange = (event, value) => {
    this.setState({
      menuSelection: value
    });

  };

  showVoluntaryPayment = (e, project_id) => {
    e.preventDefault()
    this.setState({
      selected_project: this.state.projects.filter((project) => project._id === project_id)[0]
    }, () => {
      this.setState({
        show_voluntary_payment: true
      })
    })

  }
  showRequestPayment = (e, project_id) => {
    e.preventDefault()
    this.setState({
      selected_project: this.state.projects.filter((project) => project._id === project_id)[0],
    }, () => {
      this.setState({
        show_request_payment: true,
        amount_owed: this.state.selected_project.amount_owed
      })
    })

  }
  handleChange = (e) => {
    e.preventDefault()
    this.setState({
      [e.target.name]: e.target.value
    })
  }


  dismissModal = (e) => {
    if (1===1) {
      console.log("trying to close", e.target)
    }

    (e.target.className === 'modal1' || e.target.className === 'cancelbtn' || e.target.className ==='fas fa-times') &&
      this.setState({
        show_voluntary_payment: false,
        show_request_payment: false,
        show_checkout: false,
        show_success_msg: false,
        show_leave_a_review: false,
        show_view_a_review: false


      })
  }

  handleAcceptPayment = () => {
    this.setState({
      show_voluntary_payment: false,
      show_checkout: true
    })

  }

  handleSuccessfulPayment = () => {
    axios.post('/handle_successful_payment_project', {
      project_id: this.state.selected_project._id,
      amount_payed: this.state.voluntary_payment_amount
    }).then((response) => {
      this.handleFetchProjects()
    })
    this.setState({
      success_msg: "Payment successfully sent!",
      show_success_msg: true,
      show_checkout: false
    })

  }

  handleRequestSubmit = () => {
    console.log("request running")
    this.setState({
      show_request_payment: false,
    })
    axios.post('/request_payment', {
      project_id: this.state.selected_project._id,
      amount_owed: this.state.amount_owed
    }).then((response) => {
      this.handleFetchProjects()
      this.setState({
        success_msg: "Payment request sent!",
        show_success_msg: true,
      })
    })

  }

  handleLeaveReview = (e, project_id) => {
    e.preventDefault()
    this.setState({
      selected_project: this.state.projects.filter((project) => project._id === project_id)[0],
    }, () => {
      this.setState({
        show_leave_a_review: true,
        reviewee: (this.state.selected_project.employer._id === this.props.companies_owned[1][0]._id ? this.state.selected_project.employer.company_name : this.state.selected_project.employee.company_name)
      })
    })
  }


  submit_review = (review) => {
    this.setState({
      show_leave_a_review: false,
    })
    axios.post('/leave_a_project_review', {
      project_id: this.state.selected_project._id,
      review: review,
      is_employer: this.state.selected_project.employer._id === this.props.companies_owned[1][0]._id,
      reviewer: this.props.companies_owned[1][0]._id,
      reviewee: (this.state.selected_project.employer._id === this.props.companies_owned[1][0]._id ? this.state.selected_project.employee._id : this.state.selected_project.employer._id)
    }).then((response) => {
      this.handleFetchProjects()
      this.setState({
        success_msg: "You have left a review!",
        show_success_msg: true,
      })
    })

  }

  showViewAReview = (e, project_id) => {
    e.preventDefault()
    axios.post('/view_a_review', {
      project_id: project_id,
      company: this.props.companies_owned[1][0]._id
    }).then((response) => {
      this.setState({
        review_to_view: response.data.result
      }, () => {
        this.setState({
          show_view_a_review: true
        })
      })
    })

  }

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Navbar />
        <SideMenu />
        <Generic_Success_Message
          show={this.state.show_success_msg}
          popup_header={this.state.success_msg}
          dismiss={this.dismissModal}
        />
        <LeaveAReview
          show={this.state.show_leave_a_review}
          dismissModal={this.dismissModal}
          reviewee={this.state.reviewee}
          submit_review={this.submit_review}
        />
        <StripeSignup
          show={this.state.show_connect_to_stripe}
          company_id={this.props.companies_owned !== null && this.props.companies_owned[1][0]._id}

        />
        <ViewAReview
          show={this.state.show_view_a_review}
          dismissModal={this.dismissModal}
          review={this.state.review_to_view}
        />
        <StripeCheckoutBasic
          service_provider={this.state.selected_project.employee !== undefined && this.state.selected_project.employee}
          title={this.state.selected_project.title}
          voluntary_payment_amount={this.state.voluntary_payment_amount}
          project_id={this.state.selected_project._id}
          show={this.state.show_checkout}
          handleSuccessfulPayment={this.handleSuccessfulPayment}
          dismissModal={this.dismissModal}
        />
        <VoluntaryPayment
          dismissModal={this.dismissModal}
          show={this.state.show_voluntary_payment}
          service_provider={this.state.selected_project.employee !== undefined && this.state.selected_project.employee.company_name}
          project_name={this.state.selected_project.title}
          handleChange={this.handleChange}
          voluntary_payment_amount={this.state.voluntary_payment_amount}
          handleAcceptPayment={this.handleAcceptPayment}
        />
        <RequestPayment
          dismissModal={this.dismissModal}
          show={this.state.show_request_payment}
          project_name={this.state.selected_project.title}
          handleChange={this.handleChange}
          amount_owed={this.state.amount_owed}
          handleRequestSubmit={this.handleRequestSubmit}
        />
        <div className="App UbaidLeft70 __projects-page">
          <div className='__table-wrapper'>
            <ProjectNavBar
              menuSelection={this.state.menuSelection}
              companies_owned={this.props.companies_owned}
              handleMenuChange={this.handleMenuChange}
              company_type={this.props.company_type}
              pending_projects= {this.state.pending_projects}
              active_projects= {this.state.active_projects}
              completed_projects= {this.state.completed_projects}
              paused_projects= {this.state.paused_projects}
            />
            {/*Pending*/}
            {this.state.menuSelection === 0 && (
              this.state.pending_projects.length > 0 ? this.state.pending_projects.map((project, index) => (
                <Project project={project} key={index} showOutstanding={false}>
                  <button onClick={(event) => this.handleAccept(event, project._id)}>Accept</button>
                  <button className='__red' onClick={(event) => this.handleDecline(event, project._id)}>Decline</button>
                </Project>
              ))
                : <h3>No projects to show.</h3>
            )}
            {/*Active*/}
            {this.state.menuSelection === 1 &&
              (this.state.active_projects.length > 0 ? this.state.active_projects.map((project, index) => (
                <Project project={project} key={index}>
                  <button className='__orange' onClick={(event) => this.handlePause(event, project._id)}>Pause</button>
                  <button className='__blue' onClick={(event) => this.handleComplete(event, project._id)}>Complete</button>
                  {project.amount_owed !== 0 ? (
                    this.props.companies_owned !== null &&
                      project.employer._id === this.props.companies_owned[1][0]._id
                      ? <button onClick={(event) => this.showVoluntaryPayment(event, project._id)}>Pay</button>
                      : <button onClick={(event) => this.showRequestPayment(event, project._id)}>Modify Payment Outstanding</button>
                  ) :
                    (this.props.companies_owned !== null && project.employer._id === this.props.companies_owned[1][0]._id ? <button onClick={(event) => this.showVoluntaryPayment(event, project._id)}>Pay</button> : <button onClick={(event) => this.showRequestPayment(event, project._id)}>Request Payment</button>)
                  }
                </Project>

              )) : <h4>No Projects to Show!</h4>)
            }

            {/*Paused*/}
            {this.state.menuSelection === 2 && (
              this.state.paused_projects.length > 0 ? this.state.paused_projects.map((project, index) => (
                <Project key={index} project={project}>
                  <button onClick={(event) => this.handleResume(event, project._id)} className='__darkgrey __mr-1' >Resume</button>
                  <button onClick={(event) => this.handleComplete(event, project._id)}>Complete</button>
                </Project>
              )
              ) : <h4>No Projects to Show!</h4>)}

            {/*Completed*/}
            {this.state.menuSelection === 3 && (
              this.state.completed_projects.length > 0 ? this.state.completed_projects.map((project, index) => (
                <Project project={project} key={index} showEndDate={true}>
                  {(project.employer_reviewed && project.employee_reviewed) ?
                    <button onClick={(event) => this.showViewAReview(event, project._id)}>View Review</button>
                    :
                    <>
                      {this.props.companies_owned !== null && project.employer._id === this.props.companies_owned[1][0]._id ?
                        <>
                          {project.employer_reviewed ?
                            <h5>Review Submitted!</h5>
                            :
                            <button onClick={(event) => this.handleLeaveReview(event, project._id)}>Leave Review</button>
                          }
                        </>
                        :
                        <>
                          {project.employee_reviewed ?
                            <h5>Review Submitted!</h5>
                            :
                            <button onClick={(event) => this.handleLeaveReview(event, project._id)}>Leave Review</button>
                          }
                        </>
                      }
                    </>
                  }
                </Project>
              )) : <h4>No Projects to Show!</h4>)}
          </div>
        </div>
      </React.Fragment >

    )

  }
}

const Project = ({ project, children, showEndDate = false, showOutstanding = true }) => {
  return (
    <div className='__card'>
      <div className='__left'>
      <div className='__title'>{project.title}</div>
        <div className='__description'>{project.description}</div>
      </div>
      <div className='__right'>
      <div className='__flex'>
        <i class="fas fa-user"></i>
        <span>Client: {project.employer.company_name}</span>
      </div>
      <div className='__flex'>
        <i class="fas fa-user-tie"></i>
        <span>Service Provider: {project.employee.company_name}</span>
      </div>
        <div className='__flex'>
          <i class="fas fa-hourglass-start"></i>
          <span>Start Date: {Moment(project.start_date).format('MMMM Do YYYY')}</span>
        </div>
        {showEndDate && (
          <div className='__flex'>
            <i class="fas fa-hourglass-end"></i>
            <span>End Date: {Moment(project.end_date).format('MMMM Do YYYY')}</span>
          </div>
        )}
        <div className='__flex'>
          <i className="fas fa-suitcase"></i>
          <span>Project type: {project.pricing_model !== "Single" ? "Hourly" : "Fixed"}</span>
        </div>
        <div className='__flex'>
          <i className="fas fa-file-invoice-dollar"></i>
          <span>Cost: ${project.price}{project.pricing_model !== "Single" && "/h"}</span>
        </div>
        <div className='__flex'>
          <i style={{color:"green"}} className="fas fa-dollar-sign"></i>
          <span>Spent: {project.paid_to_date !== 0 ? `$${project.paid_to_date}` : "None"}</span>
        </div>
        {showOutstanding && (
          <div className='__flex'>
            <i className="fas fa-hand-holding-usd"></i>
            <span>Payment Outstanding: {project.amount_owed !== 0 ? `$${project.amount_owed}` : " none"}</span>
          </div>
        )}

        <div className='__footer'>
          {children}
        </div>
      </div>
    </div>
  )
}


Page_Container.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Page_Container);
