import React, { Fragment, useRef, useEffect } from 'react';
import './MainBody.css';
import icon from '../assets/icon-fly-1-1.png';
import icon2 from '../assets/image1.png';
import image2 from '../assets/image2.png';
import image3 from '../assets/image3.png';
import client1 from '../assets/client1.png';
import client2 from '../assets/client2.png';
import client3 from '../assets/client3.png';
import client4 from '../assets/client4.png';
import client5 from '../assets/client5.png';
import client6 from '../assets/olympus_mobile_screen.png';
import CommunityReviews from './CommunityReviews';
import NewsLetter from './NewsLetter';
import LatestNews from './LatestNews';
import guy from '../assets/guy.png';
import rocket from '../assets/rocket1.png';
import Footer from './Footer';

const MainBody = props => {
    const floatingOlympus = useRef();
    useEffect(() => {
        window.addEventListener('scroll', () => {
            let x = window.scrollY + 500;
            if (x >= floatingOlympus.current.offsetTop) {
                floatingOlympus.current.className = 'RocketFloating';
            }
        });
    }, [])
    return (
        <Fragment>
            <div className='MainBodyOlympus'>
                <img src={icon} />
                <div className='detailsOlympus'>
                    <h2>Why Join <span className='OlympusOr'>Olympus Social Network</span>?</h2>
                    <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa.</p>
                </div>
            </div>
            <div className='MainBodyOlympus'>
                <div>
                    <h2>Meet New People <span className='OlympusOr'>all over the World</span></h2>
                    <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa.</p>
                </div>
                <img src={icon2} />
            </div>
            <div className='olypicsClients'>
                <div className='MainBodyOlympus'>
                    <img src={client1} alt='' />
                    <img src={client2} alt='' />
                    <img src={client3} alt='' />
                    <img src={client4} alt='' />
                    <img src={client5} alt='' />
                </div>
            </div>
            <div className='MainBodyOlympus'>
                <img src={image2} alt='' />
                <div className='detailsOlympus'>
                    <h2>The Best UI/UX and <span className='OlympusOr'>Awesome Features</span></h2>
                    <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa.</p>
                </div>
            </div>
            <div className='MainBodyOlympus'>
                <div className='detailsOlympus'>
                    <h2>Find People with <span className='OlympusOr'>Your Same Interests</span></h2>
                    <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa.</p>
                </div>
                <img src={image3} alt='' />
            </div>
            <CommunityReviews />
            <div className='MainBodyOlympus'>
                <img src={client6} alt='' style={{ margin: 'auto 0' }} />
                <div className='detailsOlympus'>
                    <h2>Fully Responsive <span className='OlympusOr'>Olympus Template!</span></h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                    <br />
                    <span className='list-item-loympus'><i className="fa fa-check-circle-o"></i>Build your profile in just minutes, it’s that simple!</span>
                    <span className='list-item-loympus'><i className="fa fa-check-circle-o"></i>Unlimited messaging with the best interface.</span>
                    <button className='OlympusWhiteBtn'><i className='fa fa-shopping-cart'></i>Purchase Now</button>
                    <button>More Details</button>
                </div>
            </div>
            <NewsLetter />
            <LatestNews />
            <div className='OlympicsHello'>
                <div className='MainBodyOlympus'>
                    <img src={guy} alt='' />
                    <div className='RocketOlympus'>
                        <img src={rocket} alt='' ref={floatingOlympus} />
                        <button>Start Making Friends Now</button>
                    </div>
                </div>
            </div>
            <Footer />
            <div className='lastFooterOlympus'>
                Site is built on <span>WordPress</span> by Crumina <span>Theme Development</span>
            </div>
        </Fragment>
    )
}

export default MainBody;