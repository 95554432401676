/* (2019-06-11) - This is the settings tab, as of right now it only allows you to
select whether or not weekly opportunities should be sent out or if you want
to delete your company.
*/
import React from "react";
import axios from "axios";
import update from "immutability-helper";
import { SocialIcon } from "react-social-icons";
import {
  Navbar,
  Nav,
  Row,
  Col,
  Card,
  Button,
  ButtonToolbar,
  Container,
  FormLabel,
  Form,
} from "react-bootstrap";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import "../../components/profile.css";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Checkbox from "@material-ui/core/Checkbox";

import { Helmet } from "react-helmet";
import URL from "url-parse";
import default_pic from "../../../img/default_company_pic.png";
import default_background from "../../../img/bg.png";
import rocket from "../../../img/rocketlaunch.jpg";
import StartupFuel from "../../../img/watermarksffinal.png";
import Loader from "../../components/loader";
import {
  Profile_Photo_Update,
  Cover_Photo_Update,
  Company_Update,
  Sign_In,
  Register,
} from "../../components/popups";
import {
  country_list,
  opportunity_type_list,
  industry_list,
  stage_list,
  business_type,
  company_type_list,
  goals,
} from "../../../boilerplate/lists";
import SiteTitle from "../../components/site_title.js";

import waterfall from "async/waterfall";

import { AppContext } from "../../../App";
import Navigation from "../../components/navbar";
import Footer from "./../../components/footer";
import CompanyNavBar from "./nav_bar";

import { Map, GoogleApiWrapper, InfoWindow, Marker } from "google-maps-react";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function getStyles(name, that) {
  return {
    fontWeight:
      that.state.name.indexOf(name) === -1
        ? that.props.theme.typography.fontWeightRegular
        : that.props.theme.typography.fontWeightMedium,
  };
}
const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: theme.spacing.unit / 4,
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 100,
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 100,
  },
});

class CustomizedTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: new URL(window.location.href, true),
      profile_views: [],
    };
  }
  handleAllowInvestor = (e) => {
    this.props.handleInvestorChange(e);
    this.props.handleAlertPopup();
  };

  componentDidMount() {
    axios
      .post("/fetch_profile_views", {
        company_that_was_viewed: this.state.url.query.company_id,
      })
      .then((response) => {
        this.setState({
          profile_views: response.data.results,
        });
      });
    if (this.props.userIsProfileOwner) {
      axios.post("/seen_profile_views", {
        company_that_was_viewed: this.state.url.query.company_id,
      });
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        {this.props.userIsProfileOwner && (
          <div>
            <div style={{ width: "100%" }}>
              <CompanyNavBar
                menuSelection={this.props.menuSelection}
                handleMenuChange={this.props.handleMenuChange}
                company_type={this.props.company_type}
                allow_investor={this.props.allow_investor}
                userIsProfileOwner={this.props.userIsProfileOwner}
              />
              <div
                style={{
                  backgroundColor: "unset",
                  margin: "auto",
                  width: "100%",
                  paddingBottom: "24px",
                  marginTop: "20px",
                }}
              >
                <h2 style={{ textAlign: "center" }}>Analytics </h2>

                <Grid
                  container
                  direction="row"
                  item
                  xs={12}
                  sm={9}
                  spacing={8}
                  style={{ maxWidth: "100%", padding: "20px" }}
                  className="mt-2"
                >
                  {this.state.profile_views.length > 0 ? (
                    <>
                      {this.state.profile_views.map((profile_view) => (
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={6}
                          xl={3}
                          justify="flex-start"
                          alignItems="flex-start"
                        >
                          <div
                            class="container-team"
                            style={{
                              width: "unset",
                              padding: "20px 0px",
                              margin: "0px 8px 78px 8px",
                            }}
                          >
                            <div class="avatar-flip">
                              <img
                                src={
                                  profile_view
                                    .company_image_that_did_the_viewing.length >
                                  0
                                    ? profile_view.company_image_that_did_the_viewing
                                    : default_pic
                                }
                                height="150"
                                width="150"
                              ></img>
                              <img
                                src={
                                  profile_view
                                    .company_image_that_did_the_viewing.length >
                                  0
                                    ? profile_view.company_image_that_did_the_viewing
                                    : default_pic
                                }
                                height="150"
                                width="150"
                              ></img>
                            </div>
                            <h3>
                              {profile_view.company_name_that_did_the_viewing}
                            </h3>

                            <h4>
                              {profile_view.company_type_that_did_the_viewing}
                            </h4>
                          </div>
                        </Grid>
                      ))}
                    </>
                  ) : (
                    <p style={{ textAlign: "center", width: "100%" }}>
                      No other registered companies have viewed your profile
                      yet.
                    </p>
                  )}
                </Grid>
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}
CustomizedTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CustomizedTable);
