/* (2019-06-11) - This is a file contains all of the lists used throughout the
site. If you want to change a list on the site, just change it here... usually.
*/
const distinctions_list =[
  {
    value:"female_founder",
    title:"Female Founder",
    description:"At least one of the founding team members is female.",
    img_url:"fas fa-female",
    color:"pink",
  },
  {
    value:"veteran",
    title:"Veteran Founder",
    description:"At least one of the founding team members is a veteran of the military.",
    img_url:"fas fa-fighter-jet",
    color:"#40cf58",
  },
  {
    value:"minority",
    title:"Visible Minority Founder",
    description:"One of the founding members would consider themselves a minority.",
    img_url:"fas fa-street-view",
    color:"#996633",
  },
  {
    value:"lgbtq",
    title:"LGBTQ+ Founder",
    description:"At least one of the founding team members would classify themselves as part of the LGBTQ+ community.",
    img_url:"fas fa-transgender-alt",
    color:"#99ccff",
  },
  {
    value:"disabled",
    title:"Founder with Disability ",
    description:"A founding member has a physical or mental disability.",
    img_url:"fas fa-wheelchair",
    color:"#ccffcc",
  },
  {
    value:"aboriginal",
    title:"Aboriginal Founder",
    description:"At least one of the founding team members would consider themselves as an aboriginal.",
    img_url:"fas fa-feather",
    color:"#b30000",
  },

]
module.exports = {
  distinctions_list
}
