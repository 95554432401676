import React from "react";
import { FormControl, FormLabel, Button, Form } from "react-bootstrap";
import PropTypes from "prop-types";

import {
  company_type_list,
  industry_list,
  stage_list,
  business_type,
  opportunity_type_list,
  country_list,
} from "../../../boilerplate/list.json";
import popupPic from "../../../img/rocket.png";
import developmentPic from "../../../img/development_icon.png";
import Grid from "@material-ui/core/Grid";
import { AppContext } from "../../../App";
import { withStyles } from "@material-ui/core/styles";
import axios from "axios";
import waterfall from "async/waterfall";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import FormGroup from "@material-ui/core/FormGroup";
import CardMedia from "@material-ui/core/CardMedia";
import Card from "@material-ui/core/Card";

import "./../profiles/picture.css";

const styles = (theme) => ({
  root: {
    backgroundColor: "#f5f5f5",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  media: {
    height: 140,
  },
  developmentMedia: {
    maxWidth: "200px",
    height: "200px",
    margin: "auto",
    marginTop: "20px",
    marginBottom: "20px",
  },
  p: {
    margin: 0,
  },
});

class CardTwo extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    expanded: false,
    showSharePopup: false,
  };

  render() {
    const { classes } = this.props;

    return (
      <div className="card-2-container">
        <Card className="create-opportunity-card">
          <div className="create-opportunity-heading">
            <p className="heading">Create Opportunity</p>
          </div>
          <div className="create-opportunity-message">
            <p className="message">
              Improve your funnel! This will be displayed to our global network
              of users in order to make them aware of opportunities.
            </p>
          </div>
          <div className="create-opportunity-form-container">
            <div className="form-row-1">
              <form action="" className="form">
                <div className="select-company-container">
                  <label htmlFor="select-company">Affiliated Company</label>
                  <span className="text-danger">
                    {this.props.errors["company"]}
                  </span>
                  <Form.Control
                    as="select"
                    placeholder="select"
                    name="affiliated_company"
                    onChange={this.props.handleChange}
                  >
                    <option value="select">select</option>
                    {this.props.company_list.map((co) => (
                      <option value={co._id}>{co.company_name}</option>
                    ))}
                  </Form.Control>
                </div>
                <div className="opportunity-type-container">
                  <label htmlFor="select-opportunity-type">
                    Opportunity Type
                  </label>
                  <Form.Control
                    as="select"
                    placeholder="event"
                    name="opportunity_type"
                    onChange={this.props.handleChange}
                  >
                    {opportunity_type_list.map((opp) => (
                      <option value={opp}>{opp}</option>
                    ))}
                  </Form.Control>
                </div>
              </form>
            </div>
            <div className="form-row-2">
              <form action="" className="form">
                <div className="opportunity-name-container">
                  <label htmlFor="opportunity-name">
                    Opportunity Name (Required)
                  </label>
                  <span className="text-danger">
                    {this.props.errors["name"]}
                  </span>
                  <Form.Control
                    type="text"
                    value={this.props.opportunity_name}
                    placeholder="Opportunity Name"
                    name="opportunity_name"
                    onChange={this.props.handleChange}
                    className="form-control mb-3"
                  />
                </div>
              </form>
            </div>
            <div className="form-row-3">
              <form action="" className="form">
                <div className="opportunity-description-container">
                  <label htmlFor="opportunity-description">
                    Opportunity Description
                  </label>
                  <textarea
                    type="text"
                    name="opportunity_description"
                    placeholder="Enter An opportunity Description"
                    onChange={this.props.handleChange}
                    value={this.props.opportunity_description}
                  ></textarea>
                </div>
              </form>
            </div>
            <div className="form-row-4">
              <form action="" className="form">
                <div className="website-url-container">
                  <label htmlFor="website-url">URL (Required)</label>
                  <span className="text-danger">
                    {this.props.errors["url"]}
                  </span>
                  <Form.Control
                    type="text"
                    value={this.props.website_url}
                    placeholder="Website Url"
                    name="website_url"
                    onChange={this.props.handleChange}
                    className="form-control mb-3"
                  />
                </div>
              </form>
            </div>
            <div className="form-row-5">
              <form action="" className="form">
                <div className="background-photo-container">
                  <label htmlFor="background-photo">
                    Background Photo (recommended size: 550x290)
                  </label>
                  <Form.Control
                    type="file"
                    accept=".gif,.jpg,.jpeg,.png"
                    name="file"
                    data-file-type="background"
                    onChange={this.props.handleFileUpload}
                    className="form-control mb-3"
                  />
                </div>
              </form>
            </div>
            <div className="form-row-6">
              <form action="" className="form">
                <div className="industry-container">
                  <div className="label-btn">
                    <label htmlFor="select-industry">
                      Industry (Select at least one)
                    </label>
                    <button
                      className="btn"
                      onClick={(event) => {
                        this.props.select_all
                          ? this.props.unselectAll(
                              event,
                              "target_industries",
                              industry_list
                            )
                          : this.props.selectAll(
                              event,
                              "target_industries",
                              industry_list
                            );
                      }}
                    >
                      Select All
                    </button>
                  </div>
                  <span className="text-danger">
                    {this.props.errors["industries"]}
                  </span>
                  <Form.Control
                    as="select"
                    name="target_industries"
                    multiple
                    placeholder="select"
                    onChange={this.props.handleChangeMultiple}
                  >
                    {industry_list.sort().map((industry) => (
                      <option
                        selected={
                          Array.isArray(this.props.target_industries) &&
                          this.props.target_industries.some(
                            (co_ind) => co_ind === industry
                          )
                        }
                        value={industry}
                      >
                        {industry}
                      </option>
                    ))}
                  </Form.Control>
                </div>
              </form>
            </div>
            <div className="form-row-6">
              <form action="" className="form">
                <div className="industry-container">
                  <div className="label-btn">
                    <label htmlFor="select-industry">
                      Targeted Company Stage(s) (Select at least one)
                    </label>
                    <button
                      className="btn"
                      onClick={(event) => {
                        this.props.select_all
                          ? this.props.unselectAll(
                              event,
                              "target_stage_companies",
                              stage_list
                            )
                          : this.props.selectAll(
                              event,
                              "target_stage_companies",
                              stage_list
                            );
                      }}
                    >
                      Select All
                    </button>
                  </div>
                  <span className="text-danger">
                    {this.props.errors["stage"]}
                  </span>
                  <Form.Control
                    as="select"
                    name="target_stage_companies"
                    multiple
                    placeholder="select"
                    onChange={this.props.handleChangeMultiple}
                  >
                    {stage_list.sort().map(
                      (stage) => (
                        <option
                          selected={
                            Array.isArray(this.props.target_stage_companies) &&
                            this.props.target_stage_companies.some(
                              (co_ind) => parseFloat(co_ind) === stage.value
                            )
                          }
                          value={stage.value}
                        >
                          {stage.text}
                        </option>
                      )
                      //<option value={stage.value}>{stage.text}</option>
                    )}
                  </Form.Control>
                </div>
              </form>
            </div>
            <div className="form-row-6">
              <form action="" className="form">
                <div className="industry-container">
                  <div className="label-btn">
                    <label htmlFor="select-industry">
                      Targeted Company Model(s) (Select at least one)
                    </label>
                    <button
                      className="btn"
                      onClick={(event) => {
                        this.props.select_all
                          ? this.props.unselectAll(
                              event,
                              "target_model_companies",
                              business_type
                            )
                          : this.props.selectAll(
                              event,
                              "target_model_companies",
                              business_type
                            );
                      }}
                    >
                      Select All
                    </button>
                  </div>
                  <span className="text-danger">
                    {this.props.errors["model"]}
                  </span>
                  <Form.Control
                    as="select"
                    name="target_model_companies"
                    multiple
                    placeholder="select"
                    onChange={this.props.handleChangeMultiple}
                  >
                    {business_type.sort().map((type) => (
                      <option
                        selected={
                          Array.isArray(this.props.target_model_companies) &&
                          this.props.target_model_companies.some(
                            (co_ind) => co_ind === type
                          )
                        }
                        value={type}
                      >
                        {type}
                      </option>
                    ))}
                  </Form.Control>
                  <br />
                </div>
              </form>
            </div>
            <div className="form-row-6">
              <form action="" className="form">
                <div className="industry-container">
                  <div className="label-btn">
                    <label htmlFor="select-industry">
                      Countries (Select at least one)
                    </label>
                  </div>
                  <span className="text-danger">
                    {this.props.errors["country"]}
                  </span>
                  <Form.Control
                    as="select"
                    name="geographical_location"
                    multiple
                    placeholder="select"
                    onChange={this.props.handleChangeMultiple}
                  >
                    {country_list.sort().map((country) => (
                      <option
                        selected={
                          Array.isArray(this.props.geographical_location) &&
                          this.props.geographical_location.some(
                            (co_ind) => co_ind === country
                          )
                        }
                        value={country}
                      >
                        {country}
                      </option>
                    ))}
                  </Form.Control>
                </div>
              </form>
            </div>
            <div className="form-row-7">
              <form action="" className="form">
                <div className="free-ticket-container">
                  <Checkbox
                    checked={this.props.expiration_date_checked}
                    onChange={(event) => {
                      this.props.handleCheck(event);
                    }}
                    value="expiration_date_checked"
                    name="expiration_date_checked"
                    color="primary"
                    style={{ flex: "unset" }}
                  />{" "}
                  <span>Would you like this opportunity to expire?</span>
                </div>
              </form>
            </div>
            {this.props.expiration_date_checked && (
              <div className="form-row-2">
                <form action="" className="form">
                  <div className="opportunity-name-container">
                    <label htmlFor="opportunity-name">Expiration Date</label>
                    <FormControl
                      type="datetime-local"
                      value={this.props.deadline}
                      placeholder="Deadline for people to apply"
                      name="deadline"
                      onChange={this.props.handleChange}
                      className="form-control mb-3"
                    />
                  </div>
                </form>
              </div>
            )}
            <div className="form-row-7">
              <form action="" className="form">
                <div className="free-ticket-container">
                  <Checkbox
                    checked={this.props.start_end_checked}
                    onChange={(event) => {
                      this.props.handleCheck(event);
                    }}
                    value="start_end_checked"
                    name="start_end_checked"
                    color="primary"
                    style={{ flex: "unset" }}
                  />{" "}
                  <span>
                    Would you like to have a start date and/or end date?
                  </span>
                </div>
              </form>
            </div>
            {this.props.start_end_checked && (
              <div className="form-row-2">
                <form action="" className="form">
                  <div className="opportunity-name-container">
                    <label htmlFor="opportunity-name">
                      Start Date (optional)
                    </label>
                    <FormControl
                      type="datetime-local"
                      value={this.props.start_date}
                      placeholder="What time does your opportunity start?"
                      name="start_date"
                      onChange={this.props.handleChange}
                      className="form-control mb-3"
                    />
                  </div>
                  <div className="opportunity-name-container">
                    <label htmlFor="opportunity-name">
                      End Date (Optional)
                    </label>
                    <FormControl
                      type="datetime-local"
                      value={this.props.end_date}
                      placeholder="What time does your opportunity start?"
                      name="end_date"
                      onChange={this.props.handleChange}
                      className="form-control mb-3"
                    />
                  </div>
                </form>
              </div>
            )}
            <div className="form-row-7">
              <form action="" className="form">
                <div className="free-ticket-container">
                  <Checkbox
                    checked={this.props.ticket_price_checked}
                    onChange={(event) => {
                      this.props.handleCheck(event);
                    }}
                    value="ticket_price_checked"
                    name="ticket_price_checked"
                    color="primary"
                    style={{ flex: "unset" }}
                  />
                  <span>Is There a Cost Associated with this Opportunity?</span>
                </div>
              </form>
            </div>
            {this.props.ticket_price_checked && (
              <div className="form-row-2">
                <form action="" className="form">
                  <div className="opportunity-name-container">
                    <label htmlFor="opportunity-name">Price</label>
                    <FormControl
                      type="number"
                      value={this.props.ticket_price}
                      placeholder="Please enter the cost for people applying"
                      name="ticket_price"
                      onChange={this.props.handleChange}
                      className="form-control mb-3"
                    />
                  </div>
                </form>
              </div>
            )}
            <div className="form-row-7">
              <form action="" className="form">
                <div className="free-ticket-container">
                  <Checkbox
                    checked={this.props.venue_checked}
                    onChange={(event) => {
                      this.props.handleCheck(event);
                    }}
                    value="venue_checked"
                    name="venue_checked"
                    color="primary"
                    style={{ flex: "unset" }}
                  />{" "}
                  <span>Is There a Venue for This Opportunity?</span>
                </div>
              </form>
            </div>
            {this.props.venue_checked && (
              <div className="form-row-2">
                <form action="" className="form">
                  <div className="opportunity-name-container">
                    <label htmlFor="opportunity-name">Venue Location</label>
                    <Form.Control
                      type="text"
                      value={this.props.venue_location}
                      placeholder="Enter your venue's location"
                      name="venue_location"
                      onChange={this.props.handleChange}
                      className="form-control mb-3"
                    />
                  </div>
                </form>
              </div>
            )}
            <div className="form-row-7">
              <form action="" className="form">
                <div className="free-ticket-container">
                  <Checkbox
                    checked={this.props.has_applicant_room}
                    onChange={(event) => {
                      this.props.handleCheck(event);
                    }}
                    value="has_applicant_room"
                    name="has_applicant_room"
                    color="primary"
                    style={{ flex: "unset" }}
                  />{" "}
                  <span>
                    Would you like to have an applicant room for this
                    opportunity?
                  </span>
                </div>
              </form>
            </div>
            <div className="apply-btn-container">
              <div className="btn-container">
                <button
                  onClick={this.props.handleValidation}
                  className="apply-btn"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </Card>
      </div>
    );
  }
}

CardTwo.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CardTwo);
