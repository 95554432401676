
import React, { Component, useState, useEffect } from 'react';
import './_M-serviceCard.scss';
import './M-serviceCardUbaidCustom.css'

import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';







const classes = {
  root: {
    width: '100%',
  },
  heading: {


    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {


  },
};









const ServiceCard = props => {

  const [expanded, setExpanded] = React.useState(false);




  const classes = props;




  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [state, changeState] = useState({

  })
  const StateHandler = (x) => {
    if (x.matches) {
      changeState({
        class1: '',
        class2: 'serviceCard__backcard-info serviceCard__pic__picture serviceCard__pic__picture--',
        class3: '',
        heading: 'Ubaid_Service_Card_Heading'
      })
    }
    else {
      changeState({
        class1: 'serviceCard__backcard-info serviceCard__pic__picture serviceCard__pic__picture--',
        class2: '',
        class3: "serviceCard__pic",
        heading: "serviceCard__pic__heading"
      })
    }
  }
  useEffect(() => {
    let x = window.matchMedia("(max-width: 769px)");
    StateHandler(x);
    x.addListener(StateHandler);
  }, [])
  return (
    <React.Fragment>
      <div className="serviceCard">
        <div className={state.class3}>
          <div className={state.class2 + 1} style={{ padding: 0 }}>
            <div className="serviceCard__pic__side serviceCard__pic__side--front">
              <div className={state.class1 + 1}>
              </div>
              <h4 className={state.heading + ' ubaidHeadingService'}><span className="serviceCard__pic__heading-span serviceCard__pic__heading-span--1">Technology</span></h4>
            </div>
            <div className="serviceCard__pic__side serviceCard__pic__side--back serviceCard__pic__side--back-1 ubaidServiceCard">
              <div className='serviceCard__backcard-info'>
                <h3>Technology Development</h3>
                <p>
                  the act of innovating and building software through coding languages to create solutions to problems.
              </p>
              </div>
            </div>
          </div>
        </div>
        <div className="serviceCard__info">
          <div className={classes.root}>
            <ExpansionPanel
              expanded={expanded === 'panel1'}
              onChange={handleChange('panel1')}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header">
                <Typography className={classes.heading}>
                  <h5>Mobile Development</h5>
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography>
                  <p>
                    We build fantastic native and hybrid apps. We help get your app on both the apple and google play store. We can also help you assess which development features are
                    needed at various levels in company growth, and which are not needed at the moment.
                  </p>
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel
              expanded={expanded === 'panel2'}
              onChange={handleChange('panel2')}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel2bh-header">
                <Typography className={classes.heading}>
                  <h5>Web Development</h5>
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography>
                  <p>
                    Want to create a blog, company website, or web application? Look no further. We make pristine, sophisticated websites that give you the web presence you deserve.
                  </p>
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel
              expanded={expanded === 'panel3'}
              onChange={handleChange('panel3')}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3bh-content"
                id="panel3bh-header">
                <Typography className={classes.heading}>
                  <h5>Blockchain Development</h5>
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography>
                  <p>
                    Want to make a secure back-end infrastructure involving blockchain, Dapp, or cryptocurrency? We have experience with erc 20, solidity contracts, hyperledger fabric
                    composer, and various other blockchain technologies.
                  </p>
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel
              expanded={expanded === 'panel4'}
              onChange={handleChange('panel4')}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4bh-content"
                id="panel4bh-header">
                <Typography className={classes.heading}>
                  <h5>UX UI Design</h5>
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography>
                  <p>
                    Design is of the utmost importance in the this day and age. Make sure that the users using your platform have the best possible experience. Have us design a beautiful
                    layout and road-map for your app today.
                  </p>
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </div>
          <button
            onClick={() => window.location.href = 'https://calendly.com/trevor-33'}
            className="servicesPage__button">
            CHAT WITH A TECHNOLOY REP
          </button>
        </div>
      </div>
      <div className="serviceCard">
        <div className={state.class3}>
          <div className={state.class2 + 2} style={{ padding: 0 }}>
            <div className="serviceCard__pic__side serviceCard__pic__side--front">
              <div className={state.class1 + 2}>
              </div>
              <h4 className={state.heading + ' ubaidHeadingService'}><span className="serviceCard__pic__heading-span serviceCard__pic__heading-span--1">Legal</span></h4>
            </div>
            <div className="serviceCard__pic__side serviceCard__pic__side--back serviceCard__pic__side--back-1 ubaidServiceCard">
              <div className="serviceCard__backcard-info">
                <h3>Legal Services</h3>
                <p>
                  legitimizing process, structure, or relationships through following guidelines outlined by authoritative bodies governing over set jurisdictions.
              </p>
              </div>
            </div>
          </div>
        </div>
        <div className="serviceCard__info">
          <div className={classes.root}>
            <ExpansionPanel
              expanded={expanded === 'panel15'}
              onChange={handleChange('panel15')}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel15bh-content"
                id="panel15bh-header">
                <Typography>
                  <h5>Incorporation</h5>
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography>
                  <p>
                    Incorporating a business means turning your sole proprietorship or partnership into a company formally recognized by your province of incorporation or federally.
                    Incorporate your company so it can become its own legal business structure.
                  </p>
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel
              expanded={expanded === 'panel16'}
              onChange={handleChange('panel16')}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel16bh-content"
                id="panel16bh-header">
                <Typography className={classes.heading}>
                  <h5>Shareholder Agreements</h5>
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography>
                  <p>
                    Shareholders’ agreements, is an arrangement among a company’s shareholders that describes how the company should be operated and outlines shareholders’ rights and
                    obligations. To protect the interests of your shareholders and prepare for investment, a shareholders’ agreement would be a good place to start if
                    there isn’t one already.
                  </p>
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel
              expanded={expanded === 'panel17'}
              onChange={handleChange('panel17')}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel17bh-content"
                id="panel17bh-header">
                <Typography className={classes.heading}>
                  <h5>Contracts</h5>
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography>
                  <p>
                    This is an agreement among two or more parties agree to perform or not perform a particular transaction. Whether its for employees, vendors, confidentiality or non-disclosure,
                    a contract serves as a useful way to help transactions becomes clearer so that everyone is on the same page.
                  </p>
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel
              expanded={expanded === 'panel18'}
              onChange={handleChange('panel18')}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel18bh-content"
                id="panel18bh-header">
                <Typography className={classes.heading}>
                  <h5>TermSheets</h5>
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography>
                  <p>
                    A term sheet is a nonbinding agreement setting forth the basic terms and conditions under which an investment will be made. A term sheet serves as a template to
                    develop more detailed legal documents. Once the parties involved reach an agreement on the details laid out in the term sheet, a binding agreement
                    or contract that conforms to the term sheet details, such as a shareholders’ agreement or an investment document, is then drawn up.
                  </p>
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </div>
          <a href="https://calendly.com/mrmartis">
            <button className="servicesPage__button"> CHAT WITH A LEGAL REP </button>
          </a>
        </div>
      </div>
      <div className="serviceCard">
        <div className={state.class3}>
          <div className={state.class2 + 3} style={{ padding: 0 }}>
            <div className="serviceCard__pic__side serviceCard__pic__side--front">
              <div className={state.class1 + 3}>
              </div>
              <h4 className={state.heading + ' ubaidHeadingService'}><span className="serviceCard__pic__heading-span serviceCard__pic__heading-span--1">Fundraising</span></h4>
            </div>
            <div className="serviceCard__pic__side serviceCard__pic__side--back serviceCard__pic__side--back-1 ubaidServiceCard">
              <div className="serviceCard__backcard-info">
                <h3>Fundraising Services</h3>
                <p>
                  refers to the act of obtaining capital from investors and venture capital sources for the purpose of growth, scale, and/or sustainability.
              </p>
              </div>
            </div>
          </div>
        </div>
        <div className="serviceCard__info">
          <div className={classes.root}>
            <ExpansionPanel
              expanded={expanded === 'panel19'}
              onChange={handleChange('panel19')}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel19bh-content"
                id="panel19bh-header">
                <Typography className={classes.heading}>
                  <h5>Investment Pitch Decks</h5>
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography>
                  <p>
                    We create professional pitch decks that win over investors and help you secure financing for your venture. Our team combines powerful and simplistic graphic design
                    with years of reviewing the highest quality content that gets startups funded.
                  </p>
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel
              expanded={expanded === 'panel20'}
              onChange={handleChange('panel20')}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel20bh-content"
                id="panel20bh-header">
                <Typography className={classes.heading}>
                  <h5>Fundability Assestment Score</h5>
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography>
                  <p>
                    We have designed a state of the art assessment tool that scores startups on their readiness to raise capital. The Fundability Assessment Score measures areas from
                    innovation, to team capabilities, to traction to determine the stage which startups are at and what needs to be done to improve.
                  </p>
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel
              expanded={expanded === 'panel21'}
              onChange={handleChange('panel21')}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel21bh-content"
                id="panel21bh-header">
                <Typography className={classes.heading}>
                  <h5>Unbiased 3rd Party Valuation</h5>
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography>
                  <p>
                    Our team of valuation experts will conduct a valuation assessment of your startup. We have built a unique financial model that combines models used by Venture Capital
                    investors around the world that assess pre and post revenue startups. Our 3rd party unbiased valuation gives startups an understanding of their value
                    which can be used in negotiations during the time of signing a term sheet with committed investors.
                  </p>
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel
              expanded={expanded === 'panel22'}
              onChange={handleChange('panel22')}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel22bh-content"
                id="panel22bh-header">
                <Typography className={classes.heading}>
                  <h5>Alternative Capital Consultation</h5>
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography>
                  <p>
                    We have identified various routes to raise capital for venture .You will learn when to raise capital and exactly how much to raise to ensure scale and sustainability
                    for your organization. There are many options from venture debt, factoring, government grants, competitions and many alternative financing options
                    that we will recommend based on each company uniquely.
                  </p>
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </div>
          <a href="https://calendly.com/mrmartis">
            <button className="servicesPage__button"> CHAT WITH A FUNDRAISING REP </button>
          </a>
        </div>
      </div>
      <div className="serviceCard">
        <div className={state.class3}>
          <div className={state.class2 + 4} style={{ padding: 0 }}>
            <div class="serviceCard__pic__side serviceCard__pic__side--front">
              <div className={state.class1 + 4}>
              </div>
              <h4 className={state.heading + ' ubaidHeadingService'}><span className="serviceCard__pic__heading-span serviceCard__pic__heading-span--1">Operations</span></h4>
            </div>
            <div class="serviceCard__pic__side serviceCard__pic__side--back serviceCard__pic__side--back-1 ubaidServiceCard">
              <div className="serviceCard__backcard-info">
                <h3>Operation Services</h3>
                <p>
                  the ratio between an output gained from the business and an input to run a business operation. When improving operational efficiency, the output to input ratio improves
              </p>
              </div>
            </div>
          </div>
        </div>
        <div className="serviceCard__info">
          <div className={classes.root}>
            <ExpansionPanel
              expanded={expanded === 'panel23'}
              onChange={handleChange('panel23')}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel23bh-content"
                id="panel23bh-header">
                <Typography className={classes.heading}>
                  <h5>Operational Efficiency</h5>
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography>
                  <p>
                    Mapping out processes internally can give your team and understanding of how efficient the process is from initial customer engagement to deal close to work being
                    completed. Optimizing this process results in lower costs and higher revenues.
                  </p>
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel
              expanded={expanded === 'panel24'}
              onChange={handleChange('panel24')}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel24bh-content"
                id="panel24bh-header">
                <Typography className={classes.heading}>
                  <h5>Project Management</h5>
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography>
                  <p>
                    Project management is an art as much as it is a skill. Having an expert establishing the roadmap and setting up the scope of execution in the future will increasing
                    efficiency tenfold. Our PM experts can help you with initial set up and ongoing support to coordinate the tasks of your business ensuring timely
                    execution.
                  </p>
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel
              expanded={expanded === 'panel25'}
              onChange={handleChange('panel25')}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel25bh-content"
                id="panel25bh-header">
                <Typography className={classes.heading}>
                  <h5>Operational Structure</h5>
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography>
                  <p>
                    After learning about your organization, our operations experts can suggest the best structure to set up the business in internally for maximum output. Roles and
                    responsibilities of each key member build a sense of ownership for everyone towards the core objective.
                  </p>
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel
              expanded={expanded === 'panel26'}
              onChange={handleChange('panel26')}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel26bh-content"
                id="panel26bh-header">
                <Typography className={classes.heading}>
                  <h5>Productivity Tools</h5>
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography>
                  <p>
                    Implementation and integration of Productivity tools for your business such as CRM, Enterprise Mail Accounts, Marketing tools, and many more.
                  </p>
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </div>
          <a href="https://calendly.com/mrmartis">
            <button className="servicesPage__button"> CHAT WITH A OPERATIONS REP </button>
          </a>
        </div>
      </div>
      <div className="serviceCard">
        <div className={state.class3}>
          <div className={state.class2 + 5} style={{ padding: 0 }}>
            <div className="serviceCard__pic__side serviceCard__pic__side--front">
              <div className={state.class1 + 5}>
              </div>
              <h4 className={state.heading + ' ubaidHeadingService'} ><span className="serviceCard__pic__heading-span serviceCard__pic__heading-span--1">Human Resources</span></h4>
            </div>
            <div className="serviceCard__pic__side serviceCard__pic__side--back serviceCard__pic__side--back-1 ubaidServiceCard">
              <div className="serviceCard__backcard-info">
                <h3>Human Resources</h3>
                <p>
                  the department or operations of a business or organization that deals with the hiring, administration, managing and training of personnel.
              </p>
              </div>
            </div>
          </div>
        </div>
        <div className="serviceCard__info">
          <div className={classes.root}>
            <ExpansionPanel
              expanded={expanded === 'panel27'}
              onChange={handleChange('panel27')}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel27bh-content"
                id="panel27bh-header">
                <Typography className={classes.heading}>
                  <h5>Recruitment & Headhunting</h5>
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography>
                  <p>
                    Differentiating your business in saturated industries is our forte. Brand your business to adapt with ever-changing technologies, times, and expectations. Our experts
                    will help you position your brand to stand out above the competition within your industry.
                  </p>
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel
              expanded={expanded === 'panel28'}
              onChange={handleChange('panel28')}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel28bh-content"
                id="panel28bh-header">
                <Typography className={classes.heading}>
                  <h5>Corporate Coaching & Training</h5>
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography>
                  <p>
                    Development of employees are an important part of the growth of an organization. Chat with our HR rep about our growing network of coaches and trainers that can
                    help your employees unlock their potential and break through mental barriers in the self growth.
                  </p>
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel
              expanded={expanded === 'panel29'}
              onChange={handleChange('panel29')}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel29bh-content"
                id="panel29bh-header">
                <Typography className={classes.heading}>
                  <h5>Payroll & Employee Management</h5>
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography>
                  <p>
                    Let our HR experts handle your employees from an administrative function so you have to deal with less paperwork and focus on the core business model. Our payroll
                    partners can help you set up and optimize your payroll for a low cost.
                  </p>
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel
              expanded={expanded === 'panel30'}
              onChange={handleChange('panel30')}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel30bh-content"
                id="panel30bh-header">
                <Typography className={classes.heading}>
                  <h5>Employee Benefits & Retention</h5>
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography>
                  <p>
                    In recent years, many startup and small business employee benefit providers have sprouted. By offering benefits, you give employees more of a reason to care about
                    your company and remain loyal. As a result, they should be willing to work harder, which can lead to greater productivity and higher quality.
                  </p>
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </div>
          <a href="https://calendly.com/mrmartis">
            <button className="servicesPage__button"> CHAT WITH A HUMAN RESOURCES REP </button>
          </a>
        </div>
      </div>
      <div className="serviceCard">
        <div className={state.class3}>
          <div className={state.class2 + 6} style={{ padding: 0 }}>
            <div class="serviceCard__pic__side serviceCard__pic__side--front">
              <div className={state.class1 + 6}>
              </div>
              <h4 className={state.heading + ' ubaidHeadingService'}><span class="serviceCard__pic__heading-span serviceCard__pic__heading-span--1">Marketing</span></h4>
            </div>
            <div className="serviceCard__pic__side serviceCard__pic__side--back serviceCard__pic__side--back-1 ubaidServiceCard">
              <div className="serviceCard__backcard-info">
                <h3>Marketing Services</h3>
                <p>
                  the action or business of promoting and selling products or services, including market research and advertising.
              </p>
              </div>
            </div>
          </div>
        </div>
        <div className="serviceCard__info">
          <div className={classes.root}>
            <ExpansionPanel
              expanded={expanded === 'panel31'}
              onChange={handleChange('panel31')}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel31bh-content"
                id="panel31bh-header">
                <Typography className={classes.heading}>
                  <h5>Branding</h5>
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography>
                  <p>
                    Differentiating your business in saturated industries is our forte. Brand your business to adapt with ever-changing technologies, times, and expectations. Our experts
                    will help you position your brand to stand out above the competition within your industry.
                  </p>
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel
              expanded={expanded === 'panel32'}
              onChange={handleChange('panel32')}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel32bh-content"
                id="panel32bh-header">
                <Typography className={classes.heading}>
                  <h5>Marketing</h5>
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography>
                  <p>
                    Building out a website is great, driving targeted traffic to your website through organic (non-paid) mediums is better. We can help you achieve the organic search
                    results using search engine optimization, and social media services.
                  </p>
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel
              expanded={expanded === 'panel33'}
              onChange={handleChange('panel33')}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel33bh-content"
                id="panel33bh-header">
                <Typography className={classes.heading}>
                  <h5>Advertising</h5>
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography>
                  <p>
                    Facebook, Instagram, Linked In and other social mediums are quickly becoming a “pay to play” environment. To show up more frequently you will have to advertise rather
                    just using organic strategies.
                  </p>
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel
              expanded={expanded === 'panel34'}
              onChange={handleChange('panel34')}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel34bh-content"
                id="panel34bh-header">
                <Typography className={classes.heading}>
                  <h5>Creative</h5>
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography>
                  <p>
                    The media revolution has begun and is taking over the internet. We can help your business portray the right image using pictures and videos that will best display
                    your line of business. Contact us to chat about our creative capabilities for your business.
                  </p>
                </Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </div>
          <a href="https://calendly.com/mrmartis">
            <button className="servicesPage__button"> CHAT WITH A MARKETING REP </button>
          </a>
        </div>
      </div>
    </React.Fragment>
  );

}





export default ServiceCard;
