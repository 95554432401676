import React, { useState } from "react";
import axios from "axios";
import styled from "styled-components";
import LoadingButton from "../../../components/loadingButton";

const NoteInput = styled.textarea`
  width: 100%;
  min-height: 100px;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 16px;
  font-size: 14px;
  resize: vertical;
`;

const Timeline = styled.div`
  margin-top: 24px;
`;

const Note = styled.div`
  background: #f5f5f5;
  padding: 16px;
  border-radius: 4px;
  margin-bottom: 16px;

  .note-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    font-size: 12px;
    color: #666;
  }

  .note-content {
    font-size: 14px;
  }
`;

export default function DealWallComponent({
  companyInfo,
  opportunity_data,
  updateCompanyData,
  userId,
}) {
  const [newNote, setNewNote] = useState("");
  const [loading, setLoading] = useState(false);

  const handleAddNote = async () => {
    if (!newNote.trim()) return;

    setLoading(true);
    try {
      const response = await axios.post("/add_deal_note", {
        opportunity_id: opportunity_data._id,
        company_id: companyInfo.applicant_company,
        note: newNote,
        created_by: userId,
      });

      if (response.data.status === "success") {
        updateCompanyData(
          companyInfo.applicant_company,
          response.data.application,
        );
        setNewNote("");
      }
    } catch (error) {
      console.error("Error adding note:", error);
    } finally {
      setLoading(false);
    }
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  return (
    <div>
      <div>
        <NoteInput
          value={newNote}
          onChange={(e) => setNewNote(e.target.value)}
          placeholder="Add a new note..."
        />
        <LoadingButton
          loading={loading}
          text="Add Note"
          onClick={handleAddNote}
          style={{
            background: "#2196f3",
            color: "white",
            border: "none",
            padding: "8px 16px",
            borderRadius: "4px",
            cursor: "pointer",
            fontSize: "14px",
          }}
        />
      </div>

      <Timeline>
        {companyInfo?.deal_notes
          ?.sort((a, b) => new Date(b.created_on) - new Date(a.created_on))
          .map((note, index) => (
            <Note key={index}>
              <div className="note-header">
                <span>Added by: {note.created_by_user}</span>
                <span>{formatDate(note.created_on)}</span>
              </div>
              <div className="note-content">{note.note}</div>
            </Note>
          ))}
      </Timeline>
    </div>
  );
}
