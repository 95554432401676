import React from 'react';
import './LoginPage.css'
import InputForm from './InputForm';
import CheckForm from './CheckForm';

const MainLoginPage = props => (
    <div className='olympusLogin'>
        <div className='OlympusLoginTitle'>Login to your Account</div>
        <form action='JavaScript:void(0)'>
            <InputForm type='text' label='Username' />
            <InputForm type='password' label='Your Password' />
            <div className='extraInfo'>
                <CheckForm label='Remember Me' />
                <a href='#'>Forgot my Password</a>
            </div>
            <button>Login</button>
        </form>
    </div>
)

export default MainLoginPage;