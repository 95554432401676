import React from 'react';
import './Nav.css';
import MagnifyingGlass from '../../newsfeed/icons/MagnifyingGlass';

const Nav = props => {
    
    return (
        <div className='olympusnav'>
            <label className='olymSearch'>
                <input placeholder='Search here people or pages...' />
                <MagnifyingGlass />
            </label>
        </div>
    )
}

export default Nav;