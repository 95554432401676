import React from 'react';
import './NavDropDown.scss';

const NavDropDown = props => (
    <div className='UbaidNavDropDown'>
        <i className="ubaid_carrot_icon_notification"></i>
        <div>
            <div className='notifyHeader'>
                <span>Notifications</span>
                <div>
                    <a href={`/profile?company_id=${props.children.props.company_id}&tab=3`}>SETTINGS</a>

                </div>
            </div>
            <div className='ubaid-drop-items'>
                {props.children}
            </div>
            {/*<a className='dropdownfooter' href='#'>Check all your Events</a>*/}
        </div>
    </div>
)

export default NavDropDown;
