import React from 'react';
import classnames from 'classnames';
import Moment from "moment";
import './CenterBody.css';
import post2 from '../Images/post__thumb1.jpg'
import SocialAPI from '../../live/api/social';
import PostHeader from './PostHeader';
import CameraIcon from '../icons/CameraIcon';
import Linkify from 'react-linkify';

class PostSingleComment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentlyLiked: this.props.item.currentUserLiked,
      totalLikes: this.props.item.totalLikes
    };
  }

  handleLikeToggle(e) {
    e.preventDefault();

    if (this.props.item.currentUserLiked) {
      this.handleUnlike().then(function() {
        this.props.handleUnlike(this.props.item._id)
      }.bind(this));
    } else {
      this.handleLike().then(function() {
        this.props.handleLike(this.props.item._id)
      }.bind(this));
    }
  }

  handleLike() {
    return SocialAPI.likeComment(this.props.item._id);
  }

  handleUnlike() {
    return SocialAPI.dislikeComment(this.props.item._id);
  }

  handleDelete(e) {
    SocialAPI.deleteComment(this.props.item._id).then(function() {
      this.props.handleDeleteRefresh();
    }.bind(this));
  }

  render() {
    return (
      <PostHeader
        company_id={this.props.item.author._id}
        child=''
        time={ Moment(this.props.item.created_on).fromNow() }
        name={ `${this.props.item.author.company_name}` }
        handleDelete={ this.handleDelete.bind(this) }
        handleLikeToggle={ this.handleLikeToggle.bind(this) }
        isPostOwner={ this.props.item.byCurrentUser }
        currentlyLiked={ this.props.item.currentUserLiked }
        likes={ this.props.item.totalLikes }>
          <Linkify>{ this.props.item.content } </Linkify>
      </PostHeader>
    )
  }
}

export default PostSingleComment;
