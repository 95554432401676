import React from 'react';

const MenuIcon = props => (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="41" height="32" viewBox="0 0 41 32" onClick={props.onClick}>
        <title>menu-icon</title>
        <path d="M4.571 0h-4.571v4.571h4.571v-4.571zM9.143 0v4.571h32v-4.571h-32zM13.714 13.714h-13.714v4.571h13.714v-4.571zM18.286 13.714v4.571h4.571v-4.571h-4.571zM27.429 18.286h13.714v-4.571h-13.714v4.571zM0 32h32v-4.569h-32v4.569zM36.571 32h4.571v-4.569h-4.571v4.569z"></path>
    </svg>
)

export default MenuIcon;

