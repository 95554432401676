import React from 'react';
import './CommunityReviews.css';
import team1 from '../assets/team1-100x100.jpg'
import team3 from '../assets/team3-100x100.jpg'
import team5 from '../assets/team5-100x100.jpg'

const CommunityReviews = props => (
    <div className='OlympusComunityReviews'>
        <div className='MainBodyOlympus'>
            <span className='OlympusOr'>SOCIAL NETWORK</span>
            <h2>Community Reviews</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore</p>
            <div className='OlympusReviewCards'>
                <div className='OlympusReviewCard'>
                    <div className='OlympusCardHeader'></div>
                    <div className='authorAvtar' style={{ background: 'url(' + team5 + ')' }}></div>
                    <h3>Amazing Community</h3>
                    <span>
                        <i className='fa fa-star'></i>
                        <i className='fa fa-star'></i>
                        <i className='fa fa-star'></i>
                        <i className='fa fa-star'></i>
                        <i className='fa fa-star'></i>
                    </span>
                    <p className='ReviewOlympus'>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis ostrudexercitation ullamco.</p>
                    <p className='footerOlypusReview'>
                        <b>Mathilda Brinker</b>
                        Los Angeles, CA
                    </p>
                </div>
                <div className='OlympusReviewCard' style={{ margin: '0 31px' }}>
                    <div className='OlympusCardHeader'></div>
                    <div className='authorAvtar' style={{ background: 'url(' + team1 + ')' }}></div>
                    <h3>This is the Best Social Network ever!</h3>
                    <span>
                        <i className='fa fa-star'></i>
                        <i className='fa fa-star'></i>
                        <i className='fa fa-star'></i>
                        <i className='fa fa-star'></i>
                        <i className='fa fa-star-o'></i>
                    </span>
                    <p className='ReviewOlympus'>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu nulla pariatur laborum. totam rem aperiam</p>
                    <p className='footerOlypusReview'>
                        <b>Mark Valentine</b>
                        Long Island, NY
                    </p>
                </div>
                <div className='OlympusReviewCard'>
                    <div className='OlympusCardHeader'></div>
                    <div className='authorAvtar' style={{ background: 'url(' + team3 + ')' }}></div>
                    <h3>Incredible Design!</h3>
                    <span>
                        <i className='fa fa-star'></i>
                        <i className='fa fa-star'></i>
                        <i className='fa fa-star'></i>
                        <i className='fa fa-star'></i>
                        <i className='fa fa-star'></i>
                    </span>
                    <p className='ReviewOlympus'>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae abillo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.</p>
                    <p className='footerOlypusReview'><b>Nicholas Grissom</b>San Francisco, CA</p>
                </div>
            </div>
        </div>
    </div>
)

export default CommunityReviews;