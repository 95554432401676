/* (2019-06-10) - This is the navbar used across the site, it also contains the
mobile version of the navbar (which are coded seperatly).
*/

import React from "react";
import axios from "axios";
import "./navbar.css";
import { Nav } from "react-bootstrap";
import logo from "../../img/watermarksffinal.png";
import favicon from "../../img/SF.png";
import thunder from "../../img/thunder-icon.svg";

import default_profile_pic from "../../img/default-avatar.png";
import Sub_Nav from "./navbar_sub";
import { withContext } from "./popups";
import { AppContext } from "../../App";
import URL from "url-parse";
import "./navbarUbaid.css";
import { NavLink, Link } from "react-router-dom";
import NotificationIcon from "@material-ui/icons/Notifications";
import InboxIcon from "@material-ui/icons/Message";
import WorkIcon from "@material-ui/icons/Work";
import HomeIcon from '@material-ui/icons/Home';
import ExploreIcon from '@material-ui/icons/Explore';
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import NavDropDownItem from "./NavDropDownItem";
import NavDropDown from "./NavDropDown";
import SideMenu from "../SideMenu";
import NetworkActivity from "../../newsfeed/RightBody/NetworkActivitySite";
import SocialAPI from "../api/social";
import { handleToggle } from "../SideMenu";

class Navig extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: new URL(window.location.href, true),
      old_url: "",
      unread_notif_length: 0,
      unread_messages_length: 0,
      number_projects: 0,
    };
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps !== this.props &&
      this.props.isLoggedIn === true &&
      this.props.companies_owned !== undefined &&
      this.props.companies_owned !== null &&
      this.props.companies_owned[1] !== undefined &&
      this.props.companies_owned[1][0] !== undefined
    ) {
      localStorage.setItem("com_id", this.props.companies_owned[1][0]._id);
      this.getNotifsLength();
      this.getUnreadMessagesLength();
      this.get_number_of_projects_pending();
    }
  }

  getNotifsLength = () => {
    SocialAPI.get_notifications_length(
      this.props.companies_owned[1][0]._id
    ).then((response) => {
      this.setState({
        unread_notif_length: response.data.result,
      });
    });
  };

  getUnreadMessagesLength = () => {
    axios.get("/get_number_of_unread_convos").then((response) => {
      if (response.data.status === "success") {
        this.setState({
          unread_messages_length: response.data.result,
        });
      }
    });
  };

  get_number_of_projects_pending = () => {
    axios
      .get(
        `/get_number_of_projects_pending?comp_id=${this.props.companies_owned[1][0]._id}`
      )
      .then((response) => {
        this.setState({
          number_projects: response.data.result,
        });
      });
  };

  getAvatarUrl = (array) => {
    if (array?.length > 0) {
      let new_arr = array.filter((file) => {
        return file.file_type === "user avatar";
      });
      if (new_arr.length !== 0) {
        return new_arr[0].file_url;
      } else {
        return default_profile_pic;
      }
    } else {
      return default_profile_pic;
    }
  };

  getField = (array, field) => {
    if (Array.isArray(array) && array.length > 0) {
      if (array.length !== 0) {
        return array[0][field];
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  getcompanyUrl = (array) => {
    if (Array.isArray(array) && array.length > 0) {
      if (array.length !== 0) {
        return `/profile?company_id=${array[0]._id}`;
      } else {
        return "/";
      }
    } else {
      return "/";
    }
  };
  getGroupUrl = (array, page) => {
    if (Array.isArray(array) && array.length > 0) {
      if (array.length !== 0) {
        return `${page}?group_id=${array[0]._id}`;
      } else {
        return "/";
      }
    } else {
      return "/";
    }
  };

  handleToggle = () => {
    this.setState({
      show: !this.state.show,
    });
  }

  setDisplayToBlock() {
    const leftNavExpOppToo = document.querySelector('.leftNavExpOppToo');
    if (leftNavExpOppToo) {
      leftNavExpOppToo.style.display = 'block';
    }
  }

  render() {
    let dropDown = true;

    return (
      <React.Fragment>
        <Nav
          className="navbar navbar-dark bg-white sticky-top ubaidNav"
          justify="space-between"
          style={{ marginBottom: "0px !important"}}
        >
          <SideMenu />
          <a
            class="navbar-brand"
            style={{ marginRight: "unset" }}
            href={this.props.isLoggedIn ? "/explore" : "/home_page"}
          >
            {/* <img src={favicon} height="40" className="pr-2" alt="" /> */}
            <img src={logo} height="45" alt="" />
          </a>

          <ul className="nav m-0 img-drop custom-nav">
            <div
              id="google_translate_element"
              className="ubaidGoogleTranslator"
            >
              <span className="ubaidSetMargin"></span>
            </div>
            <div className="nav-list-item"></div>

            {this.props.isLoggedIn === true ? (
              <>
                {this.props.companies_owned !== undefined &&
                  this.props.companies_owned !== null &&
                  this.props.companies_owned[1] !== undefined &&
                  this.props.companies_owned[1][0] !== undefined && (
                    <div className="d-flex d-md-flex">
                      <BottomNavigation
                        showLabels
                        onChange={"handleChange"}
                        className="navbar_icon_container"
                      >
                        {/* <div className="navbar_label_icons"> */}
                          <BottomNavigationAction
                            value="1"
                            href="/newsfeed"
                            label="Home"
                            icon={
                              <>
                                <HomeIcon style={{ fontSize: "1.5rem" }} />
                              </> 
                            } 
                          /> 
                          <BottomNavigationAction
                          value="1"
                          href="/explore"
                          label="Explore"
                          icon={
                            <>
                              <ExploreIcon style={{ fontSize: "1.5rem" }} />
                            </>
                          }
                         />
                          <BottomNavigationAction
                            value="1"
                            label= "Funding"
                            href="/funding"
                            icon={
                              <>
                                <WorkIcon style={{ fontSize: "1.5rem" }} />{" "}
                                {this.state.number_projects > 0 && (
                                  <span className="navbarNotiicationNumber">
                                    {this.state.number_projects}
                                  </span>
                                )}{" "}
                              </>
                            } 
                          />

                          <BottomNavigationAction
                            value="1"
                            href="/inbox"
                            label="Messaging"
                            icon={
                              <>
                                <InboxIcon style={{ fontSize: "1.5rem" }} />{" "}
                                {this.state.unread_messages_length > 0 && (
                                  <span className="navbarNotiicationNumber">
                                    {this.state.unread_messages_length}
                                  </span>
                                )}{" "}
                              </>
                            }
                          />
                          {/* </div> */}

                        <div className="notif_icon">
                        <BottomNavigation
                        showLabels
                        onChange={"handleChange"}
                        className="navbar_notification_bar_container"
                      >
                          <BottomNavigationAction
                            value="0"
                            label="Notifications"
                            icon={
                              <>
                                <NotificationIcon
                                  style={{ fontSize: "1.5rem" }}
                                />{" "}
                                {this.state.unread_notif_length > 0 && (
                                  <span className="navbarNotiicationNumber">
                                    {this.state.unread_notif_length}
                                  </span>
                                )}{" "} 
                              </>
                            }
                          />
                          </BottomNavigation>
                          <NavDropDown>
                            <NetworkActivity
                              company_id={this.props.companies_owned[1][0]._id}
                              getNotifsLength={this.getNotifsLength}
                            />
                          </NavDropDown>
                        </div>
                      </BottomNavigation>
                    </div>
                  )}

                <li class="nav-item dropdown ubaidProfileIcon">
                  <div
                    onClick={() => {
                      dropDown === true
                        ? this.refs.ubaidDropDownMenu.setAttribute(
                            "class",
                            "ubaidDropDownMenu"
                          )
                        : this.refs.ubaidDropDownMenu.setAttribute(
                            "class",
                            "DropMenuDisplay"
                          );
                      dropDown = !dropDown;
                    }}
                  >
                    <div
                      className="ubaidProfileIconIMG"
                      onClick={() => {
                        // set
                      }
                    }
                      style={{
                        backgroundImage:
                          "url(" +
                          this.getAvatarUrl(this.props.user.files) +
                          ")",
                      }}
                    ></div>
                    <span className="triangle"></span>
                  </div>
                  <div
                    className="ubaidDropDownMenu DropMenuDisplay"
                    ref="ubaidDropDownMenu"
                    aria-labelledby="navbarDropdown"
                  >
                    <Link class="dropdown-item" to={"/user_profile"}>
                      {this.props.user.first_name} {this.props.user.last_name}
                    </Link>
                    <div class="dropdown-divider"></div>
                    {this.props.companies_owned !== undefined &&
                    this.props.companies_owned !== null &&
                    this.props.companies_owned[1] !== undefined &&
                    this.props.companies_owned[1][0] !== undefined ? (
                      <a
                        className="dropdown-item ubaidCompanyName"
                        id="ubaidCompanyName"
                        href={`/profile?company_id=${this.props.companies_owned[1][0]._id}`}
                      >
                        {this.props.companies_owned[1][0].company_name}
                      </a>
                    ) : (
                      <Link className="dropdown-item" to="/register">
                        Register a company
                      </Link>
                    )}
                    {this.props.opportunities_judging !== null &&
                      this.props.opportunities_judging.length > 0 && (
                        <div>
                          <div class="dropdown-divider"></div>
                          {this.props.opportunities_judging.map((opp) => (
                            <div>
                              {opp !== null && (
                                <>
                                  <Link
                                    className="dropdown-item"
                                    to={`/applicant_room?opportunity_id=${opp._id}`}
                                  >
                                    {opp.opportunity_name}
                                  </Link>
                                  <div class="dropdown-divider"></div>
                                </>
                              )}
                            </div>
                          ))}
                        </div>
                      )}
                    {this.props.companies_owned !== undefined &&
                      this.props.companies_owned !== null &&
                      this.props.companies_owned[1] !== undefined &&
                      this.props.companies_owned[1].opportunity !== undefined &&
                      this.props.companies_owned[1].opportunity[0] !==
                        undefined &&
                      this.props.companies_owned[1].opportunity[0]
                        .has_applicant_room === true && (
                        <div>
                          <div class="dropdown-divider"></div>
                          <Link
                            class="dropdown-item"
                            to={`/applicant_room?opportunity_id=${this.props.companies_owned[1].opportunity[0]._id}`}
                          >
                            {
                              this.props.companies_owned[1].opportunity[0]
                                .opportunity_name
                            }
                          </Link>
                        </div>
                      )}
                    <div class="dropdown-divider"></div>
                    <a
                      className="dropdown-item"
                      onClick={this.props.handleLogOut}
                      href="#"
                    >
                      Log Out
                    </a>
                  </div>
                </li>
              </>
            ) : (
              <div className="d-flex d-md-flex">
                <li className="nav-item" style={{ lineHeight: "2" }}>
                  <div className="nav-list-item">
                    <Link className="nav-link" to="/register">
                      Register
                    </Link>
                  </div>
                </li>
                <li className="nav-item" style={{ lineHeight: "2" }}>
                  <div className="nav-list-item">      
                      <Link className="nav-link" to="/sign_in">
                        Login
                      </Link>
                  </div>
                </li>
              </div>
            )}
          </ul>
        </Nav>
        <Sub_Nav />
      </React.Fragment>
    );
  }
}

export default withContext(Navig, AppContext);
