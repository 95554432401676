import React from 'react';
import moment from 'moment';
import classnames from 'classnames';
import ProfileImage from '../../live/components/profile_image';
import ThreeDotsIcon from '../icons/ThreeDotsIcon.js';
import SocialAPI from '../../live/api/social';

class NetworkActivity extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      feed: [],
      loading: false
    };
  }

  componentDidMount() {
    this.setState({
      loading: true
    }, this.fetchFeed);
  }
  toggleRead=(e,id)=>{
    e.preventDefault()
    SocialAPI.toggle_notification_read(id).then((response) => {
      if (response.data.status === 'success') {
        this.fetchFeed()
      }
    })
  }

  fetchFeed = () => {
    SocialAPI.get_notifications()
      .then((response) => {
        if (response.data.status === 'success') {
          this.setState({
            feed: response.data.items || [],
            loading: false
          });
        }
      })
      .catch((error) => {
        this.setState({
          loading: false
        });
      });
  }

  render() {
    return (
      <div className='SuggestFriendsNews SocialSideBarCard' style={{backgroundColor:"white"}}>
        <div className='NewsCardTitle'>
          Notifications
        </div>
        <div className={ classnames('SocialLoadingIcon', {
                           'hide': !this.state.loading
                         }) }>
          <ThreeDotsIcon />
        </div>
        { this.state.feed.length == 0 && !this.state.loading && (
          <h6 style={ { textAlign: "center" } }><br />No Activity</h6>
          ) }
        { this.state.feed.map((item, i)=> (
          <>
              <div
              style={item.unread?{backgroundColor:"#f6fbff", display:"flex"}:{backgroundColor:"white", display:"flex"}}>
              <a
                key={i}
                className='SocialSideItem'
                href={"/profile?company_id=" + item.from_company}
                style={{width:"95%"}}

                >

                <ProfileImage
                  company_id={ item.from_company } />
                <div class="mainContent">
                    <div>{ item.content }</div>
                    <div class="small">{ moment(item.created_on).fromNow() }</div>
                </div>
              </a>
              {item.unread&&
                <span onClick={(event)=>this.toggleRead(event,item._id)} class="dot"></span>
              }
              </div>
              </>
            ))}
      </div>
    )
  }
}
;

export default NetworkActivity;
