/* (2019-06-11) - The loader used for the explore page.
*/
import React from 'react';
import PropTypes from 'prop-types';
import flame from '../../img/startupfuel flame.png'
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 1,
    paddingBottom: theme.spacing.unit * 2,
  },
  button: {
    margin: theme.spacing.unit,
  },
  input: {
    display: 'none',
  },
  img: {
    width: '90%',
  }
});

function PaperSheet(props) {
  const { classes } = props;

  return (
    <Hidden smDown>
      <div className={classes.root + ' ubaidFooter'}>
        <Grid
          direction="column"
          justify="center"
          alignItems="center">
          <Paper
          style={{padding: 0}}
            className={classes.root}
            elevation={2}
            alignItems="center">
            <Grid
              container
              justify="center"
              alignItems="center">
              <img
                src={flame}
                className={classes.img} />
            </Grid>
            <Grid
              container
              justify="center"
              alignItems="center">
              <Typography
                variant="caption"
                gutterBottom
                align="center"
                className="my-3">
                COPYRIGHT STARTUPFUEL 2019
              </Typography>
            </Grid>
            <Grid
              container
              direction="row"
              justify="center"
              style={{padding: ' 0 0 12px 0'}}
              alignItems="center">
              <Button
                style={{ padding: 0, margin: '0 2px 0 2px' }}
                href="https://medium.com/@StartupFuel"
                target="_blank"
                size="small"
                className={classes.button}>
                Blog
              </Button>
              </Grid>
            <Grid
              container
              direction="row"
              justify="center"
              style={{padding: ' 0 0 12px 0'}}
              alignItems="center">
              <Button
                style={{ padding: 0, margin: '0 2px 0 2px' }}
                href="/terms_conditions"
                size="small"
                className={classes.button}>
                TERMS OF SERVICE
              </Button>
              |
              <Button
                style={{ padding: 0, margin: '0 2px 0 2px' }}
                href="/privacy_policy"
                size="small"
                className={classes.button}>
                PRIVACY POLICY
              </Button>
            </Grid>
          </Paper>
        </Grid>
      </div>
    </Hidden>
  );
}

PaperSheet.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PaperSheet);
