/* (2019-06-11) - The card that is displayed to represent the companies on
the explore page.
*/
import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import default_background from "../../img/bg.png";
import classnames from "classnames";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import CardActionArea from "@material-ui/core/CardActionArea";
import Typography from "@material-ui/core/Typography";
import red from "@material-ui/core/colors/red";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ShareIcon from "@material-ui/icons/Share";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Chip from "@material-ui/core/Chip";
import FaceIcon from "@material-ui/icons/Face";
import MessageIcon from "@material-ui/icons/Message";
import FolderIcon from "@material-ui/icons/Folder";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";
import Moment from "moment";
import {
  country_list,
  opportunity_type_list,
  industry_list,
  stage_list,
  business_type,
} from "../../boilerplate/list.json";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import { Explore_Share_Popup, Generic_Success_Message } from "./popups";
import Start_A_Conversation_Popup from "./popups/start_a_conversation";

const CustomTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#e0e0e0",
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const styles = (theme) => ({
  button: {
    margin: theme.spacing.unit,
  },
  input: {
    display: "none",
  },
  root: {
    marginBottom: theme.spacing.unit * 2,
  },
  header: {
    overflow: "hidden",
  },
  chip: {
    margin: theme.spacing.unit,
  },
  CardHeight: {
    height: 200,
    overflow: "hidden",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  actions: {
    display: "flex",
  },
  expand: {
    transform: "rotate(0deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
    marginLeft: "0",
    [theme.breakpoints.up("sm")]: {
      marginRight: -8,
    },
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  type: {
    fontSize: "1em",
    color: "red",
    textAlign: "center",
    fontWeight: 0,
  },
  footer: {
    display: "flex",
  },
  title: {
    fontSize: "1rem",
    overflow: "hidden",
    height: 25,
  },
  grow: {
    flexGrow: 1,
  },
});

function handleClick() {
  alert("You clicked the Chip.");
}

class RecipeReviewCard extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    expanded: false,
    showSharePopup: false,

    showConversationPopup: false,
    showCreateMessageSuccessPopup: false,
  };

  handleExpandClick = () => {
    this.setState((state) => ({ expanded: !state.expanded }));
  };
  handleSharePopup = (e) => {
    this.setState({
      showSharePopup: !this.state.showSharePopup,
    });
  };

  handleConversationPopup = (e) => {
    e.preventDefault();
    this.setState({
      showConversationPopup: !this.state.showConversationPopup,
    });
  };

  handleSuccessfulConversation = () => {
    this.setState({
      showConversationPopup: false,
      showCreateMessageSuccessPopup: true,
    });
  };
  dismissModal = (e) => {
    (e.target.className === "modal1" || e.target.className === "cancelbtn") &&
      this.setState({
        showCreateMessageSuccessPopup: false,
        showConversationPopup: false,
      });
  };

  getImage = (array, type) => {
    if (array?.length > 0) {
      let img_obj = array.filter((x) => x.file_name === type);
      if (img_obj.length > 0) {
        return img_obj[0].file_url;
      } else {
        return default_background;
      }
    } else {
      return default_background;
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <Card className={`${classes.Card} ${classes.grow} ${classes.root}`}>
        <Explore_Share_Popup
          {...this.state}
          {...this.props}
          handleChange={this.handleChange}
          handleSharePopup={this.handleSharePopup}
        />

        <Start_A_Conversation_Popup
          dismissModal={this.dismissModal}
          companies_owned={this.props.companies_owned}
          handleSuccessfulConversation={this.handleSuccessfulConversation}
          subject={`I am interested in ${this.props.product_name}`}
          showCreateMessagePopup={this.state.showConversationPopup}
          target_id={this.props.company._id}
          company_name={this.props.company.company_name}
          target_type={this.props.company.company_type[0]}
        />
        <Generic_Success_Message
          show={this.state.showCreateMessageSuccessPopup}
          popup_header="Message Successfully Sent!"
          dismiss={this.dismissModal}
        />
        {/* <CardHeader
        avatar={
          <Avatar aria-label="Recipe" src={this.getImage(this.props.product_company_files, "profile avatar")} className={classes.avatar} />
        }
          title={<a target="_blank" href={this.props.product_link}><Typography variant="h6" className={classes.title} gutterBottom>
            {this.props.product_name}
          </Typography></a>}
          subheader={`${this.props.type} ${this.props.category?this.props.category:""} Product`}
          className={classes.header}
          action={
            <CardActions disableSpacing style={{ paddingLeft: 0 }}>
              {(this.props.userIsProfileOwner && !this.props.inPreviewMode) &&
                <div>
                  <IconButton style={{ padding: '0px', marginRight: 0 }} >
                    <EditIcon className={classes.iconHover} onClick={(event) => this.props.handleEditProduct(event, this.props._id)} style={{ fontSize: '22px' }} color={this.props.flagged ? "error" : ""} />
                  </IconButton>
                  <IconButton onClick={(event) => this.props.handleDeleteProduct(event, this.props._id)} style={{ padding: '0px', marginRight: '-10px' }} aria-label="Share">
                    <DeleteIcon className={classes.iconHover} style={{ fontSize: '22px' }} color={this.props.flagged ? "error" : ""} />
                  </IconButton>
                </div>
              }
            </CardActions>
          }
        /> */}
        <div className="exploreCardHeader">
          <div
            style={{
              backgroundImage:
                "url(" +
                this.getImage(
                  this.props.product_company_files,
                  "profile avatar"
                ) +
                ")",
            }}
            className="exploreCardAvtar"
          ></div>
          <div className="exploreCardTitle">
            <a target="_blank" href={this.props.product_link}>
              {this.props.product_name}
            </a>
            {`${this.props.type} ${
              this.props.category ? this.props.category : ""
            } Product`}
          </div>
          {this.props.companies_owned !== undefined &&
            this.props.companies_owned !== null &&
            this.props.companies_owned[1] !== undefined &&
            this.props.companies_owned[1][0] !== undefined && (
              <CardActions
                disableSpacing
                style={{ paddingLeft: 0 }}
                className={this.state.follow_loading && "follow-loading"}
              >
                <IconButton onClick={this.handleConversationPopup} size="small">
                  <MessageIcon />
                </IconButton>
              </CardActions>
            )}
        </div>

        <CardActionArea target="_blank" href={this.props.product_link}>
          <CardMedia
            className={classes.media}
            image={this.getImage(
              this.props.product_company_files,
              "product avatar"
            )}
            title="background_picture"
          />
        </CardActionArea>

        <CardContent
          className="exploreCardContent OopSerToolCardContent"
          style={{ whiteSpace: "pre-line" }}
        >
          {this.props.product_description}
        </CardContent>

        <Grid className={classes.footer}>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-start"
            className="col-3"
          >
            <CardActions className={classes.actions}>
              {this.props.confirming_b2b ? (
                <IconButton
                  className={
                    classnames(classes.expand, {
                      [classes.expandOpen]: this.state.expanded,
                    }) + " OopSerToolCardFooter"
                  }
                  onClick={this.handleExpandClick}
                  aria-expanded={this.state.expanded}
                  aria-label="Show more"
                >
                  <ExpandMoreIcon />
                </IconButton>
              ) : (
                <div style={{ height: "40px" }}></div>
              )}
            </CardActions>
          </Grid>
          <Grid container direction="row" justify="center" alignItems="center">
            <div>
              <em>
                ${this.props.product_price}
                {this.props.payment_plan === "Monthly" && "/month"}
                {this.props.payment_plan === "Yearly" && "/year"}
              </em>
            </div>
          </Grid>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-end"
            className="col-4"
          >
            <Button
              variant="contained"
              color="primary"
              target="_blank"
              href={
                this.props.product_link.includes("http")
                  ? this.props.product_link
                  : `http://${this.props.product_link}`
              }
              target="_blank"
              className={classes.button + " OopSerToolCardFooterBtn"}
              size="small"
            >
              VIEW
            </Button>
          </Grid>
        </Grid>
        <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
          <CardContent>
            {this.props.applicable_user_types !== undefined &&
              this.props.applicable_user_types !== null && (
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <CustomTableCell className="text-center">
                        Targeted User Types
                      </CustomTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow style={{ height: "auto" }}>
                      <div style={{ margin: "7px 0", textAlign: "center" }}>
                        {this.props.applicable_user_types.map((val) => (
                          <Chip
                            size="small"
                            //icon={<FaceIcon />}
                            label={
                              val == "Artificial Intelligence" ? "A.I" : val
                            }
                            //onClick={handleClick}
                            className={classes.chip}
                          />
                        ))}
                      </div>
                    </TableRow>
                  </TableBody>
                </Table>
              )}
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <CustomTableCell className="text-center">
                    Company Industries
                  </CustomTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow style={{ height: "auto" }}>
                  <div style={{ margin: "7px 0", textAlign: "center" }}>
                    {this.props.applicable_industries.map((val) => (
                      <Chip
                        size="small"
                        //icon={<FaceIcon />}
                        label={val == "Artificial Intelligence" ? "A.I" : val}
                        //onClick={handleClick}
                        className={classes.chip}
                      />
                    ))}
                  </div>
                </TableRow>
              </TableBody>
            </Table>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <CustomTableCell className="text-center">
                    Company Stages
                  </CustomTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow style={{ height: "auto" }}>
                  <div style={{ margin: "7px 0", textAlign: "center" }}>
                    {this.props.applicable_business_stages.map((val) => (
                      <Chip
                        size="small"
                        //icon={<FaceIcon />}
                        label={this.props.getStage(parseFloat(val), stage_list)}
                        //onClick={handleClick}
                        className={classes.chip}
                      />
                    ))}
                  </div>
                </TableRow>
              </TableBody>
            </Table>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <CustomTableCell className="text-center">
                    Business Models
                  </CustomTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow style={{ height: "auto" }}>
                  <div style={{ margin: "7px 0", textAlign: "center" }}>
                    {this.props.applicable_business_models.map((val) => (
                      <Chip
                        size="small"
                        //icon={<FaceIcon />}
                        label={val == "Artificial Intelligence" ? "A.I" : val}
                        //onClick={handleClick}
                        className={classes.chip}
                      />
                    ))}
                  </div>
                </TableRow>
              </TableBody>
            </Table>
            {/* <Grid container direction="row" justify="space-between" >
              <Grid container direction="column" xs={5} sm={5} alignItems="center">
                <Typography variant="caption" gutterBottom>
                Company Industries
              </Typography>
                {this.props.applicable_industries.slice(0, 3).map(val => (
                  <Chip
                    size="small"
                    //icon={<FaceIcon />}
                    label={val == 'Artificial Intelligence' ? "A.I" : val}
                    //onClick={handleClick}
                    className={classes.chip}
                  />
                ))}
              </Grid>
              <Grid container direction="column" xs={3} sm={3} alignItems="center">
                <Typography variant="caption" gutterBottom>
                Company Stages
              </Typography>
                {this.props.applicable_business_stages.slice(0, 3).map(val => (
                <Chip
                  size="small"
                  //icon={<FaceIcon />}
                  label={this.props.getStage(parseFloat(val), stage_list)}
                  //onClick={handleClick}
                  className={classes.chip}
                />
              ))}
              </Grid>
              <Grid container direction="column" xs={4} sm={4} alignItems="center">
                <Typography variant="caption" gutterBottom>
                 Business Models
              </Typography>
              {this.props.applicable_business_models.slice(0, 3).map(val => (
                <Chip
                  size="small"
                  //icon={<FaceIcon />}
                  label={val == 'Artificial Intelligence' ? "A.I" : val}
                  //onClick={handleClick}
                  className={classes.chip}
                />
              ))}
              </Grid>
            </Grid> */}
          </CardContent>
        </Collapse>
      </Card>
    );
  }
}

RecipeReviewCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RecipeReviewCard);
