import React, { useMemo, useState } from "react";
import ReactDOM from "react-dom";
import axios from 'axios'
import powered_by_stripe from '../../../img/powered_by_stripe.png'

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  CardElement,
  ElementsConsumer
} from "@stripe/react-stripe-js";

import useResponsiveFontSize from "./useResponsiveFontSize";
import Button from '@material-ui/core/Button'
import './stripe__ubaid.scss';
import { Select, MenuItem, FormControl } from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import {logEvent, Result, ErrorResult} from './stripe_util';
import './stripe.css';

const ELEMENT_OPTIONS = {
  style: {
    base: {
      fontSize: '18px',
      color: '#424770',
      letterSpacing: '0.025em',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#9e2146',
    },
  },
};



class SplitForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      postal: '',
      errorMessage: null,
      paymentMethod: null,
    };
  }


  handlePurchase = async (event) => {
    event.preventDefault();
    const {stripe, elements} = this.props;
    const {name, postal} = this.state;

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    // delete this.state.paymentMethod["id"]
    // delete this.state.paymentMethod["object"]
    // delete this.state.paymentMethod["created"]
    // delete this.state.paymentMethod["livemode"]
    console.log(this.props.clientSecret,this.state.paymentMethod)

    const result = await stripe.confirmCardPayment(`${this.props.clientSecret}`, {
      payment_method: this.state.paymentMethod.id
    });

    if (result.error) {
       // Show error to your customer (e.g., insufficient funds)
       console.log(result.error.message);
     } else {
       // The payment has been processed!
       if (result.paymentIntent.status === 'succeeded') {
         console.log("Payment Went through!")
         this.props.handleSuccessfulPayment()
         // Show a success message to your customer
         // There's a risk of the customer closing the window before callback
         // execution. Set up a webhook or plugin to listen for the
         // payment_intent.succeeded event that handles any business critical
         // post-payment actions.
       }
     }
  }
  handleCreatePaymentMethod = async (event) => {
    console.log(4)
    event.preventDefault();
    console.log(5)
    const {stripe, elements} = this.props;
    const {name, postal} = this.state;
    console.log(stripe,elements,name,postal)
    if (!stripe || !elements) {
      console.log("shouldnt be running")
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    const cardElement = elements.getElement(CardNumberElement);

    const payload = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        name,

      },
    });

    if (payload.error) {
      console.log('[error]', payload.error);
      this.setState({
        errorMessage: payload.error.message,
        paymentMethod: null,
      });
    } else {
      console.log('[PaymentMethod]', payload.paymentMethod);
      this.props.handleInputSubmit(event)
      this.setState({
        paymentMethod: payload.paymentMethod,
        errorMessage: null,
      });
    }


  };
  render() {
      const {stripe} = this.props;
      const {postal, name, paymentMethod, errorMessage} = this.state;

      return (
        <>
        {this.props.show?
          <div style={this.props.show ? { display: 'block', padding:"unset", top:"20px" } : { display: 'none' }} class="modal1 __ubaid__stripe__popup">

            <div class="__stripeModel animate">
              <div className='__card__header'>
                <div className='__flex'>
                  <b className='__author__name'>{this.props.service_provider.company_name}</b>
                  <div className='cancelbtn' onClick={this.props.dismissModal}><i onClick={this.props.dismissModal} className="fas fa-times"></i></div>
                </div>
                <div className='__small'>{this.props.title}</div>
                <h2 style={{color:"white"}}>${this.props.voluntary_payment_amount}</h2>
              </div>
              <form onSubmit={this.handlePurchase}>
            <label htmlFor="name">Full Name</label>
            <input
              id="name"
              required
              placeholder="Jenny Rosen"
              value={name}
              onChange={(event) => {
                this.setState({name: event.target.value});
              }}
            />
            <label htmlFor="cardNumber">Card Number</label>
            <CardNumberElement
              id="cardNumber"
              onBlur={logEvent('blur')}
              onChange={logEvent('change')}
              onFocus={logEvent('focus')}
              onReady={logEvent('ready')}
              options={ELEMENT_OPTIONS}
            />
            <label htmlFor="expiry">Card Expiration</label>
            <CardExpiryElement
              id="expiry"
              onBlur={logEvent('blur')}
              onChange={logEvent('change')}
              onFocus={logEvent('focus')}
              onReady={logEvent('ready')}
              options={ELEMENT_OPTIONS}
            />
            <label htmlFor="cvc">CVC</label>
            <CardCvcElement
              id="cvc"
              onBlur={logEvent('blur')}
              onChange={logEvent('change')}
              onFocus={logEvent('focus')}
              onReady={logEvent('ready')}
              options={ELEMENT_OPTIONS}
            />

            {errorMessage && <ErrorResult>{errorMessage}</ErrorResult>}
            {paymentMethod && (
              <Result>Got PaymentMethod: {paymentMethod.id}</Result>
            )}
            {this.props.clientSecret===null?
            <Button type="submit" onClick={this.handleCreatePaymentMethod} disabled={!stripe}>Pay</Button>
            :
            <Button type="submit" onClick={this.handlePurchase} disabled={!stripe}>Confirm Payment</Button>
            }
            <div style={{width:"100%", textAlign:"center", marginTop:"5px"}}><img  src={powered_by_stripe}></img></div>
          </form>
          </div>
        </div>
          :
          <>
          </>
        }

      </>
      );
    }
  }

  const InjectedCheckoutForm = (props) => (
    <ElementsConsumer>
      {({stripe, elements}) => (
        <SplitForm show={props.show} handleSuccessfulPayment={props.handleSuccessfulPayment} service_provider={props.service_provider} title={props.title} voluntary_payment_amount={props.voluntary_payment_amount}  handleInputSubmit={props.handleInputSubmit} clientSecret={props.clientSecret} stripe={stripe} elements={elements} service={props.service} dismissModal={props.dismissModal} />
      )}
    </ElementsConsumer>
  );

export class Checkout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formIsValids:false,
      subject:"",
      message:"",
      errors: {},
      clientSecret:null,
      updated:false


    }
  }
  componentDidUpdate(prevProps){
    if (prevProps!==this.props &&  prevProps.service_provider!==this.props.service_provider){
      axios.post('/fetch_company_stripe_acct', {comp:this.props.service_provider._id}).then((response)=>{
        console.log(response)
        this.setState({
          updated:true,
          stripePromise: loadStripe("pk_live_UMUZzZ99xMJh4Gj8qeqW1zbZ00vV5Qt4gk",
            {stripeAccount: response.data.result.stripe_id})
        })
      })
    }

  }
  handleInputSubmit = (e)=>{
    e.preventDefault()
    axios.post('/create_payment_intent_fixed',{payment_amount: this.props.voluntary_payment_amount,project_id:this.props.project_id,pay_to_company:this.props.service_provider._id}).then((response)=>{
      console.log(response.data.clientSecret)
        this.setState({
          clientSecret: response.data.clientSecret,
          // stripePromise: loadStripe("pk_live_UMUZzZ99xMJh4Gj8qeqW1zbZ00vV5Qt4gk",
          //   {stripeAccount: response.data.stripeAccount})
        })


    })
  }

  render (){
    return (
      <>
      {this.state.stripePromise!== undefined &&
        <Elements service={this.props.service} clientSecret={this.state.clientSecret} handleInputSubmit={this.handleInputSubmit} dismissModal={this.props.dismissModal} stripe={this.state.stripePromise}>
          <InjectedCheckoutForm handleSuccessfulPayment={this.props.handleSuccessfulPayment} show={this.props.show} dismissModal={this.props.dismissModal} handleInputSubmit={this.handleInputSubmit} clientSecret={this.state.clientSecret} stripe={this.state.stripePromise} service_provider={this.props.service_provider} title={this.props.title} voluntary_payment_amount={this.props.voluntary_payment_amount} />
        </Elements>
      }
      </>
    );

}

}


export default Checkout;
