/* (2019-06-11) - This is the main component of the team section of the SIR.
It handles all the functions for adding and editing judges. It also maps through
the judges and displays them.
*/
import React, { Component, Fragment } from "react";
import axios from "axios";
import URL from "url-parse";
import "./portal.scss";
import StarRatings from "react-star-ratings";
import { ProgressBar, Row, Col } from "react-bootstrap";
import Comment from "../comment/Comment";
import Grid from "@material-ui/core/Grid";
import { Card } from "@material-ui/core";
import default_pic from "../../../../img/default_company_pic.png";
import default_background from "../../../../img/bg.png";

import Filters from "../../../components/explore_filters";
import _uniqBy from "lodash/uniqBy";

import {
  company_type_list,
  industry_list,
  stage_list,
  business_type,
  vertical_list,
} from "../../../../boilerplate/list.json";

import {
  Button,
  Alert,
  Form,
  FormControl,
  FormLabel,
  Radio,
} from "react-bootstrap";
import { individual_user_type_list } from "../../../../boilerplate/list.json";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import { AppContext } from "../../../../App";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import SortBy from "../../../components/explore_sort_filter";

import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import DirectionsIcon from "@material-ui/icons/Directions";
import Icon from "@material-ui/core/Icon";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import classnames from "classnames";

import ArgNav from "../argNav";
import ThreeDotsIcon from "../../../../newsfeed/icons/ThreeDotsIcon";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  icon: {
    margin: theme.spacing.unit * 2,
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
});
class Page_Container extends React.Component {
  constructor(props) {
    super(props);
  }
  static contextType = AppContext;
  render() {
    return <Page {...this.props} {...this.context} />;
  }
}
class Page extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      url: new URL(window.location.href, true),
      company_list: [],
      pitchbox_company_list: [],
      query_search: "",
      filters: {},
      number_of_companies: 0,
      sortby: "active",
      loaded: false,
      noMoreContent: false,
    };
  }

  componentDidMount() {
    /*window.addEventListener(
      "scroll",
      this.handleInfiniteScroll.bind(this),
      true
    );*/
    this.setState(
      {
        fetchingNextPage: true,
      },
      this.fetchFeed
    );
    if (
      this.state.url.query.industry_tag !== undefined &&
      this.state.url.query.industry_tag !== null
    ) {
      this.setState(
        {
          filters: {
            ...this.state.filters,
            industry: [this.state.url.query.industry_tag],
          },
        },
        this.fetchFeed.bind(this, true)
      );
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleInfiniteScroll);
  }

  fetchFeed(reset) {
    var serialize = function (obj) {
      var str = [];
      for (var p in obj)
        if (obj.hasOwnProperty(p)) {
          str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
      return str.join("&");
    };
    if (this.state.showMap === true) {
      this.handleMapPopulate();
    }

    axios
      .get(
        `/fetch_explore_directory?${serialize({
          size: this.PAGE_LENGTH,
          count: reset ? 0 : this.state.company_list.length,
          search: this.state.query_search,
          filters: JSON.stringify(this.state.filters),
          sort: this.state.sortby,
        })}`
      )
      .then((response) => {
        console.log(response);
        if (response.data.status === "success") {
          var updated_list = reset
            ? response.data.result
            : this.state.company_list.concat(response.data.result);
          updated_list = _uniqBy(updated_list, (co) => {
            return co._id;
          });

          /*this.setState({
            number_of_companies: response.data.total,
            noMoreContent: response.data.result.length < this.PAGE_LENGTH,
            company_list: updated_list,
            loaded: true,
            fetchingNextPage: false,
          });*/

          axios.get(
            `fetch_submitted_companies_for_opportunity?opportunity_id=${this.state.url.query.opportunity_id}`
          )
          .then((result) => {
            console.log(result.data);        
            if (result.data.status !== "None") {
              console.log(result.data)
              //updated_list = updated_list.filter(item => result.data.full_company_list_with_flags.every(item2 => item2._id !== item._id))
              var a = updated_list.filter(x=>{
                return !result.data.full_company_list_with_flags.some(t=> t.applicant_company === x._id)
              })
              console.log("197");
              console.log(a);

              this.setState({
                pitchbox_company_list: result.data.full_company_list_with_flags,
                number_of_companies: response.data.total,
                noMoreContent: response.data.result.length < this.PAGE_LENGTH,
                company_list: a,
                loaded: true,
                fetchingNextPage: false,
              });
            }
          });
          
        }
      })
      .catch((error) => {
        this.setState({
          loaded: true,
          fetchingNextPage: false,
        });
      });
  }

  handleFilterChange = (e) => {
    let deleteKey = (object, key) => {
      delete object[key];
      return {
        ...object,
      };
    };
    const name = e.target.name;
    this.setState(
      {
        filters:
          e.target.value.length > 0
            ? {
                ...this.state.filters,
                [name]: e.target.value,
              }
            : deleteKey(this.state.filters, name),
      },
      this.fetchFeed.bind(this, true)
    );
  };

  handleSingleDelete = (data, key) => () => {
    let deleteKey = (object, key) => {
      delete object[key];
      return {
        ...object,
      };
    };
    let filtersx = this.state.filters[key];
    let chipToDelete = filtersx.indexOf(data);
    filtersx.splice(chipToDelete, 1);
    this.setState(
      {
        filters:
          filtersx.length > 0
            ? {
                ...this.state.filters,
                [key]: filtersx,
              }
            : deleteKey(this.state.filters, key),
      },
      this.fetchFeed.bind(this, true)
    );
  };

  resetFilters = () => {
    this.setState(
      {
        filters: {},
      },
      this.fetchFeed.bind(this, true)
    );
  };

  getStage = (value, array) => {
    for (let i = 0; i < array.length; i++) {
      if (array[i].value === value) {
        return array[i].text;
      }
    }
  };

  handleSearchInputChange = (e) => {
    this.setState(
      {
        query_search: e.target.value,
      },
      () => {
        setTimeout(this.fetchFeed.bind(this, true), 1000);
      }
    );
  };

  handleChange = (e) => {
    const name = e.target.name;
    this.setState(
      {
        [name]: e.target.value,
      },
      () => {
        if (name === "sortby") {
          this.fetchFeed(true);
        }
      }
    );
  };

  getStage = (value, array) => {
    for (let i = 0; i < array.length; i++) {
      if (array[i].value === value) {
        return array[i].text;
      }
    }
  };

  getAvatarUrl = (array, file_type, temp_background_url) => {
    let new_arr = array.filter((file) => {
      return file.file_type === file_type;
    });
    if (file_type === "company avatar") {
      if (new_arr.length !== 0) {
        return new_arr[0].file_url_optimized_50_50;
      } else {
        return default_pic;
      }
    }
    if (file_type === "company background") {
      if (new_arr.length !== 0) {
        return new_arr[0].file_url_optimized_500_300;
      } else {
        if (
          temp_background_url !== undefined &&
          temp_background_url !== null &&
          temp_background_url.length > 0
        ) {
          return temp_background_url;
        } else {
          return default_background;
        }
      }
    }
  };

  dismissRegisterModal = (e) => {
    this.setState({
      showRegisterPopup: false,
    });
  };

  showRegisterModal = (e) => {
    this.setState({
      showRegisterPopup: true,
    });
  };



  render() {
    const { classes } = this.props;
    return (
      <div>
        <ArgNav
          opportunity_name = {this.props.opportunity_name}
          opportunity_id = {this.state.url.query.opportunity_id}
          pathname={this.state.url.pathname} />
        <div className='__content'> 
          <div className="__explore-page">
            <div className="__header">
              <div className="__title">
                <h2>
                Explore
                </h2>
              </div>
            </div>
              
            <div className="__application-room-margin">
              <div className="ExpOppTooMainUbaid">
                <div className="filter_row">
                  <div className="UbaidRightFilter">
                    {/* <div> */}
                    <Filters
                      query_search={this.state.query_search}
                      handleFilterChange={this.handleFilterChange}
                      filters={this.state.filters}
                      handleSingleDelete={this.handleSingleDelete}
                      resetFilters={this.resetFilters}
                      getStage={this.getStage}
                      results={[]}
                      handleSearchInputChange={this.handleSearchInputChange}
                    />
                    {/* </div> */}
                  </div>
                  <div class="UbaidLeftOppExp">
                    <div className="row mb-3">
                      <div className="col">
                        <div className="card">
                          <div className="row">
                            <div className="col-sm-7 mt-2">
                              {this.state.number_of_companies !== undefined && (
                                <h5
                                  className="NumberOfPitchCompUBaid"
                                  style={{ lineHeight: "56px" }}
                                >
                                  {this.state.number_of_companies}{" "}
                                  {this.state.number_of_companies === 1
                                    ? "Company"
                                    : "Companies"}{" "}
                                </h5>
                              )}
                            </div>
                            {/*<div
                              className="col-sm-5 hidden-sm-down"
                              style={{ paddingRight: 0 }}
                            >
                              <SortBy
                                sortby={this.state.sortby}
                                handleChange={this.handleChange}
                                mapHandler={this.mapHandler}
                                MapOpen={this.state.showMap}
                                map_loading={this.state.map_loading}
                              />
                                  </div>*/}
                          </div>

                          {/*<div
                            className={
                              "ExploreMap " + (this.state.showMap ? "showMap" : "")
                            }
                          >
                            {this.state.showMap &&
                            this.state.mapped_companies.length > 0 ? (
                              <MapWithAMarkerClusterer
                                markers={this.state.mapped_companies}
                              />
                            ) : (
                              ""
                            )}
                            </div> */}
                        </div>
                      </div>
                    </div>
                    <div class="row ubaidOppCards">
                      {this.state.company_list.map((company, index) => (
                        <div className="ubaidExploreCards col-xl-3 col-lg-4 col-md-6 col-sm-12">
                         
                        </div>
                      ))}
                    </div>
                    
                    {this.state.loaded && this.state.noMoreContent && (
                      <h5 style={{ textAlign: "center" }}>
                        <br /> There is no more content to show at the moment <br />{" "}
                        <br />
                      </h5>
                    )}
                    {!this.state.noMoreContent && (
                      <div>
                        <div className="SocialLoadingIcon">
                          <ThreeDotsIcon />
                        </div>
                      </div>
                    )}
                    </div>
                  {/* <div className="right_column">
                    <h6>StartupFuel News</h6>
                    <Link to="/pitchbox">
                      <img style={{ width: "100%" }} alt="" src={Ad} />
                    </Link>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Page_Container.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Page_Container);

