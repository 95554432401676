/* (2019-06-11) - This is the settings tab, as of right now it only allows you to
select whether or not weekly opportunities should be sent out or if you want
to delete your company.
*/
import React from "react";
import axios from "axios";
import update from "immutability-helper";
import { SocialIcon } from "react-social-icons";
import {
  Navbar,
  Nav,
  Row,
  Col,
  Card,
  Button,
  ButtonToolbar,
  Container,
  FormLabel,
  Form,
} from "react-bootstrap";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import "../../components/profile.css";
import "../../components/stripe.css";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Checkbox from "@material-ui/core/Checkbox";

import { Helmet } from "react-helmet";
import URL from "url-parse";
import default_pic from "../../../img/default_company_pic.png";
import default_background from "../../../img/bg.png";
import rocket from "../../../img/rocketlaunch.jpg";
import StartupFuel from "../../../img/watermarksffinal.png";
import Loader from "../../components/loader";
import {
  Profile_Photo_Update,
  Cover_Photo_Update,
  Company_Update,
  Sign_In,
  Register,
} from "../../components/popups";
import {
  country_list,
  opportunity_type_list,
  industry_list,
  stage_list,
  business_type,
  company_type_list,
  goals,
} from "../../../boilerplate/list.json";
import SiteTitle from "../../components/site_title.js";

import waterfall from "async/waterfall";

import { AppContext } from "../../../App";
import Navigation from "../../components/navbar";
import Footer from "./../../components/footer";
import CompanyNavBar from "./nav_bar";

import { Map, GoogleApiWrapper, InfoWindow, Marker } from "google-maps-react";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function getStyles(name, that) {
  return {
    fontWeight:
      that.state.name.indexOf(name) === -1
        ? that.props.theme.typography.fontWeightRegular
        : that.props.theme.typography.fontWeightMedium,
  };
}
const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: theme.spacing.unit / 4,
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 100,
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 100,
  },
});

class CustomizedTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      csrfToken: "",
      url: new URL(window.location.href, true),
    };
  }
  handleAllowInvestor = (e) => {
    this.props.handleInvestorChange(e);
    this.props.handleAlertPopup();
  };
  componentDidMount() {
    console.log(this.props);
    axios.get("/csrf_token").then((response) => {
      console.log(response);
      this.setState({
        csrfToken: response.data.csrfToken,
      });
    });
  }
  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        {this.props.userIsProfileOwner && (
          <div>
            <div style={{ width: "100%" }}>
              <CompanyNavBar
                menuSelection={this.props.menuSelection}
                handleMenuChange={this.props.handleMenuChange}
                company_type={this.props.company_type}
                allow_investor={this.props.allow_investor}
                userIsProfileOwner={this.props.userIsProfileOwner}
              />
              <div
                style={{
                  backgroundColor: "unset",
                  margin: "auto",
                  width: "70%",
                  paddingBottom: "24px",
                  marginTop: "20px",
                }}
              >
                <h2 style={{ textAlign: "center" }}>Settings </h2>
                <h4 style={{ textAlign: "left" }}>Notifications </h4>
                <br />
                {(this.props.company_type[0] === "Tech Startup" ||
                  this.props.company_type[0] === "Startup") && (
                  <div style={{ display: "flex" }}>
                    <Checkbox
                      checked={this.props.settings.weeklyOpportunities}
                      name="weeklyOpportunities"
                      onChange={this.props.handleSettingsChange}
                      value="weeklyOpportunities"
                      color="primary"
                    />
                    <span style={{ marginLeft: "40px" }}>
                      <p className="ubaidSettingPara">
                        Would you like to receive bi-weekly opportunities for
                        your company?
                      </p>{" "}
                    </span>
                  </div>
                )}

                <div style={{ display: "flex" }}>
                  <Checkbox
                    checked={
                      this.props.settings.likesEmail !== undefined
                        ? this.props.settings.likesEmail
                        : true
                    }
                    name="likesEmail"
                    onChange={this.props.handleSettingsChange}
                    value="likesEmail"
                    color="primary"
                  />
                  <span style={{ marginLeft: "40px" }}>
                    <p className="ubaidSettingPara">
                      {" "}
                      Would you like to get an email notification when someone
                      "Likes" your post/comment?
                    </p>{" "}
                  </span>
                </div>
                <div style={{ display: "flex" }}>
                  <Checkbox
                    checked={
                      this.props.settings.commentsEmail !== undefined
                        ? this.props.settings.commentsEmail
                        : true
                    }
                    name="commentsEmail"
                    onChange={this.props.handleSettingsChange}
                    value="commentsEmail"
                    color="primary"
                  />
                  <span style={{ marginLeft: "40px" }}>
                    <p className="ubaidSettingPara">
                      {" "}
                      Would you like to get an email notification when someone
                      "Comments" on your post/comment?
                    </p>{" "}
                  </span>
                </div>
                <div style={{ display: "flex" }}>
                  <Checkbox
                    checked={
                      this.props.settings.followPostEmail !== undefined
                        ? this.props.settings.followPostEmail
                        : true
                    }
                    name="followPostEmail"
                    onChange={this.props.handleSettingsChange}
                    value="followPostEmail"
                    color="primary"
                  />
                  <span style={{ marginLeft: "40px" }}>
                    <p className="ubaidSettingPara">
                      {" "}
                      Would you like to get an email notification when someone
                      you follow makes a new post?
                    </p>{" "}
                  </span>
                </div>
                <div style={{ display: "flex" }}>
                  <Checkbox
                    checked={
                      this.props.settings.followerEmail !== undefined
                        ? this.props.settings.followerEmail
                        : true
                    }
                    name="followerEmail"
                    onChange={this.props.handleSettingsChange}
                    value="followerEmail"
                    color="primary"
                  />
                  <span style={{ marginLeft: "40px" }}>
                    <p className="ubaidSettingPara">
                      {" "}
                      Would you like to get an email notification when you have
                      a new follower?
                    </p>{" "}
                  </span>
                </div>

                <div style={{ display: "flex" }}>
                  <Checkbox
                    checked={
                      this.props.settings.profileViewEmail !== undefined
                        ? this.props.settings.profileViewEmail
                        : true
                    }
                    name="profileViewEmail"
                    onChange={this.props.handleSettingsChange}
                    value="profileViewEmail"
                    color="primary"
                  />
                  <span style={{ marginLeft: "40px" }}>
                    <p className="ubaidSettingPara">
                      {" "}
                      Would you like to get an email notification when a company
                      views your profile?
                    </p>{" "}
                  </span>
                </div>

                <div style={{ display: "flex" }}>
                  <Checkbox
                    checked={
                      this.props.settings.emailAfterMMessage !== undefined
                        ? this.props.settings.emailAfterMMessage
                        : true
                    }
                    name="emailAfterMMessage"
                    onChange={this.props.handleSettingsChange}
                    value="emailAfterMMessage"
                    color="primary"
                  />
                  <span style={{ marginLeft: "40px" }}>
                    <p className="ubaidSettingPara">
                      {" "}
                      Would you like to get an email notification if you are
                      offline and someone messages you?
                    </p>{" "}
                  </span>
                </div>
                <br />
                <h3 style={{ textAlign: "left" }}>Visibility </h3>
                <br />
                <div style={{ display: "flex" }}>
                  <Checkbox
                    checked={
                      this.props.settings.stealth !== undefined
                        ? this.props.settings.stealth
                        : false
                    }
                    name="stealth"
                    onChange={this.props.handleSettingsChange}
                    value="stealth"
                    color="primary"
                  />
                  <span style={{ marginLeft: "40px" }}>
                    <p className="ubaidSettingPara">
                      {" "}
                      Would you like to get your company to be visible on StartupFuel
                    </p>{" "}
                  </span>
                </div>
                <br />
                <h3 style={{ textAlign: "left" }}>Payment </h3>
                <br />
                {this.props.stripe_id === undefined ||
                this.props.stripe_id === null ||
                this.props.stripe_id.length === 0 ? (
                  <a
                    href={`https://connect.stripe.com/oauth/authorize?client_id=ca_HrbfJjneCJTIyc3IIeJhmF3DcXbSRmWo&state=${this.state.csrfToken}&scope=read_write&response_type=code&redirect_uri=${this.state.url.origin}/stripe_confirmation&stripe_user[email]=${this.props.user_email}&stripe_user[url]=${this.props.company_website}`}
                    class="stripe-connect light-blue"
                  >
                    <span>Connect with Stripe</span>
                  </a>
                ) : (
                  <>
                    <p style={{ color: "green" }}>
                      Already connected to Stripe.
                    </p>
                    <a
                      href={`https://connect.stripe.com/oauth/authorize?client_id=ca_HrbfJjneCJTIyc3IIeJhmF3DcXbSRmWo&state=${this.state.csrfToken}&scope=read_write&response_type=code&redirect_uri=${this.state.url.origin}/stripe_confirmation&stripe_user[email]=${this.props.user_email}&stripe_user[url]=${this.props.company_website}`}
                      class="stripe-connect light-blue"
                    >
                      <span>Re-connect with Stripe</span>
                    </a>
                  </>
                )}

                <br />
              </div>
              <div style={{ textAlign: "center" }}>
                <Button
                  variant="contained"
                  onClick={this.props.deleteCompany}
                  style={{ backgroundColor: "#ca3a64" }}
                  className={classes.button}
                >
                  Delete Company
                </Button>
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}
CustomizedTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CustomizedTable);
