/* (2019-06-10) - This page allows an admin to create an opportunity. However
this page and its components are outdated and need work to reflect the same
capabilities as when a user of SF makes an opportunity. Its components can be found
in /components/opportunity_modules
*/
import React from "react";
import axios from "axios";
import "./components/opportunity_modules/main.css";
import { Button, Alert, Form, FormLabel, Radio, Row } from "react-bootstrap";
import FormControl from "@material-ui/core/FormControl";
import { Helmet } from "react-helmet";
import URL from "url-parse";
import { AppContext } from "../App";
import Navigation from "./components/navbar";
import Footer from "./components/footer";
import { Sign_In, Register } from "./components/popups";
import {
  company_type_list,
  industry_list,
  stage_list,
  business_type,
  country_list,
  debt_type_list,
  opportunity_fields_values,
  opportunity_type_list,
} from "../boilerplate/list.json";
import default_pic from "../img/default_profile_pic.jpg";
import default_background from "../img/bg.png";
import update from "immutability-helper";
import SiteTitle from "./components/site_title.js";
import Checkbox from "@material-ui/core/Checkbox";
import MySnackbarContent from "./components/popover.js";

import Card1 from "./components/opportunity_modules/card1.js";
import Card2 from "./components/opportunity_modules/card2.js";
import Card3 from "./components/opportunity_modules/card3.js";

class Create_Opportunity_Container extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <Create_Opportunity {...this.props} {...this.context} />;
  }
}

Create_Opportunity_Container.contextType = AppContext;

class Create_Opportunity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //General
      file: "",
      opportunity_type: "Events",
      opportunity_type_old: "",
      affiliated_company: "",
      opportunity_name: "",
      target_industries: "",
      target_stage_companies: [],
      target_model_companies: "",
      target_distinctions: [],
      opportunity_description: "",
      website_url: "",
      deadline: "",
      geographical_location: "",
      venue_location: "",
      start_date: "",
      end_date: "",
      showSignInPopup: false,
      fields: "",
      select_all: false,
      showSuccessPopover: false,
      ticket_price_checked: false,
      price: 0,
      expiration_date_checked: false,
      venue_checked: false,
      start_end_checked: false,
      has_applicant_room: false,

      /*
            //Funding General
            amount:'',


            //Funding Debt
            debt_type:'',
            repayment_term:'',
            repayment_interest:'',

            //event
            start_date:'',
            end_date:'',
            venue_location:'',
            ticket_price:'',

            prize_pitch:'',

            //Innovation challenge
            deadline_to_complete:'',
            prize_challenge: '',
            objective:'', */

      company_list: [],
      errors: {},
      file_temp: "",
      imagePreviewUrl: "",
    };
  }
  handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file_temp = e.target.files[0];
    reader.onloadend = () => {
      this.setState({
        file_temp: file_temp,
        imagePreviewUrl: reader.result,
      });
    };

    reader.readAsDataURL(file_temp);
  };

  handleValidation = (event) => {
    let errors = {};
    let formIsValid = true;

    //Opportunity Name
    if (this.state.opportunity_name.length === 0) {
      formIsValid = false;
      errors["name"] = " Please Enter an Opportunity Name";
    }
    if (this.state.affiliated_company.length === 0) {
      formIsValid = false;
      errors["company"] = " Please select a company";
    }
    let lastDotPos = this.state.website_url.lastIndexOf(".");

    if (
      this.state.website_url.length === 0 ||
      lastDotPos === -1 ||
      lastDotPos === this.state.website_url.length - 1
    ) {
      formIsValid = false;
      errors["url"] =
        " Please make sure your website follows one of the following formats: startupfuel.com or www.startupfuel.com";
    }
    if (this.state.target_industries.length === 0) {
      formIsValid = false;
      errors["industries"] = " Please select at least one industry";
    }
    if (this.state.target_model_companies.length === 0) {
      formIsValid = false;
      errors["model"] = " Please select at least one industry";
    }
    if (this.state.target_stage_companies.length === 0) {
      formIsValid = false;
      errors["stage"] = " Please select at least one industry";
    }
    if (this.state.target_distinctions.length === 0) {
      formIsValid = false;
      errors["stage"] = " Please select at least one founder";
    }
    if (this.state.geographical_location.length === 0) {
      formIsValid = false;
      errors["country"] = " Please select at least one location";
    }
    //Email
    // if(!fields["email"]){
    //    formIsValid = false;
    //    errors["email"] = "Cannot be empty";
    // }

    //  if(typeof fields["email"] !== "undefined"){
    //     let lastAtPos = fields["email"].lastIndexOf('@');
    //     let lastDotPos = fields["email"].lastIndexOf('.');
    //
    //     if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["email"].indexOf('@@') == -1 && lastDotPos > 2 && (fields["email"].length - lastDotPos) > 2)) {
    //        formIsValid = false;
    //        errors["email"] = "Email is not valid";
    //      }
    // }

    this.setState(
      {
        errors: errors,
      },
      () => {
        if (formIsValid) {
          this.handleInputSubmit(event);
        }
      }
    );
  };

  getCompanyInfo = (array, field) => {
    return array.map((co) => co[field]);
  };

  componentDidMount() {
    this.fetchAssets(`explore_directory`, "company_list", function (_this) {
      _this.setState({
        company_ids: _this.getCompanyInfo(_this.state.company_list, "_id"),
        showLoader: false,
      });
    });
  }
  getStage = (value, array) => {
    for (let i = 0; i < array.length; i++) {
      if (array[i].value.toString() === value) {
        return array[i].text;
      }
    }
  };

  handleCheck = (e) => {
    let name = e.target.name;
    this.setState(
      {
        [name]: !this.state[name],
      },
      () => {
        if (this.state.ticket_price_checked) {
          this.setState({
            price: 0,
          });
        }
        if (!this.state.expiration_date_checked) {
          this.setState({
            deadline: "",
          });
        }
        if (!this.state.venue_checked) {
          this.setState({
            geographical_location: "",
          });
        }
        if (!this.state.start_end_checked) {
          this.setState({
            start_date: "",
            end_date: "",
          });
        }
      }
    );
  };

  handleChange = (e) => {
    const name = e.target.name;
    this.setState({
      [name]: e.target.value,
    });
  };

  handleChangeMultiple = (e) => {
    const name = e.target.name;
    let selected_values = [];
    Array.from(e.target.selectedOptions).forEach(function (item) {
      selected_values.push(item.value);
    });
    this.setState({
      [name]: selected_values,
    });
  };
  selectAll = (stateName, list) => {
    if (this.state.select_all === false) {
      const name = stateName;
      let selected_values = [];
      if (name !== "target_stage_companies") {
        Array.from(list).forEach(function (item) {
          selected_values.push(item);
        });
        if (list.length === selected_values.length) {
          this.setState({
            [name]: selected_values,
            select_all: true,
          });
        }
      } else {
        Array.from(list).forEach(function (item) {
          selected_values.push(item.value);
        });
        if (list.length === selected_values.length) {
          this.setState({
            [name]: selected_values,
            select_all: true,
          });
        }
      }
    }
  };
  unselectAll = (stateName, list) => {
    if (this.state.select_all === true) {
      const name = stateName;
      let selected_values = [];
      Array.from(list).forEach(function (item) {
        selected_values.push(item);
      });
      if (list.length === selected_values.length) {
        this.setState({
          [name]: [],
          select_all: false,
        });
      }
    }
  };

  fetchAssets = (asset_type, state_component, callback) => {
    axios
      .get(`/fetch_${asset_type}`)
      .then((response) => {
        if (response.data.status === "success") {
          this.setState(
            {
              [state_component]: response.data.result,
            },
            function () {
              if (callback) {
                callback(this);
              }
            }
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  togglePopup = (e) => {
    this.setState({
      showSignInPopup: !this.state.showSignInPopup,
    });
  };

  handleFileUpload = (e) => {
    let file = e.target.files[0];
    e.persist();
    this.setState(
      {
        file,
      },
      () => {
        this.handleImageChange(e);
      }
    );
  };

  getAvatarUrl = (array, file_type) => {
    if (array?.length > 0) {
      let new_arr = array.filter((file) => {
        return file.file_type === file_type;
      });
      if (new_arr.length !== 0) {
        return new_arr[0].file_url;
      } else {
        return default_pic;
      }
    } else {
      return default_pic;
    }
  };

  mapOpportunityList = () => {
    if (this.state.opportunity_type_old !== this.state.opportunity_type) {
      this.setState({
        opportunity_type_old: this.state.opportunity_type,
      });
      let arr = [];
      let i = 0;
      for (var key in opportunity_fields_values) {
        this.setState({
          [opportunity_fields_values[key]["value"]]: "",
        });
        Object.values(opportunity_fields_values[key]["opportunity_type"]).map(
          (opp) => {
            if (opp === this.state.opportunity_type) {
              arr.push(opportunity_fields_values[key]);
            }
            i++;
            if (i === Object.values(opportunity_fields_values[key]).length) {
              this.setState({
                fields: arr,
              });
            }
          }
        );
      }
    }
  };

  mappingFunc = (
    x,
    company_type_list,
    industry_list,
    stage_list,
    business_type,
    country_list,
    debt_type_list,
    opportunity_fields_values,
    opportunity_type_list
  ) => {
    return;
    {
      x.map((opp) => <option value={opp}>{opp}</option>);
    }
  };

  handleInputSubmit = (e) => {
    e.preventDefault();

    let config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    let formData = new FormData();

    for (let key in this.state) {
      if (key !== "file_temp") {
        formData.append(key, this.state[key]);
      }
    }

    axios
      .post("/create_opportunity", formData, config)
      .then((response) => {
        this.setState({
          opportunity_name: "",
          target_industries: "",
          target_stage_companies: "",
          target_model_companies: "",
          target_distinctions: "",
          opportunity_description: "",
          website_url: "",
          deadline: "",
          geographical_location: "",
          showSignInPopup: false,
          //Funding General
          amount: "",

          //Funding Debt
          debt_type: "",
          repayment_term: "",
          repayment_interest: "",
          showSuccessPopover: true,

          //event
          start_date: "",
          end_date: "",
          venue_location: "",
          ticket_price: "",

          //pitch competition/Demo
          prize_pitch: "",
          /*
          10) Deadline to apply - input date
          11) Start Date - input date
          12) End Date - input date,
          13) Time Starts - input time
          14) Time Ends - input time
          */

          //Innovation challenge
          deadline_to_complete: "",
          prize_challenge: "",
          objective: "",

          //grants
          /* 9) Amount - number
          10) Deadline to Apply - input date */

          //Hackathon
          /* 9) Prize - number
          10) Objective - input textarea
          11) Deadline to apply
          12) Start Date - input date
          13) End Date - input date,
          14) Time Starts - input time
          15) Time Ends - input time */
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  togglePopover = () => {
    this.setState({
      showSuccessPopover: !this.state.showSuccessPopover,
    });
  };

  render() {
    const error = this.state.opportunity_name.length !== 2;
    return (
      <React.Fragment>
        <SiteTitle />
        {this.state.showAlert ? (
          <Alert bsStyle="success">
            New Company
            {this.state.submittedName} has been added to the database!
          </Alert>
        ) : (
          <div></div>
        )}
        <Navigation />
        <Sign_In />
        <Register />
        {this.state.showSuccessPopover && (
          <MySnackbarContent togglePopover={this.togglePopover} />
        )}
        <div className="App-opp">
          <div className="app-body">
            <div className="container">
              <div className="cards-container">
                <Card1
                  handleChange={this.handleChange}
                  handleChangeMultiple={this.handleChangeMultiple}
                  handleFileUpload={this.handleFileUpload}
                  handleCheck={this.handleCheck}
                  getAvatarUrl={this.getAvatarUrl}
                  {...this.state}
                />
                <Card2
                  handleChange={this.handleChange}
                  handleChangeMultiple={this.handleChangeMultiple}
                  handleFileUpload={this.handleFileUpload}
                  selectAll={this.selectAll}
                  unselectAll={this.unselectAll}
                  handleImageChange={this.handleImageChange}
                  handleValidation={this.handleValidation}
                  handleCheck={this.handleCheck}
                  {...this.state}
                />
                <Card3
                  handleChange={this.handleChange}
                  handleChangeMultiple={this.handleChangeMultiple}
                  handleFileUpload={this.handleFileUpload}
                  handleCheck={this.handleCheck}
                  getAvatarUrl={this.getAvatarUrl}
                  getStage={this.getStage}
                  {...this.state}
                />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Create_Opportunity_Container;
