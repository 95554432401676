/* (2019-06-10) - This is the card that is displayed on the opportunities page.
 */
import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import default_background from "../../img/bg.png";
import classnames from "classnames";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import CardActionArea from "@material-ui/core/CardActionArea";
import Typography from "@material-ui/core/Typography";
import ShareIcon from "@material-ui/icons/Share";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Chip from "@material-ui/core/Chip";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Moment from "moment";
import { Share_Popup } from "./popups";
import { distinction_list, stage_list } from "../../boilerplate/list.json";
const CustomTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#e0e0e0",
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);
const styles = (theme) => ({
  button: {
    margin: theme.spacing.unit,
  },
  input: {
    display: "none",
  },
  header: {
    overflow: "hidden",
  },
  root: {
    marginBottom: theme.spacing.unit * 2,
  },
  chip: {
    margin: theme.spacing.unit,
  },
  CardHeight: {
    height: 210,
    overflow: "hidden",
    fontSize: "14px",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  actions: {
    display: "flex",
  },
  expand: {
    transform: "rotate(0deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
    marginLeft: "0",
    [theme.breakpoints.up("sm")]: {
      marginRight: -8,
    },
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  type: {
    fontSize: "1em",
    color: "red",
    textAlign: "center",
    fontWeight: 0,
  },
  footer: {
    display: "flex",
  },
  title: {
    fontSize: "1rem",
    overflow: "hidden",
    height: 25,
  },
  grow: {
    flexGrow: 1,
  },
});

function handleClick() {
  alert("You clicked the Chip.");
}

class RecipeReviewCard extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    expanded: false,
    showSharePopup: false,
    opportunity_id: "",
    CardHeaderHeight: "",
  };

  handleExpandClick = () => {
    this.setState((state) => ({
      expanded: !state.expanded,
    }));
  };
  handleSharePopup = (e, opp_id) => {
    this.setState(
      {
        opportunity_id: opp_id,
      },
      () => {
        this.setState({
          showSharePopup: !this.state.showSharePopup,
        });
      }
    );
  };
  render() {
    const { classes } = this.props;

    return (
      <Card className={`${classes.Card} ${classes.grow} ${classes.root}`}>
        <Share_Popup
          {...this.state}
          {...this.props}
          opportunity_id={this.state.opportunity_id}
          handleChange={this.handleChange}
          handleSharePopup={this.handleSharePopup}
        />
        <div className="exploreCardHeader">
          {this.props.affiliated_company !== null && (
            <div
              className="exploreCardAvtar"
              style={{
                backgroundImage:
                  "url(" +
                  this.props.getAvatarUrl(
                    this.props.affiliated_company.files,
                    "company avatar"
                  ) +
                  ")",
              }}
            ></div>
          )}
          <div className="exploreCardTitle">
            <a href={"/opportunity?opportunity_id=" + this.props._id}>
              {this.props.opportunity_name}
            </a>
            {"#" + this.props.opportunity_type}
          </div>
          <IconButton
            onClick={(event) => this.handleSharePopup(event, this.props._id)}
            aria-label="Share"
          >
            <ShareIcon />
          </IconButton>
        </div>
        {/* <CardHeader style={{ height: this.state.CardHeaderHeight }}
          avatar={<div>
            {(this.props.affiliated_company !== null) &&
              <Avatar
                aria-label="Recipe"
                src={this.props.getAvatarUrl(this.props.affiliated_company.files, "company avatar")}
                className={classes.avatar} />}
          </div>}
          title={<a href={'/opportunity?opportunity_id=' + this.props._id}>
            <Typography
              variant="h6"
              className={classes.title}
              gutterBottom>
              {this.props.opportunity_name} </Typography>
          </a>}
          subheader={"#" + this.props.opportunity_type}
          className={classes.header}
          action={<IconButton
            onClick={(event) => this.handleSharePopup(event, this.props._id)}
            aria-label="Share">
            <ShareIcon />
          </IconButton>} /> */}
        <CardActionArea href={"/opportunity?opportunity_id=" + this.props._id}>
          <CardMedia
            className={classes.media}
            image={
              this.props.background_cover !== undefined
                ? this.props.background_cover
                : default_background
            }
            title="background_picture"
          />
          <CardContent className="exploreCardContent OopSerToolCardContent">
            {this.props.opportunity_description}
          </CardContent>
        </CardActionArea>
        <Grid className={classes.footer}>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-start"
            className="col-3"
          >
            <CardActions className={classes.actions}>
              <IconButton
                className={
                  classnames(classes.expand, {
                    [classes.expandOpen]: this.state.expanded,
                  }) + " OopSerToolCardFooter"
                }
                onClick={this.handleExpandClick}
                aria-expanded={this.state.expanded}
                aria-label="Show more"
              >
                <ExpandMoreIcon />
              </IconButton>
            </CardActions>
          </Grid>
          <Grid container direction="row" justify="center" alignItems="center">
            {(this.props.opportunity_type === "Grants" ||
              this.props.opportunity_type === "Hackathons" ||
              this.props.opportunity_type === "Pitch Competitions") &&
              this.props.deadline !== null && (
                <Typography
                  variant="caption"
                  className={classes.type}
                  gutterBottom
                ></Typography>
              )}
            {this.props.opportunity_type === "Innovation Challenges" &&
              this.props.deadline_to_complete !== null && (
                <Typography
                  variant="caption"
                  className={classes.type}
                  gutterBottom
                ></Typography>
              )}
          </Grid>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-end"
            className="col-4"
          >
            <Button
              variant="contained"
              color="primary"
              className={classes.button + " OopSerToolCardFooterBtn"}
              size="small"
            >
              <a href={"/opportunity?opportunity_id=" + this.props._id}>VIEW</a>
            </Button>
          </Grid>
        </Grid>
        <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Grid
              container
              direction="column"
              xs={12}
              sm={12}
              alignItems="center"
            >
              {(this.props.opportunity_type === "Events" ||
                this.props.opportunity_type === "Pitch Competitions" ||
                this.props.opportunity_type === "Hackathons") && (
                <Grid
                  container
                  direction="column"
                  xs={12}
                  sm={12}
                  alignItems="center"
                >
                  {this.props.start_date !== null && (
                    <Table className={classes.table}>
                      <TableHead>
                        <TableRow>
                          <CustomTableCell
                            style={{ padding: "unset" }}
                            className="text-center"
                          >
                            Start Date
                          </CustomTableCell>
                          <CustomTableCell
                            style={{ padding: "unset" }}
                            className="text-center"
                          >
                            End Date
                          </CustomTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell
                            style={{ padding: "unset", textAlign: "center" }}
                          >
                            <Typography>
                              {Moment(this.props.start_date).format(
                                "MMMM Do YYYY"
                              )}
                            </Typography>
                          </TableCell>
                          <TableCell
                            style={{ padding: "unset", textAlign: "center" }}
                          >
                            <Typography>
                              {Moment(this.props.end_date).format(
                                "MMMM Do YYYY"
                              )}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  )}
                </Grid>
              )}
              {this.props.target_distinctions?.length > 0 && (
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <CustomTableCell className="text-center">
                        Founder Diversity
                      </CustomTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      {this.props.target_distinctions.map((distinction) => (
                        <Chip
                          size="small"
                          label={this.props.getDiversity(
                            distinction,
                            distinction_list
                          )}
                          className={classes.chip}
                        />
                      ))}
                    </TableRow>
                  </TableBody>
                </Table>
              )}
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <CustomTableCell className="text-center">
                      Location
                    </CustomTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <div style={{ textAlign: "center" }}>
                      {this.props.geographical_location.map((val) => (
                        <Chip
                          size="small"
                          //icon={<FaceIcon />}
                          label={val}
                          //onClick={handleClick}
                          className={classes.chip}
                        />
                      ))}
                    </div>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
            <Grid
              container
              direction="column"
              xs={12}
              sm={12}
              alignItems="center"
            >
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <CustomTableCell className="text-center">
                      Stage
                    </CustomTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <div style={{ textAlign: "center" }}>
                      {this.props.target_stage_companies.map((val) => (
                        <Chip
                          size="small"
                          //icon={<FaceIcon />}
                          label={this.props.getStage(val, stage_list)}
                          //onClick={handleClick}
                          className={classes.chip}
                        />
                      ))}
                    </div>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
            <Grid
              container
              direction="column"
              xs={12}
              sm={12}
              alignItems="center"
            >
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <CustomTableCell className="text-center">
                      Industry
                    </CustomTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <div style={{ textAlign: "center" }}>
                      {this.props.target_industries.map((val) => (
                        <Chip
                          size="small"
                          //icon={<FaceIcon />}
                          label={val == "Artificial Intelligence" ? "A.I" : val}
                          //onClick={handleClick}
                          className={classes.chip}
                        />
                      ))}
                    </div>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
          </CardContent>
        </Collapse>
      </Card>
    );
  }
  mediaQuery = (x) => {
    if (x.matches) {
      this.setState({ CardHeaderHeight: "100px" });
    } else {
      this.setState({ CardHeaderHeight: "" });
    }
  };
  componentDidMount() {
    let x = window.matchMedia("(max-width: 1150px)");
    this.mediaQuery(x);
    x.addListener(this.mediaQuery);
  }
}

RecipeReviewCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RecipeReviewCard);
