import React from 'react';

import '../test_mj-dev/sass/sass-css/_privacy_policy.scss';
import Navbar from './components/navbar';
import SideMenu from './SideMenu';
import './ubaidPrivacyandPolicy.css';
import './404.css';



const PrivacyPolicyPage = props => {

	return (
		<React.Fragment>
			<Navbar />
			<SideMenu />
			<div className="errorpage">
				<div id="container-error">
					<h1 className="error-title">404 </h1>
					<span>Sorry we don't have that page, but we can help you get back to StartupFuel.</span>
					<div className='mt-4'><img src="https://play-lh.googleusercontent.com/wIODufpyTTPVV_OLBAYIehA6huMh2I91lKBELAwfdYBTWuhlVg7t9CAstTeQvdusFOc=w240-h480-rw" alt="404" /></div>

					<div className='error-redirect text-center'>
					<div className="buttonerror" id="buttonerror">
						<a href="/explore">Explore Page</a>
					</div>
					<div className="buttonerror" id="buttonerror">
						<a href="https://startupfuel.com/">Landing Page</a>
					</div>
					<div className="buttonerror" id="buttonerror">
						<a href="https://startupfuel.com/pages/all-services">Services Page</a>
					</div>
				</div>
					
				</div>
				
			</div>
		</React.Fragment>

	)

}





export default PrivacyPolicyPage;
