/* (2019-06-11) - This is the main component of the team section of the SIR.
It handles all the functions for adding and editing judges. It also maps through
the judges and displays them.
*/
import React, { Component, Fragment } from "react";
import axios from "axios";
import URL from "url-parse";
import "./index.scss";
import StarRatings from "react-star-ratings";
import { ProgressBar, Row, Col } from "react-bootstrap";
import Comment from "../comment/Comment";
import Grid from "@material-ui/core/Grid";
import { Card } from "@material-ui/core";
import default_pic from "../../../../img/default_company_pic.png";
import default_background from "../../../../img/bg.png";
import SocialAPI from './../../../api/social';
import '../../../components/social.css';

import Filters from "../../../components/explore_filters";
//import Explore_Profile_Card from "../../../components/explore_card";
import Explore_Profile_Card from "./explore_card";
import _uniqBy from "lodash/uniqBy";

import {
  company_type_list,
  industry_list,
  stage_list,
  business_type,
  vertical_list,
} from "../../../../boilerplate/list.json";

import {
  Button,
  Alert,
  Form,
  FormControl,
  FormLabel,
  Radio,
} from "react-bootstrap";
import { individual_user_type_list } from "../../../../boilerplate/list.json";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import { AppContext } from "../../../../App";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import SortBy from "../../../components/explore_sort_filter";

import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import DirectionsIcon from "@material-ui/icons/Directions";
import Icon from "@material-ui/core/Icon";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import classnames from "classnames";

import ArgNav from "../argNav";
import ThreeDotsIcon from "../../../../newsfeed/icons/ThreeDotsIcon";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  icon: {
    margin: theme.spacing.unit * 2,
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
});

class SocialFeed_Container extends React.Component {
    constructor(props) {
        super(props);
      }
      static contextType = AppContext;
      render() {
        return (
          <SocialFeed
            {...this.props}
            {...this.context} />
        )
      }
    }
    class SocialFeed extends React.Component {
      constructor(props) {
        super(props);
    
        this.state = {
          feed: []
        };
      }
    
      componentDidMount() {
        this.fetchFeed();
      }
    
      fetchFeed = () => {
        SocialAPI.feed()
          .then((response) => {
            if (response.data.status === 'success') {
              this.setState({
                feed: response.data.feed || []
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    
      handlePost = (content) => {
        SocialAPI.post(content)
          .then((response) => {
            // refresh feed
            this.fetchFeed();
            this.setState({ postContent: undefined });
          })
      }
    
      handleLike = () => {
      }
    
      handleComment = () => {
      }
    
      handlePostContentChange = (event) => {
        this.setState({ postContent: event.target.value });
      }
    
      handlePostSubmit = (event) => {
        this.handlePost(this.state.postContent);
        event.preventDefault();
      }

  render() {
    return (
      <SocialFeed
        {...this.props}
        {...this.context} />
    )
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <ArgNav
          opportunity_name = {this.props.opportunity_name}
          opportunity_id = {this.state.url.query.opportunity_id}
          pathname={this.state.url.pathname} />
        <div className='__content'> 
          <div className="__explore-page">
            <div className="__header">
              <div className="__title">
                <h2>
                Explore
                </h2>
              </div>
            </div>
              
            <div className="__application-room-margin">
                <div className="row">
            <div className="col-lg-6 end">
                <div>
                <div class="card post-item">
                    <div class="post-item-content">
                    <div class="post-item-avatar">
                        <img src="https://via.placeholder.com/50" />
                    </div>
                    <div class="post-item-input">
                        <textarea name="content"
                            onChange={this.handlePostContentChange}
                            value={this.state.postContent}
                        placeholder="Share an opportunity..." />
                    </div>
                    </div>
                    <div class="post-item-actions">
                        <i class="fa fa-paperclip"></i>
                        {/* <button class="btn btn-primary btn-sm" onClick={this.handlePostSubmit}>
                        Post
                    </button> */}
                    </div>
                </div>
                </div>
                {/* <div>
                { this.state.feed.length == 0 && (
                    <h5 style={{textAlign: "center"}}>
                        <br />
                    There is no content to show at the moment
                    </h5>
                )}
                { this.state.feed.map(function(item, i) {
                    return (
                        <div key={i} class="feed-item card">
                        <div class="feed-item-header">
                            <div class="feed-item-avatar">
                            <img src="https://via.placeholder.com/50" />
                            </div>
                            <div class="feed-item-user">
                            Sarah
                            </div>
                            <div class="feed-item-timestamp feed-item-meta">
                            {Moment(item.created_on).format("MMM Do YYYY")}
                            </div>
                        </div>
                        <div class="feed-item-body">
                            {item.content}
                        </div>
                        <div class="feed-item-footer">
                            <div class="feed-item-actions row no-gutters">
                            <div class="col-2 feed-item-action-like">
                                <i class="fa fa-heart-o"></i> 3
                            </div>
                            <div class="col-8 feed-item-like-summary">
                                <img src="https://via.placeholder.com/25" />
                                <img src="https://via.placeholder.com/25" />
                                <img src="https://via.placeholder.com/25" />
                                <img src="https://via.placeholder.com/25" />
                                <div class="feed-item-likers-summary feed-item-meta">
                                You, Eliana and 4 more like this
                                </div>
                            </div>
                            <div class="col-2 feed-item-action-comment">
                                <i class="fa fa-reply"></i> 5
                            </div>
                            </div>
                        </div>
                        </div>
                        );
                    }) }
                </div> */}
            </div>
                </div>

              
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SocialFeed_Container.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SocialFeed_Container);

