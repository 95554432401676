import React from 'react';
import SuggestedFriends from '../RightBody/SuggestedFriends';
import Advertisement_Card from './Advertisement_Card';

const RightBody = props => (
  <div className='RightBodyNews'>
    <SuggestedFriends />
    <Advertisement_Card />
  </div>
)

export default RightBody;
