/* (2019-06-11) - This is the top applicants page, it contains all the functions
for displaying the top companies for the SIR.
*/
import React, { Component } from "react";
import axios from "axios";
import { CSVLink } from "react-csv";
import URL from "url-parse";
import "./index.css";
import Profile_Card from "../../../components/application_room_card_top";
import default_pic from "../../../../img/default_company_pic.png";
import default_background from "../../../../img/bg.png";
import Rating from './../ReviewsCard/Rating';
import {
  company_type_list,
  industry_list,
  stage_list,
  business_type,
} from "../../../../boilerplate/list.json";
import ThreeDotsIcon from "../../../../newsfeed/icons/ThreeDotsIcon";
import classnames from "classnames";
import SortBy from "./top_applicant_filters";
import ReviewsCards from "./../ReviewsCard";
import PropTypes from "prop-types";
import "./topapplication.scss";
import ArgNav from "../argNav";

class Page extends Component {
  constructor(props) {
    super(props);

    this.state = {
      url: new URL(window.location.href, true),
      companies: [],
      excel_data: [],
      companies_with_rating: [],
      static_companies_with_rating: [],
      loaded: false,

      selected_company_id: "",
      selected_company: "",
      showOpenReview: false,
      showPopUpRate: false,
      showSideRating: false,
      filter_number: "",

      problem: 5,
      solution: 5,
      traction: 5,
      idea: 5,
      technology: 5,
      marketing: 5,
      team: 5,
      overallRating: 5,
      comment: "",
      feedback: "",
      reviews: [],
      current_judge_has_reviewed: false,
      current_judge_review: {},
      totalOverall: 0,
      totalIdea: 0,
      totalTechnology: 0,
      totalMarketing: 0,
      totalTeam: 0,

      numberForGrid: 4,
      additional_review_criteria: [],
      totalAdditionalReviewCriteria: {},
    };
  }

  componentDidMount() {
    axios
      .get(
        `fetch_top_companies_for_opportunity?opportunity_id=${this.state.url.query.opportunity_id}`
      )
      .then((response) => {
        if (response.data.status !== "None") {
          this.setState(
            {
              companies: response.data.full_company_list_with_reviews.filter(
                (x) => x.reviews.length > 0
              ),
              loaded: true,
            },
            () => {
              for (let z = 0; z < this.state.companies.length; z++) {
                let overallRatingArr = {
                  overallRatingArr: 0,
                  ideaRatingArr: 0,
                  technologyRatingArr: 0,
                  marketingRatingArr: 0,
                  teamRatingArr: 0,
                };
                this.state.companies[z].reviews.map((review, i) => {
                  if (
                    review.pitchOverallRating === undefined ||
                    review.pitchOverallRating === null
                  ) {
                    overallRatingArr.overallRatingArr += review.overallRating;
                  } else {
                    overallRatingArr.overallRatingArr +=
                      review.pitchOverallRating;
                  }
                  overallRatingArr.ideaRatingArr += review.idea;
                  overallRatingArr.technologyRatingArr += review.technology;
                  overallRatingArr.marketingRatingArr += review.marketing;
                  overallRatingArr.teamRatingArr += review.team;
                  let y = i + 1;
                  if (y === this.state.companies[z].reviews.length) {
                    overallRatingArr.overallRatingArr =
                      overallRatingArr.overallRatingArr /
                      this.state.companies[z].reviews.length;
                    overallRatingArr.ideaRatingArr =
                      overallRatingArr.ideaRatingArr /
                      this.state.companies[z].reviews.length;
                    overallRatingArr.technologyRatingArr =
                      overallRatingArr.technologyRatingArr /
                      this.state.companies[z].reviews.length;
                    overallRatingArr.marketingRatingArr =
                      overallRatingArr.marketingRatingArr /
                      this.state.companies[z].reviews.length;
                    overallRatingArr.teamRatingArr =
                      overallRatingArr.teamRatingArr /
                      this.state.companies[z].reviews.length;

                    let company = {
                      ...this.state.companies[z],
                      ...{ overallRatingArr },
                    };
                    this.state.companies_with_rating.push(company);
                    function compareNumbers(a, b) {
                      return (
                        b.overallRatingArr.overallRatingArr -
                        a.overallRatingArr.overallRatingArr
                      );
                    }
                    var x =
                      this.state.companies_with_rating.sort(compareNumbers);
                    this.setState({
                      companies_with_rating: x,
                      static_companies_with_rating: x,
                    });
                  }
                });
              }
            }
          );
        } else {
          this.setState({
            loaded: true,
          });
        }
      });

    // for styling
  }

  getAvatarUrl = (array, file_type, temp_background_url) => {
    let new_arr = array.filter((file) => {
      return file.file_type === file_type;
    });
    if (file_type === "company avatar") {
      if (new_arr.length !== 0) {
        return new_arr[0].file_url;
      } else {
        return default_pic;
      }
    }
    if (file_type === "company background") {
      if (new_arr.length !== 0) {
        return new_arr[0].file_url;
      } else {
        if (
          temp_background_url !== undefined &&
          temp_background_url !== null &&
          temp_background_url.length > 0
        ) {
          return temp_background_url;
        } else {
          return default_background;
        }
      }
    }
  };

  getBackgroundUrl = (array, file_type) => {
    if (array?.length > 0) {
      let new_arr = array.filter((file) => {
        return file.file_type === file_type;
      });
      if (new_arr.length !== 0) {
        return new_arr[0].file_url;
      } else {
        return default_background;
      }
    } else {
      return default_background;
    }
  };
  getStage = (value, array) => {
    for (let i = 0; i < array.length; i++) {
      if (array[i].value === value) {
        return array[i].text;
      }
    }
  };
  changeRating = (newRating, name) => {
    this.setState(
      {
        [name]: newRating,
      },
      () => {
        if (
          this.props.opportunity_data.additional_review_criteria !==
            undefined &&
          this.props.opportunity_data.additional_review_criteria !== null &&
          this.props.opportunity_data.additional_review_criteria.length > 0
        ) {
          let sumOfAdditional = Object.values(
            this.state.additional_review_criteria
          ).reduce((a, b) => a + b);
          let lengthOfAdditional = Object.keys(
            this.state.additional_review_criteria
          ).length;
          this.setState({
            overallRating:
              (this.state.idea +
                this.state.technology +
                this.state.marketing +
                this.state.team) /
              4,
            pitchOverallRating:
              (this.state.idea +
                this.state.technology +
                this.state.marketing +
                this.state.team +
                sumOfAdditional) /
              (4 + lengthOfAdditional),
          });
        } else {
          let sumOfAdditional = 0;
          let lengthOfAdditional = 0;
          this.setState({
            overallRating:
              (this.state.idea +
                this.state.technology +
                this.state.marketing +
                this.state.team) /
              4,
            pitchOverallRating:
              (this.state.idea +
                this.state.technology +
                this.state.marketing +
                this.state.team +
                sumOfAdditional) /
              (4 + lengthOfAdditional),
          });
        }
      }
    );
  };
  handleToggle = (e) => {
    e.preventDefault();
    this.setState({
      showOpenReview: !this.state.showOpenReview,
    });
  };
  handleChange = (e) => {
    const name = e.target.name;
    this.setState({
      [name]: e.target.value,
    });
  };

  previewSelectedCompany = (e, company_id) => {
    e.preventDefault();
    if (
      this.props.opportunity_data.additional_review_criteria !== undefined &&
      this.props.opportunity_data.additional_review_criteria !== null &&
      this.props.opportunity_data.additional_review_criteria.length > 0
    ) {
      this.props.opportunity_data.additional_review_criteria.map((criteria) => {
        this.setState((prevState) => ({
          additional_review_criteria: {
            // object that we want to update
            ...prevState.additional_review_criteria, // keep all other key-value pairs
            [criteria]: 5, // update the value of specific key
          },
          totalAdditionalReviewCriteria: {
            ...prevState.totalAdditionalReviewCriteria, // keep all other key-value pairs
            [criteria]: 0,
          },
        }));
      });
    }
    this.setState(
      {
        selected_company_id: company_id,
        totalOverall: 0,
        totalIdea: 0,
        totalTechnology: 0,
        totalMarketing: 0,
        totalTeam: 0,
        reviews: [],
        current_judge_review: {},
        current_judge_has_reviewed: false,
        idea: 5,
        technology: 5,
        marketing: 5,
        team: 5,
        overallRating: 5,
        pitchOverallRating: 5,
        comment: "",
        feedback: "",
      },
      () => {
        this.setState({
          selected_company: this.state.companies.filter((obj) => {
            return obj._id === company_id;
          }),
          showPopUpRate: true,
        });
        axios
          .get(
            `fetch_reviews?opportunity_id=${this.state.url.query.opportunity_id}&applicant_id=${this.state.selected_company_id}`
          )
          .then((response) => {
            this.setState(
              {
                reviews: response.data.reviews_list,
              },
              () => {
                let overallRatingArr = 0;
                let ideaRatingArr = 0;
                let technologyRatingArr = 0;
                let marketingRatingArr = 0;
                let teamRatingArr = 0;
                let additionalReviewCriteriaRatingObj = {};
                if (
                  this.props.opportunity_data.additional_review_criteria !==
                    undefined &&
                  this.props.opportunity_data.additional_review_criteria !==
                    null &&
                  this.props.opportunity_data.additional_review_criteria
                    .length > 0
                ) {
                  this.props.opportunity_data.additional_review_criteria.map(
                    (criteria) => {
                      additionalReviewCriteriaRatingObj[criteria] = 0;
                    }
                  );
                }

                this.state.reviews.map((review, i) => {
                  if (
                    review.pitchOverallRating === undefined ||
                    review.pitchOverallRating === null
                  ) {
                    overallRatingArr += review.overallRating;
                  } else {
                    overallRatingArr += review.pitchOverallRating;
                  }
                  ideaRatingArr += review.idea;
                  technologyRatingArr += review.technology;
                  marketingRatingArr += review.marketing;
                  teamRatingArr += review.team;
                  if (
                    this.props.opportunity_data.additional_review_criteria !==
                      undefined &&
                    this.props.opportunity_data.additional_review_criteria !==
                      null &&
                    this.props.opportunity_data.additional_review_criteria
                      .length > 0
                  ) {
                    this.props.opportunity_data.additional_review_criteria.map(
                      (criteria) => {
                        if (review.additional_review_criteria !== undefined) {
                          additionalReviewCriteriaRatingObj[criteria] +=
                            review.additional_review_criteria[criteria];
                        }
                      }
                    );
                  }
                  let y = i + 1;

                  if (review.judge._id === this.props.user._id) {
                    this.setState({
                      current_judge_review: review,
                      current_judge_has_reviewed: true,
                      idea: review.idea,
                      technology: review.technology,
                      marketing: review.marketing,
                      team: review.team,
                      additional_review_criteria:
                        review.additional_review_criteria,
                      overallRating: review.overallRating,
                      pitchOverallRating: review.pitchOverallRating,
                      comment: review.comment,
                      feedback: review.feedback,
                    });
                  } else {
                    this.setState({
                      current_judge_review: {},
                      current_judge_has_reviewed: false,
                      problem: 5,
                      solution: 5,
                      traction: 5,
                      idea: 5,
                      technology: 5,
                      marketing: 5,
                      team: 5,
                      overallRating: 5,
                      comment: "",
                      feedback: "",
                    });
                  }
                  if (y === this.state.reviews.length) {
                    let total_rating = 0;
                    if (
                      this.props.opportunity_data.additional_review_criteria !==
                        undefined &&
                      this.props.opportunity_data.additional_review_criteria !==
                        null &&
                      this.props.opportunity_data.additional_review_criteria
                        .length > 0
                    ) {
                      this.props.opportunity_data.additional_review_criteria.map(
                        (criteria) => {
                          additionalReviewCriteriaRatingObj[criteria] =
                            additionalReviewCriteriaRatingObj[criteria] /
                            this.state.reviews.length;
                          total_rating +=
                            additionalReviewCriteriaRatingObj[criteria];
                        }
                      );
                    }
                    this.setState({
                      totalOverall:
                        overallRatingArr / this.state.reviews.length,
                      totalIdea: ideaRatingArr / this.state.reviews.length,
                      totalTechnology:
                        technologyRatingArr / this.state.reviews.length,
                      totalMarketing:
                        marketingRatingArr / this.state.reviews.length,
                      totalTeam: teamRatingArr / this.state.reviews.length,
                      totalAdditionalReviewCriteria:
                        additionalReviewCriteriaRatingObj,
                    });
                  }
                });
              }
            );
          });
      }
    );
  };
  reviewSubmit = (e) => {
    e.preventDefault();
    axios
      .post("/create_review", {
        applicant_company: this.state.selected_company[0]._id,
        opportunity_id: this.state.url.query.opportunity_id,
        judge_id: this.props.user._id,
        idea: this.state.idea,
        technology: this.state.technology,
        marketing: this.state.marketing,
        team: this.state.team,
        overallRating: this.state.overallRating,
        pitchOverallRating: this.state.pitchOverallRating,
        comment: this.state.comment,
        feedback: this.state.feedback,
        additional_review_criteria: this.state.additional_review_criteria,
      })
      .then((response) => {
        if (response.status === "Review Created") {
          this.setState({
            current_judge_review: response.data.result,
            current_judge_has_reviewed: true,
          });
        } else {
        }
      });
  };

  getFileUrl = (array, file_type) => {
    if (array?.length > 0) {
      let new_arr = array.filter((file) => {
        return file.file_type === file_type;
      });
      if (new_arr.length !== 0) {
        return new_arr[0].file_url;
      }
    } else {
      return 0;
    }
  };
  editReviewSubmit = (e) => {
    e.preventDefault();
    axios
      .post("/edit_review", {
        applicant_company: this.state.selected_company[0]._id,
        opportunity_id: this.state.url.query.opportunity_id,
        judge_id: this.props.user._id,
        idea: this.state.idea,
        technology: this.state.technology,
        marketing: this.state.marketing,
        team: this.state.team,
        overallRating: this.state.overallRating,
        pitchOverallRating: this.state.pitchOverallRating,
        comment: this.state.comment,
        feedback: this.state.feedback,
        additional_review_criteria: this.state.additional_review_criteria,
      })
      .then(this.handleHide);
  };
  handleHide = () => {
    this.setState({
      showPopUpRate: false,
      selected_company: [],
      showOpenReview: false,
    });
  };
  handleFilterChange = (e) => {
    const name = e.target.name;
    this.setState(
      {
        filter_number: e.target.value,
        companies_with_rating: this.state.static_companies_with_rating,
      },
      () => {
        if (this.state.filter_number !== "all") {
          if (
            this.state.filter_number < this.state.companies_with_rating.length
          ) {
            this.setState({
              companies_with_rating: this.state.companies_with_rating.slice(
                0,
                this.state.filter_number
              ),
            });
          }
        }
      }
    );
  };
  computeExcel = (e) => {
    let excel_data = [];
    for (let i = 0; i < this.state.companies_with_rating.length; i++) {
      let value = {
        ...this.state.companies_with_rating[i],
        ...this.state.companies_with_rating[i].company_owner,
      };
      delete value.company_owner;
      delete value.password;
      delete value.settings;
      delete value.reviews;
      delete value.overallRatingArr;
      delete value.resetPasswordExpires;
      delete value.resetPasswordToken;
      value.stage = this.getStage(value.stage, stage_list);
      excel_data.push(value);
      if (excel_data.length === this.state.companies_with_rating.length) {
        this.setState(
          {
            excel_data: excel_data,
          },
          () => {
            return true;
          }
        );
      }
    }
  };
  handleChangeAdditionalRating = (newRating, name) => {
    this.setState(
      (prevState) => ({
        additional_review_criteria: {
          // object that we want to update
          ...prevState.additional_review_criteria, // keep all other key-value pairs
          [name]: newRating, // update the value of specific key
        },
      }),
      () => {
        let sumOfAdditional = Object.values(
          this.state.additional_review_criteria
        ).reduce((a, b) => a + b);
        let lengthOfAdditional = Object.keys(
          this.state.additional_review_criteria
        ).length;
        this.setState({
          overallRating:
            (this.state.idea +
              this.state.technology +
              this.state.marketing +
              this.state.team) /
            4,
          pitchOverallRating:
            (this.state.idea +
              this.state.technology +
              this.state.marketing +
              this.state.team +
              sumOfAdditional) /
            (4 + lengthOfAdditional),
        });
      }
    );
  };

  roundAdditionalCriteria = (criteria) => {
    if (this.state.totalAdditionalReviewCriteria[criteria] !== undefined) {
      let x = this.state.totalAdditionalReviewCriteria[criteria];
      let y = x.toFixed(1);
      return y;
    } else {
      return this.state.totalAdditionalReviewCriteria[criteria];
    }
  };

  render() {
    return (
      <div className="__pb-page m-3 p-3">
        <ArgNav
          opportunity_name = {this.props.opportunity_name}
          opportunity_id = {this.state.url.query.opportunity_id}
          pathname={this.state.url.pathname} />
        {this.state.loaded ? (
          <>
            <div className="__header __application-room-padding">
              <h3 className="__title">
                Reviewed Companies
              </h3>
              {this.props.opportunity_owner._id === this.props.user._id && (
                <CSVLink
                  data={this.state.excel_data}
                  className="__csv-download"
                  onClick={() => {
                    this.computeExcel();
                  }}
                >
                  <i className="fas fa-file-csv"></i>
                </CSVLink>
              )}
            </div>
            <div className="__application-room-margin __complanies-listed">
              <h5 style={{ width: "-webkit-fill-available" }}>
                {this.state.companies_with_rating.length}{" "}
                {this.state.companies_with_rating.length === 1
                  ? "Company"
                  : "Companies"}{" "}
                Reviewed
              </h5>
              <SortBy
                handleChange={this.handleChange}
                handleFilterChange={this.handleFilterChange}
                filter_number={this.filter_number}
              />
            </div>
            <div className="__application-room-profile-card-grid">
              {this.state.companies_with_rating.length > 0 &&
                this.state.companies_with_rating.map((company, index) => (
                  <Profile_Card
                    getStage={this.getStage}
                    previewSelectedCompany={this.previewSelectedCompany}
                    getAvatarUrl={this.getAvatarUrl}
                    getFileUrl={this.getFileUrl}
                    getBackgroundUrl={this.getBackgroundUrl}
                    company_owner={company.owner}
                    opportunity_data={this.props.opportunity_data} 
                    {...company}
                    index={index}
                  />
                ))}
            </div>
            {this.state.selected_company.length > 0 && (
              <ReviewsCards
                {...this.state}
                handleHide={this.handleHide}
                getAvatarUrl={this.getAvatarUrl}
                getBackgroundUrl={this.getBackgroundUrl}
                handleToggle={this.handleToggle}
                changeRating={this.changeRating}
                handleChange={this.handleChange}
                editReviewSubmit={this.editReviewSubmit}
                reviewSubmit={this.reviewSubmit}
                roundAdditionalCriteria={this.roundAdditionalCriteria}
                opportunity_data={this.props.opportunity_data}
                handleChangeAdditionalRating={this.handleChangeAdditionalRating}
              />
            )}
          </>
        ) : (
          <div className="__pb-page p-3">
            <div className={classnames("SocialLoadingIcon")}>
              <ThreeDotsIcon />
            </div>
          </div>
        )}
      </div>
    );
  }
}

Page.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default Page;
