import React, { Component } from "react";
import { Helmet } from "react-helmet";

class GoogleAnalytics extends Component {
  render() {
    const { trackingId } = this.props;
    return (
      <Helmet>
        {/* Global Site Tag (gtag.js) - Google Analytics */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-66503GWD53"
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
          
            gtag('config', 'G-66503GWD53');
          `}
        </script>
      </Helmet>
    );
  }
}

export default GoogleAnalytics;
