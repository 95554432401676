import React from 'react';
import logo from "../assets/logo.png";
import abtestingLogo from "../assets/1r7651j-abtesting_02z036000000000000001.png";
import rocketLogo from "../assets/1jws8sm-rocket_02z02z000000000000001.png";
import pencilLogo from "../assets/23md97-pencil_02x02w000000000000001.png";
import './HowItWorks.css'; 

const HowItWorks = props => (
    <div className='pitchHowItWorks'>
        <h3>How it Works?</h3>
        <div className='pitchHowItWorksDetails'>
            <div>
                <span><img src={logo} /></span>
                <h5>Setup</h5>
                Setup your Pitch Competition on Startupfuel.com & Build the Application Form
                </div>
            <div>
                <span><img src={abtestingLogo} /></span>
                <h5>Intake</h5>
                Your PitchBox is now live! Intake for your Competition Begins & you can Invite Judges
                </div>
            <div>
                <span><img src={rocketLogo} /></span>
                <h5>Marketing</h5>
                We can help Market your Pitch Competition & Promote your Live Event to Startups
                </div>
            <div>
                <span><img src={pencilLogo} /></span>
                <h5>Review</h5>
                Pitch Contest closes! Judges review/shortlist top startups & can use PitchBox for Live Event
            </div>
        </div>
    </div>
)

export default HowItWorks;