import React, { Component } from "react";
import axios from "axios";
import URL from "url-parse";
import { Success_Popup } from "../../../components/popups";
import Chip from "@material-ui/core/Chip";
import Select from "@material-ui/core/Select";
import Switch from "@material-ui/core/Switch";
import MenuItem from "@material-ui/core/MenuItem";
import ArgNav from "../argNav";
import Navigation from "../../../components/navbar_pb";
import SettingsNavigation from "./settings-sidenav";
import "./index.scss";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

class Page extends Component {
  constructor(props) {
    super(props);

    this.state = {
      url: new URL(window.location.href, true),
      judges: [1],
      showRelevancySettings: true,
      showOtherSettings: false,
      target_industries: [],
      target_model_companies: [],
      target_distinctions: [],
      target_stage_companies: [],
      geographical_location: [],
      start_intake_date: "",
      end_intake_date: "",
      website_url: "",
      opportunity_description: "",
      max_cheque_size: "",
      min_cheque_size: "",
      prize: "",
      venue_location: "",
      background_cover: null,
      has_applicant_room: false,
      show_success_popup: false,
      success_msg: "Pitchbox successfully updated",
      isSubmitting: false,
    };
  }

  componentDidMount() {
    axios
      .get(
        `fetch_opportunity?opportunity_id=${this.state.url.query.opportunity_id}`,
      )
      .then((response) => {
        this.setState({
          ...response.data.result,
          has_applicant_room: response.data.result.has_applicant_room || false,
          opportunity_data: response.data.result,
        });
      });
  }

  displaySaved = () => {
    this.setState(
      {
        show_success_popup: true,
        isSubmitting: false,
      },
      () => {
        setTimeout(() => this.setState({ show_success_popup: false }), 1500);
      },
    );
  };

  handleSwitchChange = () => {
    this.setState((prevState) => ({
      has_applicant_room: !prevState.has_applicant_room,
    }));
  };

  handleSingleSelectChange = (event, field) => {
    this.setState({
      [field]: [event.target.value],
    });
  };

  handleFileChange = (event) => {
    this.setState({
      background_cover: event.target.files[0],
    });
  };

  handleInputChange = (event, field) => {
    let value = event.target.value;

    // If the field is one of the date fields, convert it to ISO format
    const dateFields = ["start_intake_date", "end_intake_date"];
    if (dateFields.includes(field)) {
      value = new Date(value).toISOString();
    }

    this.setState({
      [field]: value,
    });
  };

  // Function to format date to 'YYYY-MM-DDTHH:MM'
  formatDateTimeLocal = (dateTime) => {
    if (!dateTime) return "";
    const date = new Date(dateTime);
    const offset = date.getTimezoneOffset();
    const adjustedDate = new Date(date.getTime() - offset * 60 * 1000);
    return adjustedDate.toISOString().slice(0, 16);
  };

  validateForm = () => {
    const {
      start_intake_date,
      end_intake_date,
      min_cheque_size,
      max_cheque_size,
    } = this.state;

    if (new Date(start_intake_date) > new Date(end_intake_date)) {
      alert("Application start date cannot be after the application end date.");
      return false;
    }

    if (parseFloat(min_cheque_size) > parseFloat(max_cheque_size)) {
      alert(
        "Minimum cheque size cannot be greater than the maximum cheque size.",
      );
      return false;
    }

    return true;
  };

  handleFileUpload = (file) => {
    const formData = new FormData();
    formData.append("file", file);

    axios
      .post(
        `/upload_opp_file?opp_id=${this.state.url.query.opportunity_id}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        },
      )
      .then((response) => {
        if (response.data.status === "success") {
          const backgroundCoverUrl = response.data.result.background_cover;
          this.setState(
            { background_cover: backgroundCoverUrl },
            this.handleSave,
          );
        } else {
          console.error("Error uploading file:", response.data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  handleSave = () => {
    if (!this.validateForm()) {
      return;
    }

    this.setState({ isSubmitting: true }); // Set loading state

    const { background_cover } = this.state;

    // Function to proceed with the rest of the form submission
    const submitForm = () => {
      const { url, background_cover } = this.state;

      // Clean up arrays by filtering out null or empty string values
      const cleanArray = (arr) => arr.filter((item) => item && item !== "");
      const cleanValue = (value) =>
        value !== null &&
        value !== undefined &&
        value !== "" &&
        (!Array.isArray(value) || value.length > 0);

      const data = {};
      if (cleanValue(this.state.opportunity_name)) {
        data.opportunity_name = this.state.opportunity_name;
      }
      if (cleanValue(this.state.website_url)) {
        data.website_url = this.state.website_url;
      }
      if (cleanValue(this.state.opportunity_description)) {
        data.opportunity_description = this.state.opportunity_description;
      }
      if (cleanValue(this.state.start_intake_date)) {
        data.start_intake_date = this.state.start_intake_date;
      }
      if (cleanValue(this.state.end_intake_date)) {
        data.end_intake_date = this.state.end_intake_date;
      }
      if (cleanValue(this.state.min_cheque_size)) {
        data.min_cheque_size = this.state.min_cheque_size;
      }
      if (cleanValue(this.state.max_cheque_size)) {
        data.max_cheque_size = this.state.max_cheque_size;
      }
      if (cleanValue(this.state.prize)) {
        data.prize = this.state.prize;
      }
      if (cleanValue(this.state.venue_location)) {
        data.venue_location = this.state.venue_location;
      }

      data.has_applicant_room = this.state.has_applicant_room;

      // Submit the data as JSON
      axios
        .post(
          `/edit_opportunity_data?opportunity_id=${url.query.opportunity_id}`,
          data,
          {
            headers: {
              "Content-Type": "application/json",
            },
          },
        )
        .then((response) => {
          if (response.data.status === "success") {
            this.displaySaved();
          } else {
            console.error("Error updating opportunity:", response.data.message);
          }
          this.setState({ isSubmitting: false }); // Reset loading state on success or error
        })
        .catch((error) => {
          console.error("Error:", error);
          this.setState({ isSubmitting: false }); // Reset loading state on error
        });
    };

    // Check if a file is selected for upload
    if (background_cover && typeof background_cover !== "string") {
      this.handleFileUpload(this.state.background_cover);
    } else {
      submitForm();
    }
  };

  render() {
    const {
      start_intake_date,
      end_intake_date,
      opportunity_name,
      website_url,
      opportunity_description,
      max_cheque_size,
      min_cheque_size,
      prize,
      venue_location,
      isSubmitting,
      background_cover,
      has_applicant_room,
    } = this.state;

    return (
      <div>
        <Navigation opportunity_data={this.state.opportunity_data} />
        <ArgNav
          opportunity_name={this.props.opportunity_name}
          opportunity_id={this.state.url.query.opportunity_id}
          pathname={this.state.url.pathname}
        />
        <div className="_content">
          <React.Fragment>
            <Success_Popup
              show={this.state.show_success_popup}
              alert_message={this.state.success_msg}
            />
            <div className="__application-room-settings">
              <div className="__header  __application-room-padding">
                <h3>Workspace Settings</h3>
              </div>
              <div className="__pb_settings_page">
                <SettingsNavigation />

                {/* Combined Section Wrapper */}
                <div className="__main __application-room-padding">
                  {/* Relevancy Settings */}

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "16px",
                    }}
                  >
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={this.state.has_applicant_room}
                            onChange={this.handleSwitchChange}
                            color="primary"
                          />
                        }
                        label="Allow Pitchbox Submissions"
                      />
                    </FormGroup>
                  </div>
                  <div style={{ marginBottom: "16px" }}>
                    <p
                      className="__settings_text"
                      style={{ marginBottom: "4px" }}
                    >
                      Workspace Name:
                    </p>
                    <input
                      type="text"
                      value={opportunity_name}
                      onChange={(event) =>
                        this.setState({
                          opportunity_name: event.target.value,
                        })
                      }
                      style={{ display: "block", width: "100%" }}
                      placeholder="Enter Workspace Name"
                    />
                    <p
                      className="__settings_text"
                      style={{ marginBottom: "4px" }}
                    >
                      Website URL:
                    </p>
                    <input
                      type="text"
                      value={website_url}
                      onChange={(event) =>
                        this.setState({
                          website_url: event.target.value,
                        })
                      }
                      style={{ display: "block", width: "100%" }}
                      placeholder="Enter Website URL"
                    />

                    <p
                      className="__settings_text"
                      style={{ marginBottom: "4px", marginTop: "16px" }}
                    >
                      Opportunity Description:
                    </p>
                    <textarea
                      value={opportunity_description}
                      onChange={(event) =>
                        this.setState({
                          opportunity_description: event.target.value,
                        })
                      }
                      style={{
                        display: "block",
                        width: "100%",
                        height: "100px",
                      }}
                      placeholder="Enter Opportunity Description"
                    />

                    <p
                      className="__settings_text"
                      style={{ marginBottom: "4px", marginTop: "16px" }}
                    >
                      Application Start Date:
                    </p>
                    <input
                      type="datetime-local"
                      value={this.formatDateTimeLocal(start_intake_date)}
                      onChange={(event) =>
                        this.handleInputChange(event, "start_intake_date")
                      }
                      style={{ display: "block", width: "100%" }}
                    />

                    <p
                      className="__settings_text"
                      style={{ marginBottom: "4px", marginTop: "16px" }}
                    >
                      Application End Date:
                    </p>
                    <input
                      type="datetime-local"
                      value={this.formatDateTimeLocal(end_intake_date)}
                      onChange={(event) =>
                        this.handleInputChange(event, "end_intake_date")
                      }
                      style={{ display: "block", width: "100%" }}
                    />
                  </div>

                  <div style={{ marginBottom: "16px" }}>
                    <p
                      className="__settings_text"
                      style={{ marginBottom: "4px", marginTop: "16px" }}
                    >
                      Minimum Check Size:
                    </p>
                    <input
                      type="number"
                      value={min_cheque_size}
                      onChange={(event) =>
                        this.handleInputChange(event, "min_cheque_size")
                      }
                      style={{ display: "block", width: "100%" }}
                      placeholder="Enter Minimum Check Size"
                    />
                  </div>

                  <div style={{ marginBottom: "16px" }}>
                    <p
                      className="__settings_text"
                      style={{ marginBottom: "4px" }}
                    >
                      Maximum Check Size:
                    </p>
                    <input
                      type="number"
                      value={max_cheque_size}
                      onChange={(event) =>
                        this.handleInputChange(event, "max_cheque_size")
                      }
                      style={{ display: "block", width: "100%" }}
                      placeholder="Enter Maximum Check Size"
                    />
                  </div>

                  <div style={{ marginBottom: "16px" }}>
                    <p
                      className="__settings_text"
                      style={{ marginBottom: "4px" }}
                    >
                      Benefits:
                    </p>
                    <input
                      type="text"
                      value={prize}
                      onChange={(event) =>
                        this.handleInputChange(event, "prize")
                      }
                      style={{ display: "block", width: "100%" }}
                      placeholder="Enter Prize"
                    />
                  </div>

                  <div style={{ marginBottom: "16px" }}>
                    <p
                      className="__settings_text"
                      style={{ marginBottom: "4px" }}
                    >
                      Venue Location:
                    </p>
                    <input
                      type="text"
                      value={venue_location}
                      onChange={(event) =>
                        this.handleInputChange(event, "venue_location")
                      }
                      style={{ display: "block", width: "100%" }}
                      placeholder="Enter Venue Location"
                    />
                  </div>

                  <div style={{ marginBottom: "16px" }}>
                    <p
                      className="__settings_text"
                      style={{ marginBottom: "4px" }}
                    >
                      Background Cover Image:
                    </p>
                    {typeof background_cover === "string" &&
                      !(
                        background_cover instanceof File ||
                        background_cover instanceof Blob
                      ) &&
                      background_cover && (
                        <div style={{ marginBottom: "16px" }}>
                          <img
                            src={background_cover}
                            alt="Background Cover"
                            style={{
                              maxWidth: "100%",
                              height: "auto",
                              marginBottom: "8px",
                            }}
                          />
                        </div>
                      )}
                    <input
                      type="file"
                      onChange={this.handleFileChange}
                      style={{ display: "block", width: "100%" }}
                    />
                  </div>

                  {/* Save Button */}
                  <button
                    onClick={this.handleSave}
                    disabled={isSubmitting}
                    style={{
                      marginTop: "20px",
                      padding: "10px 20px",
                      backgroundColor: "#007bff",
                      color: "white",
                      border: "none",
                      borderRadius: "4px",
                      cursor: isSubmitting ? "not-allowed" : "pointer",
                      width: "100%",
                    }}
                  >
                    {isSubmitting ? "Saving..." : "Save"}
                  </button>
                </div>
              </div>
            </div>
          </React.Fragment>
        </div>
      </div>
    );
  }
}

export default Page;
