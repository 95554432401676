/* (2019-06-11) - This is the settings tab, as of right now it only allows you to
select whether or not weekly opportunities should be sent out or if you want
to delete your company.
*/
import React from "react";
import axios from "axios";
import update from "immutability-helper";
import { SocialIcon } from "react-social-icons";
import {
  Navbar,
  Nav,
  Row,
  Col,
  Card,
  Button,
  ButtonToolbar,
  Container,
  FormLabel,
  Form,
} from "react-bootstrap";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import "../../components/profile.css";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Checkbox from "@material-ui/core/Checkbox";

import { Helmet } from "react-helmet";
import URL from "url-parse";
import default_pic from "../../../img/default_company_pic.png";
import default_background from "../../../img/bg.png";
import rocket from "../../../img/rocketlaunch.jpg";
import StartupFuel from "../../../img/watermarksffinal.png";
import Loader from "../../components/loader";
import {
  Profile_Photo_Update,
  Cover_Photo_Update,
  Company_Update,
  Sign_In,
  Register,
} from "../../components/popups";
import {
  country_list,
  opportunity_type_list,
  industry_list,
  stage_list,
  business_type,
  company_type_list,
  goals,
} from "../../../boilerplate/lists";
import SiteTitle from "../../components/site_title.js";

import waterfall from "async/waterfall";

import { AppContext } from "../../../App";
import Navigation from "../../components/navbar";
import Footer from "./../../components/footer";
import CompanyNavBar from "./nav_bar";
import moment from "moment";
import { Modal } from "react-bootstrap";
import { Map, GoogleApiWrapper, InfoWindow, Marker } from "google-maps-react";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function getStyles(name, that) {
  return {
    fontWeight:
      that.state.name.indexOf(name) === -1
        ? that.props.theme.typography.fontWeightRegular
        : that.props.theme.typography.fontWeightMedium,
  };
}
const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: theme.spacing.unit / 4,
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 100,
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 100,
  },
});

class CustomizedTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: new URL(window.location.href, true),
      scores_views: [],
      isOpen: false,
      score_item: {},
    };
  }

  openModal = () => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false });

  handleAllowInvestor = (e) => {
    this.props.handleInvestorChange(e);
    this.props.handleAlertPopup();
  };

  delete_score = (e) => {
    axios
      .post(
        `/delete_fundability_score?affiliated_company=${this.state.score_item.affiliated_company}&&_id=${this.state.score_item._id}`
      )
      .then((response) => {
        console.log("delete score", response);
        if (response.data.status == "deleted") {
          axios
            .get(
              `/fetch_fundability_scores_owned?company_id=${this.state.url.query.company_id}`
            )
            .then((response) => {
              console.log("get list of scores", response.data.result);
              this.setState({
                scores_views: response.data.result,
                isOpen: false,
              });
            });
        }
      });
  };

  componentDidMount() {
    axios
      .get(
        `/fetch_fundability_scores_owned?company_id=${this.state.url.query.company_id}`
      )
      .then((response) => {
        console.log("get list of scores", response.data.result);
        this.setState({
          scores_views: response.data.result,
        });
      });
  }

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Modal show={this.state.isOpen} onHide={this.closeModal}>
          <Modal.Header closeButton style={{ background: "#3f51b5" }} />
          <Modal.Body>
            <div>
              <h4 style={{ fontWeight: "bold" }}>CFA Self-Assessment Report</h4>
              <span> ratings: </span>
              {this.state.score_item.ratings == 5 && (
                <>
                  <i
                    style={{ color: "#3f51b5", fontSize: "35px" }}
                    className="fa fa-star"
                  ></i>
                  <i
                    style={{ color: "#3f51b5", fontSize: "35px" }}
                    className="fa fa-star"
                  ></i>
                  <i
                    style={{ color: "#3f51b5", fontSize: "35px" }}
                    className="fa fa-star"
                  ></i>
                  <i
                    style={{ color: "#3f51b5", fontSize: "35px" }}
                    className="fa fa-star"
                  ></i>
                  <i
                    style={{ color: "#3f51b5", fontSize: "35px" }}
                    className="fa fa-star"
                  ></i>
                </>
              )}
              {this.state.score_item.ratings == 4 && (
                <>
                  <i
                    style={{ color: "#3f51b5", fontSize: "35px" }}
                    className="fa fa-star"
                  ></i>
                  <i
                    style={{ color: "#3f51b5", fontSize: "35px" }}
                    className="fa fa-star"
                  ></i>
                  <i
                    style={{ color: "#3f51b5", fontSize: "35px" }}
                    className="fa fa-star"
                  ></i>
                  <i
                    style={{ color: "#3f51b5", fontSize: "35px" }}
                    className="fa fa-star"
                  ></i>

                  <i
                    style={{ color: "#3f51b5", fontSize: "35px" }}
                    className="fa fa-star-o"
                  ></i>
                </>
              )}
              {this.state.score_item.ratings == 3 && (
                <>
                  <i
                    style={{ color: "#3f51b5", fontSize: "35px" }}
                    className="fa fa-star"
                  ></i>
                  <i
                    style={{ color: "#3f51b5", fontSize: "35px" }}
                    className="fa fa-star"
                  ></i>
                  <i
                    style={{ color: "#3f51b5", fontSize: "35px" }}
                    className="fa fa-star"
                  ></i>

                  <i
                    style={{ color: "#3f51b5", fontSize: "35px" }}
                    className="fa fa-star-o"
                  ></i>

                  <i
                    style={{ color: "#3f51b5", fontSize: "35px" }}
                    className="fa fa-star-o"
                  ></i>
                </>
              )}
              {this.state.score_item.ratings == 2 && (
                <>
                  <i
                    style={{ color: "#3f51b5", fontSize: "35px" }}
                    className="fa fa-star"
                  ></i>
                  <i
                    style={{ color: "#3f51b5", fontSize: "35px" }}
                    className="fa fa-star"
                  ></i>

                  <i
                    style={{ color: "#3f51b5", fontSize: "35px" }}
                    className="fa fa-star-o"
                  ></i>

                  <i
                    style={{ color: "#3f51b5", fontSize: "35px" }}
                    className="fa fa-star-o"
                  ></i>

                  <i
                    style={{ color: "#3f51b5", fontSize: "35px" }}
                    className="fa fa-star-o"
                  ></i>
                </>
              )}
              {this.state.score_item.ratings == 1 && (
                <>
                  <i
                    style={{ color: "#3f51b5", fontSize: "35px" }}
                    className="fa fa-star"
                  ></i>

                  <i
                    style={{ color: "#3f51b5", fontSize: "35px" }}
                    className="fa fa-star-o"
                  ></i>

                  <i
                    style={{ color: "#3f51b5", fontSize: "35px" }}
                    className="fa fa-star-o"
                  ></i>

                  <i
                    style={{ color: "#3f51b5", fontSize: "35px" }}
                    className="fa fa-star-o"
                  ></i>

                  <i
                    style={{ color: "#3f51b5", fontSize: "35px" }}
                    className="fa fa-star-o"
                  ></i>
                </>
              )}
              <br />
              <span style={{ fontWeight: "bold" }}>
                {" "}
                1.Product - Performance Advantage
              </span>
              <br />
              Does your proposed product or service offer performance advantages
              compare to currently deployed solutions?
              <br />
              A) There are substantial advantages to our product or service over
              current deployed solutions, or there is no one yet offering a
              comparable solution.
              <br />
              B) Our solution has measurable benefits over current deployed
              solutions or offers substantive advantages in a market niche.
              <br />
              C) Our solution meets the performance of current deployed
              solutions at a competitive cost.
              <br />
              Answer:{" "}
              <span style={{ color: "red", fontWeight: "bold" }}>
                {this.state.score_item.question_one_answer}
              </span>
              <br />
              <br />
              <span style={{ fontWeight: "bold" }}>
                {" "}
                2.Product – Market Readiness
              </span>
              <br />
              Question: How far away are you from being able to deliver
              completed products or services to your first revenue customer?
              <br />
              A) The product or service offering is finished, customer beta
              tests completed and manufacturing/supply issues have been
              addressed. Some initial sales may have been obtained.
              <br />
              B) The product or service has been tested in and performs to
              expectation. Final sales require further work on testing,
              approvals or supply chain issues. No sales yet.
              <br />
              C) The concept of the product or service has been completed, but
              further research is needed to develop the product/service or
              address critical supply chain issues.
              <br />
              Answer:{" "}
              <span style={{ color: "red", fontWeight: "bold" }}>
                {this.state.score_item.question_two_answer}
              </span>
              <br />
              <br />
              <span style={{ fontWeight: "bold" }}>
                {" "}
                3.Product – Uniqueness
              </span>
              <br />
              What is unique or patentable about your product that represents a
              barrier to entry for potential competitors?
              <br />
              A) The product or service has received a patent, or embeds
              proprietary technology or knowledge that is not easily replicated.
              Alternatively, the product or service has a unique feature (such
              as a brand) that creates a significant barrier to competitors.
              <br />
              B) There is a level of unique knowledge or know how deployed in
              the product or service, which might be patentable (or is patent
              pending). Or there is another unique property that makes it
              challenging, but not impossible for others to replicate.
              <br />
              C) The product or service is innovative, but there are limited
              formal mechanisms to reduce the likelihood that competitors will
              replicate key features in the future.
              <br />
              Answer:{" "}
              <span style={{ color: "red", fontWeight: "bold" }}>
                {this.state.score_item.question_three_answer}
              </span>
              <br />
              <br />
              <span style={{ fontWeight: "bold" }}>
                {" "}
                4. Market – First Customer
              </span>
              <br />
              Can you demonstrate that customers in your target market will
              purchase your product or service when it is available?
              <br />
              A) Customers have been intimately involved in the development
              process, and have committed to either purchase or try the
              product/service as soon as it becomes available.
              <br />
              B) Market research has confirmed a specific market for the product
              or service, but to-date no commitments have been made to test or
              purchase the product.
              <br />
              C) At present, there is no independent market validation of the
              product or service and no first customers have agreed to test or
              purchase it.
              <br />
              Answer:{" "}
              <span style={{ color: "red", fontWeight: "bold" }}>
                {this.state.score_item.question_four_answer}
              </span>
              <br />
              <br />
              <span style={{ fontWeight: "bold" }}>
                {" "}
                5.Market – Distribution
              </span>
              <br />
              Can you provide confirmation that there are no success barriers
              either about your supply chain, or distribution channel?
              <br />
              A) Suppliers and channel partners have been engaged in the
              development process, and have made firm commitments to participate
              as soon as the product or service is ready for market.
              <br />
              B) Possible channel and supply chain partners have been identified
              and initial discussions held. While interested, there are no
              formal agreements in place with these partners.
              <br />
              C) At this point, although identified, no distribution or supply
              chain partners have yet been approached.
              <br />
              Answer:{" "}
              <span style={{ color: "red", fontWeight: "bold" }}>
                {this.state.score_item.question_five_answer}
              </span>
              <br />
              <br />
              <span style={{ fontWeight: "bold" }}>
                {" "}
                6.Market – Market Size
              </span>
              <br />
              Is the overall size of the market and your likely market share,
              sufficient to generate the envisaged revenues? Further, is the
              overall market forecast to be large enough to be interesting?
              <br />
              A) There is direct evidence that the market potential for your
              product or service is large, and that you can achieve enough
              market share to achieve a sales target of over $20 million.
              <br />
              B) There is some evidence that the market potential for your
              product or service is quite large, and that you can achieve enough
              market share to achieve a sales target of over $5 million.
              <br />
              C) There is no direct evidence to support the overall size of your
              market, or to validate your likely market share. As a result, it
              is difficult to forecast with confidence that revenue will exceed
              $1 million.
              <br />
              Answer:{" "}
              <span style={{ color: "red", fontWeight: "bold" }}>
                {this.state.score_item.question_six_answer}
              </span>
              <br />
              <br />
              <span style={{ fontWeight: "bold" }}> 7.The Entrepreneur</span>
              <br />
              Do you or members of your team have any direct or relevant
              (entrepreneurial, industry, business) experience that can be
              directly applied to the challenges facing this business?
              <br />
              A) You or members of the management team have deep and significant
              relevant experience that will enable you to operate the business
              successfully. This experience enabled or encouraged you to start
              the business.
              <br />
              B) You or members of the management team have significant business
              (or equivalent, i.e. military) experience, which although not
              directly relevant, will help you to operate the business.
              <br />
              C) Your experience is primarily technical, or limited, and
              provides no direct evidence that it will help you meet the
              challenges of operating the business.
              <br />
              Answer:{" "}
              <span style={{ color: "red", fontWeight: "bold" }}>
                {this.state.score_item.question_seven_answer}
              </span>
              <br />
              <br />
              <span style={{ fontWeight: "bold" }}> 8.The Financial Plan</span>
              <br />
              Do your financial projections present a persuasive argument that
              your company can achieve cash-flow neutrality, based on your own
              investment, money you can borrow, and money you can raise from
              external investors?
              <br />
              A) You show high degree of confidence that revenues/profit margins
              forecast or external funding identified, helps you achieve cash
              flow neutrality within 12 months, either directly, or by enabling
              you to raise additional cash after 12 months.
              <br />
              B) You show a reasonable degree of confidence that revenues/profit
              margins forecast or external funding identified, helps you achieve
              cash flow neutrality within 24 months, either directly, or by
              enabling you to raise additional cash after 24 months.
              <br />
              C) You do not yet have a financial projection that provides this
              level of detail, or it shows that you will be in a negative cash
              flow position over the next 24 months, with limited confidence
              that after 24 months additional cash can be raised.
              <br />
              Answer:{" "}
              <span style={{ color: "red", fontWeight: "bold" }}>
                {this.state.score_item.question_eight_answer}
              </span>
            </div>
            <br />

            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                onClick={() => {
                  this.setState({
                    isOpen: false,
                  });
                }}
                id="buttons-grid-opp-button"
                variant="contained"
                color="primary"
                style={{ background: "#3f51b5", fontWeight: "bolder" }}
                xs={12}
              >
                Close
              </Button>

              <Button
                onClick={this.delete_score}
                id="buttons-grid-opp-button"
                variant="contained"
                color="primary"
                style={{ background: "red", fontWeight: "bolder" }}
                xs={12}
              >
                Delete
              </Button>
            </div>
          </Modal.Body>
        </Modal>
        {this.props.userIsProfileOwner && (
          <div>
            <div style={{ width: "100%" }}>
              <CompanyNavBar
                menuSelection={this.props.menuSelection}
                handleMenuChange={this.props.handleMenuChange}
                company_type={this.props.company_type}
                allow_investor={this.props.allow_investor}
                userIsProfileOwner={this.props.userIsProfileOwner}
              />
              <div
                style={{
                  backgroundColor: "unset",
                  margin: "auto",
                  width: "100%",
                  paddingBottom: "24px",
                  marginTop: "20px",
                }}
              >
                <h2 style={{ textAlign: "center" }}>
                  Critical Factor Assessment
                </h2>

                {this.state.scores_views.length > 0 ? (
                  <>
                    {this.state.scores_views.map((scores_view) => (
                      <div
                        //   class="container-team"
                        style={{
                          display: "flex",
                          color: "#729fcf",
                        }}
                        onClick={() => {
                          this.setState({
                            isOpen: true,
                            score_item: scores_view,
                          });
                        }}
                      >
                        <h6>{this.props.company_name}</h6>
                        <h6 style={{ marginLeft: "6px" }}>
                          -{" "}
                          {moment(scores_view.created_on).format(
                            "MMMM Do YYYY, h:mm:ss a"
                          )}
                        </h6>

                        <h6 style={{ marginLeft: "6px" }}>
                          - Rating: {scores_view.ratings}
                        </h6>
                        <br />
                      </div>
                    ))}
                  </>
                ) : (
                  <p style={{ textAlign: "center", width: "100%" }}>
                    No Critical Factor Assessment found.
                  </p>
                )}
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}
CustomizedTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CustomizedTable);
