import React, { useEffect, useRef, useState } from 'react';
import './MainNav.css';
import logo from '../assets/logo.png';
import CommunityLinks from './CommunityLinks/CommunityLinks';
import NavLinks from './NavLinks';
import MenuIcon from '../../newsfeed/icons/MenuIcon';
import CloseIcon from '../../newsfeed/icons/CloseIcon';

const MainNav = (props) => {
    const [drop, setDrop] = useState({class: ''})
    let MainNav = useRef();
    let stickNav = () => {
        if (window.scrollY <= 70) {
            let top = 70 - window.scrollY;
            MainNav.current.style.top = top + 'px';
        } else {
            MainNav.current.style.top = 0;
        }
    }
    useEffect(() => {
        stickNav();
        window.addEventListener('scroll', stickNav);
    }, [])
    const dropHandler = () => {
        drop.class === '' ? setDrop({class: 'OlympusDropShow'}) : setDrop({class: ''});
    }
    return (
        <div className='MainNavOlympus'>
            <div className='MainNavOlympusChild' ref={MainNav}>
                <div className='OlympusNavMain'>
                    <div className='OlympusLogo'>
                        <img src={logo} />
                        <p>
                            OLYMPUS
                        <div>BuddyPress Social Network</div>
                        </p>
                        {drop.class === '' ? <MenuIcon onClick={dropHandler} /> : <CloseIcon onClick={dropHandler} />}
                    </div>
                    <div className={'OlypusAllLinksNavMain ' + drop.class}>
                        <div className='OlypusAllLinksNav'>
                            <div className='OlympicsNavLink'>
                                <div className='OlympicsNavLinkInner'>
                                    <a href='#'>Home</a>
                                </div>
                            </div>
                            <div className='OlympicsNavLink'>
                                
                                <CommunityLinks />
                            </div>
                            <NavLinks title='Pages'>
                                <a href='#'>About Us</a>
                                <a href='#'>Contacts</a>
                                <a href='#'>Frequently Asked Questions</a>
                            </NavLinks>
                            <NavLinks title='Blog'>
                                <a href='#'>Blog Grid</a>
                                <a href='#'>Blog Masonary</a>
                                <a href='#'>Blog List</a>
                                <a href='#'>Blog Classic</a>
                                <a href='#'>Post version 1</a>
                                <a href='#'>Post version 2</a>
                                <a href='#'>Post version 3</a>
                            </NavLinks>
                            <NavLinks title='Events'>
                                <a href='#'>Events Grid</a>
                                <a href='#'>Events List</a>
                                <a href='#'>Events Day</a>
                                <a href='#'>Events Inner page</a>
                            </NavLinks>
                            <NavLinks title='Shop'>
                                <a href='#'>Shop page</a>
                                <a href='#'>Single product page</a>
                                <a href='#'>Product category page</a>
                            </NavLinks>
                            <div className='OlympusNavBtn'>Purchase Theme</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MainNav;