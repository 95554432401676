/* (2019-06-11) - The card that is displayed to represent the companies on
the explore page.
*/
import React from 'react';
import classnames from 'classnames';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import DoneIcon from '@material-ui/icons/Done';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CardActionArea from '@material-ui/core/CardActionArea';
import Typography from '@material-ui/core/Typography';
import ProfileImage from '../../live/components/profile_image';
import Moment from "moment";
import SocialAPI from '../api/social';

class SimpleExploreCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_following: false,
    };
  }

  handleFollow = (e) => {
    e.preventDefault();
    this.setState({ follow_loading: true }, () => {
      if (!this.state.is_following) {
        SocialAPI.follow(this.props._id)
          .then((response) => {
            this.setState({
              follow_loading: false,
              is_following: true
            }, this.props.handleFollow);
          }).catch((err) => {
            this.setState({
              follow_loading: false
            });
          });
      }
    });
  }

  render() {
    return (
      <Card className="simpleExploreCard">
        <CardHeader
          avatar={ <ProfileImage
                     className="large tight"
                     company_id={ this.props._id } /> }
          title={ (
            <a
              target="_blank"
              href={ '/profile?company_id=' + this.props._id }>
                <Typography variant="h6">
                  { this.props.company_name } </Typography>
              </a>
            ) }
          subheader={ "#" + this.props.company_type }
          action={ <CardActions
                     disableSpacing
                     style={ { paddingLeft: 0 } }>
                     <Tooltip
                       placement="top"
                       className={ this.state.is_following ? "UbaidUnfollow" : "ubaidFollow"}
                       title={ this.state.is_following ? "Followed" : "Follow" }
                       aria-label={ this.state.is_following ? "followed" : "follow" }>
                       <IconButton
                         onClick={ this.handleFollow.bind(this) }
                         size="small">
                         { this.state.is_following ? <DoneIcon /> : <AddIcon /> }
                       </IconButton>
                     </Tooltip>
                   </CardActions> } />
        <CardContent>
            <div className="cardContent fadeOverflow">
            { this.props.short_bio || "No description available" }
          </div>
        </CardContent>
      </Card>
      );
  }
}
export default SimpleExploreCard;
