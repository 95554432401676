import React from 'react';

const GroupIcon = props => (
    <svg>
        <path d="M9,4c1.654,0,3,1.346,3,3s-1.346,3-3,3S6,8.654,6,7S7.346,4,9,4 M9,2C6.239,2,4,4.239,4,7
        c0,2.762,2.239,5,5,5c2.762,0,5-2.238,5-5C14,4.239,11.762,2,9,2L9,2z"></path>
        <path d="M12,14H6c-2.209,0-4,1.791-4,4v4h4v-2H4v-2c0-1.104,0.897-2,2-2h6c1.104,0,2,0.896,2,2v2h-2v2h4v-4
        C16,15.791,14.209,14,12,14z"></path>
        <path d="M16,2.101v2.083C17.161,4.599,18,5.698,18,7s-0.839,2.401-2,2.816v2.083c2.282-0.463,4-2.48,4-4.899
        S18.282,2.564,16,2.101z"></path>
        <path d="M18,14v2c1.104,0,2,0.896,2,2v2h-2v2h4v-4C22,15.791,20.209,14,18,14z"></path>
    </svg>
)

export default GroupIcon;