import React, { Component } from 'react';
import axios from 'axios'
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import classnames from 'classnames';
import Button from '@material-ui/core/Button';
import CardMedia from '@material-ui/core/CardMedia';
import default_background from '../../img/bg.png'
import Avatar from '@material-ui/core/Avatar';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import ShareIcon from '@material-ui/icons/Share';
import PeopleIcon from '@material-ui/icons/People';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import dummyWallpaper from '../Images/landing-bg.jpg';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Chip from '@material-ui/core/Chip';

const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#e0e0e0',
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);
const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  input: {
    display: 'none',
  },
  root: {
    marginBottom: theme.spacing.unit * 2,
  },
  header: {
    overflow: 'hidden',

  },
  chip: {
    margin: theme.spacing.unit,
  },
  CardHeight: {
    height: 210,
    overflow: 'hidden',
    fontSize: '16px'
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  actions: {
    display: 'flex',
  },
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    marginLeft: '0',
    [theme.breakpoints.up('sm')]: {
      marginRight: -8,
    },
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  type: {
    fontSize: '1em',
    color: 'red',
    textAlign: 'center',
    fontWeight: 0,
  },
  footer: {
    display: 'flex',
  },
  title: {
    fontSize: '1rem',
    overflow: 'hidden',
    height: 25
  },
  grow: {
    flexGrow: 1,
  },
});
class Advertisement_Card extends Component {
  state = {
    expanded: false,
  }
  handleExpandClick = () => {
    this.setState(state => ({ expanded: !state.expanded }));
  }
  componentDidMount() {
    axios.get('/newsfeed_advertisement').then((response) => {
      if (response.data.status === "success") {
        this.setState({
          service: response.data.service
        })
      }
    })
  }
  getImage = (array, type) => {
    if (array?.length > 0) {
      let img_obj = array.filter((x) => x.file_name === type)
      if (img_obj.length > 0) {
        return img_obj[0].file_url
      } else {
        return default_background
      }
    }
    else {
      return default_background
    }

  }
  render() {
    const { classes } = this.props;
    return (
      <>
          <Card className="rounded mb-3"style={{ boxShadow: "unset" }}>
            <CardActionArea href='https://startupfuel.com/products/memo'>
            <CardMedia
              component="img"
              image="https://startupfuel.com/cdn/shop/files/DealMemo-LVL2-SFReport_610x_crop_center.png?v=1684113697"
              alt="Startup Deal Memo"
            />
            </CardActionArea>
            <Grid className={classes.footer}>
              <Grid container direction="row" justify="left" alignItems="left">
                <div className="p-4"style ={{fontSize: '14px'}}>StartupFuel Deal Memo Reports can be purchased to evaluate and benchmark the startup. Click above to learn more!</div>
              </Grid>
            </Grid>
          </Card>
        {/* {this.state.service !== undefined &&
          <Card style={{ boxShadow: "unset", marginBottom: '64px' }}>

            <div className='exploreCardHeader'>
              <div style={{ backgroundImage: 'url(' + this.getImage(this.state.service.service_company_files, "profile avatar") + ')' }} className='exploreCardAvtar'></div>
              <div className='exploreCardTitle'>
                <a target="_blank" href={this.state.service.service_link}>{this.state.service.title_of_service}</a>
                {this.state.service.type_of_consultant + " Service"}
              </div>
              <CardActions disableSpacing style={{ paddingLeft: 0 }}>
              </CardActions>
            </div>
            <CardActionArea href='#'>
              <CardMedia
                className={classes.media}
                image={this.getImage(this.state.service.service_company_files, "service avatar")}
                title="background_picture"
              />
            </CardActionArea>
            <CardContent className='exploreCardContent OopSerToolCardContent'>
              {this.state.service.description_of_service}
            </CardContent>
            <Grid className={classes.footer}>
              <Grid container direction="row" justify="center" alignItems="flex-start" className="col-3">

              </Grid>
              <Grid container direction="row" justify="center" alignItems="center">
                <div style={{fontSize: '16px'}}><em>${this.state.service.service_price}{this.state.service.pricing_model === "Hourly" && "/hr"}{this.state.service.pricing_model === "Monthly" && "/month"}{this.state.service.pricing_model === "Yearly" && "/year"}</em></div>
              </Grid>
              <Grid container direction="row" justify="center" alignItems="flex-end" className="col-4">
                <Button variant="contained" color="primary" target="_blank" href={this.state.service.service_link.includes('http') ? this.state.service.service_link : `http://${this.state.service.service_link}`} target="_blank" className={classes.button} size="small">
                  VIEW
            </Button>
              </Grid>
            </Grid>
          </Card>
        } */}
      </>
    )
  }
}

export default withStyles(styles)(Advertisement_Card);
