/* (2019-06-11) - This is the settings page of the SIR/Application room, it
allows you to configure various settings for the SIR. A lot of the logic is
handled on other pages, this mainly just accepts the checkbox.
*/
import React, { Component, Fragment } from "react";
import axios from "axios";
import URL from "url-parse";
import "./index.scss";
import StarRatings from "react-star-ratings";
import { ProgressBar, Row, Col } from "react-bootstrap";
import { FormControl, FormLabel, Button, Form } from "react-bootstrap";
import Comment from "./../comment/Comment";
import Grid from "@material-ui/core/Grid";
import default_pic from "../../../../img/default_company_pic.png";
import default_background from "../../../../img/bg.png";
import {
  company_type_list,
  industry_list,
  stage_list,
  business_type,
} from "../../../../boilerplate/list.json";
import { Success_Popup } from "../../../components/popups";

import SortBy from "../../../components/explore_sort_filter";

import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PhoneIcon from "@material-ui/icons/Visibility";
import FavoriteIcon from "@material-ui/icons/Star";
import PersonPinIcon from "@material-ui/icons/StarBorder";
import Paper from "@material-ui/core/Paper";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import ArgNav from "../argNav";



const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
});

class Page extends Component {
  constructor(props) {
    super(props);

    this.state = {
      url: new URL(window.location.href, true),
      judges_can_register: false,
      users_can_see_feedback: false,
      success_msg: "Saved!",
      show_success_popup: false,
      judges_get_email: false,
      opportunity_private: false,
      has_applicant_registration_email: false,
      applicant_registration_email: {
        header: "Thank you for applying!",
        body: `We appreciate you taking the time to apply to our application.
              We will get back to your shortly regarding how you did.
              `,
      },
      opportunity_form_modify: false,
      custom_text_q1: "",
      custom_text_q2: "",
      opportunity_form: [],
      addTeamMember: false,
    };
  }
  handleToggle = () =>
    this.setState({ addTeamMember: !this.state.addTeamMember }); 
    componentDidMount() {
      axios
        .get(
          `fetch_opportunity?opportunity_id=${this.state.url.query.opportunity_id}`
        )
        .then((response) => {
          this.setState({
            ...response.data.result,
          });
          console.log("this.state");
          console.log(this.state);
        });
      axios
        .get(
          `/fetch_optional_questions?opportunity_id=${this.state.url.query.opportunity_id}`
        )
        .then((response) => {
          this.setState({
            ...response.data.existing_opportunity_form,
          });
        });
  }

  displaySaved = () => {
    this.setState(
      {
        show_success_popup: true,
      },
      () => {
        setTimeout(() => this.setState({ show_success_popup: false }), 1500);
      }
    );
  };

  handleChange = (e) => {
    const name = e.target.name;
    this.setState({
      [name]: e.target.value,
    });
  };
  handleEmailChange = (e) => {
    const name = e.target.name;
    this.setState({
      applicant_registration_email: {
        ...this.state.applicant_registration_email,
        [name]: e.target.value,
      },
    });
  };

  handleCheck = (e) => {
    const name = e.target.name;
    this.setState(
      {
        [name]: !this.state[name],
      },
      () => {
        axios.post(
          `/edit_opportunity_settings?opportunity_id=${this.state.url.query.opportunity_id}`,
          {
            judges_can_register: this.state.judges_can_register,
            users_can_see_feedback: this.state.users_can_see_feedback,
            judges_get_email: this.state.judges_get_email,
            opportunity_private: this.state.opportunity_private,
            opportunity_form_modify: this.state.opportunity_form_modify,
            has_applicant_registration_email:
              this.state.has_applicant_registration_email,
          }
        );
        this.displaySaved();
      }
    );
  };
  handleCustomForm = (e, key) => {
    const name = e.target.name;
    let target_key = this.state.opportunity_form[key];
    if (
      name === "display" &&
      this.state.opportunity_form[key]["display"] === true
    ) {
      let obj = {
        ...this.state.opportunity_form[key],
        ["required"]: false,
        ["display"]: false,
      };
      this.setState({
        opportunity_form: { ...this.state.opportunity_form, [key]: obj },
      });
    } else {
      let obj = {
        ...this.state.opportunity_form[key],
        [name]: !this.state.opportunity_form[key][name],
      };
      this.setState({
        opportunity_form: { ...this.state.opportunity_form, [key]: obj },
      });
    }
  };
  handleFormSave = (e) => {
    axios.post(
      `/edit_opportunity_form?opportunity_id=${this.state.url.query.opportunity_id}`,
      {
        opportunity_form: this.state.opportunity_form,
      }
    );
    this.displaySaved();
  };
  handleEmailSave = (e) => {
    axios.post(
      `/edit_opportunity_email?opportunity_id=${this.state.url.query.opportunity_id}`,
      {
        applicant_registration_email: {
          header: this.state.applicant_registration_email.header,
          body: this.state.applicant_registration_email.body,
        },
      }
    );
    this.displaySaved();
  };

  handleSave = (e) => {
    axios.post(
      `/create_optional_questions?opportunity_id=${this.state.url.query.opportunity_id}`,
      {
        custom_text_q1: this.state.custom_text_q1,
        custom_text_q2: this.state.custom_text_q2,
      }
    );
  };

  render() {
    return (
      <React.Fragment>
        <Success_Popup
          show={this.state.show_success_popup}
          alert_message={this.state.success_msg}
        />
        <ArgNav
          opportunity_name = {this.state.opportunity_name}
          opportunity_id = {this.state.url.query.opportunity_id}
          pathname={this.state.url.pathname} />
                <div className='__content'>       
        <div className="__team-management-page __referal-partners-page">
          <div className="__header " style={{ display: "revert" }}>
            <div
              className="__application-room-padding"
              style={{ display: "block", width: "100%" }}
            >
              <h3 className="__title">
                Network Partners
              </h3>
              <Button className="btn btn-md float-right" variant="primary" onClick={this.handleToggle}>
                  &#43; Add Partner
                </Button>
            </div>
          </div>
          <div className="__table-root-parent __application-room-padding __application-room-margin">
            <div>Email</div>
            <div>Display Name</div>
            <div># of Signups</div>
            {this.props.referral_partners.map((row) => (
              <Fragment>
                <div>
                  {row.email === undefined
                    ? row.referral_user_email
                    : row.email}
                </div>
                <div>{row.first_name + " " + row.last_name}</div>
                <div>{row.number}</div>
              </Fragment>
            ))}
          </div>
        </div>
        </div> 
      </React.Fragment>
    );
  }
}

Page.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Page);
/*
<Navbar className="navbar-top navbar-horizontal navbar-dark arg-navbar-secondary" expand="sm">
                  <Container className="px-4">
                    <button className="navbar-toggler" id="navbar-collapse-main">
                      <span className="navbar-toggler-icon" />
                    </button>
                    <UncontrolledCollapse navbar toggler="#navbar-collapse-main">
                      <div className="navbar-collapse-header d-md-none">
                        <Row>
                          <Col className="collapse-brand" xs="6">
                            <Link to="/">
                              <img
                                alt="..."
                                src={require("../../../../img/sflogo.png")}
                              />
                            </Link>
                          </Col>
                          <Col className="collapse-close" xs="6">
                            <button className="navbar-toggler" id="navbar-collapse-main">
                              <span />
                              <span />
                            </button>
                          </Col>
                        </Row>
                      </div>
                      <Nav className="me-auto" navbar>
                        <NavItem className="arg-navbar-secondary-name-logo">
                          <NavLink className="nav-link-icon" to="/" tag={Link}>
                          <img
                                alt="..."
                                src={require("../../../../img/img1-2.png")}
                              />
                            <span className="nav-link-inner--text">{this.state.opportunity_name}</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <UncontrolledDropdown nav>
                            <DropdownToggle className="pr-0 arg-navbar-secondary-settings" nav>
                              <i className="material-icons">settings</i>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" >
                              <DropdownItem to={"/applicant_room_settings_judges?opportunity_id=" + this.state.url.query.opportunity_id } tag={Link}>
                                <i className="material-icons">gavel</i>
                                <span>Team</span>
                              </DropdownItem>
                              <DropdownItem to={"/applicant_room_settings_reviews?opportunity_id=" + this.state.url.query.opportunity_id } tag={Link}>
                                <i className="material-icons">grading</i>
                                <span>Review</span>
                              </DropdownItem>
                              <DropdownItem to={"/applicant_room_settings_applicants?opportunity_id=" + this.state.url.query.opportunity_id } tag={Link}>
                                <i className="material-icons">accessibility</i>
                                <span>Applicant</span>
                              </DropdownItem>
                              <DropdownItem to={"/applicant_room_settings_visibility?opportunity_id=" + this.state.url.query.opportunity_id } tag={Link}>
                                <i className="material-icons">visibility</i>
                                <span>Visibility</span>
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </NavItem>
                        <NavItem>
                          <NavLink className="nav-link-icon" to="/" tag={Link}>
                            <i className="ni ni-planet" />
                            <span className="nav-link-inner--text">Deals</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className="nav-link-icon"
                            to="/auth/register"
                            tag={Link}
                          >
                            <i className="ni ni-circle-08" />
                            <span className="nav-link-inner--text">Team</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className="nav-link-icon"
                            to="/admin/user-profile"
                            tag={Link}
                          >
                            <i className="ni ni-single-02" />
                            <span className="nav-link-inner--text">Profile</span>
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </UncontrolledCollapse>
                  </Container>
                </Navbar>

*/