/* (2019-06-11) - The card that is displayed to represent the companies on
the explore page.
*/
import React from "react";
import default_background from "../../img/bg.png";
import classnames from "classnames";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardActions from "@material-ui/core/CardActions";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import CardActionArea from "@material-ui/core/CardActionArea";
import AddIcon from "@material-ui/icons/Add";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DoneIcon from "@material-ui/icons/Done";
import Chip from "@material-ui/core/Chip";
import Button from "@material-ui/core/Button";
import Moment from "moment";
import MessageIcon from "@material-ui/icons/Message";
import Start_A_Conversation_Popup from "./popups/start_a_conversation";
import {
  country_list,
  opportunity_type_list,
  industry_list,
  stage_list,
  business_type,
} from "../../boilerplate/list.json";
import { distinctions_list } from "../../boilerplate/distinctions_list";
import "./explore_cardUbaid.css";
import { Generic_Success_Message } from "./popups";

import _compact from "lodash/compact";
import _flatten from "lodash/flatten";
import _includes from "lodash/includes";
import _forEach from "lodash/forEach";
import _intersection from "lodash/intersection";
import _difference from "lodash/difference";

import SocialAPI from "../api/social";

class ExploreAddedCard extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    expanded: false,
    is_following: false,
    follow_loading: true,
    showConversationPopup: false,
    showCreateMessageSuccessPopup: false,
    max_description_limit: 200,
  };

  componentDidMount() {
    this.fetchFollowStatus();
    if (window.innerWidth < 380) {
      this.setState({
        max_description_limit: 180,
      });
    } else if (window.innerWidth > 380 && window.innerWidth < 769) {
      this.setState({
        max_description_limit: 250,
      });
    } else {
      console.log("running");
      this.setState({
        max_description_limit: 3000,
      });
    }
  }

  componentDidUpdate(prevProps) {
    // if parent post has changed, reset everything
    if (this.props._id !== prevProps._id) {
      this.setState(
        {
          follow_loading: true,
          is_following: false,
        },
        this.fetchFollowStatus
      );
    }
  }
  dismissModal = (e) => {
    (e.target.className === "modal1" || e.target.className === "cancelbtn") &&
      this.setState({
        showCreateMessageSuccessPopup: false,
        showConversationPopup: false,
      });
  };
  handleSuccessfulConversation = () => {
    this.setState({
      showConversationPopup: false,
      showCreateMessageSuccessPopup: true,
    });
  };

  handleConversationPopup = (e) => {
    e.preventDefault();
    this.setState({
      showConversationPopup: !this.state.showConversationPopup,
    });
  };

  fetchFollowStatus = () => {
    SocialAPI.followStatus(this.props._id)
      .then((response) => {
        this.setState({
          is_following: response.data.is_following,
          follow_loading: false,
        });
      })
      .catch((err) => {
        this.setState({
          follow_loading: false,
        });
      });
  };

  handleFollow = () => {
    if (this.props.isLoggedIn === false) {
      this.props.showRegisterPopup();
    } else if (
      this.props.isLoggedIn === true &&
      this.props.companies_owned === null
    ) {
      this.props.showRegisterPopup();
    } else {
      this.setState(
        {
          follow_loading: true,
        },
        () => {
          if (!this.state.is_following) {
            SocialAPI.follow(this.props._id)
              .then((response) => {
                this.setState({
                  follow_loading: false,
                  is_following: true,
                });
              })
              .catch((err) => {
                this.setState({
                  follow_loading: false,
                });
              });
          } else {
            SocialAPI.unfollow(this.props._id)
              .then((response) => {
                this.setState({
                  follow_loading: false,
                  is_following: false,
                });
              })
              .catch((err) => {
                this.setState({
                  follow_loading: false,
                });
              });
          }
        }
      );
    }
  };

  handleExpand = (e) => {
    this.setState({
      expanded: !this.state.expanded,
    });
  };
  getDistinction = (value, field) => {
    const prope = distinctions_list.find((p) => p.value === value);
    return prope[field];
  };

  render() {
    var allTags = _compact(
      _flatten([
        this.props.looking_for,
        this.props.getStage(this.props.stage, stage_list),
        this.props.country,
        _compact(this.props.industry).map((val) =>
          val == "Artificial Intelligence" ? "A.I" : val
        ),
      ])
    );

    var filterTags = [];
    _forEach(
      this.props.filters,
      function (value, key, obj) {
        if (key === "stage") {
          _forEach(
            value,
            function (val) {
              filterTags.push(this.props.getStage(val, stage_list));
            }.bind(this)
          );
        } else if (key === "industry") {
          _forEach(
            value,
            function (val) {
              filterTags.push(val == "Artificial Intelligence" ? "A.I" : val);
            }.bind(this)
          );
        } else if (key === "country") {
          filterTags = filterTags.concat(value);
        }
      }.bind(this)
    );

    var showFilterTags = _intersection(filterTags, allTags);
    var showRemainderTags = _difference(
      allTags,
      _intersection(filterTags, allTags)
    );

    var getTagColorClass = function (tag) {
      if (this.props.looking_for[0] === tag) {
        return "blueHl";
      } else if (this.props.getStage(this.props.stage, stage_list) === tag) {
        return "redHl";
      } else if (this.props.country === tag) {
        return "";
      } else {
        return "greenHl";
      }
    }.bind(this);

    return (
      <React.Fragment>
        <Start_A_Conversation_Popup
          dismissModal={this.dismissModal}
          companies_owned={this.props.companies_owned}
          handleSuccessfulConversation={this.handleSuccessfulConversation}
          subject={``}
          showCreateMessagePopup={this.state.showConversationPopup}
          target_id={this.props._id}
          company_name={this.props.company_name}
          target_type={this.props.company_type[0]}
        />
        <Generic_Success_Message
          show={this.state.showCreateMessageSuccessPopup}
          popup_header="Message Successfully Sent!"
          dismiss={this.dismissModal}
        />
        <Card className="exploreCard MainExploreCardUBaid">
          <div className="exploreCardHeader">
            <div
              style={{
                backgroundImage:
                  "url(" +
                  this.props.getAvatarUrl(
                    this.props.company_files,
                    "company avatar"
                  ) +
                  ")",
              }}
              className="exploreCardAvtar"
            ></div>
            <div className="exploreCardTitle">
              <a href={"/profile?company_id=" + this.props._id}>
                {this.props.company_name}
              </a>
              {"#" + this.props.company_type}
            </div>
            <CardActions
              disableSpacing
              style={{ paddingLeft: 0 }}
              className={this.state.follow_loading && "follow-loading"}
            >
              <Tooltip
                placement="top"
                title={this.state.is_following ? "Unfollow" : "Follow"}
                aria-label={this.state.is_following ? "unfollow" : "follow"}
                className={
                  this.state.is_following ? "UbaidUnfollow" : "ubaidFollow"
                }
              >
                <>
                  {this.props.companies_owned !== undefined &&
                    this.props.companies_owned !== null &&
                    this.props.companies_owned[1] !== undefined &&
                    this.props.companies_owned[1][0] !== undefined && (
                      <IconButton
                        onClick={this.handleConversationPopup}
                        size="small"
                      >
                        <MessageIcon />
                      </IconButton>
                    )}
                  <IconButton onClick={this.handleFollow} size="small">
                    {this.state.is_following ? <DoneIcon /> : <AddIcon />}
                  </IconButton>
                </>
              </Tooltip>
            </CardActions>
          </div>
          {this.props.background_video_link !== undefined &&
          this.props.background_video_link !== null &&
          this.props.background_video_link.length > 0 ? (
            <div className="exploreCardMedia">
              <iframe
                style={{
                  margin: 0,
                  position: "absolute",
                  top: "0",
                  height: "100%",
                  width: "100%",
                }}
                src={this.props.background_video_link}
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          ) : (
            <CardActionArea href={"/profile?company_id=" + this.props._id}>
              <CardMedia
                className="exploreCardMedia"
                image={this.props.getAvatarUrl(
                  this.props.company_files,
                  "company background",
                  this.props.temp_background_url
                )}
                title="background_picture"
              />
            </CardActionArea>
          )}
          <div
            className={classnames(
              "exploreCardTags",
              this.state.expanded && "expanded"
            )}
          >
            <div className="allTags">
              {showFilterTags.concat(showRemainderTags).map((val) => (
                <Chip
                  variant="outlined"
                  className={classnames("exploreTag", getTagColorClass(val))}
                  color={_includes(showFilterTags, val) ? "primary" : "default"}
                  size="small"
                  label={val}
                />
              ))}
            </div>
            <div className="expandTags">
              <Chip
                size="tiny"
                onClick={this.handleExpand}
                label={<ExpandMoreIcon fontSize="small" />}
              />
            </div>
          </div>
          <div className="exploreCardContent">
            {this.props.short_bio !== undefined &&
            this.props.short_bio.length < 1 &&
            this.props.meta_short_bio !== undefined &&
            this.props.meta_short_bio !== null &&
            this.props.meta_short_bio.length > 0 ? (
              <>
                {this.props.meta_short_bio.length >
                this.state.max_description_limit
                  ? this.props.meta_short_bio.substring(
                      0,
                      this.state.max_description_limit - 3
                    ) + "..."
                  : this.props.meta_short_bio}
              </>
            ) : (
              <>
                {this.props.short_bio.length > this.state.max_description_limit
                  ? this.props.short_bio.substring(
                      0,
                      this.state.max_description_limit - 3
                    ) + "..."
                  : this.props.short_bio}
              </>
            )}
          </div>
          {this.props.distinction !== undefined &&
            this.props.distinction !== null &&
            this.props.distinction.length > 0 && (
              <div className="ExpUbaidDupSocalIconsCon">
                {this.props.distinction.map((dis) => (
                  <Tooltip title={this.getDistinction(dis, "title")}>
                    <i
                      style={{
                        background: `${this.getDistinction(dis, "color")}`,
                      }}
                      className={this.getDistinction(dis, "img_url")}
                    ></i>
                  </Tooltip>
                ))}
              </div>
            )}
        </Card>
      </React.Fragment>
    );
  }
}

export default ExploreAddedCard;
