/* (2019-06-10) - This page contains all the elements associated on an opportunity
page. This page is found when a user clicks on an item in the /opportunities
page. It references three components which make it up => Opp header, Opp dates,
opp body. These components are found in the /components . Many of the functions
used for handling events associated with opportunities can be found on this page.
*/
import React,{createRef} from "react";
import ReactDOM from 'react-dom';

import axios from "axios";
import update from "immutability-helper";
import "../main.css";
import URL from "url-parse";
import default_pic from "../img/default_company_pic.png";
import default_background from "../img/bg.png";
import { AppContext } from "../App";
import Navigation from "./components/navbar";
import Footer from "./components/footer_side";
import OppHeader from "./components/OppHeader.js";
import OppDates from "./components/OppDates.js";
import "./components/OppDates.css";

import OppBody from "./components/OppBody.js";
import {
  country_list,
  opportunity_type_list,
  industry_list,
  stage_list,
  business_type,
  fintech_vertical_list,
} from "../boilerplate/list.json";
import StartupFuel from "../img/watermarksffinal.png";
import Loader from "./components/loader";
import {
  Sign_In,
  Register,
  Share_Popup_Opp_Page,
  Forgot_Password,
  Alert_Message_To_User,
  Opportunity_Company_Update,
} from "./components/popups";
import { Stay_In_Touch } from "./components/popups/opp_signup";
import { Profile_Card } from "./components/cards";
import SideMenu from "./SideMenu";
import SiteTitle from "./components/site_title.js";
import CompanyNavBar from "./components/profiles/nav_bar.js";

const styles = (theme) => ({
  root: {
    backgroundColor: "#f5f5f5",
  },
});

class Opportunity_Container extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return <Opportunity {...this.props} {...this.context} />;
  }
}

Opportunity_Container.contextType = AppContext;
class Opportunity extends React.Component {
  constructor(props) {
    super(props);
    this.objectiveRef = createRef();
    this.venueLocationRef = createRef();
    this.prizeRef = createRef();
    this.amountRef = createRef();
    this.pitchPrize = createRef();
    this.teamref = createRef();
    this.state = {
      url: new URL(window.location.href, true),
      group_list: [],
      showSignUpPopup: false,
      showSignInPopup: false,
      showRegisterPopup: false,
      showSharePopup: false,
      rating: "",
      showApplicationPopup: false,
      team_list: [],
      affiliated_company: "",
      opportunity_list: [],

      showPopupMessage: false,
      showServicesPopupMessage: false,
      popup_header: "",
      popup_body: "",
      alert_message: "Thank you for submitting your application",

      userIsProfileOwner: false,
      avatarDetails: {
        file: "",
        file_name: "",
        file_type: "",
      },
      backgroundDetails: {
        file: "",
        file_name: "",
        file_type: "",
      },
      applicant_registration_email: {
        header: "Thank you for applying",
        body: "Your application is being processed.",
      },
      loading: true,

      //for form
      company_name: "",
      first_name: "",
      last_name: "",
      interests: [],
      email_lead: "",
      password: "",
      opportunity_form: {},
      submitted_application: false,
      menuSelection: 0,
      menuoppoBTN:true
    };
  }
  handleFileUpload = (e) => {
    let file = e.target.files[0];
    if (e.target.dataset.fileType === "avatar") {
      this.setState({
        avatarDetails: {
          file,
          file_name: "profile avatar",
          file_type: `company ${e.target.dataset.fileType}`,
          object_ref: "company",
          object_ref_value: this.state.url.query.company_id,
        },
      });
    } else {
      this.setState({
        backgroundDetails: {
          file,
          file_name: "profile background",
          file_type: `company ${e.target.dataset.fileType}`,
          object_ref: "company",
          object_ref_value: this.state.url.query.company_id,
        },
      });
    }
  };

  copyLink = () => {
    /* Get the text field */
    var copyText = document.getElementById("myInput");

    /* Select the text field */
    copyText.select();

    /* Copy the text inside the text field */
    document.execCommand("copy");

    /* Alert the copied text */
    alert("Copied the text: " + copyText.value);
  };
  opportunityApplication = (e) => {
    console.log("1610,",e)
    axios.post("/create_opportunity_application", {
      applicant_company: this.props.companies_owned[1][0]._id,
      pertains_to_opportunity: this.state.url.query.opportunity_id,
      changed_by: this.props.user._id,
    });
    // this.handleAlertPopupApplication()
  };

  handleFileSubmit = (type) => {
    let config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    let formData = new FormData();

    for (let key in this.state[type]) {
      formData.append(key, this.state[type][key]);
    }
    if (typeof this.state[type].file === "object") {
      axios
        .post(
          `/upload_file_secure?company_id=${this.state.url.query.company_id}`,
          formData,
          config
        )
        .then((response) => {
          if (response.data.status === "success") {
            if (this.state.company_files.length > 0) {
              let index = this.state.company_files.findIndex(
                (item) => item.file_type === response.data.result.file_type
              );
              index = index === -1 ? this.state.company_files.length : index;
              this.state.company_files
                .filter(
                  (item_1) =>
                    item_1.file_type === response.data.result.file_type
                )
                .map((item_2) => {
                  this.setState({
                    company_files: update(this.state.company_files, {
                      [index]: {
                        file_url: {
                          $set: response.data.result.file_url,
                        },
                      },
                    }),
                    // [type]: {
                    //   file: '',
                    //   file_name: '',
                    //   file_type: ''
                    // }
                    showEditPopup: false,
                  });
                });
            } else {
              this.setState({
                company_files: [
                  {
                    file_type: response.data.result.file_type,
                    file_url: response.data.result.file_url,
                  },
                ],

                showEditPopup: false,
              });
            }
          } // do else later
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      return;
    }
  };
  handleAlertPopupApplication = () => {
    this.setState({
      showPopupMessage: true,
      submitted_application: true,
    });
  };

  fetchAssets = (asset_type, callback) => {
    axios
      .get(`/fetch_${asset_type}`)
      .then((response) => {
        callback(response.data, this);
      })
      .catch((error) => {
        this.props.history.push("/opportunities");
        console.log(error);
      });
  };
  componentDidMount() {
    this.getscore();
    this.fetchAssets(
      `opportunity?opportunity_id=${this.state.url.query.opportunity_id}`,
      function (data, _this) {
        if (data.status === "success" || data.status === "partial") {
          _this.setState(
            {
              loading: false,
              ...data.result,
            },
            function () {
              localStorage.setItem("com_id", this.state.affiliated_company);
              this.fetchAssets(
                `company_profile_opp_owner?company_id=${this.state.affiliated_company}`,
                function (data, _this) {
                  if (data.status === "success" || data.status === "partial") {
                    _this.setState({
                      loading: false,
                      affiliated_company: {
                        ...data.result[0],
                      },
                    });
                  }
                }
              );
              if (
                _this.state.show_judge_on_opportunity !== undefined &&
                _this.state.show_judge_on_opportunity !== null &&
                _this.state.show_judge_on_opportunity === true
              ) {
                axios
                  .get(
                    `fetch_judges_for_opportunity?opportunity_id=${this.state.url.query.opportunity_id}`
                  )
                  .then((response) => {
                    _this.setState({
                      judges: response.data.judges,
                      opportunity_owner: response.data.owner,
                      access_ids: response.data.access_ids,
                    });
                  });
              }
            }
          );
        }
      }
    );
  }

  getscore = () => {
    const com_id = localStorage.getItem("com_id");
    if (com_id && !com_id.length == 0) {
      axios
        .get(`/fetch_fundability_scores_owned?company_id=${com_id}`)
        .then((response) => {
          const lotteryNumbers = response.data.result;
          const [lastNumber] = lotteryNumbers.slice(-1);
          // console.log(lotteryNumbers.slice(-1));
          const newdata = lotteryNumbers.slice(-1);
          const obj = { ...newdata };
          const firstitem = obj[0].ratings;
          console.log("get the list", firstitem);
          this.setState({
            rating: firstitem,
          });
        });
    }
  };

  componentDidUpdate(prevProps) {
    if (
      Array.isArray(this.props.companies_owned) &&
      prevProps.companies_owned !== this.props.companies_owned
    ) {
      this.get_affiliated_opportunities();
    }
  }
  getStage = (value, array) => {
    for (let i = 0; i < array.length; i++) {
      if (array[i].value === value) {
        return array[i].text;
      }
    }
  };
  getDiversity = (value, array) => {
    for (let i = 0; i < array.length; i++) {
      if (array[i].value === value) {
        return array[i].text;
      }
    }
  };
  handleChange = (e) => {
    const name = e.target.name;
    this.setState({
      [name]: e.target.value,
    });
  };

  handleChangeMultiple = (e) => {
    const name = e.target.name;
    let selected_values = [];
    Array.from(e.target.selectedOptions).forEach(function (item) {
      selected_values.push(item.value);
    });
    this.setState({
      [name]: selected_values,
    });
  };
  create_opportunity_application_referral = (
    user_id_registered,
    user_company_registered
  ) => {
    if (this.state.url.query.referrer_id !== undefined) {
      console.log("this.state.url.query.referrer_email",this.state.url.query.referrer_email)
      axios.post("/create_referral_for_opportunity", {
        referrer: this.state.url.query.referrer_id,
        referral_user_email: this.state.url.query.referrer_email,
        opp_id: this.state.url.query.opportunity_id,
        user_id_registered: user_id_registered,
        user_company_registered: user_company_registered,
      });
    }
  };

  validateResult = (data, callback) => {
    if (data.status === "success" || data.status === "partial") {
      callback(data, this);
    } else if (data.status === "redirect") {
      this.props.history.push(data.redirect_to);
    } else if (data.status === "error") {
    }
  };

  getAvatarUrl = (array, file_type) => {
    if (array?.length > 0) {
      let new_arr = array.filter((file) => {
        return file.file_type === file_type;
      });
      if (new_arr.length !== 0) {
        return new_arr[0].file_url;
      } else {
        return default_pic;
      }
    } else {
      return default_pic;
    }
  };

  handleEditPopup = (e) => {
    this.setState({
      showEditPopup: !this.state.showEditPopup,
    });
  };
  dismissAppModal = (e) => {
    if (e.target.className === "modal1") {
      this.setState({
        showApplicationPopup: false,
      });
    }
  };

  dismiss = (e) => {
    this.setState(
      {
        showPopupMessage: false,
        alert_message:
          "We want you to be as successful as possible. Visit our services page today for a limited time offer of 10% off.",
      },
      () => {
        this.setState({
          showServicesPopupMessage: true,
        });
      }
    );
  };
  dismissServices = (e) => {
    this.setState({
      showServicesPopupMessage: false,
      alert_message: "Thank you for submitting your application",
    });
  };

  handleSharePopup = (e) => {
    this.setState({
      showSharePopup: !this.state.showSharePopup,
    });
  };

  handleApplicationPopup = (e) => {
    this.setState({
      showApplicationPopup: !this.state.showApplicationPopup,
    });

    this.getscore();
  };
  handleCheckMarkChange = (name) => (event) => {
    let array = this.state.interests;
    var index = array.indexOf(event.target.value);
    // Let's say it's Bob.
    if (index !== -1) {
      array.splice(index, 1);
    } else {
      array.push(event.target.value);
    }
    this.setState({
      interests: array,
    });
  };

  handleInputSubmit = (e) => {
    e.preventDefault();
    axios
      .post(`/new_lead`, {
        first_name: this.props.first_name,
        last_name: this.props.last_name,
        email_lead: this.props.email_lead,
        interests: this.state.interests,
        company_name: this.props.company_name,
      })
      .then((response) => {
        this.props.toggleSignUpPopup();
        this.validateResult(response.data, function (data, _this) {
          window.location.href = `${_this.state.website_url}?utm_source=startupfuel.com&utm_medium=Opportunity&utm_campaign=claim`;
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleInputSkip = (e) => {
    e.preventDefault();
    window.location.href = `${this.state.website_url}?utm_source=startupfuel.com&utm_medium=Opportunity&utm_campaign=claim`;
    this.props.toggleSignUpPopup();
  };
  get_affiliated_opportunities = () => {
    if (
      this.props.companies_owned !== null &&
      this.props.companies_owned[1] !== undefined &&
      this.props.companies_owned[1][0] !== undefined
    ) {
      axios
        .get(
          `/fetch_opportunities_companies_applied?company_id=${this.props.companies_owned[1][0]._id}`
        )
        .then((response) => {
          if (
            response.data.status === "success" ||
            response.data.status === "partial"
          ) {
            this.setState({
              opportunity_list:
                response.data.result.length > 0 ? response.data.result : [],
            });
          }
        });
    }
  };

  handleMenuChange = (event, value) => {
    this.setState(
      {
        menuSelection: value,
      },
      () => {
        this.props.history.push(
          `/opportunity?opportunity_id=${this.state.url.query.opportunity_id}&tab=${this.state.menuSelection}`
        );
      }
    );
  };

 handleNavigationClick = (section) => {
    if (section === 'objective') {
      window.scrollTo({
        top: ReactDOM.findDOMNode(this.objectiveRef.current).offsetTop,
        behavior: 'smooth'
      });
    } else if (section === 'venue_location') {
      window.scrollTo({
        top: ReactDOM.findDOMNode(this.venueLocationRef.current).offsetTop,
        behavior: 'smooth'
      });
    } else if (section === 'Prize') {
      window.scrollTo({
        top: ReactDOM.findDOMNode(this.prizeRef.current).offsetTop,
        behavior: 'smooth'
      });
    }else if (section === 'Amount') {
      window.scrollTo({
        top: ReactDOM.findDOMNode(this.amountRef.current).offsetTop,
        behavior: 'smooth'
      });
    }else if (section === 'Pitch_Prize') {
      window.scrollTo({
        top: ReactDOM.findDOMNode(this.pitchPrize.current).offsetTop,
        behavior: 'smooth'
      });
    }
    else if (section === 'Team') {
      window.scrollTo({
        top: ReactDOM.findDOMNode(this.teamref.current).offsetTop,
        behavior: 'smooth'
      });
    }

  }


  render() {
    return (
      <React.Fragment>
        <SiteTitle
          title={`StartupFuel - ${this.state.opportunity_name}`}
          meta_description={this.state.opportunity_description}
          meta_keywords={`${this.state.opportunity_name}, Pitch Competitions, Pitch, Pitch Competition, Startup Opportunities`}
        />
        <Sign_In />
        <Forgot_Password />
        <Alert_Message_To_User
          show={this.props.showPopupMessage}
          handleChange={this.props.handleChange}
          dismiss={this.props.dismissModal}
          alert_message={this.props.alert_message}
          {...this.state}
        />
        <Opportunity_Company_Update
          ratings={this.state.rating}
          show={this.state.showApplicationPopup}
          dismissAppModal={this.dismissAppModal}
          handleApplicationPopup={this.handleApplicationPopup}
          companies_owned={this.props.companies_owned}
          create_opportunity_application_referral={
            this.create_opportunity_application_referral
          }
          opportunityApplication={this.opportunityApplication}
          handleAlertPopupApplication={this.handleAlertPopupApplication}
          {...this.state}
        />
        <Alert_Message_To_User
          show={this.state.showPopupMessage}
          handleChange={this.handleChange}
          dismiss={this.dismiss}
          alert_message={this.state.alert_message}
          {...this.state}
        />
        <Alert_Message_To_User
          show={this.state.showServicesPopupMessage}
          handleChange={this.handleChange}
          dismiss={this.dismissServices}
          alert_message={this.state.alert_message}
          {...this.state}
        />
        <Register />
        <Stay_In_Touch
          handleChange={this.handleChange}
          handleChangeMultiple={this.handleChangeMultiple}
          handleInputSubmit={this.handleInputSubmit}
          handleInputSkip={this.handleInputSkip}
          handleCheckMarkChange={this.handleCheckMarkChange}
          link={this.state.website_url}
          {...this.state}
        />
        <Share_Popup_Opp_Page
          {...this.state}
          handleChange={this.handleChange}
          user={this.props.user}
          handleSharePopup={this.handleSharePopup}
        />
        {this.state.loading === false ? (
          <div className="Ubaid_Opp_Page">
            <OppHeader
              toggleSignUpPopup={this.props.toggleSignUpPopup}
              handleSharePopup={this.handleSharePopup}
              handleApplicationPopup={this.handleApplicationPopup}
              opportunityApplication={this.opportunityApplication}
              isLoggedIn={this.props.isLoggedIn}
              {...this.state}
              getAvatarUrl={this.getAvatarUrl}
            />
            {/* <OppDates {...this.state} /> */}
            <div className="company-oppo-tabs-design">
            <CompanyNavBar
                {...this.state}
              menuoppoBTN={this.state.menuoppoBTN}
              handleApplicationPopup={this.handleApplicationPopup}
              toggleSignUpPopup={this.props.toggleSignUpPopup}
              opportunityApplication={this.opportunityApplication}
              isLoggedIn={this.props.isLoggedIn}
              handleSharePopup={this.handleSharePopup}
              menuSelection={this.state.menuSelection}
              companies_owned={this.props.companies_owned}
              handleMenuChange={this.handleMenuChange}
              company_type={"Startup"}
              allow_investor={"this.props.allow_investor"}
              userIsProfileOwner={"this.props.userIsProfileOwner"}
              getStage={this.getStage}
              getDiversity={this.getDiversity}
              getAvatarUrl={this.getAvatarUrl}
              handleNavigationClick={this.handleNavigationClick}
            />
            </div>
            <OppBody
              {...this.state}
              getStage={this.getStage}
              getDiversity={this.getDiversity}
              getAvatarUrl={this.getAvatarUrl}
              objectiveRef={this.objectiveRef}
              venueLocationRef={this.venueLocationRef}
              prizeRef={this.prizeRef}
              amountRef={this.amountRef}
              pitchPrize={this.pitchPrize}
              teamref={this.teamref}
            />
          </div>
        ) : (
          <Loader />
        )}
      </React.Fragment>
    );
  }
}
export default Opportunity_Container;
