
import React, { Component } from 'react';


import '../pages/_M-servicesPage.scss';

import ServiceCard from '../components/cards/servicesPage-card/M-serviceCard';

import Footer from '../../live/components/footer';

import Navbar from '../../live/components/navbar';

class ServicesPage extends Component {


  render() {

    const {classes} = this.props;

    return (



      <div className="servicesPage">
        <Navbar />
        { /* <div className="servicesPage__banner"><h3>services</h3><div style={{ justifyContent: "flex-end" }}><ul><a style={{color:"white"}} href="/">Home</a> / <a>Services</a></ul></div></div> */ }
        <div className="servicesPage-container">
          <header className="servicesPage__header" style={{marginTop: 0}}>
            <h1>STARTUPFUEL SERVICEs</h1>
          {/*  <h3>Our Guarantee</h3> */}
            <p>
              StartupFuel wants to help your company grow and develop. We realize that as a founder, you may not know everything. That being said, we want to help you connect with people that can fill the knowledge gap. Get connected with service providers that help early stage companies.
            </p>
          </header>
          <div className="servicesPage__general-consultation">
            <div className="servicesPage__general-consultation-card ">
              <div className="servicesPage__general-consultation-card__side--front">
                <div className="servicesPage__general-consultation-card-title">
                  <h1>General <br />Consultation</h1>
                </div>
              </div>
            </div>
            <div className="servicesPage__general-consultation-info">
              <h3>SPEAK TO A REP</h3>
              <p>
                Outsourcing service work to low-cost experts allows management teams to continue focusing on their strengths while improving the existing business. If you have specific
                needs you can scroll down and learn about specific services we provide. However, if you are unaware of your needs, or have multiple needs use the button
                below to book a general consultation call.
              </p>
              <a href="https://calendly.com/mrmartis">
                <button className="servicesPage__button"> CHAT WITH US </button>
              </a>
            </div>
          </div>
          <div className="servicesPage__services-cards">
            <ServiceCard />
          </div>
        </div>
      </div>

    )



  }

}




export default ServicesPage;
