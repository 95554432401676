import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

import {
  FormControl,
  FormLabel,
  Button,
  Form,
  Row,
  Col,
  Card,
} from "react-bootstrap";
import update from "immutability-helper";
import URL from "url-parse";

import {
  company_type_list,
  industry_list,
  stage_list,
  business_type,
  vertical_list,
  type_of_service_provider,
} from "../../../boilerplate/list.json";
import popupPic from "../../../img/rocket.png";
import developmentPic from "../../../img/development_icon.png";
import Grid from "@material-ui/core/Grid";
import { AppContext } from "../../../App";
import axios from "axios";
import waterfall from "async/waterfall";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import FormGroup from "@material-ui/core/FormGroup";
import CardMedia from "@material-ui/core/CardMedia";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input";
import Icon from "@material-ui/core/Icon";
import classNames from "classnames";
import InputLabel from "@material-ui/core/InputLabel";
import Chip from "@material-ui/core/Chip";
import ListItemText from "@material-ui/core/ListItemText";
import { SocialIcon } from "react-social-icons";
import VideoIcon from "@material-ui/icons/Videocam";
import ImageIcon from "@material-ui/icons/Image";
import ProfileImage from "../profile_image";
import SocialAPI from "../../api/social";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function getStyles(name, that) {
  return {
    fontWeight:
      that.state.name.indexOf(name) === -1
        ? that.props.theme.typography.fontWeightRegular
        : that.props.theme.typography.fontWeightMedium,
  };
}
const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: theme.spacing.unit / 4,
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 100,
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 100,
  },
});

const currencies = [
  {
    value: "USD",
    label: "$",
  },
  {
    value: "EUR",
    label: "€",
  },
  {
    value: "BTC",
    label: "฿",
  },
  {
    value: "JPY",
    label: "¥",
  },
];

export class NewsFeed_Popup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      list: [],
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.showFollowersPopup !== this.props.showFollowersPopup) {
      this.fetchFollowerList();
    }
  }

  fetchFollowerList = () => {
    SocialAPI.follower_list_profile(this.props._id)
      .then((response) => {
        if (response.data.status === "success") {
          this.setState({
            loaded: true,
            list: response.data.followers || [],
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const { classes } = this.props;
    return (
      <div
        style={
          this.props.showFollowersPopup
            ? { display: "block" }
            : { display: "none" }
        }
        onClick={(event) => this.props.dismissModal(event)}
        class="modal1"
      >
        <form class="modal-content animate">
          <div className="SocialFeedTitleBox" style={{ textAlign: "center" }}>
            Followers <span className="countBox">{this.state.list.length}</span>
          </div>
          <div
            class="container"
            style={{ width: "auto", height: "auto", textAlign: "center" }}
          >
            <div className="CenterBodyNewsFeed">
              {this.state.list.length == 0 && (
                <h5 style={{ textAlign: "center" }}>
                  <br />
                  No followers at the moment.
                </h5>
              )}
              {this.state.list.map(
                function (item, i) {
                  return (
                    <div key={i} className="FollowingListFeed">
                      <a href={"/profile?company_id=" + item._id}>
                        <ProfileImage company_id={item._id} />
                      </a>
                      <a
                        href={"/profile?company_id=" + item._id}
                        className="mainContent"
                      >
                        <span>{item.company_name}</span>{" "}
                        <span className="small">
                          {item.company_type && item.company_type[0]}
                        </span>
                      </a>
                    </div>
                  );
                }.bind(this)
              )}
            </div>
          </div>
        </form>
      </div>
    );
  }
}
NewsFeed_Popup.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(NewsFeed_Popup);
