import React from 'react';
import Result from '../Result/Result';
import StarIcon from '../icons/StarIcon';
import BadgeIcon from '../icons/BadgeIcon';
import ChatMessageIcon from '../icons/ChatMessageIcon';
import AddPlaceIcon from '../icons/AddPlaceIcon';
import avatar41 from '../Images/avatar41-sm.jpg';
import avatar42 from '../Images/avatar42-sm.jpg';
import avatar43 from '../Images/avatar43-sm.jpg';
import avatar44 from '../Images/avatar44-sm.jpg';
import avatar45 from '../Images/avatar45-sm.jpg';

import moment from 'moment';
import classnames from 'classnames';
import ProfileImage from '../../../components/profile_image';
import ThreeDotsIcon from '../icons/ThreeDotsIcon.js';
import SocialAPI from '../../../api/social_community';
import URL from 'url-parse';

class SuggestedFriends extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      feed: [],
      loading: false,
      url: new URL(window.location.href, true),
    };
  }

  componentDidMount() {
    this.setState({
      loading: true
    }, this.fetchFeed);
  }

  fetchFeed = () => {
    SocialAPI.follow_suggestions(5,this.state.url.query.community_id)
      .then((response) => {
        if (response.data.status === 'success') {
          this.setState({
            feed: response.data.items || [],
            loading: false
          });
        }
      })
      .catch((error) => {
        this.setState({
          loading: false
        });
      });
  }

  handleFollow(company_id) {
    return function(e) {
      e.preventDefault();
      SocialAPI.follow(company_id).then(this.fetchFeed);
    }.bind(this);
  }

  render() {
    return (
      <div className='SuggestFriendsNews SocialSideBarCard'>
        <div className='NewsCardTitle'>
          Community Follow Suggestions
        </div>
        <div className={ classnames('SocialLoadingIcon', {
                           'hide': !this.state.loading
                         }) }>
          <ThreeDotsIcon />
        </div>
        { this.state.feed.length == 0 && !this.state.loading && (
          <h6 style={ { textAlign: "center" } }><br />None Available</h6>
          ) }
        { this.state.feed.map(function(item, i) {
            return (
              <a
                key={i}
                target="_blank"
                href={ "/profile?company_id=" + item._id }
                className='SocialSideItem'>
                <ProfileImage company_id={ item._id } />
                <div className="mainContent">
                  <div>
                    { item.company_name } </div>
                  <div class="small">
                    { item.company_type && item.company_type[0] } </div>
                </div>
                <div> <a
                        href="#"
                        onClick={this.handleFollow(item._id)}
                        className="ctaBtn">Follow</a> </div>
              </a>
            )
          }.bind(this)) }
      </div>
    )
  }
}
;

export default SuggestedFriends;
