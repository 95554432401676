import React from 'react';

const MemberIcon = props => (
    <svg> 
        <path
        d="M12,4c1.654,0,3,1.346,3,3s-1.346,3-3,3c-1.654,0-3-1.346-3-3S10.346,4,12,4 M12,2C9.239,2,7,4.239,7,7
        c0,2.762,2.239,5,5,5c2.762,0,5-2.238,5-5C17,4.239,14.762,2,12,2L12,2z">
        </path> 
        <path d="M17,14H7c-2.209,0-4,1.791-4,4v4h6v-2H5v-2c0-1.104,0.897-2,2-2h10c1.104,0,2,0.896,2,2v2h-4v2h6v-4
        C21,15.791,19.209,14,17,14z"></path>
    </svg>
)

export default MemberIcon;