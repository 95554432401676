import React from 'react';

const LinksCard = props => (
    <div className='CommunityLinksCards'>
        <div className='OlypicsCardTitle'>
            {props.title}
            <div className='OlypicsBorder'></div>
        </div>
        <div>
            {props.children}
        </div>
    </div>
)

export default LinksCard