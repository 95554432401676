/* (2019-06-10) - The loader used for the explore and opportunities page.
*/
import React from 'react';
import './loader3.css'
import PropTypes from 'prop-types';
import flame from '../../img/favicon.png'
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 1,
    paddingBottom: theme.spacing.unit * 2,
  },
  button: {
    margin: theme.spacing.unit,
  },
  input: {
    display: 'none',
  },
  img: {
    width: '90%',
  }
});

const Loader = (props) => {
  const {classes} = props;
  return (

    <div
      class="loader"
      style={ { width: "200px", height: "200px" } }>
      <div class="loader-inner">
        <div class="loader-line-wrap">
          <Grid
            container
            justify="center"
            alignItems="center">
            <img
              src={ flame }
              className={ classes.img } />
          </Grid>
        </div>
      </div>
    </div>


  )
};

Loader.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Loader);
