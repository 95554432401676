import React from 'react';
import './LoginPart.css';
import LoginPage from '../LoginPage/LoginPage'

const LoginPart = props => (
    <div className='LoginPartOlympus'>
        <div className='LoginPartOlympusChild'>
            <div className='leftPart'>
                <h1>The Most Complete Social Network is Here!</h1>
                <p>
                    We are the best and biggest social network with 5 billion active users all around the world. Share your thoughts, write blog posts, show your favourite music, earn badges and much more!
                </p>
            </div>
            <LoginPage />
        </div>
    </div>
)

export default LoginPart;