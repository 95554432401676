/* (2019-06-11) - This is the settings page of the SIR/Application room, it
allows you to configure various settings for the SIR. A lot of the logic is
handled on other pages, this mainly just accepts the checkbox.
*/
import React, { Component } from "react";
import axios from "axios";
import URL from "url-parse";
import { Success_Popup } from "../../../components/popups";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import ArgNav from "../argNav";
import { Switch } from '@material-ui/core';
import {
  NavItem,
} from "reactstrap";
import { Link } from 'react-router-dom';
import './index.scss';
import { SocialIcon } from "react-social-icons";
import ProfileImage from "../../../components/profile_image"
import default_pic from "../../../../img/default_company_pic.png"
import {
  Profile_Photo_Update,
} from "../../../components/popups"

const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
});

class Page extends Component {
  constructor(props) {
    super(props);

    this.state = {
      url: new URL(window.location.href, true),
      judges: [1],
      number_of_reviews: 7,
      judges_can_register: false,
      users_can_see_feedback: false,
      additional_review_options: true,
      additional_review_criteria: [],
      temp_value: "",
      success_msg: "Saved!",
      show_success_popup: false,
      judges_get_email: false,
      opportunity_private: false,
      limit_number_of_reviews: false,
      number_of_reviews: null,
      has_applicant_registration_email: false,
      applicant_registration_email: {
        header: "Thank you for applying!",
        body: `We appreciate you taking the time to apply to our application.
              We will get back to your shortly regarding how you did.
              `,
      },
      opportunity_form_modify: false,
      custom_text_q1: "",
      custom_text_q2: "",
      opportunity_form: [],
    };
  }
  componentDidMount() {
    axios
      .get(
        `fetch_opportunity?opportunity_id=${this.state.url.query.opportunity_id}`
      )
      .then((response) => {
        this.setState({
          ...response.data.result,
        });
      });
    axios
      .get(
        `/fetch_optional_questions?opportunity_id=${this.state.url.query.opportunity_id}`
      )
      .then((response) => {
        this.setState({
          ...response.data.existing_opportunity_form,
        });
      });
  }
  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({
        judges: this.props.judges,
      });
    }
  }

  handleSharePopup = (e) => {
    this.setState({
      showSharePopup: !this.state.showSharePopup,
    });
  };

  displaySaved = () => {
    this.setState(
      {
        show_success_popup: true,
      },
      () => {
        setTimeout(() => this.setState({ show_success_popup: false }), 1500);
      }
    );
  };

  handleChange = (e) => {
    const name = e.target.name;
    this.setState({
      [name]: e.target.value,
    });
  };
  handleEmailChange = (e) => {
    const name = e.target.name;
    this.setState({
      applicant_registration_email: {
        ...this.state.applicant_registration_email,
        [name]: e.target.value,
      },
    });
  };
  handleNumberReviewsSave = (e) => {
    axios.post(
      `/edit_opportunity_settings?opportunity_id=${this.state.url.query.opportunity_id}`,
      {
        judges_can_register: this.state.judges_can_register,
        users_can_see_feedback: this.state.users_can_see_feedback,
        judges_get_email: this.state.judges_get_email,
        limit_number_of_reviews: this.state.limit_number_of_reviews,
        number_of_reviews: this.state.number_of_reviews,
        opportunity_private: this.state.opportunity_private,
        opportunity_form_modify: this.state.opportunity_form_modify,
        has_applicant_registration_email:
          this.state.has_applicant_registration_email,
      }
    );
    this.displaySaved();
  };

  handleCheck = (e) => {
    const name = e.target.name;
    this.setState(
      {
        [name]: !this.state[name],
      },
      () => {
        axios.post(
          `/edit_opportunity_settings?opportunity_id=${this.state.url.query.opportunity_id}`,
          {
            judges_can_register: this.state.judges_can_register,
            users_can_see_feedback: this.state.users_can_see_feedback,
            judges_get_email: this.state.judges_get_email,
            limit_number_of_reviews: this.state.limit_number_of_reviews,
            opportunity_private: this.state.opportunity_private,
            opportunity_form_modify: this.state.opportunity_form_modify,
            has_applicant_registration_email:
              this.state.has_applicant_registration_email,
            additional_review_options: this.state.additional_review_options,
          }
        );
        this.displaySaved();
      }
    );
  };
  handleCustomForm = (e, key) => {
    const name = e.target.name;
    let target_key = this.state.opportunity_form[key];
    if (
      name === "display" &&
      this.state.opportunity_form[key]["display"] === true
    ) {
      let obj = {
        ...this.state.opportunity_form[key],
        ["required"]: false,
        ["display"]: false,
      };
      this.setState({
        opportunity_form: { ...this.state.opportunity_form, [key]: obj },
      });
    } else {
      let obj = {
        ...this.state.opportunity_form[key],
        [name]: !this.state.opportunity_form[key][name],
      };
      this.setState({
        opportunity_form: { ...this.state.opportunity_form, [key]: obj },
      });
    }
  };
  handleFormSave = (e) => {
    axios.post(
      `/edit_opportunity_form?opportunity_id=${this.state.url.query.opportunity_id}`,
      {
        opportunity_form: this.state.opportunity_form,
      }
    );
    this.displaySaved();
  };
  handleEmailSave = (e) => {
    axios.post(
      `/edit_opportunity_email?opportunity_id=${this.state.url.query.opportunity_id}`,
      {
        applicant_registration_email: {
          header: this.state.applicant_registration_email.header,
          body: this.state.applicant_registration_email.body,
        },
      }
    );
    this.displaySaved();
  };

  handleSave = (e) => {
    axios.post(
      `/create_optional_questions?opportunity_id=${this.state.url.query.opportunity_id}`,
      {
        custom_text_q1: this.state.custom_text_q1,
        custom_text_q2: this.state.custom_text_q2,
      }
    );
  };

  addCriteria = (e) => {
    this.setState(
      {
        additional_review_criteria: [
          ...this.state.additional_review_criteria,
          this.state.temp_value,
        ],
        temp_value: "",
      },
      () => {
        axios.post(
          `/edit_opportunity_settings?opportunity_id=${this.state.url.query.opportunity_id}`,
          {
            judges_can_register: this.state.judges_can_register,
            users_can_see_feedback: this.state.users_can_see_feedback,
            judges_get_email: this.state.judges_get_email,
            limit_number_of_reviews: this.state.limit_number_of_reviews,
            opportunity_private: this.state.opportunity_private,
            opportunity_form_modify: this.state.opportunity_form_modify,
            has_applicant_registration_email:
              this.state.has_applicant_registration_email,
            additional_review_options: this.state.additional_review_options,
            additional_review_criteria: this.state.additional_review_criteria,
          }
        );
        this.displaySaved();
      }
    );
  };
  deleteCriteria = (e, value) => {
    let newList = this.state.additional_review_criteria.splice(
      this.state.additional_review_criteria.indexOf(value),
      1
    );
    this.setState(
      {
        additional_review_criteria:
          this.state.additional_review_criteria.filter((x) => x !== value),
      },
      () => {
        axios.post(
          `/edit_opportunity_settings?opportunity_id=${this.state.url.query.opportunity_id}`,
          {
            judges_can_register: this.state.judges_can_register,
            users_can_see_feedback: this.state.users_can_see_feedback,
            judges_get_email: this.state.judges_get_email,
            limit_number_of_reviews: this.state.limit_number_of_reviews,
            opportunity_private: this.state.opportunity_private,
            opportunity_form_modify: this.state.opportunity_form_modify,
            has_applicant_registration_email:
              this.state.has_applicant_registration_email,
            additional_review_options: this.state.additional_review_options,
            additional_review_criteria: this.state.additional_review_criteria,
          }
        );
        this.displaySaved();
        axios.post(
          `/edit_rating_criteria_for_opp?opportunity_id=${this.state.url.query.opportunity_id}`,
          {
            additional_review_criteria: this.state.additional_review_criteria,
            value: value,
          }
        );
      }
    );
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <ArgNav
          opportunity_name = {this.props.opportunity_name}
          opportunity_id = {this.state.url.query.opportunity_id}
          pathname={this.state.url.pathname} />
        <div className="_content">
          <React.Fragment>
            <Profile_Photo_Update
              show={this.state.showProfilePhotoPopup}
              handleChange={this.handleChange}
              handleChangeMultiple={this.handleChangeMultiple}
              handleInputSubmit={this.handleInputSubmit}
              handleFileUpload={this.handleFileUpload}
              handleFileSubmit={this.handleFileSubmit}
              dismiss={this.dismissModal}
              {...this.state}
             />
            <Success_Popup
              show={this.state.show_success_popup}
              alert_message={this.state.success_msg} />
            <div className='__application-room-settings'>
              
              <div className="__header  __application-room-padding">
                <h3>Profile Settings</h3>
              </div>
              <div className='__pb_settings_page'>
              <div className='__pb_settings_navigation'>
                 <NavItem className='__pb-setting-nav-item' to={
                      "/applicant_room_settings?opportunity_id=" +
                      this.state.url.query.opportunity_id
                    }
                    id={
                      this.state.url.pathname === "/applicant_room_settings"
                        ? "submission"
                        : "sidetabs"
                    } tag={Link}>
                  <i className="material-icons">business</i>
                  <span className='__pb_settings_navigation_text'>Profile</span>
                </NavItem>
                <NavItem className='__pb-setting-nav-item' to={
                      "/applicant_room_settings_judges?opportunity_id=" +
                      this.state.url.query.opportunity_id
                    }
                    id={
                      this.state.url.pathname === "/applicant_room_settings_judges"
                        ? "submission"
                        : "sidetabs"
                    } tag={Link}>
                  <i className="material-icons">gavel</i>
                  <span className='__pb_settings_navigation_text'>Team</span>
                </NavItem>
                <NavItem className='__pb-setting-nav-item' to={
                      "/applicant_room_settings_reviews?opportunity_id=" +
                      this.state.url.query.opportunity_id
                    }
                    id={
                      this.state.url.pathname === "/applicant_room_settings_reviews"
                        ? "submission"
                        : "sidetabs"
                    } tag={Link}>
                  <i className="material-icons">grading</i>
                  <span className='__pb_settings_navigation_text'>Review</span>
                </NavItem>
                <NavItem className='__pb-setting-nav-item' to={
                      "/applicant_room_settings_applicants?opportunity_id=" +
                      this.state.url.query.opportunity_id
                    }
                    id={
                      this.state.url.pathname === "/applicant_room_settings_applicants"
                        ? "submission"
                        : "sidetabs"
                    } tag={Link}>
                  <i className="material-icons">accessibility</i>
                  <span className='__pb_settings_navigation_text'>Applicant</span>
                </NavItem>
                <NavItem className='__pb-setting-nav-item' to={
                      "/applicant_room_settings_visibility?opportunity_id=" +
                      this.state.url.query.opportunity_id
                    }
                    id={
                      this.state.url.pathname === "/applicant_room_settings_visibility"
                        ? "submission"
                        : "sidetabs"
                    } tag={Link}>
                  <i className="material-icons">visibility</i>
                  <span className='__pb_settings_navigation_text'>Portal Visibility</span>
                </NavItem>
              </div>
                <div className='__main __application-room-padding'>
                  <label>
                    
                  </label>
                </div>
              </div>
            </div>
          </React.Fragment>
        </div>
        {/* <div class="_content">
          <React.Fragment>
            <Success_Popup
              show={this.state.show_success_popup}
              alert_message={this.state.success_msg}
            />
            <div
              className={"ubaidSubmissionPageWrap " + this.props.UbaidSubClass}
              style={{ backgroundColor: "unset", paddingBottom: "24px" }}
            >
              <div style={{ maxWidth: "unset", width: "100%" }}>
                <h1 style={{ margin: "auto" }}> Settings</h1>
              </div>
              <div
                style={{
                  width: "50%",
                  margin: "auto",
                  paddingTop: "30px",
                  display: "flex",
                }}
              >
                <Checkbox
                  checked={this.state.judges_can_register}
                  onChange={(event) => {
                    this.handleCheck(event);
                  }}
                  name="judges_can_register"
                  color="primary"
                />
                <span>
                  <p
                    style={{
                      verticalAlign: "middle",
                      marginTop: "1rem",
                      marginLeft: "30px",
                    }}
                  >
                    {" "}
                    Would you like to give judges the ability to register by sending
                    them a link?
                  </p>{" "}
                </span>
              </div>
              {this.state.judges_can_register && (
                <div style={{ width: "60%", margin: "auto", textAlign: "center" }}>
                  <br />
                  <p>
                    Team Invite Link: <br />{" "}
                    <a
                      href={`${this.state.url.origin}/applicant_room_team?opportunity_id=${this.state.url.query.opportunity_id}`}
                    >
                      <span>
                        {this.state.url.origin}/applicant_room_team?opportunity_id=
                        {this.state.url.query.opportunity_id}
                      </span>
                    </a>
                  </p>
                </div>
              )}

              <div style={{ width: "50%", margin: "auto", display: "flex" }}>
                <Checkbox
                  checked={this.state.judges_get_email}
                  onChange={(event) => {
                    this.handleCheck(event);
                  }}
                  name="judges_get_email"
                  color="primary"
                />
                <span>
                  <p
                    style={{
                      verticalAlign: "middle",
                      marginTop: "1rem",
                      marginLeft: "30px",
                    }}
                  >
                    {" "}
                    Would you like to send an automated email to judges when you
                    register them?
                  </p>{" "}
                </span>
              </div>
              <div style={{ width: "50%", margin: "auto", display: "flex" }}>
                <Checkbox
                  checked={this.state.users_can_see_feedback}
                  onChange={(event) => {
                    this.handleCheck(event);
                  }}
                  name="users_can_see_feedback"
                  color="primary"
                />
                <span>
                  <p
                    style={{
                      verticalAlign: "middle",
                      marginTop: "1rem",
                      marginLeft: "30px",
                    }}
                  >
                    {" "}
                    Would you like to give your applicant the ability to view their
                    feedback?{" "}
                    <em>
                      *We recommend only selecting this once you are done reviewing
                      the companies.
                    </em>
                  </p>{" "}
                </span>
              </div>
              <div style={{ width: "50%", margin: "auto", display: "flex" }}>
                <Checkbox
                  checked={this.state.opportunity_private}
                  onChange={(event) => {
                    this.handleCheck(event);
                  }}
                  name="opportunity_private"
                  color="primary"
                />
                <span>
                  <p
                    style={{
                      verticalAlign: "middle",
                      marginTop: "1rem",
                      marginLeft: "30px",
                    }}
                  >
                    {" "}
                    Would you like to make this opportunity private?{" "}
                    <em>
                      *this will stop your event from appearing on the opportunities
                      page.
                    </em>
                  </p>{" "}
                </span>
              </div>

              <div style={{ width: "50%", margin: "auto", display: "block" }}>
                <div style={{ width: "100%" }}>
                  <FormLabel className="control-label">
                    Current Review Criteria
                  </FormLabel>
                  <ul>
                    <li> Idea </li>
                    <li> Tech </li>
                    <li> Marketing </li>
                    <li> Team </li>
                    {this.state.additional_review_criteria !== undefined &&
                      this.state.additional_review_criteria !== null && (
                        <>
                          {this.state.additional_review_criteria.map((val) => (
                            <li>
                              {val}{" "}
                              <DeleteIcon
                                onClick={(event) => this.deleteCriteria(event, val)}
                              />
                            </li>
                          ))}
                        </>
                      )}
                  </ul>
                </div>
                <div style={{ width: "100%" }}>
                  <FormLabel className="control-label">
                    Enter Another Review Criteria
                  </FormLabel>
                  <FormControl
                    type="text"
                    value={this.state.temp_value}
                    placeholder="Ex. Social Impact"
                    name="temp_value"
                    onChange={this.handleChange}
                    className="form-control mb-3"
                  />
                </div>
                <Button
                  variant="contained"
                  onClick={this.addCriteria}
                  name="showEditOne"
                  style={{ backgroundColor: "#007bff", textAlign: "center" }}
                  className={classes.button}
                >
                  Save
                </Button>
              </div>

              <div style={{ width: "50%", margin: "auto", display: "flex" }}>
                <Checkbox
                  checked={this.state.limit_number_of_reviews}
                  onChange={(event) => {
                    this.handleCheck(event);
                  }}
                  name="limit_number_of_reviews"
                  color="primary"
                />
                <span>
                  <p
                    style={{
                      verticalAlign: "middle",
                      marginTop: "1rem",
                      marginLeft: "30px",
                    }}
                  >
                    Would you like to limit the number of reviews that each company
                    receives?
                    <em>
                      *we recommend clicking this if you have a lot of applicants
                      and want to make the judges lives easier.
                    </em>
                  </p>{" "}
                </span>
              </div>
              {this.state.limit_number_of_reviews &&
                Array.isArray(this.state.judges) && (
                  <div style={{ width: "50%", margin: "auto", display: "block" }}>
                    <p>
                      {" "}
                      How many reviews would you like each company to receive?{" "}
                    </p>
                    <div style={{ width: "100%" }}>
                      <Select
                        value={this.state.number_of_reviews}
                        onChange={this.handleChange}
                        name="number_of_reviews"
                        input={<FilledInput name="number" id="filled-age-simple" />}
                      >
                        {this.state.judges !== undefined &&
                          this.state.judges.map((number, i) => (
                            <MenuItem value={i + 1}>{i + 1}</MenuItem>
                          ))}
                      </Select>
                      <Button
                        variant="contained"
                        onClick={this.handleNumberReviewsSave}
                        name="showEditOne"
                        style={{ backgroundColor: "#007bff", textAlign: "center" }}
                        className={classes.button}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                )}
              <div style={{ width: "50%", margin: "auto", display: "flex" }}>
                <Checkbox
                  checked={this.state.has_applicant_registration_email}
                  onChange={(event) => {
                    this.handleCheck(event);
                  }}
                  name="has_applicant_registration_email"
                  color="primary"
                />
                <span>
                  <p
                    style={{
                      verticalAlign: "middle",
                      marginTop: "1rem",
                      marginLeft: "30px",
                    }}
                  >
                    {" "}
                    Would you like to send an email out to applicants when they
                    apply for your opportunity?
                  </p>{" "}
                </span>
              </div>
              {this.state.has_applicant_registration_email && (
                <div style={{ width: "50%", margin: "auto", display: "block" }}>
                  <div style={{ width: "100%" }}>
                    <FormLabel className="control-label">Email Header</FormLabel>
                    <FormControl
                      type="text"
                      value={this.state.applicant_registration_email.header}
                      placeholder="Ex.How did you hear about this opportunity?"
                      name="header"
                      onChange={this.handleEmailChange}
                      className="form-control mb-3"
                    />
                  </div>
                  <br />
                  <div style={{ width: "100%" }}>
                    <FormLabel className="control-label">Email Body</FormLabel>
                    <FormControl
                      as="textarea"
                      rows="5"
                      value={this.state.applicant_registration_email.body}
                      placeholder="Ex. Enter the name of the person that reffered you?"
                      name="body"
                      onChange={this.handleEmailChange}
                    />
                  </div>
                  <Button
                    variant="contained"
                    onClick={this.handleEmailSave}
                    name="showEditOne"
                    style={{ backgroundColor: "#007bff", textAlign: "center" }}
                    className={classes.button}
                  >
                    Save
                  </Button>
                </div>
              )}
              <div style={{ width: "50%", margin: "auto", display: "flex" }}>
                <Checkbox
                  checked={this.state.opportunity_form_modify}
                  onChange={(event) => {
                    this.handleCheck(event);
                  }}
                  name="opportunity_form_modify"
                  color="primary"
                />
                <span>
                  <p
                    style={{
                      verticalAlign: "middle",
                      marginTop: "1rem",
                      marginLeft: "30px",
                    }}
                  >
                    {" "}
                    Would you like to modify the questions in your application form?{" "}
                    <em>
                      *this change will be seen when applicants apply on your
                      opportunity page.
                    </em>
                  </p>{" "}
                </span>
              </div>

              {this.state.opportunity_form_modify && (
                <div style={{ width: "50%", margin: "auto", display: "block" }}>
                  {Object.keys(this.state.opportunity_form).map(
                    (opp_form_key, i) => (
                      <div>
                        <div style={{ width: "100%" }}>
                          <FormLabel className="control-label">
                            {this.state.opportunity_form[opp_form_key]["title"]}
                          </FormLabel>
                          <div style={{ display: "flex" }}>
                            <TextField
                              disabled
                              rows="4"
                              variant="outlined"
                              multiline
                              placeholder={
                                this.state.opportunity_form[opp_form_key][
                                  "helper_text"
                                ]
                              }
                              className={classes.textField}
                              style={{ width: "80%" }}
                            />
                            <FormControlLabel
                              value="top"
                              control={
                                <Checkbox
                                  checked={
                                    this.state.opportunity_form[opp_form_key][
                                      "display"
                                    ]
                                  }
                                  onChange={(event) => {
                                    this.handleCustomForm(event, opp_form_key);
                                  }}
                                  name="display"
                                  color="primary"
                                  style={{ width: "10%" }}
                                />
                              }
                              label="Display"
                              labelPlacement="top"
                            />
                            <FormControlLabel
                              value="top"
                              control={
                                <Checkbox
                                  checked={
                                    this.state.opportunity_form[opp_form_key][
                                      "required"
                                    ]
                                  }
                                  onChange={(event) => {
                                    this.handleCustomForm(event, opp_form_key);
                                  }}
                                  name="required"
                                  color="primary"
                                  style={{ width: "10%" }}
                                />
                              }
                              label="Required"
                              labelPlacement="top"
                            />
                          </div>
                        </div>
                      </div>
                    )
                  )}
                  <Button
                    variant="contained"
                    onClick={this.handleFormSave}
                    name="showEditOne"
                    style={{ backgroundColor: "#007bff", textAlign: "center" }}
                    className={classes.button}
                  >
                    Save
                  </Button>
                </div>
              )}
            </div>
          </React.Fragment>
        </div> */}
        {/* <div className="UbaidCompanyProfilePic">
          <div className="logo">
            <button
              className="__company-share"
              type="button"
              onClick={this.handleSharePopup}
            >
              {" "}
              <i
                style={{ color: "black" }}
                className="fas fa-share-alt"
              ></i>
            </button>
            <div className="profilePic-container">
              <img
                style={{ height: "100%" }}
                src={this.getAvatarUrl(
                  this.state.company_files,
                  "company avatar"
                )}
              />
              {this.state.userIsProfileOwner && (
                <div>
                  <div className="profilePic-overlay"></div>
                  <span
                    onClick={this.handleProfilePhotoEditPopup}
                    className="profilePic-span"
                  >
                    Edit Profile Picture
                  </span>
                </div>
              )}
            </div>
            <div style={{ background: "black" }}></div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {this.state.facebook !== null &&
              this.state.facebook.length !== 0 && (
                <SocialIcon
                  style={{ margin: "3px" }}
                  url={this.state.facebook}
                  target="_blank"
                />
              )}
            {this.state.twitter.length !== 0 && (
              <SocialIcon
                style={{ margin: "3px" }}
                url={this.state.twitter}
                target="_blank"
              />
            )}
            {this.state.instagram.length !== 0 && (
              <SocialIcon
                style={{ margin: "3px" }}
                url={this.state.instagram}
                target="_blank"
              />
            )}
          </div>
        </div> */}
      </div>
    );
  }
}

Page.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Page);
