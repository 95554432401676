import React from 'react';
import classnames from 'classnames';
import Moment from "moment";
import './CenterBody.css';
import './UserDetails.css';
import PostFooter from './PostFooter';
import PostHeader from './PostHeader';
import PostComments from './PostComments';
import post2 from '../Images/post__thumb1.jpg'
import SocialAPI from '../../live/api/social';
import Linkify from 'react-linkify';

class FeedPostWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      postDeleted: false,
      commentsHidden: true,
      currentlyLiked: props.post.currentUserLiked,
      totalLikes: props.post.totalLikes
    };
  }

  handleLikeToggle(e) {
    e.preventDefault();



    if (this.props.post.currentUserLiked) {
      this.handleUnlike().then(function() {
        this.props.handleUnLike(this.props.index)
      }.bind(this));
    } else {
      this.handleLike().then(function() {
        this.props.handleLike(this.props.index)
      }.bind(this));
    }
  }

  handleLike() {
    return SocialAPI.likePost(this.props.post._id);
  }

  handleUnlike() {
    return SocialAPI.dislikePost(this.props.post._id);
  }

  handleCommentToggle(e) {
    e.preventDefault();
    this.setState({
      commentsHidden: !this.state.commentsHidden
    });
  }

  handleDelete(e) {
    e.preventDefault();

    if (window.confirm('Are you sure you want to delete this post?')) {
      SocialAPI.deletePost(this.props.post._id).then(function() {
        this.setState({
          postDeleted: true
        });
      }.bind(this));
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.post._id !== prevProps.post._id) {
      this.setState({
        commentsHidden: true
      });
    }
  }

  render() {
    return (
      <div className={ classnames('PostCardsNewsFeed', this.state.postDeleted && 'hide') }>
        <div>
          <PostHeader
            handleDelete={ this.handleDelete.bind(this) }
            isPostOwner={ this.props.post.byCurrentUser }
            company_id={ this.props.post.author._id }
            name={ `${this.props.post.author.company_name}` }
            child=''
            time={ Moment(this.props.post.created_on).fromNow() }>
            <div style={{whiteSpace: "pre-line"}}>
            <Linkify>{ this.props.post.content }</Linkify>
            </div>
          </PostHeader>
        </div>
        <PostFooter
          handleLikeToggle={ this.handleLikeToggle.bind(this) }
          handleCommentToggle={ this.handleCommentToggle.bind(this) }
          isPostOwner={ this.props.post.byCurrentUser }
          currentlyLiked={ this.props.post.currentUserLiked }
          likes={ this.props.post.totalLikes }
          comments={ this.props.post.totalComments }
          post_id={this.props.post._id} />
        <PostComments
          postId={ this.props.post._id }
          commentsHidden={ this.state.commentsHidden } />
      </div>
      );
  }

}

export default FeedPostWrapper;
